<div class="flex justify-content-center modal_component">
    <button (click)="showDialog()" [class]="buttonClass">
        <ng-container *ngIf="imageSrc">
            <img [src]="imageSrc" alt="Filter">
        </ng-container>
        {{ buttonText }}
    </button>

    <div class="modal-content">
    <p-dialog header="{{ header }}" [(visible)]="visible" [modal]="true"  [draggable]="false" [resizable]="false">
            <ng-container *ngIf="subHead">
                <!-- <h6 class="subHead">{{subHead}}</h6> -->
            </ng-container>
            <div class="mx-auto">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <ng-container *ngIf="link">
                        <a href="#" class="fs12 me-4">{{link}}</a>
                    </ng-container>
                    <ng-container *ngIf="copyBtn">
                        <button type="button" (click)="copyLinkToClipboard()" class="secondary-btn p-12-60">{{copyBtn}}</button>
                    </ng-container>
                </div>
                <div class="d-flex justify-content-center p16">
                    <ng-container *ngIf="imageSrc1">
                        <img [src]="imageSrc1" alt="Image">
                    </ng-container>
                </div>
                <div class="d-flex justify-content-center">
                    <ng-container *ngIf="customButton">
                        <button class="blue-btn p-12-60" (click)="downloadImage()" do>{{customButton}}</button>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="content">
                <p>{{ content }}</p>
            </ng-container>


        </p-dialog>
    </div>
</div>
<!--  -->
