import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent {
  @Input() header!: string;
  @Input() content!: string;
  @Input() imageSrc!: string;
  @Input() buttonText!: string;
  @Input() buttonClass!: string; 
  @Input() imageSrc1!: string; 
  @Input() link!: string; 
  @Input() copyBtn!: string; 
  @Input() subHead!: string; 
  @Input() customButton!: string; 
  visible: boolean = false;
  
  showDialog() {
    this.visible = true;
  }

  customButtonClick() {
    // Handle the click event of the custom button here
    console.log('Custom button clicked!');
  }
  copyLinkToClipboard() {
    const el = document.createElement('textarea');
    el.value = this.link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    
    // Display message
    alert('Link copied to clipboard!');
  }
  downloadImage() {
    const link = document.createElement('a');
    link.href = this.imageSrc1;
    link.download = 'QR code'; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  products=[
    {
        id: '1000',
        code: 'f230fh0g3',
        name: 'Bamboo Watch',
        description: 'Product Description',
        image: 'bamboo-watch.jpg',
        price: 65,
        category: 'Accessories',
        quantity: 24,
        inventoryStatus: 'INSTOCK',
        rating: 5
    },
    {
        id: '1001',
        code: 'nvklal433',
        name: 'Black Watch',
        description: 'Product Description',
        image: 'black-watch.jpg',
        price: 72,
        category: 'Accessories',
        quantity: 61,
        inventoryStatus: 'OUTOFSTOCK',
        rating: 4
    },
    {
        id: '1002',
        code: 'zz21cz3c1',
        name: 'Blue Band',
        description: 'Product Description',
        image: 'blue-band.jpg',
        price: 79,
        category: 'Fitness',
        quantity: 2,
        inventoryStatus: 'LOWSTOCK',
        rating: 3
    }


  ];
}