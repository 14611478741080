import { Injector, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from '../services/app-settings.service';
import { jwtDecode } from "jwt-decode";


export abstract class AppComponentBase {
    appSettings: AppSettingsService;
    userId: any;
    ggg: any;token1:any
    userNamedata: any;
    // showHeader: boolean = false;
    constructor(injector: Injector) {
        this.appSettings = injector.get(AppSettingsService);
        // this.logger = injector.get(LoggerService);
        // this.router = injector.get(Router);
        // this.appSettings = injector.get(AppSettingsService);
        // let rout: any = window.location.href;
        // let data = rout.split('/').pop();
        // if (data == '') {
        //     // this.showHeader = false;
        // }

        var userDetails = JSON.parse(localStorage.getItem("userDetails"));
        if (userDetails) {
            this.token1 = jwtDecode(userDetails)
            this.userId = this.token1.UserId
        }
    }


}
