import { Router,ActivatedRoute } from '@angular/router';
import { userService } from 'src/app/services/userservice';


import { Component, ViewChild, Injector, OnInit, Renderer2, OnDestroy, ElementRef, QueryList, ViewChildren } from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { commonService } from '../services/commonService';
import { candidateService } from '../services/candidateService';
import { jobService } from '../services/jobservice';
import { NgForm, NgModel } from '@angular/forms';
import { TrfService } from '../services/trf.service';
import { AppComponentBase } from '../shared/app.component-base';
import { NotificationService } from '../services/notification.service';


import * as moment from 'moment';
import { jwtDecode } from 'jwt-decode';
import { roleService } from '../services/roleService';
@Component({
  selector: 'app-trf',
  templateUrl: './trf.component.html',
  styleUrls: ['./trf.component.scss'],
})
// extends AppComponentBase
export class TrfComponent implements OnInit {
  navLinkActive: any = 'Pending';
  trfSearch: any;
  loader: boolean = true;

  trfData: any;
  talentRequisition: any;
  trfJobDescription: any;

  showLoader: boolean = true;
  trfLength: any;


  @ViewChild('basicInfoo', { static: false }) basicInfoo: NgForm;

  @ViewChild('descriptionForm', { static: false }) descriptionForm: NgForm;

  rafJobTitle = true;
  rafJobDescription = false;
  rafBasicCheck = false;
  rafBasicActive = true;
  rafDescCheck = false;
  rafDescActive = false;
  trfJobTitleMOdal = false;

  viewDisable: boolean = false;

  public Editor = ClassicEditor;
  rftOverView: any;
  trfRequestedDate: any;
  trfNumberPositions: any;
  trfcttc: any;
  trftICc: any;
  trfPriorityOne: any;
  trfPriorityTwo: any;
  TRFInfoData: any = {};

  addTalentReqArray: any = [];
  trfCountriess: any = [];
  trfCampuuss: any = [];
  trfJobTitleArray: any = [];
  trfSkkillsCategory: any = [];
  trfJobTitleArrayy: any = [];
  trfSkillsss: any = [];
  trfrequesterArray: any = [];

  selectedtrfRequester: any;
  selectedtrfBrand: any;
  selectedtrfCountry: any;
  skillCategory: any;
  skills: any;
  selectedtrfJobTitlee: any;
  selectedtrfAppointmentType: any;
  selectedtrfPriority: any;
  selectedtrfCampus: any;
  minStartDate = new Date();
  isSubmitting: boolean = false;

  selectedtrfBudgeted: number;
  trfBudgeted = [
    { id: 1, name: 'Budgeted' },
    { id: 2, name: 'Non-Budgeted' },
  ];
  selectedtrfEmploymentType: number;
  trfEmploymentType = [
    { id: 1, name: 'Full Time' },
    { id: 2, name: 'Part Time' },
    { id: 3, name: 'Contract' },
    { id: 4, name: 'Freelancing' },
  ];
  selectedtrfApprovedBy: number;
  trfApprovedBy = [
    { id: 1, name: 'Approver 1' },
    { id: 2, name: 'Approver 2' },
    { id: 3, name: 'Approver 3' },
    { id: 4, name: 'Approver 4' },
  ];
  selectedtrfBBrand: number;
  trfrequesterBrand = [
    { id: 1, name: 'Brand 1' },
    { id: 2, name: 'Brand 2' },
    { id: 3, name: 'Brand 3' },
    { id: 4, name: 'Brand 4' },
  ];
  Caategory: number;
  trfCaategory = [
    { id: 1, name: 'Academic' },
    { id: 2, name: 'Non-Academic' },
  ];
  // talentRequisition: any = {};
  // trfJobDescription: any = {};
  id: any;
  sub: any;

  trfViewbuttons: boolean = false;
  trfNormalButtons: boolean = true;
  titleError: any;
  title: any;
  validateTitle: any;
  jahId: any;
  jahIds: any;
  degree: any;
  Education_details: any;
  status: any;
  userData: any;
  approverData: any;
  myModal: boolean = false;
  Appointment_data: any;
  roleStatus: boolean = false

// injector: Injector,
  constructor(
    public trfservice:TrfService, public router : Router,    public commonService: commonService,
    public userService: userService, public route: ActivatedRoute, public roleService :roleService,
    private myService: commonService,
    private jobService: jobService,
    private notifyService: NotificationService, private candService: candidateService,
    private renderer: Renderer2,
    ){
    // super(injector);
    this.sub = this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.TRFInfoData.jobskill = [
      {
        skillCategory: null,
        skills: null,
        skill_list: [],
      },
    ];
    this.talentRequisition = {};
    this.trfJobDescription = {};

    let view = this.route.snapshot.routeConfig.path;
    if (view == 'view-trf/:id') {
      this.viewDisable = true;
      this.trfViewbuttons = true;
      this.trfNormalButtons = false;
    }

  }
uid:any
paginationPageSize: number = 10;
  currentPage: number = 0;
  noOfPages: number = 0;
  v1: number;
  v2: number;
  pageNo: number = 0;
  pageRecords: number;
  totalRecords: number = 0;
  ngOnInit(): void {
    let userData = this.userService.getUserId();
    this.userData = jwtDecode(userData)
    this.uid = this.userData.UserId
    console.log(this.uid,"==========userData")

    // this.uid = userData.userId;
    // this.uid=146
    this.roletypeId(this.uid)
    this.getDataOfTrf(this.status);
    this.GetBrandDetailsByUserId(this.uid)
    //  let active:any = document.getElementsByClassName("start-list__item")[3];
    //  active.addClass('active')
    //  active.classList.add('active');
    this.trfBrandd();
    this.trfCountries();
    this.trfCampuss();
    this.trfJobTitleData();
    this.trfSkillsCategorry();
    this.trfskills();
    this.trfRequesteerr();
    this.getDegree();
    this.getLevelOfEducation();
    let date = new Date();
    this.GetCategoryType();
    this.getCategories();
    this.gethyringType();
    this.getEmployementType();
    this.brandDetails();
    this.getAppointmentType();
    setTimeout(() => {
      if (parseInt(this.id) > 0) {
        this.edittrfInfo();
      }
    }, 500);
    setTimeout(() => {
      if (parseInt(this.id) > 0) {
        this.viewtrfInfo();
      }
    }, 500);
    // setTimeout(() => {

    //   this.talentRequisition.requester=parseInt(this.uid);
    // }, 200);
    this.trfAllTabsCount(this.uid)
    this.getCurrencyCodes()

    const savedTab = sessionStorage.getItem('navLinkActive');
    this.navLinkActive = savedTab ? savedTab : 'pending';
    this.getDataOfTrf(this.getTabIndex(this.navLinkActive));
    this.setRole(this.selectedRole);
  }
  roletypeId(uid: any) {
    this.roleService.roleTypeUserId(uid).subscribe((res:any)=>{
      let hasRole2 = false;
      let hasRole6 = false;
      for (let i = 0; i < res.length; i++) {
        var element = res[i].roleTypeId;
        if (element === "2") {
          hasRole2 = true;
        }
        if (element === "6") {
          hasRole6 = true;
        }
        if (hasRole2 && hasRole6) {
          this.roleStatus = true;
        }
      }
    })
  }



  // showAllKebabMenu(i: any) {
  //   document.getElementById(`allKeebabbMenuu${i}`).style.display = 'block';
  // }

  noAllKebabMenu(i: any) {
    document.getElementById(`allKeebabbMenuu${i}`).style.display = 'none';
  }

  openedMenuIndex: number | null = null;
  documentClickListener: () => void;

  showAllKebabMenu(i: number): void {
    this.openedMenuIndex = i;

    // Show the menu
    const menuElement = document.getElementById(`allKeebabbMenuu${i}`);
    // if (menuElement) {
      menuElement.style.display = 'block';
    // }

    // Add a document click listener if not already added
    // if (!this.documentClickListener) {
    //   this.documentClickListener = this.renderer.listen('document', 'click', (event: Event) => {
    //     this.onDocumentClick(event);
    //   });
    // }
  }
  hideAllKebabMenu(): void {
    if (this.openedMenuIndex !== null) {
      // Hide the currently opened menu
      const menuElement = document.getElementById(`allKeebabbMenuu${this.openedMenuIndex}`);
      if (menuElement) {
        menuElement.style.display = 'none';
      }
      this.openedMenuIndex = null;
    }
  }

  onDocumentClick(event: Event): void {
    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the kebab menu
    const clickedInside = targetElement.closest('.all__kebab__menuu') || targetElement.closest('.pointer');
    if (!clickedInside) {
      this.hideAllKebabMenu();
    }
  }

  ngOnDestroy(): void {
    // Remove the document click listener when the component is destroyed
    if (this.documentClickListener) {
      this.documentClickListener();
    }
  }
  // public lengthTRF =


  getDataOfTrf(status){
    this.trfSearch=''
    this.loader = true;
    this.status=status
    if (status==0) {
    this.pageNo = 0;
    }else if (status==0) {
      this.pageNo = 0;
    }else if (status==1) {
      this.pageNo = 0;
    }else if (status==2) {
      this.pageNo = 0;
    }else if (status==3) {
      this.pageNo = 0;
    }else if (status==4) {
      this.pageNo = 0;
    }else if (status==5) {
      this.pageNo = 0;
    }
    let pagination = this.paginationPageSize;
    let pageNumber = this.pageNo;
    this.trfData=[]
    this.trfservice.GetTrfPagination(this.uid,this.status,pageNumber,pagination).subscribe((res: any) => {
      if (res) {
        this.trfData = res;
        this.loader = false;
      if (res[0] && res[0].totalCount) {
        this.totalRecords = res[0].totalCount;
      } else {
        this.totalRecords = 0;
      }
      this.noOfRecords(this.pageNo);
      setTimeout(() => {
        this.showLoader = false;
      }, 500);
      }
    });
  }


  gettrfData(status=0){
    this.status=status
    if (status==0) {
    this.pageNo = this.pageNo;
    }else if (status==0) {
      this.navLinkActive = 'pending';
      this.pageNo = this.pageNo;
    }else if (status==1) {
      this.navLinkActive = 'accepted';
      this.pageNo = this.pageNo;
    }else if (status==2) {
      this.navLinkActive = 'rejected';
      this.pageNo = this.pageNo;
    }else if (status==3) {
      this.navLinkActive = 'expired';
      this.pageNo = this.pageNo;
    }else if (status==4) {
      this.navLinkActive = 'jobRejected';
      this.pageNo = this.pageNo;
    }else if (status==5) {
      this.navLinkActive = 'jobClosed';
      this.pageNo = this.pageNo;
    }
    let pagination = this.paginationPageSize;
    let pageNumber = this.pageNo;
    this.trfservice.GetTrfPagination(this.uid,this.status,pageNumber,pagination).subscribe((res: any) => {
      if (res) {
        this.trfData = res;
      if (res[0] && res[0].totalCount) {
        this.totalRecords = res[0].totalCount;
      } else {
        this.totalRecords = 0;
      }
      this.noOfRecords(this.pageNo);
      setTimeout(() => {
        this.showLoader = false;
      }, 500);
      }
    });
  }

  GetTrfPagination(uid:any) {
    let pagination = this.paginationPageSize;
    let pageNumber = this.pageNo;
    this.trfservice.GetTrfPagination(uid,this.status,pageNumber,pagination).subscribe((res: any) => {
      // if (res) {
        this.trfData = res;
        this.gettrfData(this.status)
      // if (res[0] && res[0].totalCount) {
      //   this.totalRecords = res[0].totalCount;
      // } else {
      //   this.totalRecords = 0;
      // }
      // this.noOfRecords(this.pageNo);
      // setTimeout(() => {
      //   this.showLoader = false;
      // }, 500);
      // }
    });
  }
  allTRFCount = [];
  trfAllTabsCount(uid:any){
    this.commonService.trfTabsCount(uid).subscribe((data:any) => {
      console.log("tabs count data",data);
      this.allTRFCount = data;
    })
  }

  noOfRecords(currentPage) {
    this.v1 = currentPage * this.paginationPageSize + 1;
    this.v2 = (currentPage + 1) * this.paginationPageSize;
    this.pageNo = currentPage;
    // tslint:disable-next-line:max-line-length
    this.pageRecords =
      this.totalRecords % this.paginationPageSize === 0
        ? this.totalRecords / this.paginationPageSize
        : Math.floor(this.totalRecords / this.paginationPageSize) + 1;

    if (this.totalRecords == 0) {
      this.v1 = 0;
    }
    if (this.totalRecords < this.v2) {
      this.v2 = this.totalRecords;
    }
  }


  searchTrf(){
    this.pageNo=0
    console.log(this.trfSearch,"================")
    if (this.trfSearch && this.trfSearch.length >= 2) {
      this.trfData=[]
      this.TalentRequisitionSearch()
    }else{
      this.TalentRequisitionSearch()
    }
  }

  TalentRequisitionSearch(){
  let pagination = this.paginationPageSize;
    let pageNumber = this.pageNo;
    this.trfData=[]
    this.trfservice.TalentRequisitionSearch(this.trfSearch,this.uid,pageNumber,pagination,this.status).subscribe((res:any)=>{
      if (res) {
        this.trfData = res;
      //   this.hierarchyLength = res.length
      // }, 2000);
      if (res[0] && res[0].totalCount) {
        this.totalRecords = res[0].totalCount;
      } else {
        this.totalRecords = 0;
      }
      this.noOfRecords(this.pageNo);
      setTimeout(() => {
        this.showLoader = false;
      }, 500);
      }
    })
  }

  paginationButtonDisabled : boolean = false;
  previous() {
    this.pageNo = --this.pageNo;
    this.paginationButtonDisabled = true;
    setTimeout(() => {
      this.paginationButtonDisabled = false;
    }, 1000);
    if (this.trfSearch!=undefined) {
      this.TalentRequisitionSearch();
    }else{
      this.GetTrfPagination(this.uid);
    }

    // this.GetTrfPagination(this.userId);
  }
  next() {
    this.pageNo++;
    this.paginationButtonDisabled = true;
      setTimeout(() => {
        this.paginationButtonDisabled = false;
      }, 1000);
    if (this.trfSearch!=undefined) {
      this.TalentRequisitionSearch();
    }else{

      this.GetTrfPagination(this.uid);
    }
    // this.GetTrfPagination(this.userId);
    //  this.noOfRecords(this.pageNo);
  }
  getJobDate(data) {
    return moment(data).format('MMM DD,YYYY');
  }
  // openMainKebabMenu(i) {
  //   let popupID: any = document.getElementById(`popupMEnuKebab${i}`);
  //   popupID.style.display = 'block';
  // }
  // noKebbMenu(i) {
  //   let popupIDd: any = document.getElementById(`popupMEnuKebab${i}`);
  //   popupIDd.style.display = 'none';
  // }
  // commaSeperated(data: any) {
  //   // let newLine = data.replace(/,/g, '\n')
  //   // return  newLine;
  //   if (data) {
  //     var nameArr = data.split(',');
  //     var obj = nameArr;
  //     return obj;
  //   }
  // }

  editTrf(id:any){
   this.router.navigateByUrl('edit-trf/'+id)
  }
  viewTrf(id:any){
    this.router.navigateByUrl('view-trf/'+id)
  }
  rejectTrf(id:any,Rejected:any){
    this.trfservice.UpdateTalentRequisition(id,Rejected).subscribe((res:any)=>{
      this.gettrfData(this.status)
    })
  }

  blueCrossMark = false;
  showCross() {
    if (this.trfSearch.length >= 1) {
      this.blueCrossMark = true;
    } else this.blueCrossMark = false;
  }
  removeValue() {
    this.blueCrossMark = false;
    this.trfSearch = '';
    this.pageNo=0
    this.gettrfData(this.status);
    // this.trfData = false;
  }
  openNewTab(url: string) {
    window.open(url, '_blank');
  }
  trfMain : boolean = true;
  addTrfMain : boolean = false;
  openTRFForm(){
    this.trfMain = false;
    this.addTrfMain = true;
    this.rafJobDescription = false;
    this.talentRequisition.requester=parseInt(this.uid);
    this.rafJobTitle = true;
    this.rafBasicCheck = false;
    this.rafBasicActive = true;
    this.rafDescCheck = false;
    this.rafDescActive = false;
  }
  backToTRF(){
    this.rafBasicCheck = false;
    this.rafBasicActive = true;
    this.rafDescCheck = false;
    this.rafDescActive = false;


    this.trfMain = true;
    this.rafJobTitle = true;
    this.rafJobDescription = false;
    this.addTrfMain = false;
    this.basicInfoo.form.reset();
    this.descriptionForm.form.reset();
    // window.location.reload();
    this.getDataOfTrf(this.status)
  }
  jadId: any;
  viewtrfInfo() {
    this.trfservice
      .GetTalentRequisitionById(parseInt(this.id))
      .subscribe((res: any) => {
        // this.trfservice.GetBrandDetailsByUserId(res.requester).subscribe((res) => {
        //   this.brandsnewarray = []
        //   this.resVal = res
        //   this.brandsnewarray.push(this.resVal);
        // });
        if (res) {
          if (this.id) {
            // var countries = this.brands.filter(
            //   (x) => x.brandId == res.talentRequisition.brand
            // );
            // if (countries && countries[0]?.country?.length > 0) {
            this.getcountries(res.talentRequisition.brand);
            // }

            // var getCampus = this.trf_Country.filter(
            //   (x) => x.countryId == res.talentRequisition.country
            // );
            // if (
            //   getCampus &&
            //   getCampus[0]?.countryId &&
            //   getCampus[0]?.campus.length > 0
            // ) {
            //   this.getCampus(getCampus[0].countryId);
            // }
            this.getCampus(res.talentRequisition.country);

            this.TRFInfoData.jobskill = res.trfJobDescription.trfSkills;
            if (this.TRFInfoData.jobskill) {
              let skills = this.TRFInfoData.jobskill;
              for (let i = 0; i < skills.length; i++) {
                this.GetSkillNameById(skills[i].skillCategory, i);
                let skillName = skills[i].skills;
                if (skillName.indexOf(',') > -1) {
                  let arraydoc = skillName.split(',');
                  let skillnumarray = arraydoc.map(Number);
                  skills[i].skills = skillnumarray;
                } else {
                  let singleDoc = skillName.split(' ');
                  let skillnumarray = singleDoc.map(Number);
                  skills[i].skills = skillnumarray;
                }
              }
            }
            setTimeout(() => {
              var campusIds = [];
              var campuses = res.talentRequisition.campus?.split(',');

              if (campuses?.length > 0) {
                campuses = campuses.forEach((element) => {
                  campusIds.push(parseInt(element));
                });
                res.talentRequisition.campus = campusIds;
              }
              let expectedJoiningDate = this.convert(
                res.talentRequisition.expectedJoiningDate
              );
              if (res.talentRequisition.higherLevelOfEducation == 0) {
                res.talentRequisition.higherLevelOfEducation = null;
              } else {
                res.talentRequisition.higherLevelOfEducation =
                  res.talentRequisition.higherLevelOfEducation;
              }
              res.talentRequisition.expectedJoiningDate = expectedJoiningDate;
              this.jadId = res.talentRequisition.jahId;
              // var JobTitleId = res.talentRequisition.jahJobTitleId?.split(',');
              // if (JobTitleId?.length > 0) {
              //   JobTitleId.forEach((element) => {
              //     JobTitleId = parseInt(element);
              //   });
              //   res.talentRequisition.jahJobTitleId = JobTitleId;
              // }
              res.talentRequisition.jahJobTitleId = res.talentRequisition.jahId;

              if (res.talentRequisition.qualification) {
                let qualificationArray = res.talentRequisition.qualification;
                if (qualificationArray.indexOf(',') > -1) {
                  let arraydoc = qualificationArray.split(',');
                  let numberArray = arraydoc.map(Number);
                  res.talentRequisition.qualification = numberArray;
                } else {
                  let singleDoc = qualificationArray.split(' ');
                  let numberArray = singleDoc.map(Number);
                  res.talentRequisition.qualification = numberArray;
                }
              }
              this.talentRequisition = res.talentRequisition;
              this.trfJobDescription = res.trfJobDescription;

              if (this.talentRequisition.budOrNonBud) {
                this.talentRequisition.budOrNonBud =
                  +this.talentRequisition.budOrNonBud;
              }
            }, 200);
          }
        }
      });
  }

  edittrfInfo() {
    this.trfservice
      .GetTalentRequisitionById(parseInt(this.id))
      .subscribe((res: any) => {
        if (res) {
          // console.log(res, 'ffffffffffffffffffffffffffff')
          if (this.id) {
            console.log(res.talentRequisition.requester, 'reque');
            var obj = { userId: res.talentRequisition.requester };
            this.GetBrandDetailsByUserId(obj);
            // var countries = this.brandsnewarray.filter(
            //   (x) => x.brandId == res.talentRequisition.brand
            // );
            // console.log(countries,"===========================")
            // if (countries && countries[0]?.country?.length > 0) {
            this.getcountries(res.talentRequisition.brand);
            // }

            // var getCampus = this.trf_Country.filter(
            //   (x) => x.countryId == res.talentRequisition.country
            // );
            // if (
            //   getCampus &&
            //   getCampus[0]?.countryId &&
            //   getCampus[0]?.campus.length > 0
            // ) {
            this.getCampus(res.talentRequisition.country);
            // }

            this.TRFInfoData.jobskill = res.trfJobDescription.trfSkills;
            if (this.TRFInfoData.jobskill) {
              let skills = this.TRFInfoData.jobskill;
              for (let i = 0; i < skills.length; i++) {
                this.GetSkillNameById(skills[i].skillCategory, i);
                let skillName = skills[i].skills;
                if (skillName.indexOf(',') > -1) {
                  let arraydoc = skillName.split(',');
                  let skillnumarray = arraydoc.map(Number);
                  skills[i].skills = skillnumarray;
                } else {
                  let singleDoc = skillName.split(' ');
                  let skillnumarray = singleDoc.map(Number);
                  skills[i].skills = skillnumarray;
                }
              }
            }
            setTimeout(() => {
              var campusIds = [];
              var campuses = res.talentRequisition.campus?.split(',');

              if (campuses?.length > 0) {
                campuses = campuses.forEach((element) => {
                  campusIds.push(parseInt(element));
                });
                res.talentRequisition.campus = campusIds;
              }
              let expectedJoiningDate = this.convert(
                res.talentRequisition.expectedJoiningDate
              );
              console.log(
                res.talentRequisition.higherLevelOfEducation,
                '===============res.talentRequisition.higherLevelOfEducation'
              );
              if (res.talentRequisition.higherLevelOfEducation == 0) {
                res.talentRequisition.higherLevelOfEducation = null;
              } else {
                res.talentRequisition.higherLevelOfEducation =
                  res.talentRequisition.higherLevelOfEducation;
              }
              res.talentRequisition.expectedJoiningDate = expectedJoiningDate;
              // var JobTitleId = res.talentRequisition.jahJobTitleId?.split(',');
              // if (JobTitleId?.length > 0) {
              //   JobTitleId.forEach((element) => {
              //     JobTitleId = parseInt(element);
              //   });
              //   res.talentRequisition.jahJobTitleId = JobTitleId;
              // }

              if (res.talentRequisition.qualification) {
                let qualificationArray = res.talentRequisition.qualification;
                if (qualificationArray.indexOf(',') > -1) {
                  let arraydoc = qualificationArray.split(',');
                  let numberArray = arraydoc.map(Number);
                  res.talentRequisition.qualification = numberArray;
                } else {
                  let singleDoc = qualificationArray.split(' ');
                  let numberArray = singleDoc.map(Number);
                  res.talentRequisition.qualification = numberArray;
                }
              }

              res.talentRequisition.jahJobTitleId = res.talentRequisition.jahId;
              this.jadId = res.talentRequisition.jahId;

              this.talentRequisition = res.talentRequisition;
              this.trfJobDescription = res.trfJobDescription;

              if (this.talentRequisition.budOrNonBud) {
                this.talentRequisition.budOrNonBud =
                  +this.talentRequisition.budOrNonBud;
              }
            }, 200);
          }
        }
      });
  }

  convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('/');
  }

  GetSkillNameById(event: any, i: any) {
    let skillid = event.skill_CategoryId || event;
    this.commonService.GetSkillNameById(skillid).subscribe(async (res) => {
      let trfSkills: any = this.TRFInfoData.jobskill;
      trfSkills[i].skill_list = res;
    });
  }

  respectiveScreen(data) {
    // if (this.popupError?.length>0) {
    //   this.trfJobTitleMOdal = true;
    if (data == 'basicinfo') {
      // if (this.side_ButtonEnable) {
      this.rafJobTitle = true;
      this.rafJobDescription = false;
      this.rafDescActive = false;
      this.rafBasicActive = true;
      this.rafBasicCheck = true;
      console.log(
        this.TRFInfoData.jobskill,
        '================GetSkillNameById1'
      );
    }
    // }
    // }
    if (data == 'description') {
      if (this.rafDescCheck) {
        console.log(
          this.TRFInfoData.jobskill,
          '================GetSkillNameById2'
        );

        // if (this.perivous_ButtonEnable) {
        this.rafJobTitle = false;
        this.rafJobDescription = true;
        this.rafDescActive = true;
        this.rafBasicActive = false;
        this.rafBasicCheck = true;
      }
    }
  }

  @ViewChildren(NgModel) formControls: QueryList<NgModel>;
  @ViewChildren(NgModel, { read: ElementRef }) formElements: QueryList<ElementRef>;
  private scrollToFirstInvalidControl() {
    const invalidControlIndex = this.formControls.toArray().findIndex(control => control.invalid);

    if (invalidControlIndex !== -1) {
      const firstInvalidControl: HTMLElement = this.formElements.toArray()[invalidControlIndex].nativeElement;
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      firstInvalidControl.focus();
    }
  }

  trfNextScreen() {
    if (this.basicInfoo.invalid || this.errorjob) {
      this.scrollToFirstInvalidControl();
      this.basicInfoo.form.markAllAsTouched();
      this.rafJobTitle = true;
      this.rafJobDescription = false;
      this.rafBasicCheck = false;
      this.rafBasicActive = true;
      this.rafDescCheck = false;
      this.rafDescActive = false;
      // console.log(
      //   this.TRFInfoData.jobskill,
      //   '================GetSkillNameById3'
      // );
    } else {
      this.rafJobTitle = false;
      this.rafJobDescription = true;
      this.rafBasicCheck = true;
      this.rafBasicActive = false;
      this.rafDescCheck = true;
      this.rafDescActive = true;
      this.GetCategoryType();

      // console.log(
      //   this.TRFInfoData.jobskill,
      //   '================GetSkillNameById4'
      // );
    }
  }

  getAppointmentType() {
    this.commonService.getAppointmentType().subscribe((res) => {
      this.Appointment_data = res;
    });
  }

  disableemployee: boolean = false;
  typeOfappointment(event: any) {
    var ename = event.appointment_Id || event;
    if (ename == 2) {
      this.disableemployee = true;
    } else if (ename == 1) {
      this.disableemployee = false;
    }
  }

  addSkill() {
    console.log(this.TRFInfoData.jobskill, '================GetSkillNameById');
    console.log(this.skill_list, 'skill_listskill_listskill_list');

    let skill_Type: any = [];
    skill_Type = this.skill_list;
    for (let k = 0; k < this.TRFInfoData.jobskill.length; k++) {
      for (let j = 0; j < skill_Type.length; j++) {
        if (
          this.TRFInfoData.jobskill[k] &&
          this.TRFInfoData.jobskill[k].skillCategory
        ) {
          if (
            skill_Type[j].skill_CategoryId ==
            this.TRFInfoData.jobskill[k].skillCategory
          ) {
            var idToRemove = skill_Type[j].skill_CategoryId;
            skill_Type = skill_Type.filter(
              (item) => item.skill_CategoryId !== idToRemove
            );
          }
        }
      }
    }
    //     if (
    //       this.TRFInfoData.jobskill[k] &&
    //       this.TRFInfoData.jobskill[k].skillCategory
    //     )
    //       if (
    //         skill_Type[j].skill_CategoryId ==
    //         this.TRFInfoData.jobskill[k].skillCategory
    //       )
    //         var idToRemove = skill_Type[j].skill_CategoryId;
    //     skill_Type = skill_Type.filter(
    //       (item) => item.skill_CategoryId !== idToRemove
    //     );
    //   }
    // }
    this.skill_list = [];

    let obj = {
      skillCategory: null,
      skills: null,
      skill_list: [],
    };
    this.TRFInfoData.jobskill.push(obj);
    this.skill_list = skill_Type;
  }
  deleteSkill(i) {
    this.TRFInfoData.jobskill.splice(i, 1);
  }

  // addTalentReq(){
  //   this.myService.addTalentReq().subscribe(res=>{
  //     this.addTalentReqArray = res;
  //   })
  // }
  trfBrandd() {
    this.myService.getBrandData().subscribe((res) => {
      this.addTalentReqArray = res;
    });
  }
  trfCountries() {
    this.myService.GetAllCountryDetails().subscribe((res) => {
      this.trfCountriess = res;
    });
  }
  trfCampuss() {
    this.myService.getCampusData().subscribe((res) => {
      this.trfCampuuss = res;
    });
  }
  trfJobTitleData() {
    this.candService.getJobTitleData().subscribe((res) => {
      this.trfJobTitleArray = res;
    });
  }

  trfSkillsCategorry() {
    this.myService.GetSkills_Category().subscribe((res) => {
      this.trfSkkillsCategory = res;
    });
  }
  // trfJobTittlee(){
  //   this.myService.addJobTitle().subscribe(res=>{
  //     this.trfJobTitleArrayy = res;
  //   })
  // }
  trfskills() {
    this.myService.GetJobNames().subscribe((res) => {
      this.trfSkillsss = res;
    });
  }
  trfRequesteerr() {
    this.jobService.GetUsersList().subscribe((res: any) => {
      var trfArray = [];
      for (let i = 0; i < res.length; i++) {
        if (res[i].userStatus == true) {
          trfArray.push(res[i]);
        }
        this.trfrequesterArray = trfArray;
      }
    });
  }

  trfTwoSubmitDisabled: boolean = false;
  // showLoader: boolean = false;
  createTrf() {
    // this.trfTwoSubmitDisabled = true;
    if (this.descriptionForm.invalid) {
      this.descriptionForm.form.markAllAsTouched();
      this.scrollToFirstInvalidControl();
    }
    else{
    if (this.talentRequisition.budOrNonBud) {
      let budgetArray = this.talentRequisition.budOrNonBud;
      let budget = budgetArray.toString();
      this.talentRequisition.budOrNonBud = budget;
    }
    if (this.talentRequisition.qualification) {
      this.talentRequisition.qualification =
        this.talentRequisition.qualification.toString();
    }
    if (this.talentRequisition.higherLevelOfEducation == null) {
      this.talentRequisition.higherLevelOfEducation = 0;
    } else {
      this.talentRequisition.higherLevelOfEducation =
        this.talentRequisition.higherLevelOfEducation;
    }
    // if (this.talentRequisition.brand) {
    //   let res = this.resp;
    //   for (let i = 0; i < res.length; i++) {
    //     this.talentRequisition.brand= res[i].brandId;
    //   }
    //   // this.basicInfooData.country = res.countryId;
    //   // this.basicInfooData.campus = res.campusId;
    // }
    // if (this.talentRequisition.jahJobTitleId) {
    //   let jobTitleArray = this.talentRequisition.jahJobTitleId;
    //   let jobTitle = jobTitleArray.toString();
    //   this.talentRequisition.jahJobTitleId = jobTitle;
    // }
    this.talentRequisition.jahId = this.talentRequisition.jahJobTitleId;
    if (this.talentRequisition.campus) {
      let campusArray = this.talentRequisition.campus;
      let campus = campusArray.toString();
      this.talentRequisition.campus = campus;
    }

    if (this.TRFInfoData.jobskill) {
      let jobsdata = this.TRFInfoData.jobskill;
      for (let i = 0; i < jobsdata.length; i++) {
        if (jobsdata[i].skills != null) {
          var skills = jobsdata[i].skills.toString();
        }

        jobsdata[i].skills = skills;
        if (jobsdata[i] && jobsdata[i].skill_list) {
          delete jobsdata[i].skill_list;
          // delete jobsdata[i].skills
        }
      }
      this.TRFInfoData.jobskill = jobsdata;
      this.trfJobDescription.trfSkills = this.TRFInfoData.jobskill;
    }
    let params = {
      talentRequisition: this.talentRequisition,
      trfJobDescription: this.trfJobDescription,
    };

    if (this.id) {
      params['status'] = 1;
    }
    if (!this.id) {
      params['status'] = 1;
    }
    if (this.id) {
      this.trfservice.createTrfForm(params).subscribe((res) => {
        this.showLoader = true;
        if (res) {
          this.showLoader = false;
          this.trfTwoSubmitDisabled = false;
          // this.router.navigateByUrl('/trf');
          this.notifyService.showSuccess(
            'Thank you !',
            'TRF is Updated Successfully'
          );
          this.basicInfoo.form.reset();
          this.descriptionForm.form.reset();
          // window.location.reload();
          this.router.navigateByUrl('/trf');
        }
      });
    }
    if (!this.id) {
      this.trfservice.createTrfForm(params).subscribe((res) => {
        this.showLoader = true;
        if (res) {
          setTimeout(() => {
            this.showLoader = false;
            this.isSubmitting = false;
            this.trfTwoSubmitDisabled = false;
            // this.router.navigateByUrl('/trf');
            this.notifyService.showSuccess(
              'Thank you !',
              'TRF is Created Successfully'
            );
            this.basicInfoo.form.reset();
            this.descriptionForm.form.reset();
            window.location.reload();
            // this.router.navigateByUrl('/trf');
          }, 3000);
        }
      });
    }
    }
    if (this.isSubmitting) return;

    this.isSubmitting = true;
  }
  // hierarchy_job:any
  // GetJobApprovalHierarchy(){
  //   this.jobService.GetJobApprovalHierarchy().subscribe((res:any)=>{
  //     this.hierarchy_job=res
  //   })
  // }
  tahId: any;
  countryTrf: any;
  resp: any;
  jobTrf(event: any) {
    this.talentRequisition.campus = [];
    this.talentRequisition.country = [];
    this.tahId = parseInt(event.jobApprovalHierarchyId);
    this.commonService.GetJahJobtitleData(this.tahId).subscribe((res: any) => {
      this.resp = res;
      for (let i = 0; i < res.length; i++) {
        this.talentRequisition.brand = res[i].brand;
        this.countryTrf = res[i].country;
      }
    });
  }
  jahCountryid: any;
  obj: any;
  finalArrayNew: any = [];
  trfCountry(event: any) {
    this.jahCountryid = event.countryIds;
    this.commonService
      .GetAllCountryDetailsById(this.jahCountryid)
      .subscribe((res) => {
        this.talentRequisition.ctcCode = res[0].currencyCode;
        this.talentRequisition.totalIncurredCostCode = res[0].currencyCode;
      });
    this.commonService.GetJahJobtitleData(this.tahId).subscribe((res: any) => {
      for (let i = 0; i < res.length; i++) {
        let value = res[i].campus;
        var finalArrayNew: any = [];
        for (let i = 0; i < value.length; i++) {
          let element = value[i].countryId;
          if (element == this.jahCountryid) {
            this.obj = {
              campusIds: value[i].campusIds,
              campusName: value[i].campusName,
            };

            finalArrayNew.push(this.obj);
          }
        }
        this.finalArrayNew = finalArrayNew;
      }
    });
  }
  uploadedresult: any;
  skill_list: any = [];
  GetCategoryType() {
    this.commonService.GetCategoryType(true).subscribe((res) => {
      this.uploadedresult = res;
      this.skill_list = this.uploadedresult.filter(
        (value: any, index: any, self: any) =>
          self.findIndex(
            (m: any) => m.skill_CategoryId === value.skill_CategoryId
          ) === index
      );
      // this.skill_list = res;
    });
  }
  SkillNameById(event: any, i: any) {
    let jobskills = this.TRFInfoData.jobskill;
    jobskills[i].skills = [];
    let skillid = event.skill_CategoryId || event;
    this.commonService.GetSkillNameById(skillid).subscribe(async (res) => {
      let jobSkill: any = this.TRFInfoData.jobskill;
      jobSkill[i].skill_list = res;
    });
  }
  categories: any;
  getCategories() {
    this.commonService.getCategories(true).subscribe((res: any) => {
      this.categories = res;
    });
  }
  hyring_Type: any;
  gethyringType() {
    this.commonService.gethyringType().subscribe((res) => {
      if (res) {
        this.hyring_Type = res;
      }
    });
  }
  employement_details: any;
  getEmployementType() {
    this.commonService.getEmployementType().subscribe((res) => {
      this.employement_details = res;
    });
  }

  brands: any;
  resVal: any = {};
  brandDetails() {
    this.commonService.getBrandData().subscribe((res) => {
      this.brands = res;
    });
  }

  brandsnewarray: any = [];
  GetBrandDetailsByUserId(val: any) {
    console.log(val, '===sind');
    this.talentRequisition.brand = [];
    this.talentRequisition.country = [];
    this.talentRequisition.campus = [];
    this.talentRequisition.jahJobTitleId = [];
    this.trfservice.GetBrandDetailsByUserId(val).subscribe((res) => {
      console.log(val, '===saaaaaind');
      this.brandsnewarray = res;
      this.resVal = res;
      // this.brandsnewarray.push(this.resVal);
    });
  }
  trf_Country: any;
  brandId: any;
  getcountries(event: any) {
    this.talentRequisition.country = [];
    this.talentRequisition.campus = [];
    this.talentRequisition.jahJobTitleId = [];

    this.trfservice.GetCountryCampusByBrandId(event).subscribe((country) => {
      // this.brandsnewarray = []
      // this.resVal = country
      this.trf_Country = country[0].country;
      console.log(this.trf_Country, 'Success');
    });
    // this.brandId = event.brandId
    // if (event != undefined) {
    //   this.trf_Country = event;
    // }
  }
  trfCampus: any;
  countryId: any;
  hierarchy_job: any = [];

  getCampus(event: any) {
    //alert(event)
    this.talentRequisition.campus = [];
    this.talentRequisition.jahJobTitleId = [];
    this.countryId = event;
    this.commonService
      .GetAllCountryDetailsById(this.countryId)
      .subscribe((res) => {
        // this.talentRequisition.ctcCode = res[0].currencyCode;
        this.talentRequisition.totalIncurredCostCode = res[0].currencyCode;
      });
    // this.trfservice.GetJahTitlebyCountry(this.countryId).subscribe((res)=>{
    //   this.hierarchy_job=res
    // })
    this.getTitle(this.countryId);
    this.commonService.GetcampusByid(this.countryId).subscribe((res) => {
      this.trfCampus = res;
    });
  }

  getTitle(countryId) {
    this.trfservice.GetJahTitlebyCountry(countryId).subscribe((res: any) => {
      console.log(res, 'titlejoblist');
      const element = [];
      // for (let i = 0; i < res.length; i++) {
      //   if (res[i].jobTitleName != null) {
      //     element.push(res[i]);
      //   }
      this.hierarchy_job = res;
      // }
      // if (this.id) {
      //   for (let i = 0; i < res.length; i++) {
      //     if (res[i].jahId==this.jadId) {
      //      element.push(res[i]);
      //     }
      //             this.hierarchy_job = element;

      //     console.log(element,"===============")
      //   }
      // }
    });
  }

  // GetBrandDetailsByUserId(){

  // }
  popupError: any;
  validateJobTitle(event: any) {
    var jahId = event.jahId;
    var jobTitle = event.jobTitleId;
    var brand = this.talentRequisition.brand;
    var campus = this.talentRequisition.campus;
    this.trfservice
      .ValidateJahTrfJobTitleOnCampus(brand, jobTitle, campus, jahId)
      .subscribe((res: any) => {
        this.validateTitle = res;
        var errormessage = [];
        for (let i = 0; i < res.length; i++) {
          this.title = res[i].jobTitleName;
          this.jahId = res[i].jahId;
          var element = res[i].campusName;
          errormessage.push(element);
        }
        if (res.length > 0) {
          this.titleError = this.title;
          this.jahIds = this.jahId;
          this.popupError = errormessage.toString();
          this.trfJobTitleMOdal = true;
        }
      });
    this.ValidateTrfJobtitleOnCampus(jobTitle, campus);
    this.GetJobInfoByJahIdBrandId(jahId,brand)
  }

  GetJobInfoByJahIdBrandId(jahId: any, brand: any) {
    this.trfservice.GetJobInfoByJahIdBrandId(jahId,brand).subscribe((res:any)=>{
      console.log(res,"==========================brand")
      for (let i = 0; i < res.length; i++) {
        this.talentRequisition.category= res[i].jobCategoryId;
        this.talentRequisition.totalExperienceFrom= res[i].totalExperienceFrom;
        this.talentRequisition.totalExperienceTo= res[i].totalExperienceTo;
        this.talentRequisition.higherLevelOfEducation= res[i].higherLevelOfEducationId;
        if (res[i].qualification) {
          let qualificationArray = res[i].qualification;
          if (qualificationArray.indexOf(',') > -1) {
            let arraydoc = qualificationArray.split(',');
            let numberArray = arraydoc.map(Number);
            this.talentRequisition.qualification = numberArray;
          } else {
            let singleDoc = qualificationArray.split(' ');
            let numberArray = singleDoc.map(Number);
            this.talentRequisition.qualification = numberArray;
          }
        }

        this.trfJobDescription.overview=res[i].overview
        this.trfJobDescription.responsibilities=res[i].responsibilities
        this.trfJobDescription.skillsDescription=res[i].skillsDescription
        var skType=res[i].jobskillsinfo
        var skilltype:any=[]
        for (let i = 0; i < skType.length; i++) {
          var obj={
            skillCategory: skType[i].skillCategoryId,
            skills: skType[i].skills
          }
          skilltype.push(obj)
        }
        this.TRFInfoData.jobskill=skilltype
        setTimeout(() => {
          if (this.TRFInfoData.jobskill) {
            let skills = this.TRFInfoData.jobskill;
            for (let i = 0; i < skills.length; i++) {
              this.GetSkillNameById(skills[i].skillCategory, i);
              let skillName = skills[i].skills;
              if (skillName.indexOf(',') > -1) {
                let arraydoc = skillName.split(',');
                let skillnumarray = arraydoc.map(Number);
                skills[i].skills = skillnumarray;
              } else {
                let singleDoc = skillName.split(' ');
                let skillnumarray = singleDoc.map(Number);
                skills[i].skills = skillnumarray;
              }
            }
          }
        }, 400);


      }
    })
  }

  errorjob: any;
  jobRes: any;
  errorJobTitleDisabled: boolean = false;
  ValidateTrfJobtitleOnCampus(jobTitle: any, campus: any) {
    this.trfservice
      .ValidateTrfJobtitleOnCampus(jobTitle, campus)
      .subscribe((res: any) => {
        this.jobRes = res;
        if (res) {
          this.errorjob = 'TR exist';
          this.errorJobTitleDisabled = true;
        } else {
          this.errorjob = '';
          this.errorJobTitleDisabled = false;
        }
      });
  }
  campusDetails(event) {
    this.talentRequisition.jahJobTitleId = [];
    this.errorjob = '';
  }
  openJobPopPUPP() {
    this.trfJobTitleMOdal = true;
  }
  CloseTrfJobTitlepopUP() {
    this.trfJobTitleMOdal = false;
    this.talentRequisition.jahJobTitleId = [];
    this.errorjob = '';
  }
  commaSeperated(data) {
    // let newLine = data.replace(/,/g, '\n')
    // return  newLine;
    var nameArr = data.split(',');
    var obj = nameArr;
    return obj;
  }

  fromyears = [
    { id: 1, name: '0' },
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11' },
    { id: 13, name: '12' },
    { id: 14, name: '13' },
    { id: 15, name: '14' },
    { id: 16, name: '15' },
    { id: 17, name: '16' },
    { id: 18, name: '17' },
    { id: 19, name: '18' },
    { id: 20, name: '19' },
    { id: 21, name: '20' },
    { id: 22, name: '21' },
    { id: 23, name: '22' },
    { id: 24, name: '23' },
    { id: 25, name: '24' },
    { id: 26, name: '25' },
    { id: 27, name: '26' },
    { id: 28, name: '27' },
    { id: 29, name: '28' },
    { id: 30, name: '29' },
    { id: 31, name: '30' },
    { id: 32, name: '31' },
    { id: 33, name: '32' },
    { id: 34, name: '33' },
    { id: 35, name: '34' },
    { id: 36, name: '35' },
    { id: 37, name: '36' },
    { id: 38, name: '37' },
    { id: 39, name: '38' },
    { id: 40, name: '39' },
    { id: 41, name: '40' },
    { id: 42, name: '41' },
    { id: 43, name: '42' },
    { id: 44, name: '43' },
    { id: 45, name: '44' },
    { id: 46, name: '45' },
    { id: 47, name: '46' },
    { id: 48, name: '47' },
    { id: 49, name: '48' },
    { id: 50, name: '49' },
  ];
  toyears = [
    { id: 1, name: '0' },
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11' },
    { id: 13, name: '12' },
    { id: 14, name: '13' },
    { id: 15, name: '14' },
    { id: 16, name: '15' },
    { id: 17, name: '16' },
    { id: 18, name: '17' },
    { id: 19, name: '18' },
    { id: 20, name: '19' },
    { id: 21, name: '20' },
    { id: 22, name: '21' },
    { id: 23, name: '22' },
    { id: 24, name: '23' },
    { id: 25, name: '24' },
    { id: 26, name: '25' },
    { id: 27, name: '26' },
    { id: 28, name: '27' },
    { id: 29, name: '28' },
    { id: 30, name: '29' },
    { id: 31, name: '30' },
    { id: 32, name: '31' },
    { id: 33, name: '32' },
    { id: 34, name: '33' },
    { id: 35, name: '34' },
    { id: 36, name: '35' },
    { id: 37, name: '36' },
    { id: 38, name: '37' },
    { id: 39, name: '38' },
    { id: 40, name: '39' },
    { id: 41, name: '40' },
    { id: 42, name: '41' },
    { id: 43, name: '42' },
    { id: 44, name: '43' },
    { id: 45, name: '44' },
    { id: 46, name: '45' },
    { id: 47, name: '46' },
    { id: 48, name: '47' },
    { id: 49, name: '48' },
    { id: 50, name: '49' },
  ];

  totoalExperienc(val: any, item) {
    this.toyears = [];
    if (val) {
      for (let i = 0; i < this.fromyears.length; i++) {
        let numberVal = +val.name;
        let perfVal = +this.fromyears[i].name;
        if (numberVal < perfVal) {
          this.toyears.push(this.fromyears[i]);
        } else {
          // this.preFExpP[i].isDisabled = false;
        }
      }
    }
    item.totalExperienceTo = null;
  }

  getDegree() {
    this.commonService.getDegree().subscribe((res) => {
      if (res) this.degree = res;
    });
  }
  getLevelOfEducation() {
    this.commonService.getLevelOfEducation().subscribe((res) => {
      this.Education_details = res;
    });
  }
  trfSalaryRangeError: boolean = false;
  validSalaryrange(e: any) {
    // console.log(e.target.value, "eventttttttttttttttttttttttt");
    var secInput = e.target.value
    if (parseInt(secInput) < this.talentRequisition.offerSalaryRangeFrom) {
      this.trfSalaryRangeError = true;
      // console.log("trueeeeeeeeeee");
    }  if (parseInt(secInput) > this.talentRequisition.offerSalaryRangeFrom) {
      // console.log("falseeeeeeeeeeee");
      this.trfSalaryRangeError = false;
    }
  }
  newSalaryValidation: boolean = false;
  newSalaryValidationTwo: boolean = false;
  showErrorExpectSalary: boolean = false;
  getValueTwo() {
    if (
      this.talentRequisition.offerSalaryRangeFrom >
      this.talentRequisition.offerSalaryRangeTo
    ) {
      this.showErrorExpectSalary = true;
    }
    if (
      this.talentRequisition.offerSalaryRangeFrom <
      this.talentRequisition.offerSalaryRangeTo
    ) {
      this.showErrorExpectSalary = false;
    }
  }

  minSalaryCountryValidation() {
    this.talentRequisition.offerSalaryRangeTo=''
    if (this.talentRequisition.ctcCode == 'INR') {
      if (this.talentRequisition.offerSalaryRangeFrom <= 50000) {
        this.newSalaryValidation = true;
        this.newSalaryValidationTwo = false;
      } else {
        this.newSalaryValidation = false;
        this.newSalaryValidationTwo = false;
      }

      // console.log("1");
    }
    if (this.talentRequisition.ctcCode == 'USD') {
      if (this.talentRequisition.offerSalaryRangeFrom <= 5000) {
        this.newSalaryValidation = false;
        this.newSalaryValidationTwo = true;
      } else {
        this.newSalaryValidation = false;
        this.newSalaryValidationTwo = false;
      }
      // console.log("2");
    }
    if (this.talentRequisition.ctcCode == 'SGD') {
      if (this.talentRequisition.offerSalaryRangeFrom <= 5000) {
        this.newSalaryValidation = false;
        this.newSalaryValidationTwo = true;
      } else {
        this.newSalaryValidation = false;
        this.newSalaryValidationTwo = false;
      }
      // console.log("3");
    }

    // console.log(this.basicInfooData.expectedSalarySymbol, 'mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm');
  }
  newCurrenyCodeArray:any=[]
  newCurrenyArray:any=[]
  getCurrencyCodes() {
    this.candService.GetCandidateCountryList().subscribe((res: any) => {
      // this.newCurrenyCodeArray=res
      // this.newCurrenyCodeArray = res.filter(country => country.currencyCode === res);
      this.newCurrenyArray=res
      this.newCurrenyCodeArray = this.newCurrenyArray.filter(
        (value: any, index: any, self: any) =>
          self.findIndex(
            (m: any) => m.currencyCode === value.currencyCode
          ) === index
      );

    })
  }
  codeInfo:any
  clearSalaryinputFields(){
    this.talentRequisition.offerSalaryRangeFrom = '';
    this.talentRequisition.offerSalaryRangeTo = '';
    this.showErrorExpectSalary = false;
  }
  toggleModal(trid:any){
    this.myModal = true;
    this.trfservice.gettrApproverList(trid).subscribe((res:any)=>{
      this.approverData=res
    })
  }
  closepopup(){
    this.myModal = false;
  }

  setActiveTab(tab: string) {
    this.navLinkActive = tab;
    sessionStorage.setItem('navLinkActive', tab);
    this.getDataOfTrf(this.getTabIndex(tab));
  }
  getTabIndex(tab: string): number {
    switch (tab) {
      case 'pending':
        return 0;
      case 'accepted':
        return 1;
      case 'rejected':
        return 2;
      case 'expired':
        return 3;
      case 'jobRejected':
        return 4;
        case 'jobClosed':
        return 5;
      default:
        return 0;
    }
  }

  selectedRole = 'HR';
  tabsButtonDisabled = false;
  setRole(role: string): void {
    this.selectedRole = role;
    this.onRoleChange();
  }

  onRoleChange(): void {
    if (this.selectedRole === 'HR') {
      setTimeout(() => {
        this.router.navigate(['/trf']);
      }, 2000);
    } else if (this.selectedRole === 'Manager') {
      this.router.navigate(['/tr-by-managers']);
    }
  }

  dataType(data: string): string {
    return data.replace(/<br\s*\/?>/gi, '\n');
  }
}
