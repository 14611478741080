import {
  Component,
  Renderer2,
  ElementRef,
  ViewChildren,
  QueryList,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { IJPService } from 'src/app/services/IJP.service';
import { userService } from 'src/app/services/userservice';
import { candidateService } from 'src/app/services/candidateService';

import { MessageService } from 'primeng/api';
import * as QRCode from 'qrcode';
import * as moment from 'moment';

interface PageEvent {
  first: number;
  rows: number;
  page: number;
  pageCount: number;
}
@Component({
  selector: 'app-job-listing',
  templateUrl: './job-listing.component.html',
  styleUrls: ['./job-listing.component.scss'],
  providers: [MessageService],
})
export class JobListingComponent {
  first: number = 0;

  closeJobtextArea: any;

  rows: number = 10;
  IjpJobStatus: any = 0;
  token: any;
  data: any;
  selectedPage: any;
  selectedTab: any;
  IJPBasicInformationId: any;
  ijpBasicInformationId: any;
  approvedStatus: any;
  status: any;
  x: any;

  arraymy: any = [];
  requarray: any = [];
  managePublishStartDate: any = [];
  managePublishEndDate: any = [];
  ijpJobPostedData: any = [];

  constructor(
    private IJPService: IJPService,
    private router: Router,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    public candidateService: candidateService
  ) {}
  onPageChange(event: PageEvent) {
    this.first = event.first;
    // console.log('0000000000000', this.first);

    this.rows = event.rows;
    // console.log('event', event);
    this.selectedPage = event.page;
    this.getListDetails(this.selectedTab);
  }

  UserId: any;
  informationId: any;
  approvalStatus: any;
  statuss: any;
  pageNO: number = 0;
  NoOfObjectsPerPage: number = 0;
  totalCount: number = 0;
  dataSource: any[] = [];
  filteredData: any[] = [];
  searchItem: any;
  Reason: any;
  activeTab: any;
  obj: any = {};
  selectedTabb: any;
  lastSegment: any;
  jobName: string = '';
  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.data = jwtDecode(this.token);
    this.UserId = this.data.UserId;
    // console.log('this.UserId', this.UserId);

    this.rows = 10;
    // console.log('event', event);
    this.selectedPage = 0;

    // console.log(this.data, '=====================');
    var url = window.location.href;
    this.lastSegment = url.split('/').pop();
    if (this.lastSegment == 'job-listing') {
      this.getListDetails('Active');
      this.route.queryParams.subscribe((params) => {
        this.activeTab = params['tab'] || 'Active'; // Default to 'All' if no tab is specified
        this.getListDetails(this.activeTab); // Fetch data initially based on the active tab
      });
      this.selectedTabb = localStorage.getItem('selectedTabb') || 'active';

      this.getListDetails(this.selectedTab);
      this.GetIJPJobDetailsListingCount();
    } else {
      this.route.queryParams.subscribe((params) => {
        this.activeTab = params['tab'] || 'Active'; // Default to 'All' if no tab is specified
        this.getListDetails(this.activeTab); // Fetch data initially based on the active tab
      });
      this.activeTab = this.activeTab;
      this.getListDetails(this.activeTab);
      this.selectedTabb = this.activeTab;

      this.getListDetails(this.selectedTab);
      this.GetIJPJobDetailsListingCount();
    }

    // this.obj = { page: 0, rows: 10, first1: 10 };
    // this.onPageChange(this.obj);

    // this.resetPagination();
  }

  // resetPagination() {
  //   this.first = 0;
  // }

  selectTab(tabName: string) {
    this.selectedTabb = tabName;
    localStorage.setItem('selectedTabb', tabName);
  }
  getTabListDetails(tab: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab },
      queryParamsHandling: 'merge',
    });
  }

  jobLists: any = [];
  postjobtoId: any = [];
  publishid: any;

  @ViewChildren('kebabMenu') kebabMenus!: QueryList<ElementRef>;

  private globalClickListener: (() => void) | undefined;

  ngAfterViewInit(): void {
    this.globalClickListener = this.renderer.listen(
      'document',
      'click',
      (event) => this.onDocumentClick(event)
    );
  }

  ngOnDestroy(): void {
    if (this.globalClickListener) {
      this.globalClickListener();
    }
  }

  toggleKebabMenu(row: any, event: Event): void {
    event.stopPropagation(); // Prevent the event from propagating to the document

    // Close all other kebab menus
    this.jobLists.forEach((r: any) => {
      if (r !== row) {
        r.showKebabMenu = false;
      }
    });
    row.showKebabMenu = !row.showKebabMenu;
  }

  hideKebabMenu(row: any): void {
    row.showKebabMenu = false;
  }

  private onDocumentClick(event: Event): void {
    let clickedInside = false;

    this.kebabMenus.forEach((menu) => {
      if (menu.nativeElement.contains(event.target)) {
        clickedInside = true;
      }
    });

    if (!clickedInside) {
      this.jobLists.forEach((row: any) => (row.showKebabMenu = false));
    }
  }

  postDates: any = [];
  publishstartdate = new Date();
  publishenddate: any;
  ijpJobPostedData1: any = [];
  postjobto: any;
  selectedates: any = [];
  getDate(id: any) {
    this.postDates = [];
    for (let n = 0; n < this.jobInfo.length; n++) {
      // console.log(this.jobInfo, '==this.jobInfo[n].ijpBasicInformationId', id);

      if (this.jobInfo[n].ijpBasicInformationId == id) {
        this.publishid = this.jobInfo[n].ijpBasicInformationId;
        this.ijpJobPostedData1 = this.jobInfo[n].ijpJobPostedData;
        // console.log(this.ijpJobPostedData1, '===s');

        for (let i = 0; i < this.ijpJobPostedData1.length; i++) {
          var obj = {
            publishstartdate: new Date(
              this.ijpJobPostedData1[i].publishstartdate
            ),
            publishenddate: new Date(this.ijpJobPostedData1[i].publishenddate),
            postJobStatus: this.ijpJobPostedData1[i].postJobStatus,
            postjobto: this.ijpJobPostedData1[i].postjobto,
            brand_IJPJobId: this.jobInfo[n].brand_IJPJobId,
            jobTitleName: this.jobInfo[n].jobTitleName,
            id: this.ijpJobPostedData1[i].id,
            ijpBasicInformationId: this.jobInfo[n].ijpBasicInformationId,
          };
          this.postDates.push(obj);
        }
        // console.log(this.postDates, '==this.postDates');
        // if(this.ijpJobPostedData1.length>0)
        //   {
        //     for(let k=0;k<this.ijpJobPostedData1.length;k++)
        //       {
        //        if(this.ijpJobPostedData1[k].postjobto=='IJP')
        //          {
        //                this.managePublishStartDate = new Date(this.ijpJobPostedData1[k].publishstartdate)
        //                console.log(this.managePublishStartDate,'==this.managePublishStartDate')
        //                this.managePublishEndDate = new Date(this.ijpJobPostedData1[k].publishenddate)
        //                this.postjobto = this.ijpJobPostedData1[k].postjobto
        //                this.postjobtoId = this.ijpJobPostedData1[k].id
        //          }
        //       }
        //   }
      }
    }
  }

  // convert(str:any) {
  //   var date = new Date(str),
  //     mnth = ('0' + (date.getMonth() + 1)).slice(-2),
  //     day = ('0' + date.getDate()).slice(-2);
  //   return [mnth, day, date.getFullYear()].join('/');
  // }

  value: any;
  enableSave: boolean = false;
  editEndDate(i: any, type: any, id: any) {
    this.value = i;
    if (type == 'edit') {
      this.enableSave = true;
      this.enableEdit = false;
    } else {
      this.getDate(id);
      this.enableSave = false;
      this.enableEdit = true;
    }
  }

  updateEndDate(id: any, endDate: any) {
    var enddatevalue = moment(endDate).format('YYYY-MM-DD');
    //  console.log(enddatevalue)
    this.IJPService.updateEndDate(id, enddatevalue).subscribe((res: any) => {
      this.value = this.value + 1;
      this.getListDetails('Active');
      this.enableSave = false;
      this.enableEdit = true;
      this.disableEndDate = true;
    });
  }
  ijpAllCount: any;
  GetIJPJobDetailsListingCount() {
    this.IJPService.GetIJPJobDetailsListingCount(this.UserId).subscribe(
      (res: any) => {
        this.ijpAllCount = res;
      }
    );
  }
  disableEndDate: boolean = true;
  enableEdit: boolean = true;
  publishJobpostjobtoid: any;
  publishJob(
    postjobtoid: any,
    managePublishStartDate: any,
    managePublishEndDate: any,
    status: any,
    ijpInfoId: any,
    reason: any
  ) {
    // alert('1');
    // this.publishJobpostjobtoid = postjobtoid;
    this.IJPService.PublishUnpublish(
      ijpInfoId,
      status,
      postjobtoid,
      reason
    ).subscribe((res: any) => {
      if (res) {
        this.closeJobtextArea = '';
        let messageDetail =
          status === 'Published'
            ? 'Job Published Successfully'
            : 'Job Unpublished Successfully';
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: messageDetail,
        });
      }
      this.getListDetails('Active');

      // window.location.reload()
    });
    // alert('3');
    // console.log(this.publishJobpostjobtoid);
  }
  IJPAll: boolean = true;
  IJPActive: boolean = false;
  IJPApproved: boolean = false;
  IJPDrafts: boolean = false;
  IJPPending: boolean = false;
  IJPRejected: boolean = false;
  IJPClosed: boolean = false;
  noTabData: boolean = false;
  acceptRejectModal: boolean = false;
  disableNext: boolean = false;
  loading: boolean = true;
  jobInfo: any = [];
  jobListingLoader: boolean = false;

  getListDetails(type: any) {
    if (type != undefined) {
      this.jobLists = [];
      this.selectedTab = type;
      this.jobListingLoader = true;
      if (type == 'All') {
        this.IjpJobStatus = 95;
        this.IJPAll = true;
        this.IJPActive = false;
        this.IJPApproved = false;
        this.IJPDrafts = false;
        this.IJPPending = false;
        this.IJPRejected = false;
        this.IJPClosed = false;
        this.noTabData = false;
        this.acceptRejectModal = false;
      } else if (type == 'Active') {
        this.IjpJobStatus = 96;
        this.IJPAll = false;
        this.IJPActive = true;
        this.IJPApproved = false;
        this.IJPDrafts = false;
        this.IJPPending = false;
        this.IJPRejected = false;
        this.IJPClosed = false;
        this.noTabData = false;
        this.acceptRejectModal = false;
      } else if (type == 'Approved') {
        this.IjpJobStatus = 97;
        this.IJPAll = false;
        this.IJPActive = false;
        this.IJPApproved = true;
        this.IJPDrafts = false;
        this.IJPPending = false;
        this.IJPRejected = false;
        this.IJPClosed = false;
        this.noTabData = false;
        this.acceptRejectModal = false;
      }
      //  else if (type == 'Drafts') {
      //   this.IjpJobStatus = 0;
      //   this.IJPAll = false;
      //   this.IJPActive = false;
      //   this.IJPApproved = false;
      //   this.IJPDrafts = true;
      //   this.IJPPending = false;
      //   this.IJPRejected = false;
      //   this.IJPClosed = false;
      //   this.noTabData = false;
      //   this.acceptRejectModal = false;
      // }
      else if (type == 'Pending') {
        this.IjpJobStatus = 98;
        this.IJPAll = false;
        this.IJPActive = false;
        this.IJPApproved = false;
        this.IJPDrafts = false;
        this.IJPPending = true;
        this.IJPRejected = false;
        this.IJPClosed = false;
        this.noTabData = true;
        this.acceptRejectModal = true;
      } else if (type == 'Rejected') {
        this.IjpJobStatus = 99;
        this.IJPAll = false;
        this.IJPActive = false;
        this.IJPApproved = false;
        this.IJPDrafts = false;
        this.IJPPending = false;
        this.IJPRejected = true;
        this.IJPClosed = false;
        this.noTabData = true;
        this.acceptRejectModal = false;
      } else if (type == 'Closed') {
        this.IjpJobStatus = 100;
        this.IJPAll = false;
        this.IJPActive = false;
        this.IJPApproved = false;
        this.IJPDrafts = false;
        this.IJPPending = false;
        this.IJPRejected = false;
        this.IJPClosed = true;
        this.noTabData = true;
        this.acceptRejectModal = false;
      }

      this.NoOfObjectsPerPage = this.rows;
      // console.log("type", type);
      this.IJPService.GetAllJobId(
        this.IjpJobStatus,
        this.UserId,
        this.selectedPage,
        this.NoOfObjectsPerPage
      ).subscribe((res: any) => {
        this.jobInfo = res;
        if (res) {
          this.jobListingLoader = false;
        }
        if (res.length > 0) {
          this.loading = false;
          this.jobLists = res;

          this.totalCount = res[0].totalCount;
          // console.log(res,'res.length',this.NoOfObjectsPerPage)
          if (this.totalCount >= this.NoOfObjectsPerPage) {
            this.disableNext = false;
          } else {
            this.disableNext = true;
          }
        } else {
          this.loading = false;
        }

        //   {
        //     "page": 3,
        //     "first": 30,
        //     "rows": 10,
        //     "pageCount": 12
        // }
      });
    }
  }

  visible: boolean = false;
  approvalstatus: any;
  statusInfo: any;
  id: any;
  Status: any;

  showDialog(id: any, ijpJobApprovedStatus: any, status: any) {
    this.id = id;
    this.approvalstatus = ijpJobApprovedStatus;
    this.statusInfo = status;
    this.visible = true;
  }
  applySearchFilter(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const searchValue = inputElement.value.toLowerCase();

    // Implement your filter logic here
    this.filteredData = this.dataSource.filter((item) =>
      item.name.toLowerCase().includes(searchValue)
    );
  }

  getapproval = [];
  GetIJPApproval(informationId: any, approvalStatus: any, statuss: any) {
    this.IJPService.GetIJPApproval(
      informationId,
      approvalStatus,
      statuss
    ).subscribe((res: any) => {
      this.getapproval = res;
      this.getListDetails('Pending');
    });
  }

  getRejectDetails = [];
  GetIJPReject(informationId: any, Reason: any, statuss: any) {
    this.IJPService.GetIJPReject(informationId, Reason, statuss).subscribe(
      (res: any) => {
        this.getRejectDetails = res;
        this.getListDetails('Rejected');
        // console.log('GetIJPReject', res);
      }
    );
  }

  GetSearch(
    UserId: any,
    Status: any,
    pageNO: any,
    NoOfObjectsPerPage: any,
    searchItem: any
  ): void {
    this.IJPService.GetSearch(
      UserId,
      Status,
      pageNO,
      NoOfObjectsPerPage,
      searchItem
    ).subscribe((res: any) => {
      this.GetSearch = res;
      // console.log('getSearch', res);
    });
  }

  getReacticate = [];
  Reactivate(informationId: any, Reason: any, statuss: any) {
    this.IJPService.Reactivate(informationId, Reason, statuss).subscribe(
      (res: any) => {
        this.getReacticate = res;
        this.getListDetails('Pending');
        // console.log('Reactivate', res);
      }
    );
  }

  text: any;
  onSearchChange(
    UserId: any,
    pageNO: any,
    NoOfObjectsPerPage: any,
    searchItem: any
  ): void {
    var Status;
    if (this.IJPAll == true) {
      Status = '95';
    } else if (this.IJPActive == true) {
      Status = '96';
    } else if (this.IJPApproved == true) {
      Status = '97';
    } else if (this.IJPDrafts == true) {
      Status = '0';
    } else if (this.IJPPending == true) {
      Status = '98';
    } else if (this.IJPRejected == true) {
      Status = '99';
    }

    var obj = {
      userId: UserId,
      type: 'IJP',
      pageNumber: pageNO,
      numberOfObjectsPerPage: NoOfObjectsPerPage,
      searchItem: searchItem,
    };

    // this.IJPService.GetIJP_ReferralJobDetails(obj).subscribe((res: any) => {

    this.IJPService.GetSearch(
      UserId,
      Status,
      pageNO,
      NoOfObjectsPerPage,
      searchItem
    ).subscribe((res: any) => {
      this.jobLists = res;

      // console.log('getSearch', res);
    });
  }

  commaSeperated(data: any) {
    // let newLine = data.replace(/,/g, '\n')
    // return  newLine;
    if (data) {
      var nameArr = data.split(',');
      var obj = nameArr;
      return obj;
    }
  }

  stageListArray: any = [
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
    // { stageName : "stageName", recurtingMember :'recurtingMember' },
  ];

  jdteamArray: any = [
    { approverListId: 1, userName: 'userName' },
    { approverListId: 1, userName: 'userName' },
    { approverListId: 1, userName: 'userName' },
  ];

  getStagedata(data: any) {
    let array = data.split(',').join('\n');
    return array;
  }

  GetIJPJobApproversPopUpData(IJPBasicInformationId: any) {
    this.IJPService.GetIJPJobApproversPopUpData(
      IJPBasicInformationId
    ).subscribe((res: any) => {
      // this.getListDetails('IJPBasicInformationId')
      this.arraymy = res.iJPJobApproverInfos;
      this.jobName = res.jobName;
      // console.log(res, 'GetIJPJobApproversPopUpData..............');
    });
  }

  GetIJPJobRecruitmentMembersPopUp(IJPBasicInformationId: any) {
    this.candidateService
      .GetHiringStageHiringMembernew(IJPBasicInformationId)
      .subscribe((res: any) => {
        this.requarray = res.hiringStageHiringName;
        // console.log(res, 'nnnnnnnnnnnnnnnnnnnn');

        // console.log("GetIJPJobRecruitmentMembersPopUp",res);
      });
  }

  EditIJP(IJPBasicInformationId: any) {
    this.router.navigateByUrl('/edit-job-post/' + IJPBasicInformationId);

    // this.router.navigate(['/dashboard']);

    // this.IJPService.EditIJP(IJPBasicInformationId).subscribe((res:any)=>{
    //   document.getElementById(`dots-info${id}`).style.display = display;

    //   console.log("++++++EditIJP",res);

    // })
  }

  clearSearch() {
    this.searchItem = null;
    this.getListDetails(this.selectedTab);
  }

  sortColumn: string = '';
  sortDirection: boolean = true;

  sortTable(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortColumn = column;
      this.sortDirection = true;
    }

    this.jobLists.sort((a: any, b: any) => {
      let valueA = a[column];
      let valueB = b[column];

      if (typeof valueA === 'string') {
        valueA = valueA.toUpperCase();
        valueB = valueB.toUpperCase();
      }

      let result = 0;
      if (valueA < valueB) {
        result = -1;
      } else if (valueA > valueB) {
        result = 1;
      }
      return this.sortDirection ? result : -result;
    });
  }

  redirectedToDetailsPage(ijpBasicInformationId: any) {
    // window.location.href = '/job-details-page/' + ijpBasicInformationId;
    this.router.navigateByUrl('/job-details-page/' + ijpBasicInformationId);
  }
  datesvisible: boolean = false;

  showDatesDialog() {
    this.datesvisible = true;
  }

  copyJobTitle: any;
  copyJobID: any;
  openCopyModal(row: any, jobTitle: any, jobID: any) {
    row.showKebabMenu = false;
    this.ijpJobPostedData = [];
    for (let i = 0; i < row.ijpJobPostedData.length; i++) {
      this.ijpJobPostedData.push(row.ijpJobPostedData[i]);
    }
    this.generateQrCodes();
    this.copyJobTitle = jobTitle;
    this.copyJobID = jobID;
  }

  // copyLinkVisiblee : boolean = false;
  // openCopyLinkModal(){
  //   this.copyLinkVisiblee = true;
  // }

  copyText(data: any): void {
    data.select();
    document.execCommand('copy');
    data.setSelectionRange(0, 0);

    // this.notifyService.showSuccess('Link copied', `Job ${this.jobIdInfo}`);
    // console.log('Button clicked!');  // Log when the button is clicked

    // if (!inputElement.value) {
    //   console.warn('Input is empty!');
    //   return;
    // }

    // inputElement.select();
    // inputElement.setSelectionRange(0, 99999);

    // // navigator.clipboard.writeText(inputElement.value)
    // //   .then(() => {
    // //     console.log('Text copied: ', inputElement.value);  // Log the copied text
    // //     alert('Text copied');
    // //   })
    // //   .catch(err => {
    // //     console.error('Failed to copy text: ', err);
    // //   });
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Link Copied Successfully',
    });
  }

  qrCodes: any = [];
  async generateQrCodes() {
    this.qrCodes = [];
    for (const linkObj of this.ijpJobPostedData) {
      // const qrCode = await QRCode.toDataURL(linkObj.link);
      // this.qrCodes.push(qrCode);
      if (linkObj.postJobLink !== null) {
        const qrCode = await QRCode.toDataURL(linkObj.postJobLink);
        this.qrCodes.push(qrCode);
      } else {
        // If the link is null, push null to maintain the same number of items
        this.qrCodes.push(null);
      }
    }
  }
  downloadQRCodes(qrCode: string, fileName: string) {
    const link = document.createElement('a');
    link.href = qrCode;
    link.download = `${fileName}_qrcode.png`;
    link.click();
  }

  // PublishUnpublish(iJPBasicInformationId:any ){
  //   this.IJPService.PublishUnpublish(iJPBasicInformationId,'publish' ).subscribe((res:any)=>{
  //     console.log("publishhhhhhhh",res);

  //   })
  // }

  // documentClickListener : any;

  // onDocumentClick(event: Event): void {
  //   const targetElement = event.target as HTMLElement;

  //   // Check if the click is outside the kebab menu
  //   const clickedInside = targetElement.closest('.all__kebab__menuu') || targetElement.closest('.pointer');
  //   if (!clickedInside) {
  //     // this.hideAllKebabMenu();
  //   }
  // }

  // ngOnDestroy(): void {
  //   // Remove the document click listener when the component is destroyed
  //   if (this.documentClickListener) {
  //     this.documentClickListener();
  //   }
  // }

  closeModalJobTitle: any;
  closeJobTitle(data: any, data2: any) {
    this.closeModalJobTitle = data;
    this.publishJobpostjobtoid = data2;
  }

  dataType(data: string): string {
    return data.replace(/<br\s*\/?>/gi, '\n');
  }
}
