import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { StepperService } from '../../../services/stepper.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  items: MenuItem[] = [];
  activeIndex: number = 0;
  completedSteps: number[] = [];

  constructor(private stepperService: StepperService) {}

  ngOnInit() {
    this.activeIndex = 0;

    this.stepperService.activeIndex$.subscribe(index => {
      this.activeIndex = index;
    });

    this.items = [
      {
        label: 'Basic Information',
        routerLink: ['/create-job-post']
      },
      {
        label: 'Job Description',
        routerLink: ['/job-post-description']
      },
      {
        label: 'Job Approvers',
        routerLink: ['/create-job-approver']
      },
      {
        label: 'Hiring Teams',
        routerLink: ['/create-job-hiringteam']
      }
    ];
  }

  onStepClick(index: number) {
    this.stepperService.setActiveIndex(index);
  }

  markStepAsCompleted(index: number) {
    if (!this.completedSteps.includes(index)) {
      this.completedSteps.push(index);
    }
  }

  isStepCompleted(index: number): boolean {
    return this.completedSteps.includes(index);
  }
}
