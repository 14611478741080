import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HariTestComponent } from './hari-test/hari-test.component';
// import { LoginComponent } from './auth/Login/login.component';
import { AuthGuard } from './services/auth.gurd';
import { DashbordlayoutComponent } from './dashboard/dashbordlayout/dashbordlayout.component';
import { SystemFieldsComponent } from './system-fields/system-fields.component';
import { ReportsComponent } from './reports/reports.component';
import { ChartsComponent } from './charts/charts.component';
import { TrfFormComponent } from './trf-form/trf-form.component';
import { TrfFormTwoComponent } from './trf-form-two/trf-form-two.component';
import { ScheduleLoginComponent } from './auth/schedule-login/schedule-login.component';
import { resetComponent } from './resetpassword/resetpassword.component';
// import { JobListingComponent } from '../app/components/job-listing/job-listing.component';

import { CreateJobPostComponent } from './components/create-job-post/create-job-post.component';

import { JobListingComponent } from './components/job-listing/job-listing.component';
import { SideBodyComponent } from './components/side-body/side-body.component';
import { JobDescriptionComponent } from './components/side-body-components/job-description/job-description.component';
import { BasicJobDetailsComponent } from './components/side-body-components/basic-job-details/basic-job-details.component';
import { JobApproversComponent } from './components/side-body-components/job-approvers/job-approvers.component';
import { HiringTeamComponent } from './components/side-body-components/hiring-team/hiring-team.component';
// import { DashboardComponent } from './components/side-body-components/dashboard-reports/dashboard.component';
import { JobPostDescriptionComponent } from './components/job-post-description/job-post-description.component';
import { CreateJobApproverComponent } from './components/create-job-approver/create-job-approver.component';
import { CreateJobHiringteamComponent } from './components/create-job-hiringteam/create-job-hiringteam.component';
import { LoginComponent } from './components/login/login.component';
import { Dashboard2Component } from './components/side-body-components/dashboard2/dashboard2.component';
import { ReferalJobDescriptionComponent } from './components/referal-job-description/referal-job-description.component';
import { ReferalFriendFormComponent } from './components/referal-friend-form/referal-friend-form.component';
import { ExternalReferrerDetailsComponent } from './components/external-referrer-details/external-referrer-details.component';
import { InternalStaffSharedApplicationComponent } from './components/internal-staff-shared-application/internal-staff-shared-application.component';
import { InternalJobPostingComponent } from './components/internal-job-posting/internal-job-posting.component';
import { CreateJobLayoutComponent } from './components/create-job-layout/create-job-layout.component';
import { ReferralComponent } from './components/referral/referral.component';
import { JobDetailsPageComponent } from './components/job-details-page/job-details-page.component';
import { DummyChartsComponent } from './dummy-charts/dummy-charts.component';
import { NewApplicantComponent } from './components/new-applicant/new-applicant.component';
import { DashboardReportsComponent } from './components/side-body-components/dashboard-reports/dashboard.component';
import { IjpEmployeeComponent } from './components/ijp-employee/ijp-employee.component';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'dashboard', component: DashboardComponent, },
  { path: 'interview-login', component: ScheduleLoginComponent },
  {
    path: 'job-details',
    component: SideBodyComponent,
    children: [
      { path: '', redirectTo: 'basic-information', pathMatch: 'full' },
      { path: 'basic-information', component: BasicJobDetailsComponent },
      { path: 'job-description', component: JobDescriptionComponent },
      { path: 'job-approvers', component: JobApproversComponent },
      { path: 'hiring-team', component: HiringTeamComponent },
    ],
  },
  // { path: 'dashboard', component: DashboardComponent, title: 'Dashboard' },
  // { path: 'dashboard', component: DashboardReportsComponent, title: 'Dashboard' },
  // { path: 'dashboard2', component: Dashboard2Component, title: 'Dashboard2' },
  // {
  //   path: 'job-listing',
  //   component: JobListingComponent,
  //   title: 'Job Listings',
  // },
  {
    path: 'candidates/login',
    redirectTo: 'candidates/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'candidates/login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: DashbordlayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'reset',
    loadChildren: () =>
      import('./resetpassword/resetpassword.module').then((m) => m.resetModule),
  },
  {
    path: 'no-access',
    loadChildren: () =>
      import('./no-access/no-access.module').then((m) => m.NoAccessModule),
  },
  {
    path: 'candidatereset',
    loadChildren: () =>
      import('./resetpassword/resetpassword.module').then((m) => m.resetModule),
  },

  //Wild Card Route for 404 request
  // {
  //   path: '**', pathMatch: 'full',
  //   component: PagenotfoundComponent
  // },

  // { path: 'Test', component: HariTestComponent },
  {
    path: 'components',
    loadChildren: () =>
      import('./schedule/schedule.module').then((m) => m.ScheduleModule),
  },
 

  // { path: 'charts', component: ChartsComponent },
  // { path: 'trf-form', component: TrfFormComponent },
  // // { path: 'trf-form-two', component: TrfFormTwoComponent },
  // { path: 'edit-trf/:id', component: TrfFormTwoComponent },
  // { path: 'view-trf/:id', component: TrfFormTwoComponent },
  // { path: 'reset-password', component: resetComponent },
  // { path: 'reset-password/:id', component: resetComponent },
  // {
  //   path: 'create-job-hiringteam',
  //   component: CreateJobHiringteamComponent,
  // },
  // {
  //   path: 'referal-job/:id',
  //   component: ReferalJobDescriptionComponent,
  // },
  // {
  //   path: 'new-applicant-form',
  //   component: NewApplicantComponent,
  // },
  // {
  //   path: 'referal-form/:id',
  //   component: ReferalFriendFormComponent,
  // },
  // {
  //   path: 'external-referrer',
  //   component: ExternalReferrerDetailsComponent,
  // },
  // {
  //   path: 'internal-shared-new-application',
  //   component: InternalStaffSharedApplicationComponent,
  // },
  // {

  // {
  //   path: 'internal-job-posting',
  //   component: InternalJobPostingComponent,
  // },
  // {
  //   path: 'create-job-layout',
  //   component: CreateJobLayoutComponent,
  // },
  // {
  //   path: 'referral',
  //   component: ReferralComponent,
  // },
  // {
  //   path: 'edit-job-post/:id',
  //   component: CreateJobPostComponent,
  // },
  // {
  //   path: 'job-details-page/:id',
  //   component: JobDetailsPageComponent,
  // },
  // {
  //   path: 'chart',
  //   component: DummyChartsComponent,
  // },
  //   {
  //   path: 'create-job-post',
  //   component: CreateJobPostComponent,
  // },


  {
    path: 'create-job-hiringteam',
    component: CreateJobHiringteamComponent,
  },
  {
    path: 'referal-job/:id',
    component: ReferalJobDescriptionComponent,
  },
  {
    path: 'new-applicant-form',
    component: NewApplicantComponent,
  },
  {
    path: 'referal-form/:id',
    component: ReferalFriendFormComponent,
  },
  {
    path: 'external-referrer',
    component: ExternalReferrerDetailsComponent,
  },
  {
    path: 'internal-shared-new-application',
    component: InternalStaffSharedApplicationComponent,
  },

  {
    path: 'internal-job-posting',
    component: InternalJobPostingComponent,
  },
  {
    path: 'create-job-layout',
    component: CreateJobLayoutComponent,
  },
  {
    path: 'referral/:id',
    component: ReferralComponent,
  },
  {
    path: 'edit-job-post/:id',
    component: CreateJobPostComponent,
  },
  {
    path: 'job-details-page/:id',
    component: JobDetailsPageComponent,
  },
  {
    path: 'chart',
    component: DummyChartsComponent,
  },
  // {
  //   path: 'ijp-employee',
  //   component: IjpEmployeeComponent, title: 'IJP Employee'
  // },
];

// @NgModule({
//   declarations: [],
//   imports: [CommonModule, RouterModule.forRoot(routes)],
// import { NgModule } from '@angular/core';
// import { RouterModule, Routes } from '@angular/router';
// import { JobListingComponent } from './components/job-listing/job-listing.component';
// import { SideBodyComponent } from './components/side-body/side-body.component';
// import { JobDescriptionComponent } from './components/side-body-components/job-description/job-description.component';
// import { BasicJobDetailsComponent } from './components/side-body-components/basic-job-details/basic-job-details.component';
// import { JobApproversComponent } from './components/side-body-components/job-approvers/job-approvers.component';
// import { HiringTeamComponent } from './components/side-body-components/hiring-team/hiring-team.component';
// import { DashboardComponent } from './components/side-body-components/dashboard/dashboard.component';
// import { JobPostDescriptionComponent } from './components/job-post-description/job-post-description.component';
// import { CreateJobApproverComponent } from './components/create-job-approver/create-job-approver.component';
// import { CreateJobHiringteamComponent } from './components/create-job-hiringteam/create-job-hiringteam.component';
// import { LoginComponent } from './components/login/login.component';
// import { Dashboard2Component } from './components/side-body-components/dashboard2/dashboard2.component';
// import { ReferalJobDescriptionComponent } from './components/referal-job-description/referal-job-description.component';
// import { ReferalFriendFormComponent } from './components/referal-friend-form/referal-friend-form.component';
// import { ExternalReferrerDetailsComponent } from './components/external-referrer-details/external-referrer-details.component';
// import { InternalStaffSharedApplicationComponent } from './components/internal-staff-shared-application/internal-staff-shared-application.component';
// import { AddNewApplicantComponent } from './components/add-new-applicant/add-new-applicant.component';
// import { InternalJobPostingComponent } from './components/internal-job-posting/internal-job-posting.component';
// import { CreateJobLayoutComponent } from './components/create-job-layout/create-job-layout.component';
// import { ReferralComponent } from './components/referral/referral.component';
// import { JobDetailsPageComponent } from './components/job-details-page/job-details-page.component';
// import { DummyChartsComponent } from './dummy-charts/dummy-charts.component';
// const routes: Routes = [
//   { path: '', redirectTo: 'login', pathMatch: 'full' },
//   { path: 'login', component: LoginComponent, title: 'Login'},
//   {
//     path: 'job-details',
//     component: SideBodyComponent,
//     children: [
//       { path: '', redirectTo: 'basic-information', pathMatch: 'full' },
//       { path: 'basic-information', component: BasicJobDetailsComponent },
//       { path: 'job-description', component: JobDescriptionComponent },
//       { path: 'job-approvers', component: JobApproversComponent },
//       { path: 'hiring-team', component: HiringTeamComponent },
//     ],
//   },
//   { path: 'dashboard', component: DashboardComponent, title: 'Dashboard' },
//   { path: 'dashboard2', component: Dashboard2Component, title: 'Dashboard2' },
//   {
//     path: 'job-listing',
//     component: JobListingComponent,
//     title: 'Job Listings',
//   },

//   {
//     path: 'job-post-description',
//     component: JobPostDescriptionComponent,
//   },
//   {
//     path: 'create-job-approver',
//     component: CreateJobApproverComponent,
//   },

//   {
//     path: 'create-job-hiringteam',
//     component: CreateJobHiringteamComponent,
//   },
//   {
//     path: 'referal-job',
//     component: ReferalJobDescriptionComponent,
//   },
//   {
//     path: 'referal-form',
//     component: ReferalFriendFormComponent,
//   },
//   {
//     path: 'external-referrer',
//     component: ExternalReferrerDetailsComponent,
//   },
//   {
//     path: 'internal-shared-new-application',
//     component: InternalStaffSharedApplicationComponent,
//   },
//   {

//   {
//     path: 'internal-job-posting',
//     component: InternalJobPostingComponent,
//   },
//   {
//     path: 'create-job-layout',
//     component: CreateJobLayoutComponent,
//   },
//   {
//     path: 'referral',
//     component: ReferralComponent,
//   },
//   {
//     path: 'edit-job-post/:id',
//     component: CreateJobPostComponent,
//   },
//   {
//     path: 'job-details-page/:id',
//     component: JobDetailsPageComponent,
//   },
//   {
//     path: 'chart',
//     component: DummyChartsComponent,
//   },
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
