import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {

  constructor(public router : Router){

  }

  routeSettings() {
    this.router.navigateByUrl('/languages');
  }
  routeSettings1() {
    this.router.navigateByUrl('/document');
  }
  routeSettings2() {
    this.router.navigateByUrl('/experience');
  }
  routeSettings3() {
    this.router.navigateByUrl('/qualification');
  }

  routeSettings4() {
    this.router.navigateByUrl('/hiringstage');
  }
  routeSettings5() {
    this.router.navigateByUrl('/higher-education');
  }
  routeSettings6() {
    this.router.navigateByUrl('/job-category');
  }
  routeSettings7() {
    this.router.navigateByUrl('/post-job-to');
  }
  routeSettings8(){
    this.router.navigateByUrl('/department');
  }
  routeSettings9(){
    this.router.navigateByUrl('/grade');
  }
  routeSettings10(){
    this.router.navigateByUrl('/degree');
  }
  routeSettings11(){
    this.router.navigateByUrl('/study');
  }
  routeSettings12(){
    this.router.navigateByUrl('/managejob');
  }
  routeSettings13(){
    this.router.navigateByUrl('/type-of-hiring');
  }

  routeSettings14(){
    this.router.navigateByUrl('/type-of-employment');
  }
  routeSettings15(){
    this.router.navigateByUrl('/preferredIndustry');
  }
  routeSettings16(){
    this.router.navigateByUrl('/preferredDomain');
  }
  routeSettings17(){
    this.router.navigateByUrl('/workPermit');
  }
  routeSettings18(){
    this.router.navigateByUrl('/typeofappointment');
  }
  routeSettings19(){
    this.router.navigateByUrl('/nationality');
  }
  routeSettings20(){
    this.router.navigateByUrl('/skills-category');
  }
  routeSettings21(){
    this.router.navigateByUrl('/interview-template');
  }

  routeSettings25(){
    this.router.navigateByUrl('/map-job-title');
  }
  routeSettings26(){
    this.router.navigateByUrl('/post-offer');
  }
  routeSettings22(){
    this.router.navigateByUrl('/job-remuneration');
  }

  routeSettingsEntity(){
    this.router.navigateByUrl('/entity');
  }


  adminFields:any=[
    {
    Content1:"Language",
    Content2:"Document Type & Name",
    Content3:"Experience",
    Content4:"Qualification",
    Content5:"Hiring Stages & Mapping",
    Content6:"Higher Level of Education",
    Content7:"Job Category",
    Content8:"Post Job To",
    // Content9:"Department",
    content10:'Jobs Master',
    content11:'Type of Hiring',
    content12:'Type of Employment',
    content13:'Preferred Industry',
    content14:'Preferred Domain',
    content15:'Work Permit Type',
    content16:'Type of Appointment',
    // content17:'Nationality',
    skillsCategory:'Skills',
    interviewTemplate : 'Interview Template',
    interviewTemp : 'Interview Templates',
    addTemplate :'Map Jobs - Hiring Stage',
    jobRemunerationn :'Job Remuneration Annexure',
    Entityy :'Entity',
    // postOffer :'Post Offer',
    }
  ]

  formfield:any=[
    {
    Content1:"Level Of Education",
    Content2:"Grade",
    Content3:"Degree In",
    Content4:"Medium Of Study",
    }
  ]

}
