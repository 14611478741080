import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
  selector: 'app-dummy-charts',
  templateUrl: './dummy-charts.component.html',
  styleUrls: ['./dummy-charts.component.scss'],
})

export class DummyChartsComponent  {



  // @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;
  // @ViewChild('chartContainer') chartContainer!: ElementRef<HTMLDivElement>;
  // chart!: Chart;

  // ngAfterViewInit() {
  //   const labels = [
  //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow',
  //     'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange',
  //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow',
  //     'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange',
  //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow',
  //     'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange',
  //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange','Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow',
  //     'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange',
  //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow',
  //     'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange',
  //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow',
  //     'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange',
  //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'
  //   ];
  //   const data = [
  //     12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3,
  //     12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3,
  //     12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3,
  //     12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3,
  //     12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3,
  //     12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3,
  //     12, 19, 3, 5, 2, 3
  //   ];

  //   const barThickness = 30;
  //   const barMargin = 20;

  //   const chartWidth = labels.length * (barThickness + barMargin);

  //   this.chartContainer.nativeElement.style.width = `${chartWidth}px`;

  //   if (this.canvas && this.canvas.nativeElement) {
  //     this.chart = new Chart(this.canvas.nativeElement, {
  //       type: 'bar',
  //       data: {
  //         labels: labels,
  //         datasets: [
  //           {
  //             label: '# of Votes',
  //             data: data,
  //             borderWidth: 1,
  //             barThickness: barThickness,
  //             barPercentage: 1, // Adjust to control the width of bars
  //             categoryPercentage: 0.8, // Adjust to control the space between bars
  //           },
  //         ],
  //       },
  //       options: {
  //         maintainAspectRatio: false,
  //         scales: {
  //           y: {
  //             beginAtZero: true,
  //           },
  //         },
  //       },
  //     });
  //   } else {
  //     console.error('Canvas element not found');
  //   }
  // }
  @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('chartContainer') chartContainer!: ElementRef<HTMLDivElement>;
  chart!: Chart;

  // ngAfterViewInit() {
  //   const labels = [
  //     'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange','Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange','Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange','Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange','Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange','Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange','Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange','Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange','Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'
  //   ];
  //   const datasets = [
  //     {
  //       label: 'Dataset 1',
  //       data: [12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3],
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //     {
  //       label: 'Dataset 2',
  //       data: [12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,7, 11, 5, 8, 3, 4,7, 11, 5, 8, 3, 4,7, 11, 5, 8, 3, 4,7, 11, 5, 8, 3, 4,7, 11, 5, 8, 3, 4,7, 11, 5, 8, 3, 4,7, 11, 5, 8, 3, 4],
  //       backgroundColor: 'rgba(54, 162, 235, 0.5)',
  //     },
  //     {
  //       label: 'Dataset 3',
  //       data: [12, 19, 3, 5, 2, 3,12, 19, 3, 5, 2, 3,9, 13, 6, 3, 5, 8,9, 13, 6, 3, 5, 8,9, 13, 6, 3, 5, 8,9, 13, 6, 3, 5, 8,9, 13, 6, 3, 5, 8,9, 13, 6, 3, 5, 8,9, 13, 6, 3, 5, 8],
  //       backgroundColor: 'rgba(150, 192, 192, 0.5)',
  //     }
  //   ];

  //   const barThickness = 30;
  //   const barMargin = 20;

  //   const chartWidth = labels.length * (barThickness + barMargin);

  //   this.chartContainer.nativeElement.style.width = `${chartWidth}px`;

  //   if (this.canvas && this.canvas.nativeElement) {
  //     this.chart = new Chart(this.canvas.nativeElement, {
  //       type: 'bar',
  //       data: {
  //         labels: labels,
  //         datasets: datasets,
  //       },
  //       options: {
  //         maintainAspectRatio: false,
  //         scales: {
  //           x: {
  //             stacked: true,
  //           },
  //           y: {
  //             stacked: true,
  //             beginAtZero: true,
  //           },
  //         },
  //       },
  //     });
  //   } else {
  //     console.error('Canvas element not found');
  //   }
  // }

  ngAfterViewInit() {
    setTimeout(() => {
    if (this.canvas && this.canvas.nativeElement) {
        const labels = [
            'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'
        ];
        const datasets = [
            {
                label: 'Dataset 1',
                data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Dataset 2',
                data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 7, 11, 5, 8, 3, 4, 7, 11, 5, 8, 3, 4, 7, 11, 5, 8, 3, 4, 7, 11, 5, 8, 3, 4, 7, 11, 5, 8, 3, 4, 7, 11, 5, 8, 3, 4],
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
            {
                label: 'Dataset 3',
                data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8, 9, 13, 6, 3, 5, 8],
                backgroundColor: 'rgba(150, 192, 192, 0.5)',
            }
        ];

        const barThickness = 30;
        const barMargin = 20;

        const chartWidth = labels.length * (barThickness + barMargin);

        this.chartContainer.nativeElement.style.width = `${chartWidth}px`;

        this.chart = new Chart(this.canvas.nativeElement, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                  x: {
                      stacked: true,
                      border: {
                          dash: [5, 5],
                          display: true
                        } ,
                        grid: {
                           drawTicks: false,
                           display: false
                        }
                  },
                  y: {
                      border: {
                        dash: [5, 5],
                        display: true
                      } ,
                      grid: {
                         drawTicks: false,
                         display: true
                      }
                    },
              },
                plugins: {
                    datalabels: {
                        display: function(context) {
                            // Only display the label on the last dataset to avoid duplicate labels
                            return context.datasetIndex === context.chart.data.datasets.length - 1;
                        },
                        anchor: 'end',
                        align: 'end',
                        color: 'black',
                        font: {
                            weight: 'bold'
                        },
                        formatter: function(value, context) {
                            let sum = 0;
                            context.chart.data.datasets.forEach((dataset) => {
                                const val = dataset.data[context.dataIndex];
                                if (typeof val === 'number') {
                                    sum += val;
                                }
                            });
                            return sum; // Displaying the sum of stacked values
                        }
                    }
                }
            },
            plugins: [ChartDataLabels] // Adding the datalabels plugin
        });
    } else {
        console.error('Canvas element not found');
    }
});
}
}
