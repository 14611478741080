import { Component } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, FormControl, NgForm, ValidationErrors } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';


@Component({
  selector: 'app-internal-staff-shared-application',
  templateUrl: './internal-staff-shared-application.component.html',
  styleUrls: ['./internal-staff-shared-application.component.scss']
})
export class InternalStaffSharedApplicationComponent {
item: any;
i: any;
  experienceOptions: any[] | null = [];
noticePeriodOptions: any[] | null = [];
experienceeOptions: any[] | null = [];
educationOptions: any;
skillCategories: any;
skillsOptions: any;
languagesOptions: any;
countryList: any[] | null = [];
alternateCountryCode: any;
workPermitTypeList: any[] | null = [];


  formData = {
    fullName: '',
    jobTitle: '',
    phoneNumber: '',
    emailId: null,
    gender: '',
    dob: '',
    nationality: '',
    countryOfBirth: '',
    designation:'',
    organisationName:'',
    city:'',
    currentSalary:'',
    totalVariable:'',
    duration:'',
    country:'',
    fromDate: null,
    toDate: null,
    isCurrentJob:'',
    industry:'',
    experience:'',
    domain:null,
    totalExperience:null,
    noticePeriod:null,
    experiencee:null,
    education:null,
    qualification:null,
    yearOfEducation:'',
    collegeName:'',
    skillCategory:null,
    skills:null,
    languagesKnown:null,
    addressLine1:'',
    addressLine2:'',
    addressLine3:'',
    state:'',
    addresscountry:null,
    zipCode:'',
    alternatePhoneNumber:'',
    currentaddressLine1:'',
    currentaddressLine2:'',
    currentaddressLine3:'',
    currentstate:'',
    currentcountry:null,
    currentzipCode:'',
    alternateCountryCode:'',
    currentalternateCountryCode:'',
    workPermitType:null,
    passportNumber:'',
    dateOfExpiry:null,
    dateOfIssue:null,
    visaNumber:'',
    visadateOfIssue:null,
    visadateOfExpiry:null,
    alternatePhoneNumberr:'',

};
  selectedCountry: any;
dobForm: any;
perdateofbirth: any;
  gender!: string; 
countryCode: any;
emailInput: any;
currencyCode: any;
currentjob: any;
createJobForm: any;
experience: any;
// dateOfBirth: any;
higherLevelEdu: any;
qualifications: any;
languages: any;
domain: any;
currentalternateCountryCode:string= '+91';

declaration:boolean= true;
  index!: number;
  degreeList: any;
dropZone: any;

onSubmit(form: any) {
    if (form.valid) {
        console.log('Form Submitted', form.value);
        // Handle form data submission
    } else {
        console.log('Form Invalid');
    }

    if (form.valid && !this.dateRangeInvalid) {
      console.log('Form submitted successfully', this.formData);
      // Handle form submission logic here
    }
}

countries = [
  { name: 'United States', code: 'US', phoneCode: '1' },
  { name: 'India', code: 'IN', phoneCode: '91' },
  { name: 'United Kingdom', code: 'GB', phoneCode: '44' },
  // Add more countries as needed
];

selectedCountryCode = '+91'; // Default country code
phoneNumber = '';

onCountryChange(event: any): void {
  // Handle the change event here
  console.log('Country changed:', event);
}

genderOptions = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
  { id: 'other', name: 'Other' },
];
OnlyNumbersAllowed(event: KeyboardEvent): boolean {
  if (event.key === 'Backspace' || event.key === 'Delete' || event.key.startsWith('Arrow')) {
    return true;
  }
  return /[0-9]/.test(event.key);
}

selectedFile: File | null = null;

onFileChange(event: Event): void {
  const input = event.target as HTMLInputElement;
  if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0]; // Store the selected file
  }
}

  constructor(private http: HttpClient, private fb: FormBuilder){
    // this.formArray = [this.createFormGroup()];
  }
// Method to handle file upload
uploadResume(): void {
  if (this.selectedFile) {
      const formData = new FormData();
      formData.append('resume', this.selectedFile);

      // Example URL where you would send the file
      const uploadUrl = 'https://example.com/upload';

      // Use HttpClient to upload the file
      this.http.post(uploadUrl, formData)
          .pipe(
              catchError(error => {
                  // Handle upload error
                  console.error('File upload error:', error);
                  return throwError(error);
              })
          )
          .subscribe(response => {
              // Handle successful upload response
              console.log('File uploaded successfully:', response);
          });
  } else {
      console.error('No file selected');
  }
}

emailValidator(control: AbstractControl): ValidationErrors | null {
  const email = control.value;
  if (email && email.includes('@') && email.includes('.')) {
    const domain = email.split('@')[1];
    if (domain.includes('.')) {
      return null;
    }
  }
  return { invalidEmail: true };
}

dateRangeInvalid = false;
validateDateRange(index: number) {
  if (this.formData.fromDate && this.formData.toDate) {
    this.dateRangeInvalid = this.formData.fromDate >= this.formData.toDate;
  } else {
    this.dateRangeInvalid = false;
  }
}


// dropzones: Array<{ files: File[] }> = [
//   { files: [] } 
// ];


// onSelect(event: any, index: number): void {
//   this.dropzones[index].files = [...this.dropzones[index].files, ...event.addedFiles];
// }

// onRemove(file: File, index: number): void {
//   this.dropzones[index].files = this.dropzones[index].files.filter(f => f !== file);
// }

// addAnotherDropzone(): void {
//   this.dropzones.push({ files: [] });
// }

 fieldsList: Array<{ industry: string, experience: string,
}> = [];

 addFields(): void {
     this.fieldsList.push({ industry: '', experience: ''});
 }
 removeFields(index: number): void {
     this.fieldsList.splice(index, 1);
 }

 fieldsLists: Array<{
domain: any; domian: string, experiencee: ''
}> =[];

removeFieldss(index: number): void {
  this.fieldsLists.splice(index, 1);
}
addFieldss(): void {
  this.fieldsLists.push({
    domain: '',
    domian: '', experiencee: ''
  })
}

newSig: File[] = [];
newDocArray:any=[]
  newSigMultimedia: any = [];
  fileMode:any
  // newSigDocument(event: any) {
  //   this.fileMode=event.addedFiles[0].type
  //   console.log(this.fileMode,"============")
  //   this.newSigMultimedia = [];
  //   this.newDocArray = [];
  //   this.newSig.push(...event.addedFiles);
  // }

  // onRemoveSl(event:any) {
  //   this.newSig.splice(this.newSig.indexOf(event), 1);
  //   // this.files.splice(this.files.indexOf(event), 1);
  // }

  dropzones: Array<{ files: File[] }> = [{ files: [] }];

// Event handler for adding a new dropzone
addAnotherDropzone(): void {
    this.dropzones.push({ files: [] });
}

// Event handler for removing a dropzone at a specific index
removeDropzone(index: number): void {
    // Remove the dropzone at the given index
    this.dropzones.splice(index, 1);
}

// Event handler for when a file is selected in a dropzone
newSigDocument(event: any, index: number): void {
    const newFile = event.addedFiles[0];
    this.fileMode = newFile.type;
    console.log(this.fileMode, "============");
    this.dropzones[index].files.push(newFile);
}

// Event handler for removing a file from a dropzone
onRemoveSl(file: File, index: number): void {
    // Filter out the file from the dropzone's file array
    this.dropzones[index].files = this.dropzones[index].files.filter(f => f !== file);
}

educationInfoList = [{
  education: null,
  qualification: null,
  yearOfEducation: '',
  collegeName: ''
}];


// Add a new set of educational info
addEducationInfo() {
  this.educationInfoList.push({
    education: null,
    qualification: null,
    yearOfEducation: '',
    collegeName: ''
  });
}

// Remove a set of educational info by index
removeEducationInfo(index: number) {
  if (this.educationInfoList.length > 1) {
    // Remove the specified set of educational info
    this.educationInfoList.splice(index, 1);
  } else {
    console.log("Cannot remove the only set of educational information.");
  }
}


workHistoryList = [{
  designation: '',
  organisationName: '',
  city: '',
  country: '',
  currentSalary: '',
  totalVariable: '',
  fromDate: '',
  toDate: '',
  currentJob: false,
  dateRangeInvalid: false,
  currencyCode: ''
}];

// Options for currencies
currencyOptions = [
  { name: 'INR', code: 'INR' },
  { name: 'USD', code: 'USD' }
  // Add your other currency options here
];

// Method to add a new set of work history information
addWorkHistory() {
  this.workHistoryList.push({
    designation: '',
    organisationName: '',
    city: '',
    country: '',
    currentSalary: '',
    totalVariable: '',
    fromDate: '',
    toDate: '',
    currentJob: false,
    dateRangeInvalid: false,
    currencyCode: ''
  });
}

// Method to remove a set of work history information by index
removeWorkHistory(index: number) {
  if (this.workHistoryList.length > 1) {
    this.workHistoryList.splice(index, 1);
  }
}

visible: boolean = false;

showDialog() {
  this.visible = true;
}

uploadedFiles: File[] = [];

  newFileChange(event: NgxDropzoneChangeEvent) {
    const addedFiles = event.addedFiles;
    this.uploadedFiles = addedFiles;
  }

  removeFile(file: File) {
    this.uploadedFiles = this.uploadedFiles.filter(f => f !== file);
  }

}
