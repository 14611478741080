import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})


export class SsoService {
    isUserAuthenticated: boolean = false;
    isUserPermissionAuthenticated: boolean = true;
    permissionModule: any;
    hrInterview:boolean =false;


    expirationDuration: any;
    constructor() {


    }
    setUserAuthentication(res) {
        localStorage.setItem("token", res.userInfo.token);
        this.expirationDuration = new Date(res.userInfo.tokenExpresIn).getTime() - new Date().getTime();
        this.isUserAuthenticated = true;
        // this.setInterval()
    }

    setScheduleData(res){
        this.isUserAuthenticated = true;
    }

    // setInterval(){
    //     setInterval(() => 
    //     localStorage.removeItem('token'), 3000);
    // }

    get ScheduleData(){
        return this.isUserAuthenticated ;
    }

    get UserAuthenticated() {
        let token = localStorage.getItem("token");

        if (token) {
            // let rout: any = window.location.href;
            // this.permissionModule = rout.split('/').pop();


            this.isUserAuthenticated = true;
            // if (this.permissionModule != 'login' {
            //     this.UserPermissions();
            // }
            // else {
            //     this.isUserPermissionAuthenticated = true;
            // }
        }else if(this.isUserAuthenticated == true){
            return this.isUserAuthenticated;
        }
        else {
            this.isUserAuthenticated = false
        }
        return this.isUserAuthenticated;
    }

    SchedulePermission(url:any):boolean {
        if (this.isUserAuthenticated) {
            this.permissionModule = url
            let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
            let userRolePermission: any = userDetails?.userRole;
            if (userRolePermission && userRolePermission.length > 0) {
                 this.isUserPermissionAuthenticated = true;
            }
            return this.isUserPermissionAuthenticated ;
        }
    }
    
    UserPermissions(url: any): boolean {
        if (this.isUserAuthenticated) {
            this.permissionModule = url
            let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
            let userRolePermission: any = userDetails?.userRole;
            if (userRolePermission && userRolePermission.length > 0) {
                let permissioinList = userRolePermission.map(x => x.permissionName.toLowerCase());
               // console.log(permissioinList);
                let index = permissioinList.findIndex(x => x.includes(this.permissionModule.toLowerCase()));
                if (index != -1) {
                    this.isUserPermissionAuthenticated = true;
                } else {
                    this.isUserPermissionAuthenticated = false;
                }

            }
            else {
                this.isUserPermissionAuthenticated = true;
            }

        }
        return this.isUserPermissionAuthenticated

    }



    clearAuthService() {
        this.isUserAuthenticated = false;
    }


    setHrinterview(data){
         this.hrInterview = data;
    }
 
    getHrinterview(){
        return this.hrInterview;
    }

    // setLogoutTimer(expirationDuration: number) {
    //     this.tokenExpirationTimer = setTimeout(() => {
    //       this.store.dispatch(new AuthActions.Logout());
    //      //this.logout()
    //     }, expirationDuration);
    //   }
    //   clearLogoutTimer(){
    //     if(this.tokenExpirationTimer){
    //       clearTimeout(this.tokenExpirationTimer);
    //       this.tokenExpirationTimer=null
    //     }
    //   }
}