
<div class="manageSettings__main background__wrapper">

<section class="gs-dashboard-form" id="category-form" *ngIf="jobcategoryEnable">
  <a class="backToSystemFields" routerLink="/admin">
    <i class="icon-angle-down angle-rotate-d"> </i> Back to Admin </a>

  <div #container class="container-fluid mt-4 px-0">
    <div class="d-flex">
      <!-- main content-->
      <form class="col-md-4 col-xl-4 px-0" novalidate #JobCategoryForm="ngForm"
        (ngSubmit)="JobCategoryForm.form.valid && updateJobCategory(JobCategoryForm) ">
        <div class="pb-md-4">
          <h1 class="h4">Job Category</h1>
        </div>
        <div class="gs-steps-container gs-steps-container--btm new_figma_background ml-0">
          <div class="steps-form ui form new_figma_background" id="basic-info">
            <div class="gs-panel gs-panel--inner shadow-none new_figma_background">
              <div class="nationality__inputt">
                <label class="form-label-d">Job Category <span class="errorRequired">*</span></label>
                <input class="New__border skills__namee new__INput__field new__INput__fielddd mt-1 new__focus fs14"
                  type="text" #txtjobCategory="ngModel" [(ngModel)]="jobCategoryValue" name="txtjobCategory" required
                  placeholder="Enter job category" (keyup)="validateJobCategory($event)"
                  (keydown)="noSpaceBar($event)" />
                <div *ngIf="
                  (txtjobCategory.touched || JobCategoryForm.submitted) &&
                  txtjobCategory.errors?.required
                ">
                  <span class="errorMessage"> Please enter job category </span>
                </div>
                <div *ngIf="isExisitJobCategory">
                  <span class="errorMessage">
                    Job category already exists
                  </span>
                </div>
                <div *ngIf="txtjobCategory.errors">
                  <span class="errorMessage" *ngIf="txtjobCategory.errors?.pattern">
                    Job category is invalid
                  </span>
                </div>
              </div>

              <div class="d-flex mt-3 justify-content-end">
                <div class="" [ngClass]="{ notAllowed: isExisitJobCategory === true }">
                  <!-- <div *ngIf="!jobCatSubmit">
                    <button class="btn figma_blue_button" type="submit"
                      [ngClass]="{ disableBtn: isExisitJobCategory === true }">
                      Submit
                    </button>
                  </div>
                  <div *ngIf="jobCatUpdate">
                    <button class="btn figma_blue_button" type="submit"
                      [ngClass]="{ disableBtn: isExisitJobCategory === true }">
                      Update
                    </button>
                  </div> -->
                </div>
                <div class="ml-3">
                  <button class="btn discard_red_button" style="float: left" type="button"
                    (click)="formReset(JobCategoryForm)">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="mx-auto">
        <div class=" ">
          <div class="loader" *ngIf="showLoader"></div>
          <div class="language__search d-flex tab-section">
            <form class="ui form mb-4 mb-md-0 form-search js-search-input ml-auto">
              <div class="field bg-white new__search__border">
                <img class="gs-input-search__icon" src="./assets/img/view-jobs/search-sm.svg" alt="Search icon" />
                  <!-- /img/view-jobs/search-sm.svg -->
                  <!-- {{this.appSettings.imageUrl}} -->
                  <!-- <img src="./assets/img/view-jobs/search-gry.png" alt=""> -->
                <input
                  class="table-search-inputt language_Seaarch search-input-field new__focus new__INput__field new__INput__fielddd"
                  type="text" minlength="2" [(ngModel)]="jobCategorySearchKeyword" name="searchedValue"
                  #searchedValue="ngModel" placeholder="Search" style="padding-left: 35px !important"
                  (keyup)="searchJobCategoryData()" />
                  <!-- showCategoryCross() -->
                <!-- <input class="table-search-input search-input-field new__focus new__INput__field new__INput__fielddd" type="text"
                minlength="2" [(ngModel)]="filteredCategoryString" (change)="updateCategoryHiring()"
                (keyup)="updateCategoryHiring()" name="searchedValue" #searchedValue="ngModel"
                placeholder="Search by category" style="padding-left: 35px !important;" /> -->
                <img class="job-searchh pointer" src="./assets/img/figma-close-iconBlue-1.svg"
                  (click)="removeJobCategoryValuenew()" alt="close" *ngIf="jobCategorySearchKeyword" />
              </div>
              <div class="errorMessage" style="margin-top: -25px"
                *ngIf="searchedValue?.errors?.minlength && searchedValue?.touched">
                Please enter at least 2 characters.
              </div>
            </form>

            <!-- <div class="field gs-input-search ml-auto">
            <input class="form-input new__INput__field new__INput__fielddd" type="text" placeholder="Search job category"
              [(ngModel)]="filteredaddCategoryString" (change)="updateCategoryHiring()"
              (keyup)="updateCategoryHiring()" />
            <img class="gs-input-search__icon" src="{{this.appSettings.imageUrl}}/img/view-jobs/search-sm.svg"
              style="position: absolute; top: 22px;" alt="Search icon" />
          </div> -->
          </div>
          <div class="new__Table__Responsive my--new__Table__Responsive">
            <table class="mt-2 figma__Table_styles col-md-12"
              *ngIf="(jobCategoryFilteredData)?.length > 0; else noResults">
              <!-- <table> -->
              <!-- Table-->
              <thead class="">
                <tr class="table__Header__Sticky">
                  <th class="text-center" style="
                    min-width: 70px !important;
                    width: 50px !important;">
                    Sl.No
                  </th>
                  <th style="
                    padding: 10px !important;
                    min-width: 130px !important;
                    text-align:center !important;">
                    Job Category
                  </th>
                  <!-- <th style="
                    padding: 10px 10px 10px 12px !important;
                    font-size: 14px;
                  ">
                  Action
                </th> -->
                </tr>
                <div style="border-bottom :8px solid white !important;"></div>
              </thead>
              <tbody class="">
                <ng-container *ngFor="
                let list of jobCategoryFilteredData

                let i = index
              ">
                  <tr class="new-hoverR">
                    <td class="text-center" style="
                    min-width: 50px !important;
                    width: 50px !important;">
                      {{ i + 1 }}
                    </td>
                    <td class="" style="text-align:center !important;">
                      {{ list.jobCategoryName }}
                    </td>
                    <!-- <td class="tbl-txt category">
                  <div style="display: flex">
                    <img class="edit-icon" style="height: 18px; width: 18px" src="{{
                        this.appSettings.imageUrl
                      }}/img/view-jobs/edit.svg" alt="" (click)="editJobCategory(list); scrollToTop()" />

                    <a href="javascript:void(0)" *ngIf="list.status"><i class="fa fa-pencil fa-lg"
                      style="color: #1daff2;" title="Edit" (click)="editJobCategory(list);scrollToTop()"></i></a>
                  &nbsp;&nbsp;

                    <div class="custom-toggle-check">
                      <div class="ui checkbox d-flex justify-content-between">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="list.status" (change)="
                              deleteMaster(
                                $event,
                                list.jobCategoryId,
                                'jobcategory'
                              )
                            " name="status{{ i }}" [ngModelOptions]="{ standalone: true }" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <a href="javascript:void(0)" *ngIf="list.status"><i class="fa fa-trash" style="color: #1daff2;"
                      title="Delete" (click)="deleteMaster(list.jobCategoryId,'jobcategory',false)"></i></a>

                  <a href="javascript:void(0)" *ngIf="!list.status"><i class="fa fa-check" style="color: #1daff2;"
                      title="Active" (click)="deleteMaster(list.jobCategoryId,'jobcategory',true)"></i></a>
                  </div>
                </td> -->
                  </tr>
                  <div class="figma__border__bottom"></div>
                </ng-container>
              </tbody>
            </table>
          </div>

          <ng-template #noResults>
            <p class="text-center" style="color: #764fdb !important; margin-top: 30px">
              No Records Found
            </p>
          </ng-template>

          <!-- <div class="mt-4" *ngIf=" (!showLoader && (filteredCategoryItems.length == 0))">
          <p class="text-center no__records__found">No Records Found</p>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="gs-dashboard-form" id="category-form" *ngIf="jobpostEnable">
  <a class="backToSystemFields" routerLink="/admin">
    <i class="icon-angle-down angle-rotate-d"> </i> Back to Admin</a>
  <div #container class="container-fluid mt-4 px-0">
    <div class="d-flex">
      <!-- main content-->
      <form class="col-md-4 col-xl-4 px-0" novalidate #JobPostFrm="ngForm"
        (ngSubmit)="JobPostFrm.form.valid && updatepostJob(JobPostFrm)">
        <div class="pb-md-4">
          <h1 class="h4">Post Job to</h1>
        </div>
        <div class="gs-steps-container gs-steps-container--btm new_figma_background ml-0">
          <div class="steps-form ui form new_figma_background" id="basic-info">
            <div class="gs-panel gs-panel--inner shadow-none new_figma_background">
              <div>
                <label class="form-label-d mb-1">Post Job to <span class="errorRequired">*</span></label>

                <input class="New__border skills__namee new__focus new__INput__field new__INput__fielddd" type="text"
                  #txtjobPost="ngModel" [(ngModel)]="jobPostValue" name="txtjobPost" required
                  placeholder="Enter post job to" (keyup)="validateJobpost($event)" (keydown)="noSpaceBar($event)" />
                <div *ngIf="
                  (txtjobPost.touched || JobPostFrm.submitted) &&
                  txtjobPost.errors?.required
                ">
                  <span class="errorMessage"> Please enter post job </span>
                </div>
                <div *ngIf="isExisitJobPost">
                  <span class="errorMessage"> Job post to already exists </span>
                </div>
                <div *ngIf="txtjobPost.errors">
                  <span class="errorMessage" *ngIf="txtjobPost.errors?.pattern">
                    Invalid job title
                  </span>
                </div>
              </div>

              <div class="d-flex mt-3 justify-content-end">
                <div class="" [ngClass]="{ notAllowed: isExisitJobPost === true }">
                  <!-- <div *ngIf="!jobPostSubmit">
                    <button class="btn figma_blue_button" type="submit"
                      [ngClass]="{ disableBtn: isExisitJobPost === true }">
                      Submit
                    </button>
                  </div>
                  <div *ngIf="jobPostUpdate">
                    <button class="btn figma_blue_button" type="submit"
                      [ngClass]="{ disableBtn: isExisitJobPost === true }">
                      Update
                    </button>
                  </div> -->
                </div>
                <div>
                  <button class="btn ml-3 discard_red_button" type="button" (click)="formReset(JobPostFrm)">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="mx-auto">
        <div class=" ">
          <div class="loader" *ngIf="showLoader"></div>
          <div class="language__search d-flex tab-section">
            <form class="ui form mb-4 mb-md-0 form-search js-search-input ml-auto">
              <div class="field bg-white new__search__border">
                <img class="gs-input-search__icon" src="./assets/img/view-jobs/search-sm.svg" alt="Search icon" />
                <input
                  class="table-search-inputt language_Seaarch search-input-field new__focus new__INput__field new__INput__fielddd"
                  type="text" minlength="2" [(ngModel)]="postJobToSearchKeyword" name="searchedValue"
                  #searchedValue="ngModel" placeholder="Search" style="padding-left: 35px !important"
                  (keyup)="searchPostJobToData()" />
                  <!-- (keyup)="showPostJobCross()" -->
                <img class="job-searchh pointer" src="./assets/img/figma-close-iconBlue-1.svg"
                  (click)="removePostJobToValuenew()" alt=" close" *ngIf="postJobToSearchKeyword" />
              </div>
              <div class="errorMessage" style="margin-top: -25px" *ngIf="
                searchedValue?.errors?.minlength && searchedValue?.touched
              ">
                Please enter at least 2 characters.
              </div>
            </form>

            <!-- <div class="field gs-input-search ml-auto ">
            <input class="form-input new__INput__field new__INput__fielddd" type="text" placeholder="Search post job to"
              [(ngModel)]="filteredPostJobString" (change)="updatePostJobHiring()" (keyup)="updatePostJobHiring()" />
            <img class="gs-input-search__icon" src="{{this.appSettings.imageUrl}}/img/view-jobs/search-sm.svg"
              style="position: absolute; top: 22px;" alt="Search icon" />
          </div> -->
          </div>
          <div class="new__Table__Responsive my--new__Table__Responsive">
            <table class="figma__Table_styles figma__Table_styles_manage col-md-12 mt-2" *ngIf="
              (postJobToFilteredData)
                ?.length > 0;
              else noResults
            ">
              <!-- <table> -->
              <!-- Table-->
              <thead class="">
                <tr class="">
                  <th class="text-center" style="
                    min-width: 50px !important;
                    width: 50px !important;">
                    Sl.No
                  </th>
                  <th style="
                    padding-left: 6px !important;
                    min-width: 120px !important;
                     text-align:center !important;
                  ">
                    Post Job to
                  </th>
                  <!-- <th style="
                    padding: 10px 10px 10px 10px !important;
                    font-size: 14px;
                  ">
                  Action
                </th> -->
                </tr>
              </thead>
              <div style="border-bottom:8px solid white"></div>
              <tbody class="">
                <ng-container *ngFor="
                let list of postJobToFilteredData

                let i = index
              ">
                  <tr class="new-hoverR">
                    <td class="text-center" style="
                    min-width: 50px !important;
                    width: 50px !important;">
                      {{ i + 1 }}
                    </td>
                    <td class="text-center">
                      {{ list.jobPostName }}
                    </td>
                    <!-- <td class="category" style="padding: 10px 5px 5px 5px !important">
                  <div style="display: flex">
                    <img class="edit-icon" style="height: 18px; width: 18px" src="{{
                        this.appSettings.imageUrl
                      }}/img/view-jobs/edit.svg" alt="" (click)="editJobPost(list); scrollToTop()" />

                    <a href="javascript:void(0)" *ngIf="list.status">

                    <i class="fa fa-pencil fa-lg"
                      style="color: #1daff2;" title="Edit" (click)="editJobPost(list);scrollToTop()"></i></a>
                    &nbsp;&nbsp;

                    <div class="custom-toggle-check">
                      <div class="ui checkbox d-flex justify-content-between">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="list.status" (change)="
                              deleteMaster($event, list.jobPostId, 'jobpost')
                            " name="status{{ i }}" [ngModelOptions]="{ standalone: true }" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <a href="javascript:void(0)" *ngIf="list.status"><i class="fa fa-trash" style="color: #1daff2;"
                      title="Delete" (click)="deleteMaster(list.jobPostId,'jobpost',false)"></i></a>

                  <a href="javascript:void(0)" *ngIf="!list.status"><i class="fa fa-check" style="color: #1daff2;"
                      title="Active" (click)="deleteMaster(list.jobPostId,'jobpost',true)"></i></a>
                  </div>
                </td> -->
                  </tr>
                  <div class="figma__border__bottom"></div>

                </ng-container>
              </tbody>
            </table>
          </div>

          <ng-template #noResults>
            <p class="text-center" style="color: #764fdb !important; margin-top: 30px">
              No Records Found
            </p>
          </ng-template>

          <!-- <div class="mt-4" *ngIf=" (!showLoader && (filteredPostJobItems.length == 0))">
          <p class="text-center no__records__found">No Records Found</p>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="gs-dashboard-form" id="category-form" *ngIf="hiringTypeFormEnable">
  <a class="backToSystemFields" routerLink="/admin">
    <i class="icon-angle-down angle-rotate-d"> </i> Back to Admin </a>

  <div #container class="container-fluid mt-4 px-0">
    <div class="d-flex">
      <!-- main content-->
      <form class="col-md-4 col-xl-4 px-0" novalidate #hiringTypeForm="ngForm"
      (ngSubmit)="hiringTypeForm.form.valid && updateHyringType(hiringTypeForm)">
        <div class="pb-md-4">
          <h1 class="h4">Type of Hiring</h1>
        </div>
        <div class="gs-steps-container gs-steps-container--btm new_figma_background ml-0">
          <div class="steps-form ui form new_figma_background" id="basic-info">
            <div class="gs-panel gs-panel--inner shadow-none new_figma_background">
              <div class="nationality__inputt">
                <label class="form-label-d">Type of Hiring <span class="errorRequired">*</span></label>
                <input class="New__border mt-1 new__focus" type="text" #typeOfHiring="ngModel"
                  [(ngModel)]="typeofHiringValue" name="typeOfHiring" required placeholder="Enter type of hiring"
                  (keyup)="validateTypeOfHiring($event)" (keydown)="noSpaceBar($event)" />
                <!-- (keyup)="validateTypeOfHiring($event)"  -->
                <div *ngIf="(typeOfHiring.touched || hiringTypeForm.submitted) &&
                  typeOfHiring.errors?.required">
                  <span class="errorMessage">
                    Please enter type of hiring
                  </span>
                </div>
                <div *ngIf="isTypeofHiring">
                  <span class="errorMessage">
                    type of hiring already exists
                  </span>
                </div>
                <!-- <div *ngIf="txtDepart.errors">
                <span class="errorMessage" *ngIf="txtDepart.errors?.pattern">
                  type of hiring is invalid
                </span>
              </div> -->
              </div>

              <div class="d-flex mt-4 justify-content-end">
                <div [ngClass]="{ notAllowed: isTypeofHiring === true }">
                  <!-- <div *ngIf="!hiringTypeSubmit">
                    <button class="btn figma_blue_button" type="submit"
                      [ngClass]="{ disableBtn: isTypeofHiring === true }">
                      Submit
                    </button>
                  </div>
                  <div *ngIf="hiringTypeUpdate">
                    <button class="btn figma_blue_button" type="submit"
                      [ngClass]="{ disableBtn: isTypeofHiring === true }">
                      Update
                    </button>
                  </div> -->
                </div>
                <div class="ml-3">
                  <button class="discard_red_button" type="button" (click)="formReset(hiringTypeForm)">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="mx-auto tab-section">
        <div class=" ">
          <div class="loader" *ngIf="showLoader"></div>
          <div class="language__search d-flex">
            <form class="ui form mb-4 mb-md-0 form-search js-search-input ml-auto">
              <div class="field bg-white new__search__border">
                <img class="gs-input-search__icon"
                src="./assets/img/view-jobs/search-sm.svg" alt="Search icon" />
                <input
                  class="table-search-inputt language_Seaarch search-input-field new__focus new__INput__field new__INput__fielddd"
                  type="text" minlength="2" [(ngModel)]="typeOfHiringSearchKeyword" name="searchedValue"
                  #searchedValue="ngModel" placeholder="Search" style="padding-left: 35px !important"
                  (keyup)="searchtypeOfHiringData()" />
                  <!-- (keyup)="showHiiringCross()" -->
                <img class="job-searchh" src="./assets/img/figma-close-iconBlue-1.svg"
                  (click)="removetypeOfHiringValuenew()" alt="" style="cursor: pointer" *ngIf="typeOfHiringSearchKeyword" />
              </div>
              <div class="errorMessage" style="margin-top: -25px" *ngIf="
                searchedValue?.errors?.minlength && searchedValue?.touched">
                Please enter at least 2 characters.
              </div>
            </form>

            <!-- <div class="field gs-input-search ml-auto">
            <input class="new__INput__field new__INput__fielddd" type="text" placeholder="Search type of hiring"
              [(ngModel)]="filteredHireTypeString" (change)="updateHireTypeHiring()"
              (keyup)="updateHireTypeHiring()" />
            <img class="gs-input-search__icon" src="{{this.appSettings.imageUrl}}/img/view-jobs/search-sm.svg"
              style="position: absolute; top: 22px;" alt="Search icon" />
          </div> -->
          </div>
          <div class="new__Table__Responsive my--new__Table__Responsive">
            <table class="figma__Table_styles_manage figma__Table_styles col-md-12 mt-2" *ngIf="
              (typeOfHiringFilteredData)
                ?.length > 0;
              else noResults
            ">
              <!-- <table> -->
              <!-- Table-->
              <thead class="">
                <tr class="table__Header__Sticky">
                  <th style="
                    min-width: 50px !important;
                    width: 50px !important;">
                    Sl.No
                  </th>
                  <th style="min-width: 135px !important;
                     text-align:center !important;">
                    Type of hiring
                  </th>
                  <!-- <th style="
                    padding: 5px 5px 5px 35px !important;
                    font-size: 14px;
                  ">
                  Action
                </th> -->
                </tr>
              </thead>
              <div style="border-bottom:8px solid white"></div>
              <tbody class="">
                <ng-container *ngFor="
                let list of typeOfHiringFilteredData

                let i = index">
                  <tr class="new-hoverR">
                    <td class="tbl-txt d-flex bI1" style="
                    margin-top: 5px;
                    min-width: 50px !important;
                    width: 50px !important;
                    padding: 5px 5px 5px 20px !important;
                  ">
                      {{ i + 1 }}
                    </td>
                    <td class="tbl-txt category" style="padding: 5px !important; text-align:center !important;">
                      {{ list?.hiring_Name }}
                    </td>
                    <!-- <td class="tbl-txt category">
                  <div style="display: flex; justify-content: end !important">
                    <img class="edit-icon" style="height: 18px; width: 18px" src="{{
                        this.appSettings.imageUrl
                      }}/img/view-jobs/edit.svg" alt="" (click)="editHiringType(list); scrollToTop()" />

                    <a href="javascript:void(0)" *ngIf="list.hiring_Status"><i class="fa fa-pencil fa-lg"
                      style="color: #1daff2;" title="Edit" (click)="editHiringType(list);scrollToTop()"></i></a>
                  &nbsp;&nbsp;

                    <div class="custom-toggle-check">
                      <div class="ui checkbox d-flex justify-content-between">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="list.hiring_Status" (change)="
                              deleteMaster($event, list.hiring_Id, 'hiring')
                            " name="status{{ i }}" [ngModelOptions]="{ standalone: true }" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <a href="javascript:void(0)" *ngIf="list.status"><i class="fa fa-trash" style="color: #1daff2;"
                      title="Delete" (click)="deleteMaster(list.jobCategoryId,'jobcategory',false)"></i></a>

                  <a href="javascript:void(0)" *ngIf="!list.status"><i class="fa fa-check" style="color: #1daff2;"
                      title="Active" (click)="deleteMaster(list.jobCategoryId,'jobcategory',true)"></i></a>
                  </div>
                </td> -->
                  </tr>
                  <div class="figma__border__bottom"></div>
                </ng-container>
              </tbody>
            </table>
          </div>

          <ng-template #noResults>
            <p class="text-center" style="color: #764fdb !important; margin-top: 30px">
              No Records Found
            </p>
          </ng-template>

          <!-- <div class="mt-4" *ngIf=" (!showLoader && (filteredHireTypeItems.length == 0))">
          <p class="text-center no__records__found">No Records Found</p>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="gs-dashboard-form" id="category-form" *ngIf="employementTypeEnable">
  <a class="backToSystemFields" routerLink="/admin">
    <i class="icon-angle-down angle-rotate-d"> </i> Back to Admin </a>

  <div #container class="container-fluid mt-4 px-0">
    <div class="d-flex">
      <!-- main content-->
      <form class="col-md-4 col-xl-4 px-0" novalidate #employementTypeForm="ngForm"
      (ngSubmit)="employementTypeForm.form.valid && updateEmployement(employementTypeForm)">
        <div class="pb-md-4">
          <h1 class="h4">Type of Employment</h1>
        </div>
        <div class="gs-steps-container gs-steps-container--btm new_figma_background ml-0">
          <div class="steps-form ui form" id="basic-info new_figma_background">
            <div class="gs-panel gs-panel--inner shadow-none new_figma_background">
              <div class="nationality__inputt">
                <label class="form-label-d">Employment type <span class="errorRequired">*</span></label>
                <input class="New__border mt-1 new__focus" type="text" #employementType="ngModel"
                  [(ngModel)]="employementTypeValue" name="employementType" required
                  placeholder="Enter employment type" pattern="^[-a-zA-Z\s]*$"
                  (keyup)="validatetypeofEmployement($event)" (keydown)="noSpaceBar($event)" />
                <div *ngIf="
                  (employementType.touched ||
                    employementTypeForm.submitted) &&
                  employementType.errors?.required
                ">
                  <span class="errorMessage">
                    Please enter employment type
                  </span>
                </div>
                <div *ngIf="istypeofEmployement">
                  <span class="errorMessage">
                    Employment type already exists
                  </span>
                </div>
                <!-- <div *ngIf="txtDepart.errors">
                <span class="errorMessage" *ngIf="txtDepart.errors?.pattern">
                  employement type is invalid
                </span>
              </div> -->
              </div>

              <div class="d-flex mt-3 justify-content-end">
                <!-- <div [ngClass]="{ notAllowed: istypeofEmployement === true }">
                  <div *ngIf="!employeSubmiTT">
                    <button class="btn figma_blue_button" type="submit"
                      [ngClass]="{ disableBtn: istypeofEmployement === true }">
                      Submit
                    </button>
                  </div>
                  <div *ngIf="employeUpdateE">
                    <button class="btn figma_blue_button" type="submit"
                      [ngClass]="{ disableBtn: istypeofEmployement === true }">
                      Update
                    </button>
                  </div>
                </div> -->
                <div class="ml-3">
                  <button class="btn discard_red_button" style="float: left" type="button"
                    (click)="formReset(employementTypeForm)">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="mx-auto tab-section o_y_scroll">
        <div class="loader" *ngIf="showLoader"></div>
        <div class="language__search d-flex">
          <form class="ui form mb-4 mb-md-0 form-search js-search-input ml-auto">
            <div class="field gs-input-search bg-white new__search__border">
              <img class="gs-input-search__icon" src="./assets/img/view-jobs/search-sm.svg" alt="Search icon" />
              <input class="table-search-inputt search-input-field new__focus new__INput__field new__INput__fielddd"
                type="text" minlength="2" [(ngModel)]="typeOfEmploymentSearchKeyword" name="searchedValue"
                #searchedValue="ngModel" placeholder="Search" style="padding-left: 35px !important"
                (keyup)="searchtypeOfEmploymentData()" />
                <!-- (keyup)="showEmploymentCross()" -->
              <img class="job-searchh" src="./assets/img/figma-close-iconBlue-1.svg" (click)="removetypeOfEmploymentValuenew()"
              alt="" *ngIf="typeOfEmploymentSearchKeyword" style="cursor: pointer" />
            </div>
            <div class="errorMessage" style="margin-top: -25px" *ngIf="
                searchedValue?.errors?.minlength && searchedValue?.touched
              ">
              Please enter at least 2 characters.
            </div>
          </form>

          <!-- <div class="field gs-input-search ml-auto">
            <input class="form-input new__INput__field new__INput__fielddd" type="text" placeholder="Search type of employement"
              [(ngModel)]="filteredEmployeeString" (change)="updateEmployeTypeHiring()"
              (keyup)="updateEmployeTypeHiring()" />
            <img class="gs-input-search__icon" src="{{this.appSettings.imageUrl}}/img/view-jobs/search-sm.svg"
              style="position: absolute; top: 22px;" alt="Search icon" />
          </div> -->
        </div>
        <div class="new__Table__Responsive my--new__Table__Responsive">
          <table class="col-md-12 figma__Table_styles_manage figma__Table_styles mt-2" *ngIf="
              (typeOfEmploymentFilteredData)
                ?.length > 0;
              else noResults
            ">
            <!-- <table> -->
            <!-- Table-->
            <thead class="">
              <tr class="table__Header__Sticky">
                <th style="
                    min-width: 50px !important;
                    width: 80px !important; text-align:center !important;">
                  Sl.No
                </th>
                <th style="min-width: 170px !important;">
                  Type of employment
                </th>
                <!-- <th style="
                    padding: 10px 10px 10px 47px !important;
                    font-size: 14px;
                  ">
                  Action
                </th> -->
              </tr>
            </thead>
            <div style="border-bottom :8px solid white !important;"></div>
            <tbody class="">
              <ng-container *ngFor="
                let list of typeOfEmploymentFilteredData; let i = index">
                <tr class="new-hoverR">
                  <td class="text-center" style="min-width: 50px !important;
                    width: 50px !important;">
                    {{ i + 1 }}
                  </td>
                  <td class="tbl-txt category">
                    {{ list?.employment_Name }}
                  </td>
                  <!-- <td class="tbl-txt category">
                  <div style="display: flex; margin-left: 30px !important">
                    <img class="edit-icon" style="height: 18px; width: 18px" src="{{
                        this.appSettings.imageUrl
                      }}/img/view-jobs/edit.svg" alt="" (click)="editEmployement(list); scrollToTop()" />

                    <a href="javascript:void(0)" *ngIf="list.employment_Status"><i class="fa fa-pencil fa-lg"
                      style="color: #1daff2;" title="Edit" (click)="editEmployement(list);scrollToTop()"></i></a>
                  &nbsp;&nbsp;

                    <div class="custom-toggle-check">
                      <div class="ui checkbox d-flex justify-content-between">
                        <label style="width: 8px !important" class="switch">
                          <input type="checkbox" [(ngModel)]="list.employment_Status" (change)="
                              deleteMaster(
                                $event,
                                list.employment_Id,
                                'employment'
                              )
                            " name="status{{ i }}" [ngModelOptions]="{ standalone: true }" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <a href="javascript:void(0)" *ngIf="list.status"><i class="fa fa-trash" style="color: #1daff2;"
                      title="Delete" (click)="deleteMaster(list.jobCategoryId,'jobcategory',false)"></i></a>

                  <a href="javascript:void(0)" *ngIf="!list.status"><i class="fa fa-check" style="color: #1daff2;"
                      title="Active" (click)="deleteMaster(list.jobCategoryId,'jobcategory',true)"></i></a>
                  </div>
                </td> -->
                </tr>
                <div class="figma__border__bottom"></div>
              </ng-container>
            </tbody>
          </table>
        </div>

        <ng-template #noResults>
          <p class="text-center" style="color: #764fdb !important; margin-top: 30px">
            No Records Found
          </p>
        </ng-template>

        <!-- <div class="mt-4" *ngIf=" (!showLoader && (filteredEmployeeTypeItems.length == 0))">
          <p class="text-center no__records__found">No Records Found</p>
        </div> -->
      </div>
    </div>
  </div>
</section>

<section class="gs-dashboard-form" id="language-form" *ngIf="languageEnable">
  <a class="backToSystemFields" routerLink="/admin">
    <i class="icon-angle-down angle-rotate-d"> </i> Back to Admin </a>
  <div class="container-fluid mt-4">
    <div class="row">
      <!-- main content-->
      <form class="col-md-4 col-xl-4 px-0 language_fields_part" #languageFrm="ngForm"
        (ngSubmit)="languageFrm.form.valid && updateLanguage(languageFrm)">
        <div class="pb-md-4">
          <h1 class="h4">Language</h1>
        </div>
        <div class="steps-form ui form" id="basic-info new_figma_background">
          <div class="gs-panel gs-panel--inner shadow-none new_figma_background nationality__inputt">
            <label class="form-label-d mb-1">Language<span class="errorRequired">*</span></label>
            <input type="text"
              class="skills__namee form-input New__border new__INput__field new__INput__fielddd new__focus"
              placeholder="Enter language" #txtLanaguage="ngModel" (keypress)="keyPress($event)"
              [(ngModel)]="lanaguageValue" name="txtLanaguage" required (keyup)="ValidateLanguage($event)"
              pattern="^[-a-zA-Z\s]*$" (keydown)="noSpaceBar($event)" />

            <!--  pattern="[A-Za-z0-9]+" -->
            <div *ngIf="
              (txtLanaguage.touched || languageFrm.submitted) &&
              txtLanaguage.errors?.required
            ">
              <span class="errorMessage"> Please enter language </span>
            </div>
            <div *ngIf="txtLanaguage.errors">
              <span class="errorMessage" *ngIf="txtLanaguage.errors?.pattern">
                Invalid language
              </span>
            </div>
            <div *ngIf="isExisitLanName">
              <span class="errorMessage"> Language name already exists </span>
            </div>
            <div class="d-flex mt-4 justify-content-end submit_cancel_buttons">
              <div class="text-center text-md-right" [ngClass]="{ notAllowed: isExisitLanName === true }">
                <!-- <div *ngIf="!saveButton">
                  <button class="btn figma_blue_button" type="submit"
                    [ngClass]="{ disableBtn: isExisitLanName === true }">
                    Submit
                  </button>
                </div> -->
                <div *ngIf="updateButton">
                  <button class="btn figma_blue_button" type="submit">
                    Update
                  </button>
                </div>
              </div>
              <div class="text-center text-md-right ml-3">
                <button class="btn discard_red_button" style="float: left" type="button"
                  (click)="formReset(languageFrm)">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="mx-auto pl-0">
        <div class="tab-section">
          <div class="loader" *ngIf="showLoader"></div>
          <div class="language__search d-flex">
            <!-- <div class="field gs-input-search ml-auto">
            <input class="new__INput__field new__INput__fielddd new__focus" type="text" placeholder="Search language"
              [(ngModel)]="filteredStringg" (change)="updateResultss()" (keyup)="updateResultss()" />
            <img class="gs-input-search__icon" src="{{this.appSettings.imageUrl}}/img/view-jobs/search-sm.svg"
              style="position: absolute; top: 20px; " alt="Search icon" />
          </div> -->
            <form class="ui form mb-4 mb-md-0 form-search js-search-input ml-auto mr-3">
              <div class="field new__search__border">
                <img class="gs-input-search__icon input_Search_icon"
                  src="./assets/img/view-jobs/search-sm.svg" alt="Search icon" />
                <input
                  class="table_search_input search-input-field new__INput__field new__INput__fielddd new__focus language_Seaarch"
                  type="text" minlength="2" [(ngModel)]="languageSearchKeyword" name="searchedValue"
                  #searchedValue="ngModel" placeholder="Search" (keyup)="searchLanguageData()"
                  style="padding-left: 35px !important" />
                  <!-- (keyup)="showLanguageCross()" -->
                <!-- <input class="table-search-input search-input-field new__INput__field new__INput__fielddd new__focus" type="text"
                minlength="2" (change)="updateResultss()" (keyup)="updateResultss()" [(ngModel)]="filteredStringg"
                name="searchedValue" #searchedValue="ngModel" placeholder="Search by language"
                style="padding-left: 35px !important;" /> -->
                <img class="job-searchh pointer" src="./assets/img/figma-close-iconBlue-1.svg"
                  (click)="removeLanguageValuenew()" alt="close" *ngIf="languageSearchKeyword" />
              </div>
              <div class="errorMessage" style="margin-top: -25px"
              *ngIf="searchedValue?.errors?.minlength && searchedValue?.touched">
                Please enter at least 2 characters.
              </div>
            </form>
          </div>

          <div class="new__Table__Responsive my--new__Table__Responsive" style="margin-top:10px !important">
            <table class="figma__Table_styles figma__Table_styles_manage col-md-12 px-0"
              *ngIf=" (languageFilteredData).length > 0; else noResults">
              <thead class="table__Header__Sticky" style="top:0px !important">
                <tr class="">
                  <th class="text-center" style="min-width: 50px !important;width: 100px !important;">Sl.No</th>
                  <th class="" style="min-width: 50px !important;width: 150px !important;">Language</th>
                </tr>
              </thead>
              <div style="border-bottom:8px solid white"></div>
              <tbody class="">
                <ng-container *ngFor="let list of languageFilteredData; let i = index">
                  <tr class="new-hoverR relative">
                    <td class="text-center" style="min-width: 80px !important; width: 100px !important;"> {{i+1}}</td>
                    <td> {{ list.languageName }} </td>
                  </tr>
                  <div class="figma__border__bottom"></div>
                </ng-container>
              </tbody>
            </table>

          </div>
          <ng-template #noResults>
            <p class="text-center" style="color: #764fdb !important; margin-top: 30px">
              No Records Found
            </p>
          </ng-template>

          <!-- <div class="mt-4" *ngIf=" (!showLoader && (filteredItemss.length == 0))">
          <p class="text-center no__records__found">No Records Found</p>
        </div> -->
          <!-- <div *ngIf="!showLoader && totalRecords == 0" class="r1">No Records Found</div> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="gs-dashboard-form" id="qualification-form" *ngIf="educationEnable">
  <a class="backToSystemFields" routerLink="/admin">
    <i class="icon-angle-down angle-rotate-d"> </i> Back to Admin </a>

  <div #container class="container-fluid mt-4">
    <div class="row">
      <!-- main content-->
      <form class="col-md-4 col-xl-4 px-0" novalidate #BOEFrm="ngForm"
        (ngSubmit)="BOEFrm.form.valid && updateEducation(BOEFrm)">
        <div class="pb-md-4">
          <h1 class="h4">Higher Level of Education</h1>
        </div>
        <div class="gs-steps-container gs-steps-container--btm new_figma_background ml-0">
          <div class="steps-form ui form new_figma_background" id="basic-info">
            <div class="gs-panel gs-panel--inner shadow-none new_figma_background">
              <div class="nationality__inputt">
                <label class="form-label-d">Higher Level of Education
                  <span class="errorRequired">*</span></label>
                <input class="New__border New__border mt-1 new__focus" type="text" #txtBoe="ngModel"
                  [(ngModel)]="educationValue" name="txtBoe" required placeholder="Enter Higher Level of Education"
                  (keyup)="ValidateEducation($event)" (keydown)="noSpaceBar($event)" />
                <div *ngIf="
                  (txtBoe.touched || BOEFrm.submitted) &&
                  txtBoe.errors?.required
                ">
                  <span class="errorMessage">
                    Please enter Higher Level of Education
                  </span>
                </div>
                <div *ngIf="isExisitEduName">
                  <span class="errorMessage">
                    Higher Level of Education name already exists
                  </span>
                </div>
              </div>

              <!-- <div class="col-md-6 text-center text-md-right">
            <button class="btn btn--primary-d btn-sudo-arrow mx-2 js-next next" type="submit">Submit</button>
            <button class="btn btn--primary-d btn-sudo-arrow mx-2 js-next next" type="button"
              (click)="formReset(BOEFrm)">Cancel</button>
          </div> -->

              <!-- <div class="d-flex mt-3 justify-content-end">
                <div class="" [ngClass]="{ notAllowed: isExisitEduName === true }">
                  <div *ngIf="!eduSubmit">
                    <button class="btn figma_blue_button" type="submit"
                      [ngClass]="{ disableBtn: isExisitEduName === true }">
                      Submit
                    </button>
                  </div>
                  <div *ngIf="eduUpdate">
                    <button class="btn figma_blue_button" type="submit"
                      [ngClass]="{ disableBtn: isExisitEduName === true }">
                      Update
                    </button>
                  </div>
                </div>
                <div class="ml-3">
                  <button class="btn discard_red_button" type="button" (click)="formReset(BOEFrm)">
                    Cancel
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </form>

      <div class="mx-auto">
        <div class=" ">
          <div class="loader" *ngIf="showLoader"></div>
          <div class="language__search d-flex tab-section">
            <form class="ui form mb-4 mb-md-0 form-search js-search-input ml-auto">
              <div class="field gs-input-search bg-white new__search__border">
                <img class="gs-input-search__icon" src="./assets/img/view-jobs/search-sm.svg" alt="Search icon" />
                <input class="table-search-input" [(ngModel)]="higherEducationSearchKeyword" name="searchedValue"
                  #searchedValue="ngModel" placeholder="Search" style="padding-left: 35px !important"
                  (keyup)="searchHigherEducationData()" />
                <!-- <input class="table-search-input search-input-field new__focus new__INput__field new__INput__fielddd" type="text"
                minlength="2" [(ngModel)]="filteredBOEString" (change)="updateBoeHiring()" (keyup)="updateBoeHiring()"
                name="searchedValue" #searchedValue="ngModel" placeholder="Search by board of education"
                style="padding-left: 35px !important;" /> -->
                <img class="job-searchh pointer" src="./assets/img/figma-close-iconBlue-1.svg" (click)="removeHigherEducationValuenew()"
                 alt="" *ngIf="higherEducationSearchKeyword" />
              </div>
              <div class="errorMessage" style="margin-top: -25px" *ngIf="
                searchedValue?.errors?.minlength && searchedValue?.touched
              ">
                Please enter at least 2 characters.
              </div>
            </form>

            <!-- <div class="ml-auto  field gs-input-search">
            <input class="form-input new__INput__field new__INput__fielddd" type="text" placeholder="search board of education"
              [(ngModel)]="filteredBOEString" (change)="updateBoeHiring()" (keyup)="updateBoeHiring()" />
            <img class="gs-input-search__icon" src="{{this.appSettings.imageUrl}}/img/view-jobs/search-sm.svg"
              style="position: absolute; top: 22px;" alt="Search icon" />
          </div> -->
          </div>
          <div class="new__Table__Responsive my--new__Table__Responsive">
            <table class="figma__Table_styles figma__Table_styles_manage mt-2" *ngIf="
              (higherEducationFilteredData)?.length > 0;
              else noResults
            ">
              <!-- <table> -->
              <!-- Table-->
              <thead class="">
                <tr class="table__Header__Sticky">
                  <th class="text-center" style="
                    min-width: 50px !important;
                    width: 50px !important;">
                    Sl.No
                  </th>
                  <th style="
                    padding: 10px !important;
                    min-width: 200px !important;">
                    Higher Level of Education
                  </th>
                  <!-- <th style="
                    padding: 10px 10px 10px 35px !important;
                    font-size: 14px;
                  ">
                  Action
                </th> -->
                </tr>
              </thead>
              <div style="border-bottom :8px  solid white !important;"></div>
              <tbody class="">
                <ng-container *ngFor="
                let list of higherEducationFilteredData;
                let i = index">
                  <tr class="new-hoverR">
                    <td class="text-center" style="
                    min-width: 50px !important;
                    width: 50px !important;">
                      {{ i + 1 }}
                    </td>
                    <td class="" style="text-align:center !important;">
                      {{ list.educationName }}
                    </td>
                    <!-- <td class="tbl-txt category" style="padding-left: 35px !important">
                  <div style="display: flex">
                    <img class="edit-icon" style="height: 18px; width: 18px" src="{{
                        this.appSettings.imageUrl
                      }}/img/view-jobs/edit.svg" alt="" (click)="editEducation(list); scrollToTop()" />

                    <a href="javascript:void(0)" *ngIf="list.status"><i class="fa fa-pencil fal-lg"
                      style="color: #1daff2;" title="Edit" (click)="editEducation(list);scrollToTop()"></i></a>
                  &nbsp;&nbsp;

                    <div class="custom-toggle-check">
                      <div class="ui checkbox d-flex justify-content-between">
                        <label class="switch">
                          <input type="checkbox" [(ngModel)]="list.status" (change)="
                              deleteMaster(
                                $event,
                                list.educationId,
                                'education'
                              )
                            " name="status{{ i }}" [ngModelOptions]="{ standalone: true }" />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>

                    <a href="javascript:void(0)" *ngIf="list.status"><i class="fa fa-trash" style="color: #1daff2;"
                      title="Delete" (click)="deleteMaster(list.educationId,'education',false)"></i></a>

                  <a href="javascript:void(0)" *ngIf="!list.status"><i class="fa fa-check" style="color: #1daff2;"
                      title="Active" (click)="deleteMaster(list.educationId,'education',true)"></i></a>
                  </div>
                </td> -->
                  </tr>
                  <div class="figma__border__bottom"></div>
                </ng-container>
              </tbody>
            </table>
          </div>

          <ng-template #noResults>
            <p class="text-center" style="color: #764fdb !important; margin-top: 30px">
              No Records Found
            </p>
          </ng-template>
          <!-- <div class="mt-4" *ngIf=" (!showLoader && (filteredBOEItems.length == 0))">
          <p class="text-center no__records__found">No Records Found</p>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</section>


</div>
