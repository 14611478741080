<main class="ijp-employee-main pr-2">
  <!-- <h1 class="fw500 fs20 my-3"> IJP  </h1> -->
  <div class="d-flex align-items-center justify-content-between white-background p16 br8 mb16">
    <ul class="nav nav-tabs parent-nav-item" id="job-tabs" role="tablist">
      <li class="nav-item" role="presentation">
        <button (click)="ijpreferral('IJP')" class="nav-link active" id="job-postings-tab" data-toggle="tab"
          data-target="#job-postings" type="button" role="tab" aria-controls="job-postings" aria-selected="true">
          Internal Job Postings - Employee
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <!-- <button (click)="ijpreferral('Referral Campaign')" class="nav-link" id="referrals-tab" data-bs-toggle="tab" data-bs-target="#referrals" type="button" role="tab" aria-controls="referrals" aria-selected="false">Referrals</button> -->
      </li>
    </ul>
    <div class="job-list-search-card d-xl-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center justify-content-between gap12 mb-md-2">
        <!-- <div class="search-inputt relative">
          <input type="text" class="w-100" placeholder="Search" [(ngModel)]="searchItem" (keyup)="
              onSearchChange(UserId, pageNO, NoOfObjectsPerPage, searchItem)
            " />
          <img class="img-fluid absolute" src="./assets/Images/search.svg" alt="search icon" />
          <svg (click)="clearSearch()" *ngIf="searchItem" width="20" height="20" fill="currentColor"
            class="cross-iconn pointer bi bi-x" viewBox="0 0 16 16">
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div> -->
      </div>

      <!-- <div
    class="d-flex align-items-center gap12 job-list-add-buttons"
  >
    <button type="button" class="secondary-btn">
      + Add Campaign
    </button>
    <button
      type="button"
      class="blue-btn"
      routerLink="/create-job-post"
    >
      + Create Job
    </button>
  </div> -->
    </div>
  </div>
  <div class="card job-listing-main-card p-0 white-background">
    <!-- new table -->
    <div class="loader" *ngIf="loader"></div>
      <div class="job-listings-table-main job-listings-table-main--two mt-0 table-responsive" *ngIf="jobLists.length > 0">
        <table class="table mb-0">
          <thead class="d-flex">
            <tr class="col-md-12 d-flex p-0">
              <th>
                <div class="d-flex pointer" (click)="sortTable('brand_IJPJobId')">
                  <p>Job ID</p>
                  <img src="./assets/Images/sort-icons.svg" alt="sort icons" alt="sort icons" />
                </div>
              </th>
              <th>
                <div class="d-flex pointer" (click)="sortTable('jobTitleName')">
                  <p>Job Title</p>
                  <img src="./assets/Images/sort-icons.svg" alt="sort icons" alt="sort icons" />
                </div>
              </th>
              <th>
                <div class="d-flex pointer" (click)="sortTable('campusName')">
                  <p>Campus</p>
                  <img src="./assets/Images/sort-icons.svg" alt="sort icons" alt="sort icons" />
                </div>
              </th>
              <th>
                <div class="d-flex pointer" (click)="sortTable('categoryName')">
                  <p>Category</p>
                  <img src="./assets/Images/sort-icons.svg" alt="sort icons" alt="sort icons" />
                </div>
              </th>
              <th>
                <div class="d-flex pointer" (click)="sortTable('typeOfEmployementName')">
                  <p>Type</p>
                  <img src="./assets/Images/sort-icons.svg" alt="sort icons" alt="sort icons" />
                </div>
              </th>
              <th>
                <div class="d-flex pointer" (click)="sortTable('ijpJobCreatedDate')">
                  <p>Created</p>
                  <img src="./assets/Images/sort-icons.svg" alt="sort icons" alt="sort icons" />
                </div>
              </th>
              <th>
                <div class="d-flex pointer" (click)="sortTable('cutOffDate')">
                  <p>Cut-Off Date</p>
                  <img src="./assets/Images/sort-icons.svg" alt="sort icons" alt="sort icons" />
                </div>
              </th>
              <!-- <ng-container *ngIf="!noTabData"> -->
              <th>
                <div class="d-flex pointer" (click)="sortTable('appliedStatus')">
                  <p>Status</p>
                  <img src="./assets/Images/sort-icons.svg" alt="sort icons" alt="sort icons" />
                </div>
              </th>
              <!-- </ng-container> -->
              <!-- <th></th> -->
            </tr>
          </thead>
  
          <tbody class="col-md-12">
            <ng-container *ngFor="let x of jobLists; let i = index">
              <tr class="align-items-center tr-hover">
                <div class="table-row-content">
                  <div class="col-md-12 d-flex new-myyy-hover align-items-center p-0">
                    <td (click)="
                        redirectedToDetailsPage(x.ijpBasicInformationId, i)
                      ">
                      {{ x.brand_IJPJobId }}
                    </td>
                    <td (click)="
                        redirectedToDetailsPage(x.ijpBasicInformationId, i)
                      " >
                      <div style="width: fit-content;" pTooltip="{{ dataType(x.jobTitleName + '<br/>' + x.brand_IJPJobId) }}" 
                      tooltipPosition="bottom" 
                      tooltipStyleClass="custom-tooltip">
                      <p class="text-overflow" >
                        {{ x.jobTitleName }}
                      </p>
                      </div>
                      
                    </td>
                    <td (click)="
                        redirectedToDetailsPage(x.ijpBasicInformationId, i)
                      ">
                      {{ x.campusName }}
                    </td>
                    <td (click)="
                        redirectedToDetailsPage(x.ijpBasicInformationId, i)
                      ">
                      {{ x.categoryName }}
                    </td>
                    <td (click)="
                        redirectedToDetailsPage(x.ijpBasicInformationId, i)
                      ">
                      {{ x.typeOfEmployementName }}
                    </td>
                    <td (click)="
                        redirectedToDetailsPage(x.ijpBasicInformationId, i)
                      ">
                      {{ x.ijpJobCreatedDate | date }}
                    </td>
                    <td (click)="
                        redirectedToDetailsPage(x.ijpBasicInformationId, i)
                      ">
                      {{ x.cutOffDate | date }}
                    </td>
                    <!-- <ng-container *ngIf="!noTabData"> -->
                    <td class="d-flex justify-content-between align-items-center" >
                      <div class="applied-background" [ngClass]="x.appliedStatus =='Applied' ? 'applied-Green' : 'applied-orange'">
                      {{x.appliedStatus}}
                    </div>
  
                      <div class="d-flex" *ngIf="x.appliedStatus !='Applied'">
                        <!-- *ngIf="x.publishedStatus=='Published'" -->
                        <button type="button" class="three-dots-button" (click)="toggleKebabMenu(x)">
                          <img src="./assets/Images/three-dots.svg" alt="three dots" />
                        </button>
                        <div class="kebeb-menu" *ngIf="x.showKebabMenu" (mouseleave)="hideKebabMenu(x)">
                          <ul>
                            <!-- (click)="openCopyLinkModal()"   Copy IJP Link-->
                             <div data-toggle="modal" data-target="#staticBackdropCopyLink">
                            <!-- <li *ngIf="x.ijpJobStatus == '96'"
                              (click)="
                                openCopyModal(x, x.jobTitleName, x.brand_IJPJobId)
                              ">
                              Copy IJP Link
                            </li> -->
  
                            <li *ngIf="x.ijpJobStatus == '96'" (click)="
                                                addapplicant(
                                                  x.ijpBasicInformationId
                                                )
                                              ">
                              Apply Now
                            </li>
                          </div>
                          </ul>
                        </div>
                      </div>
                    </td>
                    <!-- </ng-container> -->
                  </div>
  
                  <div class="job-score-part d-flex justify-content-between align-items-center p-1">
                    <div class="d-flex col-md-6 px-0">
                      <div class="appJob_scoree appJob_scoree-new col-md-12 px-0">
                        <p class="fs12 col-md-3 color-grey">
                          Country &nbsp;
                          <span class="fw600 blue-color fs14"> {{x.countryName}} </span>
                        </p>
                        <p class="fs12 col-md-7 color-grey">
                          School &nbsp;
                          <span class="fw600 blue-color fs14"> {{x.brandName}} </span>
                        </p>
                        <p class="fs12 col-md-11 color-grey ml-1">
                          Minimum GSG experience(In years) - &nbsp;
                          <span class="fw600 blue-color fs14"> {{x.gstOrganizationExperience}} </span>
                        </p>
                      </div>
                    </div>
  
                    <div class="table-dates-partt d-flex gap-3 align-items-center" style="width: 75px" *ngIf="
                        x.ijpJobPostedData != null &&
                        x.ijpJobPostedData.length > 0
                      ">
                      <!-- Button trigger modal -->
  
                      <!-- <img class="pointer" data-toggle="modal" data-target="#staticBackdropDates"
                        src="./assets/Images/calender-icon.svg" (click)="getDate(x.ijpBasicInformationId)"
                        alt="calender" /> -->
  
                      <!-- <button type="button" class="publishedd-btn ml-2">
                        Published
                      </button> -->
                      <!--  *ngIf="x.publishedStatus=='Published'" -->
                      <!-- <button type="button" class="publish--expired-btn ml-2"
                        *ngIf="x.publishedStatus == 'Publish Expired'">
                        Published Expired
                      </button> -->
                    </div>
  
                    <!-- <ng-container *ngIf="acceptRejectModal">
                <p class="blue-color pointer" role="button" (click)="showDialog(x.ijpBasicInformationId, x.ijpJobApprovedStatus, '97')"> show </p>
                </ng-container> -->
  
                    <div class="check_recruitment_Members d-flex">
                      <!-- *ngIf="!x.jobSelectType == 'Archive' && !x.jobSelectType == 'Archive' " -->
                      <!-- <p>Check</p> -->
                      <!-- <button type="button" class="ml-2 recruit-members fs14 pointer" data-toggle="modal" (click)="
                        GetIJPJobRecruitmentMembersPopUp(
                          x.ijpBasicInformationId
                        )
                      " data-target="#recruitModal">
                        Recruitment members
                      </button> -->
  
                      <!-- Modal -->
                      <div class="modal fade mt-5 recruit-popup" id="recruitModal" data-backdrop="static"
                        data-keyboard="false" tabindex="-1" aria-labelledby="recruitModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h1 class="modal-title fs-5 fw600" id="recruitModalLabel">
                                Recruitment members
                              </h1>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <svg width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                  <path
                                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                </svg>
                              </button>
                            </div>
                            <div class="modal-body">
                              <ul class="experiences">
                                <!-- Experience -->
                                <li class="green" *ngFor="let x of requarray; let i = index">
                                  <div class="timeLine__data ml-5">
                                    <h1 class="fw600 fs14">
                                      {{ x.hiringStage }}
                                    </h1>
                                    <div *ngFor="let y of x.jobHiringMembers">
                                      <p class="fs12 fw500 mt-2">
                                        {{ y.hiringName }}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <!-- <button class="ml-2 recruit-members fs14 pointer" data-toggle="modal" (click)="
                        GetIJPJobApproversPopUpData(
                          x.ijpBasicInformationId
                        )
                      " data-target="#jobApprovers">
                        Job Approvers
                      </button> -->
  
                      <!-- approvers list popup -->
                      <div class="modal fade mt-5 recruit-popup" id="jobApprovers" data-backdrop="static"
                        data-keyboard="false" tabindex="-1" aria-labelledby="jobApproversLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content" style="width: 650px !important">
                            <div class="modal-header">
                              <h1 class="modal-title fs-5 fw600" id="jobApproversLabel">
                                Approvers List
                              </h1>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <svg width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                  <path
                                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                </svg>
                              </button>
                            </div>
                            <div class="modal-body">
                              <div class="d-flex align-items-center">
                                <h4></h4>
                              </div>
                              <div class="table-responsive">
                                <table class="my-new-table col-md-12 mt-2">
                                  <thead>
                                    <tr>
                                      <th class="t-w approver-member" style="padding: 10px; background: #764fdb">
                                        Approval Level
                                      </th>
                                      <th class="t-w approver-member" style="padding: 10px; background: #764fdb">
                                        Approver Member
                                      </th>
                                      <th class="t-w approver-member" style="padding: 10px; background: #764fdb">
                                        Designation
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let x of arraymy; let i = index">
                                      <td class="approver-member" style="padding: 10px">
                                        {{ x.approverLevel }}
                                      </td>
                                      <!-- <td style="padding: 10px">
                                    <ng-container *ngFor="let xy of commaSeperated(x.jobTitle)"> {{xy}} </ng-container>
                                  </td> -->
                                      <td class="" style="padding: 10px">
                                        <div class="p_fontt" *ngFor="
                                            let xy of commaSeperated(
                                              x.approverMemberName
                                            )
                                          ">
                                          {{ xy }}
                                        </div>
                                      </td>
                                      <td class="approver-member" style="padding: 10px">
                                        <div class="p_fontt" *ngFor="
                                            let xy of commaSeperated(
                                              x.designation
                                            )
                                          ">
                                          {{ xy }}
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- approvers list popup -->
                    </div>
                    <!-- <div class="check_recruitment_Members d-flex"> -->
                    <!-- *ngIf="!x.jobSelectType == 'Archive' && !x.jobSelectType == 'Archive' " -->
                    <!-- <p>Check</p> -->
                    <!-- <a href="javascript:void()" class="ml-2 recruit-members fs14">
                    Recruitment members
                  </a>
                  <a href="javascript:void()" class="ml-2 recruit-members fs14">
                    Job Approvers
                  </a>
                </div> -->
                  </div>
                </div>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    <ng-template class="light-blue-bg px-3" *ngIf="jobLists.length == 0">
      <!-- #noData -->
      <p class="light-blue-bg p-3 text-center" >No Records Found</p>
    </ng-template>

    <!-- new table -->
  </div>
  <div class="card flex pagination p-0" *ngIf="jobLists.length > 0">
    <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalCount"
      [rowsPerPageOptions]="[10, 20, 30]" [showJumpToPageInput]="true"></p-paginator>
  </div>
</main>
<div class="">
  <div class="modal copy-link-modal-main fade" id="staticBackdropCopyLink" data-backdrop="static" data-keyboard="false"
    tabindex="-1" aria-labelledby="staticBackdropCopyLinkLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropCopyLinkLabel">
            {{ copyJobTitle }} -({{ copyJobID }})
          </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <svg width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path
                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div class="modal-body" *ngFor="let jobs of ijpJobPostedData; let i = index">
          <div class="copy-link-modal-content">
            <div class="d-flex align-items-center figma-input gap-3">
              <p class="col-md-1">{{ jobs.postjobto }}</p>
              <input class="col-md-2" type="text" class="" [(ngModel)]="jobs.postJobLink" #myInput />
              <p-toast></p-toast>
              <button type="button" class="blue-btn px-3 col-md-2" (click)="copyText(myInput)">
                Copy Link
              </button>
              <!-- qr code -->
              <img style="width: 100px" *ngIf="qrCodes[i]" [src]="qrCodes[i]"
                alt="QR Code for {{ ijpJobPostedData[i].postJobLink }}" class="qr-code-img col-md-2" />

              <button type="button" class="secondary-btn px-3 col-md-3" (click)="
                  downloadQRCodes(qrCodes[i], ijpJobPostedData[i].postjobto)
                ">
                Download QR Code
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="table-dates-partt--tt">
  <div class="modal fade" id="staticBackdropDates" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropDatesLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 *ngIf="postDates.length > 0" class="modal-title fs-5 fw500" id="staticBackdropDatesLabel">
            Manage Publish/Unpublish ({{ postDates[0]?.jobTitleName }}({{
            postDates[0]?.brand_IJPJobId
            }}))
          </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <svg width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path
                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <table class="w-100">
            <thead>
              <th>
                <div class="ps-2">Post Job To</div>
              </th>
              <th>
                <div class="px-3">Publish Start Date</div>
              </th>
              <th>Publish End Date</th>
              <th></th>
              <th></th>
            </thead>
            <div style="margin-bottom: 10px !important"></div>
            <tbody>
              <ng-container *ngFor="let m of postDates; let i = index">
                <tr class="my-1">
                  <td class="figma-ng-select">
                    <ng-select [disabled]="true" name="selectedPostDatesJobb" placeholder="Select post job to"
                      bindlabel="postjobto" bindvalue="postjobto" [(ngModel)]="m.postjobto"></ng-select>
                  </td>
                  <td class="">
                    <div class="figma-calender px-3">
                      <p-calendar [disabled]="true" [readonlyInput]="true" class="position-relative"
                        name="myPublishStartDatee" placeholder="Select date" [(ngModel)]="m.publishstartdate"
                        dateFormat="dd/mm/yy" [ngModelOptions]="{ standalone: true }" #myPublishStartDatee="ngModel"
                        inputId="icon" [showIcon]="true"></p-calendar>
                    </div>
                  </td>
                  <td class="figma-calender">
                    <p-calendar [disabled]="i != value && disableEndDate" [readonlyInput]="true"
                      class="position-relative" name="myPublishStartDatee" placeholder="Select date"
                      [(ngModel)]="m.publishenddate" dateFormat="dd/mm/yy" [ngModelOptions]="{ standalone: true }"
                      #myPublishStartDatee="ngModel" inputId="icon" [showIcon]="true"></p-calendar>
                  </td>
                  <!-- <td>
              <span class="blue-color fs12 fw500 ms-2 pointer" *ngIf="enableEdit && !enableSave" (click)="editEndDate(i,'edit')">Edit</span>
              <span class="blue-color fs12 fw500 mx-2 pointer" *ngIf="enableSave" (click)="updateEndDate(m.id, m.publishenddate)">Save</span>
              <span class="blue-color fs12 fw500 pointer" *ngIf="!enableEdit && enableSave" (click)="editEndDate('-1','cancel')">Cancel</span>
            </td> -->
                  <!-- <td class="">
              <div class="ms-3">
                <button *ngIf="(m.postJobStatus!=null && m.postJobStatus!='Unpublished') || m.postJobStatus=='Published'" (click)="publishJob(m.id, m.publishstartdate, m.publishenddate,'Unpublished',m.ijpBasicInformationId)"
                  type="button"
                  data-dismiss="modal" aria-label="Close"

                  class="blue-btn px-3"
                >
                  Unpublish
                </button>
                <button *ngIf="m.postJobStatus==null || m.postJobStatus=='Unpublished'" (click)="publishJob(m.id, m.publishstartdate, m.publishenddate,'Published',m.ijpBasicInformationId)"
                  type="button"
                  data-dismiss="modal" aria-label="Close"

                  class="blue-btn px-3"
                >
                  Publish
                </button>
              </div>
            </td> -->
                </tr>
                <div style="margin-bottom: 10px !important"></div>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
