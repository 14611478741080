import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
// import { environment } from 'src/app/environments/environment';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class IJPService {


  headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
  loginUserId: any;
  userData: any;
  newUserId: any;

  constructor(private http: HttpClient) {
    let user = localStorage.getItem('token');
    if (user != null) {
      this.userData = jwtDecode(user)
      this.newUserId = this.userData.UserId
  }
    }

    GetTRID(userId:any):Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetTalentRequisitionByTRFStatus?userId=${userId}`, {headers: this.headers})
  }

  onTRIDChange(trid:any):Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetTalentRequisitionById?id=${trid}`, {headers: this.headers})
  }

  getjobdata() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobNames`, { headers: this.headers });
  }

  GetJobPost(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, { headers: this.headers })
  }

    GetJobTitle():Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  GetCountries(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, { headers: this.headers })
  }

  // GetCampus(): Observable<any> {
  //   return this.http.get(`${environment.apiUrl}/api/Master/GetCampusNames`, {headers: this.headers})
  // }

  GetDepartment(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  GetRecruiter(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  GetJobCategory(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetJobcategory?isManagesettings=true`, {headers: this.headers})
  }

  GetNumberOfPositions(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  GetPriority(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  //Employment Related
  GetEmployment(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetEmployment`, {headers: this.headers})
  }


  GetEmployeeDuration(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  GetExpectedJoiningDate(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  GetTypeOfAppointment(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetAppointment`, {headers: this.headers})
  }

  GetEmployeeId(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetRepalcement`, {headers:this.headers})
  }

  GetEmployeeDetails(id:any){
    return this.http.get(`${environment.apiUrl}/api/Master/GetReplacementById?Id=${id}`, {headers:this.headers})

  }

  GetSelectEmployee(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  //Type of Hiring
  GetTypeOfHiring(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetHiring`, {headers: this.headers})
  }

  GetPublishStartDate(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  GetPublishEndDate(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  GetHiringCutOffDate(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  //Hiring SPOC’s
  GetHiringManager(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/User/GetUsersForRole`, {headers: this.headers})
  }

  GetRecruitmentManager(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/User/GetUsersForRole`, {headers: this.headers})
  }

  // GetBusinessPartner(payload: { trid: any; jobtitle: any; mySchool: any; countryName: any; campus: any; department: any; jobCategory: any; priority: any; numberofposition: any; typeofhiring: any; textControl: any; getEmployment: any; typeofdurationn: any; reportingManager: any; appointment: any; mypostJObTo: any; }): Observable<any> {
  //   return this.http.get(`${environment.apiUrl}/api/User/GetUsersForRole`, {headers: this.headers})
  // }

  GetBusinessPartner(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/User/GetHRUsers`, {headers: this.headers})
  }

  GetReportingManager(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/User/GetUsersForRole`, {headers: this.headers})
  }
  // Applicant Eligibility Criteria
  GetPreferredIndustry(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetIndustry`, {headers: this.headers})
  }

    GetIndustryExperience(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

    GetPreferredDomain(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDomain`, {headers: this.headers})
  }

    GetDomainExperience(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

    GetHigherlevelEducation(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetEducationList?isManagesettings=false`, {headers: this.headers})
  }

    GetQualifications(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDegree?isManagesettings=false`, {headers: this.headers})
  }

    GetLanguagesKnown(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetLanguages?isManagesettings=false`, {headers: this.headers})
  }

    GetNationality(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetNationalityList`, {headers: this.headers})
  }

    GetGender(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

      GetNoticePeriod(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

      GetFlexibleRelocate(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

      GetTimeNeededToRelocate(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

      GetPreferredAge(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }

  //Required Documents

      GetDocumentType(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetDocumentType?isManagesettings=true`, {headers: this.headers})
  }

      GetStage(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetHiringStage`, {headers: this.headers})
  }

  //Work Permit Related

        GetWorkPermit(): Observable<any> {
    return this.http.get(`${environment.apiUrl}`, {headers: this.headers})
  }


        GetWorkPermitType(id:any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetWorkPermitTypeByCountryId?id=${id}`, {headers: this.headers})
  }

  GetCandidateCountryList() {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCandidateCountryList`, { headers: this.headers });
}

GetCountry(id:any): Observable<any> {
  return this.http.get(`${environment.apiUrl}/api/Master/GetWorkPermitTypeByCountryId?id=${id}`, {headers: this.headers})
}
GetCurrencyCode(){
  return this.http.get(`${environment.apiUrl}/api/Master/GetCandidateCountryList`,{ headers: this.headers});
}

GetCurrentCode(){
  return this.http.get(`${environment.apiUrl}/api/Master/GetCandidateCountryList`,{ headers: this.headers});

}

GetTalentById(tid: any, jid: any) {
  return this.http.get(`${environment.apiUrl}/api/Candidate/GetTalentById?TalentId=${tid}&JobTitleId=${jid}`, { headers: this.headers });
}

GetJobsByBrandId(data: any) {
  return this.http.get(
    `${environment.apiUrl}/api/Master/GetJobsByBrandId?brandId=${data}`,
    { headers: this.headers }
  );
}

GetPostJobTo(){
  return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIjpJobPost`, { headers: this.headers })
}

PostCreateJob(obj:any): Observable<any> {
  return this.http.post(`${environment.apiUrl}/api/IJPJob/PostIJPBasicInformation`, obj , { headers: this.headers })
}


PostIJPJobReferral(obj:any): Observable<any> {
  return this.http.post(`${environment.apiUrl}/api/IJPJob/PostIJPJobReferral`, obj , { headers: this.headers })
}






GetSkills(id:any): Observable<any>{
  return this.http.get(`${environment.apiUrl}/api/Master/GetSkillNameById?id=${id}` , { headers: this.headers })

}

GetSkillsCategory(){
  return this.http.get(`${environment.apiUrl}/api/Master/GetSkills_Category` , { headers: this.headers })

}

postSkill(obj:any): Observable<any> {
  return this.http.post(`${environment.apiUrl}/api/IJPJob/PostIJPJobDescription`, obj , { headers: this.headers })
}

//Hiring team

GetEmployeeCTCApprover(): Observable<any> {
  return this.http.get(`${environment.apiUrl}/api/User/GetUsersForRole`, {headers: this.headers})
}

GetRecruitingMembers(): Observable<any> {
  return this.http.get(`${environment.apiUrl}/api/IJPJob/GetPostIJPHiringTeam`, {headers: this.headers})
}

GetHiringTeamMember(): Observable<any> {
  return this.http.get(`${environment.apiUrl}/api/User/GetUsersForRole`, {headers: this.headers})
}

  //Target Audience
  GetEligibleDepartment(informationId:any  ): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetDepartmentRole?IJPBasicInformationId=${informationId}    `, {headers: this.headers})
  }

  // GetEligibleRole(informationId:any ): Observable<any> {
  //   return this.http.get(`${environment.apiUrl}/api/IJPJob/GetDepartmentRole?IJPBasicInformationId=${informationId} `, {headers: this.headers})
  // }

  GetIjpHiringStages(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIjpHiringStages`, {headers: this.headers})
  }
  getAllRoleDetails(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Role/GetAllRoleDetails`, {headers: this.headers})
  }
  getInterviewQuestionTemplate(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Master/GetInterviewQuestionTemplate`, {headers: this.headers})
  }

  GetEligibleBrand(id:any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetCountriesOnBrandIds`, {headers: this.headers})
  }

  GetEligibleCountry(id:any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Candidate/GetCountriesOnBrandIds?BrandId=${id}`, {headers: this.headers})
  }

  GetIjpReferralById(id:any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIjpReferralById?Id=${id}`, {headers: this.headers})
  }

  GetEligibleCampus(brand:any,country:any) {
    return this.http.get(`${environment.apiUrl}/api/Master/GetCountrycampuslist?brand=${brand}&country=${country}`, { headers: this.headers });
  }

  PostIJPTargetAudience(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/IJPJob/PostIJPTargetAudience`, obj , { headers: this.headers })
  }
  PostIJPEmploymentCtcApprover(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/IJPJob/PostIJPEmploymentCtcApprover`, obj , { headers: this.headers })
  }
  //Program Approvers

  GetMemberName(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/User/GetUsersForRole`, {headers: this.headers})
  }
  //
  GetAllCountryDetails(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Applicant/GetCountriesPhp`, {headers: this.headers})
  }

  PostIJPJobApprovers(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/IJPJob/PostIJPJobApprovers`,obj , { headers: this.headers })
  }

  GetAllJobId(type:any,UserId:any, pageNO:any, NoOfObjectsPerPage:any): Observable<any>{
 return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIJPJobDetailsListing?Status=${type}&UserId=${UserId}&pageNumber=${pageNO}&numberOfObjectsPerPage=${NoOfObjectsPerPage}`, {headers: this.headers})
  }

  GetIJPApproval(informationId:any, approvalStatus:any, statuss:any ): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/UpdateIJPJobApprovalStatus?IJPBasicInformationId=${informationId}&approvalStatus=${approvalStatus}&status=${statuss}`, {headers: this.headers})
  }

  GetIJPReject(informationId:any, Reason:any, statuss:any ): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/UpdateIJPJobReject?IJPBasicInformationId=${informationId}&status=${statuss}&Reason=${Reason}`, {headers: this.headers})
  }

  GetSearch(UserId:any, Status:any, pageNO:any, NoOfObjectsPerPage:any,searchItem:any ): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIJPJobDetailsSearch?UserId=${UserId}&Status=${Status}&pageNumber=${pageNO}&numberOfObjectsPerPage=${NoOfObjectsPerPage}&searchItem=${searchItem}`, {headers: this.headers})

  }

  GetIJPJobApproversPopUpData(IJPBasicInformationId:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIJPJobApproversPopUpData?IJPBasicInformationId=${IJPBasicInformationId}`, {headers: this.headers})
  }

  GetIJPJobRecruitmentMembersPopUp(IJPBasicInformationId:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIJPJobRecruitmentMembersPopUp?IJPBasicInformationId=${IJPBasicInformationId}`, {headers: this.headers})
  }

  EditIJP(IJPBasicInformationId:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetAllIJPDataById?IJPBasicInformationId=${IJPBasicInformationId}`, {headers: this.headers})
  }

  Reactivate(informationId:any,Reason:any, statuss:any ): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/UpdateIJPJobReject?IJPBasicInformationId=${informationId}&Reason=${Reason}&status=${statuss}`, {headers: this.headers})
  }

  GetUserInfoById(uid:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/User/GetUserInfoById?userId=${uid}`, {headers: this.headers})
  }

  GetIJPJobDetails(type:any,UserId:any, pageNO:any, NoOfObjectsPerPage:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIJPJobDetails?Status=${type}&UserId=${UserId}&pageNumber=${pageNO}&numberOfObjectsPerPage=${NoOfObjectsPerPage}`, {headers: this.headers})
     }

  PostIJPJobReferralDetails(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/IJPJob/PostIJPJobReferralDetails`, obj , { headers: this.headers })
  }


  PublishUnpublish(iJPBasicInformationId:any, status:any,postjobId:any,reason:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/updateIJPJobPostedStatus?iJPBasicInformationId=${iJPBasicInformationId}&status=${status}&postjobId=${postjobId}&reason=${reason}`,{headers: this.headers} )
  }

  updateEndDate(id: any, endDate: any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/api/IJPJob/UpdatePublishEndDate?id=${id}&publishEndDate=${endDate}`,{headers: this.headers} )

  }

  GetIJP_ReferralJobDetails(obj:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/api/IJPJob/GetIJP_ReferralJobDetails` ,obj,{headers: this.headers} )
  }

  getDepartmentRoleFromJobs(jobId:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetDepartmentRoleFromJobs?jobId=${jobId}`, {headers: this.headers})
  }

  addIJPAdditionalInformation(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/IJPJob/AddIJPAdditionalInformation`, obj , { headers: this.headers })
  }

  getIJPAdditionalInformation(jobId:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIJPAdditionalInformation?jobId=${jobId}`, {headers: this.headers})
  }

   
  GetIJPJobDetailsListingCount(userid:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetIJPJobDetailsListingCount?UserId=${userid}`, {headers: this.headers})
  }

  GetJobIdName(jobId:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/api/IJPJob/GetJobIdName?jobId=${jobId}`, {headers: this.headers})
  }

  IjpJobApproverRole(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Role/IjpJobApproverRole`, { headers: this.headers })
  }

  getUserDetailsByRoleId(id:any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/User/GetUserDetailsByRoleId?id=${id}`, { headers: this.headers })
  }
}


