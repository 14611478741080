import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Sidebar } from 'primeng/sidebar';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  tab: any = 'tab1';
  tab1: any;
  tab2: any;
  tab3: any;
  tab4: any;
  tab5: any;
  tab6: any;
  tab7: any;
  tab8: any;
  tab9: any;
  Clicked: any;
  showFiller = false;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateActiveTab();
      }
    });
  }

  ngOnInit(): void {
    const savedTab = localStorage.getItem('activeTab');
    this.tab = savedTab ? savedTab : 'tab1'; // default tab if no saved tab
  }

  onClick(tabNumber: number): void {
    this.tab = `tab${tabNumber}`;
    localStorage.setItem('activeTab', this.tab);
  }

  updateActiveTab(): void {
    const url = this.router.url;
    if (url.includes('dashboard')) {
      this.tab = 'tab1';
    } else if (url.includes('job-listing')) {
      this.tab = 'tab3';
    } else if (url.includes('ijp-employee')) {
      this.tab = 'tab2';
    } else if (url.includes('workspace')) {
      this.tab = 'tab4';
    } else if (url.includes('ptm')) {
      this.tab = 'tab5';
    } else if (url.includes('counseling')) {
      this.tab = 'tab6';
    } else if (url.includes('contacts')) {
      this.tab = 'tab7';
    } else if (url.includes('journals')) {
      this.tab = 'tab8';
    } else if (url.includes('tickets')) {
      this.tab = 'tab9';
    }
    localStorage.setItem('activeTab', this.tab);
  }
  @Output() toggleSideNav = new EventEmitter<void>();
  toggleSideText: any = true;
  toggleSideClick() {
    this.toggleSideText = !this.toggleSideText;
    console.log('Toggle button clicked');
    this.toggleSideNav.emit();
  }
}
