<section class="refer_friend">
  <div class="white-background mb-3" style="padding: 16px 30px; border-radius: 16px;">


  <div class="frined mb-4 pb-3 d-flex w-100">
    <div class="align-self-center">
      <a (click)="backHome()" class="back"
        ><img
          class="me-2 pe-1"
          src="./assets/Images/black-arrow_back.svg"
          alt=""
      /></a>
    </div>
    <div class="d-flex align-items-center">
      <h4 class="fs24 fw700">Refer a Friend</h4>
    </div>
  </div>
  <h4 class="fs24 fw700 mb-2" style="color: #764FDB;">Refer a Friend & get 10%, when they get Hired!</h4>
  <div style="border: 1px solid #ececec; margin-top: 16px; margin-bottom: 16px;"></div>
  <h4 class="fs18 fw600 mt-2 mb-2">AD-GCS Marketing manager</h4>
  <p class="fs12 fw400 tell_us mb-4">
    Tell us about yourself and your friend. Your friend can apply directly form
    the website or will be submitted to the applicant pool and contacted about
    the job.
  </p>

  <p class="fs14 fw600 mb-3">Share</p>
  <div class="d-flex social_icons">
    <!-- <a href="#"><img src="./assets/Images/share.svg" alt="" /></a> -->
    <a href="#"
      ><img src="../../../assets/Images/gmail-img.svg" alt=""
    /></a>
    <a href="#"
      ><img src="../../../assets/Images/linkdin-img.svg" alt=""
    /></a>
    <a href="#"><img src="../../../assets/Images/wtsapp-img.svg" alt="" /></a>
    <a href="#"
      ><img src="../../../assets/Images/face-book.svg" alt=""
    /></a>
    <div class="d-flex align-items-center ms-3 cursor_ponter">
      <img src="../../../assets/Images/copy-icon.svg" alt="" />
      <!-- <a href="#"
        >https://GSF.globalcareers.org/Applicationcareerfr.aspx?JID=1558</a
      > -->
      <p class="fs14 fw600 ms-2" (click)="copyJobLink()"></p>
    </div>
  </div>

  <!-- <div class="job_link d-flex m-0">
    <a href="#"
      ><img src="./assets/Images/bg-white-arrow.svg" alt=""
    /></a>
    <div class="d-flex align-items-center link_job">
      <a href="#"
        >https://GSF.globalcareers.org/Applicationcareerfr.aspx?JID=1558</a
      >
    </div>
    <img src="./assets/Images/bar-code-img.svg" alt="" />


  </div> -->

</div>

  <!-- form.component.html -->
  <form  >
    <div class="friend_form">

      <div class="">
        <div class="white-background mb-3" style="display: flex; flex-direction: column; row-gap: 20px;padding: 16px 30px; border-radius: 16px;">
            <h3 class="fs20 fw700">Your Information</h3>


            <section class="details-basic-info-part mt-3">
              <div class="basic-info-parts py-3 d-flex flex-wrap" style="row-gap: 12px;">
                <div class="field-part">
                  <h2>Email Address</h2>
                  <h3>{{email}}</h3>
                </div>
                <div class="field-part">
                  <h2>First Name </h2>
                  <h3>{{firstName}}</h3>
                </div>
                <div class="field-part">
                  <h2>Last Name </h2>
                  <h3>{{lastName}}</h3>
                </div>
                <div class="field-part">
                  <h2>Mobile Number</h2>
                  <h3>{{phoneCode}} {{phoneNo}}</h3>
                </div>
                <div *ngIf="userCode" class="field-part">
                  <h2>Employee Code</h2>
                  <h3>{{userCode}}</h3>
                </div>

                <div class="field-part">
                  <h2>Campus</h2>
                  <h3>{{campusName}}</h3>
                </div>
              </div>
              </section>
          <!-- Email -->
          <!-- <div class="">
            <label for="email" class="custom_label">Email Address<span class="errorRequired">*</span></label>
            <input
              type="email"
              class="form-control"
              id="email"
              name="email"
              [(ngModel)]="formData.email"
              required
              #emailInput="ngModel"
              (ngModelChange)="emailInput.control.setValidators(emailValidator)"
              (ngModelChange)="emailInput.control.updateValueAndValidity()"
            />
            <!-- Display error message if the email input is invalid, touched, or dirty -->
            <!-- <div *ngIf="emailInput.invalid && (emailInput.touched || emailInput.dirty)" class="text-danger mt-2">
              Please Enter Valid Email
            </div>
          </div> -->

          <!-- First Name -->
          <!-- <div class="">
            <label for="firstName" class="custom_label">First Name<span class="errorRequired">*</span></label>
            <input
              type="text"
              id="firstName"
              class="form-control"
              name="firstName"
              [(ngModel)]="formData.firstName"
              required
              pattern="[A-Za-z]+"
              #firstNameInput="ngModel"
            />
            <div
              *ngIf="
                firstNameInput.invalid &&
                (firstNameInput.touched || firstNameInput.dirty)
              "
              class="text-danger mt-2"
            >
              First name is required.
            </div>
          </div> -->

          <!-- Last Name -->
          <!-- <div class="">
            <label for="lastName" class="custom_label">Last Name<span class="errorRequired">*</span></label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              class="form-control"
              [(ngModel)]="formData.lastName"
              required
              pattern="[A-Za-z]+"
              #lastNameInput="ngModel"
            />
            <div
              *ngIf="
                lastNameInput.invalid &&
                (lastNameInput.touched || lastNameInput.dirty)
              "
              class="text-danger mt-2"
            >
              Last name is required.
            </div>
          </div> -->

          <!-- Mobile Number -->
          <!-- <div class="">
            <label for="mobileNumber" class="custom_label">Mobile Number<span class="errorRequired">*</span></label>
            <input
              type="text"
              id="mobileNumber"
              name="mobileNumber"
              class="form-control"
              [(ngModel)]="formData.mobileNumber"
              (keypress)="OnlyNumbersAllowed($event)"
              required
              pattern="^[0-9]+$"
              #mobilenumberInput="ngModel"
            />
            <div
              *ngIf="
                mobilenumberInput.invalid &&
                (mobilenumberInput.touched || mobilenumberInput.dirty)
              "
              class="text-danger mt-2"
            >
            Please Enter Valid Mobile Number
            </div>
          </div> -->

          <!-- Employee Code -->
          <!-- <div class="">
            <label for="employeeCode" class="custom_label">Employee Code<span class="errorRequired">*</span></label>
            <input
              type="text"
              id="employeeCode"
              name="employeeCode"
              class="form-control"
              [(ngModel)]="formData.employeeCode"
              required
              #employeecodeInput="ngModel"
            />
            <div
              *ngIf="
                employeecodeInput.invalid &&
                (employeecodeInput.touched || employeecodeInput.dirty)
              "
              class="text-danger mt-2"
            >
             Enter Valid Employee Code
            </div>
          </div> -->

          <!-- Campus -->
          <!-- <div class="">
            <label for="campus" class="custom_label">Campus<span class="errorRequired">*</span></label>
            <input
              type="text"
              id="campus"
              name="campus"
              class="form-control"
              [(ngModel)]="formData.campus"
              required
              #campusInput="ngModel"
            />
            <div
              *ngIf="
                campusInput.invalid &&
                (campusInput.touched || campusInput.dirty)
              "
              class="text-danger mt-2"
            >
            Please Enter Campus
            </div>
          </div> -->
        </div>

        <div class="col-md-2"></div>

        <div class="white-background mb-3" style="padding: 16px 30px; border-radius: 16px;">
        <div class="row" style="display: flex; flex-direction: row; row-gap: 12px;">
            <h3 class="fs20 fw700 mb-4">Friend Information</h3>
            <!-- Email -->
            <div class="col-lg-4 col-6 placeholder_styles">
              <label for="email" class="custom_label">Email Address<span class="errorRequired">*</span></label>
              <input
                type="email"
                class="form-control"
                id="friendemail"
                name="friendemail"
                placeholder="Please enter email"
                [(ngModel)]="formData.friendemail"
                required
                #friendemail="ngModel"
                (ngModelChange)="friendemail.control.setValidators(emailValidator)"
                (ngModelChange)="friendemail.control.updateValueAndValidity()"
              />
              <!-- Display error message if the email input is invalid, touched, or dirty -->
              <div *ngIf="friendemail.invalid && (friendemail.touched || friendemail.dirty)" class="text-danger mt-2">
                Please enter valid email
            </div>
            </div>

            <!-- First Name -->
            <div class="col-lg-4 col-6 placeholder_styles">
              <label for="firstName" class="custom_label">First Name<span class="errorRequired">*</span></label>
              <input
                type="text"
                id="friendfirstName"
                class="form-control"
                name="friendfirstName"
                placeholder="Please enter first name"
                [(ngModel)]="formData.friendfirstName"
                required
                pattern="[A-Za-z]+"
                #friendfirstName="ngModel"

              />
              <div
                *ngIf="
                  friendfirstName.invalid &&
                  (friendfirstName.touched || friendfirstName.dirty)
                "
                class="text-danger mt-2"
              >
                First name is required.
              </div>
            </div>

            <!-- Last Name -->
            <div class="col-lg-4 col-6 placeholder_styles">
              <label for="lastName" class="custom_label">Last Name<span class="errorRequired">*</span></label>
              <input
                type="text"
                id="friendlastName"
                name="friendlastName"
                class="form-control"
                placeholder="Please enter last name"
                [(ngModel)]="formData.friendlastName"
                required
                pattern="[A-Za-z\s]+"
                #friendlastName="ngModel"
              />
              <div
                *ngIf="
                  friendlastName.invalid &&
                  (friendlastName.touched || friendlastName.dirty)
                "
                class="text-danger mt-2"
              >
                Last name is required.
              </div>
            </div>

            <!-- Mobile Number -->
            <!-- <div class="col-lg-4 col-6">
              <label for="mobileNumber" class="custom_label">Mobile Number<span class="errorRequired">*</span></label>
              <input
                type="text"
                id="friendMobileNumber"
                name="friendMobileNumber"
                class="form-control"
                [(ngModel)]="formData.friendMobileNumber"
                (keypress)="OnlyNumbersAllowed($event)"
                required
                pattern="^[0-9]+$"
                #friendMobileNumberInput="ngModel"
              />
              <div
                *ngIf="
                  friendMobileNumberInput.invalid &&
                  (friendMobileNumberInput.touched || friendMobileNumberInput.dirty)
                "
                class="text-danger mt-2"
              >
              Please Enter Valid Mobile Number
              </div>
            </div> -->

            <div class="col-lg-4 col-6 figma-ng-select new_figma_select placeholder_styles">
              <label for="mobileNumber" class="custom_label">Mobile Number<span class="errorRequired">*</span></label>
              <div class="input-group">
              <ng-select style="width: 76px;"
                [items]="Allcountry"
                bindLabel="countryCode"
                bindValue="countryCode"
                [(ngModel)]="formData.mobilecode"
                placeholder="+91"
                id="mobilecode"
                name="mobilecode"

                [searchable]="true"
                (change)="updatePhoneNumber()"
              >
                <!-- <ng-template ng-label-tmp>
                  {{ selectedCountry }}
                </ng-template> -->
                <ng-template ng-option-tmp let-item="item">
                  {{ item.countryCode }}
                </ng-template>
              </ng-select>
              <input
                type="text"
                id="friendMobileNumber"
                name="friendMobileNumber"
                class="form-control"
                placeholder="Please enter mobile no"
                [(ngModel)]="formData.friendMobileNumber"
                (keypress)="OnlyNumbersAllowed($event)"
                required
                pattern="^[0-9]+$"
                #friendMobileNumberInput="ngModel"
              />
            </div>
              <div
                *ngIf="
                  friendMobileNumberInput.invalid &&
                  (friendMobileNumberInput.touched || friendMobileNumberInput.dirty)
                "
                class="text-danger mt-2"
              >
                Please enter valid mobile number
              </div>
            </div>

        </div>
      </div>
      </div>

      <!-- Submit Button -->
      <div class="d-flex justify-content-end">
        <button type="submit" class="blue-btn black_btn"  (click)="showDialog()">Submit</button>
      </div>

    </div>
  </form>


    <p-dialog  class="p-0" [(visible)]="visible" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
        <div class="p_content text-center">
            <div class="mb-5">
                <img src="./assets/Images/Vector.svg" alt="">
            </div>

            <h4 class="fs24 fw600 mb-5">Your Referral has been submitted sucessfully</h4>
            <button class="blue-btn green_btn fs16 fw400" (click)="goback()">Back to Dashboard </button>
          </div>
    </p-dialog>

</section>
