<!-- <div class="custom-loader" *ngIf="loading">
    <img src="../../../../assets/Images/gsf-new-logo 2.svg" alt="Loader" />
  </div> -->
  <main class="dashboard-main">
    <section class="dashboard-top-part">
      <div class="white-background p16 br8 mb-3">
        <div class="dashboard-linee d-flex align-items-center gap-2">
          <h1 class="fs26 fw600">Dashboard</h1>
          <svg
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-chevron-down pointer"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
            />
          </svg>
        </div>
        <div class="dashboard-patches mt-2">
          <div class="d-flex flex-row flex-wrap gap-2">
            <ng-container *ngFor="let patch of patchesRowArray; let i = index">
              <div
                class="single-patche d-flex align-items-center gap-2 width-max"
              >
                <h1 class="fw500 fs14">{{ patch.patchName }}</h1>
                <img
                  class="pointer"
                  (click)="removeSinglePatch(i)"
                  src="../../../../assets/Images/grey-cross.svg"
                  alt="grey cross"
                />
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>
    <section class="dashboard-filters white-background p16 br8 mb-3">
        <p-accordion [activeIndex]="0" class="p16">
          <p-accordionTab header="Dashboard Filters">
            <div class="line mx-4 my-2"></div>
            <form [formGroup]="dashboardForm">
              <div class="dashboard-filter-inputs white-background p16">
                <div class="figma-ng-select">
                  <label> School </label>
                  <ng-select
                    (change)="GetBrand('change', $event); GetAllFilters('change')"
                    placeholder="Select school"
                    [items]="brandArray"
                    bindLabel="brandName"
                    [multiple]="false"
                    bindValue="brandId"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="brandFirstObj"
                  >
                  </ng-select>
                </div>
                <div class="figma-ng-select">
                  <label> Country </label>
                  <ng-select
                    (change)="GetCountry($event); GetAllFilters('change')"
                    placeholder="Select country"
                    [multiple]="true"
                    [items]="countryArray"
                    bindLabel="countryName"
                    bindValue="countryId"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="dashboardCountryFilterItem"
                  >
                  </ng-select>
                </div>
                <div class="figma-ng-select">
                  <label> Campus </label>
                  <ng-select
                    (change)="GetCampus($event); GetAllFilters('change')"
                    placeholder="Select campus"
                    [multiple]="true"
                    [items]="campusArr"
                    bindLabel="campusName"
                    bindValue="campusId"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="dashboardCampusFilterItem"
                  >
                  </ng-select>
                </div>
                <div class="figma-ng-select">
                  <label> Job Category </label>
                  <ng-select
                    (change)="GetCategory($event); GetAllFilters('change')"
                    placeholder="Select job category"
                    [multiple]="true"
                    [items]="jobCategoryArr"
                    bindLabel="categoryName"
                    bindValue="jobCategory"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="dashboardJobCategoryFilterItem"
                  >
                  </ng-select>
                </div>
                <div class="figma-ng-select">
                  <label> Department </label>
                  <ng-select
                    (change)="GetDepartment($event); GetAllFilters('change')"
                    placeholder="Select department"
                    [multiple]="true"
                    [items]="departmentArr"
                    bindLabel="departmentName"
                    bindValue="departmentId"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="dashboardDepartmentFilterItem"
                  >
                  </ng-select>
                </div>
                <!-- <div class="figma-ng-select">
                            <label> Job Title </label>
                            <ng-select placeholder="Select"
                                [items]=" dashboardJobTitleFilter"
                                bindLabel="name"
                                bindValue="id"
                                [ngModelOptions]="{standalone: true}" [(ngModel)] ="dashboardJobTitleFilterItem"
                            >
                            </ng-select>
                            </div> -->
                <div class="figma-ng-select">
                  <label> Source </label>
                  <ng-select
                    (change)="GetSourceId($event); GetAllFilters('change')"
                    placeholder="Select source"
                    [multiple]="true"
                    [items]="sourceArr"
                    bindLabel="jopPost_Name"
                    bindValue="jobPostId"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="dashboardSourceFilterItem"
                  >
                  </ng-select>
                </div>
                <div class="dashboard-filter-calendar">
                    <label class="d-flex align-items-center">Date Range </label>
                    <div _ngcontent-ng-c533179670="" class="d-flex align-items-center gap10">
                        <div class="figma-calender w-100">
                            <p-calendar (onSelect)="GetAllFilters('change')" [(ngModel)]="dateRangeFrom" name="dateRangeFrom" [ngModelOptions]="{standalone: true}" placeholder="From" [showIcon]="true"></p-calendar>
                        </div>
                        <div class="figma-calender w-100">
                            <p-calendar (onSelect)="GetAllFilters('change')" [(ngModel)]="dateRangeTo" name="dateRangeTo" [ngModelOptions]="{standalone: true}" placeholder="To" [showIcon]="true"></p-calendar>
                        </div>
    
                    </div>
                </div>
            </div>
            <div class="dasahboardFilterBtn d-flex justify-content-end p16 br8">
                <button type="button" class="secondary-btn" (click)="resetCriteria()"> Clear Filter </button>
                <button type="submit" class="ms-3 blue-btn" (click)="savefilterCriteria()"> Save Criteria </button>
            </div>
            </form>
          </p-accordionTab>
        </p-accordion>
    </section>
    <section class="dashboard-tracker mt-4">
        <div class="d-flex justify-content-between">
          <h2>Tracker Report</h2>
          <div class="d-md-flex align-items-center toggle-switch">
            <label for="" class="me-2">Today’s Status</label>
            <input type="checkbox" role="switch" />
            <label for="" class="ms-2"> Overall Status</label>
          </div>
        </div>
        <div class="line"></div>
        <div class="tracker-report-cards">
          <div class="tracker-card jobs-position white-background p16">
            <p class="fs14 fw600">Jobs ( Positions )</p>
            <p class="fs10 fw500">Active/ Pending/ Closed</p>
            <p class="fs22 fw600 d-flex">
              <span>{{ activeJobs }}</span> / {{ pendingJobs }}/ {{ closedJobs }}
            </p>
            <p class="fs12 fw600">Total Jobs: {{ totalJobs }}</p>
          </div>
          <div class="tracker-card academic white-background p16">
            <p class="fs14 fw600">Academic Vs Non-Academic</p>
            <p class="fs22 fw600">
              <span>{{ academic }}/ {{ nonAcademic }}</span>
            </p>
            <p class="fs12 fw600">
              {{ academicPercentage }}%/ {{ nonAcademicPercentage }}%
            </p>
          </div>
          <div class="tracker-card academic replacment white-background p16">
            <p class="fs14 fw600">New Vs Replacement</p>
            <p class="fs22 fw600">{{ new }}/ {{ replacement }}</p>
            <p class="fs12 fw600">
              {{ newAverageString }}/ {{ replacementAverageString }}
            </p>
          </div>
          <div
            class="tracker-card academic selected-applicants white-background p16"
          >
            <p class="fs14 fw600">Selected Applicants</p>
            <p class="fs22 fw600">{{ totalApplicants }} Applicants</p>
            <p class="fs12 fw600">
              <span class="ms-2">{{ malePercentage }}% Male</span>
              <span class="ms-3">{{ femalePercentage }}% Female</span>
            </p>
          </div>
          <div
            class="tracker-card academic status-distribution white-background p16"
          >
            <p class="fs14 fw600">Status Distribution</p>
            <p class="fs22 fw600">{{ selectednotselectedapplicants }} Applicants</p>
            <p class="fs12 fw600">
              <span class="ms-2">{{ selectedPercentage }}% Selected</span>
              <span class="ms-3">{{ notSelectedPercentage }}% Not Selected</span>
            </p>
          </div>
          <div class="tracker-card jobs-position white-background p16">
            <p class="fs14 fw600">Talent Requisition</p>
            <p class="fs10 fw500">Accepted/ Closed/ Expired</p>
            <p class="fs22 fw600">
              <span>{{ acceptedAverage }}%</span> / {{ jobClosedAverage }}%/
              {{ expiredAverage }}%
            </p>
            <p class="fs12 fw600">Total Jobs: {{ totaltrf }}</p>
          </div>
          <div class="tracker-card jobs-position white-background p16">
            <p class="fs14 fw600">Average Time to Hire</p>
            <p class="fs10 fw500">In Weeks</p>
            <p class="fs22 fw600">3 Weeks</p>
          </div>
          <!-- <div class="tracker-card academic replacment white-background p16">
                    <p class="fs14 fw600">New Vs Replacement</p>
                    <p class="fs22 fw600 mb-2">136/ 34</p>
                    <p class="fs12 fw600">80%/ 20%</p>
                </div> -->
        </div>
    </section>
    <section class="dashboard-stages white-background br8 my-3">
        <div class="dashboard-chart ms-2 p16">
            <div class="chart-filter-btn">
              <div class="chart-head">
                <p class="fs18 fw600 secondary-black">Stages Overview</p>
                <div class="line my-4"></div>
                <p class="fs12 grey1 number-of-applicants">Number of Applicants</p>
              </div>
                <p-tabView [activeIndex]="1">
                  <!-- <p-tabPanel header="Overall">
                                      <canvas baseChart
                                      [datasets]="barChartData"
                                      [labels]="barChartLabels"
                                      [options]="barChartOptions"
                                      [legend]="barChartLegend"
                                      [type]="barChartType">
                                      </canvas>
                                  </p-tabPanel> -->
                  <p-tabPanel>
                    <ng-template pTemplate="header">
                      <span (click)="GetStagesOverviewByCountry(mainObject, 'Brand')"
                        >Brand</span
                      >
                    </ng-template>
                    <canvas class="mt-4 ps-5 w-100"
                      baseChart
                      [datasets]="barChartData"
                      [labels]="barChartLabels"
                      [options]="barChartOptions"
                      [legend]="barChartLegend"
                      [type]="barChartType"
                    >
                    </canvas>
                  </p-tabPanel>
                  <p-tabPanel>
                    <ng-template pTemplate="header">
                      <span
                        (click)="GetStagesOverviewByCountry(mainObject, 'Country')"
                        >Country</span
                      >
                    </ng-template>
                    <canvas class="mt-4 ps-5 w-100"
                      baseChart
                      [datasets]="barChartData"
                      [labels]="barChartLabels"
                      [options]="barChartOptions"
                      [legend]="barChartLegend"
                      [type]="barChartType"
                    >
                    </canvas>
                  </p-tabPanel>
                  <p-tabPanel>
                    <ng-template pTemplate="header">
                      <span (click)="GetStagesOverviewByCountry(mainObject, 'Campus')"
                        >Campus</span
                      >
                    </ng-template>
                    <canvas class="mt-4 ps-5 w-100"
                      baseChart
                      [datasets]="barChartData"
                      [labels]="barChartLabels"
                      [options]="barChartOptions"
                      [legend]="barChartLegend"
                      [type]="barChartType"
                    >
                    </canvas>
                  </p-tabPanel>
                </p-tabView>
            </div>
          </div>
    </section>
    <section class="dasahboard-sourceOfHire white-background p16 br8 mt-3 position-relative">
        <div class="dashboard-chart p16">
          <div class="chart-filter-btn">
            <div class="chart-head">
              <p class="fs18 fw600 secondary-black">Source Of Hire</p>
              <p class="fs12 grey1 number-of-applicants">Number of Applicants</p>
            </div>
            <div class="line my-3"></div>
            <div class="chart-container">
              <p-tabView>
                <p-tabPanel header="Number">
                  <ng-template pTemplate="header">
                    <span (click)="GetSourceHire(mainObject, 'Number')"
                      >Number</span
                    >
                  </ng-template>
                  <canvas class="mt-4 ps-5 w-100"
                    baseChart
                    [datasets]="barChartData1"
                    [labels]="barChartLabels1"
                    [options]="barChartOptions1"
                    [legend]="barChartLegend1"
                    [type]="barChartType1"
                    [plugins]="barChartPlugins"
                  >
                  </canvas>
                </p-tabPanel>
                <p-tabPanel header="Percentage">
                  <ng-template pTemplate="header">
                    <span (click)="GetSourceHire(mainObject, 'Percentage')"
                      >Percentage</span
                    >
                  </ng-template>
                  <canvas class="mt-4 ps-5 w-100"
                    baseChart
                    [datasets]="barChartDataPercentage"
                    [labels]="barChartLabelsPercentage"
                    [options]="barChartOptionsPercentage"
                    [legend]="barChartLegendPercentage"
                    [type]="barChartTypePercentage"
                    [plugins]="barChartPlugins1"
                  >
                  </canvas>
                </p-tabPanel>
              </p-tabView>
            </div>
          </div>
        </div>
      </section>
      <section class="dashboard-hiringTrend white-background p16 br8 my-3">
        <div class="row gx-2">
            <aside class="col-md-3 p16">
                <p class="fs16 fw600 black">Overall</p>
                <div class="line"></div>
                <div *ngFor="let country of countries" class="field-checkbox">
                    <p-checkbox [label]="country.name" name="group" [value]="country" [(ngModel)]="selectedCountries" class="grey fs14"></p-checkbox>
                </div>
            </aside>
            <div class="col-md-9">
                <div class="dashboard-chart ms-2 p16">
                    <div class="chart-filter-btn">
                        <div class="chart-head">
                            <p class="fs18 fw600 secondary-black">Hiring Trend </p>
                            <!-- <p class="fs12 grey1 number-of-applicants number-of-applicants-hiring-trend">Number of Applicants Hired</p> -->
                        </div>
                        <div class="line my-3"></div>
                        <div class="chart-container">
                          <div class="month-year-tabs">
                          <p-tabView>
                            <p-tabPanel header="Month">
                                <!-- <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </p> -->
                            </p-tabPanel>
                            <p-tabPanel header="Year">
                                <!-- <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
                                    ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                                </p> -->
                            </p-tabPanel>
                            </p-tabView>
                          </div>
                          <div class="ps-5">
                            <p-tabView [activeIndex]="1">
                                <p-tabPanel header="Overall">
                                    <p-chart class="w-100" type="line" [data]="data1" [options]="options1"></p-chart>
                                </p-tabPanel>
                                <p-tabPanel header="Country">
                                    <p-chart type="line" [data]="data1" [options]="options1"></p-chart>

                                </p-tabPanel>
                                <p-tabPanel header="Brand">
                                    <p-chart class="w-100" type="line" [data]="data1" [options]="options1"></p-chart>

                                </p-tabPanel>
                                <p-tabPanel header="Campus">
                                    <p-chart class="w-100" type="line" [data]="data1" [options]="options1"></p-chart>

                                </p-tabPanel>
                            </p-tabView>

                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section class="dashboard-positionsGlobal white-background p16 br8 my-3">
        <div class="row gx-2">
          <aside class="col-3 pt-0 p-30-60 position-relative">
            <p-chart type="doughnut" [data]="data2" [options]="options2"></p-chart>
            <p class="totalData">{{ totalPositions }} <span>Total <br> Positions</span></p>
            <div class="dashboard-positionsStatus">
              <!-- <button>{{ totalClosed }} Closed</button>
              <button>{{ totalOpen }} Open</button> -->
              <div class="closed-open-positions">
                <div class="active-card card">
                  <div class="d-flex align-items-center gap-2">
                    <div class="box"></div>
                    <p class="fw500 fs16"> 20 </p>
                    <p class="fw500 fs16"> Active </p>
                </div>
                </div>
                <div class="active-card closed-card card mt-3">
                  <div class="d-flex align-items-center gap-2">
                    <div class="box"></div>
                    <p class="fw500 fs16"> 20 </p>
                    <p class="fw500 fs16"> Active </p>
                </div>
                <div class="closed-with-applicant ms-3 mt-3">
                  <div class="d-flex align-items-center">
                    <h1 class="fw500 fs16"> 60 </h1>
                    <h2 class="fw400 fs12 grey ms-2"> Closed with Applicant </h2>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <h1 class="fw500 fs16"> 60 </h1>
                    <h2 class="fw400 fs12 grey ms-2"> Directly closed </h2>
                  </div>
                </div>
                </div>
              </div>
              <!-- <button>207 On Hold</button> -->
            </div>
          </aside>
          <div class="col-9">
            <div class="dashboard-chart my-dashboard-chart ms-2 p16">
              <div class="chart-filter-btn">
                <div class="chart-head">
                  <p class="fs18 fw600 secondary-black">
                    Open and Closed Positions Globally
                  </p>
                  <!-- <p class="fs12 grey1 number-of-applicants number-of-applicants-two">Number of Applications</p> -->
                </div>
                <p-tabView [activeIndex]="1">
                  <!-- <p-tabPanel header="Overall">
                                    <canvas baseChart
                                    [datasets]="barChartData2"
                                    [labels]="barChartLabels2"
                                    [options]="barChartOptions2"
                                    [legend]="barChartLegend2"
                                    [type]="barChartType2">
                                    </canvas>
                                </p-tabPanel> -->
    
                  <!-- <p-tabPanel header="Brand">
                    <ng-template pTemplate="header">
                      <span (click)="GetOpenClosedPositions(mainObject, 'Brand')"
                        >Brand</span
                      >
                    </ng-template>
                    <canvas
                      baseChart
                      [datasets]="barChartData2"
                      [labels]="barChartLabels2"
                      [options]="barChartOptions2"
                      [legend]="barChartLegend2"
                      [type]="barChartType2"
                    >
                    </canvas>
                  </p-tabPanel>
                  <p-tabPanel header="Country">
                    <ng-template pTemplate="header">
                      <span (click)="GetOpenClosedPositions(mainObject, 'Country')"
                        >Country</span
                      >
                    </ng-template>
                    <canvas
                      baseChart
                      [datasets]="barChartData2"
                      [labels]="barChartLabels2"
                      [options]="barChartOptions2"
                      [legend]="barChartLegend2"
                      [type]="barChartType2"
                    >
                    </canvas>
                  </p-tabPanel>
                  <p-tabPanel header="Campus">
                    <ng-template pTemplate="header">
                      <span (click)="GetOpenClosedPositions(mainObject, 'Campus')"
                        >Campus</span
                      >
                    </ng-template>
                    <canvas
                      baseChart
                      [datasets]="barChartData2"
                      [labels]="barChartLabels2"
                      [options]="barChartOptions2"
                      [legend]="barChartLegend2"
                      [type]="barChartType2"
                    >
                    </canvas>
                  </p-tabPanel> -->
                  <!-- active close tabs -->
                  <p-tabPanel header="Brand">
                    <ng-template pTemplate="header">
                      <span (click)="GetOpenClosedPositions(mainObject, 'Brand')"
                        >Active</span
                      >
                    </ng-template>
                    <canvas class="w-100"
                      baseChart
                      [datasets]="barChartData2"
                      [labels]="barChartLabels2"
                      [options]="barChartOptions2"
                      [legend]="barChartLegend2"
                      [type]="barChartType2"
                    >
                    </canvas>
                  </p-tabPanel>
                  <p-tabPanel header="Country">
                    <ng-template pTemplate="header">
                      <span (click)="GetOpenClosedPositions(mainObject, 'Country')"
                        >Closed</span
                      >
                    </ng-template>
                    <canvas class="w-100"
                      baseChart
                      [datasets]="barChartData2"
                      [labels]="barChartLabels2"
                      [options]="barChartOptions2"
                      [legend]="barChartLegend2"
                      [type]="barChartType2"
                    >
                    </canvas>
                  </p-tabPanel>
                  <!-- active close tabs -->
                </p-tabView>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="dashboard-entityBreakup white-background p16 br8">
        <p class="fs18 fw600 secondary-black">
          New Vs Replacement
        </p>
        <div class="line my-4"></div>
        <div class="row gx-2">
          <aside class="col-3 p-30-60 position-relative canvas--height">
            <p-chart type="doughnut" [data]="data2" [options]="options2"></p-chart>
            <p class="totalData totalData-2">{{ totalPositions }} <span>Total <br> Positions</span></p>
            <div class="dashboard-positionsStatus">
              <!-- <button>{{ totalClosed }} Closed</button>
              <button>{{ totalOpen }} Open</button> -->
              <div class="closed-open-positions mt-3">
                <div class="active-card card width-max">
                  <div class="d-flex align-items-center gap-2">
                    <div class="box"></div>
                    <p class="fw500 fs16"> 20 </p>
                    <p class="fw500 fs16"> Active </p>
                </div>
                </div>
                <div class="active-card card width-max">
                  <div class="d-flex align-items-center gap-2">
                    <div class="box replacement-color"></div>
                    <p class="fw500 fs16"> 200 </p>
                    <p class="fw500 fs16"> Replacement </p>
                </div>
                </div>
    
              </div>
              <!-- <button>207 On Hold</button> -->
            </div>
          </aside>
          <!-- <aside class="col-2 p16 max-heightt">
            <div class="d-flex align-items-center toggle-switch mb-4"> -->
              <!-- <label for="" class="me-2">Department wise</label>
                        <input type="checkbox" role="switch"> -->
            <!-- </div>
    
            <p class="fs16 fw600 black">Showing</p>
            <div class="line"></div>
            <div *ngFor="let department of departments" class="field-checkbox">
              <p-checkbox
                [label]="department.name"
                name="group"
                [value]="department"
                [(ngModel)]="departmentWise"
                class="grey fs14"
              ></p-checkbox>
            </div>
          </aside> -->
          <div class="col-9">
            <div class="dashboard-chart ms-2 p16">
    
    
              <div class="chart-filter-btn new-replacement--tabss">
                <div class="chart-head">
                  <!-- <p class="fs12 grey1 number-of-applicants number-of-applicants-four">Number of Applicants</p> -->
                </div>
                <p-tabView [activeIndex]="1">
                  <!-- <p-tabPanel header="Overall">
                                    <canvas baseChart
                                    [datasets]="barChartData3"
                                    [labels]="barChartLabels3"
                                    [options]="barChartOptions3"
                                    [legend]="barChartLegend3"
                                    [type]="barChartType3">
                                    </canvas>
                                </p-tabPanel> -->
    
                  <p-tabPanel header="Brand">
                    <ng-template pTemplate="header">
                      <span (click)="GetNewReplacementEntity(mainObject, 'Brand')"
                        >Brand</span
                      >
                    </ng-template>
                    <canvas class="ps-5 w-100"
                      baseChart
                      [datasets]="barChartData3"
                      [labels]="barChartLabels3"
                      [options]="barChartOptions3"
                      [legend]="barChartLegend3"
                      [type]="barChartType3"
                    >
                    </canvas>
                  </p-tabPanel>
                  <p-tabPanel header="Country">
                    <ng-template pTemplate="header">
                      <span (click)="GetNewReplacementEntity(mainObject, 'Country')"
                        >Country</span
                      >
                    </ng-template>
                    <canvas class="ps-5 w-100"
                      baseChart
                      [datasets]="barChartData3"
                      [labels]="barChartLabels3"
                      [options]="barChartOptions3"
                      [legend]="barChartLegend3"
                      [type]="barChartType3"
                    >
                    </canvas>
                  </p-tabPanel>
                  <p-tabPanel header="Campus">
                    <ng-template pTemplate="header">
                      <span (click)="GetNewReplacementEntity(mainObject, 'Campus')"
                        >Campus</span
                      >
                    </ng-template>
                    <canvas class="ps-5 w-100"
                      baseChart
                      [datasets]="barChartData3"
                      [labels]="barChartLabels3"
                      [options]="barChartOptions3"
                      [legend]="barChartLegend3"
                      [type]="barChartType3"
                    >
                    </canvas>
                  </p-tabPanel>
                </p-tabView>
    
                <p-tabView> </p-tabView>
              </div>
          </div>
          </div>
        </div>
      </section>
      <section class="dasahboard-averagTat white-background p16 br8 mt-3">
        <div class="dashboard-chart p16">
          <div class="chart-filter-btn">
            <div class="chart-head">
              <p class="fs18 fw600 secondary-black">Average TAT (In days)</p>
              <p class="fs12 grey1">Number of Applicants</p>
            </div>
            <div class="chart-container">
              <p-tabView [activeIndex]="1">
                <!-- <p-tabPanel header="Overall">
                                <canvas baseChart
                                [datasets]="barChartData4"
                                [labels]="barChartLabels4"
                                [options]="barChartOptions4"
                                [legend]="barChartLegend4"
                                [type]="barChartType4"
                                [plugins]="barChartPlugins">
                                </canvas>
                            </p-tabPanel> -->
                <p-tabPanel header="Brand">
                  <ng-template pTemplate="header">
                    <span (click)="GetAverageTAT(mainObject, 'Brand')">Brand</span>
                  </ng-template>
                  <canvas class="w-100"
                    baseChart
                    [datasets]="barChartData4"
                    [labels]="barChartLabels4"
                    [options]="barChartOptions4"
                    [legend]="barChartLegend4"
                    [type]="barChartType4"
                    [plugins]="barChartPlugins"
                  >
                  </canvas>
                </p-tabPanel>
                <p-tabPanel header="Country">
                  <ng-template pTemplate="header">
                    <span (click)="GetAverageTAT(mainObject, 'Country')"
                      >Country</span
                    >
                  </ng-template>
                  <canvas class="w-100"
                    baseChart
                    [datasets]="barChartData4"
                    [labels]="barChartLabels4"
                    [options]="barChartOptions4"
                    [legend]="barChartLegend4"
                    [type]="barChartType4"
                    [plugins]="barChartPlugins"
                  >
                  </canvas>
                </p-tabPanel>
                <p-tabPanel header="Campus">
                  <ng-template pTemplate="header">
                    <span (click)="GetAverageTAT(mainObject, 'Campus')"
                      >Campus</span
                    >
                  </ng-template>
                  <canvas class="w-100"
                    baseChart
                    [datasets]="barChartData4"
                    [labels]="barChartLabels4"
                    [options]="barChartOptions4"
                    [legend]="barChartLegend4"
                    [type]="barChartType4"
                    [plugins]="barChartPlugins"
                  >
                  </canvas>
                </p-tabPanel>
              </p-tabView>
            </div>
          </div>
        </div>
        <div class="dashboard-table">
          <h2 class="black fw700 fs18 mb-4">Global Joiners</h2>
          <div class="line"></div>
          <!-- <div class="d-flex table-filter">
            <div class="search-inputt me-3 relative">
              <input type="text" class="w-100" placeholder="Search" />
              <img
                class="img-fluid absolute"
                src="../../../../assets/Images/search.svg"
                alt="search icon"
              />
            </div>
            <div>
              <app-pop-up
                [header]="'Second Modal Header'"
                [content]="'Content for the second modal'"
                [imageSrc]="'../../../assets/Images/filter-black.svg'"
                [buttonText]="'Filter'"
                [buttonClass]="'filters-button'"
              >
              </app-pop-up>
            </div>
          </div> -->
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Entity</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                  <th class="empty-tr-td"></th>
                  <th>Total</th>
                  <th>Average TAT</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of dashboardTable; let i = index">
                  <td>{{ x.entity }}</td>
                  <td>{{ x.jan }}</td>
                  <td>{{ x.feb }}</td>
                  <td>{{ x.march }}</td>
                  <td>{{ x.apr }}</td>
                  <td>{{ x.may }}</td>
                  <td>{{ x.june }}</td>
                  <td>{{ x.july }}</td>
                  <td>{{ x.aug }}</td>
                  <td>{{ x.sept }}</td>
                  <td>{{ x.oct }}</td>
                  <td>{{ x.nov }}</td>
                  <td>{{ x.dec }}</td>
                  <td class="empty-tr-td"></td>
                  <td>{{ x.total }}</td>
                  <td>{{ x.average }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
  </main>