import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-job-approvers',
  templateUrl: './job-approvers.component.html',
  styleUrls: ['./job-approvers.component.scss']
})
export class JobApproversComponent {

  approversTableData : any[] = [
    { id: "1", position: 'Manager', name: "John Doe", email: "john@gmail.com"},
    { id: "2", position: 'Manager', name: "John Doe", email: "john@gmail.com"},
    { id: "3", position: 'Manager', name: "John Doe", email: "john@gmail.com"},
    { id: "4", position: 'Manager', name: "John Doe", email: "john@gmail.com"},

  ]
}
