import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss'],
})
export class ChartsComponent implements OnInit {
  data: any;

  basicData: any;
  basicOptions: any;

  multiAxisData: any;
  multiAxisOptions: any;

  public chart: any = null;

  dataTwo: any;

  chartOptions: any;

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
  };

  public mbarChartLabels: string[] = ['India', 'UAE', 'China'];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;
  // router: any;

  constructor() {}

  ngOnInit(): void {
    // charts
    this.data = {
      labels: ['India', 'UAE', 'China'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [95, 59, 80],
        },
      ],
    };

    this.basicData = {
      labels: ['India', 'UAE', 'China'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: '#42A5F5',
          data: [65, 59, 50],
        },
        {
          label: 'My Second dataset',
          backgroundColor: '#FFA726',
          data: [78, 48, 40],
        },
      ],
    };

    this.multiAxisData = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'Dataset 1',
          backgroundColor: [
            '#EC407A',
            '#AB47BC',
            '#42A5F5',
            '#7E57C2',
            '#66BB6A',
            '#FFCA28',
            '#26A69A',
          ],
          yAxisID: 'y',
          data: [65, 59, 80, 81, 56, 55, 10],
        },
      ],
    };

    this.dataTwo = {
      labels: ['India', 'UAE', 'China'],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
          hoverBackgroundColor: ['#64B5F6', '#81C784', '#FFB74D'],
        },
      ],
    };

    // charts
  }

  // donutChartData = [
  //   {
  //     label: 'Liverpool FC',
  //     value: 9,
  //     color: 'red',
  //   },
  //   {
  //     label: 'Real Madrid	',
  //     value: 13,
  //     color: 'orange',
  //   },
  //   {
  //     label: 'FC Bayern München',
  //     value: 5,
  //     color: 'blue',
  //   },
  // ];
  // donutChartDataa = [
  //   {
  //     label: 'Liverpool FC',
  //     value: 15,
  //     color: 'red',
  //   },
  //   {
  //     label: 'Real Madrid	',
  //     value: 59,
  //     color: 'green',
  //   },
  //   {
  //     label: 'FC Bayern München',
  //     value: 50,
  //     color: 'blue',
  //   },
  // ];




   dataA = {
    labels: [
      'Pink',
      'Light Blue',
      'Yellow'
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [200, 50, 100],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'
      ],
      hoverOffset: 4
    }]
  };
   dataB = {
    labels: [
      'Mahogany',
      'Yellow Orange',
      'Blue',
      'Green',
      'Violet',
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [150, 80, 58, 72, 95 ],
      backgroundColor: [
        'rgb(192, 64, 0)',
        'rgb(255, 170, 71)',
        'rgb(0, 118, 255)',
        'rgb(139, 176, 40)',
        'rgb(220, 12, 240)',
      ],
      hoverOffset: 4
    }]
  };



}
