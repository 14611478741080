<!-- charts -->
<div>
  <div class="card col-md-3">
    <p-chart type="bar" [data]="data" height=300px width=100%></p-chart>

  </div>
  <div class="card col-md-6">
    <p-chart type="bar" [data]="basicData" [options]="basicOptions" [style]="{'height':'40%', 'width':'50%'}"></p-chart>
  </div>
</div>
<div class="card col-md-6">
  <h5>Multi Axis</h5>
  <p-chart type="bar" [data]="multiAxisData" [options]="multiAxisOptions"
    [style]="{'height':'40%', 'width':'50%'}"></p-chart>
</div>
<div class="card col-md-6 pie-chaRT">
  <p-chart type="pie" [data]="dataTwo" [options]="chartOptions"
    [style]="{'width': '300px', 'height': '300px'}"></p-chart>
</div>


<!-- charts -->


<!-- doughnut charts -->
<!-- <div class="d-flex">
  <div class="col-md-4">
    <ngx-donut-chart [data]="donutChartData" [title]="'Sessions'" [radius]="95" [thickness]="20" [margin]="30">
    </ngx-donut-chart>
  </div>
  <div class="col-md-4">
    <ngx-donut-chart [data]="donutChartDataa" [title]="'Sessions'" [radius]="95" [thickness]="20" [margin]="30">
    </ngx-donut-chart>
  </div>
</div> -->

<div class="d-flex" style="margin-top: 50px;">
  <p-chart type="doughnut" [data]="dataA" [options]="chartOptions" width=400px height=400px></p-chart>
  <p-chart type="doughnut" [data]="dataB" [options]="chartOptions" width=400px height=400px></p-chart>
</div>
<!-- doughnut charts -->
