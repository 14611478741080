import { ComponentType } from '@angular/cdk/portal';
import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, NgForm, ValidationErrors } from '@angular/forms';
import { ReportsService } from 'src/app/services/reports.service';
import { jwtDecode } from 'jwt-decode';

import { IJPService } from 'src/app/services/IJP.service';
import { CandidateService } from 'src/app/services/candidate.service'
import { Router } from '@angular/router';

interface formData {
  friendemail: '',
  friendfirstName: '',
  friendlastName: '',
  friendMobileNumber: '',
  mobilecode: null
};

@Component({
  selector: 'app-referal-friend-form',
  templateUrl: './referal-friend-form.component.html',
  styleUrls: ['./referal-friend-form.component.scss']
})
export class ReferalFriendFormComponent {
  myForm: any;
  formattedPhoneNumber: any; userId: any;
  selectedCountry: any;
  countryCodes: any; userDetails: any
  lastSegment: any;
  formData!: formData;

  constructor(private IJPService: IJPService, private router: Router, public reportsService: ReportsService, public candidateService: CandidateService) {
    this.formData = {

      friendemail: '',
      friendfirstName: '',
      friendlastName: '',
      friendMobileNumber: '',
      mobilecode: null
    };
  }
  ngOnInit(): void {
    var url = window.location.href
    this.lastSegment = url.split("/").pop();
    let userData = this.reportsService.getUserId();
    this.userDetails = jwtDecode(userData);
    this.userId = this.userDetails.UserId;
    this.GetUserInfoById(this.userId)
    this.getCountriesnew()
  }

  Allcountry: any = [];
  getCountriesnew() {
    this.candidateService.getCountriesnew().subscribe((res: any) => {
      if ((res.status = true)) {
        this.Allcountry = res.data
      }
    });
  }



  backHome() {
    this.router.navigateByUrl('referal-job/' + this.lastSegment)
    // window.location.href='referal-job/'+this.lastSegment;
  }
  email: any; phoneCode: any; phoneNo: any; campusName: any = []; firstName: any; lastName: any; userCode: any;
  GetUserInfoById(uid: any) {
    this.IJPService.GetUserInfoById(uid).subscribe((res) => {
      console.log(res, '----')
      this.email = res.userName
      this.phoneCode = res.phoneCode
      this.phoneNo = res.phoneNo
      this.campusName = res.campusName
      this.firstName = res.firstName
      this.lastName = res.lastName
      this.userCode = res.userCode
    })
  }


  // Get a reference to the form

  @ViewChild('referralForm') referralForm!: NgForm;

  // Method to handle form submission
  onSubmit(form: NgForm): void {
    if (form.valid) {
      console.log('Form submitted successfully!', this.formData);
      form.form.reset();
    }
  }

  jobLinkUrl: string = 'https://GSF.globalcareers.org/Applicationcareerfr.aspx?JID=1558';

  copyJobLink(): void {
    navigator.clipboard.writeText(this.jobLinkUrl)
      .then(() => {
        // alert('Job link copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy the job link:', err);
      });
  }

  visible: boolean = false;

  showDialog() {
    console.log(this.formData, 'shabb')
console.log(this.userId,this.lastSegment)
    var objnew = {
      "ijpBasicInformationId": this.lastSegment,
      "userId": this.userId,
      "emailId": this.formData.friendemail,
      "firstName": this.formData.friendfirstName,
      "lastName": this.formData.friendlastName,
      "mobileNoCode": this.formData.mobilecode,
      "mobileNumber": this.formData.friendMobileNumber,
      "status": true
    }
    this.IJPService.PostIJPJobReferralDetails(objnew).subscribe((res) => {
      if (res) {
        this.visible = true;

      }
    })

  }

  goback() {
    window.location.href = 'referal-form';
  }


  OnlyNumbersAllowed(event: KeyboardEvent): boolean {
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key.startsWith('Arrow')) {
      return true;
    }
    return /[0-9]/.test(event.key);
  }

  emailValidator(control: AbstractControl): ValidationErrors | null {
    const email = control.value;
    if (email && email.includes('@') && email.includes('.')) {
      const domain = email.split('@')[1];
      if (domain.includes('.')) {
        return null; // Valid email with domain name
      }
    }
    return { invalidEmail: true }; // Invalid email or missing domain name
  }

  updatePhoneNumber() {
    // Concatenate selected country code with the phone number
    this.formattedPhoneNumber = this.selectedCountry + this.formData.friendMobileNumber;
  }
}
