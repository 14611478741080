import { Component, Injector, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { candidateService } from 'src/app/services/candidateService';
import { commonService } from 'src/app/services/commonService';

@Component({
  selector: 'app-immigration',
  templateUrl: './immigration.component.html',
  styleUrls: ['./immigration.component.scss']
})
export class ImmigrationComponent {
  tabb :any = 'tabb5';
  @ViewChild('profileBasicInfoForm', { static: false }) profileBasicInfoForm: NgForm;

  @ViewChild('newworkExperienceForm', { static: false }) newworkExperienceForm: NgForm;

  @ViewChild('applicantWorkhistoryForm', { static: false }) applicantWorkhistoryForm: NgForm;

  @ViewChild('newEducationalForm', { static: false }) newEducationalForm: NgForm;

  valcurretsal: any;
  newvaltosalary: any;
  uploadVisaFile: boolean = false;
  uploadPassportFile: boolean = false;
  uploadAlldocsFile: boolean = false;
  immigvalidation: boolean = false;
  enableNextButtons: boolean = false;
  resumeData: string;
  docPathel: any;
  docNameel: any;
  enableDoc: boolean = false

  ngOnInit(){
    this.getCountriesnew();
    this.GetDocumentByNationalityId();
    this.getEthicReligionList();
    this.nnewNewBasicInfoo.phoneNumbercode = '+91';
    this.newNewWorkkInfoo.countrycode = '+91';
    this.GetDomain();
    this.GetIndustry();
    this.getLevelOfEducation();
    this.getDegree();
    this.GetYearsDetails();
    this.GetSkills_Category();
    this.getLanguages();
    // this.GetJobDocumentTypeDocumentNameDetails();
    this.resumeData = localStorage.getItem('resumeData');
    console.log(this.resumeData,"=========<ng-container><ng-container><ng-container>=====resumeData")
    if (this.resumeData!=null) {
      var Datares=JSON.parse(this.resumeData)
      this.nnewNewBasicInfoo.profileFirstName=Datares.name,
      this.nnewNewBasicInfoo.profileEmailID=Datares.email,
      this.docPathel=Datares.resumePath,
      this.docNameel=Datares.resumeName,
      this.nnewNewBasicInfoo.phoneNumbercode=Datares.phoneCode
      this.nnewNewBasicInfoo.phoneNumber=Datares.phoneNo
      this.enableDoc=true
    }else{
      this.enableDoc=false
    }
    var today = new Date();
    var Age = 18;
    this.minAgee = new Date(
      today.getFullYear() - Age,
      today.getMonth(),
      today.getDate()
    );
  }
  minAgee : any;
  closeEl() {
    this.enableDoc = false
  }

  constructor(public router : Router,  public candidateService: candidateService, public commonService : commonService,
    injector: Injector,){
      // super(injector);
    this.documentRender = [
      {
        selectDocType: null,
        selectDocName: null,
      },
    ];
    this.newNewWorkkInfoo.aanotherIndustries = [
      {
        newAppprofileIndustryyy: null,
        newAppprofileIndustryExppp: null,
      },
    ];
    this.newNewWorkkInfoo.aanotherDomainsss = [
      {
        newAppprofileDomainnn: null,
        newAppprofileIndustryyExppp: null,
      },
    ];

    this.newNewEducationalInfoo.aanotherDegrees = [
      {
        newProfileHiggherEducationnnn: null,
        newProfileHigherQualificationnn: null,
        profileeYearGraduationn: null,
        profileCollegeNammee: null,
      },
    ];
    this.newNewEducationalInfoo.addMoreSkills = [
      {
        newAddAppSkillsCategoryyy: null,
        newAddAppSkillsss: null,
        skill_list: []
      },
    ];
    this.newNewEducationalInfoo.addMorelanguages = [
      {
        newProfileHigherLanguagesss: null,
        profileFormLanguage: 'Oral',
        newProfilelangProfiiencyyy: null,
        profileFormLanguageTwo: 'Written',
        newProfilelangProfiiencyyyTwo: null,
      },
    ];
  }

  newAppback(){
    localStorage.removeItem('resumeData');
    this.router.navigateByUrl('/candidate/candidates')
  }
  newOnnewRemove(event) {
    console.log(event);
    this.NewnewResumeFiles.splice(this.NewnewResumeFiles.indexOf(event), 1);
  }

  newProfilee = true;
  newEducationalInformationn = false;
  newWorkExperiencee = false;
  familyInformationScreenn = false;
  immigrationScreenn = false;
  DeclarationScreenn = false;
  documentsScreenn = false;

  onClickk(check: any) {
    if (check == 1) {
      this.tabb = 'tabb1';
      this.newProfilee = true;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
      window.scrollTo(0, 0);
    } else if (check == 2) {
      this.tabb = 'tabb2';

      this.newProfilee = false;
      this.newEducationalInformationn = true;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
      window.scrollTo(0, 0);
    } else if (check == 3) {
      this.tabb = 'tabb3';

      this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = true;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
      window.scrollTo(0, 0);
    } else if (check == 4) {
      this.tabb = 'tabb4';

      this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = true;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
      window.scrollTo(0, 0);
    } else if (check == 5) {
      this.tabb = 'tabb5';

      this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = true;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
      window.scrollTo(0, 0);
    } else if (check == 6) {
      this.tabb = 'tabb6';

      this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = true;
      this.documentsScreenn = false;
      window.scrollTo(0, 0);
    } else {
      this.tabb = 'tabb7';

      this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = true;
      window.scrollTo(0, 0);
    }
  }
  jobIdfordocs: any;
  nnewNewBasicInfoo: any = {};
  newWorkInfoo = {};
  newNewWorkInfoo = {};
  newFamilyyInfoo = {};
  newNewFamilyyInfoo = {};
  immigrationInfoo: any = {};
  newImmigrationInfoo: any = {};
  declarationInfoo = {};
  newDeclarationInfoo = {};
  campusList: any;
  Applintidone: any;
  public newUserId: any;
  NewnewResumeFiles: File[] = [];
  uploadResumeError: boolean = false;
  educationdetails: any = [];
  newNewEducationalInfoo: any = {};
  skillsarray: any = [];
  degreeeducation: any = [];
  educationaldetailsarray: any = [];
  arrrnewarr: any = [];
  newarrayforaddd: any = [];
  mainaddjobarrya: any = [];
  newNewWorkkInfoo: any = {};
  // newNewEducationalInfoo: any = {};
  addJobarray: any = [];
  industryarray: any = [];
  referArrayadd = [];
  industrymainarr: any = [];
  Domainarrayadd: any = [];
  domainmainarraynewone: any = [];
  validatonPassport: boolean = false;
  newdataappendpassport: any = [];
  newdataappend: any = [];
  newApplicantSubmitDisabled: boolean = false;
  newAddAppSkillsone: any = [];
  postMultimediasone: any = [];
  maindocaddarray: any = [];

  addApplicantsubmitModal: boolean = false;

  saveAndnextt(data: any) {
    if (data == 'profileDetails') {
      // console.log(this.nnewNewBasicInfoo, 'nnewNewBasicInfoo');
      this.jobIdfordocs = this.nnewNewBasicInfoo.newAppProfileJobTitleee;
      if (this.nnewNewBasicInfoo.newAppProfileJobCampus == undefined || this.nnewNewBasicInfoo.newAppProfileJobCampus == '') {
        this.campusList = null;
      } else {
        this.campusList =
          this.nnewNewBasicInfoo.newAppProfileJobCampus.toString();
      }
      var nationalityid
      if (this.nnewNewBasicInfoo.newProfileNationalityyid !== null) {
        nationalityid = this.nnewNewBasicInfoo.newProfileNationalityyid
      } else {
        nationalityid = this.nnewNewBasicInfoo.newProfileNationalityyid

      }
      if (this.docPathel!=null) {
        // this.nnewNewBasicInfoo.uploadResume=this.docPathel
        this.nnewNewBasicInfoo.resumeName=this.docNameel
      }else{
        this.nnewNewBasicInfoo.uploadResume=this.nnewNewBasicInfoo.uploadResume
        this.nnewNewBasicInfoo.resumeName=this.nnewNewBasicInfoo.resumeName
      }
      let obj = {
        source: 3,
        applicantId: this.Applintidone,
        uploadResume: this.nnewNewBasicInfoo.uploadResume,
        resumePath: this.docPathel || '',
        resumeName: this.nnewNewBasicInfoo.resumeName,
        firstName: this.nnewNewBasicInfoo.profileFirstName,
        lastName: this.nnewNewBasicInfoo.profileLasstName,
        emailAddress: this.nnewNewBasicInfoo.profileEmailID,
        alternateEmailAddress: this.nnewNewBasicInfoo.profilealternateEmailIDD,
        phoneNumberCode: this.nnewNewBasicInfoo.phoneNumbercode,
        phoneNumber: this.nnewNewBasicInfoo.phoneNumber,
        dateOfBirth: this.nnewNewBasicInfoo.profileeeDOBB,
        gender: this.nnewNewBasicInfoo.NewProfileGenderr,
        nationality: this.nnewNewBasicInfoo.newProfileNationality,
        JobSelectType: this.nnewNewBasicInfoo.jobSelectType,
        //"permanntResidenceOfCountry": basicdetails.newProfilepermanentResidencee,
        campus: this.campusList,
        passportNumber: this.nnewNewBasicInfoo.Passport,
        passportIssueDate: this.nnewNewBasicInfoo.profileeIssueDateee,
        passportExpiryDate: this.nnewNewBasicInfoo.profileeExpiryDateeee,
        nationalityId: nationalityid,
        nationalityIdNumber:
          this.nnewNewBasicInfoo.newProfilePassportIDDDNumber,
        nationalityIssueDate: this.nnewNewBasicInfoo.profileeIssueDaDateee,
        nationalityExpiryDate: this.nnewNewBasicInfoo.profileeExpiryyDaDateee,
        // "createdDate": "2023-07-03T10:49:12.044Z",
        // "modifiedDate": "2023-07-03T10:49:12.044Z",
        // "deletedDate": "2023-07-03T10:49:12.044Z",
        race: this.nnewNewBasicInfoo.newProfileRacee,
        religion: this.nnewNewBasicInfoo.newProfileReligionnnn,
        knowAboutUs: this.nnewNewBasicInfoo.newProfileHowKnowww,
        jobId: this.nnewNewBasicInfoo.newAppProfileJobTitleee,
        userId: this.newUserId,
        status: true,
        applicantCurrentAddress: {
          currentAddressId: 0,
          applicantId: 0,
          addressLine1: this.nnewNewBasicInfoo.profileAddressLineOnee,
          addressLine2: this.nnewNewBasicInfoo.profileAddressLineTTwoo,
          country: this.nnewNewBasicInfoo.newProfileCCountryr,
          state: this.nnewNewBasicInfoo.newProfileCStatee,
          zipcode: this.nnewNewBasicInfoo.profileAddressZipcode,
          status: true,
        },

        applicantPermanentAddress: {
          permanentAddressId: 0,
          applicantId: 0,
          permanentaddressline1:
            this.nnewNewBasicInfoo.permanentprofileAddressLineOnee,
          permanentaddressline2:
            this.nnewNewBasicInfoo.permanentPprofileAddressLineTTwoo,
          permanentcountry: this.nnewNewBasicInfoo.newPermanentProfileCCountryr,
          permanentstate: this.nnewNewBasicInfoo.PermanentnewProfileCStatee,
          permanentzipcode:
            this.nnewNewBasicInfoo.permanentPprofileAddressZipcode,
          status: true,
        },
        applicantStatus: '',
        bookmarkStatus: true,
        jobScore: 0,
        fitStatus: '',
      };

      console.log(obj);

      this.candidateService
        .CreateApplicantPersonalDetails(obj)
        .subscribe((res: any) => {
          this.Applintidone = res.applicantId;

          this.GetJobDocumentTypeDocumentNameDetails();
        });

      if (((this.NewnewResumeFiles.length || this.docPathel) &&
        this.nnewNewBasicInfoo.jobSelectType == 'Archive' && this.nnewNewBasicInfoo.newAppProfileJobTitleee) && (
          this.nnewNewBasicInfoo.profileFirstName && this.nnewNewBasicInfoo.profileLasstName && this.nnewNewBasicInfoo.profileEmailID && this.nnewNewBasicInfoo.phoneNumbercode &&
          this.nnewNewBasicInfoo.phoneNumber && this.nnewNewBasicInfoo.profileeeDOBB && this.nnewNewBasicInfoo.NewProfileGenderr)
      ) {
        this.tabb = 'tabb3';
        this.newProfilee = false;
        this.newEducationalInformationn = false;
        this.newWorkExperiencee = true;
        this.enableNextButtons = true;
        this.familyInformationScreenn = false;
        this.immigrationScreenn = false;
        this.DeclarationScreenn = false;
        this.documentsScreenn = false;
        // this.remainingMainScreen = false;
        // this.newAppScreen = true;
        window.scrollTo(0, 0);
        this.uploadResumeError = false;
        window.location.href = 'candidate/work-experience';
      }
      else if (((this.NewnewResumeFiles.length || this.docPathel) &&
        this.nnewNewBasicInfoo.jobSelectType == 'Active' && this.nnewNewBasicInfoo.newAppProfileJobTitleee) && (this.nnewNewBasicInfoo.newAppProfileJobCampus &&
          this.nnewNewBasicInfoo.profileFirstName && this.nnewNewBasicInfoo.profileLasstName && this.nnewNewBasicInfoo.profileEmailID && this.nnewNewBasicInfoo.phoneNumbercode &&
          this.nnewNewBasicInfoo.phoneNumber && this.nnewNewBasicInfoo.profileeeDOBB && this.nnewNewBasicInfoo.NewProfileGenderr)) {
        this.tabb = 'tabb3';
        this.newProfilee = false;
        this.newEducationalInformationn = false;
        this.newWorkExperiencee = true;
        this.enableNextButtons = true;
        this.familyInformationScreenn = false;
        this.immigrationScreenn = false;
        this.DeclarationScreenn = false;
        this.documentsScreenn = false;
        // this.remainingMainScreen = false;
        // this.newAppScreen = true;
        window.scrollTo(0, 0);
        this.uploadResumeError = false;
        window.location.href = 'candidate/work-experience';
      }
      else {
        this.tabb = 'tabb1';
        this.newProfilee = true;
        this.newEducationalInformationn = false;
        this.newWorkExperiencee = false;
        this.familyInformationScreenn = false;
        this.immigrationScreenn = false;
        this.DeclarationScreenn = false;
        this.documentsScreenn = false;
        // this.remainingMainScreen = false;
        // this.newAppScreen = true;
        window.scrollTo(0, 0);
        this.profileBasicInfoForm.control.markAllAsTouched();
        if ((!this.NewnewResumeFiles.length || this.docPathel)) {
          this.uploadResumeError = true;
        }
      }
      if (this.phoneValid && this.emailValid) {
        this.tabb = 'tabb1';
        this.newProfilee = true;
        this.newEducationalInformationn = false;
        this.newWorkExperiencee = false;
        this.familyInformationScreenn = false;
        this.immigrationScreenn = false;
        this.DeclarationScreenn = false;
        this.documentsScreenn = false;
        // this.remainingMainScreen = false;
        // this.newAppScreen = true;
        window.scrollTo(0, 0);
        this.profileBasicInfoForm.control.markAllAsTouched();
        if ((!this.NewnewResumeFiles.length || this.docPathel)) {
          this.uploadResumeError = true;
        }
      }
    }
    if (data == 'Educationalinfoo') {

      this.tabb = 'tabb5';
      // console.log(this.newNewEducationalInfoo, 'newNewEducationalInfoo');



      this.educationdetails = this.newNewEducationalInfoo.addMoreSkills;
      var category
      for (var m = 0; m < this.educationdetails?.length; m++) {
        if (this.educationdetails[m].newAddAppSkillsCategoryyy != null) {
          category = this.educationdetails[m].newAddAppSkillsCategoryyy
        } else {
          category = 0
        }
        let skillsobj = {
          applicantCategoryTypeId: 0,
          skillCategory: category,
          skills: this.educationdetails[m].newAddAppSkillsss,
          status: true,
          applicantEducationInformationId: 0,
        };
        this.skillsarray.push(skillsobj);
      }

      this.degreeeducation = this.newNewEducationalInfoo.aanotherDegrees;

      for (var k = 0; k < this.degreeeducation.length; k++) {
        let educationaldetailsobj = {
          applicantEducationDetailsId: 0,
          higherLevelOfEducation:
            this.degreeeducation[k].newProfileHiggherEducationnnn,
          qualification:
            this.degreeeducation[k].newProfileHigherQualificationnn,
          yearOfGraduation: this.degreeeducation[k].profileeYearGraduationn,
          collegeName: this.degreeeducation[k].profileCollegeNammee,
          educationType: this.degreeeducation[k].NewProfileEduTypee,
          applicantEducationInformationId: 0,
          status: true,
        };
        this.educationaldetailsarray.push(educationaldetailsobj);
      }
      this.arrrnewarr = this.newNewEducationalInfoo.addMorelanguages;
      var laguageid
      for (var m = 0; m < this.arrrnewarr.length; m++) {
        if (this.arrrnewarr[m].newProfileHigherLanguagesss != null) {
          laguageid = this.arrrnewarr[m].newProfileHigherLanguagesss

        } else {
          laguageid = 0

        }
        let langugeobj = {
          applicantLanguagesId: 0,
          languageId: laguageid,
          oralProficiency: this.arrrnewarr[m].newProfilelangProfiiencyyy,
          writtenProficiency: this.arrrnewarr[m].newProfilelangProfiiencyyyTwo,
          applicantEducationInformationId: 0,
          status: true,
        };

        this.newarrayforaddd.push(langugeobj);
      }

      let objeducational = {
        applicantEducationInformationId: 0,
        applicantId: this.Applintidone,
        applicantEducationDetails: this.educationaldetailsarray,
        applicantCategoryTypes: this.skillsarray,
        applicantLanguages: this.newarrayforaddd,
        languagesKnown: this.newNewEducationalInfoo.newProfileHigherLanguagesss,
        status: true,
      };
      console.log(objeducational, 'this.educationaldetailsarray');
      this.candidateService
        .CreateApplicantEducationDetails(objeducational)
        .subscribe((res: any) => {
          console.log(res, 'shabbi');
        });

      this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = true;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
      window.scrollTo(0, 0);
    }
    if (data == 'workInformation') {

      if (this.newworkExperienceForm.invalid) {
        // console.log("invalid");
        this.tabb = 'tabb3';
        this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = true;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
      window.scrollTo(0, 0);
      this.newworkExperienceForm.control.markAllAsTouched();
      }

      if (this.newworkExperienceForm.valid) {
        console.log("valid");

      this.tabb = 'tabb2';
      this.mainaddjobarrya = this.newNewWorkkInfoo.anotherJobb;

      if (this.mainaddjobarrya) {
        for (var m = 0; m < this.mainaddjobarrya.length; m++) {
          if (this.mainaddjobarrya[m].newJobProfileSalaryInput == null) {
            this.valcurretsal = 0;
          } else {
            this.valcurretsal =
              this.mainaddjobarrya[m].newJobProfileSalaryInput;
          }
          if (this.mainaddjobarrya[m].newJobTotalVariable == null) {
            this.newvaltosalary = 0;
          } else {
            this.newvaltosalary = this.mainaddjobarrya[m].newJobTotalVariable;
          }

          var addjobobj = {
            applicantPreviousWorkHistoryId: 0,
            designation: this.mainaddjobarrya[m].profileDesignationnNew,
            organizationName: this.mainaddjobarrya[m].newJobOrganizationName,
            city: this.mainaddjobarrya[m].newjobprofileCity,
            country: this.mainaddjobarrya[m].addAppnewCountryyy,
            currentSalary: parseInt(this.valcurretsal),

            currencyCode: this.mainaddjobarrya[m].newJobProfileSalaryItemsss,
            totalVariable: parseInt(this.newvaltosalary),
            durationFrom: this.mainaddjobarrya[m].newJobProfileDurationOne,
            durationTo: this.mainaddjobarrya[m].newJobProfileDurationTwo,
            isCurrentJob: true,
            applicantWorkHistoryId: 0,
          };
          this.addJobarray.push(addjobobj);
        }
      }

      this.industryarray = this.newNewWorkkInfoo.aanotherIndustries;
      console.log(this.industryarray, 'industryarray');
      for (var k = 0; k < this.industryarray.length; k++) {
        if (this.industryarray[k].newAppprofileIndustryyy == null) {
          var indusrty = 0;
        } else {
          indusrty = this.industryarray[k].newAppprofileIndustryyy;
        }
        if (this.industryarray[k].newAppprofileIndustryExppp == null) {
          var induexpere = 0;
        } else {
          induexpere = this.industryarray[k].newAppprofileIndustryExppp;
        }
        var industry = {
          applicantIndustryId: 0,
          industry: indusrty,
          industryExperience: induexpere,
          status: true,
          applicantWorkHistoryId: 0,
        };
        this.industrymainarr.push(industry);
      }
      if (this.newNewWorkkInfoo.countrycode == null) {
        var countrycode = this.newNewWorkkInfoo.countrycode;
      }
      let referobj = {
        applicantReferenceId: 0,
        organizationName: this.newNewWorkkInfoo.profileOrganizationname,
        refereeName: this.newNewWorkkInfoo.profileRefereennName,
        position: this.newNewWorkkInfoo.newProfileRefereePositionnn,
        emailAddress: this.newNewWorkkInfoo.newProfileEmailAddress,
        phoneNumberCode: countrycode,
        phoneNumber: this.newNewWorkkInfoo.RefPhoneNumber,
        status: true,
        applicantWorkHistoryId: 0,
      };
      this.referArrayadd.push(referobj);

      this.Domainarrayadd = this.newNewWorkkInfoo.aanotherDomainsss;
      for (var j = 0; j < this.Domainarrayadd.length; j++) {
        if (this.Domainarrayadd[j].newAppprofileDomainnn == null) {
          var domainid = 0;
        } else {
          domainid = this.Domainarrayadd[j].newAppprofileDomainnn;
        }
        if (this.Domainarrayadd[j].newAppprofileIndustryyExppp == null) {
          var experience = 0;
        } else {
          experience = this.Domainarrayadd[j].newAppprofileIndustryyExppp;
        }

        let domainobj = {
          applicantDomainId: 0,
          domain: domainid,
          domainExperience: experience,
          status: true,
          applicantWorkHistoryId: 0,
        };
        this.domainmainarraynewone.push(domainobj);
      }

      var objworkinfo = {
        applicantWorkHistoryId: 0,
        applicantId: this.Applintidone,
        totalExperience: this.newNewWorkkInfoo.newProfileTotalExperienceee,
        noticePeriod: this.newNewWorkkInfoo.newProfileNoticePerioddd,
        status: true,
        applicantIndustryDetails: this.industrymainarr,
        applicantDomainDetails: this.domainmainarraynewone,
        applicantPreviousWorkHistoryDetails: this.addJobarray,
        applicantReferenceDetails: this.referArrayadd,
      };

      this.candidateService
        .CreateApplicantWorkHistory(objworkinfo)
        .subscribe((res: any) => {
          console.log(res, 'shabbi');
        });

      this.newProfilee = false;
      this.newEducationalInformationn = true;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
      window.scrollTo(0, 0);
    }
  }
    if (data == 'familyInformtion') {
      this.tabb = 'tabb5';
      this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = true;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
      window.scrollTo(0, 0);
    }
    if (data == 'Immigratioinn') {
      if (this.newImmigrationInfoo.NewProfileWorkPermit) {
        this.tabb = 'tabb7';
        this.immigvalidation = false;
        console.log(this.newImmigrationInfoo, 'immigration');
        if (this.newImmigrationInfoo.NewProfileWorkPermit == 'Yes') {
          var immigrationobj = {
            applicantImmigrationId: 0,
            applicantId: this.Applintidone,
            isPermitToWork: this.newImmigrationInfoo.NewProfileWorkPermit,
            workPermitType:
              this.newImmigrationInfoo.newNewProfileWorkPermitTypeee,
            passportNumber: this.newImmigrationInfoo.newProfileDocIdNumberrr,
            passportDateOfIssue: this.newImmigrationInfoo.profileeDateOfIssueee,
            passportDateOfExpiry:
              this.newImmigrationInfoo.profileeDateOfIssueeeTwoo,
            status: true,
          };

          // CreateApplicantImmigration
          this.candidateService
            .CreateApplicantImmigration(immigrationobj)
            .subscribe((res: any) => {
              console.log(res, 'shabbi');
            });
            this.GetJobDocumentTypeDocumentNameDetails();
        } else {
          var immigrationobj2 = {
            applicantImmigrationId: 0,
            applicantId: this.Applintidone,
            isPermitToWork: this.newImmigrationInfoo.NewProfileWorkPermit,
            status: true,
          };
          // CreateApplicantImmigration
          this.candidateService
            .CreateApplicantImmigration(immigrationobj2)
            .subscribe((res: any) => {
              console.log(res, 'shabbi');
            });
            this.GetJobDocumentTypeDocumentNameDetails();
        }
        this.newProfilee = false;
        this.newEducationalInformationn = false;
        this.newWorkExperiencee = false;
        this.familyInformationScreenn = false;
        this.immigrationScreenn = false;
        this.DeclarationScreenn = false;
        this.documentsScreenn = true;
        window.scrollTo(0, 0);
      }
      else {
        this.immigvalidation = true;
        this.tabb = 'tabb5';
        this.immigrationScreenn = true;
      }
    }
    if (data == 'Declarationn') {
      this.tabb = 'tabb';

      this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = true;
      window.scrollTo(0, 0);
    }
    if (data == 'Documents') {
      // window.scrollTo(0, 0);
      if (this.validatonPassport) {
        if (!this.newdataappendpassport.length && !this.newdataappend.length) {
          // || !this.DocumentList.length
          this.uploadPassportFile = true;
          this.uploadVisaFile = true;
          this.newApplicantSubmitDisabled = false;
          this.addApplicantsubmitModal = false;
          this.uploadAlldocsFile = false;

        }
        // if(this.newdataappendpassport.length>=1 || this.newdataappend.length>=1 || this.DocumentList.length>=1){
        else {
          this.uploadPassportFile = false;
          this.uploadVisaFile = false;
          this.newApplicantSubmitDisabled = true;
          this.addApplicantsubmitModal = true;
          this.uploadAlldocsFile = true;

          setTimeout(() => {
            this.newApplicantSubmitDisabled = false;
          }, 4000);
        }
      }
      if (this.withoutValidationPassport) {
        this.uploadPassportFile = false;
        this.uploadVisaFile = false;
        this.newApplicantSubmitDisabled = true;
        this.addApplicantsubmitModal = true;
        this.uploadAlldocsFile = true;
        setTimeout(() => {
          this.newApplicantSubmitDisabled = false;
        }, 4000);
      }

if(this.postMultimediasone.length > 0) {
  for (var i = 0; i < this.postMultimediasone.length; i++) {
    var valnewdataarray
    if (this.postMultimediasone[i].applicantDocumentId != null && this.postMultimediasone[i].applicantDocumentId != undefined && this.postMultimediasone[i].applicantDocumentId != '') {

      valnewdataarray = this.postMultimediasone[i].applicantDocumentId
    } else {
      valnewdataarray = 0
    }
    var docobj = {
      applicantDocumentId: valnewdataarray,
      applicantId: this.Applintidone,
      documentType: this.postMultimediasone[i].docidtype,
      uploadDocument: this.postMultimediasone[i].content,
      uploadDocumentPath: '',
      uploadDocumentName: this.postMultimediasone[i].name,
      documentName: this.postMultimediasone[i].dacnameid,
      applicationType: '',
      status: true,
    };
    this.maindocaddarray.push(docobj);
  }
}else{

  var docobjnew = {
    applicantDocumentId: valnewdataarray,
    applicantId: this.Applintidone,
    documentType: 0,
    uploadDocument:'',
    uploadDocumentPath: '',
    uploadDocumentName: '',
    documentName: 0,
    applicationType: '',
    status: true,
  };
  this.maindocaddarray.push(docobjnew);

}


      this.candidateService
        .createApplicantDocument(this.maindocaddarray)
        .subscribe((res: any) => {
          // this.router.navigate(["/candidate/candidates"]);
          // this.router.navigate(['/newcandidate'])
          if (res) {
            var applicantId = res.applicantId
            // this.candidateService.GetApplicantDocumentById(applicantId).subscribe((res) => {
            //   console.log(res, 'ffffffffffffffffffffff')


            // })
            this.candidateService.ChangeApplicantStatusSubmit(applicantId)
              .subscribe((res: any) => {
                if (res) { }
              })
            // console.log(res, 'sfsfsfsfsfsfsf');
            // this.doctypeandarray = res
            // this.filesTwodata = res
            // this.remainingMainScreen = true;
            // this.documentsScreenn = false;
            // this.newAppScreen = false;
            window.scrollTo(0, 0);
          }
        });
    }
  }

  documentList: any;
  selectDocTypeArray: any[] = [];
  // selectDocName: number;
  selectDocNameArray: any[] = [];
  GetJobDocumentTypeDocumentNameDetails() {
    // GetJobDocumentTypeDocumentNameDetails
    this.candidateService
      .GetJobDocumentTypeDocumentNameDetails(this.jobIdfordocs)
      .subscribe((res: any) => {
        console.log(res, 'shs.....................................hshshshshsh');
        this.documentList = res;
        if (res && res.length > 0) {
          console.log("document res", res[0].applicantDocumentNames[0].documentName);
          this.selectDocTypeArray = res.map(item => item.documentType);
          this.selectDocNameArray = res.map(item => item.applicantDocumentNames[0].documentName);
        }
      });
  }

  // NewnewResumeFiles: File[] = [];
  imggirationMedias: any = [];
  fileToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = (error) => reject(error);
    });
  };
  newOnNewSelect(event) {
    console.log(event);
    this.NewnewResumeFiles = [];
    this.imggirationMedias=[];
    this.NewnewResumeFiles.push(...event.addedFiles);
    if (this.NewnewResumeFiles && this.NewnewResumeFiles[0]) {
      for (let i = 0; i < this.NewnewResumeFiles.length; i++) {
        this.fileToBase64(this.NewnewResumeFiles[i]).then((result) => {
          const base64String = result.replace('data:', '').replace(/^.+,/, ''); // To remove data url part
          this.imggirationMedias.push({
            name: this.NewnewResumeFiles[i].name,
            content: base64String,
          }); //postMultimedias is a array which holds image name and bas64String
          console.log(this.imggirationMedias, '===================');
          if (this.imggirationMedias) {
            for (let i = 0; i < this.imggirationMedias.length; i++) {
              this.nnewNewBasicInfoo.uploadResume =
                this.imggirationMedias[i].content;
              this.nnewNewBasicInfoo.resumeName =
                this.imggirationMedias[i].name;
            }
          }
        });
      }
    }
  }

  phoneValid: boolean = false;
  ValidateApplicantPhoneNo(number: any) {
    this.phoneValid = false;
    let phoneValue = number.target.value;
    console.log(phoneValue, '============');
    if (phoneValue.length > 0) {
      this.candidateService
        .ValidateApplicantPhoneNo(phoneValue)
        .subscribe((res) => {
          if (res) {
            this.phoneValid = true;
          } else {
            this.phoneValid = false;
          }
        });
    }
  }
  emailValid: boolean = false;
  emailValidate(data) {
    this.emailValid = false;
    let emailValue = data.target.value;
    if (emailValue.length > 0) {
      this.candidateService
        .ValidateApplicantEmailId(emailValue)
        .subscribe((res) => {
          if (res) {
            this.emailValid = true;
          } else {
            this.emailValid = false;
          }
        });
    }
  }
  // validatonPassport: boolean = false;
  withoutValidationPassport: boolean = false;
  addDocumentButton: boolean = false;
  documentEnablee: boolean = false;

  showNewAppWorkPermit(event: any) {
    this.NewAppWorkPermit = true;
    this.immigrationChecked = !this.immigrationChecked;
    // console.log(event.target.value == 'Yes',".........prudhvi..................");
    // if (event.target.value == 'Yes') {
    this.validatonPassport = true;
    this.documentEnablee = false;
    this.withoutValidationPassport = false;
    this.addDocumentButton = false;
    this.documentRender = [
      {
        selectDocType: null,
        selectDocName: null,
      },
    ]
    console.log('Yessss');
    // }
  }

  immigrationChecked: boolean = true;
  NewAppWorkPermit = false;
  documentRender: any = {}

  noShowNewAppWorkPermit(event: any) {
    this.NewAppWorkPermit = false;
    this.immigrationChecked = !this.immigrationChecked;
    // console.log(event.target.value,".........prudhvi..................");
    // if (event.target.value == 'No') {
    this.validatonPassport = false;
    this.withoutValidationPassport = true;
    this.addDocumentButton = true;
    console.log('noooooooo');
    this.documentRender = [
      {
        selectDocType: null,
        selectDocName: null,
      },
    ];
    // }

  }

  joblistarray: any = [];
  GetPublishedJobDetails() {
    this.commonService.GetPublishedJobDetails().subscribe((res: any) => {
      this.joblistarray = res;
    });
  }
  GetPublishedJobNames(userId: any) {
    this.commonService.GetPublishedJobNames(userId).subscribe((res: any) => {
      this.joblistarray = res;
    });
   }
   getArchiveJobDetails() {
    this.commonService.getArvicheJobList().subscribe((res: any) => {
      this.joblistarray = res;
    });
  }
  campusRes: any;

  onCampus(event: any) {
    var jobId = event.jobId;
    this.commonService.GetJobCampus(jobId).subscribe((res: any) => {
      this.campusRes = res;
    });
    this.GetWorkPermitTypeByJobId(jobId);
    this.nnewNewBasicInfoo.newAppProfileJobCampus = '';
    this.jobIdfordocs = jobId;
  }
  workTypenew: any;

  GetWorkPermitTypeByJobId(val: any) {
    this.candidateService
      .GetWorkPermitTypeByJobId(val)
      .subscribe((res: any) => {
        this.workTypenew = res;
        // console.log(this.workTypenew, 'ffffffffffffffffff');
      });
  }
  public archiveSelected: boolean = true;
  public archiveUpload: boolean = true;
  public campusActiveSelected: boolean = false;
  // userId : any;
  onItemChange(data) {
    let user = localStorage.getItem('userDetails');
      if (user != null) {
        let userDetails = JSON.parse(user);
        let userId = userDetails.userId.toString();
        this.newUserId = userId;
      }
    if (data == 'Archive') {
      this.getArchiveJobDetails();
      this.archiveSelected = false;
      this.campusActiveSelected = false;
    }
    if (data == 'Active') {
      this.archiveSelected = true;
      this.campusActiveSelected = true;
      // this.GetPublishedJobDetails();
      this.GetPublishedJobNames(this.newUserId)
    }
  }
  restrictNumbers(evt: any) {
    var keyCode = evt.which ? evt.which : evt.keyCode;
    if (
      (keyCode < 65 || keyCode > 90) &&
      (keyCode < 97 || keyCode > 123) &&
      keyCode != 32
    )
      return false;
    return true;

  }
  noSpaceBar(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }
  Allcountry: any = [];
  getCountriesnew() {
    this.candidateService.getCountriesnew().subscribe((res: any) => {
      console.log(res, 'shahahha');
      if ((res.status = true)) {
        this.Allcountry = res.data;
      }
    });
  }
  phoneNumberdisabledByCode: boolean = false;
  phoneNumberdisabledByCodee: boolean = false;
  disabledNumberInput(data: any) {
    if (data == 'one') {
      if (!this.Allcountry.length) {
        this.phoneNumberdisabledByCode = true;
      } else this.phoneNumberdisabledByCode = false;
    }
    if (data == 'two') {
      if (!this.Allcountry.length) {
        this.phoneNumberdisabledByCodee = true;
      } else this.phoneNumberdisabledByCodee = false;
    }
  }
  nationalitycountry: any = [];
  nationalitydocarr: any = [];
  GetDocumentByNationalityId() {
    this.candidateService.GetNationalityList().subscribe((res: any) => {
      this.nationalitycountry = res;
    });
  }
  getnationalityid(val: any) {
    this.candidateService.GetNationalityById(val).subscribe((res: any) => {
      this.nationalitydocarr =[{
         name: 'Driving Licence',
         id: '1',
         },
         {
         name: 'Passport',
         id: '2',
         },
         {
         name: 'Utility Bill',
         id: '3',
         },
         {
         name: 'Others',
         id: '4'
         }]
    })
    // for (var i = 0; i < this.nationalitycountry.length; i++) {
    //   if (this.nationalitycountry[i].countryId == val.countryId) {
    //     this.nationalitydocarr =
    //       this.nationalitycountry[i].nationalityDocuments;
    //   }
    // }
    this.nnewNewBasicInfoo.newProfileNationalityyid = null;
  }
  contrysymbol: any = [];
  stateList: any = [];
  statecityList: any = [];
  getCampusonCountry(val: any, newNewWorkkInfoo) {
    console.log(val);
    // getCoucampus
    this.candidateService.getCoucampus(val.id).subscribe((res: any) => {
      this.stateList = res.data;

      this.contrysymbol = this.Allcountry.filter(
        (value, index, self) => self.findIndex((m) => m.id === val.id) === index
      );
      this.newNewWorkkInfoo.anotherJobb[0].newJobProfileSalaryItemsss =
        this.contrysymbol[0].currencycode;
    });
    this.candidateService.getcities(val.id).subscribe((res: any) => {
      this.statecityList = res;
    });
  }
  ehicalarray: any = [];
  regiionalarr: any = [];
  getEthicReligionList() {
    this.candidateService.getEthicReligionList().subscribe((res: any) => {
      this.ehicalarray = res.data.ethicList;
      // console.log(this.ehicalarray, "this.ehicalarraythis.ehicalarraythis.ehicalarraythis.ehicalarray");
      this.regiionalarr = res.data.religionList;
      // console.log(this.regiionalarr, "this.regiionalarrthis.regiionalarrthis.regiionalarr....................this.regiionalarr");

    });
  }
  howdotodropdown = [
    { category: 'LinkedIn' },
    { category: 'Naukri' },
    { category: 'IIMJobs' },
    { category: 'Indeed' },
    { category: 'Other Job Portals' },
    { category: 'TVC' },
    { category: 'News Journal' },
    { category: 'Social Media' },
    { category: 'Digital Ads' },
    { category: 'Employee Reference' },
    { category: ' Others' },
  ];
  addNewExperienceFieldss(i: any) {
    let prefreedType = [];
    prefreedType = this.indusrtynames;
    for (let i = 0; i < this.newNewWorkkInfoo.aanotherIndustries.length; i++) {
      for (let j = 0; j < prefreedType.length; j++) {
        if (
          this.newNewWorkkInfoo.aanotherIndustries[i] &&
          this.newNewWorkkInfoo.aanotherIndustries[i].newAppprofileIndustryyy
        )
          if (
            prefreedType[j].industry_Id ==
            this.newNewWorkkInfoo.aanotherIndustries[i].newAppprofileIndustryyy
          )
            var idToRemove = prefreedType[j].industry_Id;
        prefreedType = prefreedType.filter(
          (item) => item.industry_Id !== idToRemove
        );
      }
    }
    this.indusrtynames = [];
    let obj = {
      newAppprofileIndustryyy: null,
      newAppprofileIndustryExppp: null,
    };

    setTimeout(() => {
      this.newNewWorkkInfoo.aanotherIndustries.push(obj);
      // for(let i=0;i<prefreedType.length ;i++){
      this.indusrtynames = prefreedType;
      let industries = this.newNewWorkkInfoo.aanotherIndustries;
      this.newNewWorkkInfoo.aanotherIndustries = industries;
    }, 1000);

    // this.newNewWorkkInfoo.aanotherIndustries.push(obj);
    // console.log(this.newNewWorkkInfoo.aanotherIndustries);
  }
  indusrtynames: any = [];
  // mainaddjobarrya: any = [];
  GetIndustry() {
    this.candidateService.GetIndustry().subscribe((res: any) => {
      // console.log(res, 'industry');
      this.indusrtynames = res;
    });
  }
  newAddNewDomainFieldsTwoo(i: any) {
    let domainType: any = [];
    domainType = this.Domainnames;
    for (let k = 0; k < this.newNewWorkkInfoo.aanotherDomainsss.length; k++) {
      for (let j = 0; j < domainType.length; j++) {
        if (
          this.newNewWorkkInfoo.aanotherDomainsss[k] &&
          this.newNewWorkkInfoo.aanotherDomainsss[k].newAppprofileDomainnn
        )
          if (
            domainType[j].domain_Id ==
            this.newNewWorkkInfoo.aanotherDomainsss[k].newAppprofileDomainnn
          )
            var idToRemove = domainType[j].domain_Id;
        domainType = domainType.filter((item) => item.domain_Id !== idToRemove);
      }
    }
    this.Domainnames = [];
    let obj = {
      newAppprofileIndustryyExppp: null,
      newAppprofileDomainnn: null,
    };

    this.newNewWorkkInfoo.aanotherDomainsss.push(obj);
    this.Domainnames = domainType;
    console.log(this.Domainnames);
  }
  Domainnames: any = [];
  prefferedDomain: any = [];
  GetDomain() {
    this.candidateService.GetDomain().subscribe((res: any) => {
      console.log(res, 'domail');
      this.Domainnames = res;
      this.prefferedDomain = res;
    });
  }
  newProfileTotalExperienceee: number;
  newProfileTotalExperience = [
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11' },
    { id: 13, name: '12' },
    { id: 14, name: '13' },
    { id: 15, name: '14' },
    { id: 16, name: '15' },
    { id: 17, name: '16' },
    { id: 18, name: '17' },
    { id: 19, name: '18' },
    { id: 20, name: '19' },
    { id: 21, name: '20' },
    { id: 22, name: '21' },
    { id: 23, name: '22' },
    { id: 24, name: '23' },
    { id: 25, name: '24' },
    { id: 26, name: '25' },
    { id: 27, name: '26' },
    { id: 28, name: '27' },
    { id: 29, name: '28' },
    { id: 30, name: '29' },
    { id: 31, name: '30' },
    { id: 32, name: '31' },
    { id: 33, name: '32' },
    { id: 34, name: '33' },
    { id: 35, name: '34' },
    { id: 36, name: '35' },
    { id: 37, name: '36' },
    { id: 38, name: '37' },
    { id: 39, name: '38' },
    { id: 40, name: '39' },
    { id: 41, name: '40' },
    { id: 42, name: '41' },
    { id: 43, name: '42' },
    { id: 44, name: '43' },
    { id: 45, name: '44' },
    { id: 46, name: '45' },
    { id: 47, name: '46' },
    { id: 48, name: '47' },
    { id: 49, name: '48' },
    { id: 50, name: '49' },
    { id: 51, name: '50' },
  ];
  newAppprofileIndustryExppp: any;
  newAppprofileIndustryExp = [
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11' },
    { id: 13, name: '12' },
    { id: 14, name: '13' },
    { id: 15, name: '14' },
    { id: 16, name: '15' },
    { id: 17, name: '16' },
    { id: 18, name: '17' },
    { id: 19, name: '18' },
    { id: 20, name: '19' },
    { id: 21, name: '20' },
    { id: 22, name: '21' },
    { id: 23, name: '22' },
    { id: 24, name: '23' },
    { id: 25, name: '24' },
    { id: 26, name: '25' },
    { id: 27, name: '26' },
    { id: 28, name: '27' },
    { id: 29, name: '28' },
    { id: 30, name: '29' },
    { id: 31, name: '30' },
    { id: 32, name: '31' },
    { id: 33, name: '32' },
    { id: 34, name: '33' },
    { id: 35, name: '34' },
    { id: 36, name: '35' },
    { id: 37, name: '36' },
    { id: 38, name: '37' },
    { id: 39, name: '38' },
    { id: 40, name: '39' },
    { id: 41, name: '40' },
    { id: 42, name: '41' },
    { id: 43, name: '42' },
    { id: 44, name: '43' },
    { id: 45, name: '44' },
    { id: 46, name: '45' },
    { id: 47, name: '46' },
    { id: 48, name: '47' },
    { id: 49, name: '48' },
    { id: 50, name: '49' },
    { id: 51, name: '50' },
  ];

  newAppprofileIndustryyExppp: number;
  newAppprofileIndustryyExp = [
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11' },
    { id: 13, name: '12' },
    { id: 14, name: '13' },
    { id: 15, name: '14' },
    { id: 16, name: '15' },
    { id: 17, name: '16' },
    { id: 18, name: '17' },
    { id: 19, name: '18' },
    { id: 20, name: '19' },
    { id: 21, name: '20' },
    { id: 22, name: '21' },
    { id: 23, name: '22' },
    { id: 24, name: '23' },
    { id: 25, name: '24' },
    { id: 26, name: '25' },
    { id: 27, name: '26' },
    { id: 28, name: '27' },
    { id: 29, name: '28' },
    { id: 30, name: '29' },
    { id: 31, name: '30' },
    { id: 32, name: '31' },
    { id: 33, name: '32' },
    { id: 34, name: '33' },
    { id: 35, name: '34' },
    { id: 36, name: '35' },
    { id: 37, name: '36' },
    { id: 38, name: '37' },
    { id: 39, name: '38' },
    { id: 40, name: '39' },
    { id: 41, name: '40' },
    { id: 42, name: '41' },
    { id: 43, name: '42' },
    { id: 44, name: '43' },
    { id: 45, name: '44' },
    { id: 46, name: '45' },
    { id: 47, name: '46' },
    { id: 48, name: '47' },
    { id: 49, name: '48' },
    { id: 50, name: '49' },
    { id: 51, name: '50' },
  ];

  newProfileNoticePerioddd: number;
  newProfileNoticePeriod = [
    { id: 1, name: '0-10 days' },
    { id: 2, name: '10-15 days' },
    { id: 3, name: '15-30 days' },
    { id: 4, name: '30-45 days' },
    { id: 4, name: '45-60 days' },
    { id: 4, name: 'Greater than 60 days' },
  ];
  newBackApp(data: any) {
    if (data == 'workInfoBack') {
      window.scrollTo(0, 0);
      this.tabb = 'tabb1';

      this.newProfilee = true;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
    }
    if (data == 'eduInfoBack') {
      window.scrollTo(0, 0);
      this.tabb = 'tabb3';

      this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = true;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
    }

    if (data == 'ImmigrationBack') {
      window.scrollTo(0, 0);
      this.tabb = 'tabb2';

      this.newProfilee = false;
      this.newEducationalInformationn = true;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = false;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
    }

    if (data == 'DocumentsBack') {
      window.scrollTo(0, 0);
      this.tabb = 'tabb5';

      this.newProfilee = false;
      this.newEducationalInformationn = false;
      this.newWorkExperiencee = false;
      this.familyInformationScreenn = false;
      this.immigrationScreenn = true;
      this.DeclarationScreenn = false;
      this.documentsScreenn = false;
    }
  }
  newEducationalInfoo: any = {};

  newAddMoreSkills(i: any) {
    let obj = {
      skillCategory: null,
      skills: null,
    };

    this.newEducationalInfoo.skillsList.push(obj);
  }

  newRemoveMoreSkills(i: any) {
    this.newEducationalInfoo.skillsList.splice(i, 1);
  }

  newAddAnotherDegreeeFields(i: any) {
    let obj = {
      newProfileHiggherEducationnnn: null,
      newProfileHigherQualificationnn: null,
      profileeYearGraduationn: null,
      profileCollegeNammee: null,
    };

    this.newNewEducationalInfoo.aanotherDegrees.push(obj);
  }
  newDeleteAnotherDegreeeFields(i: any) {
    this.newNewEducationalInfoo.aanotherDegrees.splice(i, 1);
  }
  SkillNameById(event: any, i: any) {
    let jobskills = this.newNewEducationalInfoo.addMoreSkills;
    jobskills[i].skill_list = [];
    let skillid = event.categoryId || event;
    this.commonService.GetSkillNameById(skillid).subscribe(async (res) => {
      let jobSkill: any = this.newNewEducationalInfoo.addMoreSkills;
      jobSkill[i].skill_list = res;
    });
  }
  newAddMoreSkillsFields(i: any) {
    let obj = {
      newAddAppSkillsCategoryyy: null,
      newAddAppSkillsss: null,
    };

    this.newNewEducationalInfoo.addMoreSkills.push(obj);
  }
  newRemoveMoreSkillsFields(i: any) {
    this.newNewEducationalInfoo.addMoreSkills.splice(i, 1);
  }
  newAddMorelanguages(i: any) {
    let obj = {
      newProfileHigherLanguagesss: null,
      profileFormLanguage: 'Oral',
      newProfilelangProfiiencyyy: null,
      profileFormLanguageTwo: 'Written',
      newProfilelangProfiiencyyyTwo: null,
    };

    this.newNewEducationalInfoo.addMorelanguages.push(obj);
  }
  newRemoveMoreLanguages(i: any) {
    this.newNewEducationalInfoo.addMorelanguages.splice(i, 1);
  }
  Education_details: any;
  getLevelOfEducation() {
    this.candidateService.getLevelOfEducation().subscribe((res) => {
      this.Education_details = res;
      // console.log(this.Education_details, 'shhshshsh');
    });
  }
  degreedropdown: any = [];
  getDegree() {
    this.candidateService.getDegree(true).subscribe((res: any) => {
      this.degreedropdown = res;
    });
  }
  countrycurrencylist: any = [];
  GetYearsDetails() {
    this.candidateService.GetYearsDetails().subscribe((res: any) => {
      // console.log(res, 'shabbcountrylist');
      this.countrycurrencylist = res;
    });
  }
  getSkills_Category: any = [];
  uploadedresult: any = []
  GetSkills_Category() {

    this.commonService.GetCategoryType(true).subscribe((res) => {
      this.uploadedresult = res;
      this.getSkills_Category = this.uploadedresult.filter(
        (value: any, index: any, self: any) =>
          self.findIndex(
            (m: any) => m.skill_CategoryId === value.skill_CategoryId
          ) === index
      );
      // this.skill_list = res;
    });
  }
  languageList: any;
  getLanguages() {
    this.candidateService.getLanguages(true).subscribe((res: any) => {
      this.languageList = res;
    });
  }

  newProfilelangProfiiencyyy: number;
  newProfilelangProfiiency = [
    { id: 1, name: 'High' },
    { id: 2, name: 'Low' },
    { id: 3, name: 'Medium' },
  ];

  files: File[] = [];
  basicInfoDatanew: any = {};
  objects: any = {};

  onselect(event: { addedFiles: any }, documentName, id: any, docid, applicantDocumentId: any) {
    console.log(event);

    if (this.files.length > 0) {
      this.objects = {};
      this.files.forEach((element: any) => {
        console.log(element, 'console.log(element)');
        Object?.keys(element).forEach((key) => {
          console.log(key, 'key');
          if (element.documentText == documentName) {
            element[key]?.push(...event.addedFiles);
          } else {
            this.objects[id] = event.addedFiles;
            this.objects['docid'] = docid;
            this.objects['documentText'] = documentName;
            // this.objects['person'] = person;
            this.objects['documentNameId'] = id;
          }
        });
      });
      this.files.push(this.objects);
    } else {
      this.objects[id] = event.addedFiles;
      this.objects['docid'] = docid;
      // this.objects['person'] = person;
      this.objects['documentText'] = documentName;
      this.objects['documentNameId'] = id;

      this.files.push(this.objects);
    }
    this.newAddAppSkillsone = [];
    this.newAddAppSkillsone.push(...event.addedFiles);

    if (this.newAddAppSkillsone && this.newAddAppSkillsone[0]) {
      for (let i = 0; i < this.newAddAppSkillsone.length; i++) {
        this.fileToBase64(this.newAddAppSkillsone[i]).then((result) => {
          const base64String = result.replace('data:', '').replace(/^.+,/, ''); // To remove data url part
          this.postMultimediasone.push({
            name: this.newAddAppSkillsone[i].name,
            content: base64String,
            docid: id,
            dacnameid: documentName,
            docidtype: docid,
            applicantDocumentId: applicantDocumentId
          });
          this.basicInfoDatanew.Photo = this.postMultimediasone[0].content;
          this.basicInfoDatanew.Name = this.postMultimediasone[0].name;
          console.log(this.postMultimediasone, 'ssssssssssssssssss');
          console.log(this.files, "fffffffffffffffffffffffffffffffffff")
        });
      }
    }
  }
  newNewPassportFiles: File[] = [];

  newNewPassportRemove(event, id: any) {
    console.log(event);

    this.files.forEach((element: any) => {
      Object.keys(element).forEach((key) => {
        if (key == id && event) {
          element[key].splice(element[key].indexOf(event), 1);
        }
      });
    });

    this.newNewPassportFiles.splice(this.newNewPassportFiles.indexOf(event), 1);
  }
  newNewVisaFiles: File[] = [];
  newAddAppSkillstwo: any = [];


  submitPopupYes(){
    // window.location.reload();
    this.router.navigateByUrl('/candidate/candidates');
  }

  onselect12(event: { addedFiles: any }, documentName, id: any, docid) {
    if (this.newNewVisaFiles.length > 0) {
      this.objects = {};
      this.newNewVisaFiles.forEach((element: any) => {
        Object.keys(element).forEach((key) => {
          if (element.documentText == documentName) {
            element[key]?.push(...event.addedFiles);
          } else {
            this.objects[id] = event.addedFiles;
            this.objects['docid'] = docid;
            this.objects['documentText'] = documentName;
            // this.objects['person'] = person;
            this.objects['documentNameId'] = id;
          }
        });
      });
      this.newNewVisaFiles.push(this.objects);
    } else {
      this.objects[id] = event.addedFiles;
      this.objects['docid'] = docid;
      // this.objects['person'] = person;
      this.objects['documentText'] = documentName;
      this.objects['documentNameId'] = id;

      this.newNewVisaFiles.push(this.objects);
    }
    this.newdataappendpassport.push(...event.addedFiles);
    this.newAddAppSkillstwo = [];
    this.newAddAppSkillstwo.push(...event.addedFiles);

    if (this.newAddAppSkillstwo && this.newAddAppSkillstwo[0]) {
      for (let i = 0; i < this.newAddAppSkillstwo.length; i++) {
        this.fileToBase64(this.newAddAppSkillstwo[i]).then((result) => {
          const base64String = result.replace('data:', '').replace(/^.+,/, ''); // To remove data url part
          this.postMultimediasone.push({
            name: this.newAddAppSkillstwo[i].name,
            content: base64String,
            docid: 0,
            dacnameid: documentName,
            docidtype: 0
          });
          this.basicInfoDatanew.Photo = this.postMultimediasone[0].content;
          this.basicInfoDatanew.Name = this.postMultimediasone[0].name;
          console.log(this.postMultimediasone, 'ssssssssssssssssss');
        });
      }
    }
  }
  newNewPassportRemovePassport(event: any) {
    // this.newdataappendpassport.splice(i,1);
    this.newdataappendpassport.splice(
      this.newdataappendpassport.indexOf(event),
      1
    );
  }

  onselect1(event: { addedFiles: any }, documentName, id: any, docid) {
    this.postMultimediasone = [];


    this.newdataappend.push(...event.addedFiles);

    if (this.newNewVisaFiles.length > 0) {
      this.objects = {};
      console.log(event, "..............eventttttttt");
      this.newdataappend.push(...event.addedFiles);

      this.newNewVisaFiles.forEach((element: any) => {
        Object.keys(element).forEach((key) => {
          console.log(key, 'key');
          if (element.documentText == documentName) {
            element[key]?.push(...event.addedFiles);
          } else {
            this.objects[id] = event.addedFiles;
            this.objects['docid'] = docid;
            this.objects['documentText'] = documentName;
            // this.objects['person'] = person;
            this.objects['documentNameId'] = id;
          }
        });
      });
      this.newNewVisaFiles.push(this.objects);
    } else {
      this.objects[id] = event.addedFiles;
      this.objects['docid'] = docid;
      // this.objects['person'] = person;
      this.objects['documentText'] = documentName;
      this.objects['documentNameId'] = id;

      this.newNewVisaFiles.push(this.objects);
    }
    // this.newdataappend.push(...event.addedFiles);
    this.newAddAppSkillstwo = [];
    this.newAddAppSkillstwo.push(...event.addedFiles);

    if (this.newAddAppSkillstwo && this.newAddAppSkillstwo[0]) {
      for (let i = 0; i < this.newAddAppSkillstwo.length; i++) {
        this.fileToBase64(this.newAddAppSkillstwo[i]).then((result) => {
          const base64String = result.replace('data:', '').replace(/^.+,/, ''); // To remove data url part
          this.postMultimediasone.push({
            name: this.newAddAppSkillstwo[i].name,
            content: base64String,
            docid: 0,
            dacnameid: documentName,
            docidtype: 0,
          });
          this.basicInfoDatanew.Photo = this.postMultimediasone[0].content;
          this.basicInfoDatanew.Name = this.postMultimediasone[0].name;
          console.log(this.postMultimediasone, 'ssssssssssssssssss');
          console.log(this.postMultimediasone[0].name, '...............name....................');
        });
      }
    }
  }
  newNewPassportRemoveVisa(event: any) {
    // this.newdataappendpassport.splice(i,1);
    this.newdataappend.splice(this.newdataappend.indexOf(event), 1);
  }

}
