import {
  Component,
  Injector,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, RouterLink, RouterStateSnapshot } from '@angular/router';
import { ReportsService } from 'src/app/services/reports.service';
// import { CookieService } from 'ngx-cookie-service';
// import { SsoService } from 'src/app/services/ssoService';
// import { userService } from 'src/app/services/userservice';
// import { AppComponentBase } from 'src/app/shared/app.component-base';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @ViewChild('loginInfo', { static: false }) loginInfo : any;

  @ViewChild('loginInfo', { static: false }) forgotForm : any;

  @ViewChild('filterInfo', { static: false }) filterInfo : any;
  loginEmail:any;

  toggleLoginUserName: boolean = true;

  selectedCar: any;

  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  public userData: any;
  errormMsg: boolean = false;
  fieldTextType: boolean = false;
  candidateLogin: boolean = false;
  userInfo: any;
  forgotEmail: any;

  constructor(public injector: Injector, public router: Router, public reportsService: ReportsService) {
    this.userData = {};
    this.userData.user = '.';
    this.forgotEmail = '';
    // this.userInfo = this.appSettings.getUserId();
    if (this.userInfo) {
      this.candidateLogin = this.userInfo.isCandidate;
    }
    // if (this.ssoService.isUserAuthenticated) {
    //   if (this.candidateLogin) {
    //     this.router.navigate([
    //       '/candidate/candidateProfile/' + this.userInfo?.userId,
    //     ]);
    //   } else {
    //     this.router.navigate['/dashboard'];
    //   }
    // } else {
      localStorage.removeItem('token');
    // }
  }

  ngOnInit(): void {}
  errormessages: any;
  errorUsername() {
    this.errormessages = '';
  }
  createLoginUser() {
    let params = {
      username: this.userData.loginEmail,
      password: this.userData.password,
      // userFullName: this.userData.userFullName,
    };
    this.errormMsg = false;
    localStorage.clear()
    this.reportsService.createUserLogin(params).subscribe((res:any)=>{
      if (res?.userInfo?.token) {
        this.errormMsg = false;
        this.reportsService.setUserId(res.userInfo.token);
        setTimeout(() => {
          this.router.navigate(['/dashboard']);
        }, 100);
      }
      else {
        this.errormMsg = true;
      }
    })
    // console.log(this.userFull, '===this.userFull');
    // if (this.userFull && this.userFull.length > 1) {
    //   console.log(params, '11');
    //   if (this.userData.userFullName != undefined) {
    //     if (this.loginInfo.valid) {
    //       // let state = this.state;
    //       let rout: any = window.location.href;
    //       let data = this.router?.url?.split('/')[1];
    //       if (data == 'candidates') {
    //         this.candidateLogin = true;
    //       }
    //       if (this.candidateLogin) {
    //         this.userService.createCandidateLogin(params).subscribe(
    //           (res: any) => {
    //             if (res?.userInfo?.token) {
    //               this.errormMsg = false;
    //               this.ssoService.setUserAuthentication(res);
    //               this.userService.setUserId(res.userInfo);
    //               this.cookieService.deleteAll();
    //               setTimeout(() => {
    //                 this.router.navigate([
    //                   '/candidate/candidateProfile/' + res.userInfo.userId,
    //                 ]);
    //               }, 100);
    //             } else {
    //               this.errormMsg = true;
    //             }
    //             // this.ssoService.setUserAuthentication(res);
    //             // this.userService.setUserId(res);
    //             // setTimeout(() => {
    //             //   this.router.navigate(['/dashboard']);
    //             // }, 100);
    //           },
    //           (err) => {
    //             var error = err;
    //             if (error) {
    //               this.errormMsg = true;
    //             }
    //           }
    //         );
    //       } else {
    //         this.userService.createUserLogin(params).subscribe(
    //           (res: any) => {
    //             if (res?.userInfo?.token) {
    //               this.errormMsg = false;
    //               this.ssoService.setUserAuthentication(res);
    //               this.userService.setUserId(res.userInfo);
    //               this.cookieService.deleteAll();
    //               setTimeout(() => {
    //                 this.router.navigate(['/manage/jobs']);
    //               }, 100);
    //             } else {
    //               this.errormMsg = true;
    //             }
    //             // this.ssoService.setUserAuthentication(res);
    //             // this.userService.setUserId(res);
    //             // setTimeout(() => {
    //             //   this.router.navigate(['/dashboard']);
    //             // }, 100);
    //           },
    //           (err) => {
    //             var error = err;
    //             if (error) {
    //               this.errormMsg = true;
    //             }
    //           }
    //         );
    //       }
    //     }
    //   } else {
    //     this.errormessages = 'select user name';
    //   }
    // } else {
    //   if (this.loginInfo.valid) {
    //     console.log(params, '22');
    //     // let state = this.state;
    //     let rout: any = window.location.href;
    //     let data = this.router?.url?.split('/')[1];
    //     if (data == 'candidates') {
    //       this.candidateLogin = true;
    //     }
    //     if (this.candidateLogin) {
    //       this.userService.createCandidateLogin(params).subscribe(
    //         (res: any) => {
    //           if (res?.userInfo?.token) {
    //             this.errormMsg = false;
    //             this.ssoService.setUserAuthentication(res);
    //             this.userService.setUserId(res.userInfo);
    //             this.cookieService.deleteAll();
    //             setTimeout(() => {
    //               this.router.navigate([
    //                 '/candidate/candidateProfile/' + res.userInfo.userId,
    //               ]);
    //             }, 100);
    //           } else {
    //             this.errormMsg = true;
    //           }
    //           // this.ssoService.setUserAuthentication(res);
    //           // this.userService.setUserId(res);
    //           // setTimeout(() => {
    //           //   this.router.navigate(['/dashboard']);
    //           // }, 100);
    //         },
    //         (err) => {
    //           var error = err;
    //           if (error) {
    //             this.errormMsg = true;
    //           }
    //         }
    //       );
    //     } else {
    //       this.userService.createUserLogin(params).subscribe(
    //         (res: any) => {
    //           if (res?.userInfo?.token) {
    //             this.errormMsg = false;
    //             this.ssoService.setUserAuthentication(res);
    //             this.userService.setUserId(res.userInfo);
    //             this.cookieService.deleteAll();
    //             setTimeout(() => {
    //               this.router.navigate(['/manage/jobs']);
    //             }, 100);
    //           } else {
    //             this.errormMsg = true;
    //           }
    //           // this.ssoService.setUserAuthentication(res);
    //           // this.userService.setUserId(res);
    //           // setTimeout(() => {
    //           //   this.router.navigate(['/dashboard']);
    //           // }, 100);
    //         },
    //         (err) => {
    //           var error = err;
    //           if (error) {
    //             this.errormMsg = true;
    //           }
    //         }
    //       );
    //     }
    //   } else {
    //     this.errormMsg = true;
    //   }
    // }
    // else {
    //   this.ssoService.setUserAuthentication();
    //   setTimeout(() => {
    //     this.router.navigate(['/dashboard']);
    //   }, 100);
    // }
  }
  pass() {
    this.errormMsg = false;
  }

  createCandidateLogin() {
    let params = {
      username: this.userData.name,
      password: this.userData.password,
    };
    if (this.loginInfo.valid) {
    }
    // else {
    //   this.ssoService.setUserAuthentication();
    //   setTimeout(() => {
    //     this.router.navigate(['/dashboard']);
    //   }, 100);
    // }
  }

  login() {}
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  sendEmail() {
    // if (this.forgotEmail) {
    //   $('#reset-model').modal('hide');
    //   let data = {
    //     username: this.forgotEmail,
    //   };
    //   let rout: any = window.location.href;
    //   let routeData = this.router?.url?.split('/')[1];
    //   if (routeData == 'candidates') {
    //     this.candidateLogin = true;
    //   }
    //   if (this.candidateLogin) {
    //     let candidateData = this.forgotEmail;
    //     this.userService
    //       .candidateNewPassword(candidateData)
    //       .subscribe((res) => {
    //         this.forgotEmail = null;
    //       });
    //   } else {
    //     this.userService.newpassword(data).subscribe((res) => {
    //       this.forgotEmail = null;
    //     });
    //   }
    // } else {
    //   let teamValidation = this.filterInfo.form.controls;
    //   for (let keyVar in teamValidation) {
    //     teamValidation[keyVar].markAsTouched();
    //     teamValidation[keyVar].markAsDirty();
    //   }
    // }
  }

  cancel() {
    this.forgotEmail = null;
  }
  userFull: any;
  userList(user: any) {
    // this.userService.GetUserFullName(user).subscribe((res: any) => {
    //   this.userFull = res;
    // });
  }
  visible: boolean = false;
  showDialog() {
    this.visible = true;
  }
  forgotSend() {
    this.visible = false;
  }

  forgotCancel() {
    this.visible = false;
  }
}
