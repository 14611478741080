import { Router } from '@angular/router';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { jobService } from '../../services/jobservice';

import { FormGroup, NgForm } from '@angular/forms';
import { Console } from 'console';
import { SsoService } from 'src/app/services/ssoService';
import { userService } from 'src/app/services/userservice';
import { AppComponentBase } from 'src/app/shared/app.component-base';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-schedule-login',
  templateUrl: './schedule-login.component.html',
  styleUrls: ['./schedule-login.component.scss']
})
export class ScheduleLoginComponent extends AppComponentBase implements OnInit  {

  scheduleLoginData: any = {};
  scheduleInterviewForm: any
  fieldTextType: boolean = false;

  forgotEmail: any;
  userDetails: any;
  userId: any;


  @ViewChild('loginInfo', { static: false }) loginInfo: NgForm;

  public userData: any;
  errormMsg: boolean = false;


  constructor(public injector: Injector, private ssoService: SsoService, public _userService: userService, 
    public router: Router, private cookieService: CookieService) {
    super(injector);
    this.userData = {};
    if (this.ssoService.isUserAuthenticated) {
      this.router.navigate['/dashboard'];
    }
    else {
      localStorage.removeItem("token");
    }

  }


  ngOnInit(): void {
  }


  // ScheduleeLogin() {
  //   this.router.navigateByUrl('/interviews');
  //   let username = this.scheduleLoginData.name;
  //   let passward = this.scheduleLoginData.password
  //   // this.jobService.GetLoginDetails(this.userId, username, passward).subscribe((data: any) => {
  //   //   if(data!='Invalid Credentials'){
  //   //     this.router.navigateByUrl('/interviews');
  //   //     this.errormMsg = false;
  //   //   }else {
  //   //     this.errormMsg = true;
  //   //   }
  //   // })

  // }

  ScheduleeLogin() {
    let params = {
      username: this.scheduleLoginData.name,
      password: this.scheduleLoginData.password
    }

      // let state = this.state;
        this._userService.createHiringLogin(params.username,params.password).subscribe((res: any) => {
          if (res) {
            this.errormMsg = false;
            this._userService.setScheduleLogin(true);
            localStorage.setItem('scheduleRes',JSON.stringify(res))
            this.ssoService.setScheduleData(res);
            this._userService.setUserId(res);
            this.cookieService.deleteAll();
            setTimeout(() => {
              this.router.navigate(['/interviews']);
            }, 100);
          }
          else {
            this.errormMsg = true;
          }
          // this.ssoService.setUserAuthentication(res);
          // this.userService.setUserId(res);
          // setTimeout(() => {
          //   this.router.navigate(['/dashboard']);
          // }, 100);
        }, err => {
          var error = err;
          if(error){
            this.errormMsg=true;
          }
        })
      

    // else {
    //   this.ssoService.setUserAuthentication();
    //   setTimeout(() => {
    //     this.router.navigate(['/dashboard']);
    //   }, 100);
    // }
  }

  // ScheduleeLogin() {
  //   this.router.navigateByUrl('/interviews');
  //   let params = {
  //     username: this.userData.name,
  //     password: this.userData.password
  //   }
  //   if (this.loginInfo.valid) {
  //     this._userService.createCandidateLogin(params).subscribe((res: any) => {
  //       if (res?.userInfo?.token) {
  //         this.errormMsg = false;
  //         this.ssoService.setUserAuthentication(res);
  //         this._userService.setUserId(res.userInfo);
  //         setTimeout(() => {
  //           this.router.navigate(['/candidate/candidates']);
  //         }, 100);
  //       }
  //       else {
  //         this.errormMsg = true;
  //       }
  //       // this.ssoService.setUserAuthentication(res);
  //       // this.userService.setUserId(res);
  //       // setTimeout(() => {
  //       //   this.router.navigate(['/dashboard']);
  //       // }, 100);
  //     })
  //   }
  //   // else {
  //   //   this.ssoService.setUserAuthentication();
  //   //   setTimeout(() => {
  //   //     this.router.navigate(['/dashboard']);
  //   //   }, 100);
  //   // }
  // }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }



}
