<main class="edu-info-main">
<div class="app_details_tabs_content tabss__sectioNN__linee mb-2 z9999 stickyy-partt">
  <ul class="stepbox-list d-flex flex-row w-100 ps-0">
    <li
      [ngClass]="{ active: tabb === 'tabb1' }"
      class="stepbox-list__item position-relative fw500 text-center pointer"
    >
      Basic Information
    </li>
    <li
      [ngClass]="{ active: tabb === 'tabb3' }"
      class="stepbox-list__item position-relative fw500 text-center pointer"
    >
      Work Experience
    </li>
    <li
      [ngClass]="{ active: tabb === 'tabb2' }"
      class="stepbox-list__item position-relative fw500 text-center pointer"
    >
      Educational Information
    </li>
    <!-- <li [ngClass]="{ active: tabb === 'tabb4' }"
      class="stepbox-list__item position-relative fw500 text-center pointer">Family Information</li> -->
    <li
      [ngClass]="{ active: tabb === 'tabb5' }"
      class="stepbox-list__item position-relative fw500 text-center pointer"
    >
      Immigration
    </li>
    <!-- <li [ngClass]="{ active: tabb === 'tabb6' }"
      class="stepbox-list__item position-relative fw500 text-center pointer">Declaration</li> -->
    <li
      [ngClass]="{ active: tabb === 'tabb7' }"
      class="stepbox-list__item position-relative fw500 text-center pointer"
    >
      Documents
    </li>
  </ul>
</div>
<div class="edu-info-part app_details_tabs_content">
<form #newEducationalForm="ngForm">
  <h1 class="fs18 fw600">Educational Information</h1>
  <div class="row">
    <ng-container *ngFor="
          let item of newNewEducationalInfoo.aanotherDegrees;
          let i = index
        ">
      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label">
          Higher Level Of Education
        </label>
        <ng-select class="" [items]="Education_details" bindLabel="educationName" bindValue="educationId"
          name="newProfileHiggherEducationn{{ i }}" #newProfileHiggherEducationn="ngModel"
          placeholder="Select higher level of education" [(ngModel)]="item.newProfileHiggherEducationnnn">
        </ng-select>
      </div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> Qualification </label>
        <ng-select class="" [items]="degreedropdown" bindLabel="degreeName" bindValue="degreeId"
          name="newProfileHigherQualificationn{{ i }}" #newProfileHigherQualificationn="ngModel"
          placeholder="Select qualification" [(ngModel)]="item.newProfileHigherQualificationnn">
        </ng-select>
      </div>

      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label">
          College / University Name
        </label>
        <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="item.profileCollegeNammee"
          name="profileCollegeNammeee{{ i }}" #profileCollegeNammeee="ngModel" placeholder="Enter name " />
      </div>

      <div class="col-md-6 mt-3 figma__ng__select">
        <label class="mb-2 figma__label">
          Year Of Graduation
        </label>
        <ng-select class="" [items]="countrycurrencylist" bindLabel="id" bindValue="id"
          name="profileeYearGraduation{{ i }}" #profileeYearGraduation="ngModel"
          placeholder="Select year of graduation" [(ngModel)]="item.profileeYearGraduationn">
        </ng-select>

        <!-- <div class="position-relative calender__ml">
          <p-calendar class="" name="profileeYearGraduation{{i}}" placeholder="DD/MM/YY"
            [(ngModel)]="item.profileeYearGraduationn" #profileeYearGraduation="ngModel"
            [showIcon]="true"></p-calendar>
        </div> -->
      </div>

      <div class="col-md-6 priority__Radios mobile_fields_gap priority_radios_mains"
        style="margin-top: 20px">
        <label class="form-label-d priority__label__heading new__input__label mb-2">
          Type:
        </label>
        <div class="gs-grouped-fileds pt-0 d-flex">
          <div class="ui radio checkbox priority_checks">
            <input class="pointer" type="radio" name="NewProfileEduTypee{{ i }}" checked="true"
              value="fulltime" #NewProfileEduTypee="ngModel" [(ngModel)]="item.NewProfileEduTypee" />
            <!-- style="font-size: 12px;" -->
            <label style="font-size: 12px" [ngStyle]="{
                  color:
                    newNewEducationalInfoo.NewProfileEduTypee ==
                    'profileFullTime'
                      ? 'black'
                      : 'gray'
                }">Full Time</label>
          </div>
          <div class="ui radio checkbox priority_checks c1p">
            <input class="pointer" type="radio" name="NewProfileEduTypee{{ i }}" value="PartTime"
              #NewProfileEduTypee="ngModel" [(ngModel)]="item.NewProfileEduTypee" />
            <label label style="font-size: 12px" [ngStyle]="{
                  color:
                    newNewEducationalInfoo.NewProfileEduTypee ==
                    'profilePartTime'
                      ? 'black'
                      : 'gray'
                }">Part Time</label>
          </div>
        </div>
      </div>

      <div class="industry_plus_btn mt-5 col-md-12" style="margin-top: 33px !important">
        <button role="button" class="figma_blue_button mr-3" *ngIf="
              i ==
                newNewEducationalInfoo.aanotherDegrees.length - 1 ||
              newNewEducationalInfoo.aanotherDegrees.length == 1
            " (click)="newAddAnotherDegreeeFields(i)">
          + Add another degree
        </button>
        <button role="button" class="discard_red_button mr-3" style="
              color: rgba(254, 84, 84, 1) !important;
              border: 1.5px solid #fe5454;
            " (click)="newDeleteAnotherDegreeeFields(i)" *ngIf="
              newNewEducationalInfoo.aanotherDegrees.length ===
                i + 1 && i != 0
            ">
          Remove
        </button>
      </div>
    </ng-container>

    <ng-container *ngFor="
          let x of newNewEducationalInfoo.addMoreSkills;
          let i = index
        ">
      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> Skills Category </label>
        <ng-select class="" [items]="getSkills_Category" bindLabel="skill_CategoryName"
          bindValue="skill_CategoryId" name="newProfileSkillsCategoryy{{ i }}"
          #newProfileSkillsCategoryy="ngModel" (change)="SkillNameById($event, i)"
          placeholder="Select skills category" [(ngModel)]="x.newAddAppSkillsCategoryyy">
        </ng-select>
      </div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> Skills </label>

        <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.newAddAppSkillsss"
          name="newAddAppSkillss{{i}}" #newAddAppSkillss="ngModel" placeholder="Enter skills" /> -->

        <ng-select class="" [items]="x.skill_list" bindLabel="skillName" bindValue="skillCategoryId"
          name="newAddAppSkillss{{ i }}" #newAddAppSkillss="ngModel" placeholder="Select skills"
          [(ngModel)]="x.newAddAppSkillsss">
        </ng-select>
      </div>

      <div class="industry_plus_btn mt-3 col-md-12">
        <span role="button" class="add__Text mr-3" *ngIf="
              i ==
                newNewEducationalInfoo.addMoreSkills.length - 1 ||
              newNewEducationalInfoo.addMoreSkills.length == 1
            " (click)="newAddMoreSkillsFields(i)">
          + Add
        </span>
        <span role="button" class="add__Text remove__Text mr-3"
          style="color: rgba(254, 84, 84, 1) !important" (click)="newRemoveMoreSkillsFields(i)" *ngIf="
              newNewEducationalInfoo.addMoreSkills.length ===
                i + 1 && i != 0
            ">
          Remove
        </span>
      </div>
    </ng-container>

    <!-- <div class="col-md-6 figma__ng__select mt-3">
      <label class="mb-2 figma__label"> Languages known  </label>
      <ng-select class="" [items]="newProfileHigherLanguages" bindLabel="name" bindValue="id"
        name="newProfileHigherLanguagess" #newProfileHigherLanguagess="ngModel"
        placeholder="Select languages" [multiple]="true"
        [(ngModel)]="newNewEducationalInfoo.newProfileHigherLanguagesss">
      </ng-select>
    </div> -->
    <ng-container *ngFor="
          let x of newNewEducationalInfoo.addMorelanguages;
          let i = index
        ">
      <div class="d-md-flex row col-md-6 px-0 ml-1 mt-3">
        <div class="col-md-4 figma__ng__select">
          <label class="mb-2 figma__label">
            Languages known
          </label>
          <ng-select class="" [items]="languageList" bindLabel="languageName" bindValue="languageId"
            name="newProfileHigherLanguagess{{ i }}" #newProfileHigherLanguagess="ngModel"
            placeholder="Select languages" [multiple]="false" [(ngModel)]="x.newProfileHigherLanguagesss">
          </ng-select>
        </div>
        <div class="col-md-4">
          <label class="mb-2 figma__label">
            Form of Language
          </label>
          <input type="text" class="col-md-12 figma__Inputs h_42 no-cursor" [value]="Oral"
            [(ngModel)]="x.profileFormLanguage" name="profileFormLanguagee{{ i }}"
            #profileFormLanguagee="ngModel" />
        </div>
        <div class="col-md-4 figma__ng__select">
          <label class="mb-2 figma__label"> Proficiency </label>
          <ng-select class="" [items]="newProfilelangProfiiency" bindLabel="name" bindValue="name"
            name="newProfilelangProfiiencyy{{ i }}" #newProfilelangProfiiencyy="ngModel"
            placeholder="Select proficiency" [multiple]="false" [(ngModel)]="x.newProfilelangProfiiencyyy">
          </ng-select>
        </div>
        <div class="col-md-4 mt-3"></div>
        <!--  -->
        <div class="col-md-4 mt-3">
          <label class="mb-2 figma__label">
            Form of Language
          </label>
          <input type="text" class="col-md-12 figma__Inputs h_42 no-cursor"
            [(ngModel)]="x.profileFormLanguageTwo" name="profileFormLanguageTwoo{{ i }}"
            #profileFormLanguageTwoo="ngModel" value="Oral" />
        </div>
        <div class="col-md-4 figma__ng__select mt-3">
          <label class="mb-2 figma__label"> Proficiency </label>
          <ng-select class="" [items]="newProfilelangProfiiency" bindLabel="name" bindValue="name"
            name="newProfilelangProfiiencyy{{ i }}" #newProfilelangProfiiencyy="ngModel"
            placeholder="Select proficiency" [multiple]="false"
            [(ngModel)]="x.newProfilelangProfiiencyyyTwo">
          </ng-select>
        </div>
        <!--  -->
      </div>

      <div class="industry_plus_btn mt-5 col-md-12" style="margin-top: 33px !important">
        <span role="button" class="plus_add mr-3" *ngIf="
              i ==
                newNewEducationalInfoo.addMorelanguages.length -
                  1 ||
              newNewEducationalInfoo.addMorelanguages.length == 1
            " (click)="newAddMorelanguages(i)">
          + Add More Languages
        </span>
        <span role="button" class="add__Text remove__Text mr-3"
          style="color: rgba(254, 84, 84, 1) !important" (click)="newRemoveMoreLanguages(i)" *ngIf="
              newNewEducationalInfoo.addMorelanguages.length ===
                i + 1 && i != 0
            ">
          Remove
        </span>
      </div>
    </ng-container>

    <div class="col-md-12 mt-4">
      <button type="button" class="figma_blue_button">
        <i class="icon-angle-down angle-rotate-d"></i> Back
      </button>
      <button type="button" class="figma_background_blue_btn float-right" style="padding: 12px 42px"
        >
        Save & Next
      </button>
    </div>
  </div>
</form>
</div>
</main>
