<section class="new__applicant__main">
  <div class="new__App__Heading_main d-flex bulk__heading col-md-12 pl-0 align-items-center">
    <span role="button" class="new_figma_blue_btn relative" (click)="newAppback()">
      <img class="new_app_back_icon" src="./assets/Images/create-arrow.svg"
        alt=" back blue  " />
    </span>

    <h1>New Applicant</h1>
  </div>
  <form #newNewApplicantForm="ngForm">
    <div class="app_details_tabs_content tabss__sectioNN__linee mb-2">
      <ul class="stepbox-list d-flex flex-row w-100 ps-0">
        <li (click)="onClickk(1)" [ngClass]="{ active: tabb === 'tabb1' }"
          class="stepbox-list__item position-relative fw500 text-center pointer">
          Basic Information
        </li>
        <li [ngClass]="{ active: tabb === 'tabb3' }" *ngIf="!enableNextButtons"
          class="stepbox-list__item position-relative fw500 text-center pointer">
          Work Experience
        </li>
        <li [ngClass]="{ active: tabb === 'tabb2' }" *ngIf="!enableNextButtons"
          class="stepbox-list__item position-relative fw500 text-center pointer">
          Educational Information
        </li>
        <!-- <li (click)="onClickk(4)" [ngClass]="{ active: tabb === 'tabb4' }"
        class="stepbox-list__item position-relative fw500 text-center pointer">Family Information</li> -->
        <li [ngClass]="{ active: tabb === 'tabb5' }" *ngIf="!enableNextButtons"
          class="stepbox-list__item position-relative fw500 text-center pointer">
          Immigration
        </li>
        <!-- <li (click)="onClickk(6)" [ngClass]="{ active: tabb === 'tabb6' }"
        class="stepbox-list__item position-relative fw500 text-center pointer">Declaration</li> -->
        <li [ngClass]="{ active: tabb === 'tabb7' }" *ngIf="!enableNextButtons"
          class="stepbox-list__item position-relative fw500 text-center pointer">
          Documents
        </li>
        <li (click)="onClickk(3)" [ngClass]="{ active: tabb === 'tabb3' }" *ngIf="enableNextButtons"
          class="stepbox-list__item position-relative fw500 text-center pointer">
          Work Experience
        </li>
        <li (click)="onClickk(2)" [ngClass]="{ active: tabb === 'tabb2' }" *ngIf="enableNextButtons"
          class="stepbox-list__item position-relative fw500 text-center pointer">
          Educational Information
        </li>
        <!-- <li (click)="onClickk(4)" [ngClass]="{ active: tabb === 'tabb4' }"
          class="stepbox-list__item position-relative fw500 text-center pointer">Family Information</li> -->
        <li (click)="onClickk(5)" [ngClass]="{ active: tabb === 'tabb5' }" *ngIf="enableNextButtons"
          class="stepbox-list__item position-relative fw500 text-center pointer">
          Immigration
        </li>
        <!-- <li (click)="onClickk(6)" [ngClass]="{ active: tabb === 'tabb6' }"
          class="stepbox-list__item position-relative fw500 text-center pointer">Declaration</li> -->
        <li (click)="onClickk(7)" [ngClass]="{ active: tabb === 'tabb7' }" *ngIf="enableNextButtons"
          class="stepbox-list__item position-relative fw500 text-center pointer">
          Documents
        </li>
      </ul>
    </div>

    <div class="new_add_applicant__profile">
      <div class="profile__forms_mainn app_details_tabs_content">
        <ng-container *ngIf="newProfilee">
          <h1 class="fw600 fs18">Basic Information</h1>
          <form #profileBasicInfoForm="ngForm">
            <div class="row">
              <ng-container *ngIf="!enableDoc">
              <div class="col-lg-3 col-12 mt-4 newUpload_pdf width-max documents">
                <label class="mb-2 figma__label mb-1">
                  Upload Resume <span class="errorRequired">*</span>
                </label>

                <!-- [disabled]="NewnewResumeFiles.length == 1" -->
                <!-- [accept]="'application/pdf'" -->
                <ngx-dropzone class=""  [ngClass]="
                      !NewnewResumeFiles.length
                        ? 'emptyPDFClass'
                        : 'FillPDFClass'
                    " (change)="newOnNewSelect($event)" [multiple]="false">
                  <ngx-dropzone-label *ngIf="!NewnewResumeFiles.length">
                    <div class="d-md-flex">
                      <button type="button" class="bulk__UploadResume">
                        <img src="../../../assets/Images/new-upload.svg" alt="upload" />
                      </button>
                      <h1 class="fw600 fs14 figma_blue_color pl-3 mt-2">
                        Upload Resume
                      </h1>
                    </div>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of NewnewResumeFiles" [removable]="true"
                    (removed)="newOnnewRemove(f)">
                    <ngx-dropzone-label class="d-flex ml-4 align-items-center">
                      <!-- <img src="{{ appSettings.imageUrl }}/img/red-pdf.svg" alt="red pdf" /> -->
                      <img *ngIf="fileTypee == 'application/pdf'" src="./assets/img/red-pdf.svg" alt="red pdf" />

                      <svg *ngIf="fileTypee == 'application/msword'" width="30" height="30" fill="#764fdb" class="bi bi-file-earmark-word-fill" viewBox="0 0 16 16">
                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M5.485 6.879l1.036 4.144.997-3.655a.5.5 0 0 1 .964 0l.997 3.655 1.036-4.144a.5.5 0 0 1 .97.242l-1.5 6a.5.5 0 0 1-.967.01L8 9.402l-1.018 3.73a.5.5 0 0 1-.967-.01l-1.5-6a.5.5 0 1 1 .97-.242z"/>
                      </svg>
                      <h1 class="fw600 fs14 pl-3 width-max">
                        {{ f.name | slice : 0 : 10 }}
                      </h1>
                    </ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
                <div *ngIf="uploadResumeError">
                  <span class="errorMessage"> Please upload resume </span>
                </div>
                <span class="errorMessage" *ngIf="supporteFilesType">
                  Upload PDF/Doc Files Only.
                  </span>
              </div>
            </ng-container>

            <ng-container *ngIf="enableDoc">

              <div class="col-md-12 newUpload_pdf mt-3">
                <label class="mb-2 figma__label mb-1">
                  Resume
                </label>
                <p class="relative" style="width:max-content !important">
                  <a style="color: #764fdb !important;" href="{{docPathel}}" target="_blank">{{docNameel}} </a>
                  <img class="absolute" style="width: 9px; cursor: pointer; right: -16px; top: -5px;"
                    src="./assets/img/blue-close-icon.svg" alt="blue close" (click)="closeEl()"
                    />
                </p>
              </div>
            </ng-container>

                <!-- from applicant pool popup -->
                <!-- <ng-container *ngIf="addAppPoolModal">
                  <div class="reject_Reason_main add-app-pool-popup-main absolute z9999">
                    <div class="reject_Reasonnn recruit__memberrs relative px-4" style="height: fit-content !important">
                      <div class="d-flex align-items-center">
                        <h1 class="fw600 fs16"> Select Job Title </h1>
                        <button class="new_figma_blue_btn ml-auto" (click)="appPoolpopup()">
                          <img src="./assets/img/blue-close-icon.svg" alt="cross" />
                        </button>
                      </div>
                      <div class="row">


                      <div class="col-md-12 mt-4" *ngIf="enableDoc">
                        <label class="mb-2 figma__label">
                          Add Applicant to ? <span class="errorRequired">*</span>
                        </label>
                        <div class="gs-grouped-fileds pt-0 d-flex mt-2">
                          <div class="priority_checks align-items-center d-flex">
                            <input class="pointer accent-color" type="radio" name="jobSelectType" value="Archive"
                              #jobSelectType="ngModel" [(ngModel)]="nnewNewBasicInfoo.jobSelectType"
                              (change)="onItemChange($event.target.value)" required />
                            <label class="ml-2 figma__label" style="margin-bottom: 0 !important">
                              Data bank
                            </label>
                          </div>
                          <div class="priority_checks align-items-center d-flex c1p">
                            <input class="pointer accent-color" type="radio" name="jobSelectType" value="Active"
                              #jobSelectType="ngModel" [(ngModel)]="nnewNewBasicInfoo.jobSelectType"
                              (change)="onItemChange($event.target.value)" required />
                            <label class="ml-2 figma__label" style="margin-bottom: 0 !important">
                              An Active Job
                            </label>
                          </div>
                        </div>
                        <div *ngIf="
                              (jobSelectType?.touched ||
                                profileBasicInfoForm.submitted) &&
                              jobSelectType.errors?.required
                            ">
                          <p class="errorMessage mt-2">Please select job type</p>
                        </div>
                      </div>

                      <div class="col-md-6 figma__ng__select mt-3" *ngIf="enableDoc">
                        <label class="mb-2 figma__label">
                          Job Title <span class="errorRequired">*</span>
                        </label>
                        <ng-container *ngIf="archiveSelected">
                          <ng-select class="" [items]="joblistarray" bindLabel="jobTitle" (change)="onCampus($event)"
                            bindValue="jobId" name="newAddAppJobTitle" #newAddAppJobTitle="ngModel"
                            placeholder="Select job title" [(ngModel)]="nnewNewBasicInfoo.newAppProfileJobTitleee" required>
                          </ng-select>
                          <div *ngIf="
                                (newAddAppJobTitle?.touched ||
                                  profileBasicInfoForm.submitted) &&
                                newAddAppJobTitle.errors?.required
                              ">
                            <span class="errorMessage">
                              Please select job title
                            </span>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!archiveSelected">
                          <ng-select class="" [items]="joblistarray" bindLabel="job_Name" bindValue="masterJobId"
                            name="newAddAppJobTitle" (change)="normalJob($event)" #newAddAppJobTitle="ngModel"
                            placeholder="Select job title" [(ngModel)]="nnewNewBasicInfoo.newAppProfileJobTitleee" required>
                          </ng-select>
                          <div *ngIf="
                                (newAddAppJobTitle?.touched ||
                                  profileBasicInfoForm.submitted) &&
                                newAddAppJobTitle.errors?.required
                              ">
                            <span class="errorMessage">
                              Please select job title
                            </span>
                          </div>
                        </ng-container>
                      </div>

                      <div class="col-md-6" *ngIf="!campusActiveSelected && enableDoc"></div>
                      <div class="col-md-6 figma__ng__select mt-3" *ngIf="campusActiveSelected && enableDoc">
                        <label class="mb-2 figma__label">
                          Campus <span class="errorRequired">*</span>
                        </label>
                        <ng-select class="" [items]="campusRes" bindLabel="campusName" bindValue="campusId"
                          name="newAppProfileCampus" #newAppProfileCampus="ngModel" placeholder="Select campus"
                          [(ngModel)]="nnewNewBasicInfoo.newAppProfileJobCampus" [multiple]="true" required>
                        </ng-select>
                        <div *ngIf="
                              (newAppProfileCampus?.touched ||
                                profileBasicInfoForm.submitted) &&
                              newAppProfileCampus.errors?.required
                            ">
                          <span class="errorMessage"> Please select campus </span>
                        </div>
                      </div>

                      <div class="col-md-12">
                      <button type="button" class="btn figma_blue_button mt-3 d-flex ml-auto"
                      [disabled]="!nnewNewBasicInfoo.jobSelectType || !nnewNewBasicInfoo.newAppProfileJobTitleee
                       || !nnewNewBasicInfoo.newAppProfileJobCampus" (click)="proceedAddApppoolPopUp()">Proceed</button>
                    </div>
                    </div>
                    </div>
                  </div>
              </ng-container> -->

                <!-- from applicant pool popup -->


              <div class="col-md-12 mt-4">
                <label class="mb-2 figma__label">
                  Add Applicant to ? <span class="errorRequired">*</span>
                </label>
                <div class="gs-grouped-fileds pt-0 d-flex mt-2">
                  <!-- <div class="priority_checks align-items-center d-flex">
                    <input class="pointer accent-color" type="radio" name="jobSelectType" value="Archive"
                      #jobSelectType="ngModel" [(ngModel)]="nnewNewBasicInfoo.jobSelectType"
                      (change)="onItemChange($event.target.value)" required />
                    <label class="ml-2 figma__label" style="margin-bottom: 0 !important">
                      Data bank
                    </label>
                  </div> -->
                    <!-- style="font-size: 12px;" -->
                    <!-- <label style="font-size: 12px"
                      [ngStyle]="{color:nnewNewBasicInfoo.jobSelectType == 'Archive'? 'black': 'gray'}">Archive</label> -->
                  <div class="priority_checks align-items-center d-flex c1p ml-0">
                    <input class="pointer accent-color" type="radio" name="jobSelectType" value="Active"
                      #jobSelectType="ngModel" [(ngModel)]="nnewNewBasicInfoo.jobSelectType"
                       required />
                       <!-- (change)="onItemChange($event.target.value)" -->
                    <label class="ml-2 figma__label" style="margin-bottom: 0 !important">
                      An Active Job
                    </label>
                    <!-- <label label style="font-size: 12px"
                      [ngStyle]="{color:nnewNewBasicInfoo.jobSelectType == 'Active'? 'black': 'gray'}">Active</label> -->
                  </div>
                </div>
                <div *ngIf="
                      (jobSelectType?.touched ||
                        profileBasicInfoForm.submitted) &&
                      jobSelectType.errors?.['required']
                    ">
                  <p class="errorMessage mt-2">Please select job type</p>
                </div>
              </div>
              <br />
              <div class="col-md-6 figma__ng__select mt-3 figma-ng-select">
                <label class="mb-2 figma__label">
                  Job Title <span class="errorRequired">*</span>
                </label>
                <!-- *ngIf="archiveSelected" -->
                <!--  *ngIf="!archiveSelected" -->
                <ng-container *ngIf="archiveSelected">
                  <ng-select class="" [items]="joblistarray" bindLabel="jobTitle" (change)="onCampus($event)"
                    bindValue="jobId" name="newAddAppJobTitle" #newAddAppJobTitle="ngModel"
                    placeholder="Select job title" [(ngModel)]="nnewNewBasicInfoo.newAppProfileJobTitleee" required>
                  </ng-select>
                  <div *ngIf="
                        (newAddAppJobTitle?.touched ||
                          profileBasicInfoForm.submitted) &&
                        newAddAppJobTitle.errors?.['required']
                      ">
                    <span class="errorMessage">
                      Please select job title
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="!archiveSelected">
                  <ng-select class="" [items]="joblistarray" bindLabel="job_Name" bindValue="masterJobId"
                    name="newAddAppJobTitle" (change)="normalJob($event)" #newAddAppJobTitle="ngModel"
                    placeholder="Select job title" [(ngModel)]="nnewNewBasicInfoo.newAppProfileJobTitleee" required>
                  </ng-select>
                  <div *ngIf="
                        (newAddAppJobTitle?.touched ||
                          profileBasicInfoForm.submitted) &&
                        newAddAppJobTitle.errors?.['required']
                      ">
                    <span class="errorMessage">
                      Please select job title
                    </span>
                  </div>
                </ng-container>
              </div>

              <div class="col-md-6" *ngIf="!campusActiveSelected"></div>
              <div class="col-md-6 figma__ng__select mt-3" *ngIf="campusActiveSelected">
                <label class="mb-2 figma__label">
                  Campus <span class="errorRequired">*</span>
                </label>
                <ng-select class="" [items]="campusRes" bindLabel="campusName" bindValue="campusId"
                  name="newAppProfileCampus" #newAppProfileCampus="ngModel" placeholder="Select campus"
                  [(ngModel)]="nnewNewBasicInfoo.newAppProfileJobCampus" [multiple]="true" required>
                </ng-select>
                <div *ngIf="
                      (newAppProfileCampus?.touched ||
                        profileBasicInfoForm.submitted) &&
                      newAppProfileCampus.errors?.['required']
                    ">
                  <span class="errorMessage"> Please select campus </span>
                </div>
              </div>
              <div class="col-md-6 mt-3 figma-ng-select figma-input">
                <label class="mb-2 figma__label">
                  First Name <span class="errorRequired">*</span>
                </label>
                <input type="text" class="col-md-12 figma__Inputs h_42" (keydown)="noSpaceBar($event)"
                  [(ngModel)]="nnewNewBasicInfoo.profileFirstName" name="profileFirstNamee"
                  #profileFirstNamee="ngModel" placeholder="Enter first name"
                  (keypress)="restrictNumbers($event)" required />
                <div *ngIf="
                      (profileFirstNamee?.touched ||
                        profileBasicInfoForm.submitted) &&
                      profileFirstNamee.errors?.['required']
                    ">
                  <span class="errorMessage">
                    Please enter first name
                  </span>
                </div>
              </div>
              <div class="col-md-6 mt-3 figma-ng-select figma-input">
                <label class="mb-2 figma__label">
                  Last Name <span class="errorRequired">*</span>
                </label>
                <input type="text" class="col-md-12 figma__Inputs h_42" (keydown)="noSpaceBar($event)"
                  [(ngModel)]="nnewNewBasicInfoo.profileLasstName" name="profileLasstNamee"
                  (keypress)="restrictNumbers($event)" #profileLasstNamee="ngModel" placeholder="Enter last name"
                  required />
                <div *ngIf="
                      (profileLasstNamee?.touched ||
                        profileBasicInfoForm.submitted) &&
                      profileLasstNamee.errors?.['required']
                    ">
                  <span class="errorMessage"> Please enter last name </span>
                </div>
              </div>
              <div class="col-md-6 mt-3 figma-input">
                <label class="mb-2 figma__label">
                  Email ID <span class="errorRequired">*</span>
                </label>
                <input type="email" class="col-md-12 figma__Inputs h_42" (keydown)="noSpaceBar($event)"
                  [(ngModel)]="nnewNewBasicInfoo.profileEmailID" name="profileEmailIDdd" #profileEmailIDdd="ngModel"
                  placeholder="Enter email id" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                  (keyup)="emailValidate($event)" required/>
                <div *ngIf="
                      (profileEmailIDdd?.touched ||
                        profileBasicInfoForm.submitted) &&
                      profileEmailIDdd.errors?.['required']
                    ">
                  <span class="errorMessage"> Please enter email id </span>
                </div>
                <!-- <div class="errorMessage" *ngIf="profileBasicInfoForm.controls.profileEmailIDdd.errors?.pattern">Invalid email format</div> -->
                <div *ngIf="emailValid">
                  <span class="errorMessage">
                    MyGIIS ID or Email already exists.
                  </span>
                </div>
              </div>
              <div class="col-md-6 mt-3 figma-input">
                <label class="mb-2 figma__label">
                  Alternative Email ID
                </label>
                <input type="text" class="col-md-12 figma__Inputs h_42" (keydown)="noSpaceBar($event)"
                  [(ngModel)]="nnewNewBasicInfoo.profilealternateEmailIDD" name="profilealternateEmailIDDd"
                  #profilealternateEmailIDDd="ngModel" placeholder="Enter alternative email id"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"/>
                  <!-- <div class="errorMessage" *ngIf="profileBasicInfoForm.controls.profilealternateEmailIDDd.errors?.pattern">Invalid email format</div> -->
                <!-- <div *ngIf="(profilealternateEmailIDDd?.touched || profileBasicInfoForm.submitted) && profilealternateEmailIDDd.errors?.required">
                    <span class="errorMessage">
                      Please enter alternative email id
                    </span>
                  </div> -->
              </div>
              <div class="col-md-6 mt-3 new__Phoe__parttttttt figma__ng__select figma-input figma-ng-select">
                <label class="mb-2 figma__label">
                  Phone No <span class="errorRequired">*</span>
                </label>
                <div class="d-flex align-items-center">
                  <ng-select style="width: 70px" class="" [(ngModel)]="nnewNewBasicInfoo.phoneNumbercode"
                    #newAddAppphoneNumber="ngModel" [items]="Allcountry" bindLabel="countryCode" bindValue="countryCode"
                    name="phoneCodeNewApp" placeholder="+91" (change)="disabledNumberInput('two')" required>
                  </ng-select>
                  <input onkeypress="return /[0-9]/i.test(event.key)" [(ngModel)]="nnewNewBasicInfoo.phoneNumber"
                    #newAddAppphoneNumber12="ngModel" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                    class="w-100 figma__Inputs mt-0" type="text" name="newAddAppphoneNumber12" required
                    placeholder="Enter phone number" (keyup)="ValidateApplicantPhoneNo($event)"
                     style="border-radius : 0 8px 8px 0 !important; height:42px !important" />
                  <!-- [disabled]="phoneNumberdisabledByCodee" -->
                  <!-- pattern="[111][0-9]{9}" -->
                </div>
                <!-- <div class="errorMessage" *ngIf="profileBasicInfoForm.controls.newAddAppphoneNumber12.errors?.pattern">
                  Invalid phone number format</div> -->
                <!-- <div *ngIf="
                      (newAddAppphoneNumber.touched ||
                        profileBasicInfoForm.submitted) &&
                      newAddAppphoneNumber.errors?.required
                    ">
                  <span class="errorMessage">
                    Please select code
                  </span>
                </div> -->
                <div *ngIf="
                      (newAddAppphoneNumber12.touched ||
                        profileBasicInfoForm.submitted) &&
                      newAddAppphoneNumber12.errors?.['required']
                    ">
                  <span class="errorMessage">
                    Please enter phone number
                  </span>
                </div>
                <div *ngIf="phoneValid">
                  <span class="errorMessage">
                    Phone number already exists.
                  </span>
                </div>
              </div>
              <div class="col-md-6"></div>
              <div class="col-md-6 mt-3 figma_calender_input new-app-DOB new-app-DOB--2 figma-calender">
                <label class="mb-2 figma__label">
                  Date Of Birth <span class="errorRequired" *ngIf="countryNameforImmigration!=='Singapore'">*</span>
                </label>
                <div class="position-relative calender__ml">
                  <p-calendar class="" name="profileeDOBBb" placeholder="Select date of birth" [maxDate]="minAgee"
                    [(ngModel)]="nnewNewBasicInfoo.profileeeDOBB" #profileeDOBBb="ngModel" [showIcon]="true"
                     ></p-calendar>
                     <!-- [required]="countryNameforImmigration=='Singapore' ? 'false' : 'true'" -->
                    <!-- [required]="countryNameforImmigration=='Singapore' ? 'false' : 'true'" -->
                </div>
                <div *ngIf="
                      (profileeDOBBb?.touched ||
                        profileBasicInfoForm.submitted) &&
                      profileeDOBBb.errors?.['required']
                    ">
                  <span class="errorMessage">
                    Please select date of birth
                  </span>
                </div>
              </div>
              <!-- <div class="col-md-6 figma__ng__select mt-3">
            <label class="mb-2 figma__label"> Gender </label>
            <ng-select class="" [items]="newProfileGender" bindLabel="id" bindValue="name"
              [(ngModel)]="nnewNewBasicInfoo.newProfileGenderr" #gender="ngModel" name="gender"
              placeholder="Select gender">
            </ng-select>
          </div> -->
              <div class="col-md-6 priority__Radios mobile_fields_gap priority_radios_mains" style="margin-top: 20px" >
                <label class="form-label-d priority__label__heading new__input__label mb-2">Gender <span
                    class="errorRequired" *ngIf="countryNameforImmigration!=='Singapore'">*</span>
                </label>
                <div class="gs-grouped-fileds pt-0 d-flex">
                  <div class="ui radio checkbox priority_checks">
                    <input class="pointer" type="radio" name="NewProfileGenderr" checked="true" value="Male"
                      #NewProfileGenderr="ngModel" [(ngModel)]="nnewNewBasicInfoo.NewProfileGenderr"
                      [required]="countryNameforImmigration=='Singapore' ? 'false' : 'true'" />
                       <!-- [required]="countryNameforImmigration=='Singapore' ? 'false' : 'true'" -->
                    <!-- style="font-size: 12px;" -->
                    <label style="font-size: 12px" [ngStyle]="{
                          color:
                            nnewNewBasicInfoo.NewProfileGenderr ==
                            'profileMaale'
                              ? 'black'
                              : 'gray'
                        }">Male</label>
                  </div>
                  <div class="ui radio checkbox priority_checks c1p">
                    <input class="pointer" type="radio" name="NewProfileGenderr" value="Female"
                      #NewProfileGenderr="ngModel" [(ngModel)]="nnewNewBasicInfoo.NewProfileGenderr"
                      [required]="countryNameforImmigration=='Singapore' ? 'false' : 'true'" />
                       <!-- [required]="countryNameforImmigration=='Singapore' ? 'false' : 'true'" -->
                    <label label style="font-size: 12px" [ngStyle]="{
                          color:
                            nnewNewBasicInfoo.NewProfileGenderr ==
                            'profileFemale'
                              ? 'black'
                              : 'gray'
                        }">Female</label>
                  </div>
                  <div class="ui radio checkbox priority_checks c1p">
                    <input class="pointer" type="radio" name="NewProfileGenderr" value="Others"
                      #NewProfileGenderr="ngModel" [(ngModel)]="nnewNewBasicInfoo.NewProfileGenderr"
                      [required]="countryNameforImmigration=='Singapore' ? 'false' : 'true'"/>
                       <!-- [required]="countryNameforImmigration=='Singapore' ? 'false' : 'true'" -->
                    <label label style="font-size: 12px" [ngStyle]="{
                          color:
                            nnewNewBasicInfoo.NewProfileGenderr ==
                            'profileOTherss'
                              ? 'black'
                              : 'gray'
                        }">Others</label>
                  </div>
                </div>
                <div *ngIf="
                      (NewProfileGenderr?.touched ||
                        profileBasicInfoForm.submitted) &&
                      NewProfileGenderr.errors?.['required']
                    ">
                  <span class="errorMessage"> Please select gender </span>
                </div>
              </div>
              <div class="newProfile_border_bottom col-md-12 my-3"></div>

              <div class="col-md-6 figma__ng__select figma-ng-select">
                <label class="mb-2 figma__label"> Nationality </label>
                <ng-select class="" (change)="getnationalityid(nnewNewBasicInfoo.nationality_Id)" [items]="nationalitycountry"
                  bindLabel="nationality_Name" bindValue="nationality_Id" name="newProfileNationalityyy"
                  #newProfileNationalityyy="ngModel" placeholder="Select nationality"
                  [(ngModel)]="nnewNewBasicInfoo.nationality_Id">
                </ng-select>
              </div>

              <!-- <div class="col-md-6 figma__ng__select">
                <label class="mb-2 figma__label"> Permanent Residence of (Country) </label>
                <ng-select class="" [items]="newProfilepermanentResidence" bindLabel="name" bindValue="id"
                  name="newProfilepermanentResidenceee" #newProfilepermanentResidenceee="ngModel"
                  placeholder="Select country" [(ngModel)]="nnewNewBasicInfoo.newProfilepermanentResidencee">
                </ng-select>
              </div> -->

              <div class="col-md-6 figma__ng__select figma-ng-select">
                <label class="mb-2 figma__label"> Nationality Id </label>
                <ng-select class="" (change)="getValueforappend($event)" [items]="nationalitydocarr"
                  bindLabel="name" bindValue="name" name="newProfileNationalityidd"
                  #newProfileNationalityidd="ngModel" placeholder="Select nationality id"
                  [(ngModel)]="nnewNewBasicInfoo.newProfileNationalityyid">
                </ng-select>
              </div>

              <div class="col-md-6 figma__ng__select mt-3 figma-input">
                <label class="mb-2 figma__label"> Id Number </label>
                <!-- <ng-select class="" [items]="newProfilenewProfileidNumberrr" bindLabel="name" bindValue="id"
                  name="newProfilenewProfileidNumberr" #newProfilenewPProfileidNumberr="ngModel"
                  placeholder="Select id number" [(ngModel)]="nnewNewBasicInfoo.newProfileiDDDNumber">
                </ng-select> -->

                <!-- *ngIf="mandetoryStatus==false" -->
                <!-- [disabled]="mandetoryStatus == false" -->
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="
                      nnewNewBasicInfoo.newProfilePassportIDDDNumber
                    " name="newProfilePassportIDDDNumberr" #newProfilePassportIDDDNumberr="ngModel"
                  placeholder="Enter id number" pattern="^[A-Za-z0-9]+$" />
                <!-- <div class="errorMessage" *ngIf="
                      profileBasicInfoForm.controls
                        .newProfilePassportIDDDNumberr?.errors?.pattern
                    ">
                  Please enter only characters and numbers
                </div> -->
                <!-- onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"  -->
              </div>

              <!-- <div class="col-md-6"></div> -->

              <div class="d-flex col-md-6 mt-3 figma_calender_input figma-calender">
                <div class="col-md-6 pl-0">
                  <label class="mb-2 figma__label"> Issue Date </label>
                  <div class="position-relative calender__ml">
                    <p-calendar class="" name="profileeIssueDatee" placeholder="Select date"
                       (onSelect)="onDateSelect($event, 'immigration')"
                      [(ngModel)]="nnewNewBasicInfoo.profileeIssueDateee" #profileeIssueDatee="ngModel"
                      [showIcon]="true"></p-calendar>
                  </div>
                </div>

                <!--  -->
                <div class="col-md-6 pr-0 figma-calender">
                  <label class="mb-2 figma__label"> Expiry Date </label>
                  <div class="position-relative calender__mll">
                    <p-calendar class="" name="profileeExpiryDatee" placeholder="Select date"
                      [(ngModel)]="nnewNewBasicInfoo.profileeExpiryDateeee"
                      #profileeExpiryDatee="ngModel" [showIcon]="true"></p-calendar>
                  </div>
                </div>
                <!--  -->
              </div>
              <div class="col-md-6 mt-3 figma_calender_input"></div>

              <div class="newProfile_border_bottom col-md-12 my-3"></div>

              <div class="col-md-6 figma__ng__select figma-input">
                <label class="mb-2 figma__label"> Passport </label>
                <input [(ngModel)]="nnewNewBasicInfoo.Passport" #newApplicantPassport="ngModel"
                  class="w-100 figma__Inputs" type="text" name="newApplicantPassport" placeholder="Enter passport"
                  pattern="^[A-Za-z0-9]+$"  />
                <!-- <div class="errorMessage" *ngIf="
                      profileBasicInfoForm.controls.newApplicantPassport
                        ?.errors?.pattern
                    ">
                  Please enter only characters and numbers
                </div> -->
                <!-- onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" -->
                <!-- <ng-select class="" [items]="NNationalityArrrayy" bindLabel="nationality_Name"
                  bindValue="nationality_Id" name="newProfileNationalityidd" #newProfileNationalityidd="ngModel"
                  placeholder="Select nationality id" [(ngModel)]="nnewNewBasicInfoo.newProfileNationalityyid">
                </ng-select> -->
              </div>
              <div class="col-md-6 d-flex">
                <div class="col-md-6 figma_calender_input figma-calender pl-0">
                  <label class="mb-2 figma__label"> Issue Date </label>
                  <div class="position-relative calender__ml">
                    <p-calendar (onSelect)="onDateSelect($event, 'visa')" class="" name="profileeIssueDaattee"
                      placeholder="Select date" [(ngModel)]="nnewNewBasicInfoo.profileeIssueDaDateee"
                      #profileeIssueDaattee="ngModel" [showIcon]="true"></p-calendar>
                  </div>
                </div>

                <div class="col-md-6 figma_calender_input figma-calender pr-0">
                  <label class="mb-2 figma__label"> Expiry Date </label>
                  <div class="position-relative calender__mll">
                    <p-calendar class="" name="profileeExpiryyDDatee" placeholder="Select date" [(ngModel)]="
                          nnewNewBasicInfoo.profileeExpiryyDaDateee
                        " #profileeExpiryyDDatee="ngModel" [showIcon]="true"></p-calendar>
                  </div>
                </div>
              </div>
              <div class="newProfile_border_bottom col-md-12 my-3"></div>

              <h1 class="fw600 fs18 figma_blue_color mt-1 mb-3 col-md-12">
                Current Address
              </h1>

              <div class="col-md-6 mt-3 figma-input">
                <label class="mb-2 figma__label"> Address line 1 </label>
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="nnewNewBasicInfoo.profileAddressLineOnee" name="profileAddressLineOne"
                  #profileAddressLineOne="ngModel" placeholder="Enter address line 1" />
              </div>
              <div class="col-md-6 mt-3 figma-input">
                <label class="mb-2 figma__label"> Address line 2 </label>
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="nnewNewBasicInfoo.profileAddressLineTTwoo" name="profileAddressLineeTTTwoo"
                  #profileAddressLineeTTTwoo="ngModel" placeholder="Enter address line 2" />
              </div>

              <div class="col-md-6 figma__ng__select mt-3 figma-ng-select">
                <label class="mb-2 figma__label"> Country</label>
                <ng-select  [(ngModel)]="nnewNewBasicInfoo.id" class="" [items]="Allcountry" bindLabel="countryName"
                  bindValue="countryId" name="newProfilenewProfileCoountry" #newProfilenewProfileCoountry="ngModel"
                  placeholder="Select country">
                  <!-- (change)="getCampusonCountry($event)" -->
                </ng-select>
              </div>

              <div class="col-md-6 figma__ng__select mt-3 figma-ng-select">
                <label class="mb-2 figma__label"> State </label>
                <ng-select class="" [items]="stateList" bindLabel="stateName" bindValue="stateId"
                  name="newProfilenewProfileiState" #newProfilenewProfileiState="ngModel" placeholder="Select state"
                  [(ngModel)]="nnewNewBasicInfoo.newProfileCStatee">
                </ng-select>
              </div>

              <div class="col-md-6 mt-3 figma-input">
                <label class="mb-2 figma__label"> Zipcode </label>
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="nnewNewBasicInfoo.profileAddressZipcode" name="profileAddressLineeZipcode"
                  #profileAddressLineeZipcode="ngModel" placeholder="Enter zipcode"
                  onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
              </div>

              <!-- <div class="newProfile_border_bottom col-md-12 my-3"></div> -->
              <h1 class="fw600 fs18 figma_blue_color mt-4 mb-2 col-md-12">
                Permanent Address
              </h1>
              <div class="d-flex my-3 col-md-12 align-items-center">
                <input type="checkbox" name="sameCurrentAddress" [(ngModel)]="newSameCurrentAddress"
                  style="accent-color: #764fdb" (change)="onclickCurrentAddress()" />
                <!--   <input type="checkbox" (change)="updatePermanentAddress()" [(ngModel)]="sameAsCurrentAddress"
                name="SameAddress" /> -->
                <label class="figma__label ml-1" style="margin-bottom: 0 !important">
                  Same as current address
                </label>
              </div>

              <div class="col-md-6 mt-3 figma-input">
                <label class="mb-2 figma__label"> Address line 1 </label>
                <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="
                      nnewNewBasicInfoo.permanentprofileAddressLineOnee
                    " name="permanentPprofileAddressLineOne" #permanentPprofileAddressLineOne="ngModel"
                  placeholder="Enter address line 1" [disabled]="sameAddressDisabled" [ngClass]="
                      !sameAddressDisabled ? 'firstClass' : 'MySecondClass'
                    " />
              </div>
              <div class="col-md-6 mt-3 figma-input">
                <label class="mb-2 figma__label"> Address line 2 </label>
                <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="
                      nnewNewBasicInfoo.permanentPprofileAddressLineTTwoo
                    " name="permanentPprofileAddressLineeTTTwoo" #permanentPprofileAddressLineeTTTwoo="ngModel"
                  placeholder="Enter address line 2" [disabled]="sameAddressDisabled" [ngClass]="
                      !sameAddressDisabled ? 'firstClass' : 'MySecondClass'
                    " />
              </div>

              <div class="col-md-6 figma__ng__select mt-3 figma-ng-select">
                <label class="mb-2 figma__label"> Country </label>
                <ng-select class=""  [items]="Allcountry" bindLabel="countryName"
                  bindValue="countryId" name="newProfilenewProfileCoountry" #newProfilenewProfileCoountry="ngModel"
                  placeholder="Select country" [(ngModel)]="
                      nnewNewBasicInfoo.id
                    " [ngClass]="
                      !sameAddressDisabled ? 'firstClass' : 'MySecondClass'
                    ">
                    <!-- (change)="getCampusonCountry($event)" -->
                </ng-select>
              </div>

              <div class="col-md-6 figma__ng__select mt-3 figma-ng-select">
                <label class="mb-2 figma__label"> State </label>
                <ng-select class="" [items]="stateList" bindLabel="stateName" bindValue="stateId"
                  name="newProfilenewProfileiState" #newProfilenewProfileiState="ngModel" placeholder="Select state"
                  [(ngModel)]="nnewNewBasicInfoo.PermanentnewProfileCStatee" [disabled]="sameAddressDisabled"
                  [ngClass]="
                      !sameAddressDisabled ? 'firstClass' : 'MySecondClass'
                    ">
                </ng-select>
              </div>

              <div class="col-md-6 mt-3 figma-input">
                <label class="mb-2 figma__label"> Zipcode </label>
                <input type="text" class="col-md-12 figma__Inputs figma-input h_42" [(ngModel)]="
                      nnewNewBasicInfoo.permanentPprofileAddressZipcode
                    " name="permanentPprofileAddressLineeZipcode" #permanentPprofileAddressLineeZipcode="ngModel"
                  placeholder="Enter zipcode" [ngClass]="
                      !sameAddressDisabled ? 'firstClass' : 'MySecondClass'
                    " onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
              </div>
              <!-- <div class="d-flex my-3 col-md-12">
                <input type="checkbox" name="sameCurrentAddress" class="" style="accent-color: #764fdb;" />
                <label class="figma__label ml-1" style="margin-bottom: 0 !important;"> Same as current address
                </label>
              </div> -->

              <div class="newProfile_border_bottom col-md-12 my-3"></div>

              <div class="col-md-6 figma__ng__select mt-3 figma-ng-select" *ngIf="countryNameforImmigration!=='Singapore'">
                <label class="mb-2 figma__label"> Race/Ethnic Group </label>
                <ng-select class="" [items]="ehicalarray" bindLabel="ethicName" bindValue="ethicId"
                  name="newProfileRaceee" #newProfileRaceee="ngModel" placeholder="Select an option"
                  [(ngModel)]="nnewNewBasicInfoo.newProfileRacee">
                </ng-select>
              </div>

              <div class="col-md-6 figma__ng__select mt-3 figma-ng-select" *ngIf="countryNameforImmigration!=='Singapore'">
                <label class="mb-2 figma__label"> Religion </label>
                <ng-select class="" [items]="regiionalarr" bindLabel="religionName" bindValue="religionId"
                  name="newProfileReligionnn" #newProfileReligionnn="ngModel" placeholder="Select religion"
                  [(ngModel)]="nnewNewBasicInfoo.newProfileReligionnnn">
                </ng-select>
              </div>

              <div class="col-md-6 figma__ng__select mt-3 figma-ng-select">
                <label class="mb-2 figma__label">
                  How did you got to know about us?
                </label>
                <ng-select class="" [items]="howdotodropdown" bindLabel="category" bindValue="category"
                  name="newProfileHowKnoww" #newProfileHowKnoww="ngModel" placeholder="Select an option"
                  [(ngModel)]="nnewNewBasicInfoo.newProfileHowKnowww">
                </ng-select>
              </div>
              <div class="col-md-12 mt-4">
                <button type="button" class="figma_background_blue_btn float-right blue-btn" style="padding: 12px 42px"
                  (click)="saveAndnextt('profileDetails')" [disabled]="!profileBasicInfoForm.form.valid || (!NewnewResumeFiles.length && !enableDoc) || supporteFilesType"
                  >
                  <!-- [disabled]="!profileBasicInfoForm.form.valid || (!NewnewResumeFiles.length && !enableDoc) || supporteFilesType" -->
                  <!-- [disabled]="!profileBasicInfoForm.form.valid" -->
                  Save & Next
                </button>
              </div>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="newEducationalInformationn">
          <form #newEducationalForm="ngForm">
            <h1 class="fs18 fw600">Educational Information</h1>
            <div class="row">
              <ng-container *ngFor="
                    let item of newNewEducationalInfoo.aanotherDegrees;
                    let i = index
                  ">
                <div class="col-md-6 figma__ng__select mt-3">
                  <label class="mb-2 figma__label">
                    Higher Level Of Education
                  </label>
                  <ng-select class="" [items]="Education_details" bindLabel="educationName" bindValue="educationId"
                    name="newProfileHiggherEducationn{{ i }}" #newProfileHiggherEducationn="ngModel"
                    placeholder="Select higher level of education" [(ngModel)]="item.newProfileHiggherEducationnnn">
                  </ng-select>
                </div>

                <div class="col-md-6 figma__ng__select mt-3">
                  <label class="mb-2 figma__label"> Qualification </label>
                  <ng-select class="" [items]="degreedropdown" bindLabel="degreeName" bindValue="degreeId"
                    name="newProfileHigherQualificationn{{ i }}" #newProfileHigherQualificationn="ngModel"
                    placeholder="Select qualification" [(ngModel)]="item.newProfileHigherQualificationnn">
                  </ng-select>
                </div>

                <div class="col-md-6 mt-3">
                  <label class="mb-2 figma__label">
                    College / University Name
                  </label>
                  <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="item.profileCollegeNammee"
                    name="profileCollegeNammeee{{ i }}" #profileCollegeNammeee="ngModel" placeholder="Enter name " />
                </div>

                <div class="col-md-6 mt-3 figma__ng__select">
                  <label class="mb-2 figma__label">
                    Year Of Graduation
                  </label>
                  <ng-select class="" [items]="countrycurrencylist" bindLabel="id" bindValue="id"
                    name="profileeYearGraduation{{ i }}" #profileeYearGraduation="ngModel"
                    placeholder="Select year of graduation" [(ngModel)]="item.profileeYearGraduationn">
                  </ng-select>

                  <!-- <div class="position-relative calender__ml">
                    <p-calendar class="" name="profileeYearGraduation{{i}}" placeholder="DD/MM/YY"
                      [(ngModel)]="item.profileeYearGraduationn" #profileeYearGraduation="ngModel"
                      [showIcon]="true"></p-calendar>
                  </div> -->
                </div>

                <div class="col-md-6 priority__Radios mobile_fields_gap priority_radios_mains"
                  style="margin-top: 20px">
                  <label class="form-label-d priority__label__heading new__input__label mb-2">
                    Type:
                  </label>
                  <div class="gs-grouped-fileds pt-0 d-flex">
                    <div class="ui radio checkbox priority_checks">
                      <input class="pointer" type="radio" name="NewProfileEduTypee{{ i }}" checked="true"
                        value="fulltime" #NewProfileEduTypee="ngModel" [(ngModel)]="item.NewProfileEduTypee" />
                      <!-- style="font-size: 12px;" -->
                      <label style="font-size: 12px" [ngStyle]="{
                            color:
                              newNewEducationalInfoo.NewProfileEduTypee ==
                              'profileFullTime'
                                ? 'black'
                                : 'gray'
                          }">Full Time</label>
                    </div>
                    <div class="ui radio checkbox priority_checks c1p">
                      <input class="pointer" type="radio" name="NewProfileEduTypee{{ i }}" value="PartTime"
                        #NewProfileEduTypee="ngModel" [(ngModel)]="item.NewProfileEduTypee" />
                      <label label style="font-size: 12px" [ngStyle]="{
                            color:
                              newNewEducationalInfoo.NewProfileEduTypee ==
                              'profilePartTime'
                                ? 'black'
                                : 'gray'
                          }">Part Time</label>
                    </div>
                  </div>
                </div>

                <div class="industry_plus_btn mt-5 col-md-12" style="margin-top: 33px !important">
                  <button role="button" class="figma_blue_button mr-3" *ngIf="
                        i ==
                          newNewEducationalInfoo.aanotherDegrees.length - 1
                      " (click)="newAddAnotherDegreeeFields(i)">
                    + Add another degree
                  </button>
                  <button role="button" class="discard_red_button mr-3" style="
                        color: rgba(254, 84, 84, 1) !important;
                        border: 1.5px solid #fe5454;
                      " (click)="newDeleteAnotherDegreeeFields(i)" *ngIf="
                        newNewEducationalInfoo.aanotherDegrees.length>1
                      ">
                    Remove
                  </button>
                </div>
              </ng-container>

              <ng-container *ngFor="
                    let x of newNewEducationalInfoo.addMoreSkills;
                    let i = index
                  ">
                <div class="col-md-6 figma__ng__select mt-3">
                  <label class="mb-2 figma__label"> Skills Category </label>
                  <ng-select class="" [items]="getSkills_Category" bindLabel="skill_CategoryName"
                    bindValue="skill_CategoryId" name="newProfileSkillsCategoryy{{ i }}"
                    #newProfileSkillsCategoryy="ngModel" (change)="SkillNameById($event, i)"
                    placeholder="Select skills category" [(ngModel)]="x.newAddAppSkillsCategoryyy">
                  </ng-select>
                </div>

                <div class="col-md-6 figma__ng__select mt-3">
                  <label class="mb-2 figma__label"> Skills </label>

                  <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.newAddAppSkillsss"
                    name="newAddAppSkillss{{i}}" #newAddAppSkillss="ngModel" placeholder="Enter skills" /> -->

                  <ng-select class="" [items]="x.skill_list" bindLabel="skillName" bindValue="skillCategoryId"
                    name="newAddAppSkillss{{ i }}" #newAddAppSkillss="ngModel" placeholder="Select skills"
                    [(ngModel)]="x.newAddAppSkillsss">
                  </ng-select>
                </div>

                <div class="industry_plus_btn mt-3 col-md-12">
                  <span role="button" class="add__Text mr-3" *ngIf="
                        i ==
                          newNewEducationalInfoo.addMoreSkills.length - 1 ||
                        newNewEducationalInfoo.addMoreSkills.length == 1
                      " (click)="newRemoveMoreSkills(i)">
                    + Add
                  </span>
                  <span role="button" class="add__Text remove__Text mr-3"
                    style="color: rgba(254, 84, 84, 1) !important" (click)="newRemoveMoreSkillsFields(i)" *ngIf="newNewEducationalInfoo.addMoreSkills.length>1
                      ">
                    Remove
                  </span>
                </div>
              </ng-container>

              <!-- <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Languages known  </label>
                <ng-select class="" [items]="newProfileHigherLanguages" bindLabel="name" bindValue="id"
                  name="newProfileHigherLanguagess" #newProfileHigherLanguagess="ngModel"
                  placeholder="Select languages" [multiple]="true"
                  [(ngModel)]="newNewEducationalInfoo.newProfileHigherLanguagesss">
                </ng-select>
              </div> -->
              <ng-container *ngFor="
                    let x of newNewEducationalInfoo.addMorelanguages;
                    let i = index
                  ">
                <div class="d-md-flex row col-lg-6 col-12 px-0 ml-1 mt-3">
                  <div class="col-lg-4 col-6 figma__ng__select">
                    <label class="mb-2 figma__label">
                      Languages known
                    </label>
                    <ng-select class="" [items]="languageList" bindLabel="languageName" bindValue="languageId"
                      name="newProfileHigherLanguagess{{ i }}" #newProfileHigherLanguagess="ngModel"
                      placeholder="Select languages" [multiple]="false" [(ngModel)]="x.newProfileHigherLanguagesss"
                      (change)="languageChange($event,x)">
                    </ng-select>
                  </div>
                  <div class="col-lg-4 col-6">
                    <label class="mb-2 figma__label">
                      Form of Language
                    </label>
                    <!-- [value]="Oral" -->
                    <input type="text" class="col-md-12 figma__Inputs h_42 no-cursor"
                      [(ngModel)]="x.profileFormLanguage" name="profileFormLanguagee{{ i }}"
                      #profileFormLanguagee="ngModel" readonly />
                  </div>
                  <div class="col-lg-4 col-6 figma__ng__select"  *ngIf="!nolanguage">
                    <label class="mb-2 figma__label"> Proficiency </label>
                    <ng-select class="" [items]="newProfilelangProfiiency" bindLabel="name" bindValue="name"
                      name="newProfilelangProfiiencyy{{ i }}" #newProfilelangProfiiencyy="ngModel"
                      placeholder="Select proficiency" [multiple]="false" [(ngModel)]="x.newProfilelangProfiiencyyy">
                    </ng-select>
                  </div>
                  <div class="col-lg-4 col-6"  *ngIf="nolanguage"></div>
                  <div class="col-lg-4 col-6 mt-3"></div>
                  <!--  -->
                  <div class="col-lg-4 col-6 mt-3">
                    <label class="mb-2 figma__label">
                      Form of Language
                    </label>
                    <input type="text" class="col-md-12 figma__Inputs h_42 no-cursor"
                      [(ngModel)]="x.profileFormLanguageTwo" name="profileFormLanguageTwoo{{ i }}"
                      #profileFormLanguageTwoo="ngModel" value="Oral" readonly />
                  </div>
                  <div class="col-lg-4 col-6 figma__ng__select mt-3" *ngIf="!nolanguage">
                    <label class="mb-2 figma__label"> Proficiency </label>
                    <ng-select class=""  [items]="newProfilelangProfiiency" bindLabel="name" bindValue="name"
                    [(ngModel)]="x.newProfilelangProfiiencyyyTwo"  name="newProfilelangProfiiencyy{{ i }}" #newProfilelangProfiiencyy="ngModel"
                      placeholder="Select proficiency" [multiple]="false"
                      >
                    </ng-select>
                  </div>
                  <div class="col-lg-4 col-6"  *ngIf="nolanguage"></div>
                  <!--  -->
                </div>

                <div class="industry_plus_btn mt-5 col-md-12" style="margin-top: 33px !important">
                  <span role="button" class="plus_add mr-3" *ngIf="
                        i ==
                          newNewEducationalInfoo.addMorelanguages.length -
                            1 ||
                        newNewEducationalInfoo.addMorelanguages.length == 1
                      " (click)="newAddMorelanguages(i)">
                    + Add More Languages
                  </span>
                  <span role="button" class="add__Text remove__Text mr-3"
                    style="color: rgba(254, 84, 84, 1) !important" (click)="newRemoveMoreLanguages(i)" *ngIf="
                        newNewEducationalInfoo.addMorelanguages.length>1
                      ">
                    Remove
                  </span>
                </div>
              </ng-container>

              <div class="col-md-12 mt-4">
                <button type="button" class="figma_blue_button" (click)="newBackApp('eduInfoBack')">
                  <i class="icon-angle-down angle-rotate-d"></i> Back
                </button>
                <button type="button" class="figma_background_blue_btn float-right" style="padding: 12px 42px"
                  (click)="saveAndnextt('Educationalinfoo')">
                  Save & Next
                </button>
              </div>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="newWorkExperiencee">
          <form #newworkExperienceForm="ngForm">
            <h1 class="col-md-6 fw600 fs18 pl-0">Work Experience</h1>
            <ng-container *ngFor="
                  let x of newNewWorkkInfoo.aanotherIndustries;
                  let i = index
                ">
              <div class="row">
                <div class="col-md-6 mt-3 figma__ng__select">
                  <label class="mb-2 figma__label"> Industry </label>
                  <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.profileIndustryyTwo"
                    name="profileIndustry{{i}}" #profileIndustry="ngModel" placeholder="Enter industry " /> -->

                  <ng-select class="" [items]="indusrtynames" bindLabel="industry_Name" bindValue="industry_Id"
                    name="newAppprofileIndustryy{{ i }}" #newAppprofileIndustryy="ngModel"
                    placeholder="Select industry" [(ngModel)]="x.newAppprofileIndustryyy">
                  </ng-select>
                </div>

                <div class="col-md-6 mt-3 figma__ng__select">
                  <label class="mb-2 figma__label">
                    Experience (in years)
                  </label>
                  <ng-select class="" [items]="newAppprofileIndustryExp" bindLabel="name" bindValue="id"
                    name="newAppprofileIndustryExpp{{ i }}" #newAppprofileIndustryExpp="ngModel"
                    placeholder="Select experience" [(ngModel)]="x.newAppprofileIndustryExppp">
                  </ng-select>
                </div>

                <div class="industry_plus_btn mt-2 col-md-12">
                  <span role="button" class="add__Text mr-3" *ngIf="
                        i == newNewWorkkInfoo.aanotherIndustries.length - 1 ||
                        newNewWorkkInfoo.aanotherIndustries.length == 1
                      " (click)="addNewExperienceFieldss(i)">
                    + Add
                  </span>
                  <span role="button" class="add__Text remove__Text mr-3"
                    style="color: rgba(254, 84, 84, 1) !important" (click)="DeleteNewExperienceFieldss(i)" *ngIf="
                        newNewWorkkInfoo.aanotherIndustries.length >1
                      ">
                    Remove
                  </span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngFor="
                  let x of newNewWorkkInfoo.aanotherDomainsss;
                  let i = index
                ">
              <div class="row">
                <div class="col-md-6 mt-3 figma__ng__select">
                  <label class="mb-2 figma__label"> Domain </label>
                  <ng-select class="" [items]="Domainnames" bindLabel="domain_Name" bindValue="domain_Id"
                    name="newAppprofileDomainn{{ i }}" #newAppprofileDomainn="ngModel" placeholder="Select domain"
                    [(ngModel)]="x.newAppprofileDomainnn">
                  </ng-select>
                  <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.profileDoamin"
                    name="profileDoaminnn{{i}}" #profileDoaminnn="ngModel" placeholder="Enter domain " /> -->
                </div>

                <div class="col-md-6 mt-3 figma__ng__select">
                  <label class="mb-2 figma__label">
                    Experience (in years)
                  </label>
                  <ng-select class="" [items]="newAppprofileIndustryyExp" bindLabel="name" bindValue="id"
                    name="newAppprofileIndustryyExpp{{ i }}" #newAppprofileIndustryyExpp="ngModel"
                    placeholder="Select experience" [(ngModel)]="x.newAppprofileIndustryyExppp">
                  </ng-select>
                  <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.profileDoaminExperience"
                    name="profileDoaminExperienceee{{i}}" #profileDoaminExperienceee="ngModel"
                    placeholder="Enter experience " /> -->
                </div>

                <div class="industry_plus_btn mt-2 col-md-12">
                  <span role="button" class="add__Text mr-3" *ngIf="
                        i == newNewWorkkInfoo.aanotherDomainsss.length - 1 ||
                        newNewWorkkInfoo.aanotherDomainsss.length == 1
                      " (click)="newAddNewDomainFieldsTwoo(i)">
                    + Add
                  </span>
                  <span role="button" class="add__Text remove__Text mr-3"
                    style="color: rgba(254, 84, 84, 1) !important" (click)="newDeleteNewDomainFieldsTwoo(i)" *ngIf="
                        newNewWorkkInfoo.aanotherDomainsss.length>1
                      ">
                    Remove
                  </span>
                </div>
              </div>
            </ng-container>
            <div class="d-md-flex">
              <div class="col-md-6 pl-0 figma__ng__select mt-3">
                <label class="mb-2 figma__label">
                  Total Experience (in years) <span class="errorRequired">*</span>
                </label>
                <ng-select class="" [items]="newProfileTotalExperience" bindLabel="name" bindValue="id"
                  name="newProfileTotalExperiencee" #newProfileTotalExperiencee="ngModel" (change)="totalExpChange($event)"
                  placeholder="Select total experience" [(ngModel)]="newNewWorkkInfoo.newProfileTotalExperienceee" required>
                </ng-select>
                <div *ngIf="(newProfileTotalExperiencee?.touched || newworkExperienceForm.submitted) && newProfileTotalExperiencee.errors?.['required']">
                    <span class="errorMessage">
                      Please select total experience
                    </span>
                  </div>
              </div>

              <div class="col-md-6 figma__ng__select mt-3 pr-0">
                <label class="mb-2 figma__label">
                  Notice Period (in days) <span class="errorRequired" *ngIf="!previousWorkHistoryDisabled">*</span>
                </label>

                <!-- <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="newNewWorkkInfoo.OrganizationName" name="OrganizationNameee{{i}}"
                  #OrganizationNameee="ngModel" placeholder="Enter notice period" /> -->

                <ng-select class="" [items]="newProfileNoticePeriod" bindLabel="name" bindValue="name"
                  name="newProfileNoticePeriodd" #newProfileNoticePeriodd="ngModel" placeholder="Select notice period"
                  [(ngModel)]="newNewWorkkInfoo.newProfileNoticePerioddd" [required]="!previousWorkHistoryDisabled">
                </ng-select>
                <div *ngIf="(newProfileNoticePeriodd?.touched || newworkExperienceForm.submitted) && newProfileNoticePeriodd.errors?.['required']">
                    <span class="errorMessage">
                      Please select notice period
                    </span>
                  </div>
              </div>
            </div>
            <div class="col-md-12 my-4 newProfile_border_bottom"></div>

            <h1 class="fw600 fs18 col-md-12 pl-0">Previous Work History</h1>
            <form #previousWorkHHistoryForm="ngForm"></form>
            <div class="row">
              <ng-container *ngFor="
                    let x of jahDataa.approverLists;
                    let i = index
                  ">
                <div class="col-md-6 mt-3">
                  <label class="mb-2 figma__label">
                    Organization Name <span class="errorRequired" *ngIf="!previousWorkHistoryDisabled">*</span>
                  </label>
                  <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.newJobOrganizationName"
                    name="newJobOrganizationNamee{{ i }}" #newJobOrganizationNamee="ngModel"
                    placeholder="Enter organization name" [required]="!previousWorkHistoryDisabled" />
                    <div *ngIf="
                      ((newJobOrganizationNamee?.touched || previousWorkHHistoryForm.submitted) && newJobOrganizationNamee.errors?.['required'])">
                     <span class="errorMessage">
                    Please enter organization name
                  </span>
                </div>
                </div>

                <div class="col-md-6 mt-3 figma__ng__select">
                  <label class="mb-2 figma__label"> Designation <span class="errorRequired" *ngIf="!previousWorkHistoryDisabled">*</span> </label>

                  <!-- <ng-select class="" [items]="Allcountry" bindLabel="name" bindValue="id"
                    name="profileDesignationnNeww{{i}}" #profileDesignationnNeww="ngModel" placeholder="Select designation"
                    [(ngModel)]="x.profileDesignationnNew">
                  </ng-select> -->

                  <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.profileDesignationnNew"
                    name="profileDesignationnNeww{{ i }}" #profileDesignationnNeww="ngModel"
                    placeholder="Enter designation" [required]="!previousWorkHistoryDisabled" />
                    <div *ngIf="
                    ((profileDesignationnNeww?.touched || previousWorkHHistoryForm.submitted) && profileDesignationnNeww.errors?.['required'])">
                   <span class="errorMessage">
                  Please enter designation
                </span>
              </div>
                </div>

                <div class="col-md-6 mt-3 figma__ng__select">
                  <label class="mb-2 figma__label"> Country <span class="errorRequired" *ngIf="!previousWorkHistoryDisabled">*</span> </label>
                  <ng-select class="" (change)="getCampusonCountry($event, x,i)" [items]="Allcountry" bindLabel="countryName"
                    bindValue="countryId" name="addAppnewCountryy{{ i }}" #addAppnewCountryy="ngModel"
                    placeholder="Select country" [(ngModel)]="x.addAppnewCountryyy" [required]="!previousWorkHistoryDisabled">
                  </ng-select>
                  <div *ngIf="
                  ((addAppnewCountryy?.touched || previousWorkHHistoryForm.submitted) && addAppnewCountryy.errors?.['required'])">
                 <span class="errorMessage">
                Please select country
              </span>
            </div>
                  <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.newJobprofileCountry"
                    name="newJobprofileCountryy{{i}}" #newJobprofileCountryy="ngModel" placeholder="Enter country" /> -->
                </div>

                <div class="col-md-6 mt-3 figma__ng__select">
                  <label class="mb-2 figma__label"> City <span class="errorRequired" *ngIf="!previousWorkHistoryDisabled">*</span> </label>

                  <ng-select class="" [items]="statecityList" bindLabel="cityName" bindValue="id"
                    name="newjobprofileCityy{{ i }}" #newjobprofileCityy="ngModel" placeholder="Select city"
                    [(ngModel)]="x.newjobprofileCity" [required]="!previousWorkHistoryDisabled">
                  </ng-select>
                  <div *ngIf="
                  ((newjobprofileCityy?.touched || previousWorkHHistoryForm.submitted) && newjobprofileCityy.errors?.['required'])">
                 <span class="errorMessage">
                Please select city
              </span>
            </div>

                  <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.newjobprofileCity"
                    name="newjobprofileCityy{{i}}" #newjobprofileCityy="ngModel" placeholder="Enter city " /> -->
                </div>

                <div class="col-md-6 figma__ng__select new__Phoe__part mt-3 new-mobile-code-curreny-inputs">
                  <label class="mb-2 figma__label">
                    Current Salary (Monthly)
                    <span *ngIf="!previousWorkHistoryDisabled" class="errorRequired">*</span>
                  </label>

                  <div class="d-flex align-items-center">
                    <ng-select style="width: 70px" class="" [items]="contrysymbol" bindLabel="currencycode"
                      bindValue="currencycode" name="newJobProfileSalaryItemss{{ i }}"
                      #newJobProfileSalaryItemss="ngModel" placeholder="INR"
                      [(ngModel)]="x.newJobProfileSalaryItemsss" [required]="!previousWorkHistoryDisabled">
                    </ng-select>
                    <input onkeypress="return /[0-9]/i.test(event.key)" [(ngModel)]="x.newJobProfileSalaryInput"
                      #newJobProfileSalaryInputt="ngModel"
                      oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" class="w-100 figma__Inputs"
                      type="text" name="newJobProfileSalaryInputt{{ i }}" placeholder="Enter current salary" [required]="!previousWorkHistoryDisabled" />
                  </div>
                  <div *ngIf="
                  ((newJobProfileSalaryInputt?.touched || previousWorkHHistoryForm.submitted) && newJobProfileSalaryInputt.errors?.['required'])">
                 <span class="errorMessage">
                Please enter salary
              </span>
            </div>
                </div>

                <div class="col-md-6 figma__ng__select new__Phoe__part mt-3 new-mobile-code-curreny-inputs">
                  <label class="mb-2 figma__label">
                    Total Variable (Per Annum)
                    <span *ngIf="!previousWorkHistoryDisabled" class="errorRequired">*</span>
                    <!-- *ngIf="!showHideSalary || !previousWorkHistoryDisabled" -->
                  </label>
                  <div class="d-flex">
                    <ng-select style="
                          width: 70px !important;
                          border-radius: 8px 0 0 8px !important;
                        " [items]="contrysymbol" bindLabel="currencycode" bindValue="currencycode"
                      name="newAppTotalVariablee{{ i }}" #newAppTotalVariablee="ngModel" placeholder="INR"
                      [(ngModel)]="x.newJobProfileSalaryItemsssVariable" [required]="!previousWorkHistoryDisabled">
                    </ng-select>
                    <input style="border-radius: 0 8px 8px 0 !important" onkeypress="return /[0-9]/i.test(event.key)"
                      [(ngModel)]="x.newJobTotalVariable" #newJobTotalVariablee="ngModel"
                      oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" class="w-100 figma__Inputs"
                      type="text" name="newJobTotalVariablee{{ i }}" placeholder="Enter total variable"
                       [required]="!previousWorkHistoryDisabled" />
                  </div>
                  <div *ngIf="
                  ((newJobTotalVariablee?.touched || previousWorkHHistoryForm.submitted) && newJobTotalVariablee.errors?.['required'])">
                 <span class="errorMessage">
                Please select salary
              </span>
            </div>
                </div>

                <div class="col-md-6 mt-3 figma_calender_input">
                  <div class="d-md-flex">
                    <div class="position-relative calender__ml col-md-6 pl-0">
                      <label class="mb-2 figma__label"> From <span class="errorRequired" *ngIf="!previousWorkHistoryDisabled">*</span> </label>
                      <p-calendar class="" name="newJobProfileDurationOnee{{ i }}" placeholder="DD/MM/YY"
                        [(ngModel)]="x.newJobProfileDurationOne" #newJobProfileDurationOnee="ngModel"
                        [showIcon]="true" [required]="!previousWorkHistoryDisabled"></p-calendar>
                        <div *ngIf="
                            ((newJobProfileDurationOnee?.touched || previousWorkHHistoryForm.submitted) && newJobProfileDurationOnee.errors?.['required'])">
                          <span class="errorMessage">
                          Please select date
                        </span>
                      </div>
                    </div>
                    <div class="position-relative calender__ml col-md-6 pr-0">
                      <label class="mb-2 figma__label"> To <span class="errorRequired" *ngIf="!previousWorkHistoryDisabled">*</span> </label>
                      <p-calendar class="" name="newJobProfileDurationTwoo{{ i }}" placeholder="DD/MM/YY"
                        [(ngModel)]="x.newJobProfileDurationTwo" #newJobProfileDurationTwoo="ngModel"
                        [showIcon]="true" [required]="!previousWorkHistoryDisabled"></p-calendar>
                        <div *ngIf="
                        ((newJobProfileDurationTwoo?.touched || previousWorkHHistoryForm.submitted) && newJobProfileDurationTwoo.errors?.['required'])">
                        <span class="errorMessage">
                          Please select date
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex mt-3 col-md-12 align-items-center">
                  <input class="accent-color" type="checkbox" [(ngModel)]="x.ThisISCurrentJobbb"
                    name="NewAppThisISCurrentJobbb{{ i }}" id="newid{{ i }}"  />
                    <!-- (change)="enableSalaryInputss($event)" -->
                  <label class="ml-1 figma__label" style="margin-bottom: 0px !important">
                    This is my current job
                  </label>
                </div>

                <div class="industry_plus_btn mt-5 col-md-12" style="margin-top: 33px !important">
                  <span role="button" class="myy-new-span-button mr-3" *ngIf="
                        i == jahDataa.approverLists.length - 1 ||
                        jahDataa.approverLists.length == 1
                      " (click)="newJobAddAnotherJob(i)">
                    + Add another job
                  </span>
                  <!-- add__Text remove__Text mr-3      color: rgba(254, 84, 84, 1) !important;
                        border: 1.5px solid #fe5454;-->
                  <span role="button"role="button" class="add__Text remove__Text mr-3"
                  style="color: rgba(254, 84, 84, 1) !important" (click)="deleteFieldss(i)" *ngIf="
                        jahDataa.approverLists.length>1
                      ">
                    Remove
                  </span>
                </div>
              </ng-container>
            </div>

            <h1 class="fw600 fs18 col-md-12 pl-0 mt-4">My References</h1>
            <div class="row mt-2">
              <ng-container *ngFor="let x of jahDataa.referreceLists;let i = index">
              <div class="col-md-6 mt-3">
                <label class="mb-2 figma__label"> Organization Name </label>
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="x.profileOrganizationname" name="profileOrganizationnamee{{i}}"
                  #profileOrganizationnamee="ngModel" placeholder="Enter organization name " />
              </div>

              <div class="col-md-6 mt-3">
                <label class="mb-2 figma__label"> Referee Name </label>
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="x.profileRefereennName" name="profileRefereennNameee{{i}}"
                  #profileRefereennNameee="ngModel" placeholder="Enter referee name " />
              </div>

              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Referee Position </label>
                <!-- <ng-select class="" [items]="newProfileRefereePosition" bindLabel="name" bindValue="id"
                  name="newProfileRefereePositionn" #newProfileRefereePositionn="ngModel"
                  placeholder="Select position" [(ngModel)]="newNewWorkkInfoo.newProfileRefereePositionnn">
                </ng-select> -->
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="x.newProfileRefereePositionnn" bindLabel="name" bindValue="id"
                  name="newProfileRefereePositionn{{i}}" #newProfileRefereePositionn="ngModel"
                  #profileRefereennNameee="ngModel" placeholder="Enter Referee Position " />
              </div>

              <div class="col-md-6 mt-3">
                <label class="mb-2 figma__label"> Email Address </label>
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="x.newProfileEmailAddress" name="newProfileEmailAddresss{{i}}"
                  #newProfileEmailAddresss="ngModel" placeholder="Enter email address" />
              </div>

              <div class="col-md-6 new__Phoe__parttttttt figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Phone No </label>
                <div class="d-flex align-items-center">
                  <ng-select style="width: 70px" class="" [items]="Allcountry"
                    [(ngModel)]="x.countrycode" bindLabel="countryCode" bindValue="countryCode"
                    name="NewAppCodeTwoo{{i}}" #NewAppCodeTwoo="ngModel" placeholder="+91"
                    (change)="disabledNumberInput('one')">
                  </ng-select>
                  <input onkeypress="return /[0-9]/i.test(event.key)" [(ngModel)]="x.RefPhoneNumber"
                    #RefPhoneNumberr="ngModel" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                    class="w-100 figma__Inputs" type="text" name="RefPhoneNumberr{{i}}" placeholder="Enter phone number"
                    [disabled]="phoneNumberdisabledByCode" style="border-left: 1px solid rgb(0, 0, 0, 0.3) !important;
                    border-radius: 0 8px 8px 0 !important; height:42px !important" />
                </div>
              </div>

              <div class="industry_plus_btn mt-5 col-md-12" style="margin-top: 33px !important">
                <span role="button" class="myy-new-span-button mr-3" *ngIf="
                      i == jahDataa.referreceLists.length - 1 ||
                      jahDataa.referreceLists.length == 1
                    " (click)="addNewReference(i)">
                  + Add another job
                </span>
                <!-- add__Text remove__Text mr-3      color: rgba(254, 84, 84, 1) !important;
                      border: 1.5px solid #fe5454;-->
                <span role="button"role="button" class="add__Text remove__Text mr-3"
                style="color: rgba(254, 84, 84, 1) !important" (click)="deleteNewReference(i)" *ngIf="
                      jahDataa.referreceLists.length>1
                    ">
                  Remove
                </span>
              </div>


            </ng-container>

              <div class="col-md-12 mt-4">
                <button type="button" class="figma_blue_button" (click)="newBackApp('workInfoBack')">
                  <i class="icon-angle-down angle-rotate-d"></i> Back
                </button>
                <button type="button" class="figma_background_blue_btn float-right" style="padding: 12px 42px"
                  (click)="saveAndnextt('workInformation')">
                  Save & Next
                </button>
              </div>

            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="familyInformationScreenn">
          <form #familyInfoForm="ngForm">
            <div class="row">
              <h1 class="fw600 fs18 col-md-12">Family Information</h1>
              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Martial Status </label>
                <ng-select class="" [items]="newProfileMartialStatus" bindLabel="name" bindValue="id"
                  name="newProfileMartialStatuss" #newProfileMartialStatuss="ngModel"
                  placeholder="Select martial status" [(ngModel)]="newNewFamilyyInfoo.newProfileMartialStatusss">
                </ng-select>
              </div>
              <div class="col-md-6"></div>

              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Name </label>
                <input [(ngModel)]="newNewFamilyyInfoo.newProfileFamilyName" #newProfileFamilyNameee="ngModel"
                  class="w-100 figma__Inputs" type="text" name="newProfileFamilyNameee" placeholder="Enter name" />
              </div>

              <div class="col-md-6 new__Phoe__part mt-3">
                <label class="mb-2 figma__label"> Phone No </label>
                <div class="d-flex align-items-center">
                  <ng-select style="width: 70px" class="" [items]="newProfilephoneCode" bindLabel="name"
                    bindValue="id" name="newProfilephoneCodee" #newProfilephoneCodee="ngModel" placeholder="+91"
                    [(ngModel)]="newNewFamilyyInfoo.newProfilephoneCodeee">
                  </ng-select>
                  <input onkeypress="return /[0-9]/i.test(event.key)"
                    [(ngModel)]="newNewFamilyyInfoo.newProfilephoneNuuumber" #newProfilephoneNuuumberr="ngModel"
                    oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" class="w-100 figma__Inputs"
                    type="text" name="newProfilephoneNuuumberr" placeholder="Enter phone number" />
                </div>
              </div>

              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Email ID </label>
                <input [(ngModel)]="newNewFamilyyInfoo.newProfileFamilyEmailId" #newProfileFamilyEmailIdd="ngModel"
                  class="w-100 figma__Inputs" type="text" name="newProfileFamilyEmailIdd"
                  placeholder="Enter email id" />
              </div>

              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Nationality </label>
                <input [(ngModel)]="
                      newNewFamilyyInfoo.newProfileFamilyNationality
                    " #newProfileFamilyNationalityy="ngModel" class="w-100 figma__Inputs" type="text"
                  name="newProfileFamilyNationalityy" placeholder="Enter nationality" />
              </div>

              <div class="col-md-6 mt-3 figma_calender_input">
                <label class="mb-2 figma__label">
                  Duration (in years)
                </label>
                <div class="position-relative calender__ml">
                  <p-calendar class="" name="profileeDurationThree" placeholder="DD/MM/YY"
                    [(ngModel)]="newNewFamilyyInfoo.profileeDurationThreee" #profileeDurationThreee="ngModel"
                    [showIcon]="true"></p-calendar>
                </div>
              </div>

              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Country </label>
                <ng-select class="" [items]="newProfileFamilyCountry" bindLabel="name" bindValue="id"

                  name="newProfileFamilyCountryy" #newProfileFamilyCountryy="ngModel" placeholder="Select country"
                  [(ngModel)]="newNewFamilyyInfoo.newProfileFamilyCountryyy">
                </ng-select>
              </div>

              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Passport Number </label>
                <input [(ngModel)]="
                      newNewFamilyyInfoo.newProfileFamilyPassportNumber
                    " #newProfileFamilyPassportNumberr="ngModel" class="w-100 figma__Inputs" type="text"
                  name="newProfileFamilyPassportNumberr" placeholder="Enter passport number" />
              </div>

              <div class="col-md-12 newProfile_border_bottom my-3"></div>

              <!--  -->
              <h1 class="fw600 fs18 figma_blue_color mt-1 mb-3 col-md-12">
                Current Address
              </h1>

              <div class="col-md-6 mt-3">
                <label class="mb-2 figma__label"> Address line 1 </label>
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="newNewFamilyyInfoo.profileAddressLineOonee" name="profileAddressLineOone"
                  #profileAddressLineOone="ngModel" placeholder="Enter address line 1" />
              </div>
              <div class="col-md-6 mt-3">
                <label class="mb-2 figma__label"> Address line 2 </label>
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="newNewFamilyyInfoo.profileAddressLineTTTwoo" name="profileAddressLineeTTTwWoo"
                  #profileAddressLineeTTTwWoo="ngModel" placeholder="Enter address line 2" />
              </div>

              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Country </label>
                <ng-select class="" [items]="newProfileiCountryyTwo" bindLabel="name" bindValue="id"
                  name="newProfileiCountryyTwoo" #newProfileiCountryyTwoo="ngModel" placeholder="Select country"
                  [(ngModel)]="newNewFamilyyInfoo.newProfileiCountryyTwooo">
                </ng-select>
              </div>

              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> State </label>
                <ng-select class="" [items]="newProfileStateTwo" bindLabel="name" bindValue="id"
                  name="newProfileStateTwoo" #newProfileStateTwoo="ngModel" placeholder="Select state"
                  [(ngModel)]="newNewFamilyyInfoo.newProfileStateTwooo">
                </ng-select>
              </div>

              <div class="col-md-6 mt-3">
                <label class="mb-2 figma__label"> Zipcode </label>
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="newNewFamilyyInfoo.profileAddressZipcodeTwo" name="profileAddressZipcodeTwoo"
                  #profileAddressZipcodeTwoo="ngModel" placeholder="Enter zipcode"
                  onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
              </div>

              <div class="newProfile_border_bottom col-md-12 my-3"></div>

              <h1 class="fw600 fs18 figma_blue_color mt-1 mb-3 col-md-12">
                Permanent Address
              </h1>

              <div class="col-md-6 mt-3">
                <label class="mb-2 figma__label"> Address line 1 </label>
                <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="
                      newNewFamilyyInfoo.permanentprofileAddressLineThree
                    " name="permanentprofileAddressLineThreee" #permanentprofileAddressLineThreee="ngModel"
                  placeholder="Enter address line 1" />
              </div>
              <div class="col-md-6 mt-3">
                <label class="mb-2 figma__label"> Address line 2 </label>
                <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="
                      newNewFamilyyInfoo.permanentPprofileAddressLineTTHRee
                    " name="permanentPprofileAddressLineTTHReee" #permanentPprofileAddressLineTTHReee="ngModel"
                  placeholder="Enter address line 2" />
              </div>

              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> Country </label>
                <ng-select class="" [items]="newProfileCountryyTHree" bindLabel="name" bindValue="id"
                  name="newProfileCountryyTHreee" #newProfileCountryyTHreee="ngModel" placeholder="Select country"
                  [(ngModel)]="newNewFamilyyInfoo.newProfileCountryyTHreeee">
                </ng-select>
              </div>

              <div class="col-md-6 figma__ng__select mt-3">
                <label class="mb-2 figma__label"> State </label>
                <ng-select class="" [items]="newProfileStateThree" bindLabel="name" bindValue="id"
                  name="newProfileStateThreee" #newProfileStateThreee="ngModel" placeholder="Select state"
                  [(ngModel)]="newNewFamilyyInfoo.newProfileStateThreeee">
                </ng-select>
              </div>

              <div class="col-md-6 mt-3">
                <label class="mb-2 figma__label"> Zipcode </label>
                <input type="text" class="col-md-12 figma__Inputs h_42"
                  [(ngModel)]="newNewFamilyyInfoo.newProfileZipcodeTwoo" name="newProfileZipcodeTwooo"
                  #newProfileZipcodeTwooo="ngModel" placeholder="Enter zipcode"
                  onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
              </div>
              <div class="d-flex my-3 col-md-12 align-items-center">
                <input type="checkbox" name="sameCurrentAddressTwo" [(ngModel)]="sameCurrentAddressTwooo"
                  class="accent-color" />
                <label class="mb-2 figma__label ml-1">
                  Same as current address
                </label>
              </div>
              <!--  -->

              <div class="col-md-12">
                <button type="button" class="figma_background_blue_btn mt-3 float-right" style="padding: 12px 42px"
                  (click)="saveAndnextt('familyInformtion')">
                  Save & Next
                </button>
              </div>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="immigrationScreenn">
          <form #immigrationScreenForm="ngForm">
            <div class="row">
              <h1 class="fw600 fs18 col-md-12">Immigration</h1>
              <div class="col-md-6 priority__Radios mobile_fields_gap priority_radios_mains" style="margin-top: 20px">
<!--
                <label class="form-label-d priority__label__heading new__input__label mb-2" *ngIf="workTypenew[0]?.countryName=='!Singapore'">Do you have a permit to
                  work in {{workTypenew[0]?.countryName}}? <span class="errorRequired">*</span> </label> -->

                  <p class="primeblue fnt18 fw600" *ngIf="workTypenew[0]?.countryName=='Singapore'">Sponsorship status</p>



                  <label *ngIf="workTypenew[0]?.countryName!='Singapore'" class="fw500 neugray " for="Do you have relevant visa to work in the Singapore "
                                class="form-label fw500 neugray"> Do you have a permit to
                                work in {{workTypenew[0]?.countryName}} ? <span class="errorRequired">*</span>
                            </label>
                            <label *ngIf="workTypenew[0]?.countryName=='Singapore'" class="fw500 neugray " for="Do you have relevant visa to work in the Singapore "
                            class="form-label fw500 neugray">

                            Will you now or in the future require sponsorship to work in Singapore?<span style="color:red">*</span>

                        </label>

                <!-- <div class="gs-grouped-fileds pt-0 d-flex">
                  <div class="ui radio checkbocolorx priority_checks">
                    <input class="pointer" type="radio" name="NewProfileWorkPermit" value="profileYes"
                      #NewProfileWorkPermit="ngModel" [(ngModel)]="newImmigrationInfoo.NewProfileWorkPermit"
                      (click)="showNewAppWorkPermit()" />
                    <label style="font-size: 12px"
                      [ngStyle]="{color:newImmigrationInfoo.NewProfileWorkPermit == 'profileYes'? 'black': 'gray'}">Yes</label>
                  </div>
                  <div class="ui radio checkbox priority_checks c1p">
                    <input class="pointer" type="radio" name="NewProfileWorkPermit" value="profileNo"
                      #NewProfileWorkPermit="ngModel" [(ngModel)]="newImmigrationInfoo.NewProfileWorkPermit"
                      (click)="noShowNewAppWorkPermit()" [checked]="true" />
                    <label label style="font-size: 12px"
                      [ngStyle]="{color:newImmigrationInfoo.NewProfileWorkPermit == 'profileNo'? 'black': 'gray'}">No</label>
                  </div>
                </div> -->
                <div class="d-flex align-items-center">
                  <div class="d-flex align-items-center">
                    <input class="accent-color" type="radio" name="NewProfileWorkPermit" value="Yes"
                      (click)="showNewAppWorkPermit($event)" [(ngModel)]="newImmigrationInfoo.NewProfileWorkPermit"
                      name="immigYesNo" #immigYesNo="ngModel" required />

                    <label class="fs12 fw600 ml-1"> Yes </label>
                  </div>
                  <div class="d-flex align-items-center ml-4">
                    <input class="accent-color" type="radio" value="No" name="NewProfileWorkPermit"
                      (click)="noShowNewAppWorkPermit($event)" name="immigYesNo" #immigYesNo="ngModel"
                      [(ngModel)]="newImmigrationInfoo.NewProfileWorkPermit" required />
                    <!-- [(ngModel)]="newImmigrationInfoo.NewProfileWorkPermit" -->
                    <label class="fs12 fw600 ml-1"> No </label>
                  </div>
                </div>
                <div *ngIf="
                      ((immigYesNo?.touched ||
                        immigrationScreenForm.submitted) &&
                        immigYesNo.errors?.['required']) ||
                      immigvalidation
                    ">
                  <span class="errorMessage">
                    Please select an option
                  </span>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6 figma__ng__select mt-4" *ngIf="NewAppWorkPermit">
                <label class="mb-2 figma__label"> Work Permit Type </label>
                <ng-select class="" [items]="workTypenew" bindLabel="workPermitTypeName" bindValue="workPermitTypeId"
                (change)="Changevalue($event)"
                  name="newNewProfileWorkPermitTypee" #newNewProfileWorkPermitTypee="ngModel"
                  placeholder="Select work permit type" [(ngModel)]="newImmigrationInfoo.newNewProfileWorkPermitTypeee" required>
                </ng-select>
              </div>

              <div class="col-md-6 mt-4" *ngIf="NewAppWorkPermit"></div>

              <!-- <div class="col-md-6 new__Phoe__part mt-3" *ngIf="NewAppWorkPermit">
                <label class="mb-2 figma__label"> Passport Number </label>
                <input onkeypress="return /[0-9]/i.test(event.key)"
                  [(ngModel)]="newImmigrationInfoo.newProfilePassportNumberTwooo"
                  #newProfilePassportNumberTwoo="ngModel"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" class="w-100 figma__Inputs"
                  type="text" name="newProfilePassportNumberTwoo" placeholder="Enter passport number" />
              </div> -->

              <!-- <div class="col-md-6 figma__ng__select mt-3" *ngIf="NewAppWorkPermit">
                <label class="mb-2 figma__label"> Document  </label>
                <ng-select class="" [items]="newProfileWorkPermitDocument" bindLabel="name" bindValue="id"
                  name="newProfileWorkPermitDocumentt" #newProfileWorkPermitDocumentt="ngModel"
                  placeholder="Select document" [(ngModel)]="newImmigrationInfoo.newProfileWorkPermitDocumenttt">
                </ng-select>
              </div> -->

              <div class="col-md-6 new__Phoe__part mt-3" *ngIf="NewAppWorkPermit">
                <label class="mb-2 figma__label"> Id Number </label>
                <input [(ngModel)]="newImmigrationInfoo.newProfileDocIdNumberrr" #newProfileDocIdNumberr="ngModel"
                [disabled]="NewAppWorkPermitnew"
                  class="w-100 figma__Inputs" type="text" name="newProfileDocIdNumberr"
                  placeholder="Enter id Number" required />
                <!--onkeypress="return /[0-9]/i.test(event.key)" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" -->
              </div>

              <div class="col-md-6 mt-3 immigration__Screenn_calender" *ngIf="NewAppWorkPermit">
                <div class="d-md-flex">
                  <div class="position-relative calender__ml col-md-6 pl-0 figma_calender_input">
                    <label class="mb-2 figma__label w-100"> Date of Issue </label>
                    <p-calendar  [disabled]="NewAppWorkPermitnew" class="" name="profileeDateOfIssue" placeholder="DD/MM/YY" [(ngModel)]="
                          newImmigrationInfoo.profileeDateOfIssueee
                        " #profileeDateOfIssue="ngModel" [showIcon]="true" [required]="true"></p-calendar>
                  </div>
                  <div class="position-relative calender__ml col-md-6 pr-0 figma_calender_input">
                    <label class="mb-2 figma__label w-100">
                      Date of Expiry
                    </label>
                    <p-calendar  [disabled]="NewAppWorkPermitnew" class="" name="profileeDateOfIssueeeTwoooo" placeholder="DD/MM/YY" [(ngModel)]="
                          newImmigrationInfoo.profileeDateOfIssueeeTwoo
                        " #profileeDateOfIssueeeTwoooo="ngModel" [showIcon]="true" [required]="true"></p-calendar>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6 new__Phoe__part mt-3" *ngIf="NewAppWorkPermit">
                <label class="mb-2 figma__label"> Visa Number </label>
                <input onkeypress="return /[0-9]/i.test(event.key)"
                  [(ngModel)]="newImmigrationInfoo.newProfileVisaNumberTwooo" #newProfileVisaNumberTwoooo="ngModel"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" class="w-100 figma__Inputs"
                  type="text" name="newProfileVisaNumberTwoooo" placeholder="Enter visa number" />
              </div>

              <div class="col-md-6 mt-3 figma_calender_input" *ngIf="NewAppWorkPermit">
                <label class="mb-2 figma__label"> Date of Issue & Expiry </label>
                <div class="d-md-flex">
                  <div class="position-relative calender__ml col-md-6 pl-0">
                    <p-calendar class="" name="profileeDateOfIssue" placeholder="DD/MM/YY"
                      [(ngModel)]="newImmigrationInfoo.profileeDateOfIssueee" #profileeDateOfIssue="ngModel"
                      [showIcon]="true"></p-calendar>
                  </div>
                  <div class="position-relative calender__ml col-md-6 pr-0">
                    <p-calendar class="" name="profileeDateOfIssueeeTwoooo" placeholder="DD/MM/YY"
                      [(ngModel)]="newImmigrationInfoo.profileeDateOfIssueeeTwoo"
                      #profileeDateOfIssueeeTwoooo="ngModel" [showIcon]="true"></p-calendar>
                  </div>
                </div>
              </div> -->

              <div class="col-md-12 mt-4">
                <button type="button" class="figma_blue_button" (click)="newBackApp('ImmigrationBack')">
                  <i class="icon-angle-down angle-rotate-d"></i> Back
                </button>
                <button type="button" class="figma_background_blue_btn float-right" style="padding: 12px 42px"
                  (click)="saveAndnextt('Immigratioinn')">
                  Save & Next
                </button>
              </div>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="DeclarationScreenn">
          <form #newDeclerationFormm="ngForm">
            <div class="">
              <h1 class="fw600 fs18 col-md-12">Declaration</h1>
              <div class="col-md-6 priority__Radios mobile_fields_gap priority_radios_mains" style="margin-top: 20px">
                <label class="form-label-d priority__label__heading new__input__label mb-2">Have you ever been
                  convicted in a court of law of any
                  country?</label>
                <div class="gs-grouped-fileds pt-0 d-flex">
                  <div class="ui radio checkbox priority_checks">
                    <input class="pointer" type="radio" name="NewProfileBeenConvicted" value="profileYes"
                      #NewProfileBeenConvicted="ngModel" [(ngModel)]="
                          newDeclarationInfoo.NewProfileBeenConvicted
                        " />
                    <label style="font-size: 12px" [ngStyle]="{
                          color:
                            newDeclarationInfoo.NewProfileBeenConvicted ==
                            'profileYes'
                              ? 'black'
                              : 'gray'
                        }">Yes</label>
                  </div>
                  <div class="ui radio checkbox priority_checks c1p">
                    <input class="pointer" type="radio" name="NewProfileBeenConvicted" value="profileNo" checked
                      #NewProfileBeenConvicted="ngModel" [(ngModel)]="
                          newDeclarationInfoo.NewProfileBeenConvicted
                        " />
                    <label label style="font-size: 12px" [ngStyle]="{
                          color:
                            newDeclarationInfoo.NewProfileBeenConvicted ==
                            'profileNo'
                              ? 'black'
                              : 'gray'
                        }">No</label>
                  </div>
                </div>
              </div>

              <div class="col-md-6 priority__Radios mobile_fields_gap priority_radios_mains" style="margin-top: 20px">
                <label class="form-label-d priority__label__heading new__input__label mb-2">
                  Have you ever been dismissed, discharged or suspended from
                  employment?
                </label>
                <div class="gs-grouped-fileds pt-0 d-flex">
                  <div class="ui radio checkbox priority_checks">
                    <input class="pointer" type="radio" name="NewProfileDischargedd" checked="true" value="profileYes"
                      #NewProfileDischargedd="ngModel" [(ngModel)]="
                          newDeclarationInfoo.NewProfileDischargedd
                        " />
                    <label style="font-size: 12px" [ngStyle]="{
                          color:
                            newDeclarationInfoo.NewProfileDischargedd ==
                            'profileYes'
                              ? 'black'
                              : 'gray'
                        }">Yes</label>
                  </div>
                  <div class="ui radio checkbox priority_checks c1p">
                    <input class="pointer" type="radio" name="NewProfileDischargedd" value="profileNo"
                      #NewProfileDischargedd="ngModel" [(ngModel)]="
                          newDeclarationInfoo.NewProfileDischargedd
                        " />
                    <label label style="font-size: 12px" [ngStyle]="{
                          color:
                            newDeclarationInfoo.NewProfileDischargedd ==
                            'profileNo'
                              ? 'black'
                              : 'gray'
                        }">No</label>
                  </div>
                </div>
              </div>

              <div class="col-md-6 priority__Radios mobile_fields_gap priority_radios_mains" style="margin-top: 20px">
                <label class="form-label-d priority__label__heading new__input__label mb-2">
                  Do you suffer from any of the following?
                </label>
                <div class="gs-grouped-fileds pt-0 d-flex">
                  <div class="ui radio checkbox priority_checks">
                    <input class="pointer" type="radio" name="NewProfileSufferingg" checked="true" value="profileYes"
                      #NewProfileSufferingg="ngModel" [(ngModel)]="newDeclarationInfoo.NewProfileSufferingg" />
                    <label style="font-size: 12px" [ngStyle]="{
                          color:
                            newDeclarationInfoo.NewProfileSufferingg ==
                            'profileYes'
                              ? 'black'
                              : 'gray'
                        }">Yes</label>
                  </div>
                  <div class="ui radio checkbox priority_checks c1p">
                    <input class="pointer" type="radio" name="NewProfileSufferingg" value="profileNo"
                      #NewProfileSufferingg="ngModel" [(ngModel)]="newDeclarationInfoo.NewProfileSufferingg" />
                    <label label style="font-size: 12px" [ngStyle]="{
                          color:
                            newDeclarationInfoo.NewProfileSufferingg ==
                            'profileNo'
                              ? 'black'
                              : 'gray'
                        }">No</label>
                  </div>
                </div>
              </div>

              <div class="d-flex my-3 col-md-12 align-items-center">
                <input type="checkbox" name="sameCurrentAddress" class="" style="accent-color: #764fdb" />
                <label class="mb-2 figma__label ml-1">I acknowledge and agree to GII’S document retention and
                  privacy terms and conditions
                </label>
              </div>

              <div class="col-md-12">
                <button type="button" class="figma_background_blue_btn mt-3 float-right" style="padding: 12px 42px"
                  (click)="saveAndnextt('Declarationn')">
                  Save & Next
                </button>
              </div>
            </div>
          </form>
        </ng-container>

        <ng-container *ngIf="documentsScreenn">
          <form #documentsForm="ngForm">
            <h1 class="fw600 fs18 col-md-12" style="padding:0px">Documents</h1>

            <!-- <button class="figma_background_blue_btn mt-3" type="button"
              (click)="openDocumentsSelects(); getDocumnetType($event)"  style="padding:16px 32px" [hidden]="documentEnablee && documentRender.length !== 0">
              Add Document
            </button> -->

            <!-- <div class="row"> -->
            <!-- <div *ngFor="let x of documentRender; let i = index">
              <div class="documentEnable row mt-4"> -->
            <!-- *ngIf="documentEnablee" -->
            <!-- <div class="col-md-4 figma__ng__select">
                  <label for="selectDocTypee" class="document__type_css figma__label">Document Type: <span
                      class="errorRequired">*</span></label>
                  <ng-select class="" [items]="selectDocTypeArray" bindLabel="name" bindValue="id"
                    placeholder="Select document type" [(ngModel)]="x.selectDocType" name="selectDocTypee{{i}}"
                    #selectDocTypee="ngModel" (change)="docDocumentType($event)" required>
                  </ng-select>
                  <div *ngIf="
                        (selectDocTypee.touched || documentsForm.submitted) &&
                        selectDocTypee.errors?.required
                      ">
                    <span class="errorMessage">
                      Please select document type
                    </span>
                  </div>
                </div> -->
            <!-- <div class="col-md-4 figma__ng__select">
                  <label for="selectDocTypee" class="document__type_css figma__label">Document Name: <span
                      class="errorRequired">*</span></label>
                  <ng-select class="" [items]="selectDocNameArray" bindLabel="name" bindValue="id"
                    placeholder="Select document name" [(ngModel)]="x.selectDocName" name="selectDocNamee{{i}}"
                    #selectDocNamee="ngModel" required>
                  </ng-select>
                  <div *ngIf="
                        (selectDocNamee.touched || documentsForm.submitted) &&
                        selectDocNamee.errors?.required
                      ">
                    <span class="errorMessage">
                      Please select document type
                    </span>
                  </div>
                </div>
                <div class="col-md-4 delete_can">
                  <img src="./assets/img/trashcan.svg" (click)="deleteUploadDoc(i)"
                    *ngIf="documentRender.length > 1" />
                </div>
                <div class="col-md-12 mt-4">

                  <ngx-dropzone (change)="onselect1($event,x.selectDocName,i,x.selectDocType)">
                    <ngx-dropzone-label>
                      <div class="d-flex align-items-center">
                        <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                        <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
                      </div>
                      <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
                      <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngIf="newNewVisaFiles[i]?.documentText" [removable]="true"
                      (removed)="onRemoveNewApplicant(f)">
                      <ngx-dropzone-label>{{ postMultimediasone[0]?.name }}</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                  </ngx-dropzone> -->
            <!-- <ngx-dropzone (change)="onselect1($event,x.selectDocName,i,x.selectDocType)">
                    <ngx-dropzone-label>
                      <div class="d-flex align-items-center">
                        <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                        <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
                      </div>
                      <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
                      <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
                    </ngx-dropzone-label>
                    <ngx-dropzone-preview *ngIf="newNewVisaFiles[i]?.name"  [removable]="true" (removed)="onRemove(f)">
                      <ngx-dropzone-label>{{ newNewVisaFiles[i]?.name }}</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                  </ngx-dropzone> -->
            <!-- </div>
                <button class="figma_background_blue_btn mt-3" type="button" (click)="addComponents()"
                  style="padding:16px 32px" *ngIf="(i == documentRender.length - 1)">
                  Add another document
                </button>
              </div>
            </div> -->


            <div class="col-md-12 mt-4" >
              <label class="figma__label ml-1 d-flex justify-content-center mb-3"
                style="margin-bottom: 14px !important;">
                <span class="fs16 fs600 mr-2"> Document Name : </span> Visa <span class="mandiatory">*</span>
              </label>
              <ngx-dropzone (change)="onselect1($event,'Visa','','')"  [accept]="'application/pdf'">
                <!-- [accept]="'application/pdf'" -->
                <ngx-dropzone-label>
                  <div class="d-flex align-items-center">
                    <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                    <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
                  </div>
                  <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
                  <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
                </ngx-dropzone-label>

                <!--   <ngx-dropzone-label class="d-flex ml-4">
                     <img *ngIf="fileTypee == 'application/pdf'" src="./assets/img/red-pdf.svg" alt="red pdf" />

                       <svg *ngIf="fileTypee == 'image/png' || fileTypee == 'image/jpeg' || fileTypee == 'image/svg' || fileTypee == 'image/jpg' "
                       width="30" height="30" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
                      </svg>

                      <svg *ngIf="fileTypee == 'application/msword'" width="30" height="30" fill="#764fdb" class="bi bi-file-earmark-word-fill" viewBox="0 0 16 16">
                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M5.485 6.879l1.036 4.144.997-3.655a.5.5 0 0 1 .964 0l.997 3.655 1.036-4.144a.5.5 0 0 1 .97.242l-1.5 6a.5.5 0 0 1-.967.01L8 9.402l-1.018 3.73a.5.5 0 0 1-.967-.01l-1.5-6a.5.5 0 1 1 .97-.242z"/>
                      </svg>
                      <h1 class="fw600 fs14 pl-3">
                        {{ f.name | slice : 0 : 10 }}
                      </h1>
                    </ngx-dropzone-label> -->

                <ngx-dropzone-preview *ngFor="let f of newdataappend" [removable]="true"
                  (removed)="newNewPassportRemoveVisa(f)">
                  <ngx-dropzone-label class="d-flex ml-4">
                    <img *ngIf="fileTypee == 'application/pdf'" src="./assets/img/red-pdf.svg" alt="red pdf" />

                      <svg *ngIf="fileTypee == 'image/png' || fileTypee == 'image/jpeg' || fileTypee == 'image/svg' || fileTypee == 'image/jpg' "
                      width="30" height="30" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
                       <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                       <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54L1 12.5v-9a.5.5 0 0 1 .5-.5z"/>
                     </svg>

                     <svg *ngIf="fileTypee == 'application/msword'" width="30" height="30" fill="#764fdb" class="bi bi-file-earmark-word-fill" viewBox="0 0 16 16">
                       <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M5.485 6.879l1.036 4.144.997-3.655a.5.5 0 0 1 .964 0l.997 3.655 1.036-4.144a.5.5 0 0 1 .97.242l-1.5 6a.5.5 0 0 1-.967.01L8 9.402l-1.018 3.73a.5.5 0 0 1-.967-.01l-1.5-6a.5.5 0 1 1 .97-.242z"/>
                     </svg>
                     <h1 class="fw600 fs14 pl-3">
                       {{ f.name | slice : 0 : 10 }}
                     </h1>
                   </ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
              <div *ngIf="uploadVisaFile">
                <span class="errorMessage">
                  Please upload document
                </span>
              </div>
            </div>

            <!-- with validation -->
            <div class="col-md-12 mt-3">
              <label class="mb-3 figma__label ml-1 d-flex justify-content-center"
                style="margin-bottom: 14px !important;">
                <span class="fs16 fs600 mr-2"> Document Name : </span> Passport <span class="mandiatory">*</span>
              </label>
              <ngx-dropzone [accept]="'application/pdf'" (change)="onselect12($event,'Passport','','')">
                <ngx-dropzone-label>
                  <div class="d-flex align-items-center">
                    <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                    <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
                  </div>
                  <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
                  <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
                </ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of newdataappendpassport" [removable]="true"
                  (removed)="newNewPassportRemovePassport(f)">
                  <ngx-dropzone-label style="width: 150px !important;">{{ f.name }} ({{ f.type
                    }})</ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
              <div *ngIf="uploadPassportFile">
                <span class="errorMessage">
                  Please upload document
                </span>
              </div>
            </div>
            <!-- with validation -->

            <!-- without validation -->
            <!-- <div class="col-md-12 mt-3" *ngIf="withoutValidationPassport">
                <label class="mb-3 figma__label ml-1 d-flex justify-content-center"
                  style="margin-bottom: 14px !important;">
                  <span class="fs16 fs600 mr-2"> Document Name : </span> Passport </label>
                <ngx-dropzone (change)="onselect12($event,'Passport','','')">
                  <ngx-dropzone-label>
                    <div class="d-flex align-items-center">
                      <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                      <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
                    </div>
                    <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
                    <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of newdataappendpassport" [removable]="true"
                    (removed)="newNewPassportRemovePassport(f)">
                    <ngx-dropzone-label style="width: 150px !important;">{{ f.name }} ({{ f.type
                      }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div> -->
            <!-- without validation -->

            <ng-container *ngIf="validatonPassport">
              <!-- *ngIf="newImmigrationInfoo.NewProfileWorkPermit !== 'addAppNo'" -->
              <div class="col-md-12 mt-5 relative" *ngFor="let doclist of documentList; let i = index">
                <!-- <label class="mb-2 figma__label ml-1 fs16 fs600 absolute" style="top:24px">
                    Document Type : <span class="figma__label">
                      {{doclist.documentType}} </span> </label> -->
                <div *ngFor="let sublist of doclist.applicantDocumentNames">
                  <!-- <label class="mb-2 col-md-7 align-items-center figma__label ml-1 my-4 fs16 fs600 d-flex">
                      Document Type : <span class="figma__label">
                    <label class="mb-2 col-md-7 align-items-center figma__label pl-0 my-4 fs16 fs600 d-flex align-items-center">
                     <p>  Document Type : </p> <span class="fs12 fw600 ml-1">
                        {{doclist.documentType}} </span>
                        <p class="align-items-center ml-auto d-flex"> Document Name : <span class="fs12 fw600 ml-1">
                          {{sublist.documentName}} </span> </p>
                     </label> -->
                  <label class="mb-2 align-items-center figma__label pl-0 my-4 fs16 fs600 d-flex align-items-center justify-content-between"
                    *ngIf="
                          sublist != '' &&
                          sublist != undefined &&
                          sublist != null
                        ">
                    <div class="align-items-center d-flex">
                      <p>Document Type :</p>
                      <span class="fs12 fw600 ml-1">{{ doclist.documentType }}
                      </span>
                      <span class="mandiatory">*</span>
                    </div>
                    <div class="">
                      <p class="align-items-center d-flex">
                        Document Name :
                        <span class="fs12 fw600 ml-1">
                          {{ sublist.documentName }}
                        </span>
                      </p>
                    </div>
                  </label>

                  <!-- [accept]="'application/pdf'" -->
                  <ngx-dropzone style="margin-top: 10px" >
                    <!-- (change)="
                          onselectnewone( $event,
                            sublist.documentName,
                            sublist.documentNameId,

                            doclist.documentTypeId,
                            sublist.applicantDocumentId

                          )
                        " -->
                    <ngx-dropzone-label>
                      <div class="d-flex align-items-center">
                        <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                        <h6 class="fw500 fs14 ml-3">
                          Drag and drop the file
                        </h6>
                      </div>
                      <span class="fw400 fs14 my-4" style="color: rgba(160, 160, 160, 1)">
                        or
                      </span>
                      <h6 class="fw500 fs14 underlinee figma_blue_color">
                        Choose file from your device
                      </h6>
                    </ngx-dropzone-label>

                    <ng-container *ngFor="let f of files">
                      <ng-container *ngFor="let fi of Object?.keys(f)">

                        <ng-container *ngIf="f.docid == doclist.documentTypeId && sublist.documentNameId == f.documentNameId">
                          <ngx-dropzone-preview *ngFor="let actualFile of f[fi]" [file]="actualFile"
                            [removable]="true" (removed)="
                                  newNewPassportRemove(
                                    actualFile,
                                    sublist.documentNameId
                                  )
                                ">
                            <ngx-dropzone-label style="width: 100px !important">{{ actualFile.name }}({{
                              actualFile.type
                              }})</ngx-dropzone-label>
                          </ngx-dropzone-preview>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ngx-dropzone>
                </div>

                <div *ngIf="uploadAlldocsFile">
                  <span class="errorMessage"> Please upload document </span>
                </div>
                <span class="errorMessage" *ngIf="supporteFilesType[i]">
                  Upload PDF/Doc Files Only
                  </span>
              </div>
            </ng-container>
            <!-- <div class="col-md-12 mt-3">
                <label class="mb-2 figma__label ml-1"> Employment Letter: </label>
                <ngx-dropzone (change)="newNewEmpLetterSelect($event)">
                  <ngx-dropzone-label>
                    <div class="d-flex align-items-center">
                      <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                      <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
                    </div>
                    <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
                    <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of newNewEmpLetterFiles" [removable]="true"
                    (removed)="newNewEmpLetterRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div> -->

            <!-- <div class="col-md-12 mt-3">
                <label class="mb-2 figma__label ml-1"> 3 months Salary Slip: </label>
                <ngx-dropzone (change)="newNewSalarySlipSelect($event)">
                  <ngx-dropzone-label>
                    <div class="d-flex align-items-center">
                      <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                      <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
                    </div>
                    <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
                    <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of newNeewSalarySlipFiles" [removable]="true"
                    (removed)="newNewSalarySlipRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div> -->

            <!-- <div class="col-md-12 mt-3">
                <label class="mb-2 figma__label ml-1"> Reliving Letter: </label>
                <ngx-dropzone (change)="newNewRelivingLetterSelect($event)">
                  <ngx-dropzone-label>
                    <div class="d-flex align-items-center">
                      <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                      <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
                    </div>
                    <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
                    <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of newnewRelivingLetterFiles" [removable]="true"
                    (removed)="newNewRelivingLetterRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div> -->

            <!-- <div class="col-md-12 mt-3">
                <label class="mb-2 figma__label ml-1"> Bank Statement: </label>
                <ngx-dropzone (change)="newNewBankStatementSelect($event)">
                  <ngx-dropzone-label>
                    <div class="d-flex align-items-center">
                      <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                      <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
                    </div>
                    <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
                    <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
                  </ngx-dropzone-label>
                  <ngx-dropzone-preview *ngFor="let f of newNewBankStatementFiles" [removable]="true"
                    (removed)="newNewBankStatementRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                  </ngx-dropzone-preview>
                </ngx-dropzone>
              </div> -->

            <!-- <button class="figma_background_blue_btn mt-4 ml-3" style="padding:12px 30px"> + Add another
                document </button> -->

            <div class="col-md-12 mt-4 pl-0">
              <button type="button" class="figma_blue_button" (click)="newBackApp('DocumentsBack')">
                <i class="icon-angle-down angle-rotate-d"></i> Back
              </button>
              <button type="button" class="figma_background_blue_btn float-right" style="padding: 12px 42px"
                (click)="saveAndnextt('Documents')" [disabled]="newApplicantSubmitDisabled">
                Submit
              </button>
            </div>
            <!-- </div> -->
          </form>
        </ng-container>
      </div>
    </div>
  </form>
</section>
<div *ngIf="addApplicantsubmitModal" id="addApplicantModalMain" class="addApplicant__modal_main">
  <div class="addApplicant__mod__content my-new--app-contentt" style="height: fit-content !important">
    <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="submitPopupNo()">
      <span aria-hidden="true">&times;</span>
    </button>

    <span class="select_option_span d-flex justify-content-center mt-4">
      Do you want to submit the application ?
    </span>
    <div class="add__bulk__buttons d-flex justify-content-center">
      <button type="button" class="btn add__App__btnn figma_background_blue_btn" data-dismiss="modal"
        aria-label="Close" (click)="submitPopupYes()">
        Yes
      </button>
      <button type="button" data-dismiss="modal" aria-label="Close"
      class="btn bulK__Upload__btnn figma_blue_button close"  (click)="submitPopupNo()"> No </button>
    </div>
  </div>
</div>
