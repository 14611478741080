<main class="edu-info-main">
  <div class="app_details_tabs_content tabss__sectioNN__linee mb-2 z9999 stickyy-partt">
    <ul class="stepbox-list d-flex flex-row w-100 ps-0">
      <li
        [ngClass]="{ active: tabb === 'tabb1' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Basic Information
      </li>
      <li
        [ngClass]="{ active: tabb === 'tabb3' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Work Experience
      </li>
      <li
        [ngClass]="{ active: tabb === 'tabb2' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Educational Information
      </li>
      <!-- <li [ngClass]="{ active: tabb === 'tabb4' }"
        class="stepbox-list__item position-relative fw500 text-center pointer">Family Information</li> -->
      <li
        [ngClass]="{ active: tabb === 'tabb5' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Immigration
      </li>
      <!-- <li [ngClass]="{ active: tabb === 'tabb6' }"
        class="stepbox-list__item position-relative fw500 text-center pointer">Declaration</li> -->
      <li
        [ngClass]="{ active: tabb === 'tabb7' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Documents
      </li>
    </ul>
  </div>
  <div class="edu-info-part app_details_tabs_content">
      <form #immigrationScreenForm="ngForm">
        <div class="row">
          <h1 class="fw600 fs18 col-md-12">Immigration</h1>
          <div class="col-md-6 priority__Radios mobile_fields_gap priority_radios_mains" style="margin-top: 20px">
            <label class="form-label-d priority__label__heading new__input__label mb-2">Do you have a permit to
              work in
              <!-- {{workTypenew[0].countryName}}? -->
              <span class="errorRequired">*</span> </label>
            <!-- <div class="gs-grouped-fileds pt-0 d-flex">
              <div class="ui radio checkbox priority_checks">
                <input class="pointer" type="radio" name="NewProfileWorkPermit" value="profileYes"
                  #NewProfileWorkPermit="ngModel" [(ngModel)]="newImmigrationInfoo.NewProfileWorkPermit"
                  (click)="showNewAppWorkPermit()" />
                <label style="font-size: 12px"
                  [ngStyle]="{color:newImmigrationInfoo.NewProfileWorkPermit == 'profileYes'? 'black': 'gray'}">Yes</label>
              </div>
              <div class="ui radio checkbox priority_checks c1p">
                <input class="pointer" type="radio" name="NewProfileWorkPermit" value="profileNo"
                  #NewProfileWorkPermit="ngModel" [(ngModel)]="newImmigrationInfoo.NewProfileWorkPermit"
                  (click)="noShowNewAppWorkPermit()" [checked]="true" />
                <label label style="font-size: 12px"
                  [ngStyle]="{color:newImmigrationInfoo.NewProfileWorkPermit == 'profileNo'? 'black': 'gray'}">No</label>
              </div>
            </div> -->
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <input class="accent-color" type="radio" name="NewProfileWorkPermit" value="addAppYes"
                  (click)="showNewAppWorkPermit($event)" [(ngModel)]="newImmigrationInfoo.NewProfileWorkPermit"
                  name="immigYesNo" #immigYesNo="ngModel" required />

                <label class="fs12 fw600 ml-1"> Yes </label>
              </div>
              <div class="d-flex align-items-center ml-4">
                <input class="accent-color" type="radio" value="addAppNo" name="NewProfileWorkPermit"
                  (click)="noShowNewAppWorkPermit($event)" name="immigYesNo" #immigYesNo="ngModel"
                  [(ngModel)]="newImmigrationInfoo.NewProfileWorkPermit" required />
                <!-- [(ngModel)]="newImmigrationInfoo.NewProfileWorkPermit" -->
                <label class="fs12 fw600 ml-1"> No </label>
              </div>
            </div>
            <div *ngIf="
                  ((immigYesNo?.touched ||
                    immigrationScreenForm.submitted) &&
                    immigYesNo.errors?.required) ||
                  immigvalidation
                ">
              <span class="errorMessage">
                Please select an option
              </span>
            </div>
          </div>

          <div class="col-md-6"></div>

          <div class="col-md-6 figma__ng__select mt-4" *ngIf="NewAppWorkPermit">
            <label class="mb-2 figma__label"> Work Permit Type </label>
            <ng-select class="" [items]="workTypenew" bindLabel="workPermitTypeName" bindValue="workPermitTypeId"
              name="newNewProfileWorkPermitTypee" #newNewProfileWorkPermitTypee="ngModel"
              placeholder="Select work permit type" [(ngModel)]="
                  newImmigrationInfoo.newNewProfileWorkPermitTypeee
                ">
            </ng-select>
          </div>

          <div class="col-md-6 mt-4" *ngIf="NewAppWorkPermit"></div>

          <!-- <div class="col-md-6 new__Phoe__part mt-3" *ngIf="NewAppWorkPermit">
            <label class="mb-2 figma__label"> Passport Number </label>
            <input onkeypress="return /[0-9]/i.test(event.key)"
              [(ngModel)]="newImmigrationInfoo.newProfilePassportNumberTwooo"
              #newProfilePassportNumberTwoo="ngModel"
              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" class="w-100 figma__Inputs"
              type="text" name="newProfilePassportNumberTwoo" placeholder="Enter passport number" />
          </div> -->

          <!-- <div class="col-md-6 figma__ng__select mt-3" *ngIf="NewAppWorkPermit">
            <label class="mb-2 figma__label"> Document  </label>
            <ng-select class="" [items]="newProfileWorkPermitDocument" bindLabel="name" bindValue="id"
              name="newProfileWorkPermitDocumentt" #newProfileWorkPermitDocumentt="ngModel"
              placeholder="Select document" [(ngModel)]="newImmigrationInfoo.newProfileWorkPermitDocumenttt">
            </ng-select>
          </div> -->

          <div class="col-md-6 new__Phoe__part mt-3" *ngIf="NewAppWorkPermit">
            <label class="mb-2 figma__label"> Id Number </label>
            <input [(ngModel)]="newImmigrationInfoo.newProfileDocIdNumberrr" #newProfileDocIdNumberr="ngModel"
              class="w-100 figma__Inputs" type="text" name="newProfileDocIdNumberr"
              placeholder="Enter id Number" />
            <!--onkeypress="return /[0-9]/i.test(event.key)" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" -->
          </div>

          <div class="col-md-6 mt-3 immigration__Screenn_calender" *ngIf="NewAppWorkPermit">
            <div class="d-md-flex">
              <div class="position-relative calender__ml col-md-6 pl-0 figma_calender_input">
                <label class="mb-2 figma__label w-100"> Date of Issue </label>
                <p-calendar class="" name="profileeDateOfIssue" placeholder="DD/MM/YY" [(ngModel)]="
                      newImmigrationInfoo.profileeDateOfIssueee
                    " #profileeDateOfIssue="ngModel" [showIcon]="true"></p-calendar>
              </div>
              <div class="position-relative calender__ml col-md-6 pr-0 figma_calender_input">
                <label class="mb-2 figma__label w-100">
                  Date of Expiry
                </label>
                <p-calendar class="" name="profileeDateOfIssueeeTwoooo" placeholder="DD/MM/YY" [(ngModel)]="
                      newImmigrationInfoo.profileeDateOfIssueeeTwoo
                    " #profileeDateOfIssueeeTwoooo="ngModel" [showIcon]="true"></p-calendar>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6 new__Phoe__part mt-3" *ngIf="NewAppWorkPermit">
            <label class="mb-2 figma__label"> Visa Number </label>
            <input onkeypress="return /[0-9]/i.test(event.key)"
              [(ngModel)]="newImmigrationInfoo.newProfileVisaNumberTwooo" #newProfileVisaNumberTwoooo="ngModel"
              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" class="w-100 figma__Inputs"
              type="text" name="newProfileVisaNumberTwoooo" placeholder="Enter visa number" />
          </div>

          <div class="col-md-6 mt-3 figma_calender_input" *ngIf="NewAppWorkPermit">
            <label class="mb-2 figma__label"> Date of Issue & Expiry </label>
            <div class="d-md-flex">
              <div class="position-relative calender__ml col-md-6 pl-0">
                <p-calendar class="" name="profileeDateOfIssue" placeholder="DD/MM/YY"
                  [(ngModel)]="newImmigrationInfoo.profileeDateOfIssueee" #profileeDateOfIssue="ngModel"
                  [showIcon]="true"></p-calendar>
              </div>
              <div class="position-relative calender__ml col-md-6 pr-0">
                <p-calendar class="" name="profileeDateOfIssueeeTwoooo" placeholder="DD/MM/YY"
                  [(ngModel)]="newImmigrationInfoo.profileeDateOfIssueeeTwoo"
                  #profileeDateOfIssueeeTwoooo="ngModel" [showIcon]="true"></p-calendar>
              </div>
            </div>
          </div> -->

          <div class="col-md-12 mt-4">
            <button type="button" class="figma_blue_button" (click)="newBackApp('ImmigrationBack')">
              <i class="icon-angle-down angle-rotate-d"></i> Back
            </button>
            <button type="button" class="figma_background_blue_btn float-right" style="padding: 12px 42px"
              (click)="saveAndnextt('Immigratioinn')">
              Save & Next
            </button>
          </div>
        </div>
      </form>
  </div>
  </main>
