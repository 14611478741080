import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { jwtDecode } from 'jwt-decode';
import { ReportsService } from 'src/app/services/reports.service';
import pluginDataLabels from 'chartjs-plugin-datalabels';
@Component({
  selector: 'app-dashboard2',
  templateUrl: './dashboard2.component.html',
  styleUrls: ['./dashboard2.component.scss']
})
export class Dashboard2Component {
  data1: any;
  options1: any;
  data2: any = {};
  options2: any;
  data3: any;
  options3: any;
  dashboardForm: FormGroup;
  numberOfLettersToShow: number = 5;
  loading: boolean = true;

  brandArray: any = [];
  countryArray: any = [];
  result: any = [];
  campusArr: any = [];
  jobCategoryArr: any = [];
  departmentArr: any = [];
  sourceArr: any = [];
  jobPosotionsArr: any = [];
  academicsArr: any = [];
  newReplacementsArr: any = [];
  averageTATArr: any = [];

  // brandArray:any=[];countryArray:any=[];result:any=[];campusArr:any=[];jobCategoryArr:any=[];departmentArr:any=[];sourceArr:any=[];
  // jobPosotionsArr:any=[];academicsArr:any=[];newReplacementsArr:any=[];averageTATArr:any=[];

  brandId:any;brandName:any;countryId:string='';campusId:string='';jobCategory:string='';departmentId:string='';jobPostId:string='';
  activeJobs:any;pendingJobs:any;closedJobs:any;totalJobs:any;academic:any;nonAcademic:any;totalCategoryCount:any;academicPercentage:any;
  nonAcademicPercentage:any;new:any;replacement:any;newAverageString:any;replacementAverageString:any;totaltrf:any;
  country:any;totalApplicants:any;malePercentage:any;femalePercentage:any;selectednotselectedapplicants:any;brandTAT:any;
  notSelectedPercentage:any;selectedPercentage:any;accepted:any;expired:any;jobClosed:any;acceptedAverage:any;expiredAverage:any;jobClosedAverage:any;

  brandFirstObj:any={};stagesOverviewObjr:any={};barChartDataobj:any={};statusDistributionObj:any={};selectedApplicantsObj:any=[];
  talentRequisitionObj:any={};mainObject:any={};stageOverview:any={};
  userDetails: any;
  userId: any;
  dateRangeFrom: any;
  dateRangeTo: any;

  // brandFirstObj: any = {};
  // stagesOverviewObjr: any = {};
  // barChartDataobj: any = {};
  // statusDistributionObj: any = {};
  // selectedApplicantsObj: any = [];
  // talentRequisitionObj: any = {};
  // mainObject: any = {};
  // stageOverview: any = {};

  patchesRowArray : any[] = [
    {patchName : 'School1: GIIS'},
    {patchName : 'School2: GIIS'},
    {patchName : 'School3: GIIS'},
    {patchName : 'School4: GIIS'},
    {patchName : 'School5: GIIS'},
    {patchName : 'School6: GIIS'},
    {patchName : 'School7: GIIS'},
  ];

  getSum(): number {
    return this.data2.datasets[0].data.reduce(
      (acc: number, curr: number) => acc + curr,
      0
    );
  }

  constructor(private fb: FormBuilder, public reportsService: ReportsService) {
    this.dashboardForm = this.fb.group({
      dateRangeFrom: ['', Validators.required],
      dateRangeTo: ['', Validators.required],
    });
    this.departmentWise = this.departments.slice();
    this.selectedCountries = this.countries.slice();
    this.selectedCategories = this.categories.slice();
  }

  ngOnInit() {
    // dashboard-hiringTrend-chart starts
    let userData = this.reportsService.getUserId();
    this.userDetails = jwtDecode(userData)
    this.userId=this.userDetails.UserId
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.data1 = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'India',
          data: [20, 30, 35, 45, 60, 70, 85],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
        },
        {
          label: 'Singapore',
          data: [28, 40, 48, 59, 76, 87, 91],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--pink-500'),
        },
      ],
    };

    this.options1 = {
      maintainAspectRatio: false,
      responsive: true,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    // Initialize data and options for the second chart
    // this.data2 :any = {
    //   type:'doughnut',
    //   labels: ['Open', 'Closed'],
    //   datasets: [
    //     {
    //       label: 'Data 1',
    //       data: [10, 20],
    //       fill: false,
    //       backgroundColor: ['#B7E334','#8B6FE8'],

    //     },
    //   ],
    // };

    this.options2 = {
      maintainAspectRatio: false,
      responsive: true,
      aspectRatio: 0.6,
      cutout: '70%',
      plugins: {
        legend: {
          display: false,
          align: 'end',
          position: 'center',
        },
        doughnutlabel: {
          labels: [
            {
              text: '220',
              font: {
                size: '60',
                units: 'em',
                family: 'Arial, Helvetica, sans-serif',
                style: 'italic',
                weight: 'bold',
              },
              color: '#bc2c1a',
            },
          ],
        },
      },
      scales: {},
    };

    /*****Page Load API CALL******/
    this.GetBrand('load', 0);
    this.GetSources();
    setTimeout(() => {
      this.GetAllFilters('load');
    }, 1000);
    /*****Page Load API CALL*****/
  }

  /****************GetBrand on Load & Change******************/
  GetBrand(type:any,event:any)
  {
    this.loading=true;
    if(type=='load')
    {
      //this.reportsService.GetAllBrands().subscribe((res:any) => {
        this.reportsService.GetBrandByUserId(this.userId).subscribe((res:any) => {
        if (res){
          this.loading = false;
        }
        this.brandArray = res
        this.brandFirstObj=res[0].brandId
        this.brandId = res[0].brandId
        this.brandName = res[0].brandName
        this.GetCountryByBrand(this.brandId)
      })
    }else{
      this.dashboardCountryFilterItem=''
      this.dashboardCampusFilterItem=''
      this.dashboardJobCategoryFilterItem=''
      this.dashboardDepartmentFilterItem=''

      // this.reportsService.GetAllBrands().subscribe((res:any) => {
        this.reportsService.GetBrandByUserId(this.userId).subscribe((res:any) => {
        if (res) {
        this.loading = false;
        this.brandArray = res
        this.brandId = event.brandId
        this.result = this.brandArray.filter((value:any, index:any, self:any) => self.findIndex((m:any) => m.brandId === this.brandId) === index);
        this.brandName = this.result[0].brandName
        this.GetCountryByBrand(this.brandId)
        }
      })
    }
  }
  /****************GetBrand on Load & Change******************/

  /****************GetCountryByBrand******************/
  GetCountryByBrand(brandId:any)
  {
    this.reportsService.GetCountryByBrand(brandId,this.userId).subscribe((result:any) => {
      if (result) {
        this.countryArray=[]
        this.countryArray = result
        this.campusArr=[]
        this.jobCategoryArr=[]
        this.departmentArr=[]
      }
    })
  }
  /****************GetCountryByBrand******************/

  /****************GetCampusByCountry******************/
  GetCountry(event: any) {
    var texts = event.map(function (el: any) {
      return el.countryId;
    });
    this.countryId = texts;
    this.countryId = this.countryId.toString();
    this.reportsService
      .GetCampusByCountry(this.brandId, this.countryId,0)
      .subscribe((result: any) => {
        this.campusArr = [];
        this.jobCategoryArr = [];
        this.departmentArr = [];

        this.dashboardCampusFilterItem = '';
        this.dashboardJobCategoryFilterItem = '';
        this.dashboardDepartmentFilterItem = '';

        result.forEach((element: any) => {
          var cumpusesasrr = element.campuses;
          cumpusesasrr.forEach((element1: any) => {
            this.campusArr.push(element1);
          });
        });
      });
  }
  /****************GetCampusByCountry******************/

  /****************GetCampusByCountry******************/
  GetCampus(event: any) {
    var texts = event.map(function (el: any) {
      return el.campusId;
    });
    this.campusId = texts;
    this.campusId = this.campusId.toString();
    this.jobCategoryArr = [];
    this.departmentArr = [];

    this.dashboardJobCategoryFilterItem = '';
    this.dashboardDepartmentFilterItem = '';

    this.reportsService
      .GetJobCategoryByCampus(this.brandId, this.countryId, this.campusId)
      .subscribe((result: any) => {
        this.jobCategoryArr = result;
      });
  }
  /****************GetCampusByCountry******************/

  /****************GetCategoryByCampus******************/
  GetCategory(event: any) {
    var texts = event.map(function (el: any) {
      return el.jobCategory;
    });
    this.jobCategory = texts;
    this.jobCategory = this.jobCategory.toString();
    this.departmentArr = [];

    this.dashboardDepartmentFilterItem = '';

    this.reportsService
      .GetDepartmentByCategory(
        this.brandId,
        this.countryId,
        this.campusId,
        this.jobCategory
      )
      .subscribe((result: any) => {
        this.departmentArr = result;
      });
  }
  /****************GetCategoryByCampus******************/

  /****************GetDepartment******************/
  GetDepartment(event: any) {
    var texts = event.map(function (el: any) {
      return el.departmentId;
    });
    this.departmentId = texts;
    this.departmentId = this.departmentId.toString();
  }
  /****************GetDepartment******************/

  /****************GetSources******************/
  GetSources() {
    this.reportsService.GetSources().subscribe((result: any) => {
      this.sourceArr = result;
    });
  }
  GetSourceId(event: any) {
    var texts = event.map(function (el: any) {
      return el.jobPostId;
    });
    this.jobPostId = texts;
    this.jobPostId = this.jobPostId.toString();
  }
  /****************GetSources******************/

  /***************Jobs(Positions)**************/
  GetjobPositions(obj: any) {
    this.reportsService.GetjobPositions(obj).subscribe((result: any) => {
      this.jobPosotionsArr = result;
      this.activeJobs = this.jobPosotionsArr.activeJobs;
      this.pendingJobs = this.jobPosotionsArr.pendingJobs;
      this.closedJobs = this.jobPosotionsArr.closedJobs;
      this.totalJobs = this.jobPosotionsArr.totalJobs;
    });
  }
  /***************Jobs(Positions)**************/

  /*******Academics & Non Academics***********/
  GetAcademics(obj: any) {
    this.reportsService.GetAcademics(obj).subscribe((result: any) => {
      this.academicsArr = result;
      this.academic = this.academicsArr.academic;
      this.nonAcademic = this.academicsArr.nonAcademic;
      this.totalCategoryCount = this.academicsArr.totalCategoryCount;
      this.academicPercentage = this.academicsArr.academicPercentage;
      this.nonAcademicPercentage = this.academicsArr.nonAcademicPercentage;
    });
  }
  /*******Academics & Non Academics************/

  /***********New Vs Replacement************/
  GetNewReplacements(obj: any) {
    this.reportsService.GetNewReplacements(obj).subscribe((result: any) => {
      this.newReplacementsArr = result;
      this.new = this.newReplacementsArr.new;
      this.replacement = this.newReplacementsArr.replacement;
      this.newAverageString = this.newReplacementsArr.newAverageString;
      this.replacementAverageString =
        this.newReplacementsArr.replacementAverageString;
    });
  }
  /************New Vs Replace****************/

  /***********Selected Applicants************/
  GetSelectedApplicants(obj: any) {
    this.reportsService.GetSelectedApplicants(obj).subscribe((result: any) => {
      this.selectedApplicantsObj = result;
      this.totalApplicants = this.selectedApplicantsObj.applicants;
      this.malePercentage = this.selectedApplicantsObj.malePercentage;
      this.femalePercentage = this.selectedApplicantsObj.femalePercentage;
    });
  }
  /************Selected Applicants************/

  /***********Status Distribution************/
  GetStatusDistribution(obj: any) {
    this.reportsService.GetStatusDistribution(obj).subscribe((result: any) => {
      this.statusDistributionObj = result;
      this.selectednotselectedapplicants =
        this.statusDistributionObj.applicants;
      this.selectedPercentage = this.statusDistributionObj.selectedPercentage;
      this.notSelectedPercentage =
        this.statusDistributionObj.notSelectedPercentage;
    });
  }
  /************Status Distribution*************/

  /***********Status Distribution************/
  GetTalentRequisition(obj: any) {
    this.reportsService.GetTalentRequisition(obj).subscribe((result: any) => {
      this.talentRequisitionObj = result;
      this.accepted = this.talentRequisitionObj.accepted;
      this.expired = this.talentRequisitionObj.expired;
      this.jobClosed = this.talentRequisitionObj.jobClosed;
      this.acceptedAverage = this.talentRequisitionObj.acceptedAverage;
      this.expiredAverage = this.talentRequisitionObj.expiredAverage;
      this.jobClosedAverage = this.talentRequisitionObj.jobClosedAverage;
      this.totaltrf = this.talentRequisitionObj.totaltrf;
    });
  }
  /************Status Distribution*************/

  /***********Stages Overview By Country, Brand, Campus************/
  GetStagesOverviewByCountry(obj: any, stageType: any) {
    if (stageType == 'Brand') {
      this.barChartData = [];
      this.reportsService
        .GetStagesOverviewbyBrand(obj)
        .subscribe((result: any) => {
          this.stagesOverviewObjr = result;
          var overviewArr = this.stagesOverviewObjr.overview;
          var mainArr = this.stagesOverviewObjr.mainArray;

          var texts = overviewArr.map(function (el: any) {
            return el.country;
          });
          this.country = texts;
          this.barChartLabels = this.country;

          mainArr.forEach((element: any) => {
            this.barChartDataobj = {
              data: element.data,
              label: element.type,
              backgroundColor: element.barChartDatabgColor,
            };
            this.barChartData.push(this.barChartDataobj);
          });
        });
    } else if (stageType == 'Country') {
      this.barChartData = [];
      this.reportsService.GetStagesOverview(obj).subscribe((result: any) => {
        this.stagesOverviewObjr = result;
        var overviewArr = this.stagesOverviewObjr.overview;
        var mainArr = this.stagesOverviewObjr.mainArray;

        var texts = overviewArr.map(function (el: any) {
          return el.country;
        });
        this.country = texts;
        this.barChartLabels = this.country;

        mainArr.forEach((element: any) => {
          this.barChartDataobj = {
            data: element.data,
            label: element.type,
            backgroundColor: element.barChartDatabgColor,
          };
          this.barChartData.push(this.barChartDataobj);
        });
      });
    } else if (stageType == 'Campus') {
      this.barChartData = [];
      this.reportsService
        .GetStagesOverviewbyCampus(obj)
        .subscribe((result: any) => {
          this.stagesOverviewObjr = result;
          var overviewArr = this.stagesOverviewObjr.overview;
          var mainArr = this.stagesOverviewObjr.mainArray;

          var texts = overviewArr.map(function (el: any) {
            return el.country;
          });
          this.country = texts;
          this.barChartLabels = this.country;

          mainArr.forEach((element: any) => {
            this.barChartDataobj = {
              data: element.data,
              label: element.type,
              backgroundColor: element.barChartDatabgColor,
            };
            this.barChartData.push(this.barChartDataobj);
          });
        });
    }
  }
  /************Stages Overview By Country, Brand, Campus*************/

  /************GetSourceHire*************/
  sourceHire: any = [];
  sourceDataArr: any = [];
  GetSourceHire(obj: any, sourceType: any) {
    this.reportsService.GetSourceHire(obj).subscribe((result: any) => {
      this.sourceHire = result;
      var texts = this.sourceHire.map(function (el: any) {
        return el.source;
      });
      var texts1 = this.sourceHire.map(function (el: any) {
        return el.number;
      });
      var texts2 = this.sourceHire.map(function (el: any) {
        return el.percentage;
      });

      if (sourceType == 'Number') {
        this.barChartLabels1 = [];
        this.barChartData1 = [];
        this.barChartLabelsPercentage = [];
        this.barChartDataPercentage = [];
        var sourceObj = {
          data: texts1,
          label: 'Sources',
          backgroundColor: '#8B6FE8',
        };
        this.barChartLabels1 = texts;
        this.barChartData1.push(sourceObj);
      }

      if (sourceType == 'Percentage') {
        this.barChartLabels1 = [];
        this.barChartData1 = [];
        this.barChartLabelsPercentage = [];
        this.barChartDataPercentage = [];
        var sourceObj1 = {
          data: texts2,
          label: 'Sources',
          backgroundColor: '#8B6FE8',
        };
        this.barChartLabelsPercentage = texts;
        this.barChartDataPercentage.push(sourceObj1);
      }
    });
  }
  /************GetSourceHire*************/

  /*************GetOpenClosedPositions*********/

  positionsArr: any = [];
  totalOpen: any;
  totalClosed: any;
  totalPositions: any;
  brandPosition: any = [];
  barChartData2OBJ: any = {};
  GetOpenClosedPositions(obj: any, positionType: any) {
    if (positionType == 'Brand') {
      this.barChartData2 = [];
      this.barChartData2OBJ = {};
      this.reportsService
        .GetOpenClosedPositionsBrandWise(obj)
        .subscribe((result: any) => {
          this.data2 = {
            type: 'doughnut',
            labels: ['Open', 'Closed'],
            datasets: [
              {
                label: 'Data 1',
                data: [result.totalOpen, result.totalClosed],
                fill: false,
                backgroundColor: ['#B7E334', '#8B6FE8'],
              },
            ],
          };

          this.totalOpen = result.totalOpen;
          this.totalClosed = result.totalClosed;
          this.totalPositions = this.totalOpen + this.totalClosed;
          this.positionsArr = result.overview;
          var postArr = this.positionsArr;
          var texts = postArr.map(function (el: any) {
            return el.country;
          });
          this.brandPosition = texts;
          this.barChartLabels2 = this.brandPosition;
          var mainArr = result.mainArray;
          mainArr.forEach((element: any) => {
            if (element.type == 'Open') {
              this.barChartData2OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#B7E334',
              };
            } else {
              this.barChartData2OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#8B6FE8',
              };
            }
            this.barChartData2.push(this.barChartData2OBJ);
          });
        });
    } else if (positionType == 'Country') {
      this.barChartData2 = [];
      this.barChartData2OBJ = {};
      this.reportsService
        .GetOpenClosedPositionsCountryWise(obj)
        .subscribe((result: any) => {
          this.data2 = {
            type: 'doughnut',
            labels: ['Open', 'Closed'],
            datasets: [
              {
                label: 'Data 1',
                data: [result.totalOpen, result.totalClosed],
                fill: false,
                backgroundColor: ['#B7E334', '#8B6FE8'],
              },
            ],
          };

          console.log(this.data2, '==this.data2');
          this.totalOpen = result.totalOpen;
          this.totalClosed = result.totalClosed;
          this.totalPositions = this.totalOpen + this.totalClosed;
          this.positionsArr = result.overview;
          var postArr = this.positionsArr;
          var texts = postArr.map(function (el: any) {
            return el.country;
          });
          this.brandPosition = texts;
          this.barChartLabels2 = this.brandPosition;
          var mainArr = result.mainArray;
          mainArr.forEach((element: any) => {
            if (element.type == 'Open') {
              this.barChartData2OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#B7E334',
              };
            } else {
              this.barChartData2OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#8B6FE8',
              };
            }
            this.barChartData2.push(this.barChartData2OBJ);
          });
        });
    } else if (positionType == 'Campus') {
      this.barChartData2 = [];
      this.barChartData2OBJ = {};
      this.reportsService
        .GetOpenClosedPositionsCampusWise(obj)
        .subscribe((result: any) => {
          this.data2 = {
            type: 'doughnut',
            labels: ['Open', 'Closed'],
            datasets: [
              {
                label: 'Data 1',
                data: [result.totalOpen, result.totalClosed],
                fill: false,
                backgroundColor: ['#B7E334', '#8B6FE8'],
              },
            ],
          };

          this.totalOpen = result.totalOpen;
          this.totalClosed = result.totalClosed;
          this.totalPositions = this.totalOpen + this.totalClosed;
          this.positionsArr = result.overview;
          var postArr = this.positionsArr;
          var texts = postArr.map(function (el: any) {
            return el.country;
          });
          this.brandPosition = texts;
          this.barChartLabels2 = this.brandPosition;
          var mainArr = result.mainArray;
          mainArr.forEach((element: any) => {
            if (element.type == 'Open') {
              this.barChartData2OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#B7E334',
              };
            } else {
              this.barChartData2OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#8B6FE8',
              };
            }
            this.barChartData2.push(this.barChartData2OBJ);
          });
        });
    }
  }
  /*************GetOpenClosedPositions*********/

  /*************GetNewReplacement*********/
  entityArr: any = [];
  brandEntity: any;
  barChartData3OBJ: any = {};
  GetNewReplacementEntity(obj: any, entityType: any) {
    if (entityType == 'Brand') {
      this.barChartData3 = [];
      this.barChartData3OBJ = {};
      this.reportsService
        .GetNewReplacementByBrand(obj)
        .subscribe((result: any) => {
          this.entityArr = result.overview;
          var entArr = this.entityArr;
          var texts = entArr.map(function (el: any) {
            return el.brand;
          });

          this.brandEntity = texts;
          this.barChartLabels3 = this.brandEntity;

          var mainArr = result.mainArray;
          mainArr.forEach((element: any) => {
            if (element.type == 'New') {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#8B6FE8',
              };
            } else {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#EE90FC',
              };
            }
            this.barChartData3.push(this.barChartData3OBJ);
          });
        });
    } else if (entityType == 'Country') {
      this.barChartData3 = [];
      this.barChartData3OBJ = {};
      this.reportsService
        .GetNewReplacementByCountry(obj)
        .subscribe((result: any) => {
          this.entityArr = result.overview;
          var entArr = this.entityArr;
          var texts = entArr.map(function (el: any) {
            return el.country;
          });
          var texts1 = entArr.map(function (el: any) {
            return el.noofapplication;
          });
          this.brandEntity = texts;
          this.barChartLabels3 = this.brandEntity;
          var mainArr = result.mainArray;
          mainArr.forEach((element: any) => {
            if (element.type == 'New') {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#8B6FE8',
              };
            } else {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#EE90FC',
              };
            }
            this.barChartData3.push(this.barChartData3OBJ);
          });
        });
    } else if (entityType == 'Campus') {
      this.barChartData3 = [];
      this.barChartData3OBJ = {};
      this.reportsService
        .GetNewReplacementByCampus(obj)
        .subscribe((result: any) => {
          this.entityArr = result.overview;
          var entArr = this.entityArr;
          var texts = entArr.map(function (el: any) {
            return el.campus;
          });
          var texts1 = entArr.map(function (el: any) {
            return el.noofapplication;
          });
          this.brandEntity = texts;
          this.barChartLabels3 = this.brandEntity;
          var mainArr = result.mainArray;
          mainArr.forEach((element: any) => {
            console.log(element, '==element');
            if (element.type == 'New') {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#8B6FE8',
              };
            } else {
              this.barChartData3OBJ = {
                data: element.data,
                label: element.type,
                backgroundColor: '#EE90FC',
              };
            }
            this.barChartData3.push(this.barChartData3OBJ);
          });
        });
    }
  }
  /*************GetNewReplacement*********/

  /*************GetAverageTAT*********/
  GetAverageTAT(obj: any, tatType: any) {
    if (tatType == 'Brand') {
      this.barChartData4 = [];
      this.barChartLabels4 = [];
      this.reportsService.GetAverageTATBrand(obj).subscribe((result: any) => {
        this.averageTATArr = result;
        var tatArr = this.averageTATArr;
        var texts = tatArr.map(function (el: any) {
          return el.brandname;
        });
        var texts1 = tatArr.map(function (el: any) {
          return el.noofapplication;
        });
        this.brandTAT = texts;
        this.barChartLabels4 = this.brandTAT;
        var tatOBJ = {
          data: texts1,
          label: 'Sources',
          backgroundColor: '#8B6FE8',
        };
        this.barChartData4.push(tatOBJ);
      });
    } else if (tatType == 'Country') {
      this.barChartData4 = [];
      this.barChartLabels4 = [];
      this.reportsService.GetAverageTATCountry(obj).subscribe((result: any) => {
        this.averageTATArr = result;
        var tatArr = this.averageTATArr;
        var texts = tatArr.map(function (el: any) {
          return el.countryname;
        });
        var texts1 = tatArr.map(function (el: any) {
          return el.noofapplication;
        });
        this.brandTAT = texts;
        this.barChartLabels4 = this.brandTAT;
        var tatOBJ = {
          data: texts1,
          label: 'Sources',
          backgroundColor: '#8B6FE8',
        };
        this.barChartData4.push(tatOBJ);
      });
    } else if (tatType == 'Campus') {
      this.barChartData4 = [];
      this.barChartLabels4 = [];
      this.reportsService.GetAverageTATCampus(obj).subscribe((result: any) => {
        this.averageTATArr = result;
        var tatArr = this.averageTATArr;
        var texts = tatArr.map(function (el: any) {
          return el.campusname;
        });
        var texts1 = tatArr.map(function (el: any) {
          return el.noofapplication;
        });
        this.brandTAT = texts;
        this.barChartLabels4 = this.brandTAT;
        var tatOBJ = {
          data: texts1,
          label: 'Sources',
          backgroundColor: '#8B6FE8',
        };
        this.barChartData4.push(tatOBJ);
      });
    }
  }
  /*************GetAverageTAT*********/

  /****************GetAllFilters******************/
  fromDate: any;
  GetAllFilters(type: any) {
    var obj = {};
    if (type == 'load') {
      obj = {
        brand: this.brandId,
        country: '',
        campus: '',
        jobCategory: '',
        department: '',
        source: this.jobPostId,
        fromDate: '1900-01-01T00:00:00.000Z',
        toDate: '1900-01-01T00:00:00.000Z',
      };
    } else {
      var startDate = this.dashboardForm.controls['dateRangeFrom'].value;
      var endDate = this.dashboardForm.controls['dateRangeTo'].value;

      if (startDate != '' && startDate != undefined && startDate != null) {
        startDate = startDate;
      } else {
        startDate = '1900-01-01T00:00:00.000Z';
      }

      if (endDate != '' && endDate != undefined && endDate != null) {
        endDate = endDate;
      } else {
        endDate = '1900-01-01T00:00:00.000Z';
      }

      obj = {
        brand: this.brandId,
        country: this.countryId,
        campus: this.campusId,
        jobCategory: this.jobCategory,
        department: this.departmentId,
        source: this.jobPostId,
        fromDate: startDate,
        toDate: endDate,
      };
    }

    this.mainObject = obj;
    this.GetjobPositions(obj);
    this.GetAcademics(obj);
    this.GetNewReplacements(obj);
    this.GetSelectedApplicants(obj);
    this.GetStatusDistribution(obj);
    this.GetTalentRequisition(obj);
    this.GetStagesOverviewByCountry(obj, 'Country');
    this.GetSourceHire(obj, 'Number');
    this.GetOpenClosedPositions(obj, 'Country');
    this.GetNewReplacementEntity(obj, 'Country');
    this.GetAverageTAT(obj, 'Country');
  }

  /****************GetAllFilters******************/

  dashboardSchoolFilterItem: any;
  dashboardSchoolFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardCountryFilterItem: any;
  dashboardCountryFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardCampusFilterItem: any;
  dashboardCampusFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardJobCategoryFilterItem: any;
  dashboardJobCategoryFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardDepartmentFilterItem: any;
  dashboardDepartmentFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardJobTitleFilterItem: any;
  dashboardJobTitleFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  dashboardSourceFilterItem: any;
  dashboardSourceFilter = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  // dashboard-stages-chart starts
  public barChartOptions = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
      // maintainAspectRatio: false,
    },

    barThickness: 45,
  };

  public barChartLabels = [
    // 'Singapore',
    // 'India',
    // 'Dubai',
    // 'Cambodia',
    // 'Abu Dubai',
  ];

  public barChartType: any = 'bar';

  public barChartLegend = true;

  barChartDatabgColor: any = [
    '#EB5146',
    '#E4CB4D',
    '#B7E334',
    '#EE90FC',
    '#8B6FE8',
  ];
  public barChartData: any = [
    // {
    //   data: [20, 40, 40, 10, 10],
    //   label: 'New',
    //    backgroundColor: '#EB5146'
    // },
    // {
    //   data: [10, 60, 10, 10, 40],
    //   label: 'Screening',
    //   backgroundColor: '#E4CB4D',
    // },
    // {
    //   data: [60, 60, 60, 60, 40],
    //   label: 'Interview',
    //   backgroundColor: '#B7E334',
    // },
    // {
    //   data: [40, 20, 20, 40, 40],
    //   label: 'Shortlisted',
    //   backgroundColor: '#EE90FC',
    // },
    // {
    //   data: [60, 60, 60, 60, 60],
    //   label: 'Offered',
    //   backgroundColor: '#8B6FE8',
    // },
    // {
    //   data: [40, 10, 20, 20, 20],
    //   label: 'Onboarded',
    //   backgroundColor: '#9FEDFF',
    // },
  ];
  // dashboard-stages-chart ends

  // dasahboard-sourceOfHire-chart starts
  public barChartOptions1 = {
    responsive: true,
    scales: {
      x: {},
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        },
      },
      legend: {
        display: true,
        align: 'end',
        // position: 'bottom',
      },
    },

    barThickness: 45,
  };

  public barChartLabels1: any = [
    // 'Job Portal',
    // 'Employee Referral',
    // 'IJP',
    // 'Advertisement',
    // 'Walk-In',
    // 'Re-Hire',
    // 'Upwork',
    // 'Consult',
    // 'Naukri',
    // 'Indeed',
    // 'Hirist',
  ];
  public barChartPlugins = [pluginDataLabels];
  public barChartType1: any = 'bar';

  public barChartLegend1 = true;

  public barChartData1: any = [
    // {
    //   data: [20, 40, 40, 10, 10, 60, 30, 40, 40, 20, 50],
    //   label: 'Sources',
    //   backgroundColor: '#8B6FE8',
    // },
  ];

  public barChartOptionsPercentage = {
    responsive: true,
    scales: {
      x: {},
    },
    plugins: {
      datalabels: {
        formatter: (value: any, context: any) => {
          return value + '%'; // Add "%" suffix to the data label
        },
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        },
      },
      legend: {
        display: true,
        align: 'end',
        // position: 'bottom',
      },
    },
    barThickness: 45,
  };

  public barChartLabelsPercentage = [
    'Job Portal',
    'Employee Referral',
    'IJP',
    'Advertisement',
    'Walk-In',
    'Re-Hire',
    'Upwork',
    'Consult',
    'Naukri',
    'Indeed',
    'Hirist',
  ];
  public barChartPlugins1 = [pluginDataLabels];
  public barChartTypePercentage: any = 'bar';

  public barChartLegendPercentage = true;

  public barChartDataPercentage = [
    {
      data: [20, 40, 40, 10, 10, 60, 30, 40, 40, 20, 50],
      label: 'Sources',
      backgroundColor: '#8B6FE8',
    },
  ];
  // dasahboard-sourceOfHire-chart ends

  public barChartOptions2 = {
    responsive: true,
    scales: {},
    plugins: {
      tooltip: {
        // backgroundColor: '#fff',
        bodyFontColor: '#000',
      },
      legend: {
        labels: {
          font: {
            color: '#000',
          },
        },
      },
    },

    barThickness: 45,
    // Set global font color
  };

  public barChartLabels2 = [
    'Singapore',
    'India',
    'Dubai',
    'Cambodia',
    'Abu Dubai',
  ];

  public barChartType2: any = 'bar';

  public barChartLegend2 = true;
  NewReplacementbarChartDatabgColor: any = ['#8B6FE8', '#EE90FC'];

  openClosedBgColor: any = ['#8B6FE8', '#B7E334'];
  public barChartData2: any = [
    // {
    //   data: [20, 40, 40, 10, 10],
    //   label: 'Open',
    //    backgroundColor: '#EB5146' },
    // {
    //   data: [10, 60, 10, 10, 40],
    //   label: 'Closed',
    //   backgroundColor: '#E4CB4D',
    // },
    // {
    //   data: [60, 60, 60, 60, 40],
    //   label: 'Interview',
    //   backgroundColor: '#B7E334',
    // },
  ];
  // dashboard-positionsGlobal-chart ends

  // dashboard-entityBreakup-chart starts

  public barChartOptions3 = {
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
      // maintainAspectRatio: false,
    },
    plugins: {
      legend: {
        display: true,
        align: 'center',
        position: 'bottom',
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
    barThickness: 45,
  };

  public barChartLabels3 = [
    'GIIS',
    'Global',
    'OWIS Osaka',
    'OWIS Singapore',
    'OWIS Punngol',
  ];

  public barChartType3: any = 'bar';

  public barChartLegend3 = true;

  public barChartData3: any = [
    // {
    //   data: [20, 40, 40, 10, 10],
    //    label: 'New',
    //     backgroundColor: '#8B6FE8' },
    // {
    //   data: [10, 60, 10, 10, 40],
    //   label: 'Replacement',
    //   backgroundColor: '#EE90FC',
    // },
  ];
  // dashboard-entityBreakup-chart ends

  // dashboard-averageTat-chart start

  public barChartOptions4 = {
    responsive: true,
    // scales: { xAxes: [{}], yAxes: [{}] },
    labels: {
      fontColor: 'green',
      display: true,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        },
      },
      legend: {
        display: true,
        align: 'end',
        // position: 'bottom',
      },
    },
    barThickness: 45,
  };

  public barChartLabels4: any = [
    // 'Dwight',
    // 'GIIS',
    // 'Glendale',
    // 'Harrods',
    // 'OWIS India',
    // 'OWIS Riyadh',
    // 'Dwight',
    // 'OWIS India',
    // 'OWIS Riyadh',
    // 'Dwight',
    // 'GIIS',
  ];

  public barChartType4: any = 'bar';

  public barChartLegend4 = true;

  public barChartData4: any = [
    // {
    //   data: [20, 40, 40, 10, 10, 60, 30, 40, 40, 20, 50],
    //   label: 'Sources',
    //   backgroundColor: '#8B6FE8',
    // },
  ];
  // dashboard-averageTat-chart ends

  data: any;

  options: any;

  selectedCategories: any[] = [];

  categories: any[] = [
    { name: 'New', key: 'A' },
    { name: 'Screening', key: 'M' },
    { name: 'Interview', key: 'P' },
    { name: 'Pre-Offer', key: 'R' },
    { name: 'Offer', key: 'X' },
    { name: 'Post-Offer', key: 'Y' },
  ];
  selectedCountries: any[] = [];

  countries: any[] = [
    { name: 'India', key: 'IN' },
    { name: 'Singapore', key: 'SN' },
    { name: 'OWIS', key: 'OW' },
    { name: 'UAE', key: 'UAE' },
    { name: 'Malaysia', key: 'ML' },
    { name: 'Japan', key: 'JA' },
    { name: 'Cambodia', key: 'CM' },
    { name: 'Dwight', key: 'DW' },
    { name: 'Regent', key: 'RE' },
    { name: 'OWIS Riyadh', key: 'OWR' },
    { name: 'Philippines', key: 'PH' },
    { name: 'OWIS India', key: 'OWI' },
    { name: 'Glendale', key: 'GD' },
  ];
  // departmentWise: boolean = true;
  departmentWise: any[] = [];

  departments: any[] = [
    { name: 'New', key: 'NW' },
    { name: 'Replacement', key: 'RP' },
  ];



dashboardTable : any = [
  { entity : 'Giis', jan : '01', feb : '02', march : '03', apr : '04', may : '05', june : '06', july : '07', aug : '08', sept : '09', oct : '10', nov : '11', dec : '12', total : '15', average : '22'},
  { entity : 'Dwight', jan : '01', feb : '02', march : '03', apr : '04', may : '05', june : '06', july : '07', aug : '08', sept : '09', oct : '10', nov : '11', dec : '12', total : '15', average : '22'},
  { entity : 'Harrods', jan : '01', feb : '02', march : '03', apr : '04', may : '05', june : '06', july : '07', aug : '08', sept : '09', oct : '10', nov : '11', dec : '12', total : '15', average : '22'},
  { entity : 'OWIS Singapore', jan : '01', feb : '02', march : '03', apr : '04', may : '05', june : '06', july : '07', aug : '08', sept : '09', oct : '10', nov : '11', dec : '12', total : '15', average : '22'},
  { entity : 'OWIS India', jan : '01', feb : '02', march : '03', apr : '04', may : '05', june : '06', july : '07', aug : '08', sept : '09', oct : '10', nov : '11', dec : '12', total : '15', average : '22'},
]


  savefilterCriteria(){
    var obj={
      'brandFirstObj':this.brandFirstObj,
      'brandFirstOb':this.dashboardCountryFilterItem,
      'dashboardCountryFilterItem':this.dashboardCampusFilterItem,
      'dashboardJobCategoryFilterItem':this.dashboardJobCategoryFilterItem,
      'dashboardJobCategoryFilter':this.dashboardDepartmentFilterItem,
      'dashboardDepar':this.dashboardSourceFilterItem,
      'dateRangeFrom':this.dateRangeFrom,
      'dateRangeTo':this.dateRangeTo
    }
  }
  resetCriteria(){
    this.dashboardCountryFilterItem='',
    this.dashboardCampusFilterItem='',
    this.dashboardJobCategoryFilterItem='',
    this.dashboardDepartmentFilterItem='',
    this.dashboardSourceFilterItem='',
    this.dateRangeFrom='',
    this.dateRangeTo=''
  }
  removeSinglePatch(i:any){
    this.patchesRowArray.splice(i,1);
  }
}
