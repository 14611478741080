import { Component, AfterViewInit  } from '@angular/core';
import { ReportsService } from 'src/app/services/reports.service';
import { jwtDecode } from 'jwt-decode';

import { IJPService } from 'src/app/services/IJP.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-referal-job-description',
  templateUrl: './referal-job-description.component.html',
  styleUrls: ['./referal-job-description.component.scss']
})
export class ReferalJobDescriptionComponent {
  ngAfterViewInit() {
    // Select all the close buttons (img elements with the "x-close" image)
    const closeButtons = document.querySelectorAll<HTMLImageElement>('.skill_set p img');

    // Add a click event listener to each close button
    closeButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        // Prevent the click event from propagating further
        event.stopPropagation();

        // Get the parent paragraph element (which contains the skill text and the close button)
        const skillElement = button.parentElement as HTMLParagraphElement;

        // Remove the skill element (paragraph) from the DOM
        if (skillElement) {
          skillElement.remove();
        }
      });
    });
  }

  lastSegment: any;
  trDetails: any;
  schoolDetail: any;
  jobDetail: any;
  countryDetail: any;
  campusDetail: any;
  departmentDetail: any;
  ijpBasicInformation: any;
  nopositionsDetail: any;
  jobcategoryDetail: any;
  priorityDetail: any;
  typeofhiringDetail: any;
  rationalDetail: any;
  typeofemployementDetail: any;
  durationDetail: any;
  joiningdateDetail: any;
  typeofappoinmentDetail: any;
  postjobtoDetail: any=[];
  industryDetail: any=[];
  salaryofferrangeDetail: any;
  publishstartdateDetail: any;
  hiringManagerDetail: any;
  recruitmentmanagername: any;
  trName: any;
  brandName: any;
  titleName:any;
  countryName:any;
  campusName:any;
  departmentName:any;
  numberOfPositions:any;
  jobCategoryname:any;
  priority:any;
  typeofhiringname:any;
  typesofemployment:any;
  employmentDuration:any;
  expectedDateOfJoining:any;
  typeofappointmentname:any;
  rationalForHiringNonBudgetedPosition:any;
  salaryOfferRangeFrom:any;
  totalExperience:any;
  domaindetail:any=[];
  currentSalaryFrom:any;
  preferredHigherEducationname:any;
  preferredQualificationsname:any;
  languageName:any;
  preferredGender:any;
  noticePeriodFrom:any;
  timeNeededToRelocate:any;
  preferredAgeBarFrom:any;
  documentTypedeatil:any=[];
  hiringmangername:any;
  businesspartnername:any;
  workPermitCountrys:any;
  workPermitname:any;
  workPermitTypes:any;
  ijpJobDescription:any;
  responsibility:any;
  overview:any;
  skillsDescription:any;
  disclaimer:any;
  websiteShortDescription:any;
  ijpjobskill:any;
  skillCategory:any;
  skilldeatil:any=[];
  approverdetail:any=[];
  hiringdetail:any=[];
  targetdetail:any=[];
  targetBrandName:any;
  preferredNationalityname:any;
  levels:any;
  department:any;
  salaryOfferRangeTo:any;
  currentSalaryTo:any;
  salaryOfferRangeSymbol:any;
  currentSalarySymbol:any;
  userDetails: any;
  userId:any;
  status:any;enableReactivate:boolean=false
  ijpJobApprovedStatus:any;
  createdUserId:any;enableApproveReject:boolean=false;
  
  constructor(private IJPService: IJPService,private router:Router,public reportsService: ReportsService) { }
  ngOnInit(): void {
    var url = window.location.href
    this.lastSegment = url.split("/").pop();
    this.getJobdetails(this.lastSegment)
    let userData = this.reportsService.getUserId();
    this.userDetails = jwtDecode(userData);
    this.userId = this.userDetails.UserId;
  }
  getJobdetails(id:any){
    this.IJPService.EditIJP(id).subscribe((res)=>{

      if(res!='Reporting Manager not found')
        {
          console.log("111111111111",res);
          this.trName=res.ijpBasicInformation.trName
          this.brandName=res.ijpBasicInformation.brandName
          this.titleName=res.ijpBasicInformation.titleName
          this.countryName=res.ijpBasicInformation.countryName
          this.campusName=res.ijpBasicInformation.campusName
          this.department=res.ijpBasicInformation.department
          this.numberOfPositions=res.ijpBasicInformation.numberOfPositions
          this.jobCategoryname=res.ijpBasicInformation.jobCategoryname
          this.priority=res.ijpBasicInformation.priority
          this.typeofhiringname=res.ijpBasicInformation.typeofhiringname
          this.hiringmangername=res.ijpBasicInformation.hiringmangername
          this.rationalForHiringNonBudgetedPosition=res.ijpBasicInformation.rationalForHiringNonBudgetedPosition
          this.recruitmentmanagername=res.ijpBasicInformation.recruitmentmanagername
          this.businesspartnername=res.ijpBasicInformation.businesspartnername
          this.typesofemployment=res.ijpBasicInformation.typesofemployment
          this.employmentDuration=res.ijpBasicInformation.employmentDuration
          this.expectedDateOfJoining=res.ijpBasicInformation.expectedDateOfJoining
          this.typeofappointmentname=res.ijpBasicInformation.typeofappointmentname
          this.salaryOfferRangeSymbol=res.ijpBasicInformation.salaryOfferRangeSymbol
          this.salaryOfferRangeFrom=res.ijpBasicInformation.salaryOfferRangeFrom
          this.salaryOfferRangeTo=res.ijpBasicInformation.salaryOfferRangeTo
          this.postjobtoDetail=res.ijpBasicInformation.ijpJobPostedData
          this.industryDetail=res.ijpBasicInformation.ijpindustry
          this.totalExperience=res.ijpBasicInformation.totalExperience
          this.currentSalarySymbol=res.ijpBasicInformation.currentSalarySymbol
          this.currentSalaryFrom=res.ijpBasicInformation.currentSalaryFrom
          this.currentSalaryTo=res.ijpBasicInformation.currentSalaryTo
          this.preferredHigherEducationname=res.ijpBasicInformation.preferredHigherEducationname
          this.preferredQualificationsname=res.ijpBasicInformation.preferredQualificationsname
          this.preferredNationalityname=res.ijpBasicInformation.preferredNationalityname
          this.languageName=res.ijpBasicInformation.languageName
          this.preferredGender=res.ijpBasicInformation.preferredGender
          this.noticePeriodFrom=res.ijpBasicInformation.noticePeriodFrom
          this.timeNeededToRelocate=res.ijpBasicInformation.timeNeededToRelocate
          this.preferredAgeBarFrom=res.ijpBasicInformation.preferredAgeBarFrom
          this.documentTypedeatil=res.ijpBasicInformation.ijpdocument
          this.domaindetail=res.ijpBasicInformation.ijpdomain
          this.workPermitname=res.ijpBasicInformation.workPermitname
          this.workPermitCountrys=res.ijpBasicInformation.workPermitCountrys
          this.workPermitTypes=res.ijpBasicInformation.workPermitTypes
          this.overview=res.ijpJobDescription.overview
          this.responsibility=res.ijpJobDescription.responsibility
          this.skillsDescription=res.ijpJobDescription.skillsDescription
          this.disclaimer=res.ijpJobDescription.disclaimer
          this.websiteShortDescription=res.ijpJobDescription.websiteShortDescription
          this.skilldeatil=res.ijpJobDescription.ijpjobskill
          this.targetdetail=res.targetAudience
          this.createdUserId = parseInt(res.ijpBasicInformation.userId)
          this.approverdetail=res.reportingManager.iJPJobApprover
          console.log(this.approverdetail,".....approverdetail");

          this.ijpJobApprovedStatus=res.ijpBasicInformation.ijpJobApprovedStatus
          this.status=res.ijpBasicInformation.status

          this.hiringdetail=res.ijpEmploymentCtcApprover.iJPHiringTeam
          for(let k=0;k<this.approverdetail.length;k++)
            {
              if(this.approverdetail[k].approveMemberId==this.userId && this.approverdetail[k].ijpJobStatus!='97' && this.approverdetail[k].ijpJobStatus!='96'  && this.approverdetail[k].ijpJobStatus!='0'   && this.approverdetail[k].ijpJobStatus!='95' )
                {
                  this.enableApproveReject = true
                  this.enableReactivate = false
                }
                else if(this.approverdetail[k].approveMemberId==this.userId && this.approverdetail[k].ijpJobStatus!='97')
                {
                  this.enableApproveReject = false
                  this.enableReactivate = true
                }
            }
        }else{
          this.enableApproveReject = false
        }

    })
  }

  visible: boolean = false;

  showDialog() {
      this.visible = true;
  }
  
}
