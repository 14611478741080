
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from '../services/app-settings.service';
import { userComponent } from '../settings/user/user.component';
import { userService } from './userservice';
import { Observable } from 'rxjs/internal/Observable';
import { jwtDecode } from 'jwt-decode';


@Injectable({
    providedIn: 'root'
})
export class roleService {

    public roleObj: any;
    public userId: any;
    public userData: any;

    headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache');


    constructor(private http: HttpClient, public appSettingSevice: AppSettingsService) {
        let user = localStorage.getItem('userDetails');
        if (user != null) {

            this.userData = jwtDecode(user)
            let userId = this.userData.UserId

            // let userDetails = JSON.parse(user);
            // this.userData = userDetails
            // let userId = userDetails.userId.toString();
            this.headers = this.headers.append('userId', userId);
            this.userId = userId;
        }
    }

    getRoleDetails() {
        return this.http.get(`${environment.apiUrl}/api/Role/GetAllRoleDetails`, { headers: this.headers });
    }

    createRole(data) {
        return this.http.post(`${environment.apiUrl}/api/Role/InsertRoleDetails`, data, { headers: this.headers });
    }
    getRoleInfoById(id) {
        return this.http.get(`${environment.apiUrl}/api/Role/GetAllRoleDetailsById=${id}`, { headers: this.headers })
    }



    getRolePermission() {
        // /api /Role /InsertRoleDetails
        return this.http.get(`${environment.apiUrl}/api/Role/GetModulePermission`, { headers: this.headers });
    }

    deleteRoleById(id) {
        return this.http.get(`${environment.apiUrl}/api/Role/DeleteRole?roleId=${id}`, { headers: this.headers });
    }

    // getRoleById(userId, roleId) {
    //     return this.http.get(`${environment.apiUrl}/api/Role/GetRolebyId?roleId=${roleId}&userId=${userId}`, { headers: this.headers });
    // }


    getRoleById(id): Observable<any> {
        let params = new HttpParams().set("roleId", id)
        return this.http.get(`${environment.apiUrl}/api/Role/GetAllRoleDetailsById`, { params: params })
    }


    GetRolesBySearch(pageNo,pageSize,value) {
        return this.http.get(`${environment.apiUrl}/api/Role/GetRoleDetailsBySearch?PagesizePerPage=pageSize,pageNumber=pageNo&userName=value`, { headers: this.headers });
    }

    getPersetPermission() {
        return this.http.get(`${environment.apiUrl}/api/Role/GetPreSetPermissions?userId=${this.userId}`, { headers: this.headers });
    }


    getUserPermission() {
        return this.userData;
    }
    
    GetUserRolesHrInterviewDetailsByUserId(data) {
        return this.http.get(`${environment.apiUrl}/api/User/GetUserRolesHrInterviewDetailsByUserId?userId=${data}`, { headers: this.headers });

    }

    roleCheck(data) {
        return this.http.get(`${environment.apiUrl}/giis/api/Role/GetRoleValidate?roleName=${data}`, { headers: this.headers });

    }

    GetUserValidate(data) {
        return this.http.get(`${environment.apiUrl}/api/Role/GetUserValidate?userId=${data}`, { headers: this.headers });

    }

    /***************User Name****************/
    userName(pageSize, pageNumber) {
        return this.http.get(`${environment.apiUrl}/api/User/GetUsers?PagesizePerPage=${pageSize}&pageNumber=${pageNumber}`, { headers: this.headers });

    }
    roleusernames(){
        return this.http.get(`${environment.apiUrl}/api/User/GetAllUserList`, { headers: this.headers });
    }
    /***************User Name****************/

    /***************User Role Type****************/
    roleType() {
        return this.http.get(`${environment.apiUrl}/api/Role/GetRoleType`, { headers: this.headers });

    }
    /***************User Role Type****************/

    roleTypeUserId(userId) {
        return this.http.get(`${environment.apiUrl}/api/Role/GetRoleTypeByUserId?userId=${userId}`, { headers: this.headers });
    }

    roleUserId(rId) {
        return this.http.get(`${environment.apiUrl}/api/User/GetUserDetailsByMultipleRoleIds?id=${rId}`, { headers: this.headers });
    }

    getTrApproversRoleType() {
        return this.http.get(`${environment.apiUrl}/api/Role/GetTrApproversRoleType`, { headers: this.headers });

    }
    
}