import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, NgForm } from '@angular/forms';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit{
  activeStepIndex: number = 0;
  steps: any;
  stepChange: any;
  employmentExp:any;
  roleDuration:any;
  numberOfPositions:any = 10;
  amount:any = '10,000';
  amount1:any = '10,000';
  amount2:any = '10,000';

  input1:any;
  input2:any;
  currentSalaryCode:any;
  currentSalaryCodefrom:any;
  currentSalaryCodeto:any;
  bonusFrom:any;
  bonusFrom1:any;
  bonusTo:any;
  bonusTo1:any;
  addAmount:any;
  selectedBonusType: string = 'flat';
  additionalBonusType : string = 'na';
  referralBonusArr: any = {};
  addBonusArr: any = {};
  hiringTeamMember:any;
  hiringStage:any;
  interviewTemplate:any;
  cutOffDate:any;
  typeOfInterview:any;
  applicantApproval:any;
  @ViewChild('targetAudienceForm') targetAudienceForm: any;
  @ViewChild('progrmaApproversForm') progrmaApproversForm: any;
  @ViewChild('referralRulesForm') referralRulesForm:any;

  stepChecked: boolean[] = [false, false, false, false, false];
  messageService: any;
  router: any;

  
  constructor( public fb: FormBuilder,private cdr: ChangeDetectorRef){
    this.referralBonusArr.referralBonusArrItem = [
      {
        bonusFrom:null,
        bonusTo: null,
        amount1: null,
    
      },
    ];
    this.addBonusArr.addBonusArrItem = [
      {
        refreeTenureSelection:null,
        addAmount: null,
    
      },
    ];
  }
 

  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  goToStep(index: number) {
    if (index >= 0 && index < this.steps.length) {
      this.activeStepIndex = index;
      this.stepChange.emit(this.activeStepIndex);
    }
  }

  // get activeStep(): string {
  //   return ['Step 1 - Target Audience', 'Step 2 - Program Approvers', 'Step 3 - Referral Rules' ,][this.activeStepIndex];
  // }


  isStepCompleted(index: number): boolean {
    return index < this.activeStepIndex;
  }

  nextStep(step: string) {
    // alert(step)

    switch (step) {
        case 'first':
            if (this.targetAudienceForm?.form?.valid) {
                this.incrementStep();
                this.targetAudienceForm.form.markAllAsTouched();
                this.stepChecked[0] = true;
            }
            break;
        case 'second':
            if (this.progrmaApproversForm?.form?.valid) {
                this.incrementStep();
                this.progrmaApproversForm.form.markAllAsTouched();
                this.stepChecked[1] = true;
            }
            break;
 
        case 'third':
            if (this.progrmaApproversForm?.form?.valid) {
                this.incrementStep();
                this.progrmaApproversForm.form.markAllAsTouched();
                this.stepChecked[2] = true;
            }
            break;
    }

    // Set previous steps as completed
    for (let i = 0; i < this.activeStepIndex; i++) {
        this.stepChecked[i] = true;
    }

    window.scrollTo(0, 0);
}


setActiveStepIndex(index: number) {
  if (index >= 0 && index < this.stepChecked.length && this.stepChecked[index]) {
      this.activeStepIndex = index;
  }
}


  private incrementStep() {
    if (this.activeStepIndex < 2) {
      this.activeStepIndex++;
    }
  }

  back() {
    if (this.activeStepIndex > 0) {
      this.activeStepIndex--;
    }
  }
  removePill(pillElement: HTMLElement) {
    pillElement.remove();
  }



  


  updateTargetAudienceForm() {
  }
  updateProgrmaApproversForm() {
  }
  updateSelectionProcessForm() {
  }
  updateReferralRulesForm() {
  }



  
  // addBrandUser = (term: any) => ({ name: ''});

  
  eligibleBrandSelection:any;
  eligibleBrand = [
    {id: 1, name: 'Vilnius'},
    {id: 2, name: 'Kaunas'},
    {id: 5, name: 'Kaunas'},
    {id: 9, name: 'Kaunas'},
];


  eligibleCountrySelection:any = null;
  eligibleCountry = [
    {id: 1, name: 'Vilnius'},
    {id: 2, name: 'Kaunas'},
];

  eligibleCampusSelection:any;
  eligibleCampus = [
    {id: 1, name: 'Vilnius'},
    {id: 2, name: 'Kaunas'},
];

  eligibleDepartmentSelection:any;
  eligibleDepartment = [
    {id: 1, name: 'Vilnius'},
    {id: 2, name: 'Kaunas'},
];
programTypeListSelection:any = 1;
  programTypeList = [
    {id: 1, name: 'Monetary Reward'},
    {id: 2, name: 'Kaunas'},
];
currencyListSelection:any = 1;
  currencyList = [
    {id: 1, name: 'INR'},
    {id: 2, name: 'USD'},
];
currencyListSelection1:any = 1;
  currencyList1 = [
    {id: 1, name: 'INR'},
    {id: 2, name: 'USD'},
];

  eligibleRoleSelection:any;
  eligibleRole = [
    {id: 1, name: 'Vilnius'},
    {id: 2, name: 'Kaunas'},
];
minimumTenureSelection:any;
  minimumTenure = [
    {id: 1, name: '1year'},
    {id: 2, name: '2years'},
];
minimumTenureSelectionSlab:any;
  minimumTenureSlab = [
    {id: 1, name: '1year'},
    {id: 2, name: '2years'},
];
refreeTenureSelection:any;
refreeTenure = [
    {id: 1, name: '3 months'},
    {id: 2, name: '6 months'},
];

approverTable: any[] = [
  { level: '1', country: 'Singapore', designation: 'Regional / Country Director',  MemberName: 'Brandon Herwitz', MailID: 'chance324@mail.com' },
  { level: '2', country: 'Singapore', designation: 'Regional HR Team',   MemberName: 'Brandon Herwitz', MailID: 'chance324@mail.com'  },
];
selectionTable: any[] = [
  { level: '1', name: 'Lincoln Westervelt', stage: 'Screening',  template: 'Template 1',cutOffDate:'Aug 20, 2023', interviewType: 'Interview Type' ,groupDiscussion:''},
  { level: '1', name: 'Lincoln Westervelt', stage: 'Screening',  template: 'Template 1',cutOffDate:'Aug 20, 2023', interviewType: 'Interview Type' ,groupDiscussion:''},
  { level: '1', name: 'Lincoln Westervelt', stage: 'Screening',  template: 'Template 1',cutOffDate:'Aug 20, 2023', interviewType: 'Interview Type' ,groupDiscussion:''},
];

visible: boolean = false;

showDialog() {
    this.visible = true;
}




deleteFieldss1(x:any) {
  // console.log(this.referralBonusArr.referralBonusArrItem);
  this.referralBonusArr.referralBonusArrItem.splice(x, 1);
}
addFieldss1(x:any) {
  // console.log(this.referralBonusArr.referralBonusArrItem);
  let obj = {
    bonusFrom:null,
    bonusTo: null,
    amount1: null,
  };

  this.referralBonusArr.referralBonusArrItem.push(obj);
}
deleteFieldss2(y:any) {
  // console.log(this.addBonusArr.addBonusArrItem);
  this.addBonusArr.addBonusArrItem.splice(y, 1);
}
addFieldss2(y:any) {
  // console.log(this.addBonusArr.addBonusArrItem);
  let obj = {
    refreeTenureSelection:null,
    addAmount: null,

  };

  this.addBonusArr.addBonusArrItem.push(obj);
}


visibleSuccess: boolean = false;
approveSuccess: boolean = false;
redirectToListing() {
  window.location.href = '/job-listing';
}

  showSuccess() {
    this.visibleSuccess = true;
  }
  postJob() {
    // Logic to post the job
    // Assuming the job posting is successful, set approveSuccess to true
    this.approveSuccess = true;
  }
}
