<!-- <div class="d-flex dash-dashboardd mt-4 w-100">
<app-header></app-header>

<div class="content-wrapper w-100 pt-5">
    <div class="dashboard-info">
        <router-outlet></router-outlet>
    </div>
</div>
</div>

<app-footer></app-footer> -->
<div class="d-flex mt-4" style="height: 100vh;">
  <div class="col-md-1 pl-0" style="z-index: 222 !important;position: relative;">
    <app-header></app-header>
  </div>
  <div class="col-md-11 pt-46" >
    <router-outlet></router-outlet>
  </div>
</div>
