<main class="work-experience-main">
  <div
    class="app_details_tabs_content tabss__sectioNN__linee mb-2 z9999 stickyy-partt"
  >
    <ul class="stepbox-list d-flex flex-row w-100 ps-0">
      <li
        [ngClass]="{ active: tabb === 'tabb1' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Basic Information
      </li>
      <li
        [ngClass]="{ active: tabb === 'tabb3' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Work Experience
      </li>
      <li
        [ngClass]="{ active: tabb === 'tabb2' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Educational Information
      </li>
      <!-- <li [ngClass]="{ active: tabb === 'tabb4' }"
        class="stepbox-list__item position-relative fw500 text-center pointer">Family Information</li> -->
      <li
        [ngClass]="{ active: tabb === 'tabb5' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Immigration
      </li>
      <!-- <li [ngClass]="{ active: tabb === 'tabb6' }"
        class="stepbox-list__item position-relative fw500 text-center pointer">Declaration</li> -->
      <li
        [ngClass]="{ active: tabb === 'tabb7' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Documents
      </li>
    </ul>
  </div>
  <div class="work-exp-part app_details_tabs_content">
  <form #newworkExperienceForm="ngForm">
    <h1 class="col-md-6 fw600 fs18 pl-0">Work Experience</h1>
    <ng-container
      *ngFor="let x of newNewWorkkInfoo.aanotherIndustries; let i = index"
    >
      <div class="row">
        <div class="col-md-6 mt-3 figma__ng__select">
          <label class="mb-2 figma__label"> Industry </label>
          <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.profileIndustryyTwo"
            name="profileIndustry{{i}}" #profileIndustry="ngModel" placeholder="Enter industry " /> -->

          <ng-select
            class=""
            [items]="indusrtynames"
            bindLabel="industry_Name"
            bindValue="industry_Id"
            name="newAppprofileIndustryy{{ i }}"
            #newAppprofileIndustryy="ngModel"
            placeholder="Select industry"
            [(ngModel)]="x.newAppprofileIndustryyy"
          >
          </ng-select>
        </div>

        <div class="col-md-6 mt-3 figma__ng__select">
          <label class="mb-2 figma__label"> Experience (in years) </label>
          <ng-select
            class=""
            [items]="newAppprofileIndustryExp"
            bindLabel="name"
            bindValue="id"
            name="newAppprofileIndustryExpp{{ i }}"
            #newAppprofileIndustryExpp="ngModel"
            placeholder="Select experience"
            [(ngModel)]="x.newAppprofileIndustryExppp"
          >
          </ng-select>
        </div>

        <div class="industry_plus_btn mt-2 col-md-12">
          <span
            role="button"
            class="add__Text mr-3"
            *ngIf="
              i == newNewWorkkInfoo.aanotherIndustries.length - 1 ||
              newNewWorkkInfoo.aanotherIndustries.length == 1
            "
            (click)="addNewExperienceFieldss(i)"
          >
            + Add
          </span>
          <span
            role="button"
            class="add__Text remove__Text mr-3"
            style="color: rgba(254, 84, 84, 1) !important"
            (click)="DeleteNewExperienceFieldss(i)"
            *ngIf="
              newNewWorkkInfoo.aanotherIndustries.length === i + 1 && i != 0
            "
          >
            Remove
          </span>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngFor="let x of newNewWorkkInfoo.aanotherDomainsss; let i = index"
    >
      <div class="row">
        <div class="col-md-6 mt-3 figma__ng__select">
          <label class="mb-2 figma__label"> Domain </label>
          <ng-select
            class=""
            [items]="Domainnames"
            bindLabel="domain_Name"
            bindValue="domain_Id"
            name="newAppprofileDomainn{{ i }}"
            #newAppprofileDomainn="ngModel"
            placeholder="Select domain"
            [(ngModel)]="x.newAppprofileDomainnn"
          >
          </ng-select>
          <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.profileDoamin"
            name="profileDoaminnn{{i}}" #profileDoaminnn="ngModel" placeholder="Enter domain " /> -->
        </div>

        <div class="col-md-6 mt-3 figma__ng__select">
          <label class="mb-2 figma__label"> Experience (in years) </label>
          <ng-select
            class=""
            [items]="newAppprofileIndustryyExp"
            bindLabel="name"
            bindValue="id"
            name="newAppprofileIndustryyExpp{{ i }}"
            #newAppprofileIndustryyExpp="ngModel"
            placeholder="Select experience"
            [(ngModel)]="x.newAppprofileIndustryyExppp"
          >
          </ng-select>
          <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.profileDoaminExperience"
            name="profileDoaminExperienceee{{i}}" #profileDoaminExperienceee="ngModel"
            placeholder="Enter experience " /> -->
        </div>

        <div class="industry_plus_btn mt-2 col-md-12">
          <span
            role="button"
            class="add__Text mr-3"
            *ngIf="
              i == newNewWorkkInfoo.aanotherDomainsss.length - 1 ||
              newNewWorkkInfoo.aanotherDomainsss.length == 1
            "
            (click)="newAddNewDomainFieldsTwoo(i)"
          >
            + Add
          </span>
          <span
            role="button"
            class="add__Text remove__Text mr-3"
            style="color: rgba(254, 84, 84, 1) !important"
            (click)="newDeleteNewDomainFieldsTwoo(i)"
            *ngIf="
              newNewWorkkInfoo.aanotherDomainsss.length === i + 1 && i != 0
            "
          >
            Remove
          </span>
        </div>
      </div>
    </ng-container>
    <div class="d-md-flex">
      <div class="col-md-6 pl-0 figma__ng__select mt-3">
        <label class="mb-2 figma__label">
          Total Experience (in years) <span class="errorRequired">*</span>
        </label>
        <ng-select
          class=""
          [items]="newProfileTotalExperience"
          bindLabel="name"
          bindValue="id"
          name="newProfileTotalExperiencee"
          #newProfileTotalExperiencee="ngModel"
          placeholder="Select total experience"
          [(ngModel)]="newNewWorkkInfoo.newProfileTotalExperienceee"
          required
        >
        </ng-select>
        <div
          *ngIf="
            (newProfileTotalExperiencee?.touched ||
              newworkExperienceForm.submitted) &&
            newProfileTotalExperiencee.errors?.required
          "
        >
          <span class="errorMessage"> Please select total experience </span>
        </div>
      </div>

      <div class="col-md-6 figma__ng__select mt-3 pr-0">
        <label class="mb-2 figma__label">
          Notice Period (in days) <span class="errorRequired">*</span>
        </label>

        <!-- <input type="text" class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="newNewWorkkInfoo.OrganizationName" name="OrganizationNameee{{i}}"
          #OrganizationNameee="ngModel" placeholder="Enter notice period" /> -->

        <ng-select
          class=""
          [items]="newProfileNoticePeriod"
          bindLabel="name"
          bindValue="name"
          name="newProfileNoticePeriodd"
          #newProfileNoticePeriodd="ngModel"
          placeholder="Select notice period"
          [(ngModel)]="newNewWorkkInfoo.newProfileNoticePerioddd"
          required
        >
        </ng-select>
        <div
          *ngIf="
            (newProfileNoticePeriodd?.touched ||
              newworkExperienceForm.submitted) &&
            newProfileNoticePeriodd.errors?.required
          "
        >
          <span class="errorMessage"> Please select notice period </span>
        </div>
      </div>
    </div>
    <div class="col-md-12 my-4 newProfile_border_bottom"></div>

    <h1 class="fw600 fs18 col-md-12 pl-0">Previous Work History</h1>
    <div class="row">
      <ng-container
        *ngFor="let x of newNewWorkkInfoo.anotherJobb; let i = index"
      >
        <div class="col-md-6 mt-3">
          <label class="mb-2 figma__label"> Organization Name </label>
          <input
            type="text"
            class="col-md-12 figma__Inputs h_42"
            [(ngModel)]="x.newJobOrganizationName"
            name="newJobOrganizationNamee{{ i }}"
            #newJobOrganizationNamee="ngModel"
            placeholder="Enter organization name "
          />
        </div>

        <div class="col-md-6 mt-3 figma__ng__select">
          <label class="mb-2 figma__label"> Designation </label>

          <!-- <ng-select class="" [items]="Allcountry" bindLabel="name" bindValue="id"
            name="profileDesignationnNeww{{i}}" #profileDesignationnNeww="ngModel" placeholder="Select designation"
            [(ngModel)]="x.profileDesignationnNew">
          </ng-select> -->

          <input
            type="text"
            class="col-md-12 figma__Inputs h_42"
            [(ngModel)]="x.profileDesignationnNew"
            name="profileDesignationnNeww{{ i }}"
            #profileDesignationnNeww="ngModel"
            placeholder="Enter designation "
          />
        </div>

        <div class="col-md-6 mt-3 figma__ng__select">
          <label class="mb-2 figma__label"> Country </label>
          <ng-select
            class=""
            (change)="getCampusonCountry($event, x)"
            [items]="Allcountry"
            bindLabel="countryName"
            bindValue="countryId"
            name="addAppnewCountryy{{ i }}"
            #addAppnewCountryy="ngModel"
            placeholder="Select country"
            [(ngModel)]="x.addAppnewCountryyy"
          >
          </ng-select>
          <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.newJobprofileCountry"
            name="newJobprofileCountryy{{i}}" #newJobprofileCountryy="ngModel" placeholder="Enter country" /> -->
        </div>

        <div class="col-md-6 mt-3 figma__ng__select">
          <label class="mb-2 figma__label"> City </label>

          <ng-select
            class=""
            [items]="statecityList"
            bindLabel="cityName"
            bindValue="id"
            name="newjobprofileCityy{{ i }}"
            #newjobprofileCityy="ngModel"
            placeholder="Select city"
            [(ngModel)]="x.newjobprofileCity"
          >
          </ng-select>

          <!-- <input type="text" class="col-md-12 figma__Inputs h_42" [(ngModel)]="x.newjobprofileCity"
            name="newjobprofileCityy{{i}}" #newjobprofileCityy="ngModel" placeholder="Enter city " /> -->
        </div>

        <div class="col-md-6 figma__ng__select new__Phoe__part mt-3">
          <label class="mb-2 figma__label">
            Current Salary (Monthly)
            <span *ngIf="!showHideSalary" class="errorRequired">*</span>
          </label>
          <div class="d-flex align-items-center">
            <ng-select
              style="width: 70px"
              class=""
              [items]="contrysymbol"
              bindLabel="currencycode"
              bindValue="currencycode"
              name="newJobProfileSalaryItemss{{ i }}"
              #newJobProfileSalaryItemss="ngModel"
              placeholder="INR"
              [(ngModel)]="x.newJobProfileSalaryItemsss"
            >
            </ng-select>
            <input
              onkeypress="return /[0-9]/i.test(event.key)"
              [(ngModel)]="x.newJobProfileSalaryInput"
              #newJobProfileSalaryInputt="ngModel"
              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
              class="w-100 figma__Inputs"
              type="text"
              name="newJobProfileSalaryInputt{{ i }}"
              placeholder="Enter current salary"
            />
          </div>
        </div>

        <div class="col-md-6 figma__ng__select new__Phoe__part mt-3">
          <label class="mb-2 figma__label">
            Total Variable (Per Annum)
            <span *ngIf="!showHideSalary" class="errorRequired">*</span>
          </label>
          <div class="d-flex">
            <ng-select
              style="
                width: 70px !important;
                border-radius: 8px 0 0 8px !important;
              "
              [items]="contrysymbol"
              bindLabel="currencycode"
              bindValue="currencycode"
              name="newAppTotalVariablee{{ i }}"
              #newAppTotalVariablee="ngModel"
              placeholder="INR"
              [(ngModel)]="x.newJobProfileSalaryItemsss"
            >
            </ng-select>
            <input
              style="border-radius: 0 8px 8px 0 !important"
              onkeypress="return /[0-9]/i.test(event.key)"
              [(ngModel)]="x.newJobTotalVariable"
              #newJobTotalVariablee="ngModel"
              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
              class="w-100 figma__Inputs"
              type="text"
              name="newJobTotalVariablee{{ i }}"
              placeholder="Enter total variable"
            />
          </div>
        </div>

        <div class="col-md-6 mt-3 figma_calender_input">
          <div class="d-md-flex">
            <div class="position-relative calender__ml col-md-6 pl-0">
              <label class="mb-2 figma__label"> From </label>
              <p-calendar
                class=""
                name="newJobProfileDurationOnee{{ i }}"
                placeholder="DD/MM/YY"
                [(ngModel)]="x.newJobProfileDurationOne"
                #newJobProfileDurationOnee="ngModel"
                [showIcon]="true"
              ></p-calendar>
            </div>
            <div class="position-relative calender__ml col-md-6 pr-0">
              <label class="mb-2 figma__label"> To </label>
              <p-calendar
                class=""
                name="newJobProfileDurationTwoo{{ i }}"
                placeholder="DD/MM/YY"
                [(ngModel)]="x.newJobProfileDurationTwo"
                #newJobProfileDurationTwoo="ngModel"
                [showIcon]="true"
              ></p-calendar>
            </div>
          </div>
        </div>

        <div class="d-flex mt-3 col-md-12 align-items-center">
          <input
            class="accent-color"
            type="checkbox"
            [(ngModel)]="x.ThisISCurrentJobbb"
            name="NewAppThisISCurrentJobbb{{ i }}"
            id="newid{{ i }}"
            (click)="enableSalaryInputss()"
          />
          <label
            class="ml-1 figma__label"
            style="margin-bottom: 0px !important"
          >
            This is my current job
          </label>
        </div>

        <div
          class="industry_plus_btn mt-5 col-md-12"
          style="margin-top: 33px !important"
        >
          <button
            role="button"
            class="secondary-btn mr-3"
            *ngIf="
              i == newNewWorkkInfoo.anotherJobb.length - 1 ||
              newNewWorkkInfoo.anotherJobb.length == 1
            "
            (click)="newJobAddAnotherJob(i)"
          >
            + Add another job
          </button>
          <button
            role="button"
            class="discard_red_button mr-3"
            style="
              color: rgba(254, 84, 84, 1) !important;
              border: 1.5px solid #fe5454;
            "
            (click)="newJobRemoveAnotherJob(i)"
            *ngIf="newNewWorkkInfoo.anotherJobb.length === i + 1 && i != 0"
          >
            Remove
          </button>
        </div>
      </ng-container>
    </div>

    <h1 class="fw600 fs18 col-md-12 pl-0 mt-4">My References</h1>
    <div class="row mt-2">
      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label"> Organization Name </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="newNewWorkkInfoo.profileOrganizationname"
          name="profileOrganizationnamee"
          #profileOrganizationnamee="ngModel"
          placeholder="Enter organization name "
        />
      </div>

      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label"> Referee Name </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="newNewWorkkInfoo.profileRefereennName"
          name="profileRefereennNameee"
          #profileRefereennNameee="ngModel"
          placeholder="Enter referee name "
        />
      </div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> Referee Position </label>
        <!-- <ng-select class="" [items]="newProfileRefereePosition" bindLabel="name" bindValue="id"
          name="newProfileRefereePositionn" #newProfileRefereePositionn="ngModel"
          placeholder="Select position" [(ngModel)]="newNewWorkkInfoo.newProfileRefereePositionnn">
        </ng-select> -->
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="newNewWorkkInfoo.newProfileRefereePositionnn"
          bindLabel="name"
          bindValue="id"
          name="newProfileRefereePositionn"
          #newProfileRefereePositionn="ngModel"
          #profileRefereennNameee="ngModel"
          placeholder="Enter Referee Position "
        />
      </div>

      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label"> Email Address </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="newNewWorkkInfoo.newProfileEmailAddress"
          name="newProfileEmailAddresss"
          #newProfileEmailAddresss="ngModel"
          placeholder="Enter email address"
        />
      </div>

      <div class="col-md-6 new__Phoe__parttttttt figma__ng__select mt-3">
        <label class="mb-2 figma__label"> Phone No </label>
        <div class="d-flex align-items-center">
          <ng-select
            style="width: 70px"
            class=""
            [items]="Allcountry"
            [(ngModel)]="newNewWorkkInfoo.countrycode"
            bindLabel="countryCode"
            bindValue="countryCode"
            name="NewAppCodeTwoo"
            #NewAppCodeTwoo="ngModel"
            placeholder="+91"
            (change)="disabledNumberInput('one')"
          >
          </ng-select>
          <input
            onkeypress="return /[0-9]/i.test(event.key)"
            [(ngModel)]="newNewWorkkInfoo.RefPhoneNumber"
            #RefPhoneNumberr="ngModel"
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
            class="w-100 figma__Inputs"
            type="text"
            name="RefPhoneNumberr"
            placeholder="Enter phone number"
            [disabled]="phoneNumberdisabledByCode"
            style="
              border-left: 1px solid rgb(0, 0, 0, 0.3) !important;
              border-radius: 0 8px 8px 0 !important;
              height: 42px !important;
            "
          />
        </div>
      </div>

      <div class="col-md-12 mt-4">
        <button type="button" class="secondary-btn" (click)="workBack()">
          <i class="icon-angle-down angle-rotate-d"></i> Back
        </button>
        <button
          type="button"
          class="figma_background_blue_btn float-right"
          style="padding: 12px 42px"
          (click)="workNext()"
        >
          Save & Next
        </button>
      </div>
    </div>
  </form>
</div>
</main>
