
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class userService {


    headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache');;

    public loginUserId: any;
    public newUserId: any;
    public userData: any;
    public getScheduleLogin:any ;

    constructor(private http: HttpClient) {
        let user = localStorage.getItem('userDetails');
        // if (user != null) {
        //     let userDetails = JSON.parse(user);
        //     this.userData = userDetails;
        //     let userId = userDetails.userId.toString();
        //     this.newUserId = userId;
        //     this.headers = this.headers.append('userId', this.newUserId);
        // }

        if (user != null) {
            this.userData = jwtDecode(user)
            this.newUserId = this.userData.UserId
            this.headers = this.headers.append('userId', this.newUserId);

        }
    }

    getUserDetails(pageSize, pageNumber) {
        return this.http.get(`${environment.apiUrl}/api/User/GetUsers?PagesizePerPage=${pageSize}&pageNumber=${pageNumber}`, { headers: this.headers });
    }

    createUserData(data) {
        return this.http.post(`${environment.apiUrl}/api/User/CreateUserInfo`, data, { headers: this.headers });
    }
    getUserInfoById(id) {
        return this.http.get(`${environment.apiUrl}/api/User/GetUserInfoById?userId=${id}`, { headers: this.headers })
    }
    deleteUserById(data) {
        return this.http.get(`${environment.apiUrl}/api/User/DeleteUser?userId=${data}`, { headers: this.headers })
    }

    createUserLogin(logindata) {
        return this.http.post(`${environment.apiUrl}/api/User/LoginUser`, logindata);
    }
    createCandidateLogin(logindata) {
        let userId: any = "0";
        // this.headers.delete('userId');
        this.headers = this.headers.delete('userId');
        let headers = this.headers.append('userId', userId);
        return this.http.post(`${environment.apiUrl}/api/Candidate/LoginCandidate`, logindata, { headers: headers });
    }
    setUserId(data) {
        localStorage.setItem("userDetails", JSON.stringify(data));
        this.loginUserId = data
    }

    setAccessUserId(data) {
        localStorage.setItem("accessToken", JSON.stringify(data));
        this.loginUserId = data
    }

    getUserId() {
        let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        this.loginUserId = userDetails;
        return this.loginUserId
    }

    getAccessUserId() {
        let userDetails = JSON.parse(localStorage.getItem("accessToken"));
        this.loginUserId = userDetails;
        return this.loginUserId
    }

    // userSerach(pagesize, pageno, userName) {
    //     return this.http.get(`${environment.apiUrl}/api/User/GetUsersbySearch?PagesizePerPage=${pagesize}&pageNumber=${pageno}&userName=${userName}`,
    //         { headers: this.headers });
    // }
    userSerach(pagesize, pageno, userName) {
        return this.http.get(`${environment.apiUrl}/api/User/UsersSearch?PagesizePerPage=${pagesize}&pageNumber=${pageno}&searchitem=${userName}`,
            { headers: this.headers });
    }

    userEmail(emailId) {
        return this.http.get(`${environment.apiUrl}/api/User/ValidateEmailId?mailId=${emailId}`, { headers: this.headers });
    }

    userPhone(phoneNo) {
        return this.http.get(`${environment.apiUrl}/api/User/ValidatePhoneNo?phoneNo=${phoneNo}`, { headers: this.headers });
    }

    getRoleforUser(id, userId) {
        return this.http.get(`${environment.apiUrl}/api/User/GetRoleforUser?depId=${id}&userId=${userId}`, { headers: this.headers });
    }

    getDepartmentforRole(userId) {
        return this.http.get(`${environment.apiUrl}/api/User/GetDeptforUser?userId=${userId}`, { headers: this.headers });

    }
    updateStatus(value, userId, comment) {
        return this.http.get(`${environment.apiUrl}/api/User/UpdateUserstatus?userId=${userId}&userStatus=${value}&comments=${comment}`, { headers: this.headers });

    }

    getUserPermission() {
        return this.userData;
    }

    newpassword(data) {
        return this.http.post(`${environment.apiUrl}/api/User/ForgotPassword`, data, { headers: this.headers });
    }

    existpasswordreset(data) {
        return this.http.post(`${environment.apiUrl}/api/User/ConfirmPassword`, data, { headers: this.headers });
    }

    getPersetPermission() {
        return this.http.get(`${environment.apiUrl}/api/Role/GetPreSetPermissions?userId=${this.newUserId}`, { headers: this.headers });
    }

    createFilter(data) {
        return this.http.post(`${environment.apiUrl}/api/User/GetUsersAddfilter`, data, { headers: this.headers });
    }

    candidateNewPassword(data) {
        return this.http.get(`${environment.apiUrl}/api/Candidate/ForgetPassword?mailId=${data}`, { headers: this.headers });
    }

    candidateResetPassword(data) {
        return this.http.post(`${environment.apiUrl}/api/Candidate/ConfirmPassword`, data, { headers: this.headers });

    }

    createUser(data) {
        return this.http.post(`${environment.apiUrl}/api/User/CreateUserInfo`, data, { headers: this.headers });
        // return this.http.post(`http://18.142.45.251:8080/giis-career/career/user-registration`, data, { headers: this.headers })
    }

    setScheduleLogin(data){
        this.getScheduleLogin = data;
    }

    getScheduleLoginData(){
        return this.getScheduleLogin;
    }

    createHiringLogin(userName,password){
        return this.http.get(`${environment.apiUrl}/api/Jobs/GetHiringDetailsByuser?username=${userName}&passward=${password}`, { headers: this.headers });
    }
    GetUserFullName(userData){
        return this.http.get(`${environment.apiUrl}/api/User/GetUserFullName?username=${userData}`, { headers: this.headers });
    }




}
