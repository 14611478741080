// import { JahModule } from './jah/jah.module';

import { CommonModule, PathLocationStrategy } from '@angular/common';
import { LocationStrategy } from '@angular/common';
import { SystemFieldsComponent } from './system-fields/system-fields.component';
// import { ChartModule } from 'primeng/chart';

import { ChartsComponent } from './charts/charts.component';
import { AddApplicantComponent } from './add-applicant/add-applicant.component';
import { JAHComponent } from './jah/jah.component';
import { TrfComponent } from './trf/trf.component';
import { TrfFormComponent } from './trf-form/trf-form.component';
import { TrfFormTwoComponent } from './trf-form-two/trf-form-two.component';
// import { InterviewsComponent } from './interviews/interviews.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DigitDecimalDirective } from './directives/digit-decimal.directive';
import { AdminComponent } from './admin/admin.component';
import { AdminDataComponent } from './admin-data/admin-data.component';
import { ReportsTwoComponent } from './reports-two/reports-two.component';
import { WorkExperienceComponent } from './work-experience/work-experience.component';
import { EducationalInfoComponent } from './educational-info/educational-info.component';
import { BasicInformationComponent } from './basic-information/basic-information.component';
import { ImmigrationComponent } from './immigration/immigration.component';
import { DocumentsComponent } from './documents/documents.component';
import { NewDashboardComponent } from './new-dashboard/new-dashboard.component';

// import { NgxDonutChartModule } from 'ngx-doughnut-chart';
// import { MatPaginator } from '@angular/material/paginator';
// import { MatTableDataSource } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from '../app/layout/header/header.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BadgeModule } from 'primeng/badge';
import { MainBodyComponent } from './components/main-body/main-body.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { SideBodyComponent } from './components/side-body/side-body.component';
import { AvatarModule } from 'primeng/avatar';

import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AccordionModule } from 'primeng/accordion';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { JobListingComponent } from './components/job-listing/job-listing.component';
import { BasicJobDetailsComponent } from './components/side-body-components/basic-job-details/basic-job-details.component';
import { JobDescriptionComponent } from './components/side-body-components/job-description/job-description.component';
import { JobApproversComponent } from './components/side-body-components/job-approvers/job-approvers.component';
import { HiringTeamComponent } from './components/side-body-components/hiring-team/hiring-team.component';
import { DialogModule } from 'primeng/dialog';
// import { DashboardComponent, DashboardReportsComponent } from './components/side-body-components/dashboard-reports/dashboard.component';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';


import { ChartModule } from 'primeng/chart';
import { NgChartsModule } from 'ng2-charts';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';


import { CreateJobPostComponent } from './components/create-job-post/create-job-post.component';

import { JobPostDescriptionComponent } from './components/job-post-description/job-post-description.component';
import { CreateJobApproverComponent } from './components/create-job-approver/create-job-approver.component';
import { CreateJobHiringteamComponent } from './components/create-job-hiringteam/create-job-hiringteam.component';
import { PopUpComponent } from './components/shared/pop-up/pop-up.component';
import { PaginatorModule } from 'primeng/paginator';
import { StepsModule } from 'primeng/steps';
import { StepperComponent } from './components/shared/stepper/stepper.component';
import { DropdownModule } from 'primeng/dropdown';
import { LoginComponent } from './components/login/login.component';
import { Dashboard2Component } from './components/side-body-components/dashboard2/dashboard2.component';
import { ReferalJobDescriptionComponent } from './components/referal-job-description/referal-job-description.component';
import { ReferalFriendFormComponent } from './components/referal-friend-form/referal-friend-form.component';
import { ExternalReferrerDetailsComponent } from './components/external-referrer-details/external-referrer-details.component';
import { InternalStaffSharedApplicationComponent } from './components/internal-staff-shared-application/internal-staff-shared-application.component';
// import { NgOtpInputModule } from  'ng-otp-input';

import { NgxDropzoneModule } from 'ngx-dropzone';
// import { AddNewApplicantComponent } from './components/add-new-applicant/add-new-applicant.component';
import { InternalJobPostingComponent } from './components/internal-job-posting/internal-job-posting.component';
import { CreateJobLayoutComponent } from './components/create-job-layout/create-job-layout.component';
import { ReferralComponent } from './components/referral/referral.component';
import { JobDetailsPageComponent } from './components/job-details-page/job-details-page.component';
import { DummyChartsComponent } from './dummy-charts/dummy-charts.component';
import { NewApplicantComponent } from './components/new-applicant/new-applicant.component';
import { DashboardReportsComponent } from './components/side-body-components/dashboard-reports/dashboard.component';
import { IjpEmployeeComponent } from './components/ijp-employee/ijp-employee.component';
import { SkeletonModule } from 'primeng/skeleton';
@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
    // HariTestComponent,
    SystemFieldsComponent,
    ChartsComponent,
    AddApplicantComponent,
    JAHComponent,
    TrfComponent,
    TrfFormComponent,
    TrfFormTwoComponent,
    DigitDecimalDirective,
    AdminComponent,
    AdminDataComponent,
    ReportsTwoComponent,
    WorkExperienceComponent,
    EducationalInfoComponent,
    BasicInformationComponent,
    ImmigrationComponent,
    DocumentsComponent,
    NewDashboardComponent,
    // HeaderComponent,
    MainBodyComponent,
    SideNavComponent,
    SideBodyComponent,
    JobListingComponent,
    BasicJobDetailsComponent,
    JobDescriptionComponent,
    JobApproversComponent,
    HiringTeamComponent,
  // DashboardReportsComponent,
    CreateJobPostComponent,
    JobPostDescriptionComponent,
    CreateJobApproverComponent,
    CreateJobHiringteamComponent,
    PopUpComponent,
    StepperComponent,
    LoginComponent,
    Dashboard2Component,
    ReferalJobDescriptionComponent,
    ReferalFriendFormComponent,
    ExternalReferrerDetailsComponent,
    InternalStaffSharedApplicationComponent,
    // AddNewApplicantComponent,
    InternalJobPostingComponent,
    CreateJobLayoutComponent,
    ReferralComponent,
    JobDetailsPageComponent,
    DummyChartsComponent,
    // InterviewsComponent,
    NewApplicantComponent,
    DashboardReportsComponent,
    IjpEmployeeComponent,

  ],
  imports: [
    SkeletonModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // DashboardModule,
    ToastrModule.forRoot({
      // Toast configuration options here
    }),
    AccordionModule,
    BrowserAnimationsModule,
    CalendarModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    ChartModule,
    EditorModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgxEditorModule,
    ReactiveFormsModule,
    NgSelectModule,
    BadgeModule,
    AvatarModule,
    ButtonModule,
    SidebarModule,
    BreadcrumbModule,
    AccordionModule,
    EditorModule,
    CalendarModule,
    TooltipModule,
    DialogModule,
    ChartModule,
    NgChartsModule,
    TabViewModule,
    CheckboxModule,
    ToastModule,
    TableModule,
    // NgOtpInputModule,
    HttpClientModule,
    PaginatorModule,
    StepsModule,
    DropdownModule,
    NgxDropzoneModule,


    // CKEditorModule,
    // MatPaginator,
    // MatTableDataSource,
  ],
  // providers: [{ provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],

  // exports: [HeaderComponent],


  bootstrap: [AppComponent],
})
export class AppModule {}
