// stepper.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepperService {
  private activeIndexSubject = new BehaviorSubject<number>(0);
  activeIndex$ = this.activeIndexSubject.asObservable();

  constructor() { }

  setActiveIndex(index: number) {
    this.activeIndexSubject.next(index);
  }
}
