<main class="job-approvers-main">
  <div class="basic-info-line-part white-background p16 br8 d-flex align-items-center justify-content-between">
    <h1 class="blue-color fs14 fw600">Step 3 - Job Approvers</h1>
    <div class="d-flex align-items-center">
      <h2>Marketing Manager</h2>
      <div class="job-id-part d-flex align-items-center gap16">
        <img src="./assets/Images/approval.svg" alt="approval" />
        <h3>Job ID- A01232</h3>
      </div>
    </div>
  </div>
  <main class="job-app-table-main mt-3">
    <table class="table figma-table">
      <thead>
        <tr>
          <th scope="col" class="fw600 fs14 py-4">Level
            <img class="ms-2" src="../../../../assets/Images/sort-icons.svg" alt="sort iocns" /> </th>
          <th scope="col" class="fw600 fs14 py-4">Approvers Designation
            <img class="ms-2" src="../../../../assets/Images/sort-icons.svg" alt="sort iocns" /> </th>
          <th scope="col" class="fw600 fs14 py-4">Member Name/ Mail ID
            <img class="ms-2" src="../../../../assets/Images/sort-icons.svg" alt="sort iocns" /> </th>
        </tr>
      </thead>
      <div style="border-bottom: 8px solid transparent;"></div>
      <tbody *ngFor="let x of approversTableData; let i = index">
        <tr>
          <td scope="row" class="fw400 fs14 py-3 ps-3"> {{i+1}} </td>
          <td class="fw400 fs14 py-3"> {{x.position}} </td>
          <td class="fw400 fs14 py-3">
            <h5 class="fs14 fw400"> {{x.name}} </h5>
            <h6 class="grey fw500 fs10 mt-2"> {{x.email}} </h6>
          </td>
        </tr>
        <div style="border-bottom: 1px solid #F5F5F5;"></div>
      </tbody>
    </table>
    <div class="type-of-hiring-card white-background p16 br8 my-3 gap16 d-flex justify-content-end">
      <button class="secondary-btn" routerLink="../job-description">previous</button>
      <button class="blue-btn" routerLink="../hiring-team">Next</button>
    </div>
  </main>
</main>
