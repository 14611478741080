import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class AppSettingsService {

    headerVisible: boolean;
    footerVisible: boolean;
    menuVisible: boolean;
    imageUrl: string = "";
    //router: Router
    showHeader: boolean = true;
    userId: any;
    constructor() {
        this.headerVisible = true;
        this.footerVisible = true;
        this.menuVisible = true;



        // this.router = this.r
        // if (!(environment.production)) {
        //     this.imageUrl = "../../../assets"
        // }
        // else {
        //     this.imageUrl = "./assets"
        // }

        this.imageUrl = "./assets"
        console.log("Production imageUrl", this.imageUrl)


        let rout: any = window.location.href;
        let data = rout.split('/').pop();
        if (data == '') {
            this.showHeader = false;
        }
    }

    // getUserDetails() {
    //     let userInfo = this.userSerive.getUserId();
    //     this.userName = userInfo.userFullName;
    //     this.image = userInfo.userPhotoPath;
    // }

    setUserId(data) {
        localStorage.setItem("userDetails", JSON.stringify(data));
    }

    getUserId() {
        if (!localStorage.getItem('reload')) {
            localStorage['reload'] = true;
            window.location.reload();
        } else {

            // If there exists a 'reload' item
            // then clear the 'reload' item in
            // local storage
            localStorage.removeItem('reload');
        }
        let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        if (userDetails) {
            this.userId = userDetails.userId;
        }

        return userDetails
    }
    getIsCandidateLogin() {
        let userDetails = JSON.parse(localStorage.getItem("userDetails"));
        return userDetails?.isCandidate
    }
    removeUser() {
        localStorage.removeItem("userDetails");
    }

}
