<div class="create-job-head d-flex justify-content-between align-items-center mb-4">
  <div class="d-xl-flex d-md-block align-items-center w-50">
    <div class="backbuton">
      <img (click)="gotojobListingpage()" src="./assets/Images/create-arrow.svg" alt="">
      <span class="backtolistpage fs16 fw700">Create Job Posting</span>
    </div>
    <div class="d-flex align-items-center m-auto">
      <span class="fs14 fw600 blue-color mr-2 pointer">Basic</span> <img src="../../../assets/Images/arrow-right-blue.svg" alt="right-arrow" class="mx-2"><span class="fs14 fw600 faded-grey ml-2"> Website</span>
    </div>
  </div>
    <div class="d-flex align-items-center">
      <button class="grey-add-btn"><img src="../../../assets/Images/add-grey.svg" alt="add" class="mx-2">Add Campaign</button>
      <img src="../../../assets/Images/rotate-left.svg" alt="reload" class="pointer primary-border p-2 mx-2">
      <img src="../../../assets/Images/message-text.svg" alt="message" class="pointer primary-border p-2 ml-2">
    </div>
  </div>
  <div class="create-job-body">
    <p-accordion class="w-full" [(activeIndex)]="activeIndex">
        <p-accordionTab header="1. Basic Job Details">
          <!-- (nextClicked)="onNextClicked()" -->
            <app-create-job-post ></app-create-job-post>
        </p-accordionTab>
        <!-- <p-accordionTab header="2. Internal Job Posting">
            <app-internal-job-posting></app-internal-job-posting>
        </p-accordionTab> -->
        <!-- <p-accordionTab header="3. Referral">
            <app-referral></app-referral>
        </p-accordionTab> -->
    </p-accordion>
</div>

