<!-- main-content-->
<div class="content-wrapper add_candidate add_applicant__main">
    <!--START: BANNER-->
    <!-- Banner-->
    <section>
      <div class="msform__container">
        <div class="col-md-12 pb-md-2 mb-1">
          <a class="gs-back-head-d applicant__backK" (click)="back()"><span
              class="btn btn--primary-d js-prev previous second__Back back__general__info"><i
                class="icon-angle-down angle-rotate-d"></i>Back to
              Applicant</span></a>
        </div>

        <h1 class="h4 ml-4 mb-md-4">Add Applicant</h1>
      </div>
    </section>
    <section id="msform">
      <div class="msform__container">
        <div class="row">
          <!--Side-panel-->
          <div class="col-md-4 col-xl-3 js-side-filter sticky-left">
            <div class="gs-side-filter">
              <ul class="gs-side-filter__list gs-long-list edit-item" id="progressbar">
                <li class="gs-side-filter__list__item active gs-long-list__item js-tab-sec edit-side-items" [ngClass]="{
                  active: sideButtonEnable,
                  check: work_SideButton
                }" data-id="general-info" (click)="sideNavClick('personalDetails')">
                  <div class="gs-side-filter__link gs-side-filter__link--sm">
                    <span>Personal Details</span>
                  </div>
                </li>
                <li class="gs-side-filter__list__item gs-long-list__item js-tab-sec edit-side-items" [ngClass]="{
                    active: workSideButton,
                    check: education_SideButton
                  }" data-id="work-history" (click)="sideNavClick('workHistory')">
                  <div class="gs-side-filter__link gs-side-filter__link--sm">
                    <span>Work History</span>
                  </div>
                </li>
                <li class="gs-side-filter__list__item gs-long-list__item js-tab-sec edit-side-items" [ngClass]="{
                    active: educationSideButton,
                    check: family_SideButton
                  }" data-id="educational-information" (click)="sideNavClick('education')">
                  <div class="gs-side-filter__link gs-side-filter__link--sm">
                    <span>Educational Information</span>
                  </div>
                </li>
                <li class="gs-side-filter__list__item gs-long-list__item js-tab-sec edit-side-items" [ngClass]="{
                    active: generalSideButton,
                    check: General_SideButton
                  }" data-id="educational-information" (click)="sideNavClick('generalInfo')">
                  <div class="gs-side-filter__link gs-side-filter__link--sm">
                    <span>General Information</span>
                  </div>
                </li>
                <li class="gs-side-filter__list__item gs-long-list__item js-tab-sec edit-side-items" [ngClass]="{
                    active: declareSideButton,
                    check: declare_SideButton
                  }" data-id="others" (click)="sideNavClick('decleration')">
                  <div class="gs-side-filter__link gs-side-filter__link--sm">
                    <span>Declarations</span>
                  </div>
                </li>
                <li class="gs-side-filter__list__item gs-long-list__item js-tab-sec edit-side-items"
                  [ngClass]="{ active: docSideButton, check: doc_SideButton }" data-id="others"
                  (click)="sideNavClick('docs')">
                  <div class="gs-side-filter__link gs-side-filter__link--sm">
                    <span>Documents</span>
                  </div>
                </li>
                <li class="gs-side-filter__list__item gs-long-list__item js-tab-sec edit-side-items" [ngClass]="{
                    active: familySideButton,
                    check: other_SideButton
                  }" data-id="family-info" (click)="sideNavClick('familyInfo')">
                  <div class="gs-side-filter__link gs-side-filter__link--sm">
                    <span>Family Information</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <form class="col-md-8 col-xl-9 addCondidate" novalidate id="general-information" #basicInfo="ngForm"
            *ngIf="showGeneralInfo">
            <div class="gs-steps-container pb-5 ml-0">
              <!--START: GENERAL INFORMATION-->
              <div class="steps-form ui form item-show itemActive add-steps-container" id="general-info">
                <div class="gs-panel add__NEWCondidate">
                  <!--Panel-header-->
                  <div class="gs-panel__header">
                    <h5 class="gs-form-panel__heading mb-0 main_text_applicant">
                      Personal Details
                    </h5>
                  </div>
                  <!--Panel-header-->
                  <div class="gs-form-panel gs-form-panel--sm pb-0">
                    <div class="gs-steps-categ">
                      <h6 class="gs-panel__subheading">
                        1. Personal details
                      </h6>
                      <div class="row">
                        <!-- <div class="col-md-4 col-sm-4 field gs-form-group">
                          <label class="form-label form-label-clr">Your Photo</label> -->
                        <!-- <img *ngIf="id && hideImage && basicInfoData.candidatePhotoPath" class="imp1"
                            src="{{path}}{{basicInfoData.candidatePhotoPath}}">
                          <img *ngIf="id && hideImage  && basicInfoData.candidatePhotoPath == null" class="imp1"> -->
                        <!-- <ngx-dropzone *ngIf="uploadImage" [multiple]="false" name="candidatePhoto"
                            class="file-upload-wrp" [expandable]="true" [accept]="'image/png,image/jpeg'"
                            accept="image/jpeg,image/jpg,image/png" required>
                            <ngx-dropzone-label> <img class="mb-md-3" src="./assets/img/upload-resume.png"
                                alt="File upload icon">
                              <p class="drpFile"> Drop your file </p>
                              <span class="drpFile">(JPG and PNG)</span><br>
                              <p class="bf1">Browse files </p>
                              <p class="drpFile">Max Acceptable <= 2MB</p>
                            </ngx-dropzone-label>
                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                              ngProjectAs="ngx-dropzone-preview" *ngFor="let f of profileFiles" [removable]="true"
                              (removed)="onProfileRemove(f)" [file]="f" style="margin:0px !important;">

                            </ngx-dropzone-image-preview>
                          </ngx-dropzone> -->
                        <!-- <ngx-dropzone (change)="onSelect($event)">
                            <ngx-dropzone-label>
                              <img class="mb-md-3" src="./assets/img/upload-resume.png" alt="File upload icon">
                              <p class="drpFile">Drop your file (pdf) or </p>
                              <p class="bf1">Browse files </p>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview style="margin-left: 100px !important" *ngFor="let f of files"
                              [removable]="true" (removed)="onRemove(f)">
                              <ngx-dropzone-label style="margin-left: 150px !important; font-size: 11px !important;">{{
                                f.name }} ({{ f.type }})</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                          </ngx-dropzone>
                          <div *ngIf="showPhotoMaxLimit">
                            <span class="errorMessage">
                              Max upload limit is 2MB
                            </span>
                          </div>
                        </div> -->
                        <!-- <div class="col-md-4 col-sm-4 field gs-form-group">
                          <label class="form-label form-label-clr">Your Photo</label>
                          <img *ngIf="id && hideImage && basicInfoData.candidatePhotoPath" class="imp1"
                            src="{{path}}{{basicInfoData.candidatePhotoPath}}">
                          <img *ngIf="id && hideImage  && basicInfoData.candidatePhotoPath == null" class="imp1">
                          <ngx-dropzone *ngIf="uploadImage" [multiple]="false" name="candidatePhoto"
                            class="file-upload-wrp" [expandable]="true" [accept]="'image/png,image/jpeg'"
                            accept="image/jpeg,image/jpg,image/png" required>
                            <ngx-dropzone-label> <img class="mb-md-3" src="./assets/img/upload-resume.png"
                                alt="File upload icon">
                              <p class="drpFile"> Drop your file </p>
                              <span class="drpFile">(JPG and PNG)</span><br>
                              <p class="bf1">Browse files </p>
                              <p class="drpFile">Max Acceptable <= 2MB</p>
                            </ngx-dropzone-label>
                            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                              ngProjectAs="ngx-dropzone-preview" *ngFor="let f of profileFiles" [removable]="true"
                              (removed)="onProfileRemove(f)" [file]="f" style="margin:0px !important;">

                            </ngx-dropzone-image-preview>
                          </ngx-dropzone>
                          <div *ngIf="showPhotoMaxLimit">
                            <span class="errorMessage">
                              Max upload limit is 2MB
                            </span>
                          </div>
                        </div> -->

                        <!-- <button *ngIf="id && hideImage && basicInfoData.candidatePhotoPath"
                            class="btn btn--primary-d em15" style="margin-left: 20px;
                          margin-bottom: 15px;" type="button" value="" data-toggle="modal" data-target=""
                            (click)="showUpload()">Edit
                            Photo</button>
                          <button *ngIf="id && hideImage && basicInfoData.candidatePhotoPath == null"
                            class="btn btn--primary-d em15" style="margin-left: 20px;
                          margin-bottom: 15px;" type="button" value="" data-toggle="modal" data-target=""
                            (click)="showUpload()">Edit
                            Photo</button> -->

                        <!-- <div class="col-md-3"></div>
                        <div class="col-md-3"></div> -->

                        <div class="col-md-4">
                          <label class="form-label form-label-clr">Upload Resume <span
                              class="errorRequired">*</span></label>
                          <input type="file" name="UploadREsume" class="uploadResumeInput" />
                        </div>
                        <div class="col-md-3"></div>
                      </div>
                      <div class="row mt-4">
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Full Name<span class="errorRequired">*</span></label>
                          <input class="form-input form-input_1" type="text" #fullName="ngModel" id="fullName"
                            [(ngModel)]="basicInfoData.fullName" pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$" name="fullName"
                            placeholder="Enter full name" />
                          <div *ngIf="fullName.errors">
                            <span class="errorMessage" *ngIf="fullName.errors?.pattern">
                              Invalid full name
                            </span>
                          </div>
                          <div *ngIf="
                              (fullName.touched || basicInfo.submitted) &&
                              fullName.errors?.required
                            ">
                            <span class="errorMessage">
                              Please enter full name
                            </span>
                          </div>
                        </div>

                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Email Address<span
                              class="errorRequired">*</span></label>
                          <input class="form-input form-input_1" type="text" name="emailAddress" id="emailAddress"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" #emailAddress="ngModel"
                            [(ngModel)]="basicInfoData.emailAddress" placeholder="Enter email address" />
                          <div *ngIf="emailAddress.errors">
                            <span class="errorMessage" *ngIf="emailAddress.errors?.pattern">
                              Invalid email
                            </span>
                          </div>
                          <div *ngIf="
                              (emailAddress.touched || basicInfo.submitted) &&
                              emailAddress.errors?.required
                            ">
                            <span class="errorMessage">
                              Please enter email address
                            </span>
                          </div>
                        </div>

                        <div class="col-md-4 field numberField">
                          <label class="form-label form-label-clr">Phone Number<span
                              class="errorRequired">*</span></label>

                          <div class="gs-labeled-drodown new-PHOne__part new__arrow__weiGht">
                            <ng-select [items]="basicPhone" placeholder="+91" bindLabel="name" bindValue="id"
                              [(ngModel)]="selectbasicPhone" name="phoneNumBERCodee">
                            </ng-select>
                            <input class="form-input labeled-input salary-rang form-input_1" type="text"
                              pattern="^[789]\d{9}$" onkeypress="return /[0-9]/i.test(event.key)" name="phoneNumber"
                              id="phoneNumber" maxlength="10" #phoneNumber="ngModel"
                              [(ngModel)]="basicInfoData.phoneNumber" placeholder="Enter phone number" />
                          </div>
                          <div *ngIf="phoneNumber.errors">
                            <span class="errorMessage" *ngIf="phoneNumber.errors?.pattern">
                              Invalid phone number
                            </span>
                          </div>
                          <div *ngIf="
                              (phoneNumber.touched || basicInfo.submitted) &&
                              phoneNumber.errors?.required
                            ">
                            <span class="errorMessage">
                              Please enter phone number
                            </span>
                          </div>
                          <div class="errorMessage" *ngIf="showPhoneError">
                            Phone number already exists
                          </div>
                        </div>
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Gender <span class="errorRequired">*</span></label>
                          <div class="gs-grouped-fileds pt-0">
                            <div class="ui radio checkbox">
                              <input class=" " type="radio" name="gender" checked="true" value="male" #gender="ngModel"
                                [(ngModel)]="basicInfoData.gender" tabindex="0" />
                              <label class="grouped-label">Male </label>
                            </div>
                            <div class="ui radio checkbox c1p">
                              <input class="" type="radio" name="gender" tabindex="0" value="female" #gender="ngModel"
                                [(ngModel)]="basicInfoData.gender" />
                              <label class="grouped-label">Female</label>
                            </div>
                            <div class="ui radio checkbox c1p">
                              <input class="" type="radio" name="gender" tabindex="0" value="other" #gender="ngModel"
                                [(ngModel)]="basicInfoData.gender" />
                              <label class="grouped-label">Others</label>
                            </div>
                          </div>
                        </div>

                        <!--START: Date of Birth AND City-->

                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Date of Birth<span
                              class="errorRequired">*</span></label>
                          <p-calendar placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" #dob="ngModel" id="dob"
                            [autoZIndex]="false" appendTo="body" [(ngModel)]="basicInfoData.dob" [maxDate]="minDob"
                            [ngModelOptions]="{ standalone: true }" [showIcon]="true" inputId="icon" name="dob">
                          </p-calendar>
                          <div *ngIf="(dob.touched || basicInfo.submitted)">
                              <span class="errorMessage">
                                Please select date of birth
                              </span>
                            </div>
                          <div *ngIf="dateofBirthError">
                            <span class="errorMessage">
                              Please select date of birth
                            </span>
                          </div>
                          <!-- <label class="input-group field">
                                <input class="input-field form-input block datepicker" type="text" name="date-birth" id="checkout-date" data-input="data-input" placeholder="( DD/MM/YYYY )"/><img class="input-icon" for="checkout-date" src="./img/job-application/calender.svg" alt="Calender icon"/>
                              </label> -->
                        </div>
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Nationality
                            <span class="errorRequired">*</span></label>
                          <input class="form-input form-input_1" type="text" name="nationality" id="nationality"
                            #nationality="ngModel" pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$"
                            [(ngModel)]="basicInfoData.nationality" placeholder="Enter nationality" />
                          <div *ngIf="
                              (nationality.touched || basicInfo.submitted) &&
                              nationality.errors?.required
                            ">
                            <span class="errorMessage">
                              Please enter nationality
                            </span>
                          </div>
                          <div *ngIf="nationality.errors">
                            <span class="errorMessage" *ngIf="nationality.errors?.pattern">
                              Invalid nationality
                            </span>
                          </div>
                        </div>
                      </div>
                      <!--START: Nationality AND Country of Birth      -->
                      <div class="row">
                        <div class="col-md-4 col-lg-4 field gs-form-group">
                          <label class="form-label form-label-clr">Country of Birth
                          </label>
                          <input class="form-input form-input_1" type="text" name="countryBirth" id="countryBirth"
                            #countryBirth="ngModel" pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$"
                            [(ngModel)]="basicInfoData.countryBirth" placeholder="Enter country of birth" />
                          <div *ngIf="
                              (countryBirth.touched || basicInfo.submitted) &&
                              countryBirth.errors?.required
                            ">
                            <span class="errorMessage">
                              Please enter country of birth
                            </span>
                          </div>
                          <div *ngIf="countryBirth.errors">
                            <span class="errorMessage" *ngIf="countryBirth.errors?.pattern">
                              Invalid country of birth
                            </span>
                          </div>
                        </div>
                        <div class="col-md-8 col-lg-8 field gs-form-group app__jOB__for new__arrow__weiGht">
                          <label class="form-label form-label-clr">Applied For<span
                              class="errorRequired">*</span></label>
                          <input class="form-input form-input_1" type="text" name="dtlnationality"
                            #dtlnationality="ngModel" [(ngModel)]="basicInfoData.selectjobTitleValue"
                            placeholder="Enter applied for" />
                          <!-- <ng-select [items]="jobTitleValue" placeholder="Select applied for" bindLabel="name"
                            bindValue="id" [(ngModel)]="selectjobTitleValue" name="dtlnationality"
                            #dtlnationality="ngModel">
                          </ng-select> -->
                          <!-- <div
                            *ngIf="(dtlnationality.touched || basicInfo.submitted) && dtlnationality.errors?.required">
                            <span class="errorMessage" *ngIf="dtlnationality.errors.required">
                              Please select appplied for
                            </span>
                          </div> -->
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 text-center text-md-right workHisNextMain desktop__buttons">
                      <button class="btn btn--primary-d next__buttons" type="button" value="general-information"
                        (click)="generalInfonext()" style="position: relative;">
                        Work History
                        <i class="icon-angle-down first__I" style="position:absolute"></i>
                      </button>
                    </div>
                    <div class="col-md-12 text-center text-md-right pb-5 workHisNextMain mobile__buttons"
                      style="position: relative">
                      <button class="btn btn--primary-d next__buttons" type="button" value="general-information"
                        (click)="generalInfonext()">
                        Next
                        <i class="icon-angle-down angle-rt27-d first__I"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!--START: WORK HISTORY-->
          <form class="col-md-8 col-xl-9 px-md-0" novalidate #workForm="ngForm" *ngIf="showWorkHistory">
              <div class="steps-form ui form add-steps-container" id="work-history">
                <div class="gs-panel" style=" box-shadow: 0 3px 7px rgb(0 0 0 / 4%);">
                  <!--Panel-header-->
                  <div class="gs-panel__header change_head_clr">
                    <h5 class="gs-form-panel__heading mb-0">2. Work History</h5>
                  </div>
                  <!--Panel-body-->
                  <div class="gs-panel__body gs-form-panel gs-form-panel--sm" style="box-shadow: none !important">
                    <div class="gs-steps-categ">
                      <h6 class="gs-panel__subheading">1. Work History</h6>
                      <div class="row pb-3">
                        <!-- <div class="field gs-form-group">
                            <label class="form-label form-label-clr">Total Years of Experience <span
                                class="errorRequired">*</span></label>
                            <input class="form-input form-input_1" type="text" name="totalExpereince" maxlength="4"
                              id="totalExpereince" #totalExpereince="ngModel" [(ngModel)]="workinfoData.totalExpereince"
                              id="txtChar" onkeypress="return isNumberKey(event,this.id)" required
                              placeholder="Please enter the number of  years" />
                            <div
                              *ngIf="(totalExpereince.touched || workForm.submitted) && totalExpereince.errors?.required">
                              <span class="errorMessage" *ngIf="totalExpereince.errors.required">
                                Please enter total years of experience
                              </span>
                            </div>
                          </div> -->

                        <!-- <div class="col-md-4">
                          <div class="field gs-form-group">
                            <label class="form-label form-label-clr">Relevant Experience <span
                                class="errorRequired">*</span></label>
                            <input class="form-input form-input_1" type="text" name="relevantExpereince" maxlength="4"
                              id="relevantExpereince" #relevantExpereince="ngModel"
                              [(ngModel)]="workinfoData.relevantExpereince" id="txtChar"
                              onkeypress="return isNumberKey(event,this.id)" required
                              placeholder="Enter the number of  years" />
                            <div *ngIf="(workinfoData.relevantExpereince > workinfoData.totalExpereince)">
                              <span class="errorMessage">
                                Relevant experience should be less than total experience
                              </span>
                            </div>
                            <div
                              *ngIf="(relevantExpereince.touched || workForm.submitted) && relevantExpereince.errors?.required">
                              <span class="errorMessage" *ngIf="relevantExpereince.errors.required">
                                Please enter total years of relevant experience
                              </span>
                            </div>
                          </div>
                        </div> -->

                        <!-- <div class="col-md-4 new__arrow__weiGht">
                          <div class="field gs-form-group">
                            <label class="form-label form-label-clr">Notice Period<span
                                class="errorRequired">*</span></label>
                            <ng-select placeholder="Select notice period" [items]="noticeValues" [clearable]="false"
                              [searchable]="false" name="noticePeriod" [(ngModel)]="workinfoData.noticePeriod" required
                              id="noticePeriod" #nnoticeModel="ngModel" bindLabel="noticePeriod"
                              bindValue="noticePeriod">
                              <ng-template ng-option-tmp let-item="item">
                                <div class="item gs-country-item"><span>{{item.noticePeriod}}</span></div>
                              </ng-template>
                            </ng-select>
                            <div *ngIf="(nnoticeModel.touched || workForm.submitted) && nnoticeModel.errors?.required">
                              <span class="errorMessage" *ngIf="nnoticeModel.errors.required">
                                Please select notice period
                              </span>
                            </div>
                          </div>
                        </div> -->
                        <div class="col-md-4"></div>

                        <div class="row col-md-12 mt-2 mobile__expp new__Margin__mobile" *ngFor="
                            let item of basicInfoData.industries;
                            let i = index
                          ">
                          <!-- preferred industry -->
                          <div
                            class="col-md-4 pr-1 field preferred__ind__part new__arrow__weiGht ng__select__width desktop__buttons">
                            <label class="form-label form-label-clr">Industry<span class="mandiatory">*</span></label>
                            <ng-select [items]="PrefInd" #prefferedIndustry="ngModel" name="prefferedIndustry{{ i }}"
                              placeholder="Select industry" bindLabel="name" bindValue="name"
                              [(ngModel)]="item.selectPrefInd">
                            </ng-select>

                            <!-- <ng-select [items]="PrefInd" name="prefferedIndustry{{i}}" #prefferedIndustry="ngModel"
                              bindLabel="name" bindValue="id" [(ngModel)]="selectPrefInd" placeholder="Select industry">
                            </ng-select> -->

                            <div *ngIf="
                                (prefferedIndustry.touched ||
                                  workForm.submitted) &&
                                prefferedIndustry.errors?.required
                              ">
                              <span class="errorMessage">
                                Please select industry
                              </span>
                            </div>
                          </div>

                          <!-- mobile version -->
                          <div
                            class="col-md-4 pr-1 field preferred__ind__part new__arrow__weiGht ng__select__width mobile__buttons">
                            <label class="form-label form-label-clr">Industry<span class="mandiatory">*</span></label>
                            <ng-select [items]="PrefInd" #prefferedIndustry="ngModel" name="prefferedIndustry{{ i }}"
                              placeholder="Select industry" bindLabel="name" bindValue="name"
                              [(ngModel)]="item.selectPrefInd" style="width:75% !important">
                            </ng-select>

                            <div *ngIf="
                                (prefferedIndustry.touched ||
                                  workForm.submitted) &&
                                prefferedIndustry.errors?.required
                              ">
                              <span class="errorMessage">
                                Please select industry
                              </span>
                            </div>
                          </div>
                          <!-- mobile version -->

                          <!-- preferred industry -->

                          <!-- Preferred industry experience(in years) -->

                          <div class="col-md-4 mb-4 new__arrow__weiGht ml-md-3 mobile__expp"
                            style="margin-top: -5px !important">
                            <label class="form-label form-label-clr">Experience(in years)<span
                                class="mandiatory">*</span></label>
                            <div class="d-flex align-items-center">
                              <div class="col-md-12 pl-0 pr-1 desktop__buttons">
                                <ng-select [items]="preFExp" placeholder="Select experience" bindLabel="name"
                                  bindValue="id" [(ngModel)]="
                                    item.prefferedIndustryExperienceFrom
                                  " name="industryExpP{{ i }}" #industryExpP="ngModel" required
                                  (change)="changeFn($event, item)">
                                </ng-select>
                                <!-- <input type="number" class="new__from" [(ngModel)]="basicInfo.industryExp1"
                                  #industryExp1="ngModel" required name="industryExp1"> -->
                              </div>

                              <div class="col-md-12 pl-0 pr-1 mobile__buttons">
                                <div class="d-flex">
                                  <ng-select class=" pl-0" [items]="preFExp" placeholder="Select experience"
                                    bindLabel="name" bindValue="id" [(ngModel)]="
                                    item.prefferedIndustryExperienceFrom
                                  " name="industryExpP{{ i }}" #industryExpP="ngModel" required
                                    (change)="changeFn($event, item)" style="width : 75% !important">
                                  </ng-select>
                                  <!-- <input type="number" class="new__from" [(ngModel)]="basicInfo.industryExp1"
                                  #industryExp1="ngModel" required name="industryExp1"> -->
                                  <div class="ml-md-4 ml-2 plus__icon">
                                    <div class="d-flex">
                                      <button type="button" class="plus-icon-button mr-1" *ngIf="
                                        i == basicInfoData.industries.length - 1 ||
                                        basicInfoData.industries.length == 1
                                      " (click)="addFieldss()">
                                        <img src="./assets/img/new-new-plus-icon.png" alt="" />
                                      </button>
                                      <button type="button" class="submit-button plus-icon-button"
                                        (click)="deleteFieldss(i)" *ngIf="basicInfoData.industries.length > 1">
                                        <img src="./assets/img/white-delete-icon.png" alt="" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- <span class="form-label form-label-clr mx-2 ml-3"> To </span>
                              <div class="col-md-5 px-0">
                                <ng-select [items]="preFExpP" bindLabel="name" bindValue="id"
                                  [(ngModel)]="item.prefferedIndustryExperienceTo" name="industryExp{{i}}"
                                  #industryExp="ngModel" style="width: 104% !important;">
                                  <ng-template ng-option-tmp let-item="item">
                                    {{ item.name}}
                                  </ng-template>
                                </ng-select> -->

                              <!-- <input type="number" class="new__from" [(ngModel)]="basicInfo.industryExp" required
                                  name="industryExp" #industryExp="ngModel"> -->
                              <!-- </div> -->
                            </div>
                            <div class="d-flex">
                              <div *ngIf="
                                  (industryExpP.touched ||
                                    workForm.submitted) &&
                                  industryExpP.errors?.required
                                ">
                                <span class="errorMessage">
                                  Please select experience
                                </span>
                              </div>
                              <!-- <div *ngIf="(industryExp1.touched || basicInfo.submitted) && industryExp1.errors?.required">
                                <span class="errorMessage" style="margin-left: 21px">
                                  Please enter experience
                                </span>
                              </div> -->
                            </div>
                          </div>

                          <div class="col-md-3 ml-md-2 plus__icon desktop__buttons">
                            <button type="button" class="plus-icon-button mr-1" *ngIf="
                                i == basicInfoData.industries.length - 1 ||
                                basicInfoData.industries.length == 1
                              " (click)="addFieldss()">
                              <img src="../../../assets/img/new-new-plus-icon.png" alt="" />
                            </button>
                            <button type="button" class="submit-button delete-buttonN" (click)="deleteFieldss(i)"
                              *ngIf="basicInfoData.industries.length > 1">
                              <img src="../../../assets/img/white-delete-icon.png" alt="" />
                            </button>
                          </div>
                        </div>

                        <div class="col-md-12" *ngFor="
                            let item of basicInfoData.domains;
                            let i = index
                          ">
                          <div class="row">
                            <div class="col-md-4 field mt-1 preferreDD__Domain pad_left_right new__arrow__weiGht">
                              <label class="form-label form-label-clr">Domain<span class="mandiatory">*</span></label>

                              <ng-select class="mobile__ng__sselect" [items]="finalDomain" #preferedDomain="ngModel"
                                name="preferedDomain{{ i }}" placeholder="Select domain" bindLabel="domain_Name"
                                bindValue="domain_Name" [(ngModel)]="item.preferedDomain">
                              </ng-select>
                              <div *ngIf="
                                  (preferedDomain.touched ||
                                    workForm.submitted) &&
                                  preferedDomain.errors?.required
                                ">
                                <span class="errorMessage">
                                  Please select domain
                                </span>
                              </div>
                            </div>
                            <!-- preffered domain -->
                            <!-- domain experience -->

                            <div class="col-md-4 mb-4 new__arrow__weiGht ml-md-2 mobile__expp">
                              <label class="form-label form-label-clr">Experience(in years)<span
                                  class="mandiatory">*</span></label>
                              <div class="col-md-12 pl-0 desktop__buttons">
                                <ng-select placeholder="Select experience" [items]="domMExp" bindLabel="name"
                                  bindValue="id" (change)="domainchangeFn($event, item)"
                                  [(ngModel)]="item.selectedDomFExp" #DomExperiencee="ngModel"
                                  name="DomExperiencee{{ i }}">
                                </ng-select>
                                <!-- <input type="number" class="new__from" [(ngModel)]="basicInfo.DomExperience"
                                    #DomExperience="ngModel" required name="DomExperience"> -->
                              </div>
                              <div class="col-md-12 pl-0 mobile__buttons">
                                <div class="d-flex">
                                  <ng-select placeholder="Select experience" [items]="domMExp" bindLabel="name"
                                    bindValue="id" (change)="domainchangeFn($event, item)"
                                    [(ngModel)]="item.selectedDomFExp" #DomExperiencee="ngModel"
                                    name="DomExperiencee{{ i }}" style="width:75% !important">
                                  </ng-select>
                                  <div class="ml-2 plus__icon mobile__buttons">
                                    <div class="d-flex">
                                      <button type="submit" class="plus-icon-button mr-2" *ngIf="
                                      i == basicInfoData.domains.length - 1 ||
                                      basicInfoData.domains.length == 1
                                    " (click)="addFieldsTwoo()">
                                        <img src="./../../../assets/img/new-new-plus-icon.png" alt="" />
                                      </button>
                                      <button type="button" class="submit-button delete-buttonN mr-2"
                                        (click)="deleteFieldsTwoo(i)" *ngIf="basicInfoData.domains.length > 1">
                                        <img src="../../../assets/img/white-delete-icon.png" alt="" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- <span class="form-label form-label-clr mx-2 ml-1"> To </span>
                                <div class="col-md-5 px-0">
                                  <ng-select [items]="domExpP" bindLabel="name" bindValue="id"
                                    [(ngModel)]="item.domainExperienceTo" name="domainExperienceTo{{i}}"
                                    #domainExperienceTo="ngModel">
                                  </ng-select> -->

                              <!-- <div class="col-md-5 px-0">
                                  <input type="number" class="new__from" [(ngModel)]="basicInfo.DomExperience1" required
                                    name="DomExperience1" #DomExperience1="ngModel">
                                </div> -->
                              <!-- </div> -->
                              <div class="d-flex">
                                <div *ngIf="
                                    (DomExperiencee.touched ||
                                      workForm.submitted) &&
                                    DomExperiencee.errors?.required
                                  ">
                                  <span class="errorMessage">
                                    Please select experience
                                  </span>
                                </div>
                                <!-- <div *ngIf="(DomExperience1.touched || basicInfo.submitted) && DomExperience1.errors?.required">
                                  <span class="errorMessage" style="margin-left:21px">
                                    Please enter experience
                                  </span>
                                </div> -->
                              </div>
                            </div>

                            <div class="col-md-3 plus__icon desktop__buttons">
                              <button type="submit" class="plus-icon-button mr-2" *ngIf="
                                  i == basicInfoData.domains.length - 1 ||
                                  basicInfoData.domains.length == 1
                                " (click)="addFieldsTwoo()">
                                <img src="./../../../assets/img/new-new-plus-icon.png" alt="" />
                              </button>
                              <button type="button" class="submit-button delete-buttonN mr-2"
                                (click)="deleteFieldsTwoo(i)" *ngIf="basicInfoData.domains.length > 1">
                                <img src="../../../assets/img/white-delete-icon.png" alt="" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-4 new__arrow__weiGht pr-0 mobile__expp">
                          <label class="form-label form-label-clr">Total Experience(in years)<span
                              class="mandiatory">*</span></label>
                          <ng-select placeholder="Select total experience"
                            class="custom col-md-12 pl-0 mobile__ngselect__width" [items]="fromyears" bindLabel="name"
                            bindValue="id" name="id" (change)="totoalExperienc($event, basicInfoData)"
                            [(ngModel)]="basicInfoData.totalExperienceFrom" #totalExperienceFrom="ngModel"
                            name="totalExperienceFrom">
                          </ng-select>
                          <!-- <div class="">
                                <input type="text" maxlength="2" class="new__from" onkeypress="return /[0-9]/i.test(event.key)"
                                  [(ngModel)]="basicInfoData.totalExperienceFrom" #totalExperienceFrom="ngModel"
                                  name="totalExperienceFrom" onkeypress="return /[0-9]/i.test(event.key)"
                                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">

                              </div> -->
                          <!-- <span class="form-label form-label-clr mx-1"> To </span> -->
                          <!-- <ng-select class="custom" [items]="toyears" bindLabel="name" bindValue="id" placeholder=""
                              name="id" [(ngModel)]="basicInfoData.totalExperienceTo" name="totalExperienceTo"
                              #totalExperienceTo="ngModel" style="width: 96% !important;">
                            </ng-select> -->
                          <!-- <div class="">
                                <input type="text" maxlength="2" class="new__from" onkeypress="return /[0-9]/i.test(event.key)"
                                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                                  [(ngModel)]="basicInfoData.totalExperienceTo" required name="totalExperienceTo"
                                  #totalExperienceTo="ngModel">
                              </div> -->

                          <div class="d-flex">
                            <div *ngIf="
                                (totalExperienceFrom.touched ||
                                  workForm.submitted) &&
                                totalExperienceFrom.errors?.required
                              ">
                              <span class="errorMessage">
                                Please select total experience
                              </span>
                            </div>

                            <!-- <div
                                *ngIf="(totalExperienceTo.touched || basicInfo.submitted) && totalExperienceTo.errors?.required">
                                <span class="errorMessage" style="margin-left: 21px">
                                  Please enter experience
                                </span>
                              </div> -->
                          </div>
                        </div>

                        <div class="col-md-4 total__Exp__field ml-md-2 pr-md-0 mobile__expp">
                          <label class="form-label form-label-clr">Notice Period(in days)<span
                              class="mandiatory">*</span></label>
                          <!-- <div class=""> -->
                          <input type="text" placeholder="Enter notice period" maxlength="3" class="new__from"
                            [(ngModel)]="basicInfoData.noticePeriodFrom" onkeypress="return /[0-9]/i.test(event.key)"
                            (keyup)="getValue('notice')" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                            #noticePeriodFrom="ngModel" name="noticePeriodFrom" />
                          <!-- </div> -->
                          <!-- <span class="form-label form-label-clr mx-2"> To </span> -->
                          <!-- <div class=""> -->
                          <!-- <input type="text" maxlength="3" class="new__from col-md-5"
                              min="basicInfoData.noticePeriodFrom" [(ngModel)]="basicInfoData.noticePeriodTo"
                              name="noticePeriodTo" onkeypress="return /[0-9]/i.test(event.key)"
                              (keyup)="getValue('notice')"
                              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" #noticePeriodTo="ngModel"> -->
                          <!-- </div> -->

                          <span class="errorMessage" *ngIf="noticeError"
                            ng-show="basicInfo.noticePeriodFrom.$error.min">Value should be greater than
                            {{ basicInfoData.noticePeriodFrom }}.</span>
                          <div class="d-flex">
                            <div *ngIf="
                                (noticePeriodFrom.touched ||
                                  workForm.submitted) &&
                                noticePeriodFrom.errors?.required
                              ">
                              <span class="errorMessage">
                                Please enter notice period
                              </span>
                            </div>
                            <!-- <div *ngIf="(noticePeriodTo.touched || basicInfo.submitted) && noticePeriodTo.errors?.required">
                              <span class="errorMessage">
                                Please enter notice period
                              </span>
                            </div> -->
                          </div>
                        </div>

                        <!-- <div class="col-md-6">
                              <div class="field gs-form-group">
                                <label class="form-label" for="checkout-date3">Date of Birth</label>
                                <p-calendar name="doj" placeholder="DD/MM/YYY" [(ngModel)]="workinfoData.doj"
                                 dateFormat="dd/mm/yy" required  readonly="false"  readonlyInput="false"
                                [ngModelOptions]="{standalone: true}" [showIcon]="true" inputId="icon"
                                required></p-calendar>
                              </div>
                            </div> -->
                      </div>
                    </div>
                    <div class="gs-steps-categ addCondidate">
                      <h6 class="gs-panel__subheading">
                        2. Previous Work History
                      </h6>
                      <div class="row">
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Designation<span
                              class="errorRequired">*</span></label>
                          <input class="form-input form-input_1" type="text" name="designation"
                            pattern="^[a-zA-Z]+(\s[a-zA-Z]+)?$" #designation="ngModel"
                            [(ngModel)]="workinfoData.designatioonn" placeholder="Enter designation"
                            (keydown)="noSpaceBar($event)" />
                        </div>

                        <!-- <div class="row">
                          <div class="col-md-12 field gs-form-group">
                            <label class="form-label">Core Subjects</label>
                            <input class="form-input" type="text" name="coreSubjects"
                              onkeypress="return /[a-z^\s]/i.test(event.key)" #coreSubjects="ngModel"
                              [(ngModel)]="workinfoData.coreSubjects" placeholder="Enter your Core Subjects" />
                          </div>
                        </div> -->

                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Organization Name</label>
                          <input class="form-input form-input_1" type="text" name="orgName"
                            onkeypress="return /[a-z^\s]/i.test(event.key)" #orgName="ngModel"
                            [(ngModel)]="workinfoData.orgNammee" placeholder="Enter your organization name" />
                        </div>
                        <!-- (keydown)="noSpaceBar($event)" -->
                        <div class="col-md-4">
                          <div class="field gs-form-group">
                            <label class="form-label form-label-clr">City</label>
                            <input class="form-input form-input_1" type="text" name="city"
                              onkeydown="return /[a-z ]/i.test(event.key)" #city="ngModel"
                              [(ngModel)]="workinfoData.city" placeholder="Enter your city"
                              (keydown)="noSpaceBar($event)" />
                          </div>
                        </div>
                        <!-- <div class="col-md-4">
                          <div class="field gs-form-group">
                            <label class="form-label form-label-clr">State</label>
                            <input class="new__State new__Placeholder" type="text" name="state"
                              onkeypress="return /[a-z^\s]/i.test(event.key)" #state="ngModel"
                              [(ngModel)]="workinfoData.state" placeholder="Enter your state"
                              (keydown)="noSpaceBar($event)" />
                          </div>
                        </div> -->

                        <div class="col-md-4 new__arrow__weiGht">
                          <div class="field gs-form-group">
                            <label class="form-label form-label-clr">Country<span class="errorRequired">*</span></label>
                            <!-- <ng-select [items]="countryNationality" placeholder="Select country" [searchable]="false"
                              (change)="getCountryValue($event)" name="country" [(ngModel)]="workinfoData.country"
                              name="country" #ddlnationality="ngModel" bindLabel="countryname" bindValue="countryname">
                              <ng-template ng-option-tmp let-item="item">
                                <div class="item gs-country-item">
                                  <span>{{item.countryname}}</span><i class="{{item.flag}}"></i>
                                </div>
                              </ng-template>
                            </ng-select> -->

                            <ng-select [items]="countrYYY" placeholder="Select country" bindLabel="name"
                              bindValue="name" [(ngModel)]="workinfoData.selectedcountrYYY" name="country"
                              #country="ngModel" onkeypress="return /[a-z]/i.test(event.key)">
                            </ng-select>

                            <!-- <select style="height:49px !important;" name="country"  [(ngModel)]="workinfoData.country">
                                  <option  [ngValue]="undefined" hidden>select country</option>
                                  <option *ngFor="let web of nationality" value="web">{{web.name}}
                                      <i class="in flag"></i>
                                  </option>
                              </select> -->
                          </div>
                        </div>

                        <!-- <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Zip Code</label>
                          <input class="form-input form-input_1" type="text" name="TwoZipCode"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            onkeypress="return /[0-9]/i.test(event.key)" #TwoZipCode="ngModel" minlength="4"
                            maxlength="6" [(ngModel)]="workinfoData.TwoZipCodeeeE" placeholder="Enter your zip code" />
                        </div> -->

                        <div class="col-md-4 new__arrow__weiGht">
                          <div class="field gs-form-group numberField">
                            <label class="form-label form-label-clr">Current Salary (Monthly)<span
                                class="errorRequired">*</span></label>
                            <div style="border-radius: 8px !important" class="gs-labeled-drodown border_rad_sec">
                              <input class="col-md-3 monthy__sal__Curr__code" placeholder="$" name="currencyCode"
                                [(ngModel)]="workinfoData.currencyCode" readonly />

                              <!-- <ng-select class="line" placeholder="$" [items]="currencySymbol" [clearable]="false"
                                [searchable]="false" name="currencyCode" [(ngModel)]="workinfoData.currencyCode"
                                #ddcnationality="ngModel" bindLabel="name" bindValue="name">
                                <ng-template ng-option-tmp let-item="item">
                                  <div class="item gs-country-item"><span>{{item.name}}</span></div>
                                </ng-template>
                              </ng-select> -->
                              <!-- <div class="ui selection dropdown">
                                    <input type="hidden" name="number"/><i class="dropdown dropdown-icon icon-angle-down"></i>
                                    <div class="default text">$</div>
                                    <div class="menu">
                                      <div class="item" data-value="1">$</div>
                                      <div class="item" data-value="2">+</div>
                                      <div class="item" data-value="3">+</div>
                                      <div class="item" data-value="3">+</div>
                                      <div class="item" data-value="3">+</div>
                                    </div>
                                  </div> -->
                              <input type="number"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                class="col-md-9 form-input labeled-input salary-rang form-input_1 new__Placeholder"
                                onkeypress="return /[0-9]/i.test(event.key)" name="currency" id="monthlySalarYy"
                                placeholder="Enter currency" #currency="ngModel" [(ngModel)]="workinfoData.currency"
                                (change)="onKeyUPP($event)" />
                            </div>
                          </div>
                        </div>
                        <!-- [value]="showDoMathValue"
                        [value]="workinfoData.currency"
                      [value]="workinfoData.currency" -->
                        <!-- <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Total Salary(Per Annum)</label>
                          <input class="form-input form-input_1" type="text" name="totalSalaryPerAnnum"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            onkeypress="return /[0-9]/i.test(event.key)" #totalSalaryPerAnnum="ngModel"
                            [(ngModel)]="workinfoData.totalSalaryAnnumm" placeholder="Enter salary"
                            [value]="showDoMathValue" readonly disabled />
                        </div> -->

                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Total Variable(Per Annum)<span
                              class="errorRequired">*</span></label>
                          <input class="form-input form-input_1" type="number" name="totalVariablePayPerAnnum"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            onkeypress="return /[0-9]/i.test(event.key)" #totalVariablePayPerAnnum="ngModel"
                            minlength="4" maxlength="6" [(ngModel)]="workinfoData.totalVariPayAnnum"
                            placeholder="Enter total variable" (change)="totalCTccC($event)" />
                        </div>

                        <!-- <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Total CTC</label>
                          <input class="form-input form-input_1" type="text" name="totalCTC"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            onkeypress="return /[0-9]/i.test(event.key)" #totalCTC="ngModel" minlength="4" maxlength="6"
                            [(ngModel)]="workinfoData.totCTCC" placeholder="Enter total ctc" [value]="showTotalCtc"
                            disabled />
                        </div> -->
                        <div class="col-md-4 Exp__inputs">
                          <label class="form-label form-label-clr">Duration<span class="errorRequired">*</span></label>
                          <div class="d-flex">
                            <div class="col-6 pl-0">
                              <p-calendar [(ngModel)]="workinfoData.dateValue" placeholder="From" name="ExpPFromM"
                                #ExpPFromM="ngModel" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>
                            </div>
                            <div class="col-6 pr-0 to__Duration">
                              <p-calendar [(ngModel)]="workinfoData.dateValue2" dateFormat="dd/mm/yy" name="ExpPTOO"
                                #ExpPTOO="ngModel" placeholder="To" [showIcon]="true"></p-calendar>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4"></div>

                        <div class="col-md-12 mt-4 mb-4">
                          <div class="ui">
                            <input class="checkbox" type="checkbox" name="isCurrentJob"
                              [(ngModel)]="workinfoData.isCurrentJob" tabindex="0" />
                            <label class="form-label form-label-clr ml-2">This is my current job</label>
                          </div>
                        </div>

                        <span class="errorMessage mb-2 ml-3" *ngIf="showJobArrayError">
                          Please add atleast one job to proceed further</span>
                      </div>

                      <div class="">
                        <button type="button"
                          class="col-md-3 field mt-1 gs-form-group btn next__buttons add__another__degree__button float__left">
                          <a class="" (click)="addJobsHere()">+ Add another Job</a>
                        </button>
                        <!-- <div class="col-md-6 field mt-1 gs-form-group"><a class="btn btn--add"
                            (click)="addanotherJob()">+ Add another Job</a></div> -->
                        <!-- <div *ngIf="enableUpdateJob" class="col-md-6 field mt-1 gs-form-group"><a class="btn btn--add"
                              (click)="updateJob()">++ Update Job</a></div> -->
                      </div>

                      <!-- <table class="aj1" *ngIf="(anotherJobArray && anotherJobArray.length)>0">
                        <tr class="tfsmall">
                          <th>Job Title</th>
                          <th>Employeer Name</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Country</th>
                          <th>Current Salary</th>
                        </tr>
                        <tr class="tfsmall" *ngFor="let job of anotherJobArray; let i = index;">
                          <td>{{job.jobTitle}}</td>
                          <td>{{job.employersName}}</td>
                          <td>{{job.city}}</td>
                          <td>{{job.state}}</td>
                          <td>{{job.country}}</td>
                          <td>{{job.currency}}</td>
                          <td> <i class="fa fa-trash" aria-hidden="true" (click)="deleteJob(i)"></i> </td>
                        </tr>
                      </table> -->


                      <table *ngIf="
                          (addJobsHereArray && addJobsHereArray.length) > 0
                        " class="col-md-12 workHistory__tableee px-0">
                        <tr class="addJobArrayHeadings">
                          <th>Designation</th>
                          <th>Organization Name</th>
                          <th>City</th>
                          <th>Country</th>
                          <th>Current Salary</th>
                          <th>Total Variable</th>
                          <th>Duration</th>
                          <th></th>
                        </tr>
                        <tr class="form-label form-label-clr" *ngFor="let x of addJobsHereArray; let i = index">
                          <td style="padding-left: 6px">
                            {{ x.designation }}
                          </td>
                          <td style="padding-left: 6px">{{ x.orgName }}</td>
                          <td style="padding-left: 6px">{{ x.city }}</td>
                          <td style="padding-left: 6px">{{ x.country }}</td>
                          <td style="padding-left: 6px">
                            {{ x.monthlYYCurCode }} {{ x.monthlYYSalary }}
                          </td>
                          <td style="padding-left: 6px">
                            {{ x.totallVariable }}
                          </td>
                          <td style="padding-left: 6px">
                            {{ x.InfoFromDate }} - {{ x.InfoToODate }}
                          </td>
                          <td>
                            <img class="trash__icon" src="../../../assets/img/red-trash.svg" alt=""
                              (click)="trashFunction(i)" />
                          </td>
                        </tr>
                      </table>
                    </div>


                    <div class="gs-steps-categ addCondidate">
                      <h6 class="gs-panel__subheading my__REf__margintop">
                        3. MY REFERENCES
                      </h6>
                      <div class="referee-onee">
                        <label class="gs-panel__subheading">Referee 1</label>
                        <div class="row">
                          <div class="col-md-4 field gs-form-group">
                            <label class="form-label form-label-clr">Organization Name</label>
                            <input class="form-input form-input_1" type="text" name="orgName"
                              onkeypress="return /[a-z]/i.test(event.key)" #orgName="ngModel"
                              [(ngModel)]="workinfoData.orgNameee" placeholder="Enter organization name" />
                          </div>

                          <div class="col-md-4 field gs-form-group">
                            <label class="form-label form-label-clr">Referee Name</label>
                            <input class="form-input form-input_1" type="text" name="refereeName" #refereeName="ngModel"
                              [(ngModel)]="workinfoData.refereeNameee" placeholder="Enter referee name"
                              onkeypress="return /[0-9]/i.test(event.key)"
                              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
                          </div>

                          <div class="col-md-4 field gs-form-group">
                            <label class="form-label form-label-clr">Position</label>
                            <input class="form-input form-input_1" type="text" name="positionREFERee"
                              #positionREFERee="ngModel" [(ngModel)]="workinfoData.positionREF"
                              placeholder="Enter position" onkeypress="return /[0-9]/i.test(event.key)"
                              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
                          </div>

                          <div class="col-md-4 field gs-form-group">
                            <label class="form-label form-label-clr">Email Address</label>
                            <input class="form-input form-input_1" type="text" name="refemailAddress"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" #refemailAddress="ngModel"
                              [(ngModel)]="workinfoData.refemailAddress" placeholder="Enter the mail id"
                              (keydown)="noSpaceBar($event)" onkeypress="return /[0-9]/i.test(event.key)"
                              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
                            <div *ngIf="refemailAddress.errors">
                              <span class="errorMessage" *ngIf="refemailAddress.errors?.pattern">
                                Invalid email
                              </span>
                            </div>
                          </div>

                          <div class="col-md-4 field gs-form-group numberField new-PHOne__part new__arrow__weiGht">
                            <label class="form-label form-label-clr">Phone Number</label>
                            <div class="gs-labeled-drodown phoneNumb__Container">
                              <ng-select [items]="countId" bindLabel="name" bindValue="name"
                                [(ngModel)]="familyinfoData.selectedCountId" name="countTTIDD" #countTTIDD="ngModel"
                                placeholder="+91">
                              </ng-select>
                              <input class="form-input labeled-input salary-rang form-input_1 new__Placeholder"
                                pattern="^[789]\d{9}$" maxlength="10" [(ngModel)]="familyinfoData.spousePhoneNumber"
                                #spousePhoneNumber="ngModel" name="spousePhoneNumber"
                                placeholder="Enter your phone number" />
                            </div>
                            <div *ngIf="spousePhoneNumber.errors">
                              <span class="errorMessage" *ngIf="spousePhoneNumber.errors?.pattern">
                                Invalid phone number
                              </span>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="mt-2">
                          <div class="btn next__buttons"><a class="" (click)="addAnotherReferenceOne()">+ Add another
                              REFERENCES</a></div>
                        </div>

                        <div class="mt-3">
                          <table class="aj1" *ngIf="(refOne && refOne.length)>0">
                            <tr class="addJobArrayHeadings">
                              <th>Organization Name</th>
                              <th>Referee Name</th>
                              <th>Position</th>
                              <th>Email Id</th>
                              <th>Phone</th>
                              <th></th>
                            </tr>
                            <tr class="form-label form-label-clr" *ngFor="let job of refOne; let i = index;">
                              <td style="padding-left:12px">{{job.orRGGNAme}}</td>
                              <td style="padding-left:12px">{{job.reFFNamee}}</td>
                              <td style="padding-left:12px">{{job.ReffPOSition}}</td>
                              <td style="padding-left:12px">{{job.REFFEMaill}}</td>
                              <td style="padding-left:12px"> {{job.countryYCODEe}} - {{job.REFFEMObilee}}</td>
                              <td> <img class="trash__icon" src="../../../assets/img/red-trash.svg" alt=""
                                  (click)="deleteReference(i)"> </td>
                            </tr>
                          </table>
                        </div> -->
                        <!-- <div class="col-md-4">
                          <div class="ui  mb-3">
                            <input class="checkbox" type="checkbox" name="checkrefAvailability"
                              #checkrefAvailability="ngModel" [(ngModel)]="workinfoData.checkrefAvailability"
                              tabindex="0" />
                            <label class="option-label fnt-sm form-label-clr">Can we get in touch with them?</label>
                          </div>
                        </div> -->

                        <!-- <div *ngIf="referenceUpdate" class="col-md-6 field mt-1 gs-form-group"><a class="btn btn--add"
                              (click)="updateReferences()">+ Update REFERENCES</a></div> -->
                      </div>
                      <div class="referee-twoo mt-3" div *ngFor="let x of refereesData.referees; let i = index">
                        <div class="d-flex" style="align-items: baseline !important">
                          <label class="gs-panel__subheading">Referee {{ i + 2 }}</label>
                          <label class="gs-panel__subheading ml-5" (click)="deleteReffFields(i)"
                            *ngIf="refereesData.referees.length > 1" style="cursor: pointer">Delete referee</label>
                          <!-- <button type="button" class="btn next__buttons ml-5" (click)="deleteReffFields(i)"
                            *ngIf="refereesData.referees.length >1">
                            - Delete referee
                          </button> -->
                        </div>
                        <div class="row mt-3">
                          <div class="col-md-4 field gs-form-group">
                            <label class="form-label form-label-clr">Organization Name</label>
                            <input class="form-input form-input_1" type="text" name="orgNameTwo{{ i }}"
                              onkeypress="return /[a-z]/i.test(event.key)" #orgNameTwo="ngModel"
                              [(ngModel)]="x.orgNameeeTwo" placeholder="Enter organization name" />
                          </div>

                          <div class="col-md-4 field gs-form-group">
                            <label class="form-label form-label-clr">Referee Name</label>
                            <input class="form-input form-input_1" type="text" name="refereeNameTwo{{ i }}"
                              #refereeNameTwo="ngModel" [(ngModel)]="x.refereeNameeeTwo"
                              placeholder="Enter referee name" onkeypress="return /[0-9]/i.test(event.key)"
                              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
                          </div>

                          <div class="col-md-4 field gs-form-group">
                            <label class="form-label form-label-clr">Position</label>
                            <input class="form-input form-input_1" type="text" name="positionREFEReeTwo{{ i }}"
                              #positionREFEReeTwo="ngModel" [(ngModel)]="x.positionREFTwo" placeholder="Enter position"
                              onkeypress="return /[0-9]/i.test(event.key)"
                              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
                          </div>

                          <div class="col-md-4 field gs-form-group">
                            <label class="form-label form-label-clr">Email Address</label>
                            <input class="form-input form-input_1" type="text" name="refemailAddressTwo{{ i }}"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" #refemailAddressTwo="ngModel"
                              [(ngModel)]="x.refemailAddressTwo" placeholder="Enter the mail id"
                              (keydown)="noSpaceBar($event)" onkeypress="return /[0-9]/i.test(event.key)"
                              oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" />
                            <div *ngIf="refemailAddressTwo.errors">
                              <span class="errorMessage" *ngIf="refemailAddressTwo.errors?.pattern">
                                Invalid email
                              </span>
                            </div>
                          </div>

                          <div class="col-md-4 field gs-form-group numberField new-PHOne__part new__arrow__weiGht">
                            <label class="form-label form-label-clr">Phone Number</label>
                            <div class="gs-labeled-drodown phoneNumb__Container">
                              <ng-select [items]="countIdTwo" bindLabel="name" bindValue="name"
                                [(ngModel)]="x.selectedcountIdTwo" name="countTTIDD{{ i }}" #countTTIDD="ngModel"
                                placeholder="+91" pattern="^[789]\d{9}$">
                              </ng-select>
                              <input class="form-input labeled-input salary-rang form-input_1 new__Placeholder"
                                pattern="^[789]\d{9}$" [(ngModel)]="x.spousePhoneNumberTwo"
                                #spousePhoneNumberTwo="ngModel" name="spousePhoneNumberTwo{{ i }}"
                                placeholder="Enter your phone number" />
                            </div>
                            <div *ngIf="spousePhoneNumberTwo.errors">
                              <span class="errorMessage" *ngIf="spousePhoneNumberTwo.errors?.pattern">
                                Invalid phone number
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-4">
                          <div class="ui  mb-3">
                            <input class="checkbox" type="checkbox" name="checkrefAvailability"
                              #checkrefAvailability="ngModel" [(ngModel)]="workinfoData.checkrefAvailability"
                              tabindex="0" />
                            <label class="option-label fnt-sm form-label-clr">Can we get in touch with them?</label>
                          </div>
                        </div> -->

                        <!-- <div *ngIf="referenceUpdate" class="col-md-6 field mt-1 gs-form-group"><a class="btn btn--add"
                              (click)="updateReferences()">+ Update REFERENCES</a></div> -->

                        <!-- <div class="mt-2">
                        <div class="btn next__buttons"><a class="" (click)="addAnotherReference()">+ Add another
                            REFERENCES</a></div>
                      </div> -->
                        <div class="col-md-3 pl-0" style="margin-top: 20px !important">
                          <button type="submit" class="btn next__buttons mr-2 addAnotherRefereeButton" *ngIf="
                              i == refereesData.referees.length - 1 ||
                              refereesData.referees.length == 1
                            " (click)="addAnotherReference(i)">
                            + Add another referee
                          </button>
                        </div>
                      </div>
                      <!-- <table class="aj1 col-md-10" *ngIf="(refTwo && refTwo.length)>0">
                        <tr class="addJobArrayHeadings">
                          <th>Organization Name</th>
                          <th>Referee Name</th>
                          <th>Position</th>
                          <th>Email Id</th>
                          <th>Phone</th>
                          <th></th>
                        </tr>
                        <tr class="form-label form-label-clr" *ngFor="let job of refTwo; let i = index;">
                          <td style="padding-left:12px">{{job.organNameTwo}}</td>
                          <td style="padding-left:12px">{{job.RefNameTwo}}</td>
                          <td style="padding-left:12px">{{job.RefPositionnTwOO}}</td>
                          <td style="padding-left:12px">{{job.RefEmailTwoo}}</td>
                          <td style="padding-left:12px">{{job.RefCountCodeTwoo}} - {{job.RefMoBIleTwoo}}</td>
                          <td> <img class="trash__icon" src="../../../assets/img/red-trash.svg" alt=""
                              (click)="deleteReferenceTwo(i)"> </td>
                        </tr>
                      </table> -->
                    </div>
                    <!-- <div class="gs-steps-categ">
                      <h6 class="gs-panel__subheading">4. Additional Attachments</h6>
                      <label class="d-block fnt-sm fnt-reg mb-2 mb-md-4 form-label-clr">Include your cover letter and
                        tell us why you
                        are a good fit for this job. (Cover letter should be in English)</label>

                      <P> <a *ngIf="id && hideWorkhistoryCertificate && workinfoData.additionalAttachmentsPath "
                          class="imp2" href="{{path}}{{workinfoData.additionalAttachmentsPath}}"
                          target="_blank">{{workinfoData.additionalAttachmentsName}}</a></P>
                      <ngx-dropzone
                        *ngIf="(uploadWorkhistoryCertificate || (workinfoData.additionalAttachmentsPath == null))"
                        class="" id="attachemtClass" [multiple]="false">
                        <ngx-dropzone-label>Drop your file (JPG/PNG/PDF) <br><br>
                          <p class="bf1">Browse files </p>
                        </ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of workInfoFiles" [removable]="true"
                          (removed)="onWorkAttachmentRemove(f)">
                          <ngx-dropzone-label style="margin-left:100px!important;">{{ f.name }} ({{ f.type
                            }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>

                      </ngx-dropzone>
                      <button *ngIf="id && hideWorkhistoryCertificate && workinfoData.additionalAttachmentsPath"
                        class="btn btn--primary-d em15" style="margin-left: 20px;
                        margin-bottom: 15px;margin-top:14px" type="button" value="" data-toggle="modal" data-target=""
                        (click)="showCertificat()">Edit
                        Pdf</button>

                    </div> -->

                    <!--START: NEXT-->
                    <div class="desktop__buttons">
                      <div class="col-md-12 text-center text-md-right d-md-flex justify-content-between px-0">
                        <button
                          class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info"
                          type="button" value="general-info" (click)="sideBarClick('workButton')">
                          <i class="icon-angle-down angle-rotate-d"></i>Personal
                          Details
                        </button>
                        <div class="gs-btn-group">
                          <!-- <button class="btn btn--primary-d" type="button" value="job-description" data-toggle="modal"
                          data-target="#save-model">Save as Draft</button> -->
                          <button class="btn btn--primary-d js-next next next__buttons second__next" type="button"
                            (click)="workHistoryNext()" value="work-history">
                            Educational Information<i class="icon-angle-down angle-rt27-d second__next new__edu_next_icon"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- mobile buttons -->
                    <div class="col-md-12 mobile__buttons px-0 mt-5 pb-4">
                      <button class="btn btn--primary-d next__buttons second__next" type="button"
                        (click)="workHistoryNext()" value="work-history">
                        Next<i class="icon-angle-down angle-rt27-d second__next media__next__icon"></i>
                      </button>
                      <button class="btn btn--primary-d next__buttons float__left relative media__back__button"
                        type="button" value="general-info" (click)="sideBarClick('workButton')">
                        <i class="icon-angle-down angle-rotate-d media__Back__icon absolute"></i>Back
                      </button>
                    </div>
                    <!-- mobile buttons -->
                  </div>
                </div>
              </div>
          </form>
          <!--START: Educational-information-->
          <form class="col-md-7 col-xl-9" novalidate *ngIf="showEducationalInfo" #educationForm="ngForm">
            <!-- <div class="gs-steps-container ml-0"> -->
              <div class="steps-form ui form">
                <div class="gs-panel mobileee__margin__left" style="box-shadow: 0 3px 7px rgb(0 0 0 / 4%)">
                  <!--Panel-header-->
                  <div class="gs-panel__header change_head_clr">
                    <h5 class="gs-form-panel__heading mb-0">
                      3. Educational Information
                    </h5>
                  </div>
                  <!--Panel-body-->
                  <div class="gs-panel__body gs-form-panel gs-form-panel--sm pb-0 addCondidate">
                    <div class="gs-steps-categ">
                      <!-- <h6 class="gs-panel__subheading">1. Degree One</h6> -->
                      <div class="row">
                        <div class="col-md-4 field gs-form-group new__arrow__weiGht eduacation__inputs">
                          <label class="form-label form-label-clr">Higher Level of Education<span
                              class="errorRequired">*</span></label>
                          <ng-select [items]="levelEducation" bindLabel="name" bindValue="name"
                            placeholder="Select higher level of education" [(ngModel)]="
                              educationinfoData.selectedlevelEducation
                            " name="levelofEducation" #levelofEducation="ngModel" [multiple]="true">
                          </ng-select>

                          <!-- <ng-select style="height:49px !important;" class="ng-selectct1" [items]="levelofeducation"
                            placeholder="Choose your level" [searchable]="false" name="levelofEducation"
                            [(ngModel)]="educationinfoData.levelofEducation" #dlenationality="ngModel"
                            bindLabel="educationName" bindValue="educationName">
                            <ng-template ng-option-tmp let-item="item">
                              <div class="item gs-country-item">
                                <span>{{item.educationName}}</span>
                              </div>
                            </ng-template>
                          </ng-select> -->
                          <!-- <select style="height:49px !important;" name="levelofEducation" [(ngModel)]="educationinfoData.levelofEducation">
                                  <option  [ngValue]="undefined" hidden>Choose your level</option>
                                  <option *ngFor="let web of levelofeducation" value="web">{{web.educationName}}
                                      <i class="in flag"></i>
                                  </option>
                              </select> -->
                        </div>
                        <div class="col-md-4 field gs-form-group new__arrow__weiGht eduacation__inputs">
                          <label class="form-label form-label-clr">Qualification<span
                              class="errorRequired">*</span></label>

                          <ng-select placeholder="Select qualification" [items]="degreeINN" bindLabel="one"
                            bindValue="one" [(ngModel)]="
                              educationinfoData.selectedqualificationn
                            " name="degreeInn" #degreeInn="ngModel" [multiple]="true">
                          </ng-select>

                          <!-- <ng-select style="height:49px !important;" class="ng-selectct1" [items]="degree"
                            placeholder="Choose your degree" [searchable]="false" name="degree"
                            [(ngModel)]="educationinfoData.degree" #dldnationality="ngModel" bindLabel="degreeName"
                            bindValue="degreeName">
                            <ng-template ng-option-tmp let-item="item">
                              <div class="item gs-country-item">
                                <span>{{item.degreeName}}</span>
                              </div>
                            </ng-template>
                          </ng-select> -->
                        </div>

                        <!-- <div class="col-md-4 field gs-form-group new__arrow__weiGht">
                          <label class="form-label form-label-clr">Grade</label>

                          <ng-select placeholder="Select grade" [items]="gradeEE" bindLabel="name" bindValue="name"
                            [(ngModel)]="educationinfoData.selectedgradeEE" name="gradeee" #gradeee="ngModel">
                          </ng-select>

                        </div> -->
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Year of Graduation<span
                              class="errorRequired">*</span></label>
                          <p-calendar name="yearofGraduation" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                            #yearofGraduation="ngModel" [(ngModel)]="educationinfoData.yearofGraduation"
                            readonly="false" [ngModelOptions]="{ standalone: true }" [showIcon]="true"
                            inputId="icon"></p-calendar>
                          <!-- <div class="input-group">
                                <input class="input-field form-input block datepicker-year" type="text" name="date-birth" id="year-graduate" data-input="data-input" placeholder="( YYYY )"/><img class="input-icon" for="checkout-date" src="./img/job-application/calender.svg" alt="Calender icon"/>
                              </div> -->
                        </div>


                        <!-- <select
                            style=" height:49px !important; border-radius: 8px !important; font-size: 12px !important;
                            color: #999 !important;" name="languagesKnown"
                            [(ngModel)]="educationinfoData.languagesKnown">
                            <option [ngValue]="undefined" hidden>Choose your Languages you know</option>
                            <option *ngFor="let web of language" value="web">{{web.languageName}}
                            </option>
                            </select> -->

                        <!-- <div class="col-md-4"></div> -->
                        <div class="col-md-8 field gs-form-group">
                          <label class="form-label form-label-clr">College / University Name<span
                              class="errorRequired">*</span></label>
                          <input class="form-input form-input_1" type="text" name="college"
                            onkeypress="return /[a-z^\s]/i.test(event.key)" [(ngModel)]="educationinfoData.college"
                            #college="ngModel" placeholder="Enter the name" (keydown)="noSpaceBar($event)" />
                        </div>
                        <div class="col-md-4 field gs-form-group new__arrow__weiGht eduacation__inputs">
                          <label class="form-label form-label-clr">Languages Known<span
                              class="errorRequired">*</span></label>
                          <ng-select [items]="knownLanguages" placeholder="Languages known" bindLabel="name"
                            bindValue="name" [(ngModel)]="
                              educationinfoData.selectedKnownLanguages
                            " name="languagesKnown" #languagesKnown="ngModel" [multiple]="true">
                          </ng-select>
                        </div>

                        <!-- <div class="col-md-12 gs-form-group">
                          <label class="form-label form-label-clr">Other Achievements (Add scholarships & awards
                            received)</label>
                          <div class="field">
                            <textarea class="gs-textarea" row="1" name="otherAchievements"
                              onkeypress="return /[a-z^\s]/i.test(event.key)"
                              [(ngModel)]="educationinfoData.otherAchievements" #otherAchievements="ngModel"
                              placeholder="Enter the details…" (keydown)="noSpaceBar($event)"></textarea>
                          </div>
                        </div> -->
                      </div>
                      <div class="row">
                        <span class="errorMessage" *ngIf="showEducationError">
                          Please add atleast one degree to proceed Further</span>

                        <div class="col-md-3 ml-3 mt-3 mb-3 btn next__buttons add__another__degree__button">
                          <a class="" (click)="addanotherDegree()">+ Add another Degree</a>
                        </div>
                      </div>
                      <div *ngIf="degreeUpdate" class="col-md-6 field mt-1 gs-form-group">
                        <a class="btn btn--add" (click)="updateReferences()">+ Update Degree</a>
                      </div>

                      <table class="aj1 col-md-10 workHistory__table px-0"
                        *ngIf="(eduInfoArray && eduInfoArray.length) > 0" style="margin-bottom: 20px">
                        <tr class="addJobArrayHeadings">
                          <th style="padding-left: 0px !important">
                            Higher Level of Education
                          </th>
                          <th>Qualification</th>
                          <th>Year of Graduation</th>
                          <th>College</th>
                          <th>Known Languages</th>
                          <th></th>
                        </tr>
                        <tr class="form-label form-label-clr another__degree"
                          *ngFor="let job of eduInfoArray; let i = index">
                          <td style="padding-left: 0px !important">
                            {{ job.educatioNN }}
                          </td>
                          <td>{{ job.qualification }}</td>
                          <td>{{ job.yearOfGradd }}</td>
                          <td>{{ job.collUniNamee }}</td>
                          <td>{{ job.langKnoWN }}</td>
                          <!-- <td><i class="fa fa-edit" aria-hidden="true" (click)="deleteDegree(i)"></i> </td> -->
                          <td>
                            <img class="trash__icon" src="../../../assets/img/red-trash.svg" alt=""
                              (click)="deleteDegree(i)" />
                          </td>
                        </tr>
                      </table>

                      <div class="desktop__buttons">
                        <div class="d-md-flex col-md-12 pl-md-0 px-0" *ngFor="
                          let item of longdesciptionInfoData.jobskill;
                          let i = index
                        ">
                          <div class="col-md-4 field form-group-d new__arrow__weiGht pl-md-0 px-0 pr-md-3">
                            <label class="form-label form-label-clr">Skills Category<span
                                class="mandiatory">*</span></label>

                            <ng-select class="mt-1" [items]="sSkillsCategory" placeholder="Select skills category"
                              bindLabel="name" bindValue="name" [(ngModel)]="item.selectedsSkillsCategory"
                              name="skillCategory{{ i }}" #skillCategory="ngModel" [addTag]="true">
                            </ng-select>
                          </div>
                          <div class="col-md-4 field form-group-d skillSS new__arrow__weiGht px-0 pl-md-3">
                            <label class="form-label form-label-clr">Skills<span class="mandiatory">*</span></label>
                            <ng-select class="mt-1" [items]="skillsS" placeholder="Select document name"
                              bindLabel="name" bindValue="name" [(ngModel)]="item.selectedSkills"
                              name="item.skills{{ i }}" #skills="ngModel" [multiple]="true">
                            </ng-select>
                          </div>

                          <div class="col-md-4 ml-5 mt-5" style="margin-top: 33px !important">
                            <button type="button" class="plus-icon-button mr-1" *ngIf="
                              i == longdesciptionInfoData.jobskill.length - 1 ||
                              longdesciptionInfoData.jobskill.length == 1
                            " (click)="addSkill()">
                              <img src="../../../assets/img/new-new-plus-icon.png" alt="" />
                            </button>
                            <button type="button" class="submit-button delete-buttonN" (click)="deleteSkill(i)"
                              *ngIf="longdesciptionInfoData.jobskill.length > 1">
                              <img src="../../../assets/img/white-delete-icon.png" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <!-- mobile version -->
                      <div class="d-md-flex col-md-12 pl-md-0 px-0 mobile__buttons" *ngFor="
                      let item of longdesciptionInfoData.jobskill;
                      let i = index
                    ">
                        <div class="col-md-4 form-group-d new__arrow__weiGht pl-md-0 px-0 pr-md-3 mt-2">
                          <label class="form-label form-label-clr">Skills Category<span
                              class="mandiatory">*</span></label>

                          <ng-select [items]="sSkillsCategory" placeholder="Select skills category" bindLabel="name"
                            bindValue="name" [(ngModel)]="item.selectedsSkillsCategory" name="skillCategory{{ i }}"
                            #skillCategory="ngModel" [addTag]="true" style="width:75% !important">
                          </ng-select>
                        </div>
                        <div class="col-md-4 field form-group-d skillSS new__arrow__weiGht px-0 pl-md-3 mt-2">
                          <label class="form-label form-label-clr">Skills<span class="mandiatory">*</span></label>
                          <div class="d-flex" style="align-items: baseline;">
                            <ng-select [items]="skillsS" placeholder="Select document name" bindLabel="name"
                              bindValue="name" [(ngModel)]="item.selectedSkills" name="item.skills{{ i }}"
                              #skills="ngModel" [multiple]="true" style="width:75% !important">
                            </ng-select>
                            <div class="ml-2">
                              <div class="d-flex">
                                <button type="button" class="plus-icon-button mr-1" *ngIf="
                            i == longdesciptionInfoData.jobskill.length - 1 ||
                            longdesciptionInfoData.jobskill.length == 1
                          " (click)="addSkill()">
                                  <img src="../../../assets/img/new-new-plus-icon.png" alt="" />
                                </button>
                                <button type="button" class="submit-button delete-buttonN" (click)="deleteSkill(i)"
                                  *ngIf="longdesciptionInfoData.jobskill.length > 1">
                                  <img src="../../../assets/img/white-delete-icon.png" alt="" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                      <!-- mobile version -->


                      <!-- <div class="d-flex mt-3">
                        <div class="col-md-4 col-lg-4 field gs-form-group new__arrow__weiGht pl-0">
                          <label class="form-label form-label-clr">Skills</label>
                          <ng-select placeholder="Select skills" [items]="skillsS" bindLabel="name" bindValue="name"
                            [(ngModel)]="educationinfoData.selectedSkills" name="skillSS" #skillSS="ngModel">
                          </ng-select>
                        </div>

                        <div class="col-md-4 col-lg-4 field gs-form-group new__arrow__weiGht">
                          <label class="form-label form-label-clr">Skills Category</label>
                          <ng-select placeholder="Select skills category" [items]="sSkillsCategory" bindLabel="name"
                            bindValue="name" [(ngModel)]="educationinfoData.selectedsSkillsCategory"
                            name="skillsCAtegoryy" #skillsCAtegoryy="ngModel">
                          </ng-select>
                        </div>
                      </div> -->

                      <!-- <div class="col-md-4 field gs-form-group">
                            <label class="form-label form-label-clr new-PHOne__part">Languages Known</label>
                            <ng-select style="height:49px !important;" class="ng-selectct1" [items]="grade"
                              placeholder="Choose your grade" [searchable]="false" name="grade"
                              [(ngModel)]="educationinfoData.grade" #dlgnationality="ngModel"
                              bindLabel="gradeName" bindValue="gradeName">
                              <ng-template ng-option-tmp let-item="item">

                                <div class="item gs-country-item">
                                  <span>{{item.gradeName}}</span>
                                </div>
                              </ng-template>
                            </ng-select>


                          </div> -->

                      <!-- <div class="mt-4">
                        <label class="d-block form-label form-label-clr">Upload certificates</label>
                        <div
                          *ngIf=" (id  && (educationinfoData.candidateEducationDocuments) && (educationinfoData.candidateEducationDocuments.length > 0))">
                          <div
                            *ngFor="let workCertificate of educationinfoData.candidateEducationDocuments; let i= index; "
                            style="display:flex;">
                            <P *ngIf=" (id  && (workCertificate) && (workCertificate.certificateName) )"> <a
                                class="imp1" href="{{path}}{{workCertificate.certificatePath}}" target="blank">
                                {{workCertificate.certificateName}}
                              </a>
                            </P>
                            <button class="down"> -->
                      <!-- <img class="msg__delete" src="{{this.appSettings.imageUrl}}/img/candidate-profile/delete.png" alt=""/> -->
                      <!-- </button>
                          </div>
                        </div>

                        <ngx-dropzone *ngIf="( showDocumentview)" class="uploadView" id="certificateClass"
                          [multiple]="true">
                          <ngx-dropzone-label>Drop your file (PDF,PNG and Jpeg) <br><br>
                            <p class="bf1">Browse files </p>
                          </ngx-dropzone-label> -->
                      <!-- <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                              </ngx-dropzone-preview> -->
                      <!-- <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                *ngFor="let f of educationUploadFiles" [removable]="true" (removed)="onUploadRemove(f)"
                                [file]="f"> -->
                      <!-- <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label> -->
                      <!-- </ngx-dropzone-image-preview> -->
                      <!-- <ngx-dropzone-preview ngProjectAs="ngx-dropzone-preview" class="ngxView"
                            ngProjectAs="ngx-dropzone-preview" *ngFor="let f of educationUploadFiles" [removable]="true"
                            style="margin-left:150px !important;" (removed)="uploadResumeRemove(f)">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                          </ngx-dropzone-preview>
                        </ngx-dropzone>
                        <button *ngIf="id && hideButtoncertificate" class="btn next__buttons" type="button" value=""
                          data-toggle="modal" data-target="" (click)="updateWorkdocument()">Update Pdf</button> -->
                      <!-- <div class="dropzone file-upload-wrp border-0 gs-upload gs-upload--lg" id="candidate-certificate-dropzone">
                                <div class="dz-default dz-message needsclick my-0">
                                  <div class="msg-wrp"><img src="./img/upload-resume.png" alt="File upload icon"/>
                                    <p class="upload-txt d-none d-md-block"> Drop your file ( PDF, DOC, DOCX, JPG and PNG) or</p>
                                    <p class="upload-txt d-block d-md-none"> PDF, DOC, DOCX, JPG and PNG file</p>
                                    <div id="upload-label"><span class="upload-browse pt-md-2">Browse files</span></div>
                                  </div>
                                </div>
                                <div class="table table-striped mb-0" id="certificatepreviews2">
                                  <div class="file-row dz-preview" id="certificatetemplate2"> -->
                      <!-- This is used as the file preview template-->
                      <!-- <div class="dz-image"><img data-dz-thumbnail=""/>
                                      <div class="progress-resume">
                                        <div class="ko-progress-circle" data-progress="0">
                                          <div class="ko-circle">
                                            <div class="full ko-progress-circle__slice">
                                              <div class="ko-progress-circle__fill"></div>
                                            </div>
                                            <div class="ko-progress-circle__slice">
                                              <div class="ko-progress-circle__fill"></div>
                                              <div class="ko-progress-circle__fill ko-progress-circle__bar"></div>
                                            </div>
                                          </div>
                                          <div class="ko-progress-circle__overlay"><span class="progress-txt">0</span></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <p class="name" data-dz-name=""></p>
                                    </div>
                                    <div class="close-icon" data-dz-remove=""><span></span><span></span></div>
                                  </div>
                                </div>
                              </div> -->

                      <!-- </div> -->
                    </div>
                    <div class="desktop__buttons">
                      <div class="text-center text-md-right pb-4 d-flex justify-content-between edu__Info__main"
                        style="margin-top: 20px; position: relative">
                        <button
                          class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info"
                          type="button" value="work-info" (click)="sideBarClick('eductionButton')">
                          <i class="icon-angle-down angle-rotate-d"></i>Work
                          History
                        </button>
                        <div class="gs-btn-group">
                          <!-- <button class="btn btn--primary-d" type="button" value="job-description" data-toggle="modal"
                          data-target="#save-model">Save as Draft</button> -->
                          <button class="btn btn--primary-d next__buttons family__info__next" type="button"
                            [disabled]="educationForm.invalid" (click)="GennInfo()" value="work-history">
                            General Information<i class="icon-angle-down angle-rt27-d"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- mobile buttons -->
                    <div
                      class="text-center text-md-right pb-4 d-flex justify-content-between edu__Info__main mobile__buttons"
                      style="margin-top: 20px; position: relative">

                      <button
                        class="btn btn--primary-d next__buttons family__info__next relative media__next__button float__right"
                        type="button" (click)="GennInfo()" value="work-history">
                        Next<i class="icon-angle-down angle-rt27-d"></i>
                      </button>

                      <button
                        class="btn btn--primary-d relative js-prev previous next__buttons second__Back back__general__info media__back__button float__left"
                        type="button" value="work-info" (click)="sideBarClick('eductionButton')">
                        <i class="icon-angle-down angle-rotate-d"></i>Back
                      </button>
                    </div>
                    <!-- mobile buttons -->


                  </div>
                </div>
                <!-- <div class="col-md-12 text-center text-md-right pr-xl-5 mt-4">
                      <button class="btn btn--primary brder-primary js-next js-tab-sec" data-id="family-info" type="button" (click)="educationalInfo()"
                       value="educational-information">Next</button>
                    </div> -->
              </div>
            <!-- </div> -->
          </form>
          <!--START: Family-information-->

          <!-- general information screen starts -->
          <form class="col-md-7 col-xl-9" novalidate #GenInfooForm="ngForm" *ngIf="showGeneralInfooo">
            <!-- <div class="gs-steps-container ml-md-0 mobileee__margin__left" -->
              <!-- style="box-shadow: 0 3px 7px rgb(0 0 0 / 4%)"> -->
              <div class="steps-form ui form">
                <div class="gs-panel">
                  <!--Panel-header-->
                  <div class="gs-panel__header change_head_clr">
                    <h5 class="gs-form-panel__heading mb-0">
                      4. General Information
                    </h5>
                  </div>

                  <!-- Address-->
                  <div class="gs-steps-categ new__Dotted__line app_new__General" style="padding: 20px">
                    <h6 class="gs-panel__subheading">1. Address</h6>
                    <h6 class="gs-panel__subheading">Permanent Address</h6>
                    <div class="row">
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Address Line 1<span
                            class="errorRequired">*</span></label>
                        <input class="form-input form-input_1" type="text" name="addRessLineOne"
                          #addRessLineOne="ngModel" [(ngModel)]="permanentAddress" placeholder="Enter address line 1" />
                      </div>
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Address Line 2<span
                            class="errorRequired">*</span></label>
                        <input class="form-input form-input_1" type="text" name="addRessLineTwo"
                          #addRessLineTwo="ngModel" [(ngModel)]="permanentAddressTwo"
                          placeholder="Enter address line 2" />
                      </div>
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Address Line 3<span
                            class="errorRequired">*</span></label>
                        <input class="form-input form-input_1" type="text" name="addRessLineThree"
                          #addRessLineThree="ngModel" [(ngModel)]="permanentAddressThree"
                          placeholder="Enter address line 3" />
                      </div>

                      <!-- <div class="col-md-4 field gs-form-group cityid">
                        <label class="form-label form-label-clr">City (Candidate Location)<span
                            class="errorRequired">*</span></label>
                        <input class="form-input form-input_1 new__Placeholder" type="text" name="maincityy"
                          onkeydown="return /[a-z ]/i.test(event.key)" #maincity="ngModel"
                          [(ngModel)]="permanentAddressFour" placeholder="Enter city" />
                        <div *ngIf="(maincity.touched || GenInfooForm.submitted) && maincity.errors?.required">
                          <span class="errorMessage" *ngIf="maincity.errors.required">
                            Please select city
                          </span>
                        </div>
                      </div> -->
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">State<span class="errorRequired">*</span></label>
                        <input class="new__State new__Placeholder" placeholder="Enter state" type="text" name="state"
                          #state="ngModel" onkeydown="return /[a-z ]/i.test(event.key)"
                          pattern="^[^\s]+[-a-zA-Z\s]+([-a-zA-Z]+)*$" [(ngModel)]="permanentAddressFive" />
                      </div>
                      <div class="col-md-4 col-lg-4 field gs-form-group new__arrow__weiGht">
                        <label class="form-label form-label-clr">Country<span class="errorRequired">*</span></label>

                        <ng-select [items]="CountryOne" bindLabel="name" bindValue="id"
                          [(ngModel)]="permanentAddressSix" placeholder="Select country" [searchable]="false"
                          name="addressCountryy" #addressCountry="ngModel">
                        </ng-select>

                        <!-- <ng-select [items]="countryNationality" placeholder="Select country" [searchable]="false"
                          [(ngModel)]="basicInfoData.address.country" name="country" #ddlnationality="ngModel"
                          bindLabel="countryname" bindValue="countryname">
                          <ng-template ng-option-tmp let-item="item">
                            <div class="item gs-country-item">
                              <span>{{item.countryname}}</span><i class="{{item.flag}}"></i>
                            </div>
                          </ng-template>
                        </ng-select> -->
                      </div>
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Zip Code<span class="errorRequired">*</span></label>
                        <input class="form-input form-input_1" type="text" name="zipcodee"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          #zipcode="ngModel" minlength="4" maxlength="6" [(ngModel)]="permanentAddressSeven"
                          placeholder="Enter your zip code" />
                      </div>

                      <div class="col-md-4 field gs-form-group numberField new-PHOne__part new__arrow__weiGht">
                        <label class="form-label form-label-clr">Alternate Phone Number<span
                            class="errorRequired">*</span></label>
                        <div class="gs-labeled-drodown">
                          <ng-select [items]="addressPhone" placeholder="+91" bindLabel="name" bindValue="id"
                            [(ngModel)]="selectAddressPhone" name="addRessPhone" #addRessPhone="ngModel">
                          </ng-select>
                          <input type="text" class="form-input labeled-input salary-rang form-input_1 new__Placeholder"
                            pattern="^[789]\d{9}$" name="permanentPhoneNumber" #permanentPhoneNumber="ngModel"
                            maxlength="10" [(ngModel)]="permanentAddressEight"
                            placeholder="Enter alternate phone number" />
                        </div>
                        <div *ngIf="permanentPhoneNumber.errors">
                          <span class="errorMessage" *ngIf="permanentPhoneNumber.errors?.pattern">
                            Invalid phone number
                          </span>
                        </div>
                      </div>

                      <div class="col-md-12 mt-4 mb-4">
                        <div class="ui side_current_items">
                          <input class="checkbox" type="checkbox" name="checkAddress" tabindex="0"
                            #checkAddress="ngModel" [(ngModel)]="sameAsCurrent" (change)="updatePermanentAddress()"
                            id="checkBox" checked="checked" />
                          <label class="option-label fnt-sm form-label-clr mt-1">Same as Permanent Address</label>
                        </div>
                      </div>

                      <!--START: Date of Birth AND City-->

                      <h6 class="gs-panel__subheading col-md-12">
                        Current Address
                      </h6>
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Address Line 1<span
                            class="errorRequired">*</span></label>
                        <input class="form-input form-input_1" type="text" name="addRessLineOneOne"
                          #addRessLineOneOne="ngModel" [(ngModel)]="currentAddress" placeholder="Enter address line 1"
                          [disabled]="sameAddressDisabled" />
                      </div>
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Address Line 2<span
                            class="errorRequired">*</span></label>
                        <input class="form-input form-input_1" type="text" name="addRessLineTwoTwo"
                          #addRessLineTwoTwo="ngModel" [(ngModel)]="currentAddressTwo"
                          placeholder="Enter address line 2" [disabled]="sameAddressDisabled" />
                      </div>
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Address Line 3<span
                            class="errorRequired">*</span></label>
                        <input class="form-input form-input_1" type="text" name="addRessLineTwoThree"
                          #addRessLineTwoThree="ngModel" [(ngModel)]="currentAddressThree"
                          placeholder="Enter address line 3" [disabled]="sameAddressDisabled" />
                      </div>

                      <!-- <div class="col-md-4 field gs-form-group cityid">
                        <label class="form-label form-label-clr">City (Candidate Location)<span
                            class="errorRequired">*</span></label>
                        <input class="form-input form-input_1 new__Placeholder" type="text" name="maincity"
                          onkeydown="return /[a-z ]/i.test(event.key)" #maincity="ngModel"
                          [(ngModel)]="currentAddressFour" placeholder="Enter city" [disabled]="sameAddressDisabled" />
                        <div *ngIf="(maincity.touched || GenInfooForm.submitted) && maincity.errors?.required">
                          <span class="errorMessage" *ngIf="maincity.errors.required">
                            Please select city
                          </span>
                        </div>
                      </div> -->
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">State<span class="errorRequired">*</span></label>
                        <input class="new__State new__Placeholder" placeholder="Enter state" type="text" name="statee"
                          #statee="ngModel" onkeydown="return /[a-z ]/i.test(event.key)"
                          pattern="^[^\s]+[-a-zA-Z\s]+([-a-zA-Z]+)*$" [(ngModel)]="currentAddressFive"
                          [disabled]="sameAddressDisabled" />
                      </div>
                      <div class="col-md-4 col-lg-4 field gs-form-group new__arrow__weiGht">
                        <label class="form-label form-label-clr">Country<span class="errorRequired">*</span></label>

                        <ng-select [items]="CountryOne" bindLabel="name" bindValue="id" [(ngModel)]="currentAddressSix"
                          placeholder="Select country" [searchable]="false" name="addressCountry"
                          #addressCountry="ngModel" [disabled]="sameAddressDisabled">
                        </ng-select>

                        <!-- <ng-select [items]="countryNationality" placeholder="Select country" [searchable]="false"
                          [(ngModel)]="basicInfoData.address.country" name="country" #ddlnationality="ngModel"
                          bindLabel="countryname" bindValue="countryname">
                          <ng-template ng-option-tmp let-item="item">
                            <div class="item gs-country-item">
                              <span>{{item.countryname}}</span><i class="{{item.flag}}"></i>
                            </div>
                          </ng-template>
                        </ng-select> -->
                      </div>
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Zip Code<span class="errorRequired">*</span></label>
                        <input class="form-input form-input_1" type="text" name="zipcode"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          #zipcode="ngModel" minlength="4" maxlength="6" [(ngModel)]="currentAddressSeven"
                          placeholder="Enter zip code" [disabled]="sameAddressDisabled" />
                      </div>

                      <div class="col-md-4 field gs-form-group numberField new-PHOne__part new__arrow__weiGht mb-3">
                        <label class="form-label form-label-clr">Alternate Phone Number<span
                            class="errorRequired">*</span></label>
                        <div class="gs-labeled-drodown">
                          <ng-select placeholder="+91" [items]="addressPhoneTwo" bindLabel="name" bindValue="id"
                            [(ngModel)]="selectAddressPhoneTwo" name="addressPhoneNumberTwo"
                            #addressPhoneNumberTwo="ngModel" [disabled]="sameAddressDisabled">
                          </ng-select>

                          <!-- <ng-select class="line" [items]="phoneCode" [clearable]="false" [searchable]="false"
                            name="currentPhoneCode" [(ngModel)]="basicInfoData.currentAddress.currentPhoneCode"
                            #ddcnationality="ngModel" bindLabel="name" bindValue="name">
                            <ng-template ng-option-tmp let-item="item">
                              <div class="item gs-country-item"><span>{{item.name}}</span></div>
                            </ng-template>
                          </ng-select> -->
                          <input type="text" class="form-input labeled-input salary-rang form-input_1 new__Placeholder"
                            pattern="^[789]\d{9}$" #currentPhoneNumber="ngModel" maxlength="10"
                            [(ngModel)]="currentAddressEight" name="currentPhoneNumber"
                            placeholder="Enter alternate phone number" [disabled]="sameAddressDisabled" />
                        </div>
                        <div *ngIf="currentPhoneNumber.errors">
                          <span class="errorMessage" *ngIf="currentPhoneNumber.errors?.pattern">
                            Invalid phone number
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 3. Immigration Status-->
                  <div class="gs-steps-categ app_new__General app_new__Generall" style="padding: 20px">
                    <h6 class="gs-panel__subheading">2. Immigration Status<span class="errorRequired">*</span></h6>
                    <div class="col-md-12 field gs-form-group pl-0">
                      <label class="form-label form-label-clr">Do you have the relevant visa to work in the country
                        (only to be filled by non-citizens)</label>
                      <div class="gs-grouped-fileds">
                        <div class="ui radio checkbox gs-grouped-filed checked">
                          <input class="" type="radio" name="relevantVisa" value="true" checked="true" required
                            (click)="relevantVisa($event, true)" [(ngModel)]="basicInfoData.immigration.relevantVisa"
                            tabindex="0" />
                          <label class="grouped-label">Yes </label>
                        </div>
                        <div class="ui radio checkbox gs-grouped-filed">
                          <input class="" type="radio" name="relevantVisa" value="false" tabindex="0"
                            (click)="relevantVisa($event, false)" required
                            [(ngModel)]="basicInfoData.immigration.relevantVisa" />
                          <label class="grouped-label">No</label>
                        </div>
                        <!-- <div *ngIf="(relevantVisa.touched || basicInfo.submitted) && relevantVisa.errors?.required">
                            <span class="errorMessage">
                              Please select relevant visa
                            </span>
                          </div> -->
                      </div>
                    </div>

                    <div class="row" *ngIf="showRelavntVisa">
                      <!-- <div class="dropzone file-upload-wrp border-0 gs-upload gs-upload--lg" id="candidate-visa-dropzone">
                            <div class="dz-default dz-message needsclick my-0">
                              <div class="msg-wrp"><img class="mb-md-3" src="./img/upload-resume.png" alt="File upload"/>
                                <p class="upload-txt d-none d-md-block"> Drop your file ( PDF, DOC, DOCX, JPG and PNG) or</p>
                                <p class="upload-txt d-block d-md-none"> PDF, DOC, DOCX, JPG and PNG file</p>
                                <div id="upload-label"><span class="upload-browse pt-md-2">Browse files</span></div>
                              </div>
                            </div>
                            <div class="table table-striped mb-0" id="previewscandidate">
                              <div class="file-row dz-preview" id="templatecandidate"> -->
                      <!-- This is used as the file preview template-->
                      <!-- <div class="dz-image"><img data-dz-thumbnail=""/>
                                  <div class="progress-resume">
                                    <div class="ko-progress-circle" data-progress="0">
                                      <div class="ko-circle">
                                        <div class="full ko-progress-circle__slice">
                                          <div class="ko-progress-circle__fill"></div>
                                        </div>
                                        <div class="ko-progress-circle__slice">
                                          <div class="ko-progress-circle__fill"></div>
                                          <div class="ko-progress-circle__fill ko-progress-circle__bar"></div>
                                        </div>
                                      </div>
                                      <div class="ko-progress-circle__overlay"><span class="progress-txt">0</span></div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p class="name" data-dz-name=""></p>
                                </div>
                                <div class="close-icon" data-dz-remove=""><span></span><span></span></div>
                              </div>
                            </div>
                          </div> -->
                      <!-- <p *ngIf="showRelvantimage" class="errorMessage">Please upload the documents</p> -->
                    </div>
                    <div class="row">
                      <!-- <div class="col-md-4 field gs-form-group new__arrow__weiGht">
                        <label class="form-label form-label-clr">Work Permit</label>
                        <input class="disable__Background" type="text" name="WorkPermitT"
                          [(ngModel)]="basicInfoData.immigration.WorkPermitT" placeholder="Enter work permit"
                          #WorkPermitT="ngModel" readonly />
                        </div> -->
                      <!-- <ng-select [items]="workPermitt" bindLabel="name" bindValue="id"
                          [(ngModel)]="selectedWworkPermitt" name="workPERMitt" #workPERMitt="ngModel"
                          placeholder="Select work permit" (change)="WorkPermitChange($event)">
                        </ng-select> -->

                      <div class="col-md-4 field gs-form-group new__arrow__weiGht">
                        <label class="form-label form-label-clr">Work Permit Type<span
                            class="errorRequired">*</span></label>
                        <ng-select [items]="WorkPermitType" bindLabel="name" bindValue="name"
                          [disabled]="disabledWorkPermitType" [(ngModel)]="selectedWorkPermitType"
                          placeholder="Select work permit type" name="workPERMittype" #workPERMittype="ngModel">
                        </ng-select>
                      </div>
                      <div class="col-md-4"></div>
                      <div class="col-md-4"></div>

                      <!-- <div class="col-md-4 field gs-form-group new__arrow__weiGht">
                        <label class="form-label form-label-clr">Sponsorship</label>
                        <ng-select [items]="Sponsorship" bindLabel="name" bindValue="id"
                          [(ngModel)]="selectedSponsorship" placeholder="Select sponsorship" name="sponsorSHIpp"
                          #sponsorSHIpp="ngModel" [disabled]="sponsorShipDisabled">
                        </ng-select>
                      </div> -->

                      <!-- <div class="col-md-4 field gs-form-group new__arrow__weiGht">
                        <label class="form-label form-label-clr">Select the Country</label>

                        <ng-select [items]="CountryTwo" bindLabel="name" bindValue="id" placeholder="Select country"
                          [(ngModel)]="selectCountryTwo" name="immigCountryyy" #immigCountryyy="ngModel">
                        </ng-select> -->

                      <!-- <ng-select class="ng-selectct1" [items]="countryNationality" placeholder="Select country"
                          [searchable]="false" name="residentCountry"
                          [(ngModel)]="basicInfoData.immigration.residentCountry" #ddlnationality="ngModel"
                          bindLabel="countryname" bindValue="countryname">
                          <ng-template ng-option-tmp let-item="item">
                            <div class="item gs-country-item">
                              <span>{{item.countryname}}</span><i class="{{item.flag}}"></i>
                            </div> -->
                      <!-- <div  class="item gs-country-item" *ngIf="item.name == 'Aland Islands'">
                                <span>Aland Islands</span><i class="ax flag"></i>
                                </div>
                                <div class="item gs-country-item" *ngIf="item.name == 'Albania'">
                                <span >Albania</span><i class="al flag"></i>
                                </div>
                                <div class="item gs-country-item" *ngIf="item.name == 'Algeria'">
                                    <span >Algeria</span><i class="dz flag"></i>
                                </div>
                                <div class="item gs-country-item" *ngIf="item.name == 'American Samoa'">
                                    <span >American Samoa</span><i class="as flag"></i>
                                </div>
                                <div class="item gs-country-item" *ngIf="item.name == 'United Arab Emirates'">
                                    <span >United Arab Emirates</span><i class="ae flag"></i>
                                 </div>
                                 <div class="item gs-country-item" *ngIf="item.name == 'United States'">
                                    <span >United States</span><i class="us flag"></i>
                                 </div>
                                 <div class="item gs-country-item" *ngIf="item.name == 'Uruguay'">
                                    <span >Uruguay</span><i class="uy flag"></i>
                                 </div> -->
                      <!-- </ng-template>
                        </ng-select> -->
                      <!-- </div> -->
                      <!-- <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Type of resident</label>
                        <input class="form-input form-input_1" type="text" name="typeOfResident"
                          [(ngModel)]="basicInfoData.immigration.typeOfResident" placeholder="Enter resident"
                          #typeOfResident="ngModel" />
                      </div> -->

                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Passport Number<span
                            class="errorRequired">*</span></label>
                        <input class="form-input form-input_1" type="text" (keypress)="keyPressAlphanumeric($event)"
                          name="passportNumber" [(ngModel)]="basicInfoData.immigration.passportNumber"
                          #passportNumber="ngModel" placeholder="Enter passport number" />
                      </div>

                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Date of Issue<span
                            class="errorRequired">*</span></label>
                        <p-calendar placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" readonly="false" #myCalender
                          (onSelect)="onDateSelected($event, 'immigration')" name="dateofIssue"
                          [(ngModel)]="basicInfoData.immigration.dateofIssue" [ngModelOptions]="{ standalone: true }"
                          [showIcon]="true" inputId="icon" #dateofIssue="ngModel"></p-calendar>
                      </div>
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Date of Expiry<span
                            class="errorRequired">*</span></label>
                        <p-calendar name="dateofExpiry" class="doe" placeholder="DD/MM/YYYY" readonly="false"
                          [minDate]="minExpireDate" [maxDate]="maxExprireDate"
                          [(ngModel)]="basicInfoData.immigration.dateofExpiry" dateFormat="dd/mm/yy" disabled="true"
                          [ngModelOptions]="{ standalone: true }" [showIcon]="true" inputId="icon"
                          #dateofExpiry="ngModel"></p-calendar>
                      </div>

                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Visa Number<span class="errorRequired">*</span></label>
                        <input class="form-input form-input_1" type="text" (keypress)="keyPressAlphanumeric($event)"
                          name="visaNumber" [(ngModel)]="basicInfoData.immigration.visaNumber" #visaNumber="ngModel"
                          placeholder="Enter visa number" />
                      </div>

                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Date of Issue<span
                            class="errorRequired">*</span></label>
                        <p-calendar placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" readonly="false" #myCalender
                          (onSelect)="onDateSelected($event, 'immigration')" name="visadateofIssue" [(ngModel)]="
                            basicInfoData.immigration.visadateofIssue
                          " [ngModelOptions]="{ standalone: true }" [showIcon]="true" inputId="icon"
                          #visadateofIssue="ngModel"></p-calendar>
                      </div>
                      <div class="col-md-4 field gs-form-group">
                        <label class="form-label form-label-clr">Date of Expiry<span
                            class="errorRequired">*</span></label>
                        <p-calendar name="visadateofExpiry" class="doe" placeholder="DD/MM/YYYY" readonly="false"
                          [minDate]="minExpireDate" [maxDate]="maxExprireDate" [(ngModel)]="
                            basicInfoData.immigration.visadateofExpiry
                          " dateFormat="dd/mm/yy" disabled="true" [ngModelOptions]="{ standalone: true }"
                          [showIcon]="true" inputId="icon" #visadateofExpiry="ngModel"></p-calendar>
                      </div>
                      <!-- <div class="col-md-8 field gs-form-group">
                        <label class="form-label form-label-clr">Please upload the relevant visa page
                          <span class="errorRequired">*</span></label>
                        <ngx-dropzone (change)="onSelect3($event)" class="" id="immgrationUpload" [multiple]="false"
                          [accept]="
                            'image/jpeg,image/jpg,image/png,application/pdf'
                          ">
                          <ngx-dropzone-label style="font-size: 13px !important">
                            <img class="mb-md-3" src="./assets/img/upload-resume.png" alt="File upload icon" />
                            Drop your file ( PDF, JPG and PNG ) or <br />
                            <p class="bf1">Browse files</p>
                          </ngx-dropzone-label>

                          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files2"
                            [removable]="true" (removed)="onImggiationRemove(f)" [file]="f">
                            <ngx-dropzone-label style="
                                margin-left: 150px !important;
                                font-size: 11px !important;
                              ">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                          </ngx-dropzone-image-preview>
                        </ngx-dropzone>
                      </div> -->
                    </div>


                    <div class="new__Dotted__line"></div>

                    <div class="desktop__buttons mt-3">
                      <div class="text-center text-md-right d-flex justify-content-between edu__Info__main"
                        style="position: relative">
                        <button
                          class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info"
                          type="button" value="work-info" (click)="genInfoBack()">
                          <i class="icon-angle-down angle-rotate-d"></i>Educational
                          Information
                        </button>
                        <div class="gs-btn-group">
                          <!-- <button class="btn btn--primary-d" type="button" value="job-description" data-toggle="modal"
                  data-target="#save-model">Save as Draft</button> -->
                          <button class="btn btn--primary-d next__buttons family__info__next" type="button"
                            (click)="GennInfoNext()" value="work-history">
                            Declarations<i class="icon-angle-down angle-rt27-d"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      class="text-center text-md-right d-flex justify-content-between edu__Info__main mobile__buttons"
                      style="position: relative">
                      <button class="btn btn--primary-d next__buttons family__info__next media__next__button"
                        type="button" (click)="GennInfoNext()" value="work-history">
                        Next<i class="icon-angle-down angle-rt27-d"></i>
                      </button>
                      <button
                        class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info media__back__button float__left"
                        type="button" value="work-info" (click)="genInfoBack()">
                        <i class="icon-angle-down angle-rotate-d"></i>Back
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            <!-- </div> -->
          </form>
          <!-- general information screen ends -->
          <!-- declerations screen starts -->
          <form class="col-md-7 col-xl-9" novalidate #declerationssForm="ngForm" *ngIf="showDeclerationSs">
            <div class="gs-steps-container ml-md-0 mobileee__margin__left"
              style="box-shadow: 0 3px 7px rgb(0 0 0 / 4%)">
              <div class="steps-form ui form">
                <div class="gs-panel">
                  <!--Panel-header-->
                  <div class="gs-panel__header change_head_clr">
                    <h5 class="gs-form-panel__heading mb-0">5. Declaration</h5>
                  </div>

                  <!--Panel-body-->
                  <div class="gs-panel__body gs-form-panel gs-form-panel--sm pb-0">
                    <div class="row">
                      <div class="col-md-12 field gs-form-group">
                        <label class="form-label form-label-clr mt-3">Have you ever been convicted in a court of law of
                          any
                          country?</label>
                        <div class="gs-grouped-fileds mb-3">
                          <div class="ui radio checkbox gs-grouped-filed checked">
                            <input class="" type="radio" name="courtofLaw" value="Yes" #courtofLaw="ngModel"
                              (click)="lawcountryEnable($event, true)" [(ngModel)]="familyinfoData.courtofLaw"
                              checked="true" />
                            <label style="font-size: 12px" [ngStyle]="{
                                color:
                                  familyinfoData.priority == 'Yes'
                                    ? 'black'
                                    : 'gray'
                              }">Yes</label>
                          </div>
                          <div class="ui radio checkbox gs-grouped-filed">
                            <input class="" type="radio" name="courtofLaw" value="No" #priority="ngModel"
                              [(ngModel)]="familyinfoData.courtofLaw" (click)="lawcountryEnable($event, false)" />
                            <label style="font-size: 12px" [ngStyle]="{
                                color:
                                  familyinfoData.courtofLaw == 'No'
                                    ? 'black'
                                    : 'gray'
                              }">No
                            </label>
                          </div>
                        </div>

                        <div class="js-if-true" *ngIf="countryEnable">
                          <!-- If you means -->
                          <label class="form-label form-label-clr">If your answer was ‘Yes’ please mention details of
                            your conviction</label>
                          <div class="field">
                            <textarea class="gs-textarea" row="1" name="courtofLawDescription"
                              #courtofLawDescription="ngModel" [(ngModel)]="familyinfoData.courtofLawDescription"
                              placeholder="Enter the details…"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 field gs-form-group">
                        <label class="form-label form-label-clr">Have you ever been dismissed, discharged or suspended
                          from employment?</label>
                        <div class="gs-grouped-fileds mb-3">
                          <div class="ui radio checkbox gs-grouped-filed checked">
                            <input class="" type="radio" name="employeeDischargeStatus" value="Yes" checked="true"
                              (click)="suspendCheck($event, true)" #employeeDischargeStatus="ngModel" [(ngModel)]="
                                familyinfoData.employeeDischargeStatus
                              " />
                            <label class="grouped-label">Yes </label>
                          </div>
                          <div class="ui radio checkbox gs-grouped-filed">
                            <input class="" type="radio" name="employeeDischargeStatus" value="No"
                              (click)="suspendCheck($event, false)" #employeeDischargeStatus="ngModel" [(ngModel)]="
                                familyinfoData.employeeDischargeStatus
                              " />
                            <label class="grouped-label">No</label>
                          </div>
                        </div>
                        <div class="js-if-true" *ngIf="suspendEnable">
                          <!-- If you means -->
                          <label class="form-label form-label-clr">If your answer was ‘Yes’ please mention details of
                            dismissal</label>
                          <div class="field">
                            <textarea class="gs-textarea" row="1 " name="employeeDischargeDesc"
                              #employeeDischargeDesc="ngModel" [(ngModel)]="familyinfoData.employeeDischargeDesc"
                              placeholder="Enter the details…"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 field gs-form-group">
                        <label class="form-label form-label-clr">Do you suffer from any of the following?
                        </label>
                        <div class="gs-grouped-fileds pt-0">
                          <!-- <div class="ui radio checkbox gs-grouped-filed checked">
                              <input class="" type="radio" name="otherStatus"
                                (click)="disabilityCheck($event,true)" [value]="YesStatus" #otherStatus="ngModel"
                                [(ngModel)]="familyinfoData.otherStatus"  checked="true"/>
                              <label class="grouped-label">Yes </label>
                            </div> -->
                          <!-- <div class="ui radio checkbox gs-grouped-filed">
                              <input class="" type="radio" name="otherStatus" [value]="NoStatus"
                                (click)="disabilityCheck($event,false)" #otherStatus="ngModel"
                                [(ngModel)]="familyinfoData.otherStatus" />
                              <label class="grouped-label">No</label>
                            </div>
                          </div> -->

                          <div class="gs-grouped-fileds pt-0">
                            <div class="ui radio checkbox gs-grouped-filed checked">
                              <input class="" type="radio" name="otherStatus" value="Yes" #otherStatus="ngModel"
                                (click)="disabilityCheck($event, true)" [(ngModel)]="familyinfoData.otherStatus"
                                checked="true" />
                              <label style="font-size: 12px" [ngStyle]="{
                                  color:
                                    familyinfoData.otherStatus == 'Yes'
                                      ? 'black'
                                      : 'gray'
                                }">Yes</label>
                            </div>
                            <div class="ui radio checkbox">
                              <input class="" type="radio" name="otherStatus" value="No" #otherStatus="ngModel"
                                [(ngModel)]="familyinfoData.otherStatus" (click)="disabilityCheck($event, false)" />
                              <label style="font-size: 12px" [ngStyle]="{
                                  color:
                                    familyinfoData.otherStatus == 'No'
                                      ? 'black'
                                      : 'gray'
                                }">No
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="js-if-true" *ngIf="checkDisability">
                          <!-- If you means -->
                          <label class="form-label form-label-clr">Please mention details of disability</label>
                          <div class="field">
                            <textarea class="gs-textarea" row="1" name="otherStatusDescription"
                              #otherStatusDescription="ngModel" [(ngModel)]="
                                familyinfoData.otherStatusDescription
                              " placeholder="Enter the details…"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex">
                      <div>
                        <input class="checkbox" type="checkbox" id="chkTermsandConditons" name="chkTermsandConditons"
                          [value]="true" [(ngModel)]="termsAndConditions" (change)="termsAndConditionsChange($event)"
                          tabindex="0" />
                      </div>
                      <div class="ml-2">
                        <label class="option-label fnt-xs" for="chkTermsandConditons">I
                          acknowledge and agree to GIIS’s document
                          retention and privacy terms and conditions.</label>
                      </div>
                    </div>


                    <!-- <button class="btn btn--primary-d js-prev previous next__buttons second__Back back__FamILY__info"
                      type="button" value="work-info" (click)="sideBarClick('FamilPart')"><i
                        class="icon-angle-down angle-rotate-d"></i>Family Information</button> -->

                    <div class="desktop__buttons">
                      <div class="text-center text-md-right d-flex justify-content-between edu__Info__main"
                        style="margin-top: 20px; position: relative">
                        <button
                          class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info"
                          type="button" value="work-info" (click)="DeclarationsBack()">
                          <i class="icon-angle-down angle-rotate-d"></i>General
                          Information
                        </button>
                        <div class="gs-btn-group">
                          <!-- <button class="btn btn--primary-d" type="button" value="job-description" data-toggle="modal"
                  data-target="#save-model">Save as Draft</button> -->
                          <button class="btn btn--primary-d next__buttons family__info__next" type="button"
                            (click)="DeclerationsNext()" value="work-history">
                            Documents<i class="icon-angle-down angle-rt27-d"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="text-center text-md-right d-flex justify-content-between edu__Info__main mobile__buttons"
                      style="margin-top: 20px; position: relative">
                      <button class="btn btn--primary-d next__buttons family__info__next" type="button"
                        (click)="DeclerationsNext()" value="work-history">
                        next<i class="icon-angle-down angle-rt27-d"></i>
                      </button>

                      <button
                        class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info float__left"
                        type="button" value="work-info" (click)="DeclarationsBack()">
                        <i class="icon-angle-down angle-rotate-d"></i>Back
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- declerations screen ends -->
          <!-- documents screen starts -->
          <form class="col-md-7 col-xl-9 px-0" novalidate #DoCUMentsForm="ngForm" *ngIf="showDocumentssS">
            <div class="gs-steps-container ml-0 mobileee__margin__left" style="box-shadow: 0 3px 7px rgb(0 0 0 / 4%)">
              <div class="steps-form ui form">
                <div class="gs-panel">
                  <!--Panel-header-->
                  <div class="gs-panel__header change_head_clr">
                    <h5 class="gs-form-panel__heading mb-0">6. Documents</h5>
                  </div>
                  <div class="document_screen_main">
                    <h1 class="gs-panel__subheading">Documents</h1>

                    <div class="document__one" >
                      <h2 class="form-label form-label-clr">Upload Visa </h2>

                      <ngx-dropzone (change)="onSelectDoc($event)">
                        <ngx-dropzone-label style="font-size: 10px !important">
                          <img class="" src="../../assets/img/upload-resume-sm.png" alt="File upload icon" />
                          Drop your file ( PDF, JPG and PNG ) or <br />
                          <p class="bf1">Browse files</p>
                        </ngx-dropzone-label>

                        <ngx-dropzone-preview class="mx-auto" *ngFor="let f of filesDocs" [removable]="true"
                          (removed)="onRemoveDoc(f)">
                          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                      </ngx-dropzone>
                      <!-- <ngx-dropzone (change)="onSelectDoc($event)" [expandable]="true">
                        <ngx-dropzone-label style="font-size: 10px !important">
                          <img class="" src="./assets/img/upload-resume.png" alt="File upload icon" />
                          <span> Drop your file ( PDF, JPG and PNG ) or</span> <br />
                          <p class="bf1">Browse files</p>
                        </ngx-dropzone-label>
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of filesDocs"
                          [file]="f" [removable]="true" (removed)="onRemoveDoc(f)">
                          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-image-preview>
                      </ngx-dropzone> -->


                    </div>
                    <div class="document__two">
                      <h2 class="form-label form-label-clr">Upload Document 2 </h2>
                      <!-- <ngx-dropzone (change)="onSelectDocOne($event)">
                        <ngx-dropzone-label style="font-size: 10px !important">
                          <img class="" src="./assets/img/upload-resume.png" alt="File upload icon" />
                          Drop your file ( PDF, JPG and PNG ) or <br />
                          <p class="bf1">Browse files</p>
                        </ngx-dropzone-label>

                        <ngx-dropzone-preview class="mx-auto" *ngFor="let f of filesDocs" [removable]="true"
                          (removed)="onRemoveDocone(f)">
                          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-preview>
                      </ngx-dropzone> -->
                      <ngx-dropzone (change)="onSelectDocOne($event)" [expandable]="true">
                        <ngx-dropzone-label style="font-size: 10px !important">
                          <img class="" src="./assets/img/upload-resume.png" alt="File upload icon" />
                          <span> Drop your file ( PDF, JPG and PNG ) or</span> <br />
                          <p class="bf1">Browse files</p>
                        </ngx-dropzone-label>
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of filesDocs1"
                          [file]="f" [removable]="true" (removed)="onRemoveDocone(f)">
                          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-image-preview>
                      </ngx-dropzone>
                    </div>
                    <div class="document__three">
                      <h2 class="form-label form-label-clr">Upload Document 3 </h2>
                      <ngx-dropzone (change)="onSelectDocTwo($event)">
                        <ngx-dropzone-label style="font-size: 10px !important">
                          <img class="" src="./assets/img/upload-resume.png" alt="File upload icon" />
                          <span> Drop your file ( PDF, JPG and PNG ) or</span> <br />
                          <p class="bf1">Browse files</p>
                        </ngx-dropzone-label>

                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of filesDocs2"
                          [file]="f" [removable]="true" (removed)="onRemoveDoctwo(f)">
                          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-image-preview>
                      </ngx-dropzone>
                    </div>
                    <div class="document__four">
                      <h2 class="form-label form-label-clr">Upload Document 4 </h2>
                      <ngx-dropzone (change)="onSelectDocThree($event)">
                        <ngx-dropzone-label style="font-size: 10px !important">
                          <img class="" src="./assets/img/upload-resume.png" alt="File upload icon" />
                          <span> Drop your file ( PDF, JPG and PNG ) or</span> <br />
                          <p class="bf1">Browse files</p>
                        </ngx-dropzone-label>

                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of filesDocs3"
                          [file]="f" [removable]="true" (removed)="onRemoveDocthree(f)">
                          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-image-preview>
                      </ngx-dropzone>
                    </div>
                    <div class="document__five">
                      <h2 class="form-label form-label-clr">Upload Document 5 </h2>
                      <ngx-dropzone (change)="onSelectDocFour($event)">
                        <ngx-dropzone-label style="font-size: 10px !important">
                          <img class="" src="./assets/img/upload-resume.png" alt="File upload icon" />
                          <span> Drop your file ( PDF, JPG and PNG ) or</span> <br />
                          <p class="bf1">Browse files</p>
                        </ngx-dropzone-label>

                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of filesDocs4"
                          [file]="f" [removable]="true" (removed)="onRemoveDocfour(f)">
                          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                        </ngx-dropzone-image-preview>
                      </ngx-dropzone>
                    </div>
                  </div>




                  <!-- <div class="gs-panel__body gs-form-panel--sm pb-0 mt-3" style="padding-left:15px">
                    <div class="row pb-4">
                      <div class="col-md-4 new__arrow__weiGht">
                        <label class="form-label form-label-clr">
                          Document Type
                        </label>
                        <ng-select [items]="doccTyPEE" placeholder="Select document type" bindLabel="name"
                          bindValue="id" [(ngModel)]="selectedDocType" name="doccType" #doccType="ngModel">
                        </ng-select>
                      </div>

                      <div class="col-md-4 new__arrow__weiGht">
                        <label class="form-label form-label-clr">
                          Document Name
                        </label>
                        <ng-select [items]="doccNamee" placeholder="Select document name" bindLabel="name"
                          bindValue="id" [(ngModel)]="selectedDocName" name="doccName" #doccName="ngModel">
                        </ng-select>
                      </div>
                      <div class="col-md-4"></div>

                      <div class="col-md-4 mt-3">
                        <label class="form-label form-label-clr">
                          Upload Documents
                        </label>


                        <ngx-dropzone (change)="onSelectDoc($event)">
                          <ngx-dropzone-label style="font-size: 10px !important">
                            <img class="" src="./assets/img/upload-resume.png" alt="File upload icon" />
                            Drop your file ( PDF, JPG and PNG ) or <br />
                            <p class="bf1">Browse files</p>
                          </ngx-dropzone-label>

                          <ngx-dropzone-preview class="mx-auto" *ngFor="let f of filesDocs" [removable]="true"
                            (removed)="onRemoveDoc(f)">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                          </ngx-dropzone-preview>
                        </ngx-dropzone>
                      </div>
                    </div>
                  </div> -->

                  <div class="desktop__buttons mx-3">
                    <div class="text-center text-md-right d-flex justify-content-between declare__main"
                      style="margin-top: 20px; position: relative">
                      <button class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info"
                        type="button" value="work-info" (click)="docsBack()">
                        <i class="icon-angle-down angle-rotate-d"></i>Declaration
                      </button>
                      <div class="gs-btn-group">
                        <!-- <button class="btn btn--primary-d" type="button" value="job-description" data-toggle="modal"
                  data-target="#save-model">Save as Draft</button> -->
                        <!-- <button class="btn btn--primary-d next__buttons family__info__next" type="button"
                        (click)="docsNext()" value="work-history">Family Information<i
                          class="icon-angle-down angle-rt27-d"></i></button> -->
                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn--primary-d next__buttons family__info__next"
                          data-toggle="modal" data-target="#exampleModal">
                          Family Information
                          <i class="icon-angle-down angle-rt27-d"></i>
                        </button>


                      </div>
                    </div>
                  </div>
                  <!-- mobile buttons -->
                  <div class="text-center text-md-right d-flex justify-content-between declare__main mobile__buttons"
                    style="margin-top: 20px; position: relative">
                    <button type="button" class="btn btn--primary-d next__buttons family__info__next"
                      data-toggle="modal" data-target="#exampleModal">
                      Next
                      <i class="icon-angle-down angle-rt27-d"></i>
                    </button>

                    <button
                      class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info float__left"
                      type="button" value="work-info" (click)="docsBack()">
                      <i class="icon-angle-down angle-rotate-d"></i>Back
                    </button>



                  </div>
                  <!-- mobile buttons -->


                </div>
              </div>
            </div>
          </form>

          <!--Document Modal -->
          <div class="modal fade family__modal__main" id="exampleModal" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable" style="margin-top: 150px !important">
              <div class="modal-content">
                <div class="modal-header family__modal__header">
                  <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
                  <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                   <img src="../../../assets/img/cancel.svg" alt="">
                 </button> -->
                </div>
                <div class="modal-body family__modal__content">
                  You've successfully submitted the application. For
                  the ease of onboarding would you like to proceed
                  with other information ?
                </div>
                <div class="modal-footer family__modal__footer">
                  <button type="button" data-dismiss="modal" aria-label="Close" (click)="docsNext()"
                    class="btn btn--primary-d next__buttons">
                    Yes
                  </button>
                  <button type="button" data-dismiss="modal" aria-label="Close"
                    class="btn btn--primary-d next__buttons">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--Document Modal -->


          <!-- documents screen ends -->

          <form class="col-md-7 col-xl-9" novalidate #familyForm="ngForm">
            <div class="gs-steps-container pb-1 ml-md-0 mobileee__margin__left"
              style="box-shadow: 0 3px 7px rgb(0 0 0 / 4%)" *ngIf="showfamilyInfo">
              <div class="steps-form ui form" id="family-info">
                <div class="gs-panel">
                  <!--Panel-header-->
                  <div class="gs-panel__header change_head_clr">
                    <h5 class="gs-form-panel__heading mb-0">
                      7. Family Information
                    </h5>
                  </div>
                  <!--Panel-body-->
                  <div class="gs-panel__body gs-form-panel gs-form-panel--sm pb-0 addCondidate">
                    <div class="gs-steps-categ">
                      <h6 class="gs-panel__subheading mt-3">
                        1. Spouse Details
                      </h6>
                      <div class="row">
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Full Name</label>
                          <input class="form-input form-input_1" type="text" name="spouseName"
                            onkeypress="return /[a-z^\s]/i.test(event.key)" [(ngModel)]="familyinfoData.spouseName"
                            #spouseName="ngModel" placeholder="Enter your full name" />
                        </div>

                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Email Address</label>
                          <input class="form-input form-input_1" type="text" name="spouseEmail"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [(ngModel)]="familyinfoData.spouseEmail"
                            #spouseEmail="ngModel" placeholder="Enter your email address" />
                          <div *ngIf="spouseEmail.errors">
                            <span class="errorMessage" *ngIf="spouseEmail.errors?.pattern">
                              Invalid email
                            </span>
                          </div>
                        </div>

                        <div class="col-md-4 field gs-form-group number Field new-PHOne__part new__arrow__weiGht">
                          <label class="form-label form-label-clr">Phone Number</label>
                          <div class="gs-labeled-drodown">
                            <ng-select class="line" [items]="phoneCode" [clearable]="false" [searchable]="false"
                              name="spousePhoneCode" [(ngModel)]="familyinfoData.spousePhoneCode"
                              #ddcpnationality="ngModel" bindLabel="name" bindValue="name">
                              <ng-template ng-option-tmp let-item="item">
                                <!-- <label class="form-check-label ml-2 custom-control-label">{{item.name}}</label> -->
                                <div class="item gs-country-item">
                                  <span>{{ item.name }}</span>
                                </div>
                              </ng-template>
                            </ng-select>
                            <input class="form-input labeled-input salary-rang form-input_1" type="number"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              onkeypress="return /[0-9]/i.test(event.key)"
                              [(ngModel)]="familyinfoData.spousePhoneNumber" #spousePhoneNumber="ngModel"
                              name="spousePhoneNumber" placeholder="Enter your phone number" />
                          </div>
                          <div *ngIf="spousePhoneNumber.errors">
                            <span class="errorMessage" *ngIf="spousePhoneNumber.errors?.pattern">
                              Invalid phone number
                            </span>
                          </div>
                        </div>
                        <div class="col-md-4 field gs-form-group Mobile__margin__toop">
                          <label class="form-label form-label-clr">Date of Birth</label>
                          <p-calendar name="spouseDOB" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                            [(ngModel)]="familyinfoData.spouseDOB" readonly="false"
                            [ngModelOptions]="{ standalone: true }" [showIcon]="true" inputId="icon">
                          </p-calendar>
                          <!-- <label class="input-group">
                                <input class="input-field form-input block datepicker" type="text" name="date-birth" id="issue-date1" data-input="data-input" placeholder="( DD/MM/YYYY )"/><img class="input-icon" for="checkout-date" src="./img/job-application/calender.svg" alt="Calender icon"/>
                              </label> -->
                        </div>
                      </div>
                      <h6 class="gs-panel__subheading">Immigration Status</h6>
                      <div class="row">
                        <div class="col-md-4 field gs-form-group new__arrow__weiGht">
                          <label class="form-label form-label-clr">Country</label>
                          <ng-select class="ng-selectct1" [items]="countryNationality" placeholder="Select country"
                            [searchable]="false" name="spouseCountry" [(ngModel)]="familyinfoData.spouseCountry"
                            #ddlnationality="ngModel" bindLabel="countryname" bindValue="countryname">
                            <ng-template ng-option-tmp let-item="item">
                              <!-- <label class="form-check-label ml-2 custom-control-label">{{item.name}}</label> -->
                              <div class="item gs-country-item">
                                <span>{{ item.countryname }}</span><i class="{{ item.flag }}"></i>
                              </div>
                            </ng-template>
                          </ng-select>
                        </div>
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Type of resident</label>
                          <!-- <select style="height:49px !important;" name="spouseResidentCountry"
                              [(ngModel)]="familyinfoData.spouseResidentCountry">
                              <option [ngValue]="undefined" hidden>Choose the Resident</option>
                              <option *ngFor="let web of nationality" value="web">{{web.countryname}}
                              </option>
                            </select> -->
                          <input class="form-input form-input_1" type="text" name="typeOfResident"
                            [(ngModel)]="familyinfoData.typeOfResident" placeholder="Enter your resident"
                            #typeOfResident="ngModel" />
                        </div>

                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Passport Number</label>
                          <input class="form-input form-input_1" type="text" name="spousePassportNumber"
                            (keypress)="keyPressAlphanumeric($event)" [(ngModel)]="familyinfoData.spousePassportNumber"
                            #spousePassportNumber="ngModel" placeholder="Enter your passport numbers" />
                        </div>

                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Date of Issue</label>
                          <p-calendar name="spousePassportDOI" placeholder="DD/MM/YYYY" readonly="false"
                            (onSelect)="onDateSelected($event, 'spouse')" [(ngModel)]="familyinfoData.spousePassportDOI"
                            dateFormat="mm/dd/yy" [ngModelOptions]="{ standalone: true }" [showIcon]="true"
                            inputId="icon" #spousePassportDOI="ngModel"></p-calendar>
                        </div>
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Date of Expiry</label>
                          <p-calendar name="spousePassportDOE" placeholder="DD/MM/YYYY" readonly="false"
                            [(ngModel)]="familyinfoData.spousePassportDOE" dateFormat="mm/dd/yy"
                            [minDate]="minExpireDate" [maxDate]="maxExprireDate" disabled="true"
                            [ngModelOptions]="{ standalone: true }" [showIcon]="true" inputId="icon"
                            #spousePassportDOE="ngModel"></p-calendar>
                        </div>
                      </div>
                      <!-- <button class="btn next__buttons">+ Add Spouse</button> -->
                    </div>
                    <div class="gs-steps-categ">
                      <h6 class="gs-panel__subheading">2. Child Details</h6>
                      <div class="row">
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Full Name</label>
                          <input class="form-input form-input_1" type="text" name="childName"
                            onkeypress="return /[a-z^\sa-z]/i.test(event.key)" #childName="ngModel"
                            [(ngModel)]="familyinfoData.childName" placeholder="Enter your full name" />
                        </div>

                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Email Address</label>
                          <input class="form-input form-input_1" type="text" name="childEmail"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" #childEmail="ngModel"
                            [(ngModel)]="familyinfoData.childEmail" placeholder="Enter your email address" />
                          <div *ngIf="childEmail.errors">
                            <span class="errorMessage" *ngIf="childEmail.errors?.pattern">
                              Invalid email
                            </span>
                          </div>
                        </div>

                        <div class="col-md-4 field gs-form-group new-PHOne__part new__arrow__weiGht">
                          <label class="form-label form-label-clr">Phone Number</label>
                          <div class="gs-labeled-drodown">
                            <ng-select [items]="childPhoneCode" placeholder="+91" [searchable]="false"
                              name="childPhoneCode" bindLabel="name" bindValue="name" [(ngModel)]="
                                familyinfoData.selectedchildPhoneCode
                              " #ddcpnationality="ngModel">
                            </ng-select>
                            <input class="form-input labeled-input salary-rang form-input_1" type="number"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              onkeypress="return /[0-9]/i.test(event.key)" name="childPhoneNumber"
                              #childPhoneNumber="ngModel" [(ngModel)]="familyinfoData.childPhoneNumber"
                              placeholder="Enter your phone number" />
                          </div>
                          <div *ngIf="childPhoneNumber.errors">
                            <span class="errorMessage" *ngIf="childPhoneNumber.errors?.pattern">
                              Invalid phone number
                            </span>
                          </div>
                        </div>
                        <div class="col-md-4 field gs-form-group Mobile__margin__toop">
                          <label class="form-label form-label-clr">Date of Birth</label>
                          <p-calendar name="childDOB" placeholder="DD/MM/YYYY" readonly="false" dateFormat="dd/mm/yy"
                            #childPhoneNumber="ngModel" [(ngModel)]="familyinfoData.childDOB"
                            [ngModelOptions]="{ standalone: true }" [showIcon]="true" inputId="icon"></p-calendar>
                        </div>
                      </div>
                      <h6 class="gs-panel__subheading">Immigration Status</h6>
                      <div class="row">
                        <div class="col-md-4 field gs-form-group new__arrow__weiGht">
                          <label class="form-label form-label-clr">Country</label>

                          <ng-select [items]="countryNationality" bindLabel="name" bindValue="name"
                            [(ngModel)]="familyinfoData.selectedChildCountry" placeholder="Select country"
                            name="childCountryy" #childCountryy="ngModel">
                          </ng-select>
                        </div>
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Type of resident</label>
                          <!-- <select style="height:49px !important;" name="childResidentCountry"
                              [(ngModel)]="familyinfoData.childResidentCountry">
                              <option [ngValue]="undefined" hidden>Choose the type</option>
                              <option *ngFor="let web of nationality" value="web">{{web.countryname}}
                                <i class="in flag"></i>
                              </option>
                            </select> -->
                          <input class="form-input form-input_1" type="text" name="typeOfResident"
                            #typeOfResident="ngModel" placeholder="Enter your resident"
                            [(ngModel)]="familyinfoData.typeOfResidenet" />
                          <!-- <div class="ui fluid selection dropdown form-input">
                                <input type="hidden" name="immig-country"/><i class="dropdown dropdown-icon icon-angle-down"></i>
                                <div class="default text drp-plachlder gs-coutry-text">Choose the type</div>
                                <div class="menu">
                                  <div class="item gs-country-item" data-value="in"><span>India</span><i class="in flag"></i></div>
                                  <div class="item gs-country-item" data-value="ax"><span>Aland Islands</span><i class="ax flag"></i></div>
                                  <div class="item gs-country-item" data-value="al"><span>Albania</span><i class="al flag"></i></div>
                                  <div class="item gs-country-item" data-value="dz"><span>Algeria</span><i class="dz flag"></i></div>
                                  <div class="item gs-country-item" data-value="as"><span>American Samoa</span><i class="as flag"></i></div>
                                  <div class="item gs-country-item" data-value="ae"><span>United Arab Emirates</span><i class="ae flag"></i></div>
                                  <div class="item gs-country-item" data-value="us"><span>United States</span><i class="us flag"></i></div>
                                  <div class="item gs-country-item" data-value="uy"><span>Uruguay</span><i class="uy flag"></i></div>
                                </div>
                              </div> -->
                        </div>

                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Passport Number</label>
                          <input class="form-input form-input_1" type="text" name="childPassportNumber"
                            (keypress)="keyPressAlphanumeric($event)" #childPassportNumber="ngModel"
                            [(ngModel)]="familyinfoData.childPassportNumber"
                            placeholder="Enter your passport numbers" />
                        </div>

                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Date of Issue</label>
                          <p-calendar name="childPassportDOI" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                            (onSelect)="onDateSelected($event, 'child')" readonly="false"
                            [(ngModel)]="familyinfoData.childPassportDOI" [ngModelOptions]="{ standalone: true }"
                            [showIcon]="true" inputId="icon"></p-calendar>
                        </div>
                        <div class="col-md-4 field gs-form-group">
                          <label class="form-label form-label-clr">Date of Expiry</label>
                          <p-calendar name="childPassportDOE" placeholder="DD/MM/YYYY" class="list-default"
                            dateFormat="dd/mm/yy" disabled="true" [minDate]="minExpireDate" [maxDate]="maxExprireDate"
                            readonly="false" [(ngModel)]="familyinfoData.childPassportDOE"
                            [ngModelOptions]="{ standalone: true }" [showIcon]="true" inputId="icon"></p-calendar>
                        </div>
                      </div>
                      <table class="aj1 workHistory__table" *ngIf="(childArray && childArray.length) > 0">
                        <tr class="addJobArrayHeadings">
                          <th class="cp20">Full Name</th>
                          <th class="cp20">Email</th>
                          <th class="cp20">Phone Number</th>
                          <th class="cp20">Date of Birth</th>
                          <th class="cp20">Country</th>
                          <th class="cp20">Type of Resident</th>
                          <th class="cp20">Passport</th>
                          <th class="cp20">Date of Issue</th>
                          <th class="cp20">Date of Expire</th>
                        </tr>
                        <tr class="form-label form-label-clr" *ngFor="let child of childArray; let i = index">
                          <td>{{ child.childName }}</td>
                          <td style="padding-right: 15px">
                            {{ child.childEmail }}
                          </td>
                          <td>{{ child.childPhoneNumber }}</td>
                          <td>{{ child.childDOB }}</td>
                          <td>{{ child.childCountry }}</td>
                          <td>{{ child.childPassportNumber }}</td>
                          <td>{{ child.childResidentCountry }}</td>
                          <td>{{ child.childPassportDOI }}</td>
                          <td>{{ child.childPassportDOE }}</td>
                          <!-- <td><i class="fa fa-edit" aria-hidden="true" (click)="editChild(i)"></i> </td> -->
                          <td (click)="deleteChild(i)">
                            <img src="../../../assets/img/red-trash.svg" alt="" />
                          </td>
                        </tr>
                      </table>
                      <div class="col-md-12 pl-0 pl-md-0">
                        <div class="btn next__buttons add__child__button float__left">
                          <a class="" (click)="addworkChild()">+ Add Child</a>
                        </div>
                        <div *ngIf="childUpdate" class="col-md-6 field mt-1 gs-form-group">
                          <a class="btn btn--add" (click)="updateChild()">+ Update Child</a>
                        </div>
                      </div>
                      <!-- <a class="btn btn--add m-width100" href="javascript:void(0);" data-dismiss="modal" (click)="addworkChild()">+ Add Child</a> -->

                      <div class="desktop__buttons">
                        <div class="text-center text-md-right pb-4 d-flex justify-content-between edu__Info__main"
                          style="margin-top: 20px; position: relative">
                          <button
                            class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info"
                            type="button" value="work-info" (click)="lastBack()">
                            <i class="icon-angle-down angle-rotate-d"></i>Documents
                          </button>
                          <div class="text-center text-md-left">
                            <div class="" [ngClass]="{
                              notAllowed: disabledPublishButton === true
                            }">
                              <a class="btn btn--info float-right" style="color: white" [ngClass]="{
                                disablePublishBtn:
                                  disabledPublishButton === true
                              }" value="general-information" (click)="publicSubmit()">Publish
                                <i *ngIf="btnLoading" class="fa fa-refresh fa-spin"></i></a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- mobile buttons -->
                      <div
                        class="text-center col-12 text-md-right pb-4 d-flex justify-content-between edu__Info__main mobile__buttons px-0"
                        style="margin-top: 65px; position: relative">
                        <button
                          class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info float__left"
                          type="button" value="work-info" (click)="lastBack()">
                          <i class="icon-angle-down angle-rotate-d"></i>back
                        </button>
                        <div class="">
                          <div class="" [ngClass]="{
                            notAllowed: disabledPublishButton === true
                          }">
                            <a class="btn btn--info float-right" style="color: white" [ngClass]="{
                              disablePublishBtn:
                                disabledPublishButton === true
                            }" value="general-information" (click)="publicSubmit()">Publish
                              <i *ngIf="btnLoading" class="fa fa-refresh fa-spin"></i></a>
                          </div>
                        </div>
                      </div>
                      <!-- mobile buttons -->


                      <!-- <div class="text-center text-md-right pb-4 d-flex justify-content-between edu__Info__main mobile__buttons"
                        style="margin-top: 20px; position: relative">
                        <button
                          class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info"
                          type="button" value="work-info" (click)="lastBack()">
                          <i class="icon-angle-down angle-rotate-d"></i>Documents
                        </button>
                        <div class="text-center text-md-left">
                          <div class="" [ngClass]="{
                              notAllowed: disabledPublishButton === true
                            }">
                            <a class="btn btn--info float-right" style="color: white" [ngClass]="{
                                disablePublishBtn:
                                  disabledPublishButton === true
                              }" value="general-information" (click)="publicSubmit()">Publish
                              <i *ngIf="btnLoading" class="fa fa-refresh fa-spin"></i></a>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <!-- <div class="col-md-12 text-center text-md-right px-0 pb-3 d-flex justify-content-between"
                      style="margin-top:20px;">
                      <button
                        class="btn btn--primary-d js-prev previous next__buttons second__Back back__general__info educ__infO__Back"
                        type="button" value="education-info" (click)="sideBarClick('familyButton')"><i
                          class="icon-angle-down angle-rotate-d"></i>Educational
                        Information</button>
                      <div class="gs-btn-group">
                        <button class="btn btn--primary-d next__buttons others__next" type="button"
                          [disabled]="familyForm.invalid" (click)="familyInfoNext()" value="work-history">Others<i
                            class="icon-angle-down angle-rt27-d"></i></button>
                      </div>
                    </div> -->
                  </div>
                </div>
                <!-- <div class="col-md-12 text-center text-md-right pr-xl-5 mt-4">
                      <button class="btn btn--primary brder-primary js-next js-tab-sec" data-id="others" type="button" (click)="familyInfoNext()"
                       value="educational-information">Next</button>
                    </div> -->
              </div>
            </div>
            <!--START: OTHERS-->
            <div class="col-md-12 col-xl-12" *ngIf="showOthers">
              <div class="gs-steps-container ml-0">
                <div class="steps-form ui form" id="others">
                  <div class="gs-panel">
                    <!--Panel-header-->
                  </div>
                </div>
                <!-- Reviewd information Modal popup-->
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
</div>

<!-- <div class="row mt-4 mt-md-5 text-center text-md-left">
  <div class="ml-2 mb-4" [ngClass]="{'notAllowed': disabledPublishButton === true }">
    <a class="btn btn--info float-right" style="color: white;"
      [ngClass]="{'disablePublishBtn': disabledPublishButton === true }" value="general-information"
      (click)="publicSubmit()">Publish <i *ngIf="btnLoading" class="fa fa-refresh fa-spin"></i></a>
  </div>
</div> -->
