 <main class="side-nav-main position-relative">
  <div class="side-nav-logo-part text-center">
    <!-- <img src="./assets/Images/giis-only-logo.svg" alt="side nav logo" /> -->
    <!-- <h1 *ngIf="toggleSideText">GIIS Singapore, India</h1> -->
  </div>
    <div class="side-nav-menu-list mt-lg-1 mt-md-1 mt-1">
      <ul>
        <li (click)="onClick(1)" [ngClass]="{ active: tab === 'tab1' }" class="d-flex align-items-center pointer" [routerLink]="'/dashboard'">
          <img *ngIf="tab === 'tab1'" [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/white-dashboard-icon.svg" alt="side home" />
          <img *ngIf="tab !== 'tab1'" [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/grey-dashboard.svg" alt="side home" />
          <p *ngIf="toggleSideText" class="ml-2 width-max"> Dashboard </p>
        </li>
        <li (click)="onClick(3)" [ngClass]="{ active: tab === 'tab3' }"  class="d-flex align-items-center pointer"
        [routerLink]="'/job-listing'">
        <img *ngIf="tab == 'tab3'" [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/white-job-listing.svg" alt="side home" />
          <img *ngIf="tab !== 'tab3'" [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/grey-job-listing.svg" alt="side home" />
          <p *ngIf="toggleSideText" class="ml-2 width-max"> IJP </p>
        </li>
        <li (click)="onClick(2)" [ngClass]="{ active: tab === 'tab2' }"  class="d-flex align-items-center pointer"
        [routerLink]="'/ijp-employee'">
          <img *ngIf="tab == 'tab2'" [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/white-ijp-employee.svg" alt="side home" />
          <img *ngIf="tab !== 'tab2'" [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/grey-ijp-employee.svg" alt="side home" />
          <p *ngIf="toggleSideText" class="ml-2 width-max"> IJP Employee </p>
        </li>
        <!-- <li (click)="onClick(4)" [ngClass]="{ active: tab === 'tab4' }"  class="d-flex align-items-center pointer">
          <img [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/side-work-space.svg" alt="side home" />
          <p *ngIf="toggleSideText" class="ml-2"> Workspace </p>
        </li>
        <li (click)="onClick(5)" [ngClass]="{ active: tab === 'tab5' }"  class="d-flex align-items-center pointer">
          <img [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/side-ptm.svg" alt="side home" />
          <p *ngIf="toggleSideText" class="ml-2"> PTM </p>
        </li>
        <li (click)="onClick(6)" [ngClass]="{ active: tab === 'tab6' }"  class="d-flex align-items-center pointer">
          <img [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/side-counselling.svg" alt="side home" />
          <p *ngIf="toggleSideText" class="ml-2"> Counseling </p>
        </li>
        <li (click)="onClick(7)" [ngClass]="{ active: tab === 'tab7' }"  class="d-flex align-items-center pointer">
          <img [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/side-contacts.svg" alt="side home" />
          <p *ngIf="toggleSideText" class="ml-2"> Contacts </p>
        </li>
        <li (click)="onClick(8)" [ngClass]="{ active: tab === 'tab8' }"  class="d-flex align-items-center pointer">
          <img [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/side-journals.svg" alt="side home" />
          <p *ngIf="toggleSideText" class="ml-2" style="width:max-content"> Daily Journals </p>
        </li>
        <li (click)="onClick(9)" [ngClass]="{ active: tab === 'tab9' }"  class="d-flex align-items-center pointer">
          <img [ngClass]="{'mx-auto': !toggleSideText}" src="./assets/Images/side-ticket-star.svg" alt="side home" />
          <p *ngIf="toggleSideText" class="ml-2"> Tickets </p>
        </li> -->
      </ul>
    </div>
    <div class="side-nav-collapse position-absolute" (click)="toggleSideClick()">
      <button type="button">
        <img *ngIf="toggleSideText" src="./assets/Images/side-nav-collapse.svg" alt="side nav" />
        <img *ngIf="!toggleSideText" class="rotate-180" src="./assets/Images/side-nav-collapse.svg" alt="side nav" />

      </button>
    </div>

</main>

<!-- <mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="side">
    <p>Auto-resizing sidenav</p>
    @if (showFiller) {
      <p>Lorem, ipsum dolor sit amet consectetur.</p>
    }
    <button (click)="showFiller = !showFiller" mat-raised-button>
      Toggle extra text
    </button>
  </mat-drawer>

  <div class="example-sidenav-content">
    <button type="button" mat-button (click)="drawer.toggle()">
      Toggle sidenav
    </button>
  </div>
  </mat-drawer-container> -->
