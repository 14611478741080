import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-main-body',
  templateUrl: './main-body.component.html',
  styleUrls: ['./main-body.component.scss']
})
export class MainBodyComponent {
  toggleSideText!: boolean;

  constructor(private router: Router) {}
  
  isLoginPage(): boolean {
    return this.router.url === '/login';
  }
  // toggleSideClick() {
  //   this.toggleSideText = !this.toggleSideText;
  // }



  isSideExpanded: boolean = false;

  toggleSideClick() {
    console.log('toggleSideClick method called');
    this.isSideExpanded = !this.isSideExpanded;
  }
}

