
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class activityService {
    userData: any;
    headers: HttpHeaders;



    // userDetails :any = localStorage.getItem('userDetails');
    // user :any =  jwtDecode(this.userDetails);
    // userId:any = this.user.UserId ;
    // headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').append('userId',this.userId).set('Cache-Control', 'no-cache').set('Pragma', 'no-cache'); ;
    tokenVal:any
    userDetails: string;
    user: any;
    userId: any;
    constructor(private http: HttpClient,private router: Router) {

       this.userDetails = localStorage.getItem('userDetails');
       if(this.userDetails!=null){
       this.user  =  jwtDecode(this.userDetails);
        this.userId = this.user.UserId ;
       this.headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').append('userId',this.userId).set('Cache-Control', 'no-cache').set('Pragma', 'no-cache'); ;
        }
//         var url=this.router.url
//    var url1=url.split("/").pop();
//    var seg = url1.split("?").pop()
//    this.tokenVal = seg


//        let userDetails = localStorage.getItem('userDetails');
//         // user = JSON.parse(this.userDetails);
//         // userId:any = this.user.userId.toString() ;
//         // headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').append('userId',this.userId).set('Cache-Control', 'no-cache').set('Pragma', 'no-cache'); ;

//         if(userDetails!=null)
//         {
//             this.userData = jwtDecode(userDetails)
//             this.userId = this.userData.UserId
//             this.headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').append('userId',this.userId).set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
//         }
        // else if(userDetails==null && this.tokenVal!='login')
        // {
        //     this.router.navigateByUrl('/login');
        // }

    }

    activityDataList(){
        return this.http.get(`${environment.apiUrl}/api/ActivityLog/GetActivityLog?userId=${this.userId}`, { headers: this.headers })
    }

    clearLog(params){
        return this.http.post(`${environment.apiUrl}/api/ActivityLog/ClearActivityLog?userId=${this.userId}`,params, { headers: this.headers })
    }

    logFilter(startDate,endDate){
        return this.http.get(`${environment.apiUrl}/api/ActivityLog/GetActivityLogFilters?userId=${this.userId}&FilterFirstDate=${startDate}&FilterLastDate=${endDate}`,
         { headers: this.headers })
    }
    notificationDetails(){
        return this.http.get(`${environment.apiUrl}/api/ActivityLog/GetNotificationsLog?userId=${this.userId}`, { headers: this.headers })
    }
    getActivityCount(){
        return this.http.get(`${environment.apiUrl}/api/ActivityLog/GetActivityLogCount?userId=${this.userId}`, { headers: this.headers })
    }
    getDashboardCount(campusName){
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetDashboardCount?userId=${this.userId}&campusName=${campusName}`, { headers: this.headers })
    }

    getDashboardSchedules(){
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetDashboardSchedules?userId=${this.userId}`, { headers: this.headers })
    }
    getSchedules(){
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetSchedules?userId=${this.userId}`, { headers: this.headers })
    }

    GetNotificationBySearch(notificationName){
        return this.http.get(`${environment.apiUrl}/api/ActivityLog/GetNotificationsbyFilter?userId=${this.userId}&keyword=${notificationName}`, { headers: this.headers })
    }

    clearNotification(params){
        return this.http.post(`${environment.apiUrl}/api/ActivityLog/ClearActivityLog?userId=${this.userId}`, params, { headers: this.headers })
    }

    getJobDetails(campusName){
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetJobDetails?userId=${this.userId}&campusName=${campusName}`, { headers: this.headers })
    }

    getNewCandidateDetails(campusName){
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetNewCandidateDetails?userId=${this.userId}&campusName=${campusName}`, { headers: this.headers })
    }

    getInterviewDetails(campusName){
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetInterviewDetails?userId=${this.userId}&campusName=${campusName}`, { headers: this.headers })
    }

    getOfferDetails(campusName){
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetOfferDetails?userId=${this.userId}&campusName=${campusName}`, { headers: this.headers })
    }

    getMessageDetails(campusName){
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetMessageDetails?userId=${this.userId}&campusName=${campusName}`, { headers: this.headers })
    }

    logSearch(userId,searchedValue){
        return this.http.get(`${environment.apiUrl}/api/ActivityLog/GetActivityLogKeySearch?UserId=${this.userId}&activityTitle=${searchedValue}`, { headers: this.headers })
    }

    getTypeofEmployeecount(){
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetTypeofEmployee`, { headers: this.headers })

    }

    getTotalDepartmentCount(){
        return this.http.get(`${environment.apiUrl}/api/Dashboard/GetTotalDepartments`, { headers: this.headers })
        }

}
