import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-create-job-hiringteam',
  templateUrl: './create-job-hiringteam.component.html',
  styleUrls: ['./create-job-hiringteam.component.scss'],
  providers: [MessageService]
})
export class CreateJobHiringteamComponent {
  visible: boolean = false;

    showDialog() {
        this.visible = true;
    }

    constructor(private messageService: MessageService) {}
    show(): void {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Content' });
  
      setTimeout(() => {
        this.gotoJobDescription();
      }, 2000); 
    }

  gotojobListingpage(){
    window.location.href='/create-job-approver'

  }

  gotoJobDescription(){
    window.location.href='/job-listing'
  }


  products=[
    {
        id: '1000',
        code: 'f230fh0g3',
        name: 'Bamboo Watch',
        description: 'Product Description',
        image: 'bamboo-watch.jpg',
        price: 65,
        category: 'Accessories',
        quantity: 24,
        inventoryStatus: 'INSTOCK',
        rating: 5
    },
    {
        id: '1001',
        code: 'nvklal433',
        name: 'Black Watch',
        description: 'Product Description',
        image: 'black-watch.jpg',
        price: 72,
        category: 'Accessories',
        quantity: 61,
        inventoryStatus: 'OUTOFSTOCK',
        rating: 4
    },
    {
        id: '1002',
        code: 'zz21cz3c1',
        name: 'Blue Band',
        description: 'Product Description',
        image: 'blue-band.jpg',
        price: 79,
        category: 'Fitness',
        quantity: 2,
        inventoryStatus: 'LOWSTOCK',
        rating: 3
    }


  ];

}
