<div class="stepper-component">
    <p-steps [readonly]="true" [model]="items" [activeIndex]="activeIndex">
      <ng-template let-item let-index="index" pTemplate="item">
        <div [ngClass]="{'p-steps-current': index === activeIndex, 'p-steps-completed': isStepCompleted(index)}" (click)="onStepClick(index)">
          <div class="p-steps-number" [ngClass]="{'p-steps-number-done': isStepCompleted(index)}">
            {{index + 1}}
          </div>
          <div class="p-steps-title">{{item.label}}</div>
        </div>
      </ng-template>
    </p-steps>
  </div>
  