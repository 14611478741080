import { Component } from '@angular/core';

@Component({
  selector: 'app-trf-form',
  templateUrl: './trf-form.component.html',
  styleUrls: ['./trf-form.component.scss'],
})
export class TrfFormComponent {
  trfRequestedDate: any;
  trfNumberPositions: any;
  trfJobDescription: any;
  trfcttc: any;
  trftICc: any;
  trfPriorityOne: any;
  trfPriorityTwo: any;

  selectedtrfRequester: number;
  trfRequester = [
    { id: 1, name: 'Requester 1' },
    { id: 2, name: 'Requester 2' },
    { id: 3, name: 'Requester 3' },
    { id: 4, name: 'Requester 4' },
  ];
  selectedtrfBrand: number;
  trfBrand = [
    { id: 1, name: 'Brand 1' },
    { id: 2, name: 'Brand 2' },
    { id: 3, name: 'Brand 3' },
    { id: 4, name: 'Brand 4' },
  ];
  selectedtrfCountry: number;
  trfCountry = [
    { id: 1, name: 'Country 1' },
    { id: 2, name: 'Country 2' },
    { id: 3, name: 'Country 3' },
    { id: 4, name: 'Country 4' },
  ];
  selectedtrfJobTitlee: number;
  trfJobTitlee = [
    { id: 1, name: 'Title 1' },
    { id: 2, name: 'Title 2' },
    { id: 3, name: 'Title 3' },
    { id: 4, name: 'Title 4' },
  ];
  selectedtrfApprovedBy: number;
  trfApprovedBy = [
    { id: 1, name: 'Approver 1' },
    { id: 2, name: 'Approver 2' },
    { id: 3, name: 'Approver 3' },
    { id: 4, name: 'Approver 4' },
  ];
  selectedtrfEmploymentType: number;
  trfEmploymentType = [
    { id: 1, name: 'Employment Type 1' },
    { id: 2, name: 'Employment Type 2' },
    { id: 3, name: 'Employment Type 3' },
    { id: 4, name: 'Employment Type 4' },
  ];
  selectedtrfAppointmentType: number;
  trfAppointmentType = [
    { id: 1, name: 'Appointment Type 1' },
    { id: 2, name: 'Appointment Type 2' },
    { id: 3, name: 'Appointment Type 3' },
    { id: 4, name: 'Appointment Type 4' },
  ];
  selectedtrfPriority: number;
  trfPriority = [
    { id: 1, name: 'Priority 1' },
    { id: 2, name: 'Priority 2' },
    { id: 3, name: 'Priority 3' },
    { id: 4, name: 'Priority 4' },
  ];
  selectedtrfBudgeted: number;
  trfBudgeted = [
    { id: 1, name: 'Budgeted' },
    { id: 2, name: 'Non-Budgeted' },
  ];
  selectedtrfCampus: number;
  trfCampus = [
    { id: 1, name: 'Campus 1' },
    { id: 2, name: 'Campus 2' },
    { id: 3, name: 'Campus 3' },
    { id: 4, name: 'Campus 4' },
    { id: 5, name: 'Campus 5' },
    { id: 6, name: 'Campus 6' },
    { id: 7, name: 'Campus 7' },
    { id: 8, name: 'Campus 8' },
    { id: 9, name: 'Campus 9' },
    { id: 10, name: 'Campus 10' },
  ];

  constructor() {}
}
