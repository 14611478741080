<div class="trf_form_main background__wrapper">
  <h1 class="head-h1 col-md-12 px-0"> Talent Requisition Form </h1>
  <div class="row">
    <div class="col-md-4 figma__ng__select">
      <label for="icon" class="figma__label my-3">Requester</label>
      <ng-select class="" [items]="trfRequester" bindLabel="name" bindValue="id" [(ngModel)]="selectedtrfRequester"
        name="totalExperiencee" placeholder="Select requester">
      </ng-select>
    </div>
    <div class="col-md-4">
      <label for="icon" class="figma__label my-3">Requested Date</label>
      <input type="text" class="no-cursor figma__Inputs col-md-12 px-0 trf_form_input" placeholder="Requested date"
        readonly [(ngModel)]="trfRequestedDate" name="trfRequestedDattee" />
    </div>
    <div class="col-md-4 figma__ng__select">
      <label for="icon" class="figma__label my-3">Priority </label>
      <div class="col-md-12 d-flex yes__No__radios yes__No__radios_Two pl-0">
        <p class="d-flex">
          <input type="radio" id="declare1" name="declareOne" value="true" [(ngModel)]="trfPriorityOne">
          <label for="declare1" class="figma__label pl-2 pt-1">High Priority</label>
        </p>
        <p class="ml-5 d-flex">
          <input type="radio" id="declare2" name="declareOne" value="false" [(ngModel)]="trfPriorityTwo">
          <label for="declare2" class="figma__label pl-2 pt-1">Medium Priority</label>
        </p>
      </div>

      <!-- <ng-select class="" [items]="trfPriority" bindLabel="name" bindValue="id" [(ngModel)]="selectedtrfPriority"
        name="trfPriiorrity" placeholder="Select priority">
      </ng-select> -->
    </div>
    <div class="col-md-4 figma__ng__select">
      <label for="icon" class="figma__label my-3">Brand</label>
      <ng-select class="" [items]="trfBrand" bindLabel="name" bindValue="id" [(ngModel)]="selectedtrfBrand"
        name="totalExperiencee" placeholder="Select brand">
      </ng-select>
    </div>
    <div class="col-md-4 figma__ng__select">
      <label for="icon" class="figma__label my-3">Country</label>
      <ng-select class="" [items]="trfCountry" bindLabel="name" bindValue="id" [(ngModel)]="selectedtrfCountry"
        name="totalExperiencee" placeholder="Select country">
      </ng-select>
    </div>
    <div class="col-md-4 figma__ng__select">
      <label for="icon" class="figma__label my-3">Campus</label>
      <ng-select class="" [items]="trfCampus" bindLabel="name" bindValue="id" [(ngModel)]="selectedtrfCampus"
        name="totalExperiencee" placeholder="Select campus" [multiple]="true">
      </ng-select>
    </div>
    <div class="col-md-8 figma__ng__select">
      <label for="icon" class="figma__label my-3">Job Title</label>
      <ng-select class="" [items]="trfJobTitlee" bindLabel="name" bindValue="id" [(ngModel)]="selectedtrfJobTitlee"
        name="totalExperiencee" placeholder="Select job title" [multiple]="true">
      </ng-select>
    </div>

    <div class="col-md-4">
      <label for="icon" class="figma__label my-3">Number Of Positions</label>
      <input type="text" class="figma__Inputs col-md-12 px-0 trf_form_input" placeholder="Enter number of Positions"
        [(ngModel)]="trfNumberPositions" name="trfNumberPositionnss" />
    </div>
    <div class="col-md-4 figma__ng__select">
      <label for="icon" class="figma__label my-3">Budgeted / Non-budgeted</label>
      <ng-select class="" [items]="trfBudgeted" bindLabel="name" bindValue="id" [(ngModel)]="selectedtrfBudgeted"
        name="totalExperiencee" placeholder="Select an option" [multiple]="true">
      </ng-select>
    </div>
    <div class="col-md-4 figma__ng__select">
      <label for="icon" class="figma__label my-3"> Type Of Employment </label>
      <ng-select class="" [items]="trfEmploymentType" bindLabel="name" bindValue="id"
        [(ngModel)]="selectedtrfEmploymentType" name="trfEmpTyppe" placeholder="Select type of employment">
      </ng-select>
    </div>
    <div class="col-md-4 figma__ng__select">
      <label for="icon" class="figma__label my-3">Type Of Appointment </label>
      <ng-select class="" [items]="trfAppointmentType" bindLabel="name" bindValue="id"
        [(ngModel)]="selectedtrfAppointmentType" name="trfAppointmentTyppee" placeholder="Select type of appointment">
      </ng-select>
    </div>
    <div class="col-md-4">
      <label for="icon" class="figma__label my-3">CTC</label>
      <input type="text" class="figma__Inputs col-md-12 px-0 trf_form_input" placeholder="Enter ctc"
        [(ngModel)]="trfcttc" name="trfcttcc" />
    </div>
    <div class="col-md-4">
      <label for="icon" class="figma__label my-3">Total Incurred Cost</label>
      <input type="text" class="figma__Inputs col-md-12 px-0 trf_form_input" placeholder="Enter total incurred cost"
        [(ngModel)]="trftICc" name="trfTotalCost" />
    </div>
    <div class="col-md-4 figma__ng__select">
      <label for="icon" class="figma__label my-3">Approved By </label>
      <ng-select class="" [items]="trfApprovedBy" bindLabel="name" bindValue="id" [(ngModel)]="selectedtrfApprovedBy"
        name="trfAApproverr" placeholder="Select approved by">
      </ng-select>
    </div>


    <div class="col-md-8">
      <label for="icon" class="figma__label my-3">Job Description </label>
      <textarea name="trfJobDescriptiioonn" class="figma__textArea col-md-12 px-0" [(ngModel)]="trfJobDescription"
        placeholder="Enter job description"></textarea>
    </div>
  </div>
</div>
