import { Component } from '@angular/core';
import { AbstractControl, FormControl, NgForm, ValidationErrors } from '@angular/forms';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-external-referrer-details',
  templateUrl: './external-referrer-details.component.html',
  styleUrls: ['./external-referrer-details.component.scss']
})
export class ExternalReferrerDetailsComponent {
  current:any;
employeeForm: any;
currentEmployee: any;
  

  
  formData = {
    email: '',
    firstName: '',
    lastName: '',
    mobileNumber: '',
    employeeCode: '',
    campus: '',
    friendemail: '',
    friendfirstName: '',
    friendlastName: '',
    friendMobileNumber: ''
  };

  // Get a reference to the form

  @ViewChild('externalreferrerForm') externalreferrerForm!: NgForm;

  // Method to handle form submission
    onSubmit(form: NgForm): void {
        if (form.valid) {
            console.log('Form submitted successfully!', this.formData);
            form.form.reset();
        }
    }

    jobLinkUrl: string = 'https://GSF.globalcareers.org/Applicationcareerfr.aspx?JID=1558';

    copyJobLink(): void {
      navigator.clipboard.writeText(this.jobLinkUrl)
        .then(() => {
          // alert('Job link copied to clipboard!');
        })
        .catch(err => {
          console.error('Failed to copy the job link:', err);
        });
    }

    visible: boolean = false;

    showDialog() {
        this.visible = true;
    }

    goback() {
      window.location.href='referal-form';
    }
    

    OnlyNumbersAllowed(event: KeyboardEvent): boolean {
      if (event.key === 'Backspace' || event.key === 'Delete' || event.key.startsWith('Arrow')) {
        return true;
      }
      return /[0-9]/.test(event.key);
    }

    emailValidator(control: AbstractControl): ValidationErrors | null {
      const email = control.value;
      if (email && email.includes('@') && email.includes('.')) {
        const domain = email.split('@')[1];
        if (domain.includes('.')) {
          return null; // Valid email with domain name
        }
      }
      return { invalidEmail: true }; // Invalid email or missing domain name
    }

    referAFriendScreen : boolean = true;
    viewJobDescription(data:any){
      window.scrollTo(0,0);
      if (data == '1') {
          this.referAFriendScreen = false;
      }
      if (data == '2') {
          this.referAFriendScreen = true;

      }
    }

    ngAfterViewInit() {
      // Select all the close buttons (img elements with the "x-close" image)
      const closeButtons = document.querySelectorAll<HTMLImageElement>('.skill_set p img');
  
      // Add a click event listener to each close button
      closeButtons.forEach((button) => {
        button.addEventListener('click', (event) => {
          // Prevent the click event from propagating further
          event.stopPropagation();
  
          // Get the parent paragraph element (which contains the skill text and the close button)
          const skillElement = button.parentElement as HTMLParagraphElement;
  
          // Remove the skill element (paragraph) from the DOM
          if (skillElement) {
            skillElement.remove();
          }
        });
      });
    }

    back() {
      window.location.href='referal-job';
    }
}
