import {
  Component,
  Injector,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { candidateService } from '../services/candidateService';
import { commonService } from '../services/commonService';
import { manageService } from '../services/managesetting.service';
import { NotificationService } from '../services/notification.service';
import { AppComponentBase } from '../shared/app.component-base';
import { size } from 'lodash';
import { jobService } from '../services/jobservice';

@Component({
  selector: 'app-admin-data',
  templateUrl: './admin-data.component.html',
  styleUrls: ['./admin-data.component.scss']
})
export class AdminDataComponent {

  @ViewChild('JobCategoryForm', { static: false }) JobCategoryForm: NgForm;

  @ViewChild('employementTypeForm', { static: false }) employementTypeForm: NgForm;

  @ViewChild('hiringFrm', { static: false }) BOEFrm: NgForm;

  @ViewChild('educationLevelFrm', { static: false }) educationLevelFrm: NgForm;



  public showLoader: boolean = false;
  public jobCategoryData: any;
  public jobPostData: any;
  public hiringTypeValue: any;
  public hyringTypeList: any;


  filteredCategoryItems = [];
  filteredPostJobItems = [];
  filteredHireTypeItems = [];

  public jobcategoryEnable: boolean = false;
  public jobpostEnable: boolean = false;
  public hiringTypeFormEnable: boolean = false;
  public employementTypeEnable: boolean = false;
  public educationEnable: boolean = false;
  isExisitEduName: boolean = false;


  public title: any;
  employeSubmiTT = false;
  employeUpdateE = false;

  public employementTypeValue: any;
  employment_Id: any;
  submitemployementTypeValue: boolean = true;
  updateemployementTypeValueEnable: boolean = false;

  filteredBOEString: string = '';
  filteredBOEItems = [];
  public educationId: any;
  public levelofeducation: any;


  filteredPostJobString: string = '';
  filteredCategoryString: string = '';
  filteredHireTypeString: string = '';
  filteredEmployeeString: string = '';

  blueCrossMark = false;

  public languageEnable: boolean = false;
  @ViewChild('languageFrm', { static: false }) languageFrm: NgForm;
  public langauge: any = [];
  filteredStringg: string = '';
  public lanaguageValue: any;
  filteredItemss = [];
  public languageId: number = 0;
  public submitLanguage: boolean = true;
  public updateLanguageEnable: boolean = false;
  isExisitLanName: boolean = false;

  public employementTypeList: any;
  filteredEmployeeTypeItems = [];



  constructor( injector: Injector,
    private route: ActivatedRoute,
    public commonService: commonService,
    public candidateService: candidateService,
    public manageService: manageService,
    private notifyService: NotificationService,
    public jobService: jobService) {

    this.updateCategoryHiring();
    this.updatePostJobHiring();
    this.updateHireTypeHiring();
    this.updateEmployeTypeHiring();
    this.updateResultss();



    let jobCategoryurl = this.route.snapshot.routeConfig.path;
    if (jobCategoryurl == 'job-category') {
      this.jobcategoryView();
    }

    let jobPosturl = this.route.snapshot.routeConfig.path;
    if (jobPosturl == 'post-job-to') {
      this.postjobView();
    }
    let hiringTypeUrl = this.route.snapshot.routeConfig.path;
    if (hiringTypeUrl == 'type-of-hiring') {
      this.hiringTypeValue = null;
      this.hiringTypeData();
    }
    let employementTypeUrl = this.route.snapshot.routeConfig.path;
    if (employementTypeUrl == 'type-of-employment') {
      this.employementTypeValue = null;
      this.employementTypeData();
    }
    let url = this.route.snapshot.routeConfig.path;
    if (url == 'languages') {
      this.langaugeView();
    }

    let educationUrl = this.route.snapshot.routeConfig.path;
    if (educationUrl == 'higher-education') {
      this.educationView();
    }

  }

  ngOnInit(){}

  formReset(form: NgForm) {

    this.employment_Id = 0;
    this.isExisitLanName = false;
    this.isExisitEduName = false;

    this.employeSubmiTT = false;
    this.employeUpdateE = false;

    form.resetForm();

  }




  jobcategoryView() {
    this.showLoader = true;
    this.jobcategoryEnable = true;
    // this.title = 'Add Job Category';
    this.commonService.getJobCategory(true).subscribe((res) => {
      this.jobCategoryData = res;
      this.jobCategoryFilteredData = res;
      this.filteredCategoryItems = this.jobCategoryData;
      this.showLoader = false;
    });
  }

  postjobView() {
    this.showLoader = true;
    this.jobpostEnable = true;
    // this.title = 'Add Job Post';
    this.commonService.getpostJob(true).subscribe((res) => {
      this.jobPostData = res;
      this.postJobToFilteredData = res;
      this.filteredPostJobItems = this.jobPostData;
      this.showLoader = false;
    });
  }

  removePostJobValue() {
    this.filteredPostJobString = '';
    this.updatePostJobHiring();
    this.blueCrossMark = false;
  }

  async updatePostJobHiring() {
    this.filteredPostJobItems = this.searchByPostJob(this.jobPostData);
  }

  async updateCategoryHiring() {
    this.filteredCategoryItems = this.searchByCatee(this.jobCategoryData);
  }

  searchByCatee(hiree) {
    return hiree?.filter((item) => {
      if (this.filteredCategoryString.trim() === '') {
        return true;
      } else {
        return item.jobCategoryName
          .toLowerCase()
          .includes(this.filteredCategoryString.trim().toLocaleLowerCase());
      }
    });
  }


  keyPress(evt) {
    var keyCode = evt.which ? evt.which : evt.keyCode;
    if (/^[A-Za-z]+$/) {
      return true;
    } else return false;
  }

  searchByPostJob(hiree) {
    return hiree?.filter((item) => {
      if (this.filteredPostJobString.trim() === '') {
        return true;
      } else {
        return item.jobPostName
          .toLowerCase()
          .includes(this.filteredPostJobString.trim().toLocaleLowerCase());
      }
    });
  }


  hiringTypeData() {
    this.showLoader = true;
    this.hiringTypeFormEnable = true;
    // this.title = 'Type of Hiring';
    this.commonService.gethyringType().subscribe((res) => {
      this.showLoader = false;
      this.hyringTypeList = res;
      this.typeOfHiringFilteredData = res;
      this.filteredHireTypeItems = this.hyringTypeList;
    });
  }




  showPostJobCross() {
    if (this.filteredPostJobString.length > 0) {
      this.blueCrossMark = true;
    } else this.blueCrossMark = false;
  }

  showCategoryCross() {
    if (this.filteredCategoryString.length > 0) {
      this.blueCrossMark = true;
    } else this.blueCrossMark = false;
  }

  showHiiringCross() {
    if (this.filteredHireTypeString.length > 0) {
      this.blueCrossMark = true;
    } else this.blueCrossMark = false;
  }
  showEmploymentCross() {
    if (this.filteredEmployeeString.length > 0) {
      this.blueCrossMark = true;
    } else this.blueCrossMark = false;
  }

  removeHireTypeValue() {
    this.filteredHireTypeString = '';
    this.updateHireTypeHiring();
    this.blueCrossMark = false;
  }

  async updateHireTypeHiring() {
    this.filteredHireTypeItems = this.searchByHireType(this.hyringTypeList);
  }
  searchByHireType(hiree) {
    return hiree?.filter((item) => {
      if (this.filteredHireTypeString.trim() === '') {
        return true;
      } else {
        return item.hiring_Name
          .toLowerCase()
          .includes(this.filteredHireTypeString.trim().toLocaleLowerCase());
      }
    });
  }

  removeEmpTypeValue() {
    this.filteredEmployeeString = '';
    this.updateEmployeTypeHiring();
    this.blueCrossMark = false;
  }


  async updateEmployeTypeHiring() {
    this.filteredEmployeeTypeItems = this.searchByEmployeeType(this.employementTypeList);
  }
  searchByEmployeeType(hiree) {
    return hiree?.filter((item) => {
      if (this.filteredEmployeeString.trim() === '') {
        return true;
      } else {
        return item.employment_Name
          .toLowerCase()
          .includes(this.filteredEmployeeString.trim().toLocaleLowerCase());
      }
    });
  }

  showLanguageCross() {
    if (this.filteredStringg.length > 0) {
      this.blueCrossMark = true;
    } else this.blueCrossMark = false;
  }
  removeLangValue() {
    this.filteredStringg = '';
    this.updateResultss();
    this.blueCrossMark = false;
  }

  updateResultss() {
    this.filteredItemss = this.searchByValuee(this.langauge);
    // if (this.filteredStringg == '') {
    //   this.noRecordsFound = true;
    // }
  }

  searchByValuee(languagee) {
    return languagee.filter((item) => {
      if (this.filteredStringg.trim() === '') {
        return true;
      } else {
        return item.languageName
          .toLowerCase()
          .includes(this.filteredStringg.trim().toLocaleLowerCase());
      }
    });
  }

  updateEmployement(form: NgForm) {
    if (
      this.employementTypeValue &&
      !/^ *$/.test(this.employementTypeValue) &&
      this.employementTypeForm.form
    ) {
      this.showLoader = true;
      let params = {
        employment_Id: 0,
        employment_Name: this.employementTypeValue,
        employment_Status: true,
      };
      var id = this.employment_Id;
      if (this.employment_Id > 0) {
        params['employment_Id'] = this.employment_Id;
      }
      this.manageService.InsertEmployment(params).subscribe((res) => {
        this.showLoader = true;
        setTimeout(() => {
          this.employementTypeEnable = true;
          this.employementTypeValue = '';
          this.submitemployementTypeValue = true;
          this.updateemployementTypeValueEnable = false;
          this.formReset(form);
          this.employementTypeData();
          if (id > 0) {
            this.notifyService.showSuccess(
              'Thank you !',
              'Type of employment is Updated Successfully'
            );
          } else {
            this.notifyService.showSuccess(
              'Thank you !',
              'Type of employment is Created Successfully'
            );
          }
        }, 2000);
      });
    }
  }
  employementTypeData() {
    this.showLoader = true;
    this.employementTypeEnable = true;
    this.title = 'Type of employment';
    this.commonService.getEmployementType().subscribe((res) => {
      this.showLoader = false;
      this.employementTypeList = res;
      this.typeOfEmploymentFilteredData = res;
      this.filteredEmployeeTypeItems = this.employementTypeList;
    });
  }


  editEmployement(data) {
    this.submitemployementTypeValue = false;
    this.employment_Id = data.employment_Id;
    this.updateemployementTypeValueEnable = true;
    this.title = 'Edit type of employment';
    this.employementTypeValue = data.employment_Name;
    this.employeSubmiTT = true;
    this.employeUpdateE = true;
  }



  langaugeView() {
    this.showLoader = true;
    this.title = 'Add Language';
    this.languageEnable = true;
    this.commonService.getLanguages(true).subscribe((res) => {
      this.langauge = res;
      this.languageFilteredData = res;
      this.filteredItemss = this.langauge;
      this.showLoader = false;
    });
  }

  updateLanguage(form?: NgForm) {
    if (this.lanaguageValue) {
      this.showLoader = true;
      let params = {
        languageName: this.lanaguageValue,
        language_Status: true,
        languageId: 0,
      };
      if (this.languageId > 0) {
        params['languageId'] = this.languageId;
      }
      this.manageService.insertLanguage(params).subscribe((res: any) => {
        // this.languageEnable = false;

        setTimeout(() => {
          var id = this.languageId;
          this.showLoader = false;
          // this.languageEnable = false;
          this.submitLanguage = true;
          this.updateLanguageEnable = false;
          // this.lanaguageValue = '';
          this.formReset(form);
          this.langaugeView();
          if (id > 0) {
            this.notifyService.showSuccess(
              'Thank you !',
              'Language is Updated Successfully'
            );
          } else {
            this.notifyService.showSuccess(
              'Thank you !',
              'Language is Created Successfully'
            );
          }
        }, 2000);
      });
    }
  }

  showHigherLevelCross() {
    if (this.filteredBOEString.length > 0) {
      this.blueCrossMark = true;
    } else this.blueCrossMark = false;
  }




  async updateBoeHiring() {
    this.filteredBOEItems = this.searchByBoe(this.levelofeducation);
  }

  searchByBoe(hiree) {
    return hiree?.filter((item) => {
      if (this.filteredBOEString.trim() === '') {
        return true;
      } else {
        return item.educationName
          .toLowerCase()
          .includes(this.filteredBOEString.trim().toLocaleLowerCase());
      }
    });
  }

  educationView() {
    this.showLoader = true;
    this.educationEnable = true;
    this.title = 'Add Board of Education';
    this.commonService.getLevelOfEducation(true).subscribe((res) => {
      this.levelofeducation = res;
      this.higherEducationFilteredData = res;
      this.filteredBOEItems = this.levelofeducation;
      this.showLoader = false;
    });
  }


  ValidateEducation(e) {
    var name = e?.target?.value;
    name = name.trim();
    // this.educationValue = name;
    if (name.length > 0) {
      this.manageService
        .ValidateEducation(name, this.educationId)
        .subscribe((res: any) => {
          this.isExisitEduName = res;
        });
    }
  }

  removeBOEValue() {
    this.filteredBOEString = '';
    this.updateBoeHiring();
    this.blueCrossMark = false;
  }


  ValidateLanguage(e) {
    var name = e?.target?.value;
    name = name.trim();
    // this.lanaguageValue = name;
    if (name.length > 0) {
      this.manageService
        .ValidateLanguage(name, this.languageId)
        .subscribe((res: any) => {
          this.isExisitLanName = res;
        });
    }
  }


  noSpaceBar(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

  jobCategoryFilteredData : any = [];
  jobCategorySearchKeyword: string = '';
  searchJobCategoryData(){
   this.jobCategoryFilteredData = [...this.filteredCategoryItems]
    this.jobCategoryFilteredData = this.filteredCategoryItems.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(this.jobCategorySearchKeyword.toLowerCase())
      )
    );
  }

  removeJobCategoryValuenew(){
    this.jobCategorySearchKeyword = null;
    this.jobcategoryView();
  }
 
 
  postJobToFilteredData : any = [];
  postJobToSearchKeyword: string = '';
  searchPostJobToData(){
   this.postJobToFilteredData = [...this.filteredPostJobItems]
    this.postJobToFilteredData = this.filteredPostJobItems.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(this.postJobToSearchKeyword.toLowerCase())
      )
    );
  }

  removePostJobToValuenew(){
    this.postJobToSearchKeyword = null;
    this.postjobView();
  }
  
  
  typeOfHiringFilteredData : any = [];
  typeOfHiringSearchKeyword: string = '';
  searchtypeOfHiringData(){
   this.typeOfHiringFilteredData = [...this.filteredHireTypeItems]
    this.typeOfHiringFilteredData = this.filteredHireTypeItems.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(this.typeOfHiringSearchKeyword.toLowerCase())
      )
    );
  }

  removetypeOfHiringValuenew(){
    this.typeOfHiringSearchKeyword = null;
    this.hiringTypeData();
  }
  
  typeOfEmploymentFilteredData : any = [];
  typeOfEmploymentSearchKeyword: string = '';
  searchtypeOfEmploymentData(){
   this.typeOfEmploymentFilteredData = [...this.employementTypeList]
    this.typeOfEmploymentFilteredData = this.employementTypeList.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(this.typeOfEmploymentSearchKeyword.toLowerCase())
      )
    );
  }

  removetypeOfEmploymentValuenew(){
    this.typeOfEmploymentSearchKeyword = null;
    this.employementTypeData();
  }
  
  
  languageFilteredData : any = [];
  languageSearchKeyword: string = '';
  searchLanguageData(){
   this.languageFilteredData = [...this.filteredItemss]
    this.languageFilteredData = this.filteredItemss.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(this.languageSearchKeyword.toLowerCase())
      )
    );
  }

  removeLanguageValuenew(){
    this.languageSearchKeyword = null;
    this.langaugeView();
  }
  
  
  higherEducationFilteredData : any = [];
  higherEducationSearchKeyword: string = '';
  searchHigherEducationData(){
   this.higherEducationFilteredData = [...this.filteredBOEItems]
    this.higherEducationFilteredData = this.filteredBOEItems.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(this.higherEducationSearchKeyword.toLowerCase())
      )
    );
  }

  removeHigherEducationValuenew(){
    this.higherEducationSearchKeyword = null;
    this.educationView();
  }





}
