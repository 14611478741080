import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SsoService } from './ssoService';
//import { SsoAuthService } from './sso-auth.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    userAuthenticated: boolean = false;
    constructor(private router: Router, private ssoService: SsoService) {

    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {




        //let rout: any = window.location.href;
        //let data = rout.split('/').pop();
        // if (state.url == "login" && !this.ssoService.isUserAuthenticated) {
        //     //this.router.navigate(['/login']);
        //     return true;
        // } else if (state.url == '' && this.ssoService.isUserAuthenticated) {
        //     //this.router.navigate(['/login']);
        //     return true;
        // }
        // else {
        //     return false;
        // }

        if (this.ssoService.UserAuthenticated) {
            let url = state?.url?.split('/').pop();
            if ( (url != 'dashboard') &&  (url != 'interviews') ) {
                let isPermission = this.ssoService.UserPermissions(url);
                this.ssoService.setHrinterview(true);
            }else if((url != 'dashboard') &&  (url == 'interviews')){
                let isPermission = this.ssoService.SchedulePermission(url);
                this.ssoService.setHrinterview(false);
            }

            return true;
        }else if(this.ssoService.setUserAuthentication){
            return true;
        }
        else {
            this.router.navigateByUrl('/login');
            return false;
        }


    }

    // canLoad(): boolean {

    //     let pathname = window.location.pathname;

    //     if (pathname.indexOf('/shad/') > -1) {
    //         this.router.navigate(['/login']);
    //         return false;
    //     }

    //     return this.checkLogin();
    // }

    // canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //     //console.log(route);
    //     let url = state.url;
    //     if (url.indexOf('traffic-reports') !== -1) {
    //         if (this.sso.getSubscriberEndpointId()) {
    //             return true;
    //         } else {
    //             this.router.navigate(['/support/subscriber/search']);
    //             return false;
    //         }
    //     }

    //     return true;
    // }

    // checkLogin() {
    //     if (this.sso.isLoggedIn()) {
    //         //console.log(this.router.url);
    //         return true;
    //     } else {
    //         this.router.navigate(['/login']);
    //         return false;
    //     }
    // }

}
