<main class="login-main position-relative">
  <section class="col-md-4 mx-auto">
    <div class="p-3 figma__ng__select">
      <div class="text-center">
        <img
          class="login-logo"
          src="./assets/Images/gsf-new-logo.png"
          alt="logo"
        />
      </div>
      <!-- <h3 class="mobile-title">myGIIS Parent</h3> -->

      <!-- <a class="mt-3" style="position: relative; left: -200px">
        <img
          style="width: 74px !important; height: 50px !important"
          src="./assets/Images/GSF-LOGO.png"
          alt="GIIS-Logo"
      /></a> -->

      <h3 class="text-center my-4 fs20 fw600">Welcome to GSG Careers</h3>
      <!-- <h6 class="title title--sub">Login for an experience tailored to you</h6> -->
      <form
        class="top"
        novalidate
        #loginInfo="ngForm"
        novalidate
        (ngSubmit)="loginInfo.form.valid && createLoginUser()"
      >
        <div class="figma__input__styles input-border-transparent loginMb_5">
          <label class="fs12 fw600"
            >Enter GSG ID or Email <span class="error"> * </span>
          </label>
          <input
            class="w-100"
            id="username"
            type="text"
            placeholder="Enter myGSF id or email"
            autocomplete="off"
            required
            [(ngModel)]="userData.loginEmail"
            name="email"
            #email="ngModel"
            style="color: black !important"
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
          />
          <div class="mt-1" *ngIf="email.invalid && (email.dirty || email.touched)">
            <div class="error" *ngIf="email.errors?.['required']">
              Email is required
            </div>
            <div class="error" *ngIf="email.errors?.['pattern']">
              Invalid email format
            </div>
          </div>

          <!-- <div
            *ngIf="
              loginEmail.invalid &&
              (loginEmail.dirty || loginEmail.touched)
            "
          >
            <div *ngIf="loginEmail.errors?.required">
              Email is required.
            </div>
            <div *ngIf="loginEmail.errors?.pattern">
              Invalid email format.
            </div>
          </div> -->
        </div>

        <!-- <div
          style="text-align: left"
          *ngIf="
            (email.touched || loginInfo.submitted) && email.errors.required
          "
        >
          <span class="errorMessage" style="color: red">
            Email is required
          </span>
        </div> -->
        <ng-container
          *ngIf="userFull && userFull.length !== 0 && userFull.length !== 1"
        >
          <div class="login-ng-select mt-4">
            <label class="fs12 fw600"
              >Select Username <span class="error"> * </span>
            </label>
            <ng-select
              [items]="userFull"
              placeholder="Select username"
              bindLabel="userFullName"
              [multiple]="false"
              name="userFullName"
              bindValue="userFullName"
              #userFullName="ngModel"
              (change)="errorUsername()"
              [(ngModel)]="userData.userFullName"
            >
            </ng-select>
          </div>
          <!-- <div style="text-align: left;"
          *ngIf="(userFullName.touched || loginInfo.submitted) && userFullName.errors?.required">
          <span class="errorMessage" style="color: red;">
            userFullName is required
          </span>
        </div> -->
          <div style="text-align: left">
            <span class="errorMessage" style="color: red">{{
              errormessages
            }}</span>
          </div>
        </ng-container>

        <div class="figma__input__styles mt-4 position-relative">
          <label class="fs12 fw600"
            >Enter Password <span class="error"> * </span>
          </label>
          <input
            class="w-100"
            id="password"
            placeholder="Enter password"
            [type]="fieldTextType ? 'text' : 'password'"
            required
            [(ngModel)]="userData.password"
            name="password"
            #password="ngModel"
            autocomplete="off"
            style="color: black !important"
            (keyup)="pass()"
          />
          <span class="p-viewer position-absolute">
            <svg
              *ngIf="fieldTextType == false"
              (click)="toggleFieldTextType()"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-eye-slash pointer"
              viewBox="0 0 16 16"
            >
              <path
                d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"
              />
              <path
                d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"
              />
              <path
                d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"
              />
            </svg>
            <!-- <img
              style="margin-left: -30px; margin-top: 14px"
              class="pointer"
              *ngIf="fieldTextType == false"
              (click)="toggleFieldTextType()"
              src="./assets/Images/hide-password-icon.svg"
              alt="password icons"
            /> -->
            <svg
              *ngIf="fieldTextType == true"
              (click)="toggleFieldTextType()"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-eye pointer"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"
              />
              <path
                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"
              />
            </svg>
            <!-- <img
              style="margin-left: -30px; margin-top: 14px"
              class="pointer"
              *ngIf="fieldTextType == true"
              (click)="toggleFieldTextType()"
              src="./assets/Images/show-password-icon.png"
              alt="password icons"
            /> -->

            <!-- <i class="fa fa-eye" *ngIf="fieldTextType == true" aria-hidden="true" style="color:white;margin-left:-24px"
              (click)="toggleFieldTextType()"></i>
            <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="fieldTextType == false"
              style="color:white;margin-left:-24px;" (click)="toggleFieldTextType()"></i> -->
          </span>
          <!-- <div
            style="text-align: left"
            *ngIf="
              (password.touched || loginInfo.submitted) &&
              password.errors?.required
            "
          >
            <span class="errorMessage" style="color: red">
              Password is required
            </span>
          </div> -->
        </div>

        <div class="mt-1" *ngIf="password.invalid && (password.dirty || password.touched)">
          <div class="error" *ngIf="password.errors?.['required']">Password is required</div>
        </div>

        <!-- <div style="text-align: left;" *ngIf="(password.touched || loginInfo.submitted) && password.errors?.required">
          <span class="errorMessage" style="color: red;">
            Password is required
          </span>
        </div> -->
        <!-- <div class="field">
          <div class="ui fluid selection dropdown form-dropdown form-input form-group floating-label-group">
            <input class="form-control" type="hidden" name="ty-resident"/><i class="dropdown dropdown-icon icon-angle-down dropdown-icon--gry"></i>
            <div class="default text text-drop drp-plachlder">Select Campus</div>
            <div class="menu no-border">
              <div class="item" data-value="af"> Campus 1</div>
              <div class="item" data-value="ax"> Campus 2</div>
              <div class="item" data-value="al"> Campus 3</div>
              <div class="item" data-value="dz"> Campus 4</div>
            </div>
          </div>
        </div> -->
        <div class="mt-3 d-flex align-items-center justify-content-center">
          <input type="checkbox" tabindex="0" />
          <label class="fs12 ml-2 fw500">Remember me</label>
        </div>
        <div style="text-align: left" *ngIf="errormMsg">
          <span class="errorMessage" style="color: red">
            Invalid email or password or User may be Inactive
          </span>
        </div>
        <!-- <button class="btn btn-primary login-btn" (click)="createLoginUser()">LOGIN</button> -->
        <button
          class="blue-btn w-100 mt-2"
          (click)="createLoginUser()"
          [disabled]="loginInfo.form.invalid"
        >
          Login
        </button>
        <!-- *ngIf="!toggleLoginUserName" -->
        <!-- <ng-container *ngIf="userFull && userFull.length == 1"> -->
        <div class="forgot-password-modal">
          <p class="mt-2 text-center" (click)="showDialog()">
            <span class="fs12 fw500 pointer">Forgot Password</span>
          </p>
          <p-dialog
            [(visible)]="visible"
            [modal]="true"
            [style]="{ width: '30vw' }"
            [draggable]="false"
            [resizable]="false"
          >
            <div class="pt-4">
              <h1
                class="fw600 fs14 mb-3 text-center"
                style="color: black !important"
              >
                Do you want to send the reset link to the ID ?
              </h1>
              <form #forgotForm="ngForm">
              <div
                class="figma__input__styles input-border-transparent loginMb_5"
              >
                <label class="fs12 fw600" style="color: black !important"
                  >Enter Email <span class="error"> * </span> </label
                >
                <input
                  class="w-100"
                  id="username"
                  type="text"
                  placeholder="Enter email"
                  autocomplete="off"
                  required
                  [(ngModel)]="userData.forgotEmail"
                  name="forgotEmaill"
                  #forgotEmaill="ngModel"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                />
                <div class="mt-1" *ngIf="forgotEmaill.invalid && (forgotEmaill.dirty || forgotEmaill.touched)">
                  <div class="error" *ngIf="forgotEmaill.errors?.['required']">
                    Email is required
                  </div>
                  <div class="error" *ngIf="forgotEmaill.errors?.['pattern']">
                    Invalid email format
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-3">
                <button
                  type="button"
                  class="blue-btn"
                  (click)="forgotSend()"
                  [disabled]="forgotForm.form.invalid"
                >
                  Send
                </button>
                <button
                  type="button"
                  class="secondary-btn"
                  (click)="forgotCancel()"
                >
                  cancel
                </button>
              </div>
            </form>
            </div>
          </p-dialog>
        </div>
        <!-- </ng-container> -->
      </form>
      <!-- <div class="forgot d-flex">
        <button class="btn btn-log btn-log--frgt text-left">Forgot Password?</button>
        <button class="btn btn-log btn-log--skp p-0">Skip</button>
      </div> -->
      <!-- <h3 class="title title--grid-title">Other myGIIS apps</h3>
      <div class="container product-section">
        <div class="row product-container">
          <div class="col-sm-4 product-column"><span class="product"> <img class="product-image product-image--one"
                src="{{this.appSettings.imageUrl}}/img/login/my-giis.svg" alt="product" /></span>
            <h3 class="title title--product-title">myGIIS </h3>
          </div>
          <div class="col-sm-4 product-column"><span class="product"> <img class="product-image product-image--two"
                src="{{this.appSettings.imageUrl}}/img/login/news.svg" alt="product" /></span>
            <h3 class="title title--product-title">myGIIS News </h3>
          </div>
          <div class="col-sm-4 product-column"><span class="product"> <img class="product-image product-image--three"
                src="{{this.appSettings.imageUrl}}/img/login/giis-books.svg" alt="product" /></span>
            <h3 class="title title--product-title">myGIIS Books</h3>
          </div>
        </div>
      </div> -->
      <!-- <div class="row text-center align-content-center justify-content-center my-auto">
        <h3 class="available-on">available on</h3><img class="download-icon download-icon--apple"
          src="{{this.appSettings.imageUrl}}/img/login/apple.png" alt="apple" /><img
          class="download-icon download-icon--android" src="{{this.appSettings.imageUrl}}/img/login/android.png"
          alt="android" />
      </div> -->
    </div>
  </section>
  <img
    class="w-100 login-layer-image"
    src="./assets/Images/login_bg.svg"
    alt="login layer"
  />
  <div class="footer-links-main d-flex align-items-center position-absolute">
    <div class="footer-links justify-content-center d-flex">
      <a
        class="footer-link fs12 fw600"
        href="https://careers.smartlearn.com/gsg/privacy-policy"
        >Privacy Policy</a
      >
      <a
        class="footer-link fs12 fw600"
        href="https://careers.smartlearn.com/gsg/terms-of-use"
        >Terms & Conditions</a
      >
      <a
        class="footer-link fs12 fw600"
        href="https://careers.smartlearn.com/gsg/disclaimer"
        >Disclaimer</a
      >
    </div>
    <h6 class="fs12 fw600 justify-content-center d-flex copy-right ms-3">
      Copyright © 2023 Global Schools Foundation. All rights reserved.
    </h6>
  </div>
</main>

<!--START: Login BANNER-->
<section class="login p-0">
  <div class="row">
    <!-- <div class="col-sm-7 login-right-section">
      <div class="banner">
        <img
          class="desktop-logo"
          src="./assets/Images/login/login-logo.png"
          alt="logo"
        /><img
          class="login-right-vector"
          src="./assets/Images/login/login-right-vector.png"
          alt="vector"
        />
      </div>
      <img
        class="gsf-logo"
        src="./assets/Images/login/gsf-logo.png"
        alt="logo"
      /> -->
    <!-- <p class="check-out">Checkout the latest GIIS articles on <span class="global-news"> <a>Global School
            News</a></span></p> -->
    <!-- <div class="footer-links">
        <a class="footer-link" href="https://careers.smartlearn.com/gsg/privacy-policy">Privacy Policy</a>
        <a class="footer-link border-lr" href="https://careers.smartlearn.com/gsg/terms-of-use">Terms & Conditions</a>
        <a class="footer-link" href="https://careers.smartlearn.com/gsg/disclaimer">Disclaimer</a>
      </div>
      <h6 class="copy-right">
        Copyright © 2023 Global Schools Foundation. All rights reserved.
      </h6>
    </div> -->
    <div
      class="close-modal modal fade"
      id="reset-model"
      tabindex="-1"
      role="dialog"
      aria-labelledby="reactivateTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content close-modal__content delete-model-content">
          <div class="modal-header d-flex flex-column p-0 border-0 mb-3">
            <h5 class="close-modal__h5">
              Do you want to send the reset link to the ID ?
            </h5>
          </div>
          <form novalidate #filterInfo="ngForm" novalidate>
            <div class="col-md-12 field form-group-d px-0 figma__input__styles">
              <label
                class="col-md-12 form-label-d px-0 d-flex justify-content-start"
                >Email ID</label
              >
              <input
                type="text"
                class="form-input col-md-12 forgot-email-input"
                name="forgotEmail"
                placeholder="Enter email id"
                [(ngModel)]="forgotEmail"
                #ddlforgotEmail="ngModel"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                required
              />
              <!-- <div
                class="d-flex justify-content-start"
                *ngIf="ddlforgotEmail.errors"
              >
                <span
                  class="errorMessage"
                  *ngIf="ddlforgotEmail.errors?.pattern"
                >
                  Invalid email
                </span>
              </div> -->
              <!-- <div
                class="d-flex justify-content-start"
                *ngIf="
                  (ddlforgotEmail.touched || filterInfo.submitted) &&
                  ddlforgotEmail.errors?.required
                "
              >
                <span class="errorMessage"> Please enter email id </span>
              </div> -->
            </div>
            <div
              class="modal-footer d-flex justify-content-between border-0 p-0 mt-2"
            >
              <button
                class="figma_background_blue_btn btn"
                type="button"
                (click)="sendEmail()"
              >
                Send
              </button>
              <button
                class="btn figma_blue_button"
                type="button"
                data-dismiss="modal"
                (click)="cancel()"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
