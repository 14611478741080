<!-- <div class="backbuton">
    <img (click)="gotojobListingpage()" src="./assets/Images/create-arrow.svg" alt="">
    <span class="backtolistpade">Create Job Posting</span>
  </div> -->



  <!-- <app-create-job-post></app-create-job-post> -->
  <div class="row">
    <div class="col-1">
      <div class="stepper white-background br8">
        <ng-container *ngFor="let step of ['1', '2',]; let i = index">
          <div class="step"
          [ngClass]="{ 'active': i === activeStepIndex, 'completed': isStepCompleted(i) }"
          (click)="activeStepIndex = i">
         <p class="step-count"> {{ i + 1 }}</p>
     </div>
        </ng-container>
      </div>
    </div>

    <div class="col-11">
      <div class="step-displayed white-background mb-4 p16 br8 d-flex justify-content-between">
        <p class="fs14 fw600 blue-color">{{ activeStep }}</p>
        <!-- <div class="job-id-part d-flex align-items-center">
          <h3>Marketing Manager</h3>
          <img src="../../../assets/Images/approval.svg" alt="approval">
          <h3>Job ID- A01232</h3>
        </div> -->
      </div>
      <div class="job-post-steps ">
        <div *ngIf="activeStepIndex === 0">

          <form novalidate #targetAudienceForm="ngForm"
          (ngSubmit)="targetAudienceForm.form.valid && updateTargetAudienceForm; nextStep('first')">
            <!-- <app-stepper></app-stepper> -->
            <div class="white-background p16 br8 mb16 position-relative">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleBrand" class="form-label fw500 neugray">Eligible Brand</label>
                <!-- (change)="getnationalityid($event)" -->

                <ng-select class="custom" [multiple]="true" [items]="eligibleBrands" bindLabel="brand" bindValue="brandId" placeholder="Select eligible brand"
                (change)="GetCountries(eligibleBrandss)" [(ngModel)]="eligibleBrandss" name="eligibleBrandSelect" #eligibleBrandSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(eligibleBrandSelect.touched || targetAudienceForm.submitted) &&
                eligibleBrandSelect.errors?.['required']">
                <span class="errorMessage"> Please select eligible brand</span>
              </div>
              <div class="d-flex selected-values-main gap-3 align-items-center mt-2">
             <div class="selected-values">
              <div class="single-selected-value" *ngFor="let x of displayedItems; let i = index">
              <p> {{x}} <svg width="18" height="18" fill="#976EF7" class="bi bi-x pointer" viewBox="0 0 16 16" (click)="deleteSingleItem(i)">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg> </p>
            </div>
             </div>
             <div class="more-items" *ngIf="remainingCount > 0">
             <p>  + {{ remainingCount }} more </p>
            </div>
            </div>
              </div>
              <!-- <p class="blue-brd-pill mt-3" #pillElementBrand> GIIS <img src="../../../assets/Images/x-close.svg" alt="close" class="ms-2" (click)="removePill(pillElementBrand)"></p> -->
            </div>
            <div class="white-background p16 br8 mb16">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCountry" class="form-label fw500 neugray">Eligible Country</label>
                <!-- (change)="getnationalityid($event)" -->
                <ng-select class="custom" [multiple]="true" [items]="eligibleCountry" bindLabel="country" bindValue="countryId" placeholder="Select eligible country"
                (change)="GetCampusss(eligibleCountryyyy)" [(ngModel)]="eligibleCountryyyy" name="eligibleCoutnrySelect" #eligibleCoutnrySelect="ngModel" required>
                </ng-select>
                <div *ngIf="(eligibleCoutnrySelect.touched || targetAudienceForm.submitted) &&
                eligibleCoutnrySelect.errors?.['required']">
                    <span class="errorMessage"> Please select eligible country</span>
                  </div>
              </div>
              <!-- <p class="blue-brd-pill mt-3" #pillElementCountry> GIIS <img src="../../../assets/Images/x-close.svg" alt="close" class="ms-2" (click)="removePill(pillElementCountry)"></p> -->
            </div>
            <div class="white-background p16 br8 mb16">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Campus</label>
                <!-- (change)="getnationalityid($event)" -->
                <ng-select class="custom" [multiple]="true" [items]="campusInfo" bindLabel="campusName" bindValue="campusId" placeholder="Select eligible campus"
                  [(ngModel)]="eligibleCampussss" name="eligibleCampusSelect" #eligibleCampusSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(eligibleCampusSelect.touched || targetAudienceForm.submitted) &&
                eligibleCampusSelect.errors?.['required']">
                    <span class="errorMessage"> Please select eligible campus</span>
                  </div>
              </div>
              <!-- <p class="blue-brd-pill mt-3" #pillElementCampus> GIIS <img src="../../../assets/Images/x-close.svg" alt="close" class="ms-2" (click)="removePill(pillElementCampus)"></p> -->
            </div>
            <div class="white-background p16 br8 mb16">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Department</label>
                <!-- (change)="getnationalityid($event)" -->
                <ng-select class="custom" [multiple]="true" [items]="eligibleDepartment" bindLabel="departmentname" bindValue="departmentId" placeholder="Select eligible department"
                  [(ngModel)]="eligibleDepartments" name="eligibleDepartmentSelect" #eligibleDepartmentSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(eligibleDepartmentSelect.touched || targetAudienceForm.submitted) &&
                eligibleDepartmentSelect.errors?.['required']">
                    <span class="errorMessage"> Please select eligible department</span>
                  </div>
              </div>
              <!-- <p class="blue-brd-pill mt-3" #pillElementDepartment> GIIS <img src="../../../assets/Images/x-close.svg" alt="close" class="ms-2" (click)="removePill(pillElementDepartment)"></p> -->
            </div>
            <div class="white-background p16 br8 mb16">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Role</label>
                <!-- (change)="getnationalityid($event)" -->
                <ng-select class="custom" [multiple]="true" [items]="eligibleRole" bindLabel="enterJobRoles" bindValue="jobRolesId" placeholder="Select eligible role"
                  [(ngModel)]="eligibleRolesss" name="eligibleRoleSelect" #eligibleRoleSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(eligibleRoleSelect.touched || targetAudienceForm.submitted) &&
                eligibleRoleSelect.errors?.['required']">
                    <span class="errorMessage"> Please select eligible role</span>
                  </div>
              </div>
              <!-- <p class="blue-brd-pill mt-3" #pillElementRole> GIIS <img src="../../../assets/Images/x-close.svg" alt="close" class="ms-2" (click)="removePill(pillElementRole)"></p> -->
            </div>
            <!-- <div class="white-background p16 br8 mb16">
              <div class="row">
                <div class="col-md-6 col-lg-4 figma-ng-select">
                  <label for="eligibleCampus" class="form-label fw500 neugray">Employment Experience ( In months ) <sup>*</sup></label>
                  <input type="text" class="form-control" placeholder="Enter experience" onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="employmentExp" name="employmentExpSelect" #employmentExpSelect="ngModel" required>

                  <div *ngIf="(employmentExpSelect.touched || targetAudienceForm.submitted) &&
                  employmentExpSelect.errors?.['required']">
                      <span class="errorMessage"> Please enter experience</span>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 figma-ng-select">
                  <label for="eligibleCampus" class="form-label fw500 neugray">Current Role duration <sup>*</sup></label>
                  <input type="text" class="form-control" placeholder="Enter role duration" onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="roleDuration" name="roleDurationSelect" #roleDurationSelect="ngModel" required>

                  <div *ngIf="(roleDurationSelect.touched || targetAudienceForm.submitted) &&
                  roleDurationSelect.errors?.['required']">
                      <span class="errorMessage"> Please enter role duration</span>
                    </div>
                </div>
              </div>
            </div> -->

            <div class="step-navigation white-background p16 br8">
              <button class="secondary-btn me-2">Previous</button>
              <button class="blue-btn">Next</button>
            </div>
          </form>
        </div>

        <div *ngIf="activeStepIndex === 1">
          <form novalidate #progrmaApproversForm="ngForm"
          (ngSubmit)="progrmaApproversForm.form.valid && updateProgrmaApproversForm(); nextStep('second')">
          <div class="table-responsive">
            <table class="table approver-table">
              <thead>
                <tr class="white-background br8">
                  <th class="blackk fs14 fw600 p16">Level <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th>
                  <!-- <th class="blackk fs14 fw600 p16">Country <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th> -->
                  <th class="blackk fs14 fw600 p16">Approvers Designation <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Member Name/ Mail ID <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of approverTable; let i = index"  class="white-background br8">
                  <td class="fs14 blackk fw400 p16">{{ x.level }}</td>
                  <!-- <td class="fs14 blackk fw400 p16">{{ x.country }}</td> -->
                  <td class="fs14 blackk fw400 p16">{{ x.designation }}</td>
                  <td class="d-flex p16">

                    <div class="d-flex gap-3 align-items-center member-name-save">
                    <div class="figma-ng-select">
                      <ng-select class="custom" [items]="memberName" bindLabel="userName" bindValue="userId" [multiple]="true"
                        placeholder="Select name" [(ngModel)]="x.hiringTeamName" name="hiringTeamNameSelect"
                         #hiringTeamNameSelect="ngModel">
                      </ng-select>
                    </div>
                    <!-- <button type="button" class="blue-btn px-2"> Save </button>
                    <button class="secondary-btn me-2">Edit</button> -->

                  </div>


                    <!-- <div>
                      <p class="fs14 blackk fw400 mb-1">{{ x.MemberName }}</p>
                     <p class="fs10 fw500 grey">{{ x.MailID }}</p>
                    </div>
                    <div class="verticalline"></div>
                    <div>
                      <p class="fs14 blackk fw400 mb-1">{{ x.MemberName }}</p>
                     <p class="fs10 fw500 grey">{{ x.MailID }}</p>
                    </div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="field gs-form-group white-background mb-4 p16 br8">
            <label class="form-label blackk fs14 fw600 mb-2">Does this job require approval from Applicant Reporting Manager ?</label>
            <div class="gs-grouped-fileds d-flex mt-2">
              <div class="ui radio checkbox gs-grouped-filed me-2">
                <input class="hidden" type="radio" [(ngModel)]="applicantApproval" name="applicantApproval" [value]="true" tabindex="0"/>
                <label class="grouped-label blackk fs14 fw600 ms-2">Yes</label>
              </div>
              <div class="ui radio checkbox gs-grouped-filed">
                <input class="hidden" type="radio" [(ngModel)]="applicantApproval" name="applicantApproval" [value]="false" tabindex="0"/>
                <label class="grouped-label blackk fs14 fw600 ms-2">No</label>
              </div>
            </div>

          </div>
          <div class="step-navigation white-background mb-4 p16 br8">
            <button (click)="back()" class="secondary-btn me-3">Previous</button>
            <button class="blue-btn">Next</button>
          </div>
          </form>
        </div>

        <!-- <div *ngIf="activeStepIndex === 2">
          <form novalidate #selectionProcessForm="ngForm"
          (ngSubmit)="selectionProcessForm.form.valid && updateSelectionProcessForm();nextStep('third')">
          <div class="white-background p16 br8 mb16">
            <p class="fw600 fs16 blackk">Recruitment Members</p>
                  <div class="line"></div>

                  <div class="row">
                    <div class="mb-3 col-md-6 col-lg-4 figma-ng-select">
                      <label for="Nationality" class="form-label fw500 neugray">Hiring Team Member <sup>*</sup></label> -->
                      <!-- (change)="getnationalityid($event)" -->
                      <!-- <ng-select class="custom" bindLabel="name" bindValue="id"
                        placeholder="Select hiring team member" [ngModel]="hiringTeamMember" name="hiringTeamMemberSelect" #hiringTeamMemberSelect="ngModel" required> -->
                        <!--  formControlName="pernationality" -->
                      <!-- </ng-select>
                      <div *ngIf="(hiringTeamMemberSelect.touched || selectionProcessForm.submitted) &&
                      hiringTeamMemberSelect.errors?.['required']">
                          <span class="errorMessage"> Please select hiring team member</span>
                        </div>
                    </div>
                    <div class="mb-3 col-md-6 col-lg-4 figma-ng-select">
                      <label for="Nationality" class="form-label fw500 neugray">Hiring Stage<sup>*</sup></label>
                      <ng-select class="custom" bindLabel="name" bindValue="id"
                        placeholder="Select hiring stage"  [ngModel]="hiringStage" name="hiringStageSelect" #hiringStageSelect="ngModel" required>
                      </ng-select>
                      <div *ngIf="(hiringStageSelect.touched || selectionProcessForm.submitted) &&
                      hiringStageSelect.errors?.['required']">
                          <span class="errorMessage"> Please select hiring stage</span>
                        </div>
                    </div>
                    <div class="mb-3 col-md-6 col-lg-4 figma-ng-select">
                      <label for="Nationality" class="form-label fw500 neugray">Interview Template<sup>*</sup></label>
                      <ng-select class="custom" bindLabel="name" bindValue="id"
                        placeholder="Select interview template" [ngModel]="interviewTemplate" name="interviewTemplateSelect" #interviewTemplateSelect="ngModel" required>
                      </ng-select>
                      <div *ngIf="(interviewTemplateSelect.touched || selectionProcessForm.submitted) &&
                      interviewTemplateSelect.errors?.['required']">
                          <span class="errorMessage"> Please select interview template</span>
                        </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="mb-3 col-md-6 col-lg-4 figma-calender">
                      <label for="Nationality" class="form-label fw500 neugray">Cut-off Date<sup>*</sup></label>
                      <div>
                        <p-calendar [showIcon]="true"  class="primecalendar"
                        placeholder="Select cut-off date" [ngModel]="cutOffDate" name="cutOffDateSelect" #cutOffDateSelect="ngModel" [required]="true"></p-calendar>
                        <div *ngIf="(cutOffDateSelect.touched || selectionProcessForm.submitted) &&
                        cutOffDateSelect.errors?.['required']">
                          <span class="errorMessage"> Please select cut-off-date</span>
                        </div>
                      </div>

                    </div>
                    <div class="mb-3 col-md-6 col-lg-4 figma-ng-select">
                      <label for="Nationality" class="form-label fw500 neugray">Type of Interview<sup>*</sup></label>
                      <ng-select class="custom" bindLabel="name" bindValue="id"
                        placeholder="Select type of interview"  [ngModel]="typeOfInterview" name="typeOfInterviewSelect" #typeOfInterviewSelect="ngModel" required>
                      </ng-select>
                      <div *ngIf="(typeOfInterviewSelect.touched || selectionProcessForm.submitted) &&
                      typeOfInterviewSelect.errors?.['required']">
                        <span class="errorMessage"> Please select type of interview</span>
                      </div>
                    </div>
                  </div>

                    <app-pop-up (click)="showDialog()" [buttonText]="'Add to List'" [buttonClass]="'add-approver-btn'"></app-pop-up>



          </div> -->
          <!-- <div class="table-responsive">
            <table class="table selection-table">
              <thead>
                <tr class="white-background br8">
                  <th class="blackk fs14 fw600 p16">Sr.No  <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Name <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Hiring stage <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Template <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Cut-off date <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Interview Type <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Group Discussion <img src="../../../assets/Images/sort-icons.svg" alt="sort" class="ms-2 pointer"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of selectionTable; let i = index"  class="white-background br8">
                  <td class="fs14 blackk fw400 p16">{{ x.level }}</td>
                  <td class="fs14 blackk fw400 p16">{{ x.name }}</td>
                  <td class="fs14 blackk fw400 p16">{{ x.stage }}</td>
                  <td class="fs14 blackk fw400 p16">{{x.template}}</td>
                  <td class="fs14 blackk fw400 p16">{{x.cutOffDate}}</td>
                  <td class="fs14 blackk fw400 p16">{{x.interviewType}}</td>
                  <td class="fs14 blackk fw400 p16"><input type="checkbox"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="step-navigation white-background mb-4 p16 br8">
            <button (click)="back()" class="secondary-btn me-3">Previous</button>
            <button type="submit" class="blue-btn">Submit</button>
          </div>
          </form>

        </div>

      </div>
    </div>
  </div> -->
  <!-- <div class="flex justify-content-center">
      <p-accordion class="w-full" [activeIndex]="1">
        <p-accordionTab header="2. Internal Job Posting">




        </p-accordionTab>
      </p-accordion>
  </div> -->

