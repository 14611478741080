<main class="side-body-main">
  <div
    class="d-flex align-items-center job-details-top-part justify-content-between"
  >
    <div class="back-btn d-flex align-items-center">
      <button type="button">
        <img src="./assets/Images/back-arrow.svg" alt="back" />
      </button>
      <h1>Create Job Posting</h1>
    </div>
    <div class="breadcrumbs">
      <div class="flex justify-content-center">
        <p-breadcrumb
          class="max-w-full"
          [model]="breadcrumbitem"
        ></p-breadcrumb>
      </div>
    </div>
    <div class="campaign-part d-flex align-items-center">
      <div class="d-flex align-items-center gap16">
        <div class="grey-button">
          <button type="button" class="">+ &nbsp; Add Campaign</button>
        </div>
        <div class="small-button">
          <button type="button">
            <img src="./assets/Images/rotate-left.svg" alt="rotate" />
          </button>
        </div>
        <div class="small-button">
          <button type="button">
            <img src="./assets/Images/message-text.svg" alt="rotate" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <div class="basic-job-details-part details-accordion">
    <div class="flex justify-content-center">
      <p-accordion [activeIndex]="0">
        <p-accordionTab header="1. Basic Job Details">
          <div class="line"></div>
          <div class="accordion-parttt-body">
            <router-outlet></router-outlet>
            <!-- components switching -->

            <!-- <app-basic-job-details
              *ngIf="showBasicDetails"
              (showJobDescription)="
                showBasicDetails = false; showJobDescription = true
              "
            ></app-basic-job-details>

            <app-job-description
              *ngIf="showJobDescription"
              (showBasicDetails)="showBasicDetails = true"
              (showJobApprovers)="showJobApprovers = true"
            ></app-job-description>

            <app-job-approvers
              *ngIf="showJobApprovers"
              (showJobDescription)="
                showJobDescription = false; showJobApprovers = false
              "
            ></app-job-approvers> -->

            <!-- components switching -->
          </div>
        </p-accordionTab>

        <p-accordionTab header="Header II">
          <div class="line"></div>
          <div class="accordion-parttt-body">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
            eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci
            velit, sed quia non numquam eius modi.
          </div>
        </p-accordionTab>

        <p-accordionTab header="Header III">
          <div class="line"></div>
          <div class="accordion-parttt-body">
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores et quas molestias excepturi sint occaecati cupiditate non
            provident, similique sunt in culpa qui officia deserunt mollitia
            animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis
            est et expedita distinctio. Nam libero tempore, cum soluta nobis est
            eligendi optio cumque nihil impedit quo minus.
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</main>

