 <!--<section class="referal_job_description white-background">
    <div class="job_details d-flex justify-content-between ">
        <div>
            <ul>
                <li>Campus: <span>East Coast Campus</span> Singapore</li>
                <li>Category : <span>Academic</span></li>
                <li>Type : <span>New</span></li>
                <li>Cretaed : <span>July 19, 2023</span></li>
                <li>Cut off Date : <span>July 31, 2023</span></li>
                <li>Job Description :</li>
            </ul>
        </div>

        <div class="d-flex align-items-center gap12 job-list-add-buttons align-self-baseline">
            <button type="button" class="secondary-btn" routerLink="/apply-behalf"> Apply </button>
            <button type="button" class="blue-btn" routerLink="/new-applicant-form"> Refer for this Position </button>
          </div>
    </div>

    <div class="description">
        <div class="row">
            <div class="col-md-4">
                <h5>Overview *</h5>
            </div>
            <div class="col-md-8">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>

            <div style="border-bottom: 1px solid #C6C6C6; margin-bottom: 24px; padding-bottom: 24px;"></div>
            <div class="col-md-4">
                <h5>Responsibilities *</h5>
            </div>
            <div class="col-md-8">
                <ul>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                </ul>
            </div>

            <div style="border-bottom: 1px solid #C6C6C6; margin-bottom: 24px; padding-bottom: 24px;"></div>
            <div class="col-md-4">
                <h5>Skill Description *</h5>
            </div>
            <div class="col-md-8 new_li">
                <ul>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                </ul>
            </div>

            <div style="border-bottom: 1px solid #C6C6C6; margin-bottom: 24px; padding-bottom: 24px;"></div>
            <div class="col-md-4">
                <h5>About Us*</h5>
            </div>
            <div class="col-md-8">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>

            <div style="border-bottom: 1px solid #C6C6C6; margin-bottom: 24px; padding-bottom: 24px;"></div>
            <div class="col-md-4">
                <h5>Disclaimer*</h5>
            </div>
            <div class="col-md-8">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                <p>Note:Lorem Ipsum is simply dummy text of the printing and typesetting industry. i</p>
            </div>

            <div style="border-bottom: 1px solid #C6C6C6; margin-bottom: 24px; padding-bottom: 24px;"></div>
            <div class="col-md-4">
                <h5>Skill Category *</h5>

            </div>
            <div class="col-md-8">
                <div class="skill_set">
                    <div>
                        <h5 class="mb-3">Skill*</h5>
                        <div class="d-flex">
                            <p class="d-flex">Skill 1<img src="./assets/Images/x-close.svg" alt=""></p>
                            <p class="d-flex">Skill 2<img src="./assets/Images/x-close.svg" alt=""></p>
                            <p class="d-flex">Skill 3<img src="./assets/Images/x-close.svg" alt=""></p>
                            <p class="d-flex">Skill 4<img src="./assets/Images/x-close.svg" alt=""></p>
                            <p class="d-flex">Skill 5<img src="./assets/Images/x-close.svg" alt=""></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section> -->

<main class="managers-details-main p-3">
    <div class="back d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center pointer" [routerLink]="'/job-listing'">
        <svg
          width="22"
          height="22"
          fill="currentColor"
          class="bi bi-arrow-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
          />
        </svg>
        <p class="fw600 fs18 ms-3 page-head">Back To Job-Listing <p>
      </div>
      <div class="d-flex align-items-center gap12 job-list-add-buttons align-self-baseline">
        <button type="button" class="secondary-btn" style="width:max-content;" routerLink="/apply-behalf"> Apply </button>
        <button type="button" class="blue-btn" style="width: max-content;" [routerLink]="'/referal-form/'+lastSegment" > Refer for this Position </button>
      </div>
      
  
    </div>
  
    <section class="details-basic-info-part mt-3">
      <div class="top-part d-flex justify-content-between">
        <h1 class="fw600 fs16 mb-3">Basic Info</h1>
      </div>
      <div class="line my-0"></div>
      <h4 class="fw600 fs16 mt-4">Job Information</h4>
      <div class="basic-info-parts py-3 d-flex">
        <div class="field-part">
          <h2>TRA ID</h2>
          <h3>{{trName}}</h3>
        </div>
        <div class="field-part">
          <h2>School</h2>
          <h3>{{brandName}}</h3>
        </div>
        <div class="field-part">
          <h2>Job Title</h2>
          <h3>{{titleName}}</h3>
        </div>
        <div class="field-part">
          <h2>Country</h2>
          <h3>{{countryName}}</h3>
        </div>
        <div class="field-part">
          <h2>Campus</h2>
          <h3> {{campusName}} </h3>
          <!-- <h3 *ngFor="let x of commaSeperated(trdata?.campusNames)">{{ x }}</h3> -->
        </div>
      </div>
  
      <div class="basic-info-parts py-3 d-flex">
  
        <div class="field-part">
          <h2>Department</h2>
          <h3>{{department}}</h3>
  
        </div>
        <div class="field-part">
          <h2>Recruiter</h2>
          <h3>{{recruitmentmanagername}}</h3>
        </div>
        <div class="field-part">
          <h2>Number of Positions</h2>
          <h3>{{numberOfPositions}}</h3>
        </div>
        <div class="field-part">
          <h2>Job Category</h2>
          <h3>{{jobCategoryname}}</h3>
        </div>
        <div class="field-part">
          <h2>Priority</h2>
          <h3>{{priority}}</h3>
        </div>
      </div>
  
      <h4 class="fw600 fs16 mt-4">Type of Hiring</h4>
      <div class="basic-info-parts py-3 d-flex">
  
        <div class="field-part">
          <h2>Type of Hiring</h2>
          <h3>{{typeofhiringname}}</h3>
        </div>
        <div class="field-part pe-1">
          <h2>Rational for Hiring Non-Budgeted position</h2>
          <h3 [innerHTML]="rationalForHiringNonBudgetedPosition"></h3>
        </div>
        <div class="field-part">
          <h2>Type Of Employment</h2>
          <h3>{{typesofemployment}}</h3>
        </div>
      </div>
  
      <h4 class="fw600 fs16 mt-4">Employment Related</h4>
      <div class="basic-info-parts py-3 d-flex flex-wrap" style="row-gap: 12px;">
        <div class="field-part">
          <h2>Employment Duration( In Days)</h2>
          <h3>{{employmentDuration}}</h3>
        </div>
        <div class="field-part">
          <h2>Expected Joining Date</h2>
          <h3>{{expectedDateOfJoining | date}}</h3>
        </div>
        <div class="field-part">
          <h2>Type of Appointment</h2>
          <h3>{{typeofappointmentname}}</h3>
        </div>
        <div class="field-part">
          <h2>Salary Offered Range Code (In Annual)</h2>
          <h3>{{salaryOfferRangeSymbol}}</h3>
        </div>
        <div class="field-part">
          <h2>Salary Offered Range From (In Annual)</h2>
          <h3>{{salaryOfferRangeFrom}}</h3>
        </div>
        <div class="field-part p-0">
          <h2>Salary Offered Range To (In Annual)</h2>
          <h3>{{salaryOfferRangeTo}}</h3>
        </div>
      </div>
  
      <h4 class="fw600 fs16 mt-4">Post Job To</h4>
      <div class="basic-info-parts py-3 d-flex" *ngFor="let m of postjobtoDetail; let n = index">
  
        <div class="field-part" >
          <h2 *ngIf="n==0">Post Job to</h2>
          <h3 >{{m?.postjobto}}</h3>
        </div>
        <div class="field-part" >
          <h2 *ngIf="n==0">Publish Start Date</h2>
          <h3>{{m.publishstartdate | date}}</h3>
        </div>
        <div class="field-part" >
          <h2 *ngIf="n==0">Publish End Date</h2>
          <h3>{{m.publishenddate | date}}</h3>
        </div>
      </div>
  
      <h4 class="fw600 fs16 mt-4">Hiring SPOC’s</h4>
      <div class="basic-info-parts py-3 d-flex">
  
        <div class="field-part" >
          <h2>Hiring Manager</h2>
          <h3>{{hiringmangername}}</h3>
        </div>
        <div class="field-part">
          <h2>Recruitment Manager</h2>
          <h3>{{recruitmentmanagername}}</h3>
        </div>
        <div class="field-part">
          <h2>Business Partner</h2>
          <h3>{{businesspartnername}}</h3>
        </div>
      </div>
  
      <h4 class="fw600 fs16 mt-4">Applicant Eligibility Crieteria</h4>
      <div class="basic-info-parts py-3 d-flex" *ngFor="let m of industryDetail; let n = index">
  
        <div class="field-part">
          <h2 *ngIf="n==0">Preferred Industry</h2>
          <h3>{{m?.prefferedIndustryName}}</h3>
        </div>
        <div class="field-part">
          <h2 *ngIf="n==0">Industry Experience( In Years)</h2>
          <h3>{{m.prefferedIndustryExperienceFrom}}</h3>
        </div>
        <!-- <div class="basic-info-parts py-3 d-flex" *ngFor="let m of domaindetail; let n = index"> -->
          <ng-container *ngFor="let m of domaindetail; let n = index">
        <div class="field-part" >
          <h2 *ngIf="n==0">Preferred Domain</h2>
          <h3>{{m.preferedDomainName}}</h3>
        </div>
        <div class="field-part">
          <h2 *ngIf="n==0">Domain Experience( In Years)</h2>
          <h3>{{m.domainExperienceFrom}}</h3>
        </div>
      </ng-container>
        <div class="field-part">
          <h2>Total Experience( In Years)</h2>
          <h3>{{totalExperience}}</h3>
        </div>
      </div>
  
      <div class="basic-info-parts py-3 d-flex flex-wrap" style="row-gap: 12px;" >
  
        <div class="field-part">
          <h2>Current Salary Currency Code( Monthly)</h2>
          <h3>{{currentSalarySymbol}}</h3>
        </div>
        <div class="field-part">
          <h2>Current Salary Range From( Monthly)</h2>
          <h3>{{currentSalaryFrom}}</h3>
        </div>
        <div class="field-part">
          <h2>Current Salary Range To( Monthly)</h2>
          <h3>{{currentSalaryTo}}</h3>
        </div>
        <div class="field-part">
          <h2>Higher level of Education</h2>
          <h3>{{preferredHigherEducationname}}</h3>
        </div>
        <div class="field-part">
          <h2>Qualifications</h2>
          <h3>{{preferredQualificationsname}}</h3>
        </div>
        <div class="field-part p-0">
          <h2>Languages Known</h2>
          <h3>{{languageName}}</h3>
        </div>
        <div class="field-part">
          <h2>Nationality</h2>
          <h3>{{preferredNationalityname}}</h3>
        </div>
      </div>
  
      <div class="basic-info-parts py-3 d-flex">
  
        <div class="field-part">
          <h2>Gender</h2>
          <h3>{{preferredGender}}</h3>
        </div>
        <div class="field-part">
          <h2>Notice period ( in Days )</h2>
          <h3>{{noticePeriodFrom}}</h3>
        </div>
        <div class="field-part">
          <h2>Time needed to relocate ( in Days ) </h2>
          <h3>{{timeNeededToRelocate}}</h3>
        </div>
        <div class="field-part">
          <h2>Preferred age ( Above 18 years )</h2>
          <h3>{{preferredAgeBarFrom}}</h3>
        </div>
      </div>
  
      <h4 class="fw600 fs16 mt-4">Required Documents</h4>
      <div class="basic-info-parts py-3 d-flex" *ngFor="let m of documentTypedeatil; let n = index">
        <div class="field-part">
          <h2 *ngIf="n==0">Document Type</h2>
          <h3>{{m.documentTypeName}}</h3>
        </div>
        <div class="field-part">
          <h2 *ngIf="n==0">Stage</h2>
          <h3>{{m.stageName || "--"}}</h3>
        </div>
      </div>
  
      <h4 class="fw600 fs16 mt-4">Work Permit Related</h4>
      <div class="basic-info-parts py-3 d-flex">
        <div class="field-part">
          <h2>Work Permit</h2>
          <h3>{{workPermitname}}</h3>
        </div>
        <div class="field-part">
          <h2>Country</h2>
          <h3>{{workPermitCountrys}}</h3>
        </div>
        <div class="field-part">
          <h2>Work Permit Type</h2>
          <h3>{{workPermitTypes}}</h3>
        </div>
      </div>
    </section>
  
    <section class="details-basic-info-part job-description-part-main mt-3">
      <div class="top-part d-flex justify-content-between">
        <h1 class="fw600 fs16">Job Description</h1>
      </div>
      <div class="line my-3"></div>
      <div class="overview-part mt-2">
        <h1 class="fw600 fs14">Overview</h1>
        <p [innerHTML]="overview"></p>
      </div>
      <div class="responsibilities-part mt-3">
        <h1 class="fw600 fs14">Responsibilities</h1>
        <ul class="pl-4 mt-2">
          <p [innerHTML]="responsibility"> </p>
        </ul>
      </div>
  
      <div class="responsibilities-part mt-3" >
        <h1 class="fw600 fs14">Skills Description</h1>
        <ul class="pl-4 mt-2">
          <p [innerHTML]="skillsDescription"></p>
  
        </ul>
      </div>
  
      <div class="responsibilities-part mt-3">
        <h1 class="fw600 fs14">Website Short Description</h1>
        <ul class="pl-4 mt-2">
          <p [innerHTML]="websiteShortDescription"> </p>
  
        </ul>
      </div>
  
      <div class="responsibilities-part mt-3">
        <h1 class="fw600 fs14">Disclaimer</h1>
          <p class="fw400 fs16 mt-2" [innerHTML]="disclaimer"></p>
      </div>
  
      <!-- <div class="d-flex align-items-center gap-3">
      <div class="responsibilities-part mt-3" *ngFor="let m of documentTypedeatil; let n = index" >
        <h1 *ngIf="n==0" class="fw600 fs14">Skills Category</h1>
          <p> {{m.skillCategory}} </p>
      </div>
  
      <div class="responsibilities-part mt-3" >
        <h1 *ngIf="n==0" class="fw600 fs14">Skills</h1>
          <p> {{m.skillsName}} </p>
      </div> -->
    <!-- </div> -->
      <div class="basic-info-parts py-3 d-flex" *ngFor="let m of skilldeatil; let n = index">
        <div class="field-part">
          <h2 *ngIf="n==0">Skills Category</h2>
          <h3>{{m.skillCategoryName}}</h3>
        </div>
        <div class="field-part">
          <h2 *ngIf="n==0">Skills</h2>
          <h3>{{m.skillsName || "--"}}</h3>
        </div>
      </div>
    </section>
    <!-- <section class="details-basic-info-part mt-3">
      <div class="top-part">
        <div
          class="d-md-flex col-md-12 figma__ng__select mt-3"
          > -->
          <!-- *ngFor="let item of TRFInfoData.jobskill; let i = index" -->
          <!-- <div
            class="col-md-6 col-xl-4 field pl-0 pr-4 figma-ng-select"
          >
            <label class="form-label-d new__input__label"
              >Skills Category<span class="mandiatory">*</span></label
            >
  
            <ng-select
              [items]="skill_list"
              bindLabel="skill_CategoryName"
              bindValue="skill_CategoryId"
              [(ngModel)]="skillCategory"
              name="trfSkillsCCAtegorry"
              #trfSkillsCCAtegorry="ngModel"
              placeholder="Select skills category"
              [multiple]="false" disabled
            >
            </ng-select>
          </div>
          <div
            class="col-md-6 col-xl-4 figma-ng-select ms-3"
          >
            <label class=" "
              >Skills<span class="mandiatory">*</span></label
            >
            <ng-select
              [items]=" skill_list"
              bindLabel="skillName"
              bindValue="skillCategoryId"
              [(ngModel)]="skills"
              name="trffSkiillss"
              #trffSkiillss="ngModel"
              placeholder="Select skills"
              [multiple]="true"
            disabled
            >
            </ng-select>
          </div>
        </div>
      </div>
    </section> -->
  
    <!-- <section class="details-basic-info-part approval-list-part-main mt-3">
      <div class="top-part d-flex justify-content-between">
        <h1 class="fw600 fs16">Recruiting Members</h1>
      </div>
      <div class="line my-2"></div>
  
      <div class="approval-list-table-main">
        <div class="">
          <table class="table">
            <thead>
                <tr>
                    <th>S.No <img src="./assets/Images/sort-icons.svg" alt="sort"></th>
                    <th>Member <img src="./assets/Images/sort-icons.svg" alt="sort"></th>
                    <th colspan="3">Hiring stage<img src="./assets/Images/sort-icons.svg" alt="sort"></th>
                    <th>Interview Template <img src="./assets/Images/sort-icons.svg" alt="sort"></th>
                    <th>Cut-off Date <img src="./assets/Images/sort-icons.svg" alt="sort"></th>
                    <th>Interview Tye <img src="./assets/Images/sort-icons.svg" alt="sort"></th>
                    <th>Group Discussion <img src="./assets/Images/sort-icons.svg" alt="sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let m of hiringdetail; let n = index">
                  <td>
                    <div class="d-flex justify-content-center">
                      {{n+1}}
                    </div>
                  </td>
                  <td>{{m.teamMember}}</td>
                  <td colspan="3">{{m.hiringStageName}}</td>
                <td>
                  <div class="d-flex justify-content-center">
                  {{m.interviewTemplate}}
                </div>
                </td>
                <td>{{m.cutoffDate | date}}</td>
                <td> {{m.interviewType}} </td>
                <td> - </td>
                </tr>
            </tbody>
        </table>
  
        </div>
      </div>
  
    </section> -->
  
    <!-- <section class="details-basic-info-part approval-list-part-main mt-3">
      <div class="top-part d-flex justify-content-between">
        <h1 class="fw600 fs16">Internal Job Posting</h1>
      </div>
      <div class="line my-2"></div>
      <h4 class="fw600 fs16 mt-4">Eligible Brand</h4>
      <div class="basic-info-parts py-2">
        <div class="field-part internal_fields" *ngFor="let m of targetdetail; let n = index" >
          <h2>{{m.targetBrandName}}</h2>
          
        </div>
      </div>
  
      <h4 class="fw600 fs16 mt-4">Eligible Country</h4>
      <div class="basic-info-parts py-2">
        <div class="field-part" *ngFor="let m of targetdetail; let n = index">
          <h2>{{m.targetCountryName}}</h2>
        
        </div>
      </div>
  
      <h4 class="fw600 fs16 mt-4">Eligible Campus</h4>
      <div class="basic-info-parts py-2">
        <div class="field-part" *ngFor="let m of targetdetail; let n = index">
          <h2>{{m.targetCampusName}}</h2>
         
        </div>
      </div>
  
    </section> -->
  
  
    <!-- <section class="details-basic-info-part approval-list-part-main mt-3">
      <div class="top-part d-flex justify-content-between">
        <h1 class="fw600 fs16"> Program Approvers </h1>
      </div>
      <div class="line my-2"></div>
  
      <div class="approval-list-table-main">
        <div class="">
          <table class="table">
            <thead>
                <tr>
                    <th >Level <img src="./assets/Images/sort-icons.svg" alt="sort"></th>
                    <th >Approvers Designation <img src="./assets/Images/sort-icons.svg" alt="sort"></th>
                    <th colspan="3">Members Name/ Email Id <img src="./assets/Images/sort-icons.svg" alt="sort"></th>
                </tr>
            </thead>
            <tbody>
                <tr  *ngFor="let m of approverdetail; let n = index" >
                  <td> {{m.levels}} </td>
                  <td> {{m.role}} </td>
                  <td colspan="3">
                    <div class="mt-1">
                    {{m.approveMember}}
                    </div>
                </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-4">
          <p class="fw400 fs14">Does this job require approval from Applicant Reporting Manager ?</p>
          <p class="fw600 fs16">Yes</p>
        </div>
        </div>
      </div>
  
    </section> -->
  
    <!-- <div class=" flex justify-content-center rejectModal">
      <p-dialog
          [(visible)]="visible"
          [modal]="true"
          [style]="{ width: '500px', }">
          <div>
            <div class="d-flex justify-content-between align-items-start">
              <p class="fs18 fw600 font-black mb-3">Are you sure you want to reject the request</p>
              <img src="../../../assets/Images/x-close.svg" (click)="visible = false"  alt="">
            </div>
            <textarea class="p-2 mb-3" name="rejectReason" id="rejectDescription" rows="8" cols="58" placeholder="Describe"></textarea>
            <div class="d-flex justify-content-end">
              <button class="secondary-btn" (click)="visible = false" >Cancel</button>
              <button class="reject-button ms-3" (click)="visible = false">Reject</button>
            </div>
          </div>
      </p-dialog>
    </div> -->
  </main>
  
