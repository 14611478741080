<div class="backbuton">
  <img (click)="gotojobListingpage()" src="./assets/Images/create-arrow.svg" alt="">
  <span class="backtolistpade">Create Job Posting</span>
</div>

<div class="job-aprover-main-section">
  <app-stepper></app-stepper>

  <div class="border-class">

    <div class="card">
      <p-table [value]="products" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
              <tr>
                  <th>Level</th>
                  <th>Approvers Designation</th>
                  <th>Member Name</th>
                  <th>Email</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
              <tr>
                  <td>{{ product.code }}</td>
                  <td>{{ product.name }}</td>
                  <td>{{ product.category }}</td>
                  <td>{{ product.quantity }}</td>
              </tr>
          </ng-template>
      </p-table>
  </div>
  </div>
  <div>
    <button class="add-approver-btn">
      <img src="./assets/Images/add-icon.svg" alt="">
      <span>Add Approvers</span>
    </button>
  </div>
</div>


<div class="border-classnext" >
  <button class="secondary-btn me-3" (click)="gotojobListingpage()">Back</button>

  <button class="blue-btn" (click)="gotoJobDescription()">Next</button>

</div>

