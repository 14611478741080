import {
  Component,
  ComponentFactoryResolver,
  Injector,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JobsComponent } from './manage/jobs/jobs.component';
import { AppComponentBase } from './shared/app.component-base';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AppComponentBase implements OnInit {
  title = 'GIISCareer';

  public sub: any;
  public id: any;
  isLoginPage:boolean=false
  // public showHeader :boolean = true;
  constructor(
    public injector: Injector,
    public router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {
    super(injector);
    let rout: any = window.location.href;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // this.isLoginPage = event.url === '/login';
      }
    });
    // let data = rout.split('/').pop();
    // if (data == '') {
    //   this.showHeader = false;
    // }
    // this.appSettings
  }
  ngOnInit() {
    console.log(isDevMode());
    // this. loadComponent();
  }
  // loadComponent() {
  //   const componentFactory = this.componentFactoryResolver.resolveComponentFactory(JobsComponent);
  //   const componentRef = this.viewContainerRef.createComponent(componentFactory);
  // }
}
