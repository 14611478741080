<!-- Header : {{appSettings.showHeader}}
<app-header *ngIf="appSettings.showHeader"></app-header>

<app-footer *ngIf="appSettings.showHeader"></app-footer>

<main>
        <div *ngIf="!appSettings.showHeader">

                <router-outlet></router-outlet>

        </div>
</main> -->
<router-outlet></router-outlet>
