<div class="backbuton">
  <img (click)="gotojobListingpage()" src="./assets/Images/create-arrow.svg" alt="">
  <span class="backtolistpade">Create Job Posting</span>
</div>


<div class="job-aprover-main-section">
  <app-stepper></app-stepper>

  <div class="border-class">
    <h1 class="fw600 fs16">Hiring Team</h1>
          <div class="line"></div>

          <div class="row">
            <div class="mb-3 col-md-4 figma-ng-select">
              <label for="Nationality" class="form-label fw500 neugray">Hiring Team Member</label>
              <!-- (change)="getnationalityid($event)" -->
              <ng-select class="custom" bindLabel="nationality_Name" bindValue="nationality_Id"
                placeholder="Select Hiring Team Member" name="nationalityid">
                <!--  formControlName="pernationality" -->
              </ng-select>
            </div>
            <div class="mb-3 col-md-4 figma-ng-select">
              <label for="Nationality" class="form-label fw500 neugray">Hiring Stage</label>
              <ng-select class="custom" bindLabel="nationality_Name" bindValue="nationality_Id"
                placeholder="Select Hiring Stage"  name="nationalityid">
              </ng-select>
            </div>
            <div class="mb-3 col-md-4 figma-ng-select">
              <label for="Nationality" class="form-label fw500 neugray">Interview Template</label>
              <ng-select class="custom" bindLabel="nationality_Name" bindValue="nationality_Id"
                placeholder="Select Interview Template"  name="nationalityid">
              </ng-select>
            </div>

          </div>
          <div class="row">
            <div class="mb-3 col-md-4 figma-calender">
              <label for="Nationality" class="form-label fw500 neugray">Cut-off Date</label>
              <div>
                <p-calendar [showIcon]="true"  class="primecalendar"
                placeholder="Select cut-off date"></p-calendar>
              </div>

            </div>
            <div class="mb-3 col-md-4 figma-ng-select">
              <label for="Nationality" class="form-label fw500 neugray">Type of Interview</label>
              <ng-select class="custom" bindLabel="nationality_Name" bindValue="nationality_Id"
                placeholder="Select Type of Interview"  name="nationalityid">
              </ng-select>
            </div>
          </div>

            <app-pop-up (click)="showDialog()" [buttonText]="'Add to List'" [buttonClass]="'add-approver-btn'"></app-pop-up>



          <div class="card" style="margin-top: 15px;">
            <p-table [value]="products" [tableStyle]="{ 'min-width': '50rem' }">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Level</th>
                        <th>Approvers Designation</th>
                        <th>Member Name</th>
                        <th>Email</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>{{ product.code }}</td>
                        <td>{{ product.name }}</td>
                        <td>{{ product.category }}</td>
                        <td>{{ product.quantity }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
  </div>
  <div class="d-flex align-items-cente check-section">
    <input type="checkbox">
    <span>Are all Hiring stages added.</span>
  </div>

</div>

<!-- figma-calender -->


<div class="border-classnext">
  <button class="secondary-btn me-3" (click)="gotojobListingpage()">Back</button>
  <p-toast></p-toast>
  <button class="blue-btn" (click)="show(); gotoJobDescription()">Submit</button>

</div>







