<div class="backbuton mb-3">
  <img (click)="gotojobListingpage()" src="./assets/Images/create-arrow.svg" alt="">
  <span class="backtolistpage fs16 fw700">Create Job Posting</span>
</div>
<div class="row w-100">
  <div class="col-md-2 col-xl-1 col-lg-1">
    <div class="stepper white-background br8">
      <div>
      <ng-container *ngFor="let step of stepsArray; let i = index">
        <div
          class="step"
          [ngClass]="{
            active: i === activeStepIndex,
            completed: isStepCompleted(i)
          }"
          (click)="activeStepIndex = i"
          >
          <!-- (click)="setActiveStepIndex(i)" -->
          <p class="step-count pointer">{{ i + 1 }}</p>
        </div>
      </ng-container>
    </div>
      <!-- <div *ngIf="!referalscreen">
      <ng-container *ngFor="let step of stepsArray1; let i = index">
        <div
          class="step"
          [ngClass]="{
            active: i === activeStepIndex,
            completed: isStepCompleted(i)
          }"
            (click)="activeStepIndex = i"
          >
          (click)="setActiveStepIndex(i)"
          <p class="step-count pointer">{{ i + 1 }}</p>
        </div>
      </ng-container>
    </div> -->
    </div>
  </div>

  <div class="col-xl-11 col-lg-11 col-md-10">
    <div
      class="step-displayed white-background mb-4 p16 br8 d-flex justify-content-between"
    >
      <p class="fs14 fw600 blue-color">{{ activeStep() }}</p>
    </div>
    <!-- <div class="job-id-part d-flex align-items-center">
      <h3>Marketing Manager</h3>
      <img src="./assets/Images/approval.svg" alt="approval" />
      <h3>Job ID- A01232</h3>
    </div> -->
    <div class="job-post-steps">
      <div *ngIf="activeStepIndex === 0">
        <form
          novalidate
          #createJobForm="ngForm"
          (ngSubmit)="nextStep('first')"
        >
          <!-- <app-stepper></app-stepper> -->

          <div class="white-background p16 br8 mb16">
            <p class="fs14 fw600 blackk">Job Information</p>
            <div class="row">
              <div class="line2"></div>
              <div *ngIf="!treditId" class="col-md-6 col-xl-4 mb-3 figma-ng-select">
                <label for="trid" class="form-label fw500 neugray"
                  >TR-ID <sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="getridsss"
                  bindLabel="trfid"
                  bindValue="talentId"
                  placeholder="Select TR-ID"
                  [(ngModel)]="trid"
                  name="tridSelect"
                  #tridSelect="ngModel"
                  (change)="getTRInfo(trid)"
                  required>
                </ng-select>
                <div
                  *ngIf="(tridSelect.touched || createJobForm.submitted) &&
                tridSelect.errors?.['required']"
                >
                  <span class="errorMessage"> Please enter tr-id </span>
                </div>
              </div>
              <div *ngIf="treditId"class="col-md-6 col-xl-4 mb-3 figma-ng-select">
                <label for="trid" class="form-label fw500 neugray"
                >TR-ID <sup>*</sup></label>
              <input type="text" class="form-control" [(ngModel)]="trInfo" name="tridSelect" placeholder="Enter tr id" required readonly>

              </div>
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="jobtitle" class="form-label fw500 neugray"
                  >Job Title</label
                >
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="jobtitle"
                  name="jobtitlee"
                  #jobtitlee="ngModel"
                  placeholder="Job title"
                  readonly
                />
                <!-- (change)="getnationalityid($event)" -->
                <!-- <ng-select class="custom" bindLabel="jobtitle" bindValue="jobtitle" placeholder="Select job title"
                  [(ngModel)]="jobtitle"  name="jobtitleSelect" #jobtitleSelect="ngModel" required>
                </ng-select> -->
                <!-- <div *ngIf="(jobtitleSelect.touched || createJobForm.submitted) &&
                jobtitleSelect.errors?.['required']">
                    <span class="errorMessage"> Please select job title </span>
                  </div> -->
              </div>
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="School" class="form-label fw500 neugray"
                  >School</label
                >
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="mySchool"
                  name="mySchoolSelect"
                  #mySchoolSelect="ngModel"
                  placeholder="School"
                  readonly
                />
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="country" class="form-label fw500 neugray"
                  >Country</label
                >
                <!-- (change)="getnationalityid($event)" -->
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="countryName"
                  name="countrySelect"
                  onchange="onchaness()"
                  #countrySelect="ngModel"
                  placeholder="Country"
                  readonly
                />
                <!-- <ng-select class="custom" bindLabel="country" bindValue="country" placeholder="Select country"
                  [(ngModel)]="country" name="countrySelect" #countrySelect="ngModel" required>
                </ng-select> -->
                <!-- <div *ngIf="(countrySelect.touched || createJobForm.submitted) &&
                countrySelect.errors?.['required']">
                    <span class="errorMessage"> Please select country </span>
                  </div> -->
              </div>
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="campus" class="form-label fw500 neugray"
                  >Campus</label
                >
                <!-- (change)="getnationalityid($event)" -->
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="campus"
                  name="campusSelect"
                  #campusSelect="ngModel"
                  placeholder="Campus"
                  readonly
                />
                <!-- <ng-select class="custom" bindLabel="campus" bindValue="campus" placeholder="Select campus"
                  [(ngModel)]="campus" name="campusSelect" #campusSelect="ngModel" required>
                </ng-select> -->
                <!-- <div *ngIf="(campusSelect.touched || createJobForm.submitted) &&
                campusSelect.errors?.['required']">
                    <span class="errorMessage"> Please select campus </span>
                  </div> -->
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="campus" class="form-label fw500 neugray"
                  >Department</label
                >
                <!-- (change)="getnationalityid($event)" -->
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="department"
                  name="departmentSelect"
                  #departmentSelect="ngModel"
                  placeholder="Department"
                  readonly
                />
                <!-- <ng-select class="custom" bindLabel="campus" bindValue="campus" placeholder="Select campus"
                  [(ngModel)]="campus" name="campusSelect" #campusSelect="ngModel" required>
                </ng-select> -->
                <!-- <div *ngIf="(campusSelect.touched || createJobForm.submitted) &&
                campusSelect.errors?.['required']">
                    <span class="errorMessage"> Please select campus </span>
                  </div> -->
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="jobCategory" class="form-label fw500 neugray"
                  >Job Category<sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="jobcategory"
                  bindLabel="jobCategoryName"
                  bindValue="jobCategoryId"
                  placeholder="Select job category"
                  [(ngModel)]="jobCategory"
                  name="jobCategorySelect"
                  #jobCategorySelect="ngModel"
                  required
                >
                </ng-select>
                <div
                  *ngIf="(jobCategorySelect.touched || createJobForm.submitted) &&
                jobCategorySelect.errors?.['required']"
                >
                  <span class="errorMessage"> Please select job category </span>
                </div>
              </div>
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="jobCategory" class="form-label fw500 neugray"
                  >Priority <sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="GetPriority"
                  bindLabel="priority_Name"
                  bindValue="priority_Name"
                  placeholder="Select priority"
                  [(ngModel)]="priority"
                  name="prioritySelect"
                  #prioritySelect="ngModel"
                  required
                >
                </ng-select>
                <div
                  *ngIf="(prioritySelect.touched || createJobForm.submitted) &&
                prioritySelect.errors?.['required']"
                >
                  <span class="errorMessage"> Please select priority </span>
                </div>
              </div>
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="numberofposition" class="form-label fw500 neugray"
                  >Number of Positions<sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <!-- <ng-select class="custom" bindLabel="numberofposition" bindValue="numberofposition"
                  placeholder="Select number of positions" [(ngModel)]="numberofposition" name="numberofpositionSelect" #numberofpositionSelect="ngModel" required>
              </ng-select> -->
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="numberofposition"
                  name="numberofpositionSelect"  (keyup)="slectappoinmentId(numberofposition)"
                  #numberofpositionSelect="ngModel"
                  placeholder="Enter number of positions"
                  onkeypress="return /[0-9]/i.test(event.key)"
                  [maxLength]="3"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                  required
                />
                <!-- <ng-select class="custom" bindLabel="noPositions" bindValue="noPositions"
              placeholder="Select" [(ngModel)]="numberofposition" name="numberofpositionSelect" #numberofpositionSelect="ngModel" required>
            </ng-select> -->
                <div
                  *ngIf="(numberofpositionSelect.touched || createJobForm.submitted) &&
                numberofpositionSelect.errors?.['required']"
                >
                  <span class="errorMessage">
                    Please enter number of positions
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- <div class="mb-3 col-md-4 figma-ng-select">
                <label for="department" class="form-label fw500 neugray">Department<sup>*</sup></label>
                <ng-select class="custom" bindLabel="department" bindValue="department" placeholder="Select department"
                  [(ngModel)]="department" name="departmentSelect" #departmentSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(departmentSelect.touched || createJobForm.submitted) &&
                departmentSelect.errors?.['required']">
                    <span class="errorMessage"> Please select department </span>
                  </div>
              </div> -->
            </div>
            <div class="row">
              <!-- <div class="mb-3 col-md-4 figma-ng-select">
                <label for="requiter" class="form-label fw500 neugray">Recruiter<sup>*</sup></label>
                <ng-select class="custom" bindLabel="requiter" bindValue="requiter" placeholder="Select recruiter"
                  [(ngModel)]="requiter" name="requiterSelect" #requiterSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(requiterSelect.touched || createJobForm.submitted) &&
                requiterSelect.errors?.['required']">
                    <span class="errorMessage"> Please select recruiter </span>
                  </div>
              </div> -->
            </div>
            <!-- <span>Priority</span>
            <div style="display: flex; align-items: center;margin-right: 2px;">
              <div>
                <input type="radio" value="High" style="margin-right: 10px;margin-top: 10px;" [(ngModel)]="priority" name="priorityRadio" #priorityRadio="ngModel" required>
                <span>High Priority</span>

              </div>
              <div style="margin-left: 10px;">

                <input type="radio" value="Low" style="margin-right: 10px;margin-top: 10px;" [(ngModel)]="priority" name="priorityRadio" >
                <span>Low Priority</span>
              </div>
              <div *ngIf="(priorityRadio.touched || createJobForm.submitted) &&
              priorityRadio.errors?.['required']">
                  <span class="errorMessage"> Please select number of positions </span>
                </div>
            </div> -->
          </div>
          <div class="second-hyringtype white-background p16 br8 mb16">
            <p class="fw600 fs16 blackk">Type of Hiring</p>
            <div class="line2"></div>

            <div class="row mt-4">
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="typeofhiring" class="form-label neugray"
                  >Type of Hiring<sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="ijphiring"
                  (change)="typeOfHiringEdit($event)"
                  bindLabel="hiring_Name"
                  bindValue="hiring_Id"
                  placeholder="Select type of hiring"
                  [(ngModel)]="typeofhiring"
                  name="typeofhiringSelect"
                  #typeofhiringSelect="ngModel"
                  required
                >
                </ng-select>
                <div
                  *ngIf="(typeofhiringSelect.touched || createJobForm.submitted) &&
                typeofhiringSelect.errors?.['required']"
                >
                  <span class="errorMessage">
                    Please select type of hiring
                  </span>
                </div>
              </div>

              <div class="col-12">
                <label
                  for="Nationality"
                  class="form-label neugray width-max budgeted-label pe-0"
                  >Reason for Hiring Non-Budgeted position<sup *ngIf="typeofhiring != 1">*</sup></label
                >
                <div class="editor">
                  <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
                  <p-editor
                    [(ngModel)]="textControl"
                    name="myeditor" #myeditor="ngModel"
                    [style]="{ height: '120px' }"  [required]="typeofhiring != 1" ></p-editor>
                </div>
                <div
                *ngIf=" ((myeditor.touched || createJobForm.submitted) && myeditor.errors?.['required'])"
              >
                <span class="errorMessage">
                  Please enter text
                </span>
              </div>
              </div>

              <div class="my-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="typeofemp" class="form-label fw500 neugray"
                  >Type Of Employment<sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="getEmployment"
                  bindLabel="employment_Name"
                  bindValue="employment_Id"
                  placeholder="Select type of employment"
                  [(ngModel)]="typeofemp"
                  name="typeofempSelect"
                  #typeofempSelect="ngModel"
                  required
                >
                </ng-select>
                <div
                  *ngIf="(typeofempSelect.touched || createJobForm.submitted) &&
              typeofempSelect.errors?.['required']"
                >
                  <span class="errorMessage">
                    Please select type of employment
                  </span>
                </div>
              </div>

              <div class="my-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="typeofduration" class="form-label fw500 neugray"
                  >Employment Duration (In Months)<sup>*</sup></label
                >
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="typeofdurationn"
                  name="typeofduration"
                  #typeofduration="ngModel"
                  placeholder="Employment duration"
                  [maxLength]="2" required
                  onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                  [maxlength]="3"
                />
                <!-- <ng-select class="custom" bindLabel="typeofduration" bindValue="typeofduration"
                placeholder="Select employment duration" [(ngModel)]="typeofdurationOne" name="typeofdurationSelect" #typeofdurationSelect="ngModel" required>
              </ng-select> -->
              <div *ngIf="(typeofduration.touched || createJobForm.submitted) &&
              typeofduration.errors?.['required']">
                  <span class="errorMessage"> Please enter employment duration </span>
                </div>
              </div>

              <div class="my-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="typeofemp" class="form-label fw500 neugray"
                  >Reporting Manager</label
                >
                <!-- <input type="text" class="form-control" [(ngModel)]="myReportingManager" name="myReportingManagerr"
              #myReportingManagerr="ngModel" placeholder="Reporting manager" readonly /> -->
                <ng-select
                  class="custom"
                  [items]="reportingmanager"
                  bindLabel="userName"
                  bindValue="userId"
                  placeholder="Select reporting manager"
                  [(ngModel)]="reportingManagerId"
                  name="reportingManagerSelect"
                  #reportingManagerSelect="ngModel"

                >
                </ng-select>
                <div
                  *ngIf="(reportingManagerSelect.touched || createJobForm.submitted) &&
              reportingManagerSelect.errors?.['required']"
                >
                  <span class="errorMessage">
                    Please select reporting manager
                  </span>
                </div>
              </div>

              <div class="my-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="typeofappointment" class="form-label fw500 neugray"
                  >Type of Appointment<sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <!-- <ng-select class="custom" bindLabel="typeofappointment" bindValue="typeofappointment"
                placeholder="Select type of appointment" formControlName="typeofappointment" name="typeofappointment">
              </ng-select> -->
                <ng-select
                  [items]="typeOfAppointmentData"
                  [clearable]="false"

                  bindLabel="appointment_Name"
                  bindValue="appointment_Id"
                  [(ngModel)]="typeofappointment"
                  name="typeofappointmentSelect"
                  #typeofappointmentSelect="ngModel"
                >
                </ng-select>
                <!-- <div
                  *ngIf="(typeofappointmentSelect.touched || createJobForm.submitted) &&
              typeofappointmentSelect.errors?.['required']"
                >
                  <span class="errorMessage">
                    Please select type of appointment
                  </span>
                </div> -->
              </div>

              <div
                class="table-responsive appointment--table"
                *ngIf="typeofappointment === 2"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        Employee ID
                        <img src="./assets/Images/sort-icons.svg" alt="sort" />
                      </th>
                      <th>
                        Name
                        <img src="./assets/Images/sort-icons.svg" alt="sort" />
                      </th>
                      <th>
                        Email ID
                        <img src="./assets/Images/sort-icons.svg" alt="sort" />
                      </th>
                      <th>
                        Department
                        <img src="./assets/Images/sort-icons.svg" alt="sort" />
                      </th>
                      <th>
                        Designation
                        <img src="./assets/Images/sort-icons.svg" alt="sort" />
                      </th>
                      <th>
                        Last Working Date
                        <img src="./assets/Images/sort-icons.svg" alt="sort" />
                      </th>
                      <th>
                        Reporting manager
                        <img src="./assets/Images/sort-icons.svg" alt="sort" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <ng-container *ngFor="let x of appointments"> -->
                    <tr
                      *ngFor="
                        let x of employeeList.employeeTable;
                        let i = index
                      "
                    >
                      <td>
                        <div class="figma-ng-select employeeIdSelect">
                          <ng-select
                            [items]="getemployeeid"
                            [clearable]="false"
                            bindLabel="userCode"
                            bindValue="userCode"
                            [(ngModel)]="employeeIddd[i]"
                            (change)="GetEmployeeDetails(employeeIddd[i],i)"
                            name="typeofappointmentSelect{{i}}"
                            #typeofappointmentSelect="ngModel"
                          >
                          </ng-select>
                        </div>
                      </td>
                      <ng-container
                        *ngIf="getemployeedetails[i]"
                      >
                        <td>{{ getemployeedetails[i]?.name }}</td>
                        <td>{{ getemployeedetails[i]?.email }}</td>
                        <td>{{ getemployeedetails[i]?.departmentName }}</td>
                        <td>{{ getemployeedetails[i]?.designation }}</td>
                        <td>{{ getemployeedetails[i]?.lastWorkingDate | date }}</td>
                        <td>{{ getemployeedetails[i].reportingManagerName }}</td>
                      </ng-container>
                    </tr>
                    <!-- </ng-container> -->
                  </tbody>
                </table>
              </div>

              <!-- <ng-container *ngIf="typeofappointment === 2">
              <fieldset class="my-textarea">
                <label class=""> Add Employee Details <sup>*</sup> </label>
                <textarea name="" id="" [(ngModel)]="employeeDetailsTextArea" name="employeeDetailsTextAreaa"
                ngModel="employeeDetailsTextAreaa" required></textarea>
              </fieldset>
            </ng-container> -->

              <div class="col-md-4"></div>
              <div class="col-md-4"></div>

              <div class="col-12">
                <table class="addjobs-first-table w-100">
                  <thead>
                    <th class="fs12 fw500">
                      Post Job To <sup >*</sup>
                    </th>
                    <th class="fs12 fw500">
                      Publish Start Date <sup>*</sup>
                    </th>
                    <th class="fs12 fw500">
                      Publish End Date <sup>*</sup>
                    </th>
                    <th></th>
                  </thead>
                  <tbody>
                    <ng-container>
                      <tr class="figma-ng-select figma-calender align-items-start">
                        <!-- <form #addjobsDatesFormm="ngForm"> -->
                        <td class="col-md-4">
                          <div class="col-md-12 p-0">
                            <ng-select
                              #mypostJObToo="ngModel"
                              name="mypostJObToo"
                              (change)="showAllDate($event)"
                              placeholder="Select post job to"
                              [items]="getpostJob"
                              bindLabel="ijpJobPostName"
                              bindValue="ijpJobPostId"
                              [(ngModel)]="mypostJObTo"
                              [multiple]="false"
                              [required]="isFieldRequired()"

                            >
                            </ng-select>
                            <div
                              *ngIf="(mypostJObToo.touched || createJobForm.submitted) &&
                mypostJObToo.errors?.['required']"
                            >
                              <span class="errorMessage">
                                Please select post job to
                              </span>
                            </div>
                          </div>
                          <!-- soureData -->
                        </td>
                        <td class="col-md-3">
                          <p-calendar
                            class="position-relative figma-calender"
                            name="myPublishStartDatee"
                            placeholder="Select date"
                            [(ngModel)]="myPublishStartDate"
                            dateFormat="mm/dd/yy" [minDate]="minPublishdate"
                            #myPublishStartDatee="ngModel"
                            inputId="icon"
                            [showIcon]="true"
                            [required]="isFieldRequired()"

                          ></p-calendar>

                          <div
                            *ngIf="(myPublishStartDatee.touched || createJobForm.submitted) &&
                          myPublishStartDatee.errors?.['required']"
                          >
                            <span class="errorMessage">
                              Please select date
                            </span>
                          </div>
                        </td>
                        <td class="col-md-3">
                          <p-calendar
                            class="position-relative figma-calender"
                            name="myPublishEndDatee"
                            placeholder="Select date"
                            (onSelect)="newonendDateSelected($event)"
                            [(ngModel)]="myPublishEndDate"
                            dateFormat="mm/dd/yy"[minDate]="edate1"
                            #myPublishEndDatee="ngModel"
                            inputId="icon"
                            [showIcon]="true"
                            [required]="isFieldRequired()"

                          ></p-calendar>
                          <div
                            *ngIf="(myPublishEndDatee.touched || createJobForm.submitted) &&
                          myPublishEndDatee.errors?.['required']"
                          >
                            <span class="errorMessage">
                              Please select date
                            </span>
                          </div>
                        </td>

                        <td class="col-md-1">
                          <button
                            type="button"
                            class="secondary-btn"
                            (click)="addDatesData()"
                            [disabled]="
                              !mypostJObTo ||
                              !myPublishStartDate ||
                              !myPublishEndDate
                            "
                          >
                            Add
                          </button>
                        </td>
                        <!-- [disabled]="basicInfoData.postJObTo || basicInfoData.publishStartDate || basicInfoData.publishEndDate || basicInfoData.hirirngProcessCutoffDate" -->
                        <!-- </form> -->
                      </tr>
                    </ng-container>
                    <ng-container
                      *ngFor="let x of addjobsDatesArray; let i = index"
                    >
                      <tr class="postJobDynamic">
                        <td class="pl-2">{{ x.postJobTo }}</td>
                        <td>{{ x.startDate | date : "dd/MM/yyyy" }}</td>
                        <td>
                          <div class="d-flex align-items-center">
                            {{ x.endDate | date : "dd/MM/yyyy" }}
                            <!-- <button type="button" class="btn secondary-btn ml-auto" style="padding:10px 20px !important"
                       name="toggleButton{{i}}" id="toggleButton{{i}}"
                      (click)="togglePublishButton(i)" > {{togglePublishUnpublish ?  'Publish' : 'Unpublish'}} </button> -->
                          </div>
                        </td>
                        <!-- <td> {{x.hiringcutoffdate}} </td> -->
                        <td>
                          <img (click)="toggleIndexModal()"
                            src="./assets/Images/delete.svg"
                            alt="trash"
                            class="pointer"
                          />


<!--
                          <div
                        class="modal fade" >
                        <div class="modal-dialog">
                          <div class="modal-content text-center">
                            <div class="modal-body fs20 fw500">
                              Are You Sure To Delete This Record?
                            </div>
                            <div class="modal-footer justify-content-center">
                              <button
                                type="button"
                                class="btn secondary-btn"
                                data-dismiss="modal"
                                style="
                                  border: 1px solid red !important;
                                  color: red !important;
                                "
                              >
                                No
                              </button>
                              <button
                                type="button"
                                class="btn secondary-btn"
                                (click)="dateRecordsYes(i)"
                                data-dismiss="modal"
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>


                      </div> -->
                        </td>
                      </tr>
                      <!-- Modal -->

                    </ng-container>
                  </tbody>
                </table>


                <!-- <span *ngIf="addAtleastOne" class="errorMessage mb-3"> Please add atlease one </span> -->
              </div>

              <!-- <div class="my-3 col-md-4 figma-ng-select">
              <label for="postjobto" class="form-label fw500 neugray">Post Job To<sup>*</sup></label>
              <ng-select class="custom" bindLabel="postjobto" bindValue="postjobto" placeholder="Select job"
                [ngModel]="postjobto" name="postjobtoSelect" #postjobtoSelect="ngModel" required>
              </ng-select>
              <div *ngIf="(postjobtoSelect.touched || createJobForm.submitted) &&
              postjobtoSelect.errors?.['required']">
                  <span class="errorMessage"> Please select post job to</span>
                </div>
            </div> -->

              <div class="my-3 col-md-6 col-xl-4 figma-calender">
                <label
                  class="form-label fw500 neugray d-flex position-relative"
                  style="width: max-content">
                  Hiring Process Cut-off Date <img
                  src="./assets/Images/tooltip-info-icon.svg"
                  pTooltip="This date should be the overall hiring process end date"
                  severity="secondary"
                  tooltipPosition="bottom"
                /><sup>*</sup></label
                >

                <p-calendar
                  [showIcon]="true"
                  [(ngModel)]="myHiringCutOffDate"
                  name="myHiringCutOffDateCal"
                  #myHiringCutOffDateCal="ngModel"
                  class="primecalendar"
                  placeholder="Expected joining date"
                  [required]="true"
                ></p-calendar>
                <!-- <ng-select class="custom" bindLabel="reportingManager" bindValue="reportingManager"
                placeholder="Select hiring process cut-off date"[(ngModel)]="myHiringCutOffDate" name="myHiringCutOffDateCal" #myHiringCutOffDateCal="ngModel" required>
              </ng-select> -->
                <div
                  *ngIf="(myHiringCutOffDateCal.touched || createJobForm.submitted) &&
                myHiringCutOffDateCal.errors?.['required']"
                >
                  <span class="errorMessage"> Please select date</span>
                </div>
              </div>

              <div class="my-3 col-md-6 col-xl-4 figma-calender">
                <label class="form-label fw500 neugray"
                  >Expected Joining Date<sup>*</sup></label
                >
                <p-calendar
                  [showIcon]="true"
                  [(ngModel)]="expJoiningDate"
                  name="expJoiningDateSelect"
                  #expJoiningDateSelect="ngModel"
                   [minDate]="minPublishdate"
                  class="publishstartdate"
                  placeholder="Expected joining date"
                  [required]="true"

                ></p-calendar>
                <div
                  *ngIf="(expJoiningDateSelect.touched || createJobForm.submitted) &&
                expJoiningDateSelect.errors?.['required']"
                >
                  <span class="errorMessage"> Please select joining date </span>
                </div>
              </div>
            </div>
          </div>

          <div class="recruit-documets white-background p16 br8 mb16">
            <p class="fs14 fw600 blackk">Applicant Related</p>
            <div class="line2"></div>
            <ng-container
              *ngFor="let item of requiredDocArr.requiredDocList; let e = index"
            >
              <div class="row">
                <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                  <label for="Nationality" class="form-label fw500 neugray"
                    >Document Type<sup>*</sup></label
                  >
                  <!-- (change)="getnationalityid($event)" -->

                  <ng-select
                    class="custom"
                    [items]="documentTypes"
                    bindLabel="document_type"
                    bindValue="documenttypeId"
                    placeholder="Select document type"
                    [(ngModel)]="item.documentType"
                    [disabled]="e < requiredDocArr.requiredDocList.length - 1"
                    [multiple]="true"
                    name="documentType{{ e }}"
                    #documentType="ngModel"
                    required
                  >
                  </ng-select>
                  <div
                    *ngIf="(documentType.touched || createJobForm.submitted) &&
                  documentType.errors?.['required']"
                  >
                    <span class="errorMessage">
                      Please select document type</span
                    >
                  </div>
                </div>
                <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                  <label for="Nationality" class="form-label fw500 neugray"
                    >Stage<sup>*</sup></label
                  >
                  <!-- (change)="getnationalityid($event)" -->
                  <ng-select
                    class="custom"
                    [items]="stage"
                    bindLabel="stageName"
                    bindValue="stageId"
                    placeholder="Select stage"
                    [(ngModel)]="item.stages"
                    name="stagesSelect{{ e }}"
                    #stagesSelect="ngModel"
                    required
                    [disabled]="e < requiredDocArr.requiredDocList.length - 1"
                  >
                  </ng-select>
                  <div
                    *ngIf="(stagesSelect.touched || createJobForm.submitted) &&
                  stagesSelect.errors?.['required']"
                  >
                    <span class="errorMessage"> Please select stage</span>
                  </div>
                </div>
                <div class="add-icon-main col-md-6 col-xl-4">
                  <span
                    role="button"
                    class="add__Text remove__Text"
                    (click)="deleteFieldss4(e)"
                    *ngIf="
                      requiredDocArr.requiredDocList.length === e + 1 && e != 0
                    "
                    style="color: rgba(254, 84, 84, 1) !important"
                  >
                    Remove
                  </span>
                  <button
                    role="button"
                    class="add__Text add-iconnew"
                    *ngIf="
                      e == requiredDocArr.requiredDocList.length - 1 ||
                      requiredDocArr.requiredDocList.length == 1
                    "
                    (click)="addFieldss4(e)"
                    [disabled]="!isAddButtonEnabled(item.documentType, item.stages)"
                    >
                    <!-- <img src="./assets/Images/add-icon.svg" alt="add-icon" /> -->
                    Add
                  </button>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="recruit-documets white-background p16 br8 mb16">
            <p class="d-flex align-items-center fs14 fw600 blackk">
              Applicant Eligibility Criteria
              <span
                ><img
                  src="./assets/Images/help-icon.svg"
                  style="width: 16px;margin-left: 8px;"
                  alt="help-icon"
                  pTooltip="Fields set under Eligibility Criteria will auto filter
                   applicants and lable them as 'Not Fit' & 'Fit'. It also impacts applicant's score"
                  tooltipPosition="right"
                  tooltipStyleClass="tooltip"
              /></span>
            </p>
            <div class="line2"></div>
            <div class="row position-relative figma-ng-select align-items-center" *ngFor="let item of industryObj.industries; let i = index">
              <!-- preferred industry -->
              <div class="col-md-6 col-xl-4 mb-3">
                <label class="">Preferred Industry</label>
                <ng-select class="mt-1" [items]="preferdIndsutry" name="prefferedIndustry{{ i }}"
                  placeholder="Select preferred industry" bindLabel="industry_Name" bindValue="industry_Id"
                  [(ngModel)]="item.prefferedIndustry" #prefferedIndustry="ngModel" [disabled]="i < industryObj.industries.length - 1">
                </ng-select>

                <div *ngIf="
                    (prefferedIndustry.touched || createJobForm.submitted) &&
                    prefferedIndustry.errors?.['required']
                  ">
                  <span class="errorMessage">
                    Please select preferred industry
                  </span>
                </div>
              </div>
            <div class="col-md-6 col-xl-4 mb-3">
                <label class="">Preferred Ind Exp (In Years)</label>
                <div class="d-flex">
                  <div class="col-md-6 px-0">
                    <ng-select [items]="GetIndustryExperience" bindLabel="experience_Name" bindValue="experience_Name"
                      [(ngModel)]="item.prefferedIndustryExperienceFrom" (change)="changeFn($event,item)" name="industryExpP{{ i }}"
                      #industryExpP="ngModel" [disabled]="i < industryObj.industries.length - 1">
                    </ng-select>

                  </div>
                  <!-- <span class="form-label-d mx-md-3 mobile__To_Text">
                    To
                  </span> -->
                  <div class="col-md-6 pl-2 pr-0">
                    <ng-select [items]="GetIndustryExperiencetoo" bindLabel="experience_Name" bindValue="experience_Name"
                      [(ngModel)]="item.prefferedIndustryExperienceTo" name="industryExp{{ i }}"
                      #industryExp="ngModel" style="width: 96% !important" [disabled]="i < industryObj.industries.length - 1">

                    </ng-select>
                  </div>
                  <div class="d-flex">
                    <div *ngIf="
                        (industryExp.touched || createJobForm.submitted) &&
                        industryExp.errors?.['required']
                      ">
                      <span class="errorMessage">
                        Please select preferred industry experience
                      </span>
                    </div>

                  </div>
                </div>
              </div>

              <div class="add-icon-main col-md-6 col-xl-4">
                <span role="button" class="add__Text remove__Text" style="color: rgba(254, 84, 84, 1) !important;"
                (click)="deleteFieldss(i)" *ngIf="( industryObj.industries.length === ( i+1)) &&  (i !=0 )">
                Remove </span>
                <button role="button" class="add__Text add-iconnew"
                  *ngIf="i == industryObj.industries.length - 1 || industryObj.industries.length == 1"
                  (click)="addindustry(i)">
                  <!-- [disabled]="!(item.prefferedIndustry > 1 && item.prefferedIndustryExperienceFrom > 1 && item.prefferedIndustryExperienceTo > 1)" -->
                  Add More Industries </button>
                  <!-- <img src="../../../assets/Images/add-icon.svg" alt="add" /> -->
              </div>
            </div>
            <!-- <form [formGroup]="componentsForm">
              <div formArrayName="components">
                <div *ngFor="let control of components.controls; let i = index">
                  <div [formGroupName]="i" class="row align-items-baseline mt-2">
                    <fieldset class="col-sm-6 col-md-4 col-lg-4 my-select">
                      <legend>
                        Select Component <span class="error">*</span>
                      </legend>
                      <p-dropdown
                        [options]="brands"
                        formControlName="jobRemunerationComponent"
                        optionLabel="name"
                        [showClear]="true"
                        placeholder="Select component"
                      ></p-dropdown>
                    </fieldset>

                    <fieldset class="col-sm-6 col-md-4 col-lg-4 my-input">
                      <div class="row">
                        <div class="col-6">
                          <legend>
                            Percentage(%) <span class="error">*</span>
                          </legend>
                          <p-inputNumber
                            formControlName="jobRemunerationPercentage"
                            inputId="minmax"
                            mode="decimal"
                            inputId="mile"
                            suffix="%"
                            [maxlength]="2"
                          ></p-inputNumber>
                        </div>
                        <div class="col-6">
                          <legend>Value</legend>
                          <input
                            placeholder="Value"
                            class="w-100 dependent"
                            type="text"
                            formControlName="jobRemunerationValue"
                            readonly
                          />
                        </div>
                      </div>
                    </fieldset>

                    <div class="col-sm-6 col-md-4 col-lg-4 d-flex column-gap-3">
                      <p-button
                        icon="pi pi-trash"
                        (click)="removeComponent(i)"
                        [rounded]="true"
                        [text]="true"
                        [raised]="true"
                        severity="danger"
                      ></p-button>
                      <p-button
                        icon="pi pi-plus"
                        (click)="addComponent()"
                        [rounded]="true"
                        [text]="true"
                        [raised]="true"
                      ></p-button>
                    </div>
                  </div>
                </div>
              </div>
            </form> -->
            <div class="Industry-section">
              <ng-container
                *ngFor="
                  let item of domainObj.ijpdomain;
                  let a = index
                "
              >
                <div class="row">
                  <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                    <label for="Nationality" class="form-label fw500 neugray"
                      >Preferred Domain</label
                    >
                    <!-- (change)="getnationalityid($event)" -->
                    <ng-select
                      class="custom"
                      [items]="preferdDomain"
                      bindLabel="domain_Name"
                      bindValue="domain_Id"
                      placeholder="Select preferred domain"
                      [(ngModel)]="item.preferedDomain"
                      name="preferedDomainSelect{{ a }}"
                      #preferedDomainSelect="ngModel"
                      [disabled]="a < domainObj.ijpdomain.length - 1"
                    >
                    </ng-select>
                    <div
                      *ngIf="(preferedDomainSelect.touched || createJobForm.submitted) &&
                      preferedDomainSelect.errors?.['required']"
                    >
                      <span class="errorMessage">
                        Please select preferred domain</span
                      >
                    </div>
                  </div>
                  <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                    <label for="Nationality" class="form-label fw500 neugray"
                      >Domain Experience (In Years)</label
                    >
                    <!-- (change)="getnationalityid($event)" -->
                    <div class="d-flex">
                      <div class="col-md-6 px-0">
                        <ng-select (change)="getDomainExp($event,item)"
                          class="custom"
                          [items]="GetDomainExperience"
                          bindLabel="experience_Name"
                          [(ngModel)]="item.domainExperienceFrom"
                          name="fromDomainInput{{ a }}"
                          #fromDomainInput="ngModel"
                          bindValue="experience_Name"
                          [disabled]="a < domainObj.ijpdomain.length - 1"
                        >
                        </ng-select>
                      </div>
                      <div class="col-md-6 pl-2 pr-0">
                        <ng-select
                          class="custom"
                          [items]="GetDomainExperience2"
                          bindLabel="experience_Name"
                          [(ngModel)]="item.domainExperienceTo"
                          name="toDomainInput{{ a }}"
                          #toDomainInput="ngModel"
                          bindValue="experience_Name"
                          [disabled]="a < domainObj.ijpdomain.length - 1"
                        >
                        </ng-select>
                      </div>
                    </div>

                    <!-- (change)="getnationalityid($event)" -->
                    <!-- <ng-select class="custom" bindLabel="nationality_Name" [(ngModel)]="item.preferedFromDomainExperience" name="preferedDomainExperience{{a}}" #preferedFromDomainExperience="ngModel" bindValue="nationality_Name"
                          placeholder="Select">
                        </ng-select> -->

                    <!-- <input type="text" class="form-control" id="numberId" [(ngModel)]="item.preferedFromDomain" name="fromDomainInput{{a}}" #preferedFromDomain="ngModel" placeholder="from" />
                        <input type="text" class="form-control" id="numberId1" [(ngModel)]="item.preferedToDomain" name="toDomainInput{{a}}" #preferedToDomain="ngModel" placeholder="to" /> -->
                    <!-- <div
                      *ngIf="(toDomainInput.touched || createJobForm.submitted) &&
                        toDomainInput.errors?.['required']"
                    >
                      <span class="errorMessage">
                        Please select domain experience</span
                      >
                    </div> -->
                  </div>
                  <div class="add-icon-main col-md-6 col-xl-4">
                    <span
                      role="button"
                      class="add__Text remove__Text"
                      (click)="deleteFieldss1(a)"
                      *ngIf="
                        domainObj.ijpdomain.length === a + 1 &&
                        a != 0
                      "
                      style="color: rgba(254, 84, 84, 1) !important"
                    >
                      Remove
                    </span>
                    <button
                      role="button"
                      class="add__Text add-iconnew"
                      *ngIf="
                        a == domainObj.ijpdomain.length - 1 ||
                        domainObj.ijpdomain.length == 1
                      "
                      (click)="addFieldss1(a)"
                      >
                      <!-- [disabled]="!(item.preferedDomain && item.preferedDomain > 1 && item.domainExperienceFrom > 1 && item.domainExperienceTo > 1)" -->
                      <!-- <img src="./assets/Images/add-icon.svg" alt="add-icon" /> -->
                      Add More domain
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>

            <!-- <ng-container *ngFor="let item of totalExperienceArr.totalExperienceItems; let f = index"> -->
            <div class="row mt-2">
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray"
                  >Total Experience (In Years) <sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <div class="d-flex">
                  <div class="col-md-6 px-0">
                    <ng-select (change)="getTotalExp($event)"
                      class="custom"
                      [items]="GetEmployeeDuration"
                      bindLabel="experience_Name"
                      bindValue="experience_Name"
                      [(ngModel)]="totalExperienceSelect"
                      name="totalExperienceSelect1"
                      #totalExperienceSelect1="ngModel"
                      required
                    >
                    </ng-select>
                    <div
                    *ngIf="(totalExperienceSelect1.touched || createJobForm.submitted) &&
                    totalExperienceSelect1.errors?.['required']"
                  >
                    <span class="errorMessage">
                      Please select total experience</span
                    >
                  </div>
                  </div>
                  <div class="col-md-6 pl-2 pr-0">
                    <ng-select
                      class="custom"
                      [items]="GetEmployeeDuration2"
                      bindLabel="experience_Name"
                      bindValue="experience_Name"
                      [(ngModel)]="totalExperienceSelect2"
                      name="totalExperienceSelecttt"
                      #totalExperienceSelecttt="ngModel"
                      required
                    >
                    </ng-select>
                    <div
                    *ngIf="(totalExperienceSelecttt.touched || createJobForm.submitted) &&
                    totalExperienceSelecttt.errors?.['required']"
                  >
                    <span class="errorMessage">
                      Please select total experience</span
                    >
                  </div>
                  </div>
                </div>
                <!-- <div
                  *ngIf="(totalExperienceSelect1.touched || createJobForm.submitted) &&
                        totalExperienceSelect1.errors?.['required']"
                >
                  <span class="errorMessage"> Please select experience</span>
                </div> -->
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray"
                  >Current Salary Range (Annual)</label
                >
                <!-- (change)="getnationalityid($event)" -->
                <div class="d-flex gap-2">
                  <!-- (change)="getnationalityid($event)" -->
                  <ng-select
                    class="custom col-md-4 px-0"
                    [items]="Currentcurrencycodess"
                    bindLabel="currencyCode"
                    [(ngModel)]="totalExperience"
                    name="totalExperience1"
                    #totalExperience1="ngModel"
                    bindValue="currencyCode"
                    placeholder="Select"
                  >
                  </ng-select>
                  <!-- <input type="text" class="form-control"  [(ngModel)]="currentSalaryCode" readonly /> -->
                  <input (keyup)="getFromTo()"
                    type="text"
                    class="form-control ml-2"
                    [(ngModel)]="currentSalaryCodefrom"
                    name="fromcurrentSalaryCodeInput"
                    #fromTotalExpInput="ngModel"
                    oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    placeholder="from"
                  />
                  <input
                    type="text"
                    class="form-control ml-2 pr-0"
                    [(ngModel)]="currentSalaryCodeto"
                    name="toTotalExpInput"
                    #toTotalExpInput="ngModel"
                    oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    placeholder="to"
                    (keyup)="currentSalaryRangeLogic($event)"
                  />
                  <!-- <div *ngIf="(toDomainInput.touched || createJobForm.submitted) && toDomainInput.errors?.['required']">
                            <span class="errorMessage"> Please select domain experience</span>
                          </div> -->
                </div>
                <div class="errorMessage mt-1" *ngIf="showErrorCurrentSalary"
                ng-show="currentSalaryCodefrom.$error.min">Value should be greater than
                {{ currentSalaryCodefrom }}.</div>
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select leftt-tooltipp">
                <label for="Nationality" class="form-label fw500 neugray">
                  Salary Offer Range (Annual) <sup>*</sup>
                  <img
                  src="./assets/Images/tooltip-info-icon.svg"
                  tooltipPosition="bottom"
                  pTooltip="If you select Singapore Position, then you have to select INR or USD only."

                  />
                </label>
                <div class="">
                  <!-- <input type="text" class="form-control" [(ngModel)]="salaryofferRangeCode" readonly /> -->
                  <!-- <div class="col-md-4 pe-1"> -->
                    <div class="d-flex gap-2">
                      <ng-select
                        class="custom col-md-4 px-0"
                        [items]="currencycodess"
                        bindLabel="currencyCode"
                        bindValue="currencyCode"
                        placeholder="Select"
                        [(ngModel)]="salaryofferRangeCode"
                        name="salaryofferRangeCodeee"
                        #salaryofferRangeCodeee="ngModel"></ng-select
                      >
                      <!-- </div> -->
                      <!-- <div class="col-md-4 ps-1"> -->
                      <input (keyup)="getFromToo()"
                        type="text"
                        class="form-control ml-2"
                        [(ngModel)]="salaryofferRangeCodefrom"
                        name="fromsalaryofferRangeCodeInput"
                        placeholder="from"
                        #fromsalaryofferRangeCodeInput="ngModel"
                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                        onkeypress="return /[0-9]/i.test(event.key)"
                        required
                      />
                      <!-- </div> -->
                      <!-- <div class="col-md-4 ps-1"> -->
                      <input (keyup)="getFromToo()"
                        type="text"
                        class="form-control ml-2"
                        [(ngModel)]="salaryofferRangeCodeto"
                        name="tosalaryofferRangeCodeInput"
                        placeholder="to"
                        #tosalaryofferRangeCodeInput="ngModel"
                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                        onkeypress="return /[0-9]/i.test(event.key)"
                        required
                      />
                    </div>

                <div
                  class="my-1"
                  *ngIf="(fromsalaryofferRangeCodeInput.touched || createJobForm.submitted)
                          && fromsalaryofferRangeCodeInput.errors?.['required']"
                >
                  <span class="errorMessage">
                    Please enter expected salary range</span
                  >
                </div>
                <div class="errorMessage" *ngIf="showErrorRangeSalary"
                ng-show="salaryofferRangeCodefrom.$error.min">Value should be greater than
                {{ salaryofferRangeCodefrom }}.</div>
                <!-- </div> -->
              </div>
                <div
                  class="d-flex gap-2 mt-2 align-items-center display-to-applicants"
                >
                  <p class="mr-1">Display to Applicants</p>
                  <input type="checkbox" [(ngModel)]="displayToApplicants" [checked]="displayToApplicants" (change)="onCheckboxChange($event)" />

                </div>
              </div>

              <!-- <div class="add-icon-main col-md-4">
                        <span role="button" class="add__Text remove__Text" (click)="deleteFieldss3(f)"
                        *ngIf="(totalExperienceArr.totalExperienceItems.length === ( f+1)) &&  (f !=0 )"
                        style="color:rgba(254, 84, 84, 1) !important">
                        Remove
                      </span>
                      <span role="button" class="add__Text add-iconnew" *ngIf="
                      f == totalExperienceArr.totalExperienceItems.length - 1 ||
                      totalExperienceArr.totalExperienceItems.length == 1
                    " (click)="addFieldss3(f)">
                    <img src="./assets/Images/add-icon.svg" alt="add-icon">

                        Add
                      </span>
                      </div> -->
            </div>
            <!-- </ng-container> -->

            <div class="row mt-2">
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray">
                  Higher Level of Education</label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="higherLevelEducation"
                  bindLabel="educationName"
                  bindValue="educationId"
                  placeholder="Select education"
                  [(ngModel)]="higherLevelEdu"
                  name="higherLevelEduSelect"
                  #higherLevelEduSelect="ngModel"
                >
                </ng-select>
                <div
                  *ngIf="(higherLevelEduSelect.touched || createJobForm.submitted) &&
                higherLevelEduSelect.errors?.['required']"
                >
                  <span class="errorMessage"> Please select education</span>
                </div>
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray">
                  Qualifications</label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="qualification"
                  bindLabel="degreeName"
                  bindValue="degreeId"
                  [multiple]="true"
                  placeholder="Select qualifications"
                  [(ngModel)]="qualifications"
                  name="qualificationsSelect"
                  #qualificationsSelect="ngModel"
                >
                </ng-select>
                <div
                  *ngIf="(qualificationsSelect.touched || createJobForm.submitted) &&
                qualificationsSelect.errors?.['required']"
                >
                  <span class="errorMessage">
                    Please select qualifications</span
                  >
                </div>
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray">
                  Languages Known</label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [multiple]="true"
                  [items]="languageKnown"
                  bindLabel="languageName"
                  bindValue="languageId"
                  placeholder="Select languages known"
                  [(ngModel)]="Knownnlanguages"
                  name="languagesKnown"
                  #languagesKnown="ngModel"
                >
                </ng-select>
                <div
                  *ngIf="(languagesKnown.touched || createJobForm.submitted) &&
                languagesKnown.errors?.['required']"
                >
                  <span class="errorMessage"> Please select languages</span>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray">
                  Nationality</label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="nationalities"
                  bindLabel="nationality_Name"
                  bindValue="nationality_Id"
                  placeholder="Select nationality"
                  [(ngModel)]="myNationality"
                  name="nationalityid"
                  #nationalityid="ngModel"
                >
                </ng-select>
                <div
                  *ngIf="(nationalityid.touched || createJobForm.submitted) &&
                nationalityid.errors?.['required']"
                >
                  <span class="errorMessage"> Please select nationality</span>
                </div>
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray"
                  >Preferred Gender</label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="GetGender"
                  bindLabel="nationality_Name"
                  bindValue="nationality_Name"
                  placeholder="Select gender"
                  [(ngModel)]="gender"
                  [multiple]="true"
                  name="genderSelect"
                  #genderSelect="ngModel"
                >
                </ng-select>
                <div
                  *ngIf="(genderSelect.touched || createJobForm.submitted) &&
                genderSelect.errors?.['required']"
                >
                  <span class="errorMessage"> Please select gender</span>
                </div>
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label class="form-label fw500 neugray"
                  >Flexible to Relocate</label
                >

                <ng-select
                  class="custom"
                  [items]="GetFlexibleRelocate"
                  bindLabel="nationality_Name"
                  bindValue="nationality_Name"
                  placeholder="Select flexible to relocate"
                  [(ngModel)]="relocateFlexible"
                  name="relocateFlexiblee"
                  #relocateFlexiblee="ngModel"
                >
                </ng-select>
                <div
                  *ngIf="(relocateFlexiblee.touched || createJobForm.submitted) &&
                relocateFlexiblee.errors?.['required']"
                >
                  <span class="errorMessage"> Please select</span>
                </div>
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray"
                  >Notice period (In Days)<sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <!-- <ng-select class="custom" bindLabel="nationality_Name" bindValue="nationality_Id"
                  placeholder="Select notice period" [(ngModel)]="noticePeriod" name="noticePeriodSelect" #noticePeriodSelect="ngModel" required>
                </ng-select> -->
                <div class="d-flex">
                  <div class="col-md-6 px-0">
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="noticePeriodSelect"
                      name="noticePeriodSelectt"
                      #noticePeriodSelectt="ngModel"
                      min="1"
                      [maxlength]="3"
                      (keyup)="getFromTo3()"
                      oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                      onkeypress="return /[0-9]/i.test(event.key)"
                      required
                    />
                    <div
                  *ngIf="(noticePeriodSelectt.touched || createJobForm.submitted) &&
                  noticePeriodSelectt.errors?.['required']"
                >
                  <span class="errorMessage"> Please select notice period</span>
                </div>
                  </div>
                  <div class="col-md-6 pl-2 pr-0">
                    <input
                      type="text"
                      min="1"
                      [maxlength]="3"
                      class="form-control"
                      [(ngModel)]="noticePeriodSelectttt"
                      name="noticePeriodSelecttt"
                      #noticePeriodSelecttt="ngModel"
                      (keyup)="getFromTo3()"
                      oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                      onkeypress="return /[0-9]/i.test(event.key)"
                      required
                    />
                    <div
                    *ngIf="(noticePeriodSelecttt.touched || createJobForm.submitted) &&
                    noticePeriodSelecttt.errors?.['required']"
                  >
                    <span class="errorMessage"> Please select notice period</span>
                  </div>
                  <div *ngIf="showErrorNoticeRange" class="errorMessage">Must be greater than {{noticePeriodSelect}}</div>

                  </div>
                </div>
                <!-- <div
                  *ngIf="(noticePeriodSelectt.touched || createJobForm.submitted) &&
                noticePeriodSelectt.errors?.['required']"
                >
                  <span class="errorMessage"> Please enter notice period</span>
                </div> -->
              </div>

              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray">
                  Minimum Age Bar (Above or equal to 18 yrs)</label
                >
                <div class="d-flex">
                  <div class="col-md-6 px-0">
                    <input
                      type="number"
                      class="form-control"
                      [(ngModel)]="preferredAgeSelect"
                      name="preferredAgeSelectt"
                      #preferredAgeSelectt="ngModel"
                      oninput="if(!this.value.match('^[1-9][0-9]*$')) this.value=''; if(this.value.length > 2) this.value = this.value.slice(0, 2);"
                      onkeypress="return /[0-9]/i.test(event.key)"
                      min="18"
                      [maxlength]="2"
                      (keyup)="getFromTo2()"
                    />
                    <div *ngIf="preferredAgeSelectt.invalid && (preferredAgeSelectt.dirty || preferredAgeSelectt.touched)">
                      <div *ngIf="preferredAgeSelectt.errors?.['min']" class="errorMessage">Minimum age should be 18 or above.</div>
                    </div>
                  </div>
                  <div class="col-md-6 pl-2 pr-0">
                    <input
                      type="number"
                      class="form-control"
                      [(ngModel)]="preferredAgeSelecttWo"
                      name="preferredAgeSelectttt"
                      #preferredAgeSelectttt="ngModel"
                      oninput="if(!this.value.match('^[1-9][0-9]*$')) this.value=''; if(this.value.length > 2) this.value = this.value.slice(0, 2);"
                      onkeypress="return /[0-9]/i.test(event.key)"
                      min="18"
                      [maxlength]="2"
                      (keyup)="getFromTo2()"
                    />
                    <div *ngIf="preferredAgeSelectttt.invalid && (preferredAgeSelectttt.dirty || preferredAgeSelectttt.touched)">
                      <!-- <div *ngIf="preferredAgeSelectttt.errors?.['min']" class="errorMessage">Minimum age should be 18 or above.</div> -->
                    </div>
                    <div *ngIf="showErrorAgeRange" class="errorMessage">Age must be greater than or equal to 18</div>
                  </div>
                </div>


                <!-- <ng-select class="custom" bindLabel="nationality_Name" bindValue="nationality_Id"
                  placeholder="Select preferred age" [(ngModel)]="preferredAge" name="preferredAgeSelect" #preferredAgeSelect="ngModel" required>
                </ng-select> -->
                <!-- <div
                  *ngIf="(preferredAgeSelectt.touched || createJobForm.submitted) &&
                preferredAgeSelectt.errors?.['required']"
                >
                  <span class="errorMessage"> Please enter age</span>
                </div> -->
              </div>
            </div>

            <div class="row mt-2">
              <!-- <div class="mb-3 col-md-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray">Time needed to relocate ( in Days ) <sup>*</sup></label>
                <ng-select class="custom" bindLabel="nationality_Name" bindValue="nationality_Id"
                  placeholder="Select time needed to relocate" [(ngModel)]="timeToRelocate" name="timeToRelocateSelect" #timeToRelocateSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(timeToRelocateSelect.touched || createJobForm.submitted) &&
                timeToRelocateSelect.errors?.['required']">
                  <span class="errorMessage"> Please select time needed to relocate</span>
                </div>
              </div> -->
            </div>
          </div>

          <!-- <div class="emplyee-related-section white-background p16 br8 mb16">
            <p class="fw600 fs16 blackk"> Employment Related </p>
            <div class="line2"></div>
            <div>
              <div class="row"> -->

          <!-- <div class="mb-3 col-md-4 figma-ng-select">
                  <label for="selectemployee" class="form-label fw500 neugray">Select Employee<sup>*</sup></label>

                  <ng-select class="custom" bindLabel="name" bindValue="id"
                    placeholder="Select employee" [(ngModel)]="selectemployee" name="selectemployeeSelect" #selectemployeeSelect="ngModel" required>
                  </ng-select>
                  <div *ngIf="(selectemployeeSelect.touched || createJobForm.submitted) &&
                  selectemployeeSelect.errors?.['required']">
                      <span class="errorMessage"> Please select employee </span>
                    </div>
                </div> -->
          <!-- <div class="mb-3 col-md-4" style="margin-top: 35px;">
                  <div (click)="addEmployeeToTable(typeofempInput?.value)"  class="add-icon-main">
                    <img src="./assets/Images/add-icon.svg" alt="add-icon">
                    <span class="add-iconnew">Add</span>
                  </div>
                </div> -->

          <!-- </div>
              <div class="row">
                <div class="mb-3 col-md-4 figma-calender">
                  <label for="Nationality" class="form-label fw500 neugray">Tentative Job Start Date<sup>*</sup></label> -->
          <!-- <p-calendar [showIcon]="true" [(ngModel)]="perdateofbirth" name="perdateofbirthCal" #perdateofbirthCal="ngModel" [required]="true" class="primecalendar"
                    placeholder="Expected joining date" ></p-calendar> -->
          <!-- <ng-select class="custom" bindLabel="jobStartDate" bindValue="jobStartDate"
                    placeholder="Select job start date" [(ngModel)]="perdateofbirth" name="perdateofbirthCal" #perdateofbirthCal="ngModel" required> -->
          <!-- </ng-select> -->
          <!-- <p-calendar [showIcon]="true" [(ngModel)]="tentativeJobStartDate" name="tentativeJobStartDateSelect" #tentativeJobStartDateSelect="ngModel" [required]="true" class="publishstartdate"
                    placeholder="Publish joining date"></p-calendar>
                    <div *ngIf="(tentativeJobStartDateSelect.touched || createJobForm.submitted) &&
                    tentativeJobStartDateSelect.errors?.['required']">
                        <span class="errorMessage"> Please select job start date </span>
                      </div>
                </div>





              </div>
              <div class="row">
                <div class="mb-3 col-md-4 figma-calender">
                  <label for="publishstartdate" class="form-label fw500 neugray">Publish Start Date<sup>*</sup></label>
                  <p-calendar [showIcon]="true" [(ngModel)]="publishstartdate" name="publishstartdateSelect" #publishstartdateSelect="ngModel" [required]="true" class="publishstartdate"
                    placeholder="Publish joining date"></p-calendar>
                    <div *ngIf="(publishstartdateSelect.touched || createJobForm.submitted) &&
                    publishstartdateSelect.errors?.['required']">
                      <span class="errorMessage"> Please select publish start date </span>
                    </div>
                </div>
                <div class="mb-3 col-md-4 figma-calender">
                  <label for="publisheddate" class="form-label fw500 neugray">Publish End Date<sup>*</sup></label>
                  <p-calendar [showIcon]="true" [(ngModel)]="publisheddate" name="publisheddateSelect" #publisheddateSelect="ngModel" [required]="true" class="primecalendar"
                    placeholder="Publish end date"></p-calendar>
                    <div *ngIf="(publisheddateSelect.touched || createJobForm.submitted) &&
                    publisheddateSelect.errors?.['required']">
                      <span class="errorMessage"> Please select publish end date </span>
                    </div>
                </div>


              </div>
            </div> -->

          <!-- <div class="row mt-3 align-items-end">
              <div class="col-md-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray">Minimum Offer Salary Range( Monthly)<sup>*</sup></label>

                <div class="mb-3 d-flex gap-2">
                  <ng-select class="custom" bindLabel="nationality_Name" bindValue="nationality_Id" placeholder="INR"
                    [(ngModel)]="currency" name="currencySelect" #currencySelect="ngModel" required>
                  </ng-select>

                  <input type="text" class="form-control" [(ngModel)]="fromsalry" name="salarySelect" #salarySelect="ngModel" id="fromsalry" placeholder="from" />
                  <input type="text" class="form-control" [(ngModel)]="tosalery" name="salarySelect" #salarySelect="ngModel" id="tosalery" placeholder="to" />
                  <div *ngIf="(salarySelect.touched || createJobForm.submitted) &&
                  salarySelect.errors?.['required']">
                      <span class="errorMessage"> Please select salary </span>
                    </div>
                </div>
              </div>
              <div class="col-md-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray">Maximum Offer Salary Range( Monthly)<sup>*</sup></label>

                <div class="mb-3 figma-ng-select d-flex gap-2"> -->
          <!-- <ng-select class="custom" bindLabel="nationality_Name" bindValue="nationality_Id" placeholder="INR"
                    [(ngModel)]="currency" name="currencySelect" #currencySelect="ngModel" required>
                  </ng-select>

                  <input type="text" class="form-control" [(ngModel)]="fromsalry" name="salarySelect" #salarySelect="ngModel" id="fromsalry" placeholder="from" />
                  <input type="text" class="form-control" [(ngModel)]="tosalery" name="salarySelect" #salarySelect="ngModel" id="tosalery" placeholder="to" />
                  <div *ngIf="(salarySelect.touched || createJobForm.submitted) &&
                  salarySelect.errors?.['required']">
                      <span class="errorMessage"> Please select salary </span>
                    </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3 create-job-select figma-ng-select pb-3" style="display: flex;align-items: center;">

                  <input type="checkbox" class="custom" style="width: 14px; height: 14px; border-radius: 5px;
                   border: 1.5px" [(ngModel)]="typaofappointcheckbox" name="typaofappointcheck">
                  <span for="Nationality" class="fs12 fw500 neugray" style="margin-left: 10px;">Type of
                    Appointment</span>

                </div>
              </div>
            </div> -->

          <!-- <div class="row">

            </div>

            <div class="row">

              <div class="mb-3 col-md-4 figma-calender">
                <label for="publishstartdate" class="form-label fw500 neugray">Publish Start Date<sup>*</sup></label>
                <p-calendar [showIcon]="true" [(ngModel)]="publishstartdate" name="publishstartdateSelect" #publishstartdateSelect="ngModel" [required]="true" class="publishstartdate"
                  placeholder="Publish joining date"></p-calendar>
                  <div *ngIf="(publishstartdateSelect.touched || createJobForm.submitted) &&
                  publishstartdateSelect.errors?.['required']">
                    <span class="errorMessage"> Please select publish start date </span>
                  </div>
              </div>
              <div class="mb-3 col-md-4 figma-calender">
                <label for="publisheddate" class="form-label fw500 neugray">Publish End Date<sup>*</sup></label>
                <p-calendar [showIcon]="true" [(ngModel)]="publisheddate" name="publisheddateSelect" #publisheddateSelect="ngModel" [required]="true" class="primecalendar"
                  placeholder="Publish end date"></p-calendar>
                  <div *ngIf="(publisheddateSelect.touched || createJobForm.submitted) &&
                  publisheddateSelect.errors?.['required']">
                    <span class="errorMessage"> Please select publish end date </span>
                  </div>
              </div>
              <div class="mb-3 col-md-4 figma-calender">
                <label for="hiringcuttofdate" class="form-label fw500 neugray">Hiring Process Cut-off Date<sup>*</sup></label>
                <p-calendar [showIcon]="true" [(ngModel)]="hiringcuttofdate" name="hiringcuttofdateSelect" #hiringcuttofdateSelect="ngModel" [required]="true" class="primecalendar"
                  placeholder="Hiring process date"></p-calendar>
                  <div *ngIf="(hiringcuttofdateSelect.touched || createJobForm.submitted) &&
                  hiringcuttofdateSelect.errors?.['required']">
                    <span class="errorMessage"> Please select hiring process cut-off date </span>
                  </div>
              </div>

            </div> -->
          <!-- </div> -->

          <div class="hiring-spoc white-background p16 br8 mb16">
            <p class="fs14 fw600">Hiring SPOC’s</p>
            <div class="line2"></div>

            <div class="row">
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="hiringmanager" class="form-label fw500 neugray"
                  >Hiring Manager<sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="hiringManager"
                  bindLabel="userName"
                  bindValue="userId"
                  placeholder="Select hiring manager"
                  [(ngModel)]="hiringmanager"
                  name="hiringmanagerSelect"
                  #hiringmanagerSelect="ngModel"
                  required
                >
                </ng-select>
                <div
                  *ngIf="(hiringmanagerSelect.touched || createJobForm.submitted) &&
                hiringmanagerSelect.errors?.['required']"
                >
                  <span class="errorMessage">
                    Please select hiring manager
                  </span>
                </div>
              </div>
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="requitermanager" class="form-label fw500 neugray"
                  >Recruitment Manager<sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="recruitmentManager"
                  bindLabel="userName"
                  bindValue="userId"
                  placeholder="Select recruitment manager"
                  [(ngModel)]="requitermanager"
                  name="requitermanagerSelect"
                  #requitermanagerSelect="ngModel"
                  required
                >
                </ng-select>
                <div
                  *ngIf="(requitermanagerSelect.touched || createJobForm.submitted) &&
                requitermanagerSelect.errors?.['required']"
                >
                  <span class="errorMessage">
                    Please select recruitment manager
                  </span>
                </div>
              </div>
              <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                <label for="businesspartner" class="form-label fw500 neugray"
                  > HR Business Partner<sup>*</sup></label
                >
                <!-- (change)="getnationalityid($event)" -->
                <ng-select
                  class="custom"
                  [items]="businessPartner"
                  bindLabel="userName"
                  bindValue="userId"
                  placeholder="Select business partner"
                  [(ngModel)]="businesspartner"
                  name="businesspartnerSelect"
                  #businesspartnerSelect="ngModel"
                  required
                >
                </ng-select>
                <div
                  *ngIf="(businesspartnerSelect.touched || createJobForm.submitted) &&
                businesspartnerSelect.errors?.['required']"
                >
                  <span class="errorMessage">
                    Please select business partner
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="hiring-spoc white-background p16 br8 mb16">
            <h1 class="fs14 fw600 blackk">Work Permit Related</h1>
            <div class="line"></div>
            <div>
              <!-- <ng-container *ngFor="let item of workPermitArr.workPermitRelated; let b = index"> -->
              <div class="row">
                <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                  <label for="Nationality" class="form-label fw500 neugray"
                    >Work Permit<sup>*</sup></label
                  >
                  <!-- (change)="getnationalityid($event)" -->
                  <ng-select
                    class="custom"
                    [items]="workPermits"
                    bindLabel="nationality_Name"
                    bindValue="nationality_Id"
                    (change)="workPermitChange($event)"
                    placeholder="Select work permit"
                    [(ngModel)]="workPermit"
                    name="workPermitSelect"
                    #workPermitSelect="ngModel"
                    required
                  >
                  </ng-select>
                  <div
                    *ngIf="(workPermitSelect.touched || createJobForm.submitted) &&
                      workPermitSelect.errors?.['required']"
                  >
                    <span class="errorMessage"> Please select work permit</span>
                  </div>
                </div>
                <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                  <label for="Nationality" class="form-label fw500 neugray"
                    >Country<sup *ngIf="hideHash">*</sup></label
                  >
                  <!-- (change)="getnationalityid($event)" -->
                  <ng-select
                    class="custom"
                    [items]="candidateCountryList"
                    bindLabel="countryName"
                    bindValue="countryId"
                    [disabled]="workPermitDisabled"
                    (change)="GetCountry(workCountry)"
                    placeholder="Select country"
                    [(ngModel)]="workCountry"
                    name="workCountrySelect"
                    #workCountrySelect="ngModel"
                    required
                  >
                  </ng-select>
                  <div
                    *ngIf="(workCountrySelect.touched || createJobForm.submitted) &&
                      workCountrySelect.errors?.['required']"
                  >
                    <span class="errorMessage"> Please select Country</span>
                  </div>
                </div>
                <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
                  <label for="Nationality" class="form-label fw500 neugray"
                    >Work Permit Type<sup *ngIf="hideHash">*</sup></label
                  >
                  <!-- (change)="getnationalityid($event)" -->
                  <ng-select
                    class="custom"
                    [items]="getcountriesss"
                    bindLabel="workPermit_Name"
                    bindValue="workPermit_Id"
                    [disabled]="workPermitDisabled"
                    placeholder="Select work permit type"
                    [(ngModel)]="workPermitType"
                    name="workPermitTypeSelecT"
                    #workPermitTypeSelect="ngModel"
                    required
                  >
                  </ng-select>
                  <div
                    *ngIf="(workPermitTypeSelect.touched || createJobForm.submitted) &&
                      workPermitTypeSelect.errors?.['required']"
                  >
                    <span class="errorMessage">
                      Please select work permit type</span
                    >
                  </div>
                </div>
              </div>

              <!-- <div class="add-icon-main">
                    <span role="button" class="add__Text remove__Text" (click)="deleteFieldss2(b)"
                  *ngIf="(workPermitArr.workPermitRelated.length === ( b+1)) &&  (b !=0 )"
                  style="color:rgba(254, 84, 84, 1) !important">
                  Remove
                </span>
                <span role="button" class="add__Text add-iconnew " *ngIf="
                b == workPermitArr.workPermitRelated.length - 1 ||
                workPermitArr.workPermitRelated.length == 1
              " (click)="addFieldss2(b)">
              <img  src="./assets/Images/add-icon.svg" alt="add-icon">
                  Add
                </span>
                  </div> -->
              <!-- </ng-container> -->
            </div>
          </div>

          <div class="step-navigation mb16 p16 br8" >
            <button type="submit" class="blue-btn" >Next</button>
            <!-- [disabled]="showErrorCurrentSalary || !createJobForm.form.valid || !addjobsDatesArray.length" -->
          </div>
        </form>
      </div>

      <div *ngIf="activeStepIndex === 1">
        <form
          novalidate
          #createJobDescriptionForm="ngForm"
          (ngSubmit)="

            nextStep('second')
          "
        >
          <div class="white-background p16 br8 mb16">
            <h1 class="fw600 fs16">Overview <sup>*</sup></h1>
            <div class="line2"></div>

            <div class="editor">
              <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
              <p-editor
                [(ngModel)]="textControl1"
                name="editor1"
                #editor1="ngModel"
                required
                [style]="{ height: '100px' }"
              ></p-editor>
              <div
              *ngIf="(editor1.touched || createJobDescriptionForm.submitted) &&
              editor1.errors?.['required']"
            >
              <span class="errorMessage">
                Please enter text
              </span>
            </div>
            </div>
          </div>

          <div class="white-background p16 br8 mb16">
            <h1 class="fw600 fs16">Responsibilities <sup>*</sup></h1>
            <div class="line2"></div>

            <div class="editor">
              <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
              <p-editor
                [(ngModel)]="textControl2"
                name="editor2"
                #editor2="ngModel"
                required
                [style]="{ height: '100px' }"
              ></p-editor>
              <div
              *ngIf="(editor2.touched || createJobDescriptionForm.submitted) &&
              editor2.errors?.['required']"
            >
              <span class="errorMessage">
                Please enter text
              </span>
            </div>
            </div>
          </div>

          <div class="white-background p16 br8 mb16">
            <h1 class="fw600 fs16">Skill Description <sup>*</sup></h1>
            <div class="line2"></div>

            <div class="editor">
              <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
              <p-editor
                [(ngModel)]="textControl3"
                name="editor3"
                #editor3="ngModel"
                required
                [style]="{ height: '100px' }"
              ></p-editor>
              <div
              *ngIf="(editor3.touched || createJobDescriptionForm.submitted) &&
              editor3.errors?.['required']"
            >
              <span class="errorMessage">
                Please enter text
              </span>
            </div>
            </div>
          </div>

          <div class="white-background p16 br8 mb16">
            <h1 class="fw600 fs16">Website Short Description <sup>*</sup></h1>
            <div class="line2"></div>

            <div class="editor">
              <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
              <p-editor
                [(ngModel)]="textControl4"
                name="editor4"
                #editor4="ngModel"
                required
                [style]="{ height: '100px' }"
              ></p-editor>
              <div
              *ngIf="(editor4.touched || createJobDescriptionForm.submitted) &&
              editor4.errors?.['required']"
            >
              <span class="errorMessage">
                Please enter text
              </span>
            </div>
            </div>
          </div>

          <div class="white-background p16 br8 mb16">
            <h1 class="fw600 fs16">Disclaimer <sup>*</sup></h1>
            <div class="line2"></div>

            <div class="editor">
              <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
              <p-editor
                [(ngModel)]="textControl5"
                name="editor5"
                #editor5="ngModel"
                required
                [style]="{ height: '100px' }"
              ></p-editor>
              <div
              *ngIf="(editor5.touched || createJobDescriptionForm.submitted) &&
              editor5.errors?.['required']"
            >
              <span class="errorMessage">
                Please enter text
              </span>
            </div>
            </div>
          </div>

          <!-- <div class="white-background p16 br8 mb16">
            <h1 class="fw600 fs16">Responsibilities <sup>*</sup></h1>
            <div class="line2"></div>

            <div class="editor">
              <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor>
              <p-editor
                [(ngModel)]="textControl6"
                name="editor6"
                #editor6="ngModel"
                required
                [style]="{ height: '100px' }"
              ></p-editor>
              <div
              *ngIf="(editor6.touched || createJobDescriptionForm.submitted) &&
              editor6.errors?.['required']"
            >
              <span class="errorMessage">
                Please enter text
              </span>
            </div>
            </div>
          </div> -->

          <div class="white-background p16 br8 mb16 skills-category">
            <ng-container *ngFor="let item of skillDataArr.skillLists; let n = index">
              <div class="row">
                <div class="mb-3 col-md-6 col-lg-4 mb-3 figma-ng-select">
                  <label for="Nationality" class="form-label fw500 neugray">Skill category <sup>*</sup></label>
                  <ng-select class="custom" [items]="skillcategroy" bindLabel="skillsName" bindValue="skillsId" (change)="getSkills($event,n)"


                    placeholder="Select skill category" [(ngModel)]="item.skillCategory" name="skillCategorySelect{{n}}" #skillCategorySelect="ngModel" required
                    [disabled]="n < skillDataArr.skillLists.length - 1"


                    >
                  </ng-select>
                  <div *ngIf="(skillCategorySelect.touched || createJobDescriptionForm.submitted) &&
                  skillCategorySelect.errors?.['required']">
                      <span class="errorMessage"> Please select skill category</span>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 figma-ng-select">
                  <label for="eligibleBrand" class="form-label fw500 neugray">Skills <sup>*</sup></label>

                  <ng-select
                  class="custom position-relative"
                  [multiple]="true"
                  [items]="item.skill_list"
                  bindLabel="skillName"
                  bindValue="skillCategoryId"
                  placeholder="Please select skills"
                  [(ngModel)]="item.skills"
                  name="skillsSelect{{n}}"
                  #skillsSelect="ngModel"
                  [multiple]="true"
                  required
                  [disabled]="n < skillDataArr.skillLists.length - 1"
                  >
                  </ng-select>

                  <div *ngIf="(skillsSelect.touched || createJobDescriptionForm.submitted) &&
                  skillsSelect.errors?.['required']">
                      <span class="errorMessage"> Please select skills</span>
                    </div>
                </div>
                <div class="add-icon-main col-md-6 col-xl-4 col-xl-4">
                  <span
                    role="button"
                    class="add__Text remove__Text"
                    (click)="deleteFieldss5(n)"
                    *ngIf="skillDataArr.skillLists.length === n + 1 && n != 0"
                    style="color: rgba(254, 84, 84, 1) !important"
                  >
                    Remove
                  </span>
                  <span
                    role="button"
                    class="add__Text add-iconnew"
                    *ngIf="
                      n == skillDataArr.skillLists.length - 1 ||
                      skillDataArr.skillLists.length == 1
                    "
                    (click)="addFieldss5(n)"
                    [disabled]="!isAddSkillButtonEnabled(item.skillCategory, item.skills)"

                  >
                    <!-- <img src="./assets/Images/add-icon.svg" alt="add-icon" /> -->

                    Add More Skills
                  </span>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="step-navigation p16 br8 mb16">
            <button (click)="back()" class="secondary-btn mr-3">
              Previous
            </button>
            <button type="submit" class="blue-btn">Next</button>
          </div>
        </form>
      </div>
      <!-- <div>{{activeStepIndex}}</div> -->
      <div *ngIf="activeStepIndex === 2">
        <!-- code given by adithya to ajith -->
        <form
          novalidate
          #createJobApproversForm="ngForm"
          (ngSubmit)="nextStep('third')
          "
        >
          <div class="white-background br8 p16">
            <p class="blue-color fs18 fw600 mb-3">
              Employment Offer Approver
              <span class="fs16 blackk">(During Offer Stage)</span>
            </p>
            <div class="mb-3 col-md-6 col-xl-4 figma-ng-select">
              <label for="postjobto" class="form-label fw500 neugray"
                >Employment CTC Approver<sup>*</sup></label
              >
              <!-- (change)="getnationalityid($event)" -->
              <ng-select
                class="custom"
                [items]="getEmployeeCtc"
                bindLabel="userName"
                bindValue="userId"
                placeholder="Select employment ctc approver"
                [(ngModel)]="empCtcApprover"
                name="empCtcApproverSelect"
                #empCtcApproverSelect="ngModel"
                required
              >
              </ng-select>
              <div
                *ngIf="(empCtcApproverSelect.touched || createJobApproversForm.submitted) &&
            empCtcApproverSelect.errors?.['required']"
              >
                <span class="errorMessage">
                  Please select employment ctc approver</span
                >
              </div>
            </div>
          </div>
          <div class=" recruiter-table p16 white-background table-responsive my-new-recruiter-table">
            <!-- [ngClass]="mycalenderOpened ? 'openedCalenderHeight' : '' " -->
            <p class="blackk fs16 fw600 mb-3">Recruiting Members</p>
            <table class="table white-background">
              <thead>
                <tr>
                  <th>Hiring Stage</th>
                  <th>Hiring Team Member<sup>*</sup></th>
                  <th>Interview Template<sup>*</sup></th>
                  <th>Interview Type<sup>*</sup></th>
                  <th> <p style="width:120px"> Cutoff Date<sup>*</sup></p> </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of hiringStage; let i = index">
                  <td>
                    <input class="cursor_not_allowed dependent_color_field" [(ngModel)]="x.stageName"
                    placeholder="Hiring stage"
                    name="x.stageName{{i}}" #ResphiringScreeningg="ngModel" readonly  />
                  </td>
                  <td>
                    <div class="figma-ng-select" style="width:200px">
                      <ng-select [items]="userRoleList" bindLabel="userMailId" bindValue="userMailId"
                      [(ngModel)]="x.teamMember" name="x.teamMember{{i}}" #hiringTeaamListtt="ngModel"
                      placeholder="Hiring team member" [multiple]="true" required>
                    </ng-select>
                    <div
                    *ngIf="(createJobApproversForm.submitted) &&
                    hiringTeaamListtt.errors?.['required']"
                  >
                  <!-- hiringTeaamListtt.touched ||  -->
                    <span class="errorMessage">
                      Please select hiring team member</span
                    >
                  </div>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="figma-ng-select w-100">
                      <ng-select name="x.template{{i}}" placeholder="Job interview template"
                          [items]="Interview_list" #template="ngModel" bindLabel="interviewTemplateName" bindValue="interviewTemplateId"
                          [clearable]="false" [searchable]="true" [closeOnSelect]="true"
                          [(ngModel)]="x.template" [multiple]="false" [hideSelected]="true"
                           [disabled]=" x.stageName ==='Screening' || x.stageName ==='Demo' || x.stageName ==='Ref Check' || x.stageName ==='Induction'|| x.stageName ==='Approval Stage' || x.stageName ==='Onboarding' || x.stageName ==='Offer' || x.stageName ==='Doc Check'"
                           >
                           <!-- [disabled]="viewDisable ? 'disabled' : null" -->
                           <ng-template ng-option-tmp let-item="item" let-index="i" let-item$="item$">
                            <div class="custom-control custom-checkbox">
                              <label class="form-check-label ml-2 custom-control-label">{{ item.interviewTemplateName }}</label>
                            </div>
                          </ng-template>
                        </ng-select>
                    </div>
                  </td>
                  <td class="col-md-3">
                    <div class="figma-ng-select w-100">
                      <ng-select [items]="InterviewwData" #interrrviewData="ngModel" name="x.interviewType{{i}}"
                      placeholder="Select interview" [clearable]="false" [searchable]="true"  bindLabel="name" bindValue="name"
                      [(ngModel)]="x.interviewType" [disabled]=" x.stageName ==='Screening'|| x.stageName ==='Demo' ||  x.stageName ==='Performance Feedback' ||  x.stageName ==='Approval Stage' || x.stageName ==='Ref Check' || x.stageName ==='Induction' || x.stageName ==='Onboarding' || x.stageName ==='Offer' || x.stageName ==='Doc Check'" >
                    </ng-select>
                    </div>
                  </td>
                  <td class="col-md-2">
                    <div class="figma-calender w-100">
                      <!-- <input class="new-old-datepicker" type="date" name="x.cutoffdate{{i}}" placeholder="Cutoff date"
                       [(ngModel)]="x.cutoffdate" #cutoffdate="ngModel" required> -->
                      <p-calendar name="x.cutoffdate{{i}}" placeholder="Cutoff date" [style]="{'width': '100%'}"
                            [inputStyle]="{'width':'100%'}" [(ngModel)]="x.cutoffdate" dateFormat="dd/mm/yy" [minDate]="minStartDate"
                             [maxDate]="newCutoffdate" [ngModelOptions]="{standalone: true}" #cutoffdate="ngModel" inputId="icon"
                             [showIcon]="true" [required]="true" (onFocus)="onCalendarOpen()" (onBlur)="onCalendarClose()"></p-calendar>
                             <div
                             *ngIf="(createJobApproversForm.submitted) &&
                             cutoffdate.errors?.['required']"
                           >
                           <!-- cutoffdate.touched ||  -->
                             <span class="errorMessage">
                               Please select cutoff date</span
                             >
                           </div>
                    </div>
                  </td>
                  <td class="pt-4">
                    <ng-container *ngIf="(x.teamMember && x.cutoffdate) && x.stageName ==='Demo'">
                      <input class="hiringTeam-checkboox" type="checkbox" name="x.hireStageCheckBooxOne{{i}}" [(ngModel)]="x.hireStageCheckBooxOne" [checked]="false" (change)="updateCheckboxState()"
                      [disabled]="!(x.teamMember?.length > 0) || !x.cutoffdate">
                  </ng-container>
                  <ng-container *ngIf="(x.teamMember || x.cutoffdate) && (x.teamMember && x.template && x.interviewType && x.cutoffdate) || (x.stageName ==='Screening' ||  x.stageName ==='Performance Feedback' ||  x.stageName ==='Approval Stage' ||x.stageName ==='Ref Check' || x.stageName ==='Induction' || x.stageName ==='Onboarding' || x.stageName ==='Offer' || x.stageName ==='Doc Check')">
                      <input [disabled]="!(x.teamMember?.length > 0) || !x.cutoffdate" class="hiringTeam-checkboox" type="checkbox" name="x.hireStageCheckBooxOne{{i}}" [(ngModel)]="x.hireStageCheckBooxOne" [checked]="true" (change)="updateCheckboxState()">
                  </ng-container>
                    <!-- <input
                      type="checkbox"
                      (change)="toggleAddButton(); selectedRowIndex = i"
                      /> -->
                      <!-- [disabled]="!isAllFieldsSelected(x)" -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="white-background br8 p16 justify-content-between d-flex mb-3">
            <div class="error ll-hh-20">
              Note:- Please select mandatory stages (Screening, Performance Feedback, Approval Stage, Ref check, Doc
              check, Offer, Onboarding, Induction). Then add button will be
              enabled.
            </div>
            <div class="d-flex align-items-center">
            <button type="button" class="secondary-btn pointer d-flex ml-2" *ngIf="isAnyCheckboxChecked"  [disabled]="!empCtcApprover"
               (click)="addToHiringTeamTablee()" style="width: auto;padding: 8px 16px !important; height:max-content"> Add </button>
               <button class="clear-btn" *ngIf="teamArray.length > 0" (click)="clearTeamArray()" style="height:max-content">Clear</button>
          </div>
          </div>
          <ng-container *ngIf="teamArray.length > 0">
            <div class="table-responsive recruiter-table-options p16 white-background br8">
              <table class="table">
                <thead>
                  <tr>
                    <th> <p class="width-max"> Hiring Stage </p> </th>
                    <th> <p class="width-max"> Hiring Team Member </p> </th>
                    <th> <p class="width-max"> Interview Template </p> </th>
                    <th> <p class="width-max"> Interview Type </p> </th>
                    <th> <p class="width-max"> Cutoff Date </p> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of teamArray">
                    <td>{{ row.hiringStage }}</td>
                    <td>{{ row.teamMember }}</td>
                    <td>{{ row.interviewTemplate || '--'}}</td>
                    <td>{{ row.interviewType || '--'}}</td>
                    <td>{{ row.cutoffDate | date : 'MM/dd/yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-container>
          <div class="step-navigation p16 mb16 mt-0">
            <button (click)="back()" class="secondary-btn mr-3">Previous</button>
            <button type="submit" class="blue-btn"  *ngIf="teamArray.length">Next</button>
          </div>
        </form>
        <!-- code given by adithya to ajith -->

        <!-- <form novalidate #createJobApproversForm="ngForm"
        (ngSubmit)="createJobApproversForm.form.valid && updatecreateJobApproversForm();nextStep('third')">
        <div class="border-class">

          <div class="card">
            <p-table [value]="products" [tableStyle]="{ 'min-width': '50rem' }">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Level</th>
                        <th>Approvers Designation</th>
                        <th>Member Name</th>
                        <th>Email</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>{{ product.code }}</td>
                        <td>{{ product.name }}</td>
                        <td>{{ product.category }}</td>
                        <td>{{ product.quantity }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        </div>
        <div>
          <button class="add-approver-btn">
            <img src="./assets/Images/add-icon.svg" alt="">
            <span>Add Approvers</span>
          </button>
        </div>
        <div class="step-navigation">
          <button (click)="back()" class="secondary-btn me-3">Back</button>
          <button class="blue-btn">Next</button>
        </div>
        </form> -->
      </div>
<!--
      <div *ngIf="activeStepIndex === 3">
        <form
          novalidate
          #createhiringTeamsForm="ngForm"
          (ngSubmit)="
            createhiringTeamsForm.form.valid && updatecreatehiringTeamsForm();
            nextStep('fourth')
          "
        >
          <div class="white-background p16 br8 mb16">
            <p class="fw600 fs16 blackk">Recruitment Members</p>
            <div class="line"></div>

            <div class="row">
              <div class="mb-3 col-md-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray"
                  >Hiring Team Member</label
                >
                <ng-select
                  class="custom"
                  bindLabel="nationality_Name"
                  bindValue="nationality_Id"
                  placeholder="Select Hiring Team Member"
                  [ngModel]="hiringTeamMember"
                  name="hiringTeamMemberSelect"
                  #hiringTeamMemberSelect="ngModel"
                  required
                >
                </ng-select>

                <div *ngIf="(hiringTeamMemberSelect.touched || createhiringTeamsForm.submitted) &&
                  hiringTeamMemberSelect.errors?.['required']">
                      <span class="errorMessage"> Please select hiring team member</span>
                    </div>
              </div>
              <div class="mb-3 col-md-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray"
                  >Hiring Stage</label
                >
                <ng-select
                  class="custom"
                  bindLabel="nationality_Name"
                  bindValue="nationality_Id"
                  placeholder="Select Hiring Stage"
                  [ngModel]="hiringStage"
                  name="hiringStageSelect"
                  #hiringStageSelect="ngModel"
                  required
                >
                </ng-select>
                <div *ngIf="(hiringStageSelect.touched || createhiringTeamsForm.submitted) &&
                    hiringStageSelect.errors?.['required']">
                      <span class="errorMessage"> Please select hiring stage</span>
                    </div>
              </div>
              <div class="mb-3 col-md-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray"
                  >Interview Template</label
                >
                <ng-select
                  class="custom"
                  bindLabel="nationality_Name"
                  bindValue="nationality_Id"
                  placeholder="Select Interview Template"
                  [ngModel]="interviewTemplate"
                  name="interviewTemplateSelect"
                  #interviewTemplateSelect="ngModel"
                  required
                >
                </ng-select>
                <div *ngIf="(interviewTemplateSelect.touched || createhiringTeamsForm.submitted) &&
                    interviewTemplateSelect.errors?.['required']">
                      <span class="errorMessage"> Please select interview template</span>
                    </div>
              </div>
            </div>
            <div class="row">
              <div class="mb-3 col-md-4 figma-calender">
                <label for="Nationality" class="form-label fw500 neugray"
                  >Cut-off Date</label
                >
                <div>
                  <p-calendar
                    [showIcon]="true"
                    class="primecalendar"
                    placeholder="Select cut-off date"
                    [ngModel]="cutOffDate1"
                    name="cutOffDate1Select"
                    #cutOffDate1Select="ngModel"
                  ></p-calendar>
                  <div *ngIf="(cutOffDate1Select.touched || createhiringTeamsForm.submitted) &&
                      cutOffDate1Select.errors?.['required']">
                        <span class="errorMessage"> Please select cut-off date</span>
                      </div>
                </div>
              </div>
              <div class="mb-3 col-md-4 figma-ng-select">
                <label for="Nationality" class="form-label fw500 neugray"
                  >Type of Interview</label
                >
                <ng-select
                  class="custom"
                  bindLabel="nationality_Name"
                  bindValue="nationality_Id"
                  placeholder="Select Type of Interview"
                  [ngModel]="typeOfInterview"
                  name="typeOfInterviewSelect"
                  #typeOfInterviewSelect="ngModel"
                  required
                >
                </ng-select>
                <div *ngIf="(typeOfInterviewSelect.touched || createhiringTeamsForm.submitted) &&
                      typeOfInterviewSelect.errors?.['required']">
                        <span class="errorMessage"> Please select type of interview</span>
                      </div>
              </div>
            </div>

          </div>
          <div class="white-background p16 br8 mb16" style="margin-top: 15px">
            <p-table [value]="products" [tableStyle]="{ 'min-width': '50rem' }">
              <ng-template pTemplate="header">
                <tr>
                  <th>Level</th>
                  <th>Approvers Designation</th>
                  <th>Member Name</th>
                  <th>Email</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-product>
                <tr>
                  <td>{{ product.code }}</td>
                  <td>{{ product.name }}</td>
                  <td>{{ product.category }}</td>
                  <td>{{ product.quantity }}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="d-flex check-section">
            <input type="checkbox" />
            <span>Are all Hiring stages added.</span>
          </div>
          <div class="step-navigation white-background p16 br8 mb16">
            <button (click)="back()" class="secondary-btn me-3">
              Previous
            </button>
            <button (click)="next()" class="blue-btn">Next</button>
          </div>
        </form>
      </div> -->

      <div *ngIf="activeStepIndex === 3">

        <form novalidate #targetAudienceForm="ngForm"
        (ngSubmit)="nextStep('fourth')">
          <!-- <app-stepper></app-stepper> -->
          <div class="white-background p16 br8 mb16 position-relative">
            <div class="col-md-6 col-lg-4 figma-ng-select">
              <label for="eligibleBrand" class="form-label fw500 neugray">Eligible Brand <sup>*</sup></label>
              <!-- (change)="getnationalityid($event)" -->

              <ng-select class="custom" [multiple]="true" [items]="eligibleBrands" bindLabel="brand" bindValue="brandId" placeholder="Select eligible brand"
              (change)="GetCountries(eligibleBrandss)" [(ngModel)]="eligibleBrandss" name="eligibleBrandSelect" #eligibleBrandSelect="ngModel" required>
              </ng-select>
              <div *ngIf="(eligibleBrandSelect.touched || targetAudienceForm.submitted) &&
              eligibleBrandSelect.errors?.['required']">
              <span class="errorMessage"> Please select eligible brand</span>
            </div>
            <div class="d-flex selected-values-main gap-3 align-items-center mt-2">
           <div class="selected-values">
            <div class="single-selected-value" *ngFor="let x of displayedItems; let i = index">
            <p> {{x}} <svg width="18" height="18" fill="#976EF7" class="bi bi-x pointer" viewBox="0 0 16 16" (click)="deleteSingleItem(i)">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
            </svg> </p>
          </div>
           </div>
           <div class="more-items" *ngIf="remainingCount > 0">
           <p>  + {{ remainingCount }} more </p>
          </div>
          </div>
            </div>
            <!-- <p class="blue-brd-pill mt-3" #pillElementBrand> GIIS <img src="../../../assets/Images/x-close.svg" alt="close" class="ml-2" (click)="removePill(pillElementBrand)"></p> -->
          </div>
          <div class="white-background p16 br8 mb16">
            <div class="col-md-6 col-lg-4 figma-ng-select">
              <label for="eligibleCountry" class="form-label fw500 neugray">Eligible Country <sup>*</sup></label>
              <!-- (change)="getnationalityid($event)" -->
              <ng-select class="custom" [multiple]="true" [items]="eligibleCountry" bindLabel="country" bindValue="countryId" placeholder="Select eligible country"
              (change)="GetCampusss(eligibleCountryyyy)" [(ngModel)]="eligibleCountryyyy" name="eligibleCoutnrySelect" #eligibleCoutnrySelect="ngModel" required>
              </ng-select>
              <div *ngIf="(eligibleCoutnrySelect.touched || targetAudienceForm.submitted) &&
              eligibleCoutnrySelect.errors?.['required']">
                  <span class="errorMessage"> Please select eligible country</span>
                </div>
            </div>
            <!-- <p class="blue-brd-pill mt-3" #pillElementCountry> GIIS <img src="../../../assets/Images/x-close.svg" alt="close" class="ml-2" (click)="removePill(pillElementCountry)"></p> -->
          </div>
          <div class="white-background p16 br8 mb16">
            <div class="col-md-6 col-lg-4 figma-ng-select">
              <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Campus <sup>*</sup></label>
              <!-- (change)="getnationalityid($event)" -->
              <ng-select class="custom" [multiple]="true" [items]="campusInfo" bindLabel="campusName" bindValue="campusId" placeholder="Select eligible campus"
                [(ngModel)]="eligibleCampussss" name="eligibleCampusSelect" #eligibleCampusSelect="ngModel" required>
              </ng-select>
              <div *ngIf="(eligibleCampusSelect.touched || targetAudienceForm.submitted) &&
              eligibleCampusSelect.errors?.['required']">
                  <span class="errorMessage"> Please select eligible campus</span>
                </div>
            </div>
            <!-- <p class="blue-brd-pill mt-3" #pillElementCampus> GIIS <img src="../../../assets/Images/x-close.svg" alt="close" class="ml-2" (click)="removePill(pillElementCampus)"></p> -->
          </div>
          <div class="white-background p16 br8 mb16">
            <div class="col-md-6 col-lg-4 figma-ng-select">
              <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Department <sup>*</sup></label>
              <!-- (change)="getnationalityid($event)" -->
              <ng-select class="custom" [multiple]="true" [items]="eligibleDepartment" bindLabel="department" bindValue="departmentId" placeholder="Select eligible department"
                [(ngModel)]="eligibleDepartments" name="eligibleDepartmentSelect" #eligibleDepartmentSelect="ngModel" disabled >
              </ng-select>
              <div *ngIf="(eligibleDepartmentSelect.touched || targetAudienceForm.submitted) &&
              eligibleDepartmentSelect.errors?.['required']">
                  <span class="errorMessage"> Please select eligible department</span>
                </div>
            </div>
            <!-- <p class="blue-brd-pill mt-3" #pillElementDepartment> GIIS <img src="../../../assets/Images/x-close.svg" alt="close" class="ml-2" (click)="removePill(pillElementDepartment)"></p> -->
          </div>
          <div class="white-background p16 br8 mb16">
            <div class="col-md-6 col-lg-4 figma-ng-select">
              <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Role <sup>*</sup></label>
              <!-- (change)="getnationalityid($event)" -->
              <ng-select class="custom" [multiple]="true" [items]="eligibleDepartment" bindLabel="role" bindValue="roleId" placeholder="Select eligible role"
                [(ngModel)]="eligibleRolesss" name="eligibleRoleSelect" #eligibleRoleSelect="ngModel" disabled >
              </ng-select>
              <div *ngIf="(eligibleRoleSelect.touched || targetAudienceForm.submitted) &&
              eligibleRoleSelect.errors?.['required']">
                  <span class="errorMessage"> Please select eligible role</span>
                </div>
            </div>
            <!-- <p class="blue-brd-pill mt-3" #pillElementRole> GIIS <img src="../../../assets/Images/x-close.svg" alt="close" class="ml-2" (click)="removePill(pillElementRole)"></p> -->
          </div>
          <!-- <div class="white-background p16 br8 mb16">
            <div class="row">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray">Employment Experience ( In months ) <sup>*</sup></label>
                <input type="text" class="form-control" placeholder="Enter experience" onkeypress="return /[0-9]/i.test(event.key)"
                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="employmentExp" name="employmentExpSelect" #employmentExpSelect="ngModel" required>

                <div *ngIf="(employmentExpSelect.touched || targetAudienceForm.submitted) &&
                employmentExpSelect.errors?.['required']">
                    <span class="errorMessage"> Please enter experience</span>
                  </div>
              </div>
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray">Current Role duration <sup>*</sup></label>
                <input type="text" class="form-control" placeholder="Enter role duration" onkeypress="return /[0-9]/i.test(event.key)"
                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="roleDuration" name="roleDurationSelect" #roleDurationSelect="ngModel" required>

                <div *ngIf="(roleDurationSelect.touched || targetAudienceForm.submitted) &&
                roleDurationSelect.errors?.['required']">
                    <span class="errorMessage"> Please enter role duration</span>
                  </div>
              </div>
            </div>
          </div> -->

          <div class="step-navigation mb16 p16 br8">
            <button (click)="back()" class="secondary-btn mr-3">Previous</button>
            <button type="submit" class="blue-btn">Next</button>
          </div>
        </form>
      </div>
      <div *ngIf="activeStepIndex === 4">
        <form novalidate #progrmaApproversForm="ngForm" (ngSubmit)=" nextStep('fifth')">

        <div>
          <table class="table approver-table">
            <thead>
              <tr class="white-background br8">
                <th class="blackk fs14 fw600 p16 py-2">Level <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                <!-- <th class="blackk fs14 fw600 p16">Country <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th> -->
                <th class="blackk fs14 fw600 p16 py-2">Approvers Designation <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                <th class="blackk fs14 fw600 p16  col-md-6">Member Name/ Mail ID<sup>*</sup> <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let x of approverTable; let i = index"  class="white-background br8">
                <td class="fs14 blackk fw400 p16">{{ x.level }}</td>
                <!-- <td class="fs14 blackk fw400 p16">{{ x.country }}</td> -->
                <td class="fs14 blackk fw400 p16">{{ x.designation }}</td>
                <td class="p16 col-md-6">

                  <div class="figma-ng-select col-md-10">
                    <ng-select class="custom" [items]="x.filteredMembers" bindLabel="userName" bindValue="userId" [multiple]="true"
                      placeholder="Select name" [(ngModel)]="x.hiringTeamName" name="hiringTeamNameSelect{{i}}"
                      (change)="onMemberSelect(i)" #hiringTeamNameSelect="ngModel" required>
                    </ng-select>
                    <div *ngIf="(hiringTeamNameSelect.touched || progrmaApproversForm.submitted) &&
                    hiringTeamNameSelect.errors?.['required']">
              <span class="errorMessage"> Please select member name</span>
            </div>
                  </div>
                  <!-- <button type="button" class="blue-btn px-2"> Save </button>
                  <button class="secondary-btn mr-2">Edit</button> -->



                  <!-- <div>
                    <p class="fs14 blackk fw400 mb-1">{{ x.MemberName }}</p>
                   <p class="fs10 fw500 grey">{{ x.MailID }}</p>
                  </div>
                  <div class="verticalline"></div>
                  <div>
                    <p class="fs14 blackk fw400 mb-1">{{ x.MemberName }}</p>
                   <p class="fs10 fw500 grey">{{ x.MailID }}</p>
                  </div> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="field gs-form-group white-background mb-4 p16 br8">
          <label class="form-label blackk fs14 fw600 mb-2">Does this job require approval from Applicant Reporting Manager ?<sup>*</sup></label>
          <div class="gs-grouped-fileds d-flex mt-2 gap-3">
            <div class="ui radio checkbox gs-grouped-filed mr-2 d-flex">
              <input class="" type="radio" [(ngModel)]="applicantApproval" name="applicantApprovall" value="true" required  />
              <label class="grouped-label blackk fs14 fw600 ml-2">Yes</label>
            </div>
            <div class="ui radio checkbox gs-grouped-filed d-flex">
              <input class="" type="radio" [(ngModel)]="applicantApproval" name="applicantApprovall" value="false" required  />
              <label class="grouped-label blackk fs14 fw600 ml-2">No</label>
            </div>

            </div>
            <p class="errorMessage my-2" *ngIf="showRadioError"> Please select an option </p>


        </div> -->
        <div class="step-navigation mb16 p16 br8">
          <button (click)="back()" class="secondary-btn mr-3">Previous</button>
          <!-- <p-toast key="toast1" /> -->
          <button type="submit" pRipple class="blue-btn"
         >Submit</button>
         <!-- [disabled]="progrmaApproversForm.form.invalid" -->
          <!-- <button class="blue-btn" (click)="addaproveFithscreen()"  *ngIf="enableReferralCampaign==true"  >Next</button> -->
          <!-- [routerLink]="['/referral/'+edId]" -->
        </div>
        </form>
      </div>
      <div *ngIf="activeStepIndex === 5">
        <form novalidate #referralForm="ngForm">
          <!-- <div class="white-background p16 br8 mb16 ">
            <div class="col-md-6 col-lg-4 figma-ng-select">
              <label for="eligibleBrand" class="form-label fw500 neugray">Eligible Brand</label>

              <ng-select class="custom position-relative" [multiple]="true" [items]="eligibleBrand" bindLabel="name" bindValue="id" placeholder="Please select eligible brand"
                [ngModel]="eligibleBrandSelection"  name="eligibleBrandSelect" #eligibleBrandSelect="ngModel" required>
              </ng-select>

              <div *ngIf="(eligibleBrandSelect.touched || referralForm.submitted) &&
              eligibleBrandSelect.errors?.['required']">
                  <span class="errorMessage"> Please select eligible brand</span>
                </div>
            </div>
          </div>
          <div class="white-background p16 br8 mb16">
            <div class="col-md-6 col-lg-4 figma-ng-select">
              <label for="eligibleCountry" class="form-label fw500 neugray">Eligible Country</label>
              <ng-select class="custom" [multiple]="true" [items]="eligibleCountryReferral" bindLabel="name" bindValue="id" placeholder="Select eligible country"
                [ngModel]="eligibleCountrySelection" name="eligibleCoutnrySelect" #eligibleCoutnrySelect="ngModel" required>
              </ng-select>
              <div *ngIf="(eligibleCoutnrySelect.touched || referralForm.submitted) &&
              eligibleCoutnrySelect.errors?.['required']">
                  <span class="errorMessage"> Please select eligible country</span>
                </div>
            </div>
          </div>
          <div class="white-background p16 br8 mb16">
            <div class="col-md-6 col-lg-4 figma-ng-select">
              <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Campus</label>
              <ng-select class="custom" [multiple]="true" [items]="eligibleCampus" bindLabel="name" bindValue="id" placeholder="Select eligible campus"
                [ngModel]="eligibleCampusSelection" name="eligibleCampusSelect" #eligibleCampusSelect="ngModel" required>
              </ng-select>
              <div *ngIf="(eligibleCampusSelect.touched || referralForm.submitted) &&
              eligibleCampusSelect.errors?.['required']">
                  <span class="errorMessage"> Please select eligible campus</span>
                </div>
            </div>
          </div>
          <div class="white-background p16 br8 mb16">
            <div class="col-md-6 col-lg-4 figma-ng-select">
              <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Department</label>
              <ng-select class="custom" [multiple]="true" [items]="eligibleDepartmentReferral" bindLabel="name" bindValue="id" placeholder="Select eligible department"
                [ngModel]="eligibleDepartmentSelection" name="eligibleDepartmentSelect" #eligibleDepartmentSelect="ngModel" required>
              </ng-select>
              <div *ngIf="(eligibleDepartmentSelect.touched || referralForm.submitted) &&
              eligibleDepartmentSelect.errors?.['required']">
                  <span class="errorMessage"> Please select eligible department</span>
                </div>
            </div>
          </div>
          <div class="white-background p16 br8 mb16">
            <div class="col-md-6 col-lg-4 figma-ng-select">
              <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Role</label>
              <ng-select class="custom" [multiple]="true" [items]="eligibleRole" bindLabel="name" bindValue="id" placeholder="Select eligible role"
                [ngModel]="eligibleRoleSelection" name="eligibleRoleSelect" #eligibleRoleSelect="ngModel" required>
              </ng-select>
              <div *ngIf="(eligibleRoleSelect.touched || referralForm.submitted) &&
              eligibleRoleSelect.errors?.['required']">
                  <span class="errorMessage"> Please select eligible role</span>
                </div>
            </div>
          </div>
          <div class="white-background p16 br8 mb16">
            <div class="row">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray">Employment Experience ( In months ) <sup>*</sup></label>
                <input type="text" class="form-control" placeholder="Enter experience" onkeypress="return /[0-9]/i.test(event.key)"
                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="employmentExp" name="employmentExpSelect" #employmentExpSelect="ngModel" required>

                <div *ngIf="(employmentExpSelect.touched || referralForm.submitted) &&
                employmentExpSelect.errors?.['required']">
                    <span class="errorMessage"> Please enter experience</span>
                  </div>
              </div>
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray">Current Role duration <sup>*</sup></label>
                <input type="text" class="form-control" placeholder="Enter role duration" onkeypress="return /[0-9]/i.test(event.key)"
                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="roleDuration" name="roleDurationSelect" #roleDurationSelect="ngModel" required>

                <div *ngIf="(roleDurationSelect.touched || referralForm.submitted) &&
                roleDurationSelect.errors?.['required']">
                    <span class="errorMessage"> Please enter role duration</span>
                  </div>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table approver-table">
              <thead>
                <tr class="white-background br8">
                  <th class="blackk fs14 fw600 p16">Level <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Country <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Approvers Designation <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Member Name/ Mail ID <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of referralApproverTable; let i = index"  class="white-background br8">
                  <td class="fs14 blackk fw400 p16">{{ x.level }}</td>
                  <td class="fs14 blackk fw400 p16">{{ x.country }}</td>
                  <td class="fs14 blackk fw400 p16">{{ x.designation }}</td>
                  <td class="d-flex p16">
                    <div>
                      <p class="fs14 blackk fw400 mb-1">{{ x.MemberName }}</p>
                     <p class="fs10 fw500 grey">{{ x.MailID }}</p>
                    </div>
                    <div class="verticalline"></div>
                    <div>
                      <p class="fs14 blackk fw400 mb-1">{{ x.MemberName }}</p>
                     <p class="fs10 fw500 grey">{{ x.MailID }}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> -->
          <!-- <div class="field gs-form-group white-background mb-4 p16 br8">
            <label class="form-label blackk fs14 fw600 mb-2">Does this job require approval from Applicant Reporting Manager ?</label>
            <div class="gs-grouped-fileds d-flex mt-2">
              <div class="ui radio checkbox gs-grouped-filed mr-2">
                <input class="hidden" type="radio" [(ngModel)]="applicantApproval" name="applicantApproval" [value]="true" tabindex="0"/>
                <label class="grouped-label blackk fs14 fw600 ml-2">Yes</label>
              </div>
              <div class="ui radio checkbox gs-grouped-filed">
                <input class="hidden" type="radio" [(ngModel)]="applicantApproval" name="applicantApproval" [value]="false" tabindex="0"/>
                <label class="grouped-label blackk fs14 fw600 ml-2">No</label>
              </div>
            </div>

          </div> -->
          <div class="white-background p16 br8 mb16 base-configuration">
            <div class="gs-grouped-fields d-flex mb-4 custom01 mb-4">
              <div class="ui radio checkbox gs-grouped-field" (click)="setReferralApplicable('applicable')">
                <input type="radio" name="applicable" id="applicable" [checked]="referralApplicable === 'applicable'" />
                <label for="applicable" class="grouped-label fs14 fw600 blackk ml-2">Applicable</label>
              </div>
              <div class="ui radio checkbox gs-grouped-field ms-3" (click)="setReferralApplicable('notApplicable')">
                <input type="radio" name="applicable" id="notApplicable" [checked]="referralApplicable === 'notApplicable'" />
                <label for="notApplicable" class="grouped-label fs14 fw600 blackk ml-2">Not Applicable</label>
              </div>
            </div>
          </div>
          <div class="applicable-part" *ngIf="referralApplicable === 'applicable'">
            <div class="white-background p16 br8 mb16 base-configuration">
              <div class="row">
                <p class="fw600 fs14 blackk mb-4">Base Configuration</p>
                <div class="col-md-6 col-lg-4 figma-ng-select">
                  <label for="eligibleCountry" class="form-label fw500 neugray">Program Type</label>
                  <!-- (change)="getnationalityid($event)" -->
                  <ng-select class="custom" [items]="programTypeList" bindLabel="name" bindValue="name" placeholder="Select program type"
                    [(ngModel)]="programTypeListSelection" name="programTypeListSelect" #programTypeListSelect="ngModel" required>
                  </ng-select>
                  <div *ngIf="(programTypeListSelect.touched || referralForm.submitted) &&
                  programTypeListSelect.errors?.['required']">
                      <span class="errorMessage"> Please select program type</span>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 figma-ng-select">
                  <label for="eligibleCountry" class="form-label fw500 neugray">Currency</label>
                  <!-- (change)="getnationalityid($event)" -->
                  <ng-select class="custom" [items]="currencyArray" bindLabel="currencycode" bindValue="currencycode" placeholder="Select currency"
                    [(ngModel)]="referralCurrencyListSelection" name="currencyListSelect" #currencyListSelect="ngModel" required>
                  </ng-select>
                  <div *ngIf="(currencyListSelect.touched || referralForm.submitted) &&
                  currencyListSelect.errors?.['required']">
                      <span class="errorMessage"> Please select currency</span>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 figma-ng-select">
                  <label for="eligibleCampus" class="form-label fw500 neugray d-flex"><img src="./assets/Images/tooltip-info-icon.svg" pInputText
                    pTooltip="Number of Positions" tooltipPosition="top"  alt=""> Open Position </label>
                  <!-- (change)="getnationalityid($event)" -->
                  <input type="text" class="form-control" placeholder="Enter number of positions" onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"  (keyup)="updateMaxValue();"  [(ngModel)]="numberOfPositionsReferral" value="10" name="numberOfPositionsSelect" #numberOfPositionsSelect="ngModel" required>

                  <div *ngIf="(numberOfPositionsSelect.touched || referralForm.submitted) &&
                  numberOfPositionsSelect.errors?.['required']">
                      <span class="errorMessage"> Please enter number of positions</span>
                    </div>
                </div>
              </div>
            </div>
            <div class="bonus-type white-background p16 br8 mb16">
                <p class="fw600 fs14 blackk mb-4">Bonus Type </p>
                <div class="field gs-form-group mb-4">
                  <div class="gs-grouped-fileds d-flex custom01">
                    <div class="ui radio checkbox gs-grouped-filed checked" (click)="selectedBonusType = 'flat';valuechage()">
                      <input class="hidden" type="radio" name="bonus" [checked]="selectedBonusType === 'flat'" tabindex="0"/>
                      <label class="grouped-label fs14 fw600 blackk ml-2">Flat </label>
                    </div>
                    <div class="ui radio checkbox gs-grouped-filed ms-3" (click)="selectedBonusType = 'tenure';valueChange1()">
                      <input class="hidden" type="radio" name="bonus" [checked]="selectedBonusType === 'tenure'" tabindex="0"/>
                      <label class="grouped-label fs14 fw600 blackk ml-2">Tenure</label>
                    </div>

                    <!-- <div class="ui radio checkbox gs-grouped-filed ms-3" (click)="selectedBonusType = 'slab'">
                      <input class="hidden" type="radio" name="bonus" [checked]="selectedBonusType === 'slab'" tabindex="0"/>
                      <label class="grouped-label fs14 fw600 blackk ml-2">Slab Based</label>
                    </div> -->

                  </div>
                </div>
                <div class="row flat-type align-items-start" *ngIf="selectedBonusType === 'flat'">
                  <div class="row align-items-start">
                    <div class="col-md-6 row">
                      <label class="form-label fw500 fs12 neugray mb-2" style="line-height: 18px;">Referral Bonus</label>
                      <div class="figma-ng-select col-md-2 position-relative">
                        <input type="text" value="1" class="form-control"  [(ngModel)]="referralBonus" placeholder="" name="referralBonuss" #referralBonuss="ngModel" disabled>
                        <img src="./assets/Images/profile-icon.png" alt="person" style="position: absolute;top: 50%;width: 25px;height: 25px;transform: translate(50%, -50%);right: 35px;">
                        <!-- <div *ngIf="(referralBonuss.touched || referralForm.submitted) && referralBonuss.errors?.['required']">
                        <span class="errorMessage"> Please enter bonus</span>
                      </div> -->
                      </div>
                      <div class="col-md-3 figma-ng-select">

                        <ng-select class="custom" [items]="currencyArray" bindLabel="currencycode" bindValue="currencycode" placeholder="Select currency"
                          [(ngModel)]="currencyListSelection1" name="currencyListSelect1" #currencyListSelect1="ngModel" required>
                        </ng-select>
                        <div *ngIf="(currencyListSelect1.touched || referralForm.submitted) &&
                        currencyListSelect1.errors?.['required']">
                            <span class="errorMessage"> Please select currency</span>
                          </div>
                      </div>
                      <div class="col-md-6 figma-ng-select">
                        <input type="text" class="form-control" placeholder="Enter referral bonus amount" onkeypress="return /[0-9]/i.test(event.key)"
                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"  (keyup)="updateMaxValue()"  [(ngModel)]="referralAmount" name="amountSelect" #amountSelect="ngModel" required>

                        <div *ngIf="(amountSelect.touched || referralForm.submitted) &&
                        amountSelect.errors?.['required']">
                            <span class="errorMessage"> Please enter amount</span>
                          </div>
                      </div>
                    </div>
                    <div class="col-md-4 figma-ng-select">
                      <label  class="form-label fw500 neugray d-flex"><img src="./assets/Images/tooltip-info-icon.svg" pInputText
                        pTooltip="Select minimum tenure" tooltipPosition="top"  alt="">Minimum Tenure (In months)</label>
                      <!-- (change)="getnationalityid($event)" -->
                      <ng-select class="custom" [items]="refreeTenure" bindLabel="name" bindValue="id" placeholder="Select minimum tenure"
                        [(ngModel)]="minimumTenureSelection" name="minimumTenuretSelect" #minimumTenuretSelect="ngModel" required>
                      </ng-select>
                      <div *ngIf="(minimumTenuretSelect.touched || referralForm.submitted) &&
                      minimumTenuretSelect.errors?.['required']">
                          <span class="errorMessage"> Please select minimum tenure</span>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="tenure row" *ngIf="selectedBonusType === 'tenure'">
                  <ng-container >
                    <div class="row">
                      <div class="mb-3 col-md-2 figma-ng-select">
                        <label class="form-label fw500 neugray">Referral Bonus</label>
                        <ng-select class="custom" [items]="currencyArray" bindLabel="currencycode" bindValue="currencycode" placeholder="Select currency"
                        [(ngModel)]="refreeTenureCurrencySelection" name="refreeTenureCurrencytSelect" #refreeTenureCurrencytSelect="ngModel" required>
                      </ng-select>
                      <div *ngIf="(refreeTenureCurrencytSelect.touched || referralForm.submitted) &&
                      refreeTenureCurrencytSelect.errors?.['required']">
                          <span class="errorMessage"> Please select currency</span>
                        </div>
                        <!-- <div class="d-flex gap-2 align-items-center">

                          <input type="text" class="form-control"  onkeypress="return /[0-9]/i.test(event.key)"
                          oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [(ngModel)]="bonusFrom" name="bonusFromInput" #bonusFromInput="ngModel" placeholder="from" /> -
                          <input type="text" class="form-control"  onkeypress="return /[0-9]/i.test(event.key)"
                          oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [(ngModel)]="bonusTo" name="bonusToInput" #bonusToInput="ngModel" placeholder="to" />
                        </div> -->
                      </div>
                      <div class="col-md-6 col-lg-4 figma-ng-select">
                        <label  class="form-label fw500 neugray">Referral Bonus</label>
                        <input type="text" class="form-control" placeholder="Enter referral bonus" onkeypress="return /[0-9]/i.test(event.key)"

                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [(ngModel)]="referralAmount" name="amountSelect1" #amountSelect1="ngModel"
                        (keyup)="updateMaxValue()" required>

                        <div *ngIf="(amountSelect1.touched || referralForm.submitted) &&
                        amountSelect1.errors?.['required']">
                            <span class="errorMessage"> Please enter referral bonus</span>
                          </div>
                      </div>
                    </div>
                    <div class="row" *ngFor="let item of addBonusArr.addBonusArrItem; let q = index">
                      <div class="col-md-6 col-lg-4 figma-ng-select mb-4">
                        <label  class="form-label fw500 neugray d-flex"><img src="./assets/Images/tooltip-info-icon.svg" pInputText
                          pTooltip="Select tenure of referee" tooltipPosition="top"  alt="">Tenure of Referee ( In months )</label>
                        <!-- (change)="getnationalityid($event)" -->
                        <ng-select class="custom" [items]="refreeTenure" bindLabel="name" bindValue="id" placeholder="Select minimum tenure"
                          [(ngModel)]="item.refreeTenureSelection" name="refreeTenuretSelect{{q}}" #refreeTenuretSelect="ngModel" required>
                        </ng-select>
                        <div *ngIf="(refreeTenuretSelect.touched || referralForm.submitted) &&
                        refreeTenuretSelect.errors?.['required']">
                            <span class="errorMessage"> Please select refree tenure</span>
                          </div>
                      </div>
                      <!-- (keyup)="caluculatedata(item.addAmount,q)" -->
                      <div class="col-md-6 col-lg-4 figma-ng-select">
                        <label  class="form-label fw500 neugray">Additional %</label>
                        <input type="text" class="form-control sign-suffix" placeholder="Enter"  onkeypress="return /[0-9]/i.test(event.key)"
                        oninput="if(!this.value.match(/^100$|^(\d{0,2}(\.\d{0,2})?)$/)) this.value='';"  [(ngModel)]="item.addAmount" name="addAmountSelect{{q}}" #addAmountSelect="ngModel" required>

                        <div *ngIf="(addAmountSelect.touched || referralForm.submitted) &&
                        addAmountSelect.errors?.['required']">
                            <span class="errorMessage"> Please enter additional %</span>
                          </div>
                      </div>
                      <div class="col-md-4 align-self-center">
                        <!-- <label class="form-label fw500 neugray fs12">Action</label> -->
                        <div class="d-flex">
                          <img src="./assets/Images/add-icon.svg" alt="edit"  class="add__Text add-iconnew blue-color fs14 fw600 mr-3 pointer" *ngIf="
                          q == addBonusArr.addBonusArrItem.length - 1 ||
                          addBonusArr.addBonusArrItem.length == 1
                        " (click)="addFieldssRef2(q)">
                          <img src="./assets/Images/delete.svg" alt="delete" role="button" class="add__Text remove__Text" (click)="deleteFieldssRef2(q)"
                          *ngIf="(addBonusArr.addBonusArrItem.length === ( q+1)) &&  (q !=0 )"
                          style="color:rgba(254, 84, 84, 1) !important">
                        </div>
                      </div>
                    </div>
                    <!-- <div class="add-icon-main col-md-6 col-lg-4">

                      <span role="button" class="add__Text add-iconnew blue-color fs14 fw600" *ngIf="
                      y == addBonusArr.addBonusArrItem.length - 1 ||
                      addBonusArr.addBonusArrItem.length == 1
                    " (click)="addFieldss2(y)">
                    <img src="./assets/Images/add-icon.svg" alt="add-icon">
                    Add More Milestone
                      </span>
                      </div> -->
                  </ng-container>
                </div>
                <!-- <div class="slab-type" *ngIf="selectedBonusType === 'slab'">
                  <div class="col-md-6 col-lg-4 figma-ng-select mb-4">
                    <label  class="form-label fw500 neugray d-flex"><img src="./assets/Images/tooltip-info-icon.svg" pInputText
                      pTooltip="Select minimum tenure" tooltipPosition="top"  alt="">Minimum Tenure</label>
                    <ng-select class="custom" [items]="minimumTenureSlab" bindLabel="name" bindValue="id" placeholder="Select minimum tenure"
                      [ngModel]="minimumTenureSelectionSlab" name="minimumTenuretSelectSlab" #minimumTenuretSelectSlab="ngModel" required>
                    </ng-select>
                    <div *ngIf="(minimumTenuretSelectSlab.touched || referralRulesForm.submitted) &&
                    minimumTenuretSelectSlab.errors?.['required']">
                        <span class="errorMessage"> Please select minimum tenure</span>
                      </div>
                  </div>
                  <ng-container *ngFor="let item of referralBonusArr.referralBonusArrItem; let x = index">
                    <div class="row">
                      <div class="mb-3 col-md-6 col-lg-4 figma-ng-select">
                        <label class="form-label fw500 neugray">Referral Bonus</label>
                        <div class="d-flex gap-2 align-items-center">

                          <input type="text" class="form-control"  onkeypress="return /[0-9]/i.test(event.key)"
                          oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [(ngModel)]="item.bonusFrom" name="bonusFromInput{{x}}" #bonusFromInput="ngModel" placeholder="from" /> -
                          <input type="text" class="form-control"  onkeypress="return /[0-9]/i.test(event.key)"
                          oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [(ngModel)]="item.bonusTo" name="bonusToInput{{x}}" #bonusToInput="ngModel" placeholder="to" />
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 figma-ng-select">
                        <label  class="form-label fw500 neugray">Referral Bonus</label>
                        <input type="text" class="form-control" placeholder="Enter referral bonus" onkeypress="return /[0-9]/i.test(event.key)"
                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="item.amount1" name="amountSelect1{{x}}" #amountSelect1="ngModel" required>

                        <div *ngIf="(amountSelect1.touched || targetAudienceForm.submitted) &&
                        amountSelect1.errors?.['required']">
                            <span class="errorMessage"> Please enter referral bonus</span>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <label class="form-label fw500 neugray fs12">Action</label>
                        <div class="d-flex">
                          <img class="m-2 pointer" src="./assets/Images/edit-2.svg" alt="edit">
                          <img class="m-2" src="./assets/Images/delete.svg" alt="delete" role="button" class="add__Text remove__Text" (click)="deleteFieldss1(x)"
                          *ngIf="(referralBonusArr.referralBonusArrItem.length === ( x+1)) &&  (x !=0 )"
                          style="color:rgba(254, 84, 84, 1) !important">
                        </div>
                      </div>

                    </div>

                    <div class="add-icon-main col-md-6 col-lg-4">

                    <span role="button" class="add__Text add-iconnew blue-color fs14 fw600" *ngIf="
                    x == referralBonusArr.referralBonusArrItem.length - 1 ||
                    referralBonusArr.referralBonusArrItem.length == 1
                  " (click)="addFieldss1(x)">
                  <img src="./assets/Images/add-icon.svg" alt="add-icon">
                  Add More Bonus Slab
                    </span>
                    </div>
                  </ng-container>

                </div> -->

            </div>
            <!-- <div class="additional-bonus-type white-background p16 br8 mb16">
              <p class="fw600 fs14 blackk mb-4 d-flex ">Additional Bonus <img src="./assets/Images/tooltip-info-icon.svg" pInputText
                pTooltip="Additional Bonus" tooltipPosition="right"  alt="info" style="width: 20px;height: 20px;margin-left: 4px;"></p>
              <div class="field gs-form-group mb-4">
                <p class="fs12 fw500 blackk mb-2">Based on</p>
                <div class="gs-grouped-fileds d-flex mb-4 custom01">
                  <div class="ui radio checkbox gs-grouped-filed checked" (click)="additionalBonusType = 'na'">
                    <input class="hidden" type="radio" name="addBonus" [checked]="additionalBonusType === 'na'" tabindex="0"/>
                    <label class="grouped-label fs14 fw600 blackk ml-2">N/A </label>
                  </div>
                  <div class="ui radio checkbox gs-grouped-filed ms-3" (click)="additionalBonusType = 'tenure'">
                    <input class="hidden" type="radio" name="addBonus" [checked]="additionalBonusType === 'tenure'" tabindex="0"/>
                    <label class="grouped-label fs14 fw600 blackk ml-2">Tenure</label>
                  </div>

                </div>
                <div class="tenure row" *ngIf="additionalBonusType === 'tenure'">
                  <ng-container *ngFor="let item of addBonusArr.addBonusArrItem; let q = index">
                    <div class="row">
                      <div class="col-md-6 col-lg-4 figma-ng-select mb-4">
                        <label  class="form-label fw500 neugray d-flex"><img src="./assets/Images/tooltip-info-icon.svg" pInputText
                          pTooltip="Select tenure of referee" tooltipPosition="top"  alt="">Tenure of Referee ( In months )</label>
                        <ng-select class="custom" [items]="refreeTenure" bindLabel="name" bindValue="id" placeholder="Select minimum tenure"
                          [ngModel]="item.refreeTenureSelection" name="refreeTenuretSelect{{q}}" #refreeTenuretSelect="ngModel" required>
                        </ng-select>
                        <div *ngIf="(refreeTenuretSelect.touched || referralForm.submitted) &&
                        refreeTenuretSelect.errors?.['required']">
                            <span class="errorMessage"> Please select refree tenure</span>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 figma-ng-select">
                        <label  class="form-label fw500 neugray">Additional %</label>
                        <input type="text" class="form-control sign-suffix" placeholder="Enter" onkeypress="return /[0-9]/i.test(event.key)"
                        oninput="if(!this.value.match(/^100$|^(\d{0,2}(\.\d{0,2})?)$/)) this.value='';"  [ngModel]="item.addAmount" name="addAmountSelect{{q}}" #addAmountSelect="ngModel" required>

                        <div *ngIf="(addAmountSelect.touched || referralForm.submitted) &&
                        addAmountSelect.errors?.['required']">
                            <span class="errorMessage"> Please enter additional %</span>
                          </div>
                      </div>
                      <div class="col-md-4 align-self-center">
                        <div class="d-flex">
                          <img src="./assets/Images/add-icon.svg" alt="edit"  class="add__Text add-iconnew blue-color fs14 fw600 me-3 pointer" *ngIf="
                          q == addBonusArr.addBonusArrItem.length - 1 ||
                          addBonusArr.addBonusArrItem.length == 1
                        " (click)="addFieldssRef2(q)">
                          <img src="./assets/Images/delete.svg" alt="delete" role="button" class="add__Text remove__Text" (click)="deleteFieldssRef2(q)"
                          *ngIf="(addBonusArr.addBonusArrItem.length === ( q+1)) &&  (q !=0 )"
                          style="color:rgba(254, 84, 84, 1) !important">
                        </div>
                      </div>
                    </div>
                    <div class="add-icon-main col-md-6 col-lg-4">

                      <span role="button" class="add__Text add-iconnew blue-color fs14 fw600" *ngIf="
                      y == addBonusArr.addBonusArrItem.length - 1 ||
                      addBonusArr.addBonusArrItem.length == 1
                    " (click)="addFieldss2(y)">
                    <img src="./assets/Images/add-icon.svg" alt="add-icon">
                    Add More Milestone
                      </span>
                      </div>
                  </ng-container>
                </div>
              </div>
            </div> -->
            <div class="budgeted-for white-background p16 br8 mb16">
              <p class="fs14 fw600 blackk mb-4">Budgeted for the programme</p>
              <div class="mb-3">
                <p class="fs12 fw500 blackk mb-2">Minimum Budget</p>
                <p class="fs14 fw600 blackk">Rs. {{referralAmount }}/-</p>
              </div>
              <div class="maximum">
                <p class="fs12 fw500 blackk mb-2">Maximum Budget</p>
                <p class="fs14 fw600 blackk">{{ maxValue }}</p>
              </div>
            </div>
          </div>

          <div class="step-navigation white-background p16 br8 ">
            <button (click)="back()" class="secondary-btn mr-2">Previous</button>
            <button class="blue-btn" (click)="showSuccess()">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- <div class=" flex justify-content-center mt-2 pt-2">
  <p-accordion class="w-full" [activeIndex]="0">
    <p-accordionTab header="1.Basic Job Details">


    </p-accordionTab>
  </p-accordion>
</div> -->
<ng-container *ngIf="openIndexModal">
  <div class="blurred-background">
  <div class="delete-confirm-modal-main">
    <div class="modal-dialog">
      <div class="modal-content" style="background-color: white; border-radius:8px; padding: 1.2rem;">
        <div class="modal-body bdr_btm">
          <div class="fs18 fw600" style="padding: 20px;color: #2f3031;">
            Are you sure to delete this record?
          </div>
        </div>
        <div class="modal-footer justify-content-center" style="gap:12px">
          <button
                                type="button"
                                class="btn secondary-btn px-3"
                                data-dismiss="modal"
                                style="
                                  border: 1px solid red !important;
                                  color: red !important;
                                " (click)="dateRecordNo()"
                              >
                                No
                              </button>
                              <button
                                type="button"
                                class="btn secondary-btn px-3"
                                (click)="dateRecordsYes(i)"
                                data-dismiss="modal"
                              >
                                Yes
                              </button>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>

<div class=" flex justify-content-center successApproveModal">
  <!-- <p-button  label="Show" /> -->
  <p-dialog
      [(visible)]="visibleSuccess"
      [modal]="true"
      [style]="{ width: '400px', height:'500px' }">
      <div *ngIf="!approveSuccess">
        <div class="mb-4 text-center">
          <img src="./assets/Images/approvesuccess-modal.svg"  style="height: 300px;width: 350px;" alt="Success">
        </div>

        <div>
          <p class="fs18 text-center mb-4  fw600">Are you sure you want to Post the Job?</p>
          <div class="text-center">
            <button class="secondary-btn mb-2" (click)="showApproveSuccess()">Yes</button>
            <button class="blue-btn mb-2 ml-2" (click)="visibleSuccess = false">No, check details</button>
          </div>
        </div>
      </div>

      <div *ngIf="approveSuccess">
        <img src="./assets/Images/successmodal.svg" style="height: 350px;width: 350px;margin-block-end: 24px;" alt="Success">
        <p class="fs18 text-center mb-4  fw600">Job posted and created successfully</p>
        <button class="blue-btn text-center w-100" (click)="redirectToListing()">Back to Listings</button>
      </div>
  </p-dialog>
</div>

<p-toast></p-toast>
