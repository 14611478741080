
<main class="documents-main">
<div class="app_details_tabs_content tabss__sectioNN__linee mb-2 z9999 stickyy-partt">
  <ul class="stepbox-list d-flex flex-row w-100 ps-0">
    <li
      [ngClass]="{ active: tabb === 'tabb1' }"
      class="stepbox-list__item position-relative fw500 text-center pointer"
    >
      Basic Information
    </li>
    <li
      [ngClass]="{ active: tabb === 'tabb3' }"
      class="stepbox-list__item position-relative fw500 text-center pointer"
    >
      Work Experience
    </li>
    <li
      [ngClass]="{ active: tabb === 'tabb2' }"
      class="stepbox-list__item position-relative fw500 text-center pointer"
    >
      Educational Information
    </li>
    <!-- <li [ngClass]="{ active: tabb === 'tabb4' }"
      class="stepbox-list__item position-relative fw500 text-center pointer">Family Information</li> -->
    <li
      [ngClass]="{ active: tabb === 'tabb5' }"
      class="stepbox-list__item position-relative fw500 text-center pointer"
    >
      Immigration
    </li>
    <!-- <li [ngClass]="{ active: tabb === 'tabb6' }"
      class="stepbox-list__item position-relative fw500 text-center pointer">Declaration</li> -->
    <li
      [ngClass]="{ active: tabb === 'tabb7' }"
      class="stepbox-list__item position-relative fw500 text-center pointer"
    >
      Documents
    </li>
  </ul>
</div>
<div class="documents-part app_details_tabs_content">
<form #documentsForm="ngForm">
  <h1 class="fw600 fs18 col-md-12" style="padding: 0px">Documents</h1>

  <!-- <button class="figma_background_blue_btn mt-3" type="button"
      (click)="openDocumentsSelects(); getDocumnetType($event)"  style="padding:16px 32px" [hidden]="documentEnablee && documentRender.length !== 0">
      Add Document
    </button> -->

  <!-- <div class="row"> -->
  <!-- <div *ngFor="let x of documentRender; let i = index">
      <div class="documentEnable row mt-4"> -->
  <!-- *ngIf="documentEnablee" -->
  <!-- <div class="col-md-4 figma__ng__select">
          <label for="selectDocTypee" class="document__type_css figma__label">Document Type: <span
              class="errorRequired">*</span></label>
          <ng-select class="" [items]="selectDocTypeArray" bindLabel="name" bindValue="id"
            placeholder="Select document type" [(ngModel)]="x.selectDocType" name="selectDocTypee{{i}}"
            #selectDocTypee="ngModel" (change)="docDocumentType($event)" required>
          </ng-select>
          <div *ngIf="
                (selectDocTypee.touched || documentsForm.submitted) &&
                selectDocTypee.errors?.required
              ">
            <span class="errorMessage">
              Please select document type
            </span>
          </div>
        </div> -->
  <!-- <div class="col-md-4 figma__ng__select">
          <label for="selectDocTypee" class="document__type_css figma__label">Document Name: <span
              class="errorRequired">*</span></label>
          <ng-select class="" [items]="selectDocNameArray" bindLabel="name" bindValue="id"
            placeholder="Select document name" [(ngModel)]="x.selectDocName" name="selectDocNamee{{i}}"
            #selectDocNamee="ngModel" required>
          </ng-select>
          <div *ngIf="
                (selectDocNamee.touched || documentsForm.submitted) &&
                selectDocNamee.errors?.required
              ">
            <span class="errorMessage">
              Please select document type
            </span>
          </div>
        </div>
        <div class="col-md-4 delete_can">
          <img src="./assets/img/trashcan.svg" (click)="deleteUploadDoc(i)"
            *ngIf="documentRender.length > 1" />
        </div>
        <div class="col-md-12 mt-4">

          <ngx-dropzone (change)="onselect1($event,x.selectDocName,i,x.selectDocType)">
            <ngx-dropzone-label>
              <div class="d-flex align-items-center">
                <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
              </div>
              <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
              <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
            </ngx-dropzone-label>
            <ngx-dropzone-preview *ngIf="newNewVisaFiles[i]?.documentText" [removable]="true"
              (removed)="onRemoveNewApplicant(f)">
              <ngx-dropzone-label>{{ postMultimediasone[0]?.name }}</ngx-dropzone-label>
            </ngx-dropzone-preview>
          </ngx-dropzone> -->
  <!-- <ngx-dropzone (change)="onselect1($event,x.selectDocName,i,x.selectDocType)">
            <ngx-dropzone-label>
              <div class="d-flex align-items-center">
                <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
                <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
              </div>
              <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
              <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
            </ngx-dropzone-label>
            <ngx-dropzone-preview *ngIf="newNewVisaFiles[i]?.name"  [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label>{{ newNewVisaFiles[i]?.name }}</ngx-dropzone-label>
            </ngx-dropzone-preview>
          </ngx-dropzone> -->
  <!-- </div>
        <button class="figma_background_blue_btn mt-3" type="button" (click)="addComponents()"
          style="padding:16px 32px" *ngIf="(i == documentRender.length - 1)">
          Add another document
        </button>
      </div>
    </div> -->

  <div class="col-md-12 mt-4" *ngIf="validatonPassport">
    <label
      class="figma__label ml-1 d-flex justify-content-center mb-3"
      style="margin-bottom: 14px !important"
    >
      <span class="fs16 fs600 mr-2"> Document Name : </span> Visa
      <span class="mandiatory">*</span>
    </label>
    <ngx-dropzone
      [accept]="'application/pdf'"
      (change)="onselect1($event, 'Visa', '', '')"
    >
      <ngx-dropzone-label>
        <div class="d-flex align-items-center">
          <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
          <h6 class="fw500 fs14 ml-3">Drag and drop the file</h6>
        </div>
        <span class="fw400 fs14 my-4" style="color: rgba(160, 160, 160, 1)">
          or
        </span>
        <h6 class="fw500 fs14 underlinee figma_blue_color">
          Choose file from your device
        </h6>
      </ngx-dropzone-label>
      <ngx-dropzone-preview
        *ngFor="let f of newdataappend"
        [removable]="true"
        (removed)="newNewPassportRemoveVisa(f)"
      >
        <ngx-dropzone-label style="width: 150px !important"
          >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
        >
      </ngx-dropzone-preview>
    </ngx-dropzone>
    <div *ngIf="uploadVisaFile">
      <span class="errorMessage"> Please upload document </span>
    </div>
  </div>

  <!-- with validation -->
  <div class="col-md-12 mt-3" *ngIf="validatonPassport">
    <label
      class="mb-3 figma__label ml-1 d-flex justify-content-center"
      style="margin-bottom: 14px !important"
    >
      <span class="fs16 fs600 mr-2"> Document Name : </span> Passport
      <span class="mandiatory">*</span>
    </label>
    <ngx-dropzone
      [accept]="'application/pdf'"
      (change)="onselect12($event, 'Passport', '', '')"
    >
      <ngx-dropzone-label>
        <div class="d-flex align-items-center">
          <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
          <h6 class="fw500 fs14 ml-3">Drag and drop the file</h6>
        </div>
        <span class="fw400 fs14 my-4" style="color: rgba(160, 160, 160, 1)">
          or
        </span>
        <h6 class="fw500 fs14 underlinee figma_blue_color">
          Choose file from your device
        </h6>
      </ngx-dropzone-label>
      <ngx-dropzone-preview
        *ngFor="let f of newdataappendpassport"
        [removable]="true"
        (removed)="newNewPassportRemovePassport(f)"
      >
        <ngx-dropzone-label style="width: 150px !important"
          >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
        >
      </ngx-dropzone-preview>
    </ngx-dropzone>
    <div *ngIf="uploadPassportFile">
      <span class="errorMessage"> Please upload document </span>
    </div>
  </div>
  <!-- with validation -->

  <!-- without validation -->
  <!-- <div class="col-md-12 mt-3" *ngIf="withoutValidationPassport">
        <label class="mb-3 figma__label ml-1 d-flex justify-content-center"
          style="margin-bottom: 14px !important;">
          <span class="fs16 fs600 mr-2"> Document Name : </span> Passport </label>
        <ngx-dropzone (change)="onselect12($event,'Passport','','')">
          <ngx-dropzone-label>
            <div class="d-flex align-items-center">
              <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
              <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
            </div>
            <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
            <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of newdataappendpassport" [removable]="true"
            (removed)="newNewPassportRemovePassport(f)">
            <ngx-dropzone-label style="width: 150px !important;">{{ f.name }} ({{ f.type
              }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div> -->
  <!-- without validation -->

  <ng-container>
    <!-- *ngIf="newImmigrationInfoo.NewProfileWorkPermit !== 'addAppNo'" -->
    <div class="col-md-12 mt-5 relative" *ngFor="let doclist of documentList">
      <!-- <label class="mb-2 figma__label ml-1 fs16 fs600 absolute" style="top:24px">
            Document Type : <span class="figma__label">
              {{doclist.documentType}} </span> </label> -->
      <div *ngFor="let sublist of doclist.applicantDocumentNames">
        <!-- <label class="mb-2 col-md-7 align-items-center figma__label ml-1 my-4 fs16 fs600 d-flex">
              Document Type : <span class="figma__label">
            <label class="mb-2 col-md-7 align-items-center figma__label pl-0 my-4 fs16 fs600 d-flex align-items-center">
             <p>  Document Type : </p> <span class="fs12 fw600 ml-1">
                {{doclist.documentType}} </span>
                <p class="align-items-center ml-auto d-flex"> Document Name : <span class="fs12 fw600 ml-1">
                  {{sublist.documentName}} </span> </p>
             </label> -->
        <label
          class="mb-2 align-items-center figma__label pl-0 my-4 fs16 fs600 d-flex align-items-center justify-content-between"
          *ngIf="sublist != '' && sublist != undefined && sublist != null"
        >
          <div class="align-items-center d-flex">
            <p>Document Type :</p>
            <span class="fs12 fw600 ml-1">{{ doclist.documentType }} </span>
            <span class="mandiatory">*</span>
          </div>
          <div class="">
            <p class="align-items-center d-flex" style="margin-left: 100px">
              Document Name :
              <span class="fs12 fw600 ml-1">
                {{ sublist.documentName }}
              </span>
            </p>
          </div>
        </label>

        <ngx-dropzone
          [accept]="'application/pdf'"
          style="margin-top: 10px"
          (change)="
            onselect(
              $event,
              sublist.documentName,
              sublist.documentNameId,

              doclist.documentTypeId,
              sublist.applicantDocumentId
            )
          "
        >
          <ngx-dropzone-label>
            <div class="d-flex align-items-center">
              <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
              <h6 class="fw500 fs14 ml-3">Drag and drop the file</h6>
            </div>
            <span class="fw400 fs14 my-4" style="color: rgba(160, 160, 160, 1)">
              or
            </span>
            <h6 class="fw500 fs14 underlinee figma_blue_color">
              Choose file from your device
            </h6>
          </ngx-dropzone-label>

          <ng-container *ngFor="let f of files">
            <ng-container *ngFor="let fi of Object?.keys(f)">
              <ng-container
                *ngIf="
                  f.docid == doclist.documentTypeId &&
                  sublist.documentNameId == f.documentNameId
                "
              >
                <ngx-dropzone-preview
                  *ngFor="let actualFile of f[fi]"
                  [file]="actualFile"
                  [removable]="true"
                  (removed)="
                    newNewPassportRemove(actualFile, sublist.documentNameId)
                  "
                >
                  <ngx-dropzone-label style="width: 100px !important"
                    >{{ actualFile.name }}({{
                      actualFile.type
                    }})</ngx-dropzone-label
                  >
                </ngx-dropzone-preview>
              </ng-container>
            </ng-container>
          </ng-container>
        </ngx-dropzone>
      </div>

      <div *ngIf="uploadAlldocsFile">
        <span class="errorMessage"> Please upload document </span>
      </div>
    </div>
  </ng-container>
  <!-- <div class="col-md-12 mt-3">
        <label class="mb-2 figma__label ml-1"> Employment Letter: </label>
        <ngx-dropzone (change)="newNewEmpLetterSelect($event)">
          <ngx-dropzone-label>
            <div class="d-flex align-items-center">
              <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
              <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
            </div>
            <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
            <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of newNewEmpLetterFiles" [removable]="true"
            (removed)="newNewEmpLetterRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div> -->

  <!-- <div class="col-md-12 mt-3">
        <label class="mb-2 figma__label ml-1"> 3 months Salary Slip: </label>
        <ngx-dropzone (change)="newNewSalarySlipSelect($event)">
          <ngx-dropzone-label>
            <div class="d-flex align-items-center">
              <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
              <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
            </div>
            <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
            <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of newNeewSalarySlipFiles" [removable]="true"
            (removed)="newNewSalarySlipRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div> -->

  <!-- <div class="col-md-12 mt-3">
        <label class="mb-2 figma__label ml-1"> Reliving Letter: </label>
        <ngx-dropzone (change)="newNewRelivingLetterSelect($event)">
          <ngx-dropzone-label>
            <div class="d-flex align-items-center">
              <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
              <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
            </div>
            <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
            <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of newnewRelivingLetterFiles" [removable]="true"
            (removed)="newNewRelivingLetterRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div> -->

  <!-- <div class="col-md-12 mt-3">
        <label class="mb-2 figma__label ml-1"> Bank Statement: </label>
        <ngx-dropzone (change)="newNewBankStatementSelect($event)">
          <ngx-dropzone-label>
            <div class="d-flex align-items-center">
              <img src="./assets/img/upload-cloud-02.svg" alt="upload" />
              <h6 class="fw500 fs14 ml-3"> Drag and drop the file </h6>
            </div>
            <span class="fw400 fs14 my-4" style="color:rgba(160, 160, 160, 1)"> or </span>
            <h6 class="fw500 fs14 underlinee figma_blue_color"> Choose file from your device </h6>
          </ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let f of newNewBankStatementFiles" [removable]="true"
            (removed)="newNewBankStatementRemove(f)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div> -->

  <!-- <button class="figma_background_blue_btn mt-4 ml-3" style="padding:12px 30px"> + Add another
        document </button> -->

  <div class="col-md-12 mt-4 pl-0">
    <button
      type="button"
      class="figma_blue_button"
      (click)="newBackApp('DocumentsBack')"
    >
      <i class="icon-angle-down angle-rotate-d"></i> Back
    </button>
    <button
      type="button"
      class="figma_background_blue_btn float-right"
      style="padding: 12px 42px"
      (click)="saveAndnextt('Documents')"
      [disabled]="newApplicantSubmitDisabled"
    >
      Save & Submit
    </button>
  </div>
  <!-- </div> -->
</form>
</div>
</main>

