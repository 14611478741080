<!--START: Login BANNER-->
<section class="login p-0">
  <div class="row">
    <div class="col-sm-5 left-login-section"><img class="login-logo"
        src="{{ appSettings.imageUrl }}/img/login/login-logo.png" alt="logo" />
      <h3 class="mobile-title">myGIIS Parent</h3>

      <a class="mt-3" style="position: relative; left: -200px;"><img src="{{ appSettings.imageUrl }}/img/GiisLogo.png"
        alt="GIIS-Logo" /></a>

      <h3 class="title title--desktop-title login__title mt-3" style="font-weight:600">Welcome to myGSF Careers
      <!-- <span class="fs14 fw600 login__spannn"> (For Interview Slot Creation) </span> -->
    </h3>
      <h6 class="title title--sub">Login for an experience tailored to you</h6>
      <form class="top" novalidate #scheduleInterviewForm="ngForm" novalidate
        (ngSubmit)="scheduleInterviewForm.form.valid">
        <div class="form-group floating-label-group loginMb_5">
          <input class="form-control" id="username" type="text" autocomplete="off" required [(ngModel)]="scheduleLoginData.name"
            name="email" #email="ngModel" style="color: black !important" />
          <label class="floating-label">Enter myGSF ID or Email</label>
        </div>
        <div style="text-align: left;" *ngIf="(email.touched || scheduleInterviewForm.submitted) && email.errors?.required">
          <span class="errorMessage" style="color: red;">
            Email is required
          </span>
        </div>
        <div class="form-group floating-label-group loginMb_5">
          <input class="form-control" id="password" required type="password"
            [(ngModel)]="scheduleLoginData.password" name="password" #password="ngModel" autocomplete="off" style="color: black !important"/>
          <label class="floating-label">Enter Password</label>
          <span class="p-viewer">
            <i class="fa fa-eye" *ngIf="fieldTextType == true" aria-hidden="true" style="color:white;margin-left:-24px"
              (click)="toggleFieldTextType()"></i>
            <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="fieldTextType == false"
              style="color:white;margin-left:-24px;" (click)="toggleFieldTextType()"></i>
          </span>
        </div>

        <div style="text-align: left;" *ngIf="(password.touched || scheduleInterviewForm.submitted) && password.errors?.required">
          <span class="errorMessage" style="color: red;">
            Password is required
          </span>
        </div>
        <!-- <div class="field">
          <div class="ui fluid selection dropdown form-dropdown form-input form-group floating-label-group">
            <input class="form-control" type="hidden" name="ty-resident"/><i class="dropdown dropdown-icon icon-angle-down dropdown-icon--gry"></i>
            <div class="default text text-drop drp-plachlder">Select Campus</div>
            <div class="menu no-border">
              <div class="item" data-value="af"> Campus 1</div>
              <div class="item" data-value="ax"> Campus 2</div>
              <div class="item" data-value="al"> Campus 3</div>
              <div class="item" data-value="dz"> Campus 4</div>
            </div>
          </div>
        </div> -->
        <div class="" style="margin-top:14px;">
          <input type="checkbox" tabindex="0" />
          <label class="option-label fnt-xs checkbox-label"
          style="margin-top:-5px; color: black !important;">Remember me</label>
        </div>
        <div style="text-align: left;" *ngIf="errormMsg">
          <span class="errorMessage" style="color: red;">
            Invalid email or password or User may be Inactive
          </span>
        </div>
        <button class="btn btn-primary login-btn" (click)="ScheduleeLogin()" >LOGIN</button>
        <!-- <button class="btn btn-primary login-btn" >LOGIN</button> -->

        <p style="color:black;cursor:pointer;margin-top:5px;" data-target="#reset-model" data-toggle="modal"><span
            style="position:cursor;">Forgot Password</span></p>
      </form>
      <!-- <div class="forgot d-flex">
        <button class="btn btn-log btn-log--frgt text-left">Forgot Password?</button>
        <button class="btn btn-log btn-log--skp p-0">Skip</button>
      </div> -->
      <!-- <h3 class="title title--grid-title">Other myGIIS apps</h3>
      <div class="container product-section">
        <div class="row product-container">
          <div class="col-sm-4 product-column"><span class="product"> <img class="product-image product-image--one"
                src="{{ appSettings.imageUrl }}/img/login/my-giis.svg" alt="product" /></span>
            <h3 class="title title--product-title">myGIIS </h3>
          </div>
          <div class="col-sm-4 product-column"><span class="product"> <img class="product-image product-image--two"
                src="{{ appSettings.imageUrl }}/img/login/news.svg" alt="product" /></span>
            <h3 class="title title--product-title">myGIIS News </h3>
          </div>
          <div class="col-sm-4 product-column"><span class="product"> <img class="product-image product-image--three"
                src="{{ appSettings.imageUrl }}/img/login/giis-books.svg" alt="product" /></span>
            <h3 class="title title--product-title">myGIIS Books</h3>
          </div>
        </div>
      </div> -->
      <!-- <div class="row text-center align-content-center justify-content-center my-auto">
        <h3 class="available-on">available on</h3><img class="download-icon download-icon--apple"
          src="{{ appSettings.imageUrl }}/img/login/apple.png" alt="apple" /><img
          class="download-icon download-icon--android" src="{{ appSettings.imageUrl }}/img/login/android.png"
          alt="android" />
      </div> -->
    </div>
    <div class="col-sm-7 login-right-section">
      <div class="banner"><img class="desktop-logo" src="{{ appSettings.imageUrl }}/img/login/login-logo.png"
          alt="logo" /><img class="login-right-vector"
          src="{{ appSettings.imageUrl }}/img/login/login-right-vector.png" alt="vector" /></div><img
        class="gsf-logo" src="{{ appSettings.imageUrl }}/img/login/gsf-logo.png" alt="logo" />
      <!-- <p class="check-out">Checkout the latest GIIS articles on <span class="global-news"> <a>Global School
            News</a></span></p> -->
      <div class="footer-links"><a class="footer-link">Privacy Statement</a><a class="footer-link border-lr">Terms &
          Conditions</a><a class="footer-link">Disclaimer</a></div>
      <h6 class="copy-right">Copyright © 2023 Global Schools Foundation. All rights reserved. </h6>
    </div>
    <div class="close-modal modal fade" id="reset-model" tabindex="-1" role="dialog" aria-labelledby="reactivateTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content close-modal__content delete-model-content">
          <div class="modal-header d-flex flex-column p-0 border-0 mb-3">
            <h5 class="close-modal__h5">Do you want to send the reset link to the ID ?</h5>
          </div>
          <form novalidate #filterInfo="ngForm" novalidate>
            <div class="col-md-6 field form-group-d">
              <label class="form-label-d" style="margin-left:-85px;">Email ID</label>
              <input type="text" class="form-input" name="forgotEmail" placeholder="Enter the Email ID"
                style="width:330px" [(ngModel)]="forgotEmail" #ddlforgotEmail="ngModel"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
              <div *ngIf="ddlforgotEmail.errors" style="margin-left:-67px;">
                <span class="errorMessage" *ngIf="ddlforgotEmail.errors?.pattern">
                  Invalid email
                </span>
              </div>
              <div *ngIf="(ddlforgotEmail.touched || filterInfo.submitted) && ddlforgotEmail.errors?.required">
                <span class="errorMessage">
                  Please enter Email Id
                </span>
              </div>
            </div>
            <!-- <div class="modal-footer d-flex justify-content-between border-0 p-0 mt-2">
              <button class="btn-blue btn-yesno" type="button">Send</button>
              <button class="btn-blue-border btn-yesno" type="button" data-dismiss="modal"
                (click)="cancel()">Cancel</button>
            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
