<div class="backbuton">
  <img (click)="gotojobListingpage()" src="./assets/Images/create-arrow.svg" alt="">
  <span class="backtolistpade">Create Job Posting</span>
</div>


<div class="job-description-main-section">
  <app-stepper></app-stepper>

  <div class="border-class">

    <h1 class="fw600 fs16"> Overview </h1>
    <div class="line"></div>


    <div class="editor">
      <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
      <p-editor [(ngModel)]="textControl" [style]="{ height: '150px' }"></p-editor>
    </div>

  </div>

  <div class="border-class">

    <h1 class="fw600 fs16"> Responsibilities </h1>
    <div class="line"></div>

    
    <div class="editor">
      <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
      <p-editor [(ngModel)]="textControl" [style]="{ height: '150px' }"></p-editor>
    </div>

  </div>


  <div class="border-class">

    <h1 class="fw600 fs16"> Skill Description </h1>
    <div class="line"></div>

   
    <div class="editor">
      <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
      <p-editor [(ngModel)]="textControl" [style]="{ height: '150px' }"></p-editor>
    </div>

  </div>


  <div class="border-class">

    <h1 class="fw600 fs16"> Website Short Description </h1>
    <div class="line"></div>

    
    <div class="editor">
      <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
      <p-editor [(ngModel)]="textControl" [style]="{ height: '150px' }"></p-editor>
    </div>

  </div>


  <div class="border-class">

    <h1 class="fw600 fs16">Disclaimer </h1>
    <div class="line"></div>


    <div class="editor">
      <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
      <p-editor [(ngModel)]="textControl" [style]="{ height: '150px' }"></p-editor>
    </div>

  </div>

  <div class="border-class">

    <h1 class="fw600 fs16">Responsibilities</h1>
    <div class="line"></div>


    <div class="editor">
      <!-- <p-editor formControlName="text" [style]="{ height: '320px' }"></p-editor> -->
      <p-editor [(ngModel)]="textControl" [style]="{ height: '150px' }"></p-editor>
    </div>

  </div>

  <div class="border-class">
    <button class="secondary-btn me-3" (click)="gotojobListingpage()">Back</button>

    <button class="blue-btn" (click)="gotoJobDescription()">Next</button>

  </div>

</div>
