<main class="hiring-team-main">
  <div
    class="basic-info-line-part white-background p16 br8 d-flex align-items-center justify-content-between"
  >
    <h1 class="blue-color fs14 fw600">Step 4 - Hiring Team</h1>
    <div class="d-flex align-items-center">
      <h2>Marketing Manager</h2>
      <div class="job-id-part d-flex align-items-center gap16">
        <img src="./assets/Images/approval.svg" alt="approval" />
        <h3>Job ID- A01232</h3>
      </div>
    </div>
  </div>
  <div class="job-info-inputs-part white-background p16 br8 mt-3">
    <h1 class="fw600 fs16">Recruitment Members</h1>
    <div class="line"></div>
    <form [formGroup]="hiringTeamForm">
      <div class="job-info-inputs">
        <div class="figma-ng-select">
          <label> Hiring Team Member </label>
          <ng-select
            id="hiringTeamMember"
            formControlName="hiringTeamMember"
            placeholder="Select hiring team member"
            [items]="hiringTeamMembersItems"
            bindLabel="name"
            bindValue="id"
          >
          </ng-select>
        </div>

        <div class="figma-ng-select">
          <label> Hiring Stage </label>
          <ng-select
            id="hiringHiringStage"
            formControlName="hiringHiringStage"
            placeholder="Select hiring stage"
            [items]="hiringTeamStageItems"
            bindLabel="name"
            bindValue="id"
          >
          </ng-select>
        </div>

        <div class="figma-ng-select">
          <label> Interview Template </label>
          <ng-select
            id="hiringInterviewTemplate"
            formControlName="hiringInterviewTemplate"
            placeholder="Select interview template"
            [items]="hiringTeamInterviewTemplateItems"
            bindLabel="name"
            bindValue="id"
          >
          </ng-select>
        </div>

        <div class="figma-calender">
          <label> Cut Off Date </label>
          <p-calendar
            formControlName="hiringTeamCutOffDate"
            placeholder="Select cutoff date"
            [showIcon]="true"
          ></p-calendar>
        </div>

        <div class="figma-ng-select">
          <label> Type of Interview </label>
          <ng-select
            id="hiringInterviewType"
            formControlName="hiringInterviewType"
            placeholder="Select type of interview"
            [items]="hiringTeamInterviewItems"
            bindLabel="name"
            bindValue="id"
          >
          </ng-select>
        </div>
        <div class=""></div>
        <!--  routerLink="../job-approvers" -->
        <div>
          <button type="button" class="blue-btn" (click)="hiringAddToList()">
            Add to List
          </button>
        </div>
        <div class="figma-modal">
          <!-- <button
            type="button"
            class="blue-btn"
            (click)="showModalDialog()"
            icon="pi pi-external-link"
            label=""
          >
            Add other Members
          </button> -->
          <p-dialog
            header="Add Other Members"
            [(visible)]="displayModal"
            [modal]="true"
            [style]="{ width: '76vw' }"
            [baseZIndex]="10000"
            [draggable]="false"
            [resizable]="false"
          >
            <div class="line mt-0 mb-4"></div>

            <section
              class="add-other-members-main type-of-hiring-card white-background p16 br8 mt-3"
            >
              <form
                [formGroup]="otherMembersForm"
                (ngSubmit)="otherMembersFormSubmit()"
              >
                <div class="job-info-inputs">
                  <div class="figma-ng-select">
                    <label> Hiring Team Member </label>
                    <ng-select
                      id="hiringTeamMemberTwo"
                      formControlName="hiringTeamMemberTwo"
                      placeholder="Select hiring team member"
                      [items]="hiringTeamMembersItems"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                  </div>

                  <div class="figma-ng-select">
                    <label> Hiring Stage </label>
                    <ng-select
                      id="hiringHiringStageTwo"
                      formControlName="hiringHiringStageTwo"
                      placeholder="Select hiring stage"
                      [items]="hiringTeamStageItems"
                      bindLabel="name"
                      bindValue="id"
                    >
                    </ng-select>
                  </div>
                  <div class="figma-calender">
                    <label> Cut Off Date </label>
                    <p-calendar
                      id="hiringTeamCutOffDateTwo"
                      formControlName="hiringTeamCutOffDateTwo"
                      placeholder="Select cutoff date"
                      [showIcon]="true"
                    ></p-calendar>
                  </div>
                  <div>
                    <button type="submit" class="blue-btn">Add to List</button>
                  </div>
                </div>
              </form>
            </section>

            <div class="type-of-hiring-card white-background p16 br8 mt-3">
              <div class="other-members-table">
                <table class="table figma-table mt-2">
                  <thead>
                    <tr>
                      <th scope="col" class="fw600 fs14 py-4">
                        S.No
                        <img
                          class="ms-2"
                          src="../../../../assets/Images/sort-icons.svg"
                          alt="sort iocns"
                        />
                      </th>
                      <th scope="col" class="fw600 fs14 py-4">
                        Name
                        <img
                          class="ms-2"
                          src="../../../../assets/Images/sort-icons.svg"
                          alt="sort iocns"
                        />
                      </th>
                      <th scope="col" class="fw600 fs14 py-4">
                        Hiring stage
                        <img
                          class="ms-2"
                          src="../../../../assets/Images/sort-icons.svg"
                          alt="sort iocns"
                        />
                      </th>
                      <th scope="col" class="fw600 fs14 py-4">
                        Cut-off date
                        <img
                          class="ms-2"
                          src="../../../../assets/Images/sort-icons.svg"
                          alt="sort iocns"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <div style="border-bottom: 8px solid transparent"></div>
                    <tr>
                      <td scope="row" class="fw400 fs14 py-3 ps-3"></td>
                      <td class="fw400 fs14 py-3">
                        {{ otherMembersFormData?.hiringTeamMemberTwo }}
                      </td>
                      <td class="fw400 fs14 py-3">
                        {{ otherMembersFormData?.hiringHiringStageTwo }}
                      </td>
                      <td class="fw400 fs14 py-3">
                        {{ otherMembersFormData?.hiringTeamCutOffDateTwo }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="line"></div>
            <button type="button" class="blue-btn">Submit</button>
            <!-- <ng-template pTemplate="footer">
            <p-button
              icon="pi pi-check"
              (click)="displayModal = false"
              label="Yes"
              class="p-button-text"
            ></p-button>
            <p-button
              icon="pi pi-times"
              (click)="displayModal = false"
              label="No"
            ></p-button>
          </ng-template> -->
          </p-dialog>
        </div>
      </div>
    </form>
  </div>

  <ng-container *ngIf="hiringTeamTableArray.length">
    <table class="table figma-table mt-2">
      <thead>
        <tr>
          <th scope="col" class="fw600 fs14 py-4">
            S.No
            <img
              class="ms-2"
              src="../../../../assets/Images/sort-icons.svg"
              alt="sort iocns"
            />
          </th>
          <th scope="col" class="fw600 fs14 py-4">
            Name
            <img
              class="ms-2"
              src="../../../../assets/Images/sort-icons.svg"
              alt="sort iocns"
            />
          </th>
          <th scope="col" class="fw600 fs14 py-4">
            Hiring stage
            <img
              class="ms-2"
              src="../../../../assets/Images/sort-icons.svg"
              alt="sort iocns"
            />
          </th>
          <th scope="col" class="fw600 fs14 py-4">
            Template
            <img
              class="ms-2"
              src="../../../../assets/Images/sort-icons.svg"
              alt="sort iocns"
            />
          </th>
          <th scope="col" class="fw600 fs14 py-4">
            Cut-off date
            <img
              class="ms-2"
              src="../../../../assets/Images/sort-icons.svg"
              alt="sort iocns"
            />
          </th>
          <th scope="col" class="fw600 fs14 py-4">
            Interview Type
            <img
              class="ms-2"
              src="../../../../assets/Images/sort-icons.svg"
              alt="sort iocns"
            />
          </th>
          <th scope="col" class="fw600 fs14 py-4">
            Group Discussion
            <img
              class="ms-2"
              src="../../../../assets/Images/sort-icons.svg"
              alt="sort iocns"
            />
          </th>
        </tr>
      </thead>
      <div style="border-bottom: 8px solid transparent"></div>
      <tbody *ngFor="let x of hiringTeamTableArray; let i = index">
        <tr>
          <td scope="row" class="fw400 fs14 py-3 ps-3">{{ i + 1 }}</td>
          <td class="fw400 fs14 py-3">{{ f.hiringTeamMember.value }}</td>
          <td class="fw400 fs14 py-3">{{ f.hiringHiringStage.value }}</td>
          <td class="fw400 fs14 py-3">{{ f.hiringInterviewTemplate.value }}</td>
          <td class="fw400 fs14 py-3">{{ f.hiringTeamCutOffDate.value }}</td>
          <td class="fw400 fs14 py-3">{{ f.hiringInterviewType.value }}</td>
          <td class="fw400 fs14 py-3">
            <input type="checkbox" name="hiringGroupDiscussion" />
          </td>
        </tr>
        <div style="border-bottom: 1px solid #f5f5f5"></div>
      </tbody>
    </table>
  </ng-container>
  <!-- <div
    class="type-of-hiring-card white-background p16 br8 my-3 d-flex align-items-center justify-content-between"
  >
    <div class="hiring-stages-checkbox d-flex align-items-center">
      <input
        type="checkbox"
        name="hiringStagesAddedCheckbox"
        id="hiringStagesAddedCheckbox"
      />
      <p class="fw600 fs14 ms-2">Are all Hiring Stages Added?</p>
    </div>
    <div class="gap16 d-flex justify-content-end">
      <button class="secondary-btn" routerLink="../job-approvers">
        previous
      </button>
      <button
        class="blue-btn"
        (click)="toggleHiringTeamSubmitModal()"
        icon="pi pi-external-link"
        label=""
      >
        Submit
      </button>
      <p-dialog
        header="Add Other Members"
        [(visible)]="hiringTeamSubmitModal"
        [modal]="true"
        [style]="{ width: '76vw' }"
        [baseZIndex]="10000"
        [draggable]="false"
        [resizable]="false"
      >
      </p-dialog>
    </div>
  </div> -->
  <div class="type-of-hiring-card white-background p16 br8 my-3 gap16 d-flex justify-content-end">
    <button class="secondary-btn" routerLink="../job-approvers">previous</button>
    <button class="blue-btn" (click)="showSurePostDialog()">Next</button>
  </div>

  <!-- submit post modal -->
  <section class="submit-post-modal-main text-center">
  <p-dialog [(visible)]="surePostModalVisible" [modal]="true" [style]="{ width: '31vw' }" [draggable]="false" [resizable]="false">
    <div class="submit-job-postt">
      <img *ngIf="postScreenOne" src="../../../../assets/Images/submit-job-pic-1.svg" alt="submit job" />
      <img *ngIf="postScreenTwo" src="../../../../assets/Images/submit-job-pic-2.svg" alt="submit job" />
      <div class="submit-job-buttons-part">
        <h1 class="fw700 fs20 my-4"> Are you sure you want to Post the Job? </h1>
        <div class="d-flex align-items-center justify-content-between" *ngIf="postScreenOne">
          <button type="button" class="secondary-btn" (click)="postScreenYes()"> Yes </button>
          <button type="button" class="blue-btn submit-post-yes-button" (click)="postScreenNo()"> No, Check Details </button>
        </div>
        <button type="button" class="blue-btn submit-post-yes-button" *ngIf="postScreenTwo" [routerLink]="'/job-details/basic-information'"> Back to dashboard </button>
      </div>
    </div>
</p-dialog>
</section>
  <!-- submit post modal -->

</main>
<div class="hiring-team-toaster">
<p-toast></p-toast>
</div>
