import { Component } from '@angular/core';

@Component({
  selector: 'app-create-job-layout',
  templateUrl: './create-job-layout.component.html',
  styleUrls: ['./create-job-layout.component.scss']
})
export class CreateJobLayoutComponent {

  gotojobListingpage(){
    window.location.href='/job-listing'
  }
  activeIndex = 0;

  onNextClicked() {
    console.log("Next clicked in parent");
    if (this.activeIndex < 4) { 
        this.activeIndex++;
    } else {
        this.activeIndex = 0;
    }
}

}
