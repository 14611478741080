import { HttpClient } from '@angular/common/http';
import { ParsedVariable } from '@angular/compiler';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { userService } from 'src/app/services/userservice';

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  NgForm,
  NgModel,
} from '@angular/forms';
import { Router, RouterLink, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

import { jwtDecode } from 'jwt-decode';
import * as moment from 'moment';
import { Editor, Toolbar } from 'ngx-editor';
import { MessageService } from 'primeng/api';
import { IJPService } from 'src/app/services/IJP.service';
import { ReportsService } from 'src/app/services/reports.service';
import { HeaderComponent } from 'src/app/layout/header/header.component';

@Component({
  selector: 'app-create-job-post',
  templateUrl: './create-job-post.component.html',
  styleUrls: ['./create-job-post.component.scss'],
  providers: [MessageService,HeaderComponent]
})
export class CreateJobPostComponent implements OnInit {
  stepsArray: any = ['1', '2', '3', '4', '5',]
  stepsArray1: any = ['1', '2', '3', '4', '5']

  userId: any;
  brandId: any;
  userDetails: any;
  trfJobId: any;
  trfcampus: any;
  conuntry_data: any;
  brandInfo: any;
  trfBrand: any;
  c_camp: any;
  trfpostJob: any;
  formData: any;
  id: any;
  employeeIddd: any;
  employeeList: any = {};
  positions: any;
  appointments: any[] = [];
  dataemp: any = [];
  minPublishdate = new Date();
  minStartDate = new Date();
  minPublishdateCutOff: any;
  edate1: any;
  hiring_date1: any;
  selectedJobPostIds: number[] = [];
  selectedJob: any;
  newCutoffdate: any;
  brandData: any;
  ijpBasicInformationId: number = 0;
  ijpJobDescriptionId: number = 0;
  userRoleList: any;
  Interview_list: any;
  eligibleCountryyyy: any;
  eligibleCountry: any;
  campusInfo: any;
  eligibleCampussss: any;
  eligibleDepartments: any;
  // eligibleDepartment: any;
  eligibleRolesss: any;
  displayedItems: any;
  showSelectedNames: any;
  eligibleBrandss: any;
  memberName: any;
  applicantApproval: boolean = false;
  displayToApplicants: boolean = false;
  level: any;
  i: any;
  subId: any;
  sub: any;
  edId: any;
  document_Type: any = [];
  campusInfoData: any;
  countryInfoData: any;
  brandInfoData: any;
  jobtitleData: any;
  trInfo: any;
  deptNameId: any;
  hiring_Data: any;
  postIds: any;
  budOrNonBud: any;
  docJr: any;
  matchingDoc: any;
  preferredAgeBarFrom: any;
  // targetAudienceForm: boolean;
  // employeeTable: any;




  getWorkPermitsByCountryId($event: any) {
    throw new Error('Method not implemented.');
  }
  getGetSkillNameById($event: any) {
    throw new Error('Method not implemented.');
  }
  trid: any;
  empCtcApprover: any;
  cutOffDate: any;
  trfdata($event: any) {
    throw new Error('Method not implemented.');
  }
  visibleSuccess: boolean = false;
  mySchool: any;
  basicInfoData: any = {};
  typeofdurationn: any;
  activeStepIndex: number = 0;
  stepChange: any;
  steps: any;
  preferedindustryArr: any;
  // totalExperienceArr: any;
  components3: any;
  components4: any;
  noticePeriodOptions: any;

  // isStepCompleted(index: number): boolean {
  //   return index < this.activeStepIndex;
  // }

  activeStep(): string {
    return [
      'Step 1 - Basic Information',
      'Step 2 - Job Description',
      'Step 3 - Hiring Team',
      'Step 4 - Target Audience',
      'Step 5 - Program Approvers',
      'Step 6 - Referral',

    ][this.activeStepIndex];
  }
  @ViewChild('createJobForm') createJobForm: any;
  @ViewChild('createJobDescriptionForm') createJobDescriptionForm: any;
  @ViewChild('createJobApproversForm') createJobApproversForm: any;
  @ViewChild('targetAudienceForm') targetAudienceForm: any;
  @ViewChild('progrmaApproversForm') progrmaApproversForm: any;
  @ViewChild('referralForm') referralForm: any;
  stepChecked: boolean[] = [false, false, false, false, false, false];

  nextStep(step: string) {
    // alert(step)

    switch (step) {
      case 'first':
        if (this.createJobForm?.form?.valid) {
          this.PostCreateJob();
          // this.incrementStep();
          // this.createJobForm.form.markAllAsTouched();
          this.stepChecked[0] = true;
          window.scrollTo(0, 0);

          this.IJPService.EditIJP(this.userId).subscribe((res) => {
            if (res) {
              this.activeStepIndex = 1;
            }
            else {
              this.activeStepIndex = 0;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something Went Wrong' });
            }

            if (res != 'Reporting Manager not found') {
              this.postjobtoDetail = res?.ijpBasicInformation?.ijpJobPostedData
              // postjobto
              for (var n = 0; n < this.postjobtoDetail?.length; n++) {
                if (this.postjobtoDetail[n]?.postjobto == "Referral Campaign") {

                  this.referalscreen = true
                }

              }

            }
          })


        }else{
          if (this.createJobForm.invalid) {
            this.createJobForm.form.markAllAsTouched();
            this.scrollToFirstInvalidControl();
            
            // console.log('form is invalid');
            return;
          } 
        }
        break;
      case 'second':
        if (this.createJobDescriptionForm?.form?.valid) {

          this.descriptionInfo();
          this.GetSkillsCategory();

          // this.incrementStep();
          // this.createJobDescriptionForm.form.markAllAsTouched();
          this.stepChecked[1] = true;
          window.scrollTo(0, 0);

          this.IJPService.GetSkillsCategory().subscribe((res: any) => {
            this.skillcategroy = res;
          });
        } else{
          if (this.createJobDescriptionForm.invalid) {
            this.createJobDescriptionForm.form.markAllAsTouched();
            this.scrollToFirstInvalidControl();
            
            // console.log('form is invalid');
            return;
          } 
        }
        break;
      case 'third':
        if (this.teamArray.length) {
          // this.activeStepIndex = 3;
          this.createJobApproversForm.form.markAllAsTouched();
          this.next();
          this.stepChecked[2] = true;
        }
        break;
      case 'fourth':
        if (this.targetAudienceForm?.form?.valid) {
          // this.incrementStep();
          this.targetAudienceForm.form.markAllAsTouched();
          this.postIJPTargetAudience();
          this.stepChecked[3] = true;
        }else{
          if (this.targetAudienceForm.invalid) {
            this.targetAudienceForm.form.markAllAsTouched();
            this.scrollToFirstInvalidControl();
            
            // console.log('form is invalid');
            return;
          } 
        }
        break;
      case 'fifth':
        if (this.progrmaApproversForm?.form?.valid) {
          this.incrementStep();
          this.showSuccess();
          this.progrmaApproversForm.form.markAllAsTouched();
          // this.PostIJPJobApprovers();
          this.stepChecked[4] = true;
        }else{
          if (this.progrmaApproversForm.invalid) {
            this.progrmaApproversForm.form.markAllAsTouched();
            this.scrollToFirstInvalidControl();
            
            // console.log('form is invalid');
            return;
          } 
        }
        break;
      case 'sixth':
        if (this.referralForm?.form?.valid) {
          this.incrementStep();
          this.referralForm.form.markAllAsTouched();
          this.stepChecked[5] = true;
        }
        break;
    }

    // Set previous steps as completed
    for (let i = 0; i < this.activeStepIndex; i++) {
      this.stepChecked[i] = true;
    }

    // window.scrollTo(0, 0);
  }
  @ViewChildren(NgModel) formControls: QueryList<NgModel>;
  @ViewChildren(NgModel, { read: ElementRef }) formElements: QueryList<ElementRef>;
  private scrollToFirstInvalidControl() {
    const invalidControlIndex = this.formControls.toArray().findIndex(control => control.invalid);

    if (invalidControlIndex !== -1) {
      const firstInvalidControl: HTMLElement = this.formElements.toArray()[invalidControlIndex].nativeElement;
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      firstInvalidControl.focus();
    }
  }
  isFieldRequired(): boolean {
    return this.addjobsDatesArray.length === 0;
  }



  setActiveStepIndex(index: number) {
    if (index >= 0 && index < this.stepChecked.length && this.stepChecked[index]) {
      this.activeStepIndex = index;
    }
  }

  isStepCompleted(index: number): boolean {
    return this.stepChecked[index];
  }

  private incrementStep() {
    if (this.activeStepIndex < 4) {
      this.activeStepIndex++;
    }
  }
  showRadioError: boolean = false;
  showSuccess() {

    this.visibleSuccess = true;

    // this.referalscreenrefence()



    // if (!this.applicantApproval) {
    //   this.showRadioError = true;
    //   this.visibleSuccess = false;
    // }
    // else{
    //   this.showRadioError = false;
    //   this.visibleSuccess = true;
    //   setTimeout(() => {
    //     this.showRadioError = false;
    //   }, 2000);
    // }
  }


  referalscreenrefence() {

    this.IJPService.EditIJP(this.userId).subscribe((res) => {

      if (res != 'Reporting Manager not found') {
        this.postjobtoDetail = res?.ijpBasicInformation?.ijpJobPostedData
        // postjobto
        for (var n = 0; n < this.postjobtoDetail?.length; n++) {
          if (this.postjobtoDetail[n]?.postjobto == "Referral Campaign") {

            this.referalscreen = true
          }


        }

      }
    })

  }

  addaproveFithscreen() {

    let approverTables: any[] = [];
    for (let i = 0; i < this.approverTable.length; i++) {
      var obj = {
        approveMember: this.approverTable[i].hiringTeamName.toString(),
        role: this.approverTable[i].designation.toString(),
        levels: this.approverTable[i].level.toString(),
      };

      approverTables.push(obj);
    }
    var approver = {
      ijpBasicInformationId: this.ijpBasicInformationId,
      ijpReportingmanagerId: 0,
      reportingmanager: true,
      iJPJobApprover: approverTables,

    };

    this.IJPService.PostIJPJobApprovers(approver).subscribe((res: any) => {
      this.ijpBasicInformationId = res.ijpBasicInformationId;
      if (res) {
        if (this.referalscreen == true) {
          this.approveSuccess = false;

        } else {
          this.approveSuccess = true;

        }
      }
      // else this.approveSuccess = false;
    });


    // this.PostIJPJobApprovers();

  }

  approveSuccess: boolean = false;
  postjobtoDetail: any = []
  referalscreen: boolean = false
  showApproveSuccess() {

    // console.log(this.referalscreen, ' this.referalscreen')
    // if (this.referalscreen == true) {
    //   var applicable: any
    //   if (this.referralApplicable == 'notApplicable') {
    //     applicable = false
    //   } else {
    //     applicable = true

    //   }
    //   var flatnew = false
    //   if (this.selectedBonusType == 'flat') {
    //     flatnew = true
    //   } else {
    //     flatnew = false
    //   }


    //   this.arrayvalues = []
    //   this.tenuredata = this.addBonusArr.addBonusArrItem

    //   for (var i = 0; i < this.tenuredata.length; i++) {
    //     if (this.tenuredata[i].refreeTenureSelection == null) {
    //       this.tenuredata[i].refreeTenureSelection = 0
    //     } else {
    //       this.tenuredata[i].refreeTenureSelection = this.tenuredata[i].refreeTenureSelection
    //     }

    //     var value = {

    //       "refreeTenureSelection": this.tenuredata[i].refreeTenureSelection,
    //       "addAmount": this.tenuredata[i].addAmount
    //     }
    //     this.arrayvalues.push(value)


    //   }
    //   var objnew = {
    //     "ijpBasicInformationId": this.ijpBasicInformationId,
    //     "applicable": applicable,
    //     "programType": this.programTypeListSelection,
    //     "currency": this.referralCurrencyListSelection,
    //     "openPosition": this.numberOfPositionsReferral,
    //     "flat": flatnew,
    //     "flatBonusPercent": this.referralBonus,
    //     "flatBonusCurrency": this.currencyListSelection1,
    //     "flatBonusAmount": this.referralAmount,
    //     "minimumTenure": this.minimumTenureSelection,
    //     "additionalBonusTenure": true,
    //     "minimumBudget": this.referralAmount,
    //     "maximumBudget": this.maxValue,
    //     "tenureBonusAmount": this.referralAmount,
    //     "tenureBonusCurrency": this.refreeTenureCurrencySelection,
    //     "status": true,
    //     "tenure": this.arrayvalues
    //   }

    //   if (this.referralForm?.valid) {
    //     this.IJPService.PostIJPJobReferral(objnew).subscribe(async (res) => {
    //       console.log(res, 'amount1amount1')

    //     });
    //   }


    // } else {

    let approverTables: any[] = [];
    for (let i = 0; i < this.approverTable.length; i++) {
      var obj = {
        approveMember: this.approverTable[i].hiringTeamName.toString(),
        role: this.approverTable[i].designation.toString(),
        levels: this.approverTable[i].level.toString(),
      };

      approverTables.push(obj);
    }
    var approver = {
      ijpBasicInformationId: this.ijpBasicInformationId,
      ijpReportingmanagerId: 0,
      reportingmanager: this.applicantApproval,
      iJPJobApprover: approverTables,

    };

    this.IJPService.PostIJPJobApprovers(approver).subscribe((res: any) => {
      this.ijpBasicInformationId = res.ijpBasicInformationId;
      if (res) {
        this.approveSuccess = true;
      }
      else this.approveSuccess = false;
    });


    //  this.PostIJPJobApprovers();

    // }

  }
  redirectToListing() {
    // window.location.href = '/job-listing';
    window.history.back();
  }
  // showSuccess() {
  //   this.messageService.clear();
  //   this.messageService.add({
  //     key: 'toast1',
  //     severity: 'success',
  //     summary: 'Success',
  //     detail: 'Job create success',
  //     life: 3000,
  //   });

  //   setTimeout(() => {
  //     this.router.navigate(['job-listing']);
  //   }, 3000);
  // }
  checkMaxValue(value: number) {
    if (value > 100) {
      this.preferredAge = 100;
    }
  }

  back() {
    if (this.activeStepIndex > 0) {
      this.activeStepIndex--;
    }
    this.ijpBasicInformationId = this.ijpBasicInformationId;
    this.ijpJobDescriptionId = this.ijpJobDescriptionId;
    this.GetDocumentType()

    this.GetSkillsCategory();

    var teamData = this.teamArray;
    var hirigiData = this.hiring_Data;
    for (let i = 0; i < teamData?.length; i++) {
      for (let j = 0; j < hirigiData?.length; j++) {
        if (teamData[i].hiringStage === hirigiData[j]?.stageId) {
          teamData[i].hiringStage = hirigiData[j]?.stageName;
          let hiringStage = hirigiData[j]?.stageId;
          for (let l = 0; l < this.Interview_list?.length; l++) {
            const element = this.Interview_list[l]?.interviewTemplateId;
            if (
              teamData[i].interviewTemplate ===
              this.Interview_list[l].interviewTemplateId ||
              teamData[i].interviewTemplate === undefined
            ) {
              if (teamData[i].interviewTemplate != undefined) {
                teamData[i].interviewTemplate =
                  this.Interview_list[l].interviewTemplateName;
              } else {
                teamData[i].interviewTemplate = 0;
              }
            }
          }
        }
      }
    }


  this.IJPService.GetSkillsCategory().subscribe((res: any) => {
    this.skillcategroy = res;
  });
  }


  isOptional = false;
  editor!: Editor;

  html = '';
  jahDataa: any = {};
  preferedDomainArr: any = {};
  workPermitArr: any = {};

  updateCreateJobForm() { }
  updatecreateJobDescriptionForm() { }
  updatecreateJobApproversForm() { }
  updatecreatehiringTeamsForm() { }
  updateTargetAudienceForm() { }
  updateProgrmaApproversForm() { }

  selectedCar: any;
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  selectedCar1: any;
  cars1 = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  // trid:any;
  postjobto: any;
  jobtitle: any;
  country: any;
  countryName: any;
  campus: any;
  department: any;
  requiter: any;
  jobCategory: any;
  numberofposition: any;
  priority: any;
  typeofhiring: any;
  textControl: any;
  typeofdurationOne: any;
  perdateofbirth: any;
  tentativeJobStartDate: any;
  expJoiningDate: any;
  myHiringCutOffDate: any;
  indExpp1: any;
  indExpp2: any;
  myReportingManager: any;
  employeeDetailsTextArea: any;
  fromsalry: any;
  tosalery: any;
  typaofappointcheckbox: any;
  publishstartdate: any;
  publisheddate: any;
  hiringcuttofdate: any;
  hiringmanager: any;
  requitermanager: any;
  businesspartner: any;
  prefered: any;
  experience: any;
  preferedDomain: any;
  pernationality: any;
  qualifications: any;
  languages: any;
  nationality: any;
  gender: any;
  noticePeriod: any;
  relocate: any;
  timeToRelocate: any;
  preferredAge: any;
  document: any;
  stages: any;
  workPermitType: any;
  workPermit: any;
  workCountry: any;
  higherLevelEdu: any;
  fromDomain: any;
  toDomain: any;
  textControl1: any;
  textControl2: any;
  textControl3: any;
  textControl4: any;
  textControl5: any;
  textControl6: any;
  preferedFromDomainExperience: any;
  preferedFromDomain: any;
  preferedToDomain: any;
  reportingManager: any;
  isLinear = false;
  totalExperienceSelect: any;
  totalExperienceSelect2: any;
  currentSalaryCode: any;
  currentSalaryCodefrom: any;
  currentSalaryCodeto: any;
  salaryofferRangeCode: any;
  salaryofferRangeCodefrom: any;
  salaryofferRangeCodeto: any;
  Knownnlanguages: any;
  myNationality: any;
  relocateFlexible: any;
  relocateFlexibleTwo: any;
  noticePeriodSelect: any;
  noticePeriodSelectttt: any;
  preferredAgeSelect: any;
  preferredAgeSelecttWo: any;
  totalExpfrom: any;
  totalExpto: any;
  totalExperience: any;
  totalExperienceArr: any = {};
  requiredDocArr: any = {};
  hiringTeamMember: any;
  hiringStage: any;
  interviewTemplate: any;
  cutOffDate1: any = {};
  typeOfInterview: any;
  mypostJObTo: any;
  myPublishStartDate: any;
  myPublishEndDate: any;

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  domainObj: any = {};
  industryObj: any = {};

  constructor(
    private messageService: MessageService,
    public fb: FormBuilder,
    private IJPService: IJPService,
    public reportsService: ReportsService,
    private http: HttpClient,
    private router: Router,
    private userService:userService,
    private rout: ActivatedRoute
  ) {
    this.jahDataa.approverLists = [
      {
        prefferedIndustry: null,
        prefferedIndustryExperienceFrom: null,
        prefferedIndustryExperienceTo: null,
      },
    ];
    this.preferedDomainArr.preferedDomainItem = [
      {
        preferedDomainSelect: null,
        preferedFromDomainExperience: null,
        preferedFromDomain: null,
        preferedToDomain: null,
      },
    ];
    this.workPermitArr.workPermitRelated = [
      {
        workPermit: null,
        workCountry: null,
        workPermitType: null,
      },
    ];
    this.totalExperienceArr.totalExperienceItems = [
      {
        totalExperienceSelect: null,
        totalExpfrom: null,
        totalExpto: null,
        totalExperience: null,
      },
    ];
    this.requiredDocArr.requiredDocList = [
      {
        documentType: null,
        stages: null,
      },
    ];
    this.skillDataArr.skillLists = [
      {
        skillCategory: null,
        skills: null,
        skill_list: []
      },
    ];
    this.employeeList.employeeTable = [
      {
        employeeId: null,
        name: null,
        emailId: null,
        department: null,
        designation: null,
        lastWorkingDate: null,
        reportingManager: null,
      },
    ];
    this.domainObj.ijpdomain = [
      {
        preferedDomain: null,
        domainExperienceFrom: null,
        domainExperienceTo: null,
      },
    ];

    this.industryObj.industries = [
      {
        prefferedIndustry: null,
        prefferedIndustryExperienceFrom: null,
        prefferedIndustryExperienceTo: null,
      },
    ];
    this.sub = this.rout.params.subscribe((params) => {
      console.log('this is the idddd', params);
      this.edId = params['id'];
    });

    this.referralBonusArr.referralBonusArrItem = [
      {
        bonusFrom: null,
        bonusTo: null,
        amount1: null,

      },
    ];
    this.addBonusArr.addBonusArrItem = [
      {
        refreeTenureSelection: null,
        addAmount: null,

      },
    ];
  }

  onCheckboxChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.displayToApplicants = inputElement.checked;
    setTimeout(() => {
      const res = { displayToApplicants: this.displayToApplicants };
    }, 0);
  }
  // formData(arg0: string, formData: any) {
  //   throw new Error('Method not implemented.');
  // }

  PostCreateJob() {
    var empdetails: any = [];
    for (let i = 0; i < this.getemployeedetails.length; i++) {
      var obj = {
        employeeId: this.getemployeedetails[i].userCode,
        name: this.getemployeedetails[i].name,
        emailId: this.getemployeedetails[i].email,
        department: this.getemployeedetails[i].departmentId,
        designation: this.getemployeedetails[i].designation,
        lastWorkingDate: this.getemployeedetails[i].lastWorkingDate,
        reportingManager: this.getemployeedetails[i].reportingManagerName,
      };
      empdetails.push(obj);
    }
    var postArray: any = [];
    for (let j = 0; j < this.addjobsDatesArray.length; j++) {
      const element = this.addjobsDatesArray[j];
      var data = {
        postjobto: this.addjobsDatesArray[j].postJobTo,
        publishstartdate: moment(this.addjobsDatesArray[j].startDate).format(
          'YYYY-MM-DDTHH:mm:ss'
        ),
        publishenddate: moment(this.addjobsDatesArray[j].endDate).format(
          'YYYY-MM-DDTHH:mm:ss'
        ),
      };
      postArray.push(data);
    }
  var langugessid=this.Knownnlanguages
    if (this.Knownnlanguages) {
      langugessid = langugessid.toString()
    } else {
      langugessid = null
    }
    var qualificationid= this.qualifications
    if (this.qualifications) {
      qualificationid = qualificationid.toString()
    } else {
      qualificationid = null
    }

    var gendervalnew=this.gender
    if (this.gender) {
      gendervalnew =gendervalnew.toString()
    } else {
      gendervalnew = null
    }
    if (this.domainObj.ijpdomain) {
      var docData = this.domainObj.ijpdomain
      var docArray = []
      for (let i = 0; i < docData.length; i++) {
        if (docData[i].preferedDomain == null && docData[i].domainExperienceFrom == null && docData[i].domainExperienceTo == null) {
          let obj = {
            preferedDomain: 0,
            domainExperienceFrom: 0,
            domainExperienceTo: 0,
          };
          docArray.push(obj);
          this.domainObj.ijpdomain = docArray
        } else {
          this.domainObj.ijpdomain = this.domainObj.ijpdomain
        }
      }
    }
    if (this.workCountry == null) {
      this.workCountry = 0
    } else {
      this.workCountry = this.workCountry
    }
    if (this.workPermitType == null) {
      this.workPermitType = 0
    } else {
      this.workPermitType = this.workPermitType
    }

    if (this.industryObj.industries) {
      var indData = this.industryObj.industries
      var ikndArray = []
      for (let i = 0; i < indData.length; i++) {
        if (indData[i].prefferedIndustry == null && indData[i].prefferedIndustryExperienceFrom == null && indData[i].prefferedIndustryExperienceTo == null) {
          let obj = {
            prefferedIndustry: 0,
            prefferedIndustryExperienceFrom: 0,
            prefferedIndustryExperienceTo: 0,
          };
          ikndArray.push(obj);
          this.industryObj.industries = ikndArray
        } else {
          this.industryObj.industries = this.industryObj.industries
        }
      }
    }

    if (this.myNationality == null) {
      this.myNationality = 0
    } else {
      this.myNationality = this.myNationality
    }

    if (this.currentSalaryCodefrom == null) {
      this.currentSalaryCodefrom = 0
    } else {
      this.currentSalaryCodefrom = this.currentSalaryCodefrom
    }

    if (this.currentSalaryCodeto == null) {
      this.currentSalaryCodeto = 0
    } else {
      this.currentSalaryCodeto = this.currentSalaryCodeto
    }

    if (this.reportingManagerId == null) {
      this.reportingManagerId = 0
    } else {
      this.reportingManagerId = this.reportingManagerId
    }

    if (this.higherLevelEdu == null) {
      this.higherLevelEdu = 0
    } else {
      this.higherLevelEdu = this.higherLevelEdu
    }

    var docuArray: any = [];
    for (let k = 0; k < this.requiredDocArr.requiredDocList.length; k++) {
      const element = this.requiredDocArr.requiredDocList[k];
      var objj = {
        documentType:
          this.requiredDocArr.requiredDocList[k].documentType.toString(),
        stage: this.requiredDocArr.requiredDocList[k].stages.toString(),
      };
      docuArray.push(objj);
    }
    // if (this.ijpBasicInformationId) {
    //   this.c_camp = this.campusInfoData
    //   this.country_data = this.countryInfoData
    //   this.brandData = this.brandInfoData
    //   this.trfJobId = this.jobtitleData
    //   this.trid = this.trid
    //   this.department = this.deptNameId
    // } else {
    //   this.c_camp = this.c_camp.toString()
    //   this.country_data = this.country_data
    //   this.brandData = this.brandData
    //   this.trfJobId = this.trfJobId
    //   this.trid = this.trid
    //   this.department = this.department
    // }

    if (this.ijpBasicInformationId) {
      if (this.campusInfoData != undefined && this.countryInfoData != undefined
        && this.brandInfoData != undefined && this.jobtitleData != undefined) {
        this.c_camp = this.campusInfoData
        this.country_data = this.countryInfoData
        this.brandData = this.brandInfoData
        this.trfJobId = this.jobtitleData
        this.trid = this.trid
        this.department = this.deptNameId
      } else {
        this.c_camp = this.c_camp.toString()
        this.country_data = this.country_data
        this.brandData = this.brandData
        this.trfJobId = this.trfJobId
        this.trid = this.trid
        this.department = this.department
      }
    } else {
      this.c_camp = this.c_camp.toString()
      this.country_data = this.country_data
      this.brandData = this.brandData
      this.trfJobId = this.trfJobId
      this.trid = this.trid
      this.department = this.department
    }
    const payload = {
      ijpBasicInformationId: 0,
      userId: this.userId,
      trId: this.trid,
      jobTitle: this.trfJobId,
      country: this.country_data,
      brand: this.brandData,
      campus: this.c_camp,
      jobCategory: this.jobCategory,
      priority: this.priority,
      numberOfPositions: this.numberofposition,
      typeOfHiring: this.typeofhiring,
      rationalForHiringNonBudgetedPosition: this.textControl,
      typesOfAppointment: this.typeofappointment,
      expectedDateOfJoining: moment(this.myHiringCutOffDate).format(
        'YYYY-MM-DDTHH:mm:ss'
      ),
      typesOfEmployment: this.typeofemp,
      displayToApplicants: this.displayToApplicants,
      employmentDuration: this.typeofdurationn,
      ijpjobreplacement: empdetails,
      department: this.department,
      hiringManager: this.hiringmanager,
      recruitmentManager: this.requitermanager,
      businessPartner: this.businesspartner,
      ijpindustry: this.industryObj.industries,
      ijpdomain: this.domainObj.ijpdomain,
      totalExperienceTo: this.totalExperienceSelect,
      totalExperienceFrom: this.totalExperienceSelect2,
      currentSalarySymbol: this.totalExperience,
      currentSalaryFrom: this.currentSalaryCodefrom,
      currentSalaryTo: this.currentSalaryCodeto,
      preferredHigherEducation: this.higherLevelEdu,
      preferredQualifications:qualificationid,
      preferredLanguagesKnown: langugessid,
      preferredNationality: this.myNationality,
      preferredGender:gendervalnew,
      noticePeriodFrom: this.noticePeriodSelect,
      noticePeriodTo: this.noticePeriodSelectttt,
      flexibleToRelocate: this.relocateFlexible,
      preferredAgeBarFrom:this.preferredAgeSelect ?? 0,
      preferredAgeBarTo: this.preferredAgeSelecttWo ?? 0,
      reportingManager: this.reportingManagerId,
      ijpdocument: docuArray,
      workPermit: this.workPermit,
      workPermitCountry: this.workCountry,
      workPermitType: this.workPermitType,
      hirirngProcessCutoffDate: moment(this.myHiringCutOffDate).format(
        'YYYY-MM-DDTHH:mm:ss'
      ),
      ijpJobPostedData: postArray,
      status: true,
      salaryOfferRangeSymbol: this.salaryofferRangeCode,
      salaryOfferRangeFrom: this.salaryofferRangeCodefrom,
      salaryOfferRangeTo: this.salaryofferRangeCodeto,
    };
    console.log(payload,"payload");

    var id = this.ijpBasicInformationId;
    if (this.ijpBasicInformationId > 0) {
      payload['ijpBasicInformationId'] = this.ijpBasicInformationId;
    }
    console.log(payload, "==================workPermitType")

    this.IJPService.PostCreateJob(payload).subscribe((res: any) => {
      this.ijpBasicInformationId = res.ijpBasicInformationId;
      this.GetEligibleDepartment(this.ijpBasicInformationId)
      // sessionStorage.setItem(
      //   'ijpBasicInformationId',
      //   this.ijpBasicInformationId.toString()
      // );
      // console.log('this.ijpBasicInformationId', this.ijpBasicInformationId);
    });
  }

  currentSalaryRangeLogic(e:any): void{
    var currentSaralyVar =Number(e.target.value);
    if (this.currentSalaryCodefrom >= currentSaralyVar) {
      this.showErrorCurrentSalary = true;
    }
    else{
      this.showErrorCurrentSalary = false;
    }
    console.log(this.currentSalaryCodefrom);

  }

  deleteFieldss(i: any) {
    console.log(this.industryObj.industries);
    this.industryObj.industries.splice(i, 1);
  }

  isFieldComplete(i: number): boolean {
    const industry = this.industryObj.industries[i];
    return industry && industry.prefferedIndustry && industry.prefferedIndustryExperienceFrom && industry.prefferedIndustryExperienceTo;
  }
  addindustry(i: any) {
    let prefreedType: any = [];
    prefreedType = this.prefferedIndustryY;
    console.log(
      this.industryObj.industries,
      '===this.industryObj.industries',
      prefreedType
    );

    for (let k = 0; k < this.industryObj.industries.length; k++) {
      for (let j = 0; j < prefreedType.length; j++) {
        if (
          this.industryObj.industries[k] &&
          this.industryObj.industries[k].prefferedIndustry
        )
          if (
            prefreedType[j].industry_Id ==
            this.industryObj.industries[k].prefferedIndustry
          )
            var idToRemove = prefreedType[j].industry_Id;
        prefreedType = prefreedType.filter(
          (item: any) => item.industry_Id !== idToRemove
        );
      }
    }
        let lastIndustry = this.industryObj.industries[this.industryObj.industries.length - 1];

    if (!lastIndustry ||
        !lastIndustry.prefferedIndustry ||
        !lastIndustry.prefferedIndustryExperienceFrom ||
        !lastIndustry.prefferedIndustryExperienceTo) {
      return;
    }

    this.preferdIndsutry = [];
    //  setTimeout(()=>{
    let obj = {
      prefferedIndustry: null,
      prefferedIndustryExperienceFrom: null,
      prefferedIndustryExperienceTo: null,
    };

    setTimeout(() => {
      // for(let i=0;i<prefreedType.length ;i++){
      this.preferdIndsutry = prefreedType;
      let industries = this.industryObj.industries;
      this.industryObj.industries = industries;
      this.industryObj.industries.push(obj);
    }, 1000);

    //     console.log(this.industryObj,"2222222222222222222222222");
    //     for (let i = 0; i < this.industryObj.industries.length; i++) {
    //       let doc_info = this.industryObj.industries[i];
    //       var doc_id = doc_info.prefferedIndustry;
    //       for (let k = 0; k < doc_id.length; k++) {
    //         for (let j = 0; j < this.preferdIndsutry.length; j++) {
    // const element = this.preferdIndsutry[j].industry_Id;
    // if (this.preferdIndsutry[j] && doc_id[k]) {
    // if (doc_id[k] == this.preferdIndsutry[j].industry_Id) {
    //               var idToRemove = this.preferdIndsutry[j].industry_Id;
    //                 this.preferdIndsutry = this.preferdIndsutry.filter(
    //                 (item:any) => item.industry_Id !== idToRemove
    //               );
    //             }
    //           }
    //         }
    //         }
    //     }

    //     let obj = {
    //       prefferedIndustry: null,
    //       prefferedIndustryExperienceFrom: null,
    //       prefferedIndustryExperienceTo: null,
    //     };

    //     this.industryObj.industries.push(obj);
  }


  deleteFieldss1(a: any) {
    console.log(this.domainObj.ijpdomain);
    this.domainObj.ijpdomain.splice(a, 1);
  }
  addFieldss1(a: any) {
    console.log(this.domainObj.ijpdomain);
    let lastDomain = this.domainObj.ijpdomain[this.domainObj.ijpdomain.length - 1];

    if (!lastDomain ||
        !lastDomain.preferedDomain ||
        !lastDomain.domainExperienceFrom ||
        !lastDomain.domainExperienceTo) {
      return;
    }
    let domainType: any = [];
    domainType = this.preferdDomain;
    for (let k = 0; k < this.domainObj.ijpdomain.length; k++) {
      for (let j = 0; j < domainType.length; j++) {
        if (
          this.domainObj.ijpdomain[k] &&
          this.domainObj.ijpdomain[k].preferedDomain
        )
          if (
            domainType[j].domain_Id ==
            this.domainObj.ijpdomain[k].preferedDomain
          )
            var idToRemove = domainType[j].domain_Id;
        domainType = domainType.filter(
          (item: any) => item.domain_Id !== idToRemove
        );
      }
    }
    this.preferdDomain = [];
    let obj = {
      preferedDomain: null,
      domainExperienceFrom: null,
      domainExperienceTo: null,
    };
    this.domainObj.ijpdomain.push(obj);
    this.preferdDomain = domainType;
    // setTimeout(() => {
    //   this.domainObj.ijpdomain;
    // }, 200);
    // if(this.prefferedDomain.length > 1){
    //   this.prefferedDomain.splice(i,1)
    //   }else{
    //     this.prefferedDomain = [];
    //   }
  }
  deleteFieldss2(b: any) {
    console.log(this.workPermitArr.workPermitRelated);
    this.workPermitArr.workPermitRelated.splice(b, 1);
  }
  addFieldss2(b: any) {
    console.log(this.workPermitArr.workPermitRelated);
    let obj = {
      workPermit: null,
      workCountry: null,
      workPermitType: null,
    };

    this.workPermitArr.workPermitRelated.push(obj);
  }
  deleteFieldss3(f: any) {
    console.log(this.totalExperienceArr.totalExperienceItems);
    this.totalExperienceArr.totalExperienceItems.splice(f, 1);
  }
  addFieldss3(f: any) {
    console.log(this.totalExperienceArr.totalExperienceItems);
    let obj = {
      totalExperienceSelect: null,
      totalExpfrom: null,
      totalExpto: null,
      totalExperience: null,
    };

    this.totalExperienceArr.totalExperienceItems.push(obj);
  }
  deleteFieldss4(e: any) {

    if (this.requiredDocArr.requiredDocList.length > 0) {
      const previousIndex = Math.max(e - 1, 0);
    }
    this.requiredDocArr.requiredDocList.splice(e, 1);
    const lastApproverId = this.requiredDocArr.requiredDocList.length > 0
      ? this.requiredDocArr.requiredDocList[this.requiredDocArr.requiredDocList.length - 1].documentType.toString()
      : null;
    if (lastApproverId != null) {
      this.matchingDoc = this.docJr.filter((post: any) => lastApproverId.includes(post.documenttypeId));
    }
    console.log(this.matchingDoc);
    this.documentTypes = [...this.matchingDoc, ...this.documentTypes];


  }

  addFieldss4(i: any) {
    let lastDocument = this.requiredDocArr.requiredDocList[this.requiredDocArr.requiredDocList.length - 1];

    if (!lastDocument ||
        !lastDocument.documentType ||
        !lastDocument.stages) {
      return;
    }
    for (let i = 0; i < this.requiredDocArr.requiredDocList.length; i++) {
      let doc_info = this.requiredDocArr.requiredDocList[i];
      var doc_id = doc_info.documentType;
      for (let k = 0; k < doc_id.length; k++) {
        //     // const element
        for (let j = 0; j < this.documentTypes.length; j++) {
          const element = this.documentTypes[j].documenttypeId;
          if (this.documentTypes[j] && doc_id[k]) {
            if (doc_id[k] == this.documentTypes[j].documenttypeId) {
              var idToRemove = this.documentTypes[j].documenttypeId;
              this.documentTypes = this.documentTypes.filter(
                (item: any) => item.documenttypeId !== idToRemove
              );
            }
          }
        }
      }
    }
    let obj = {
      documentType: null,
      stages: null,
    };

    this.requiredDocArr.requiredDocList.push(obj);
  }
  isAddButtonEnabled(documentType: any, stages: any): boolean {
    return documentType && documentType.length > 0 && stages;
  }

  show(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Message Content',
    });
  }
  // shabbeer:any

  // ngOnDestroy(): void {
  //   this.editor.destroy();
  // }
  lastSegment: any
  ngOnInit(): void {
    this.updateMaxValue();

    console.log('dmwamawdvwad');
    var url = window.location.href
    this.lastSegment = url.split("/").pop();

    // let userData = this.reportsService.getUserId();
    // this.userDetails = jwtDecode(userData);
    // this.userId = this.userDetails.UserId;
    let userData = this.userService.getUserId();
    this.userDetails = jwtDecode(userData);
    this.userId = this.userDetails.UserId;

    this.GetTRID(this.userId);

    // this.editor = new Editor();

    this.GetTypeOfHiring();
    this.GetJobCategory();
    this.GetTypeOfAppointment();
    this.GetDocumentType();
    this.GetStage();
    this.GetPreferredIndustry();
    this.GetPreferredDomain();
    this.GetHigherlevelEducation();
    this.GetQualifications();
    this.GetLanguagesKnown();
    this.GetNationality();
    this.GetCurrencyCode();
    this.GetCurrentCode();
    this.GetEmployment();
    this.GetHiringManager();
    this.GetRecruitmentManager();
    this.GetBusinessPartner();
    this.GetCandidateCountryList();
    this.GetReportingManager();
    this.GetPostJobTo();
    this.GetEmployeeId();
    this.GetSkillsCategory();
    this.GetEmployeeCTCApprover();
    this.GetHiringTeamMember();
    this.GetAllCountryDetails()
    // this.GetEligibleRole();
    // this.GetCountries(this.eligibleBrandss);
    // this.GetSkills(this.skillId);
    // this.GetEmployeeDetails(this.id);
    // this.formData = {
    //   trid: 'someValue',
    //   jobtitle: 'someValue',
    //   mySchool: 'someValue',
    //   countryName: 'someValue',
    //   campus: 'someValue',
    //   department: 'someValue',
    //   jobCategory: 'someValue',
    //   priority: 'someValue',
    //   numberofposition: 'someValue',
    //   typeofhiring: 'someValue',
    //   textControl: 'someValue',
    //   getEmployment: 'someValue',
    //   typeofdurationn: 'someValue',
    //   reportingManager: 'someValue',
    //   appointment: 'someValue',
    //   mypostJObTo: 'someValue'
    // };

    this.initializeForm();
    this.addComponent();
    this.addComponent1();
    this.addComponent2();

    this.getemployeeid = []; // Initialize getemployeeid
    this.employeeIddd = Array.from({
      length: this.employeeList.employeeTable.length,
    }); // Initialize employeeIdds with empty values
    this.getemployeedetails = Array.from({
      length: this.employeeList.employeeTable.length,
    }); // Initialize getemployeedetails with empty values
    this.getAllRoleDetails();
    this.getInterviewQuestionTemplate();
    // this.GetEligibleDepartment();
    // this.GetEligibleRole();
    this.GetEligibleBrand(0);
    this.GetMemberName();
    if (parseInt(this.edId) > 0) {
      this.editListingById(this.edId)
    }


    this.IJPService.EditIJP(this.userId).subscribe((res) => {
      if (res != 'Reporting Manager not found') {
        this.postjobtoDetail = res?.ijpBasicInformation?.ijpJobPostedData
        console.log(this.postjobtoDetail, 'this.postjobtoDetail')
        // postjobto
        for (var n = 0; n < this.postjobtoDetail?.length; n++) {
          if (this.postjobtoDetail[n]?.postjobto == "Referral Campaign") {

            this.referalscreen = true
          }
        }
      }
    })

    this.GetIjpReferralById()


  }

  // GetEligibleDepartment() {
  //   throw new Error('Method not implemented.');
  // }
  // GetEligibleRole() {
  //   throw new Error('Method not implemented.');
  // }
  // GetMemberName() {
  //   throw new Error('Method not implemented.');
  // }
  // GetCountry() {
  //   throw new Error('Method not implemented.');
  // }
  dropdownstatic: any = [
    {
      name: sessionStorage,
      id: 1,
    },
  ];

  domainForm: any;
  preferedForm: any;
  workPermitForm: any;

  initializeForm() {
    this.domainForm = this.fb.group({
      components: this.fb.array([]),
    });
    this.preferedForm = this.fb.group({
      components1: this.fb.array([]),
    });
    this.workPermitForm = this.fb.group({
      components2: this.fb.array([]),
    });
  }

  get components() {
    return this.domainForm.get('components') as FormArray;
  }
  get components1() {
    return this.preferedForm.get('components1') as FormArray;
  }
  get components2() {
    return this.workPermitForm.get('components2') as FormArray;
  }

  addComponent() {
    const componentGroup = this.fb.group({
      preferedDomain: [null],
      jobRemunerationValue: [null],
    });
    this.components.push(componentGroup);
  }
  addComponent1() {
    const componentGroup1 = this.fb.group({
      prefered: [null],
      experience: [null],
    });
    this.components1.push(componentGroup1);
  }
  addComponent2() {
    const componentGroup2 = this.fb.group({
      workPermit: [null],
      workCountry: [null],
      workPermitType: [null],
    });
    this.components2.push(componentGroup2);
  }
  addComponent3() {
    const componentGroup3 = this.fb.group({
      totalExperienceSelect: [null],
      totalExpfrom: [null],
      totalExpto: [null],
      totalExperience: [null],
    });
    this.components3.push(componentGroup3);
  }
  addComponent4() {
    const componentGroup4 = this.fb.group({
      documentType: [null],
      stages: [null],
    });
    this.components4.push(componentGroup4);
  }

  addDocumentSection() { }

  gotoJobDescription() {
    window.location.href = '/job-post-description';
  }
  @Output() nextClicked = new EventEmitter<void>();

  next() {
    var teamData = this.teamArray;
    var hirigiData = this.hiring_Data;
    for (let i = 0; i < teamData.length; i++) {
      for (let j = 0; j < hirigiData.length; j++) {
        if (teamData[i].hiringStage === hirigiData[j].stageName) {
          teamData[i].hiringStage = hirigiData[j].stageId;
          let hiringStage = hirigiData[j].stageId;
          for (let l = 0; l < this.Interview_list.length; l++) {
            const element = this.Interview_list[l].interviewTemplateId;
            if (
              teamData[i].interviewTemplate ===
              this.Interview_list[l].interviewTemplateName ||
              teamData[i].interviewTemplate === undefined
            ) {
              if (teamData[i].interviewTemplate != undefined) {
                teamData[i].interviewTemplate =
                  this.Interview_list[l].interviewTemplateId;
              } else {
                teamData[i].interviewTemplate = 0;
              }
            }
          }
        }
      }
    }
    var obj = {
      ijpBasicInformationId: this.ijpBasicInformationId,
      employmentCtcApprover: this.empCtcApprover,
      iJPHiringTeam: this.teamArray,
    };
    this.IJPService.PostIJPEmploymentCtcApprover(obj).subscribe((res: any) => {
      // this.activeStepIndex = 2;

      if (res) {
        this.activeStepIndex = 3;

        this.GetEligibleDepartment(this.ijpBasicInformationId)

      }
      else {
        this.activeStepIndex = 2;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something Went Wrong' });
      }
      // this.nextClicked.emit();
    });
  }

  gotojobListingpage() {
    // window.location.href = '/job-listing';
    window.history.back();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  typeofdurationControl = new FormControl();

  // Define options for the select
  typeofdurationOptions = [
    { id: 1, typeofduration: 'Option 1' },
    { id: 2, typeofduration: 'Option 2' },
    { id: 3, typeofduration: 'Option 3' },
    // Add more options as needed
  ];

  // currency: any;
  currency: any = 'India';

  currencyItem = [
    { id: 'USA', name: 'USD' },
    { id: 'India', name: 'INR' },
    { id: 'United Kingdom', name: 'GBP' },
    // Add more items as needed
  ];
  // hideTable: boolean = true;
  typeofappointment: any = 1;

  // appointment = [
  //     {id: 1, name: 'New'},
  //     {id: 2, name: 'Replacement'},
  // ];

  typeofemp: any;

  emp = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Replacement' },
  ];

  showTable: boolean = false;

  // employeeTable :any= [
  // { empid: null, name: null, email: null, dept: null, designation: null, lastworkday: null, reprotingmanager: null },
  //   { empid: 'AO1235', name: 'Tiana', email: 'tiana12@mail.com', dept: 'Marketing', designation: 'Sr. Marketing Executive', lastworkday: 'Aug 20, 2023', reprotingmanager: 'Rhiel Madsen' },
  // ];

  selectemployee: any;
  typeofdurationInput: any;
  typeofempInput: any;
  perdateofbirthInput: any;
  // Method to add selected values to the array
  // addEmployeeToTable(typeofemp: string) {
  //   // Push selected values to the array
  //   this.employeeTable.push({
  //     typeofemp: typeofemp,
  //     // Extract other form values similarly
  //   });

  //   // Optionally, you can reset the form here if needed
  //   // this.resetForm();
  // }
  products = [
    {
      id: '1000',
      code: 'f230fh0g3',
      name: 'Bamboo Watch',
      description: 'Product Description',
      image: 'bamboo-watch.jpg',
      price: 65,
      category: 'Accessories',
      quantity: 24,
      inventoryStatus: 'INSTOCK',
      rating: 5,
    },
    {
      id: '1001',
      code: 'nvklal433',
      name: 'Black Watch',
      description: 'Product Description',
      image: 'black-watch.jpg',
      price: 72,
      category: 'Accessories',
      quantity: 61,
      inventoryStatus: 'OUTOFSTOCK',
      rating: 4,
    },
    {
      id: '1002',
      code: 'zz21cz3c1',
      name: 'Blue Band',
      description: 'Product Description',
      image: 'blue-band.jpg',
      price: 79,
      category: 'Fitness',
      quantity: 2,
      inventoryStatus: 'LOWSTOCK',
      rating: 3,
    },
  ];
  visible: boolean = false;

  // showDialog() {
  //     this.visible = true;
  // }

  getridsss = [];
  GetTRID(userId: any) {
    this.IJPService.GetTRID(userId).subscribe((res) => {
      this.getridsss = res;
    });
  }


  ontrchangess = [];
  trfid: any;
  country_data: any;
  getTRInfo(trid: any) {
    this.IJPService.onTRIDChange(trid).subscribe((res) => {
      this.ontrchangess = res;
      this.ontrchangess.map;
      this.countryName = res.talentRequisition.country;
      this.country_data = res.talentRequisition.country;
      this.c_camp = res.talentRequisition.campus.split(',');
      this.trfJobId = res.talentRequisition.jahJobTitleId;
      this.trfid = res.talentRequisition.talentId;
      this.GetTalentById(this.trfid, this.trfJobId);
      this.mySchool = res.talentRequisition.brand;
      this.brandData = res.talentRequisition.brand;
      this.jobCategory = res.talentRequisition.category;
      this.department = res.deptName;
      this.priority = res.talentRequisition.priority;
      this.numberofposition = res.talentRequisition.numberOfPositions;
      this.slectappoinmentId(this.numberofposition);
      var budrate = parseInt(res.talentRequisition.budOrNonBud)
      this.typeofhiring = budrate
      this.expJoiningDate = this.convert(res.talentRequisition.expectedJoiningDate);
      this.higherLevelEdu = res.talentRequisition.higherLevelOfEducation;
      this.qualifications = res.talentRequisition.qualification
        .split(',')
        .map(Number);
      this.currentSalaryCode = res.talentRequisition.totalIncurredCostCode;
      this.totalExperienceSelect = res.talentRequisition.totalExperienceFrom
      this.totalExperienceSelect2 = res.talentRequisition.totalExperienceTo
      this.typeofemp = res.talentRequisition.typeOfEmployment;
      this.salaryofferRangeCode = res.talentRequisition.ctcCode
      this.salaryofferRangeCodefrom = res.talentRequisition.offerSalaryRangeFrom
      this.salaryofferRangeCodeto = res.talentRequisition.offerSalaryRangeTo

      this.textControl1 = res?.trfJobDescription.overview
      this.textControl2 = res?.trfJobDescription.responsibilities
      this.textControl3 = res?.trfJobDescription.skillsDescription
      this.textControl4 = res?.trfJobDescription.websiteShortDescription
      this.textControl5 = res?.trfJobDescription.disclaimer

      this.skillDataArr.skillLists=res?.trfJobDescription.trfSkills
      if (this.skillDataArr.skillLists) {
        let skills = this.skillDataArr.skillLists;
        for (let i = 0; i < skills.length; i++) {
          this.GetSkillNameById(skills[i].skillCategory, i);
          let skillName = skills[i].skills;
          if (skillName.indexOf(',') > -1) {
            let arraydoc = skillName.split(',');
            let skillnumarray = arraydoc.map(Number);
            skills[i].skills = skillnumarray;
          } else {
            let singleDoc = skillName.split(' ');
            let skillnumarray = singleDoc.map(Number);
            skills[i].skills = skillnumarray;
          }
        }
      }

      this.getJobTitle(this.userId);
      this.GetIjpHiringStages();




      // this.typeofappointment=res.talentRequisition.typeofappointment
      // this.getpostJob=res.talentRequisition.mypostJObTo

    });
  }
  jid(trfJobId: any, jid: any) {
    throw new Error('Method not implemented.');
  }
  getJobTitle(userId: any) {
    this.IJPService.GetTRID(userId).subscribe((res) => {
      console.log(res,'sssssssssssssssssssssssssssssssssssssssssss')
      for (let i = 0; i < res.length; i++) {
        if (res[i].jahJobId == this.trfJobId) {
          this.jobtitle = res[i].jahJobName;
        }
      }
    });
  }

  getschoolName(userId: any) {
    this.IJPService.GetTRID(userId).subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        if (res[i].brandId == this.trfJobId) {
          this.mySchool = res[i].brand;
        }
      }
    });
  }

  campusdata: any;
  finalCampus: any = [];
  GetTalentById(tid: any, jid: any) {
    this.finalCampus = [];
    this.IJPService.GetTalentById(tid, jid).subscribe((res: any) => {
      this.campusdata = res[0].campusdata;

      for (let i = 0; i < res.length; i++) {
        console.log(res[i].brandId, '====sindh', this.brandInfo);
        if (res[i].countrytId == this.countryName) {
          // this.basicInfoData.campus = res[i].country;
          this.countryName = res[i].country;
        }
        if (res[i].brandId == this.mySchool) {
          // this.basicInfoData.brand = res[i].brand;
          this.mySchool = res[i].brand;
        }
        let value = res[i].campusdata;
        for (let j = 0; j < value.length; j++) {
          for (let k = 0; k < this.c_camp.length; k++) {
            if (this.c_camp[k] == value[j].campusId) {
              this.finalCampus.push(value[j].campusName);
              this.basicInfoData.postJob = this.finalCampus;
              this.campus = this.finalCampus;
            }
          }
        }
      }
    });
  }

  ijphiring = [];
  GetTypeOfHiring() {
    this.IJPService.GetTypeOfHiring().subscribe((res) => {
      this.ijphiring = res;
    });
  }

  jobcategory = [];
  GetJobCategory() {
    this.IJPService.GetJobCategory().subscribe((res) => {
      this.jobcategory = res;
    });
  }

  slectappoinmentId(numberofposition: any) {
    this.employeeIddd = [];
    this.getemployeedetails = [];

    this.employeeList.employeeTable = [];

    var numberOfPositions = parseInt(numberofposition);
    if (numberOfPositions > 0) {
      for (let index = 0; index < numberOfPositions; index++) {
        var newEmployee = {
          employeeId: null,
          name: null,
          emailId: null,
          department: null,
          designation: null,
          lastWorkingDate: null,
          reportingManager: null,
        };

        this.employeeList.employeeTable.push(newEmployee);
      }
    }
  }

  changeFn(val: any, item: any) {
    item.prefferedIndustryExperienceTo = null;
    this.GetIndustryExperiencetoo = [];
    if (val) {
      for (let i = 0; i < this.GetIndustryExperience.length; i++) {
        let numberVal = +val.experience_Name;
        let perfVal = +this.GetIndustryExperience[i].experience_Name;
        if (numberVal < perfVal) {
          this.GetIndustryExperiencetoo.push(this.GetIndustryExperience[i]);
          //   // this.preFExpP[i].isDisabled = false;
        }
      }
    }
  }

  getDomainExp(val: any, item: any) {
    item.domainExperienceTo = null;
    this.GetDomainExperience2 = [];
    if (val) {
      for (let i = 0; i < this.GetDomainExperience.length; i++) {
        let numberVal = +val.experience_Name;
        let perfVal = +this.GetDomainExperience[i].experience_Name;
        if (numberVal < perfVal) {
          this.GetDomainExperience2.push(this.GetDomainExperience[i]);
          //   // this.preFExpP[i].isDisabled = false;
        }
      }
    }
  }

  getTotalExp(val: any) {
    this.GetEmployeeDuration2 = [];
    if (val) {
      for (let i = 0; i < this.GetEmployeeDuration.length; i++) {
        let numberVal = +val.experience_Name;
        let perfVal = +this.GetEmployeeDuration[i].experience_Name;
        if (numberVal < perfVal) {
          this.GetEmployeeDuration2.push(this.GetEmployeeDuration[i]);
          //   // this.preFExpP[i].isDisabled = false;
        }
      }
    }
  }
  showErrorCurrentSalary: boolean = false;
  getFromTo() {
    let fromNumber = +this.currentSalaryCodefrom;
    let toNumber = +this.currentSalaryCodeto;
    if (fromNumber > toNumber) {
      this.showErrorCurrentSalary = true;
    } else {
      this.showErrorCurrentSalary = false;
    }
  }

  showErrorRangeSalary: boolean = false;
  showErrorAgeRange: boolean = false;
  showErrorNoticeRange: boolean = false;

  getFromToo() {
    let fromSalary = +this.salaryofferRangeCodefrom;
    let toSalary = +this.salaryofferRangeCodeto;


    if (fromSalary > toSalary) {
      this.showErrorRangeSalary = true;
    } else {
      this.showErrorRangeSalary = false;
    }


  }
  getFromTo2() {
    let fromAge = +this.preferredAgeSelect;
    let toAge = +this.preferredAgeSelecttWo;

    if (fromAge > toAge) {
      this.showErrorAgeRange = true;
    } else {
      this.showErrorAgeRange = false;
    }
  }
  getFromTo3() {
    let fromAge = +this.noticePeriodSelect;
    let toAge = +this.noticePeriodSelectttt;

    if (fromAge > toAge) {
      this.showErrorNoticeRange = true;
    } else {
      this.showErrorNoticeRange = false;
    }
  }

  typeOfAppointmentData: any = [];
  GetTypeOfAppointment() {
    this.IJPService.GetTypeOfAppointment().subscribe(
      (res) => {
        this.typeOfAppointmentData = res;
      },
      (error) => {
        console.error('Error fetching type of appointment:', error);
      }
    );
  }

  documentTypes: any = [];
  GetDocumentType() {
    this.IJPService.GetDocumentType().subscribe((res) => {
      this.documentTypes = res;
      this.docJr = res
    });
  }

  getpostJob: any = [];
  GetPostJobTo() {
    this.IJPService.GetPostJobTo().subscribe((res: any) => {
      this.getpostJob = res;
      this.postIds = res;
    });
  }

  stage = [];
  GetStage() {
    this.IJPService.GetStage().subscribe((res) => {
      this.stage = res;
    });
  }

  preferdIndsutry: any = [];
  prefferedIndustryY: any = [];
  GetPreferredIndustry() {
    this.IJPService.GetPreferredIndustry().subscribe((res) => {
      this.preferdIndsutry = res;
      this.prefferedIndustryY = res;

    });
  }

  preferdDomain: any = [];
  GetPreferredDomain() {
    this.IJPService.GetPreferredDomain().subscribe((res) => {
      this.preferdDomain = res;
    });
  }

  higherLevelEducation = [];
  GetHigherlevelEducation() {
    this.IJPService.GetHigherlevelEducation().subscribe((res) => {
      this.higherLevelEducation = res;
    });
  }

  qualification = [];
  GetQualifications() {
    this.IJPService.GetQualifications().subscribe((res) => {
      this.qualification = res;
    });
  }

  languageKnown = [];
  GetLanguagesKnown() {
    this.IJPService.GetLanguagesKnown().subscribe((res) => {
      this.languageKnown = res;
    });
  }

  hiringManager = [];
  GetHiringManager() {
    this.IJPService.GetHiringManager().subscribe((res) => {
      this.hiringManager = res;
    });
  }

  recruitmentManager = [];
  GetRecruitmentManager() {
    this.IJPService.GetRecruitmentManager().subscribe((res) => {
      this.recruitmentManager = res;
    });
  }

  businessPartner = [];
  GetBusinessPartner() {
    this.IJPService.GetBusinessPartner().subscribe((res) => {
      this.businessPartner = res;

    });
  }

  reportingmanager = [];
  GetReportingManager() {
    this.IJPService.GetReportingManager().subscribe((res) => {
      this.reportingmanager = res;
    });
  }

  getcountriesss = [];
  GetCountry(id: any) {
    this.workPermitType = []
    this.IJPService.GetCountry(id).subscribe((res) => {
      this.getcountriesss = res;
    });
  }

  getemployeedetails: any[] = [];
  GetEmployeeDetails(id: any, i: number) {
    this.getemployeedetails[i] = [];
    // var dataemp:any=[]
    this.IJPService.GetEmployeeDetails(id).subscribe((res: any) => {
      const employeeDetails = { ...res };
      const tempArray = [...this.getemployeedetails];
      while (tempArray.length <= i) {
        tempArray.push(null);
      }

      tempArray[i] = employeeDetails;

      // Assign the temporary array back to getemployeedetails
      this.getemployeedetails = tempArray;
    });
  }

  workPermits = [
    { nationality_Id: 1, nationality_Name: 'Required' },
    { nationality_Id: 2, nationality_Name: 'Not Required' },
    { nationality_Id: 3, nationality_Name: ' Sponsorship' },
  ];

  workPermitDisabled: boolean = true;
  hideHash: boolean = true;

  workPermitChange(event: any) {
    this.workCountry = null
    this.workPermitType = null
    // console.log(event.nationality_Name);
    if (event.nationality_Name == 'Required') {
      this.workPermitDisabled = false;
      this.hideHash = true;
    }
    if ((event.nationality_Name == ' Sponsorship') || (event.nationality_Name == 'Not Required')) {
      this.workPermitDisabled = true;
      this.hideHash = false;
    }
    console.log(this.hideHash);

  }

  hideHashEditor: boolean = true;

  typeOfHiringEdit(event: any) {

    if (event.hiring_Name === 'Budgeted') {
      this.hideHashEditor = false;
    } else if (event.hiring_Name === 'Non-Budgeted') {
      this.hideHashEditor = true;
    }

  }




  GetGender = [
    { nationality_Name: 'Male' },
    { nationality_Name: 'Female' },
    { nationality_Name: ' Any' },
  ];

  GetFlexibleRelocate = [
    { nationality_Name: 'Yes' },
    { nationality_Name: 'No ' },
  ];

  GetPriority = [{ priority_Name: 'High Priority' }, { priority_Name: 'Normal Priority' }];
  GetEmployeeDuration = [
    { experience_Name: '0' },
    { experience_Name: '1' },
    { experience_Name: '2' },
    { experience_Name: '3' },
    { experience_Name: '4' },
    { experience_Name: '5' },
    { experience_Name: '6' },
    { experience_Name: '7' },
    { experience_Name: '8' },
    { experience_Name: '9' },
    { experience_Name: '10' },
    { experience_Name: '11' },
    { experience_Name: '12' },
    { experience_Name: '13' },
    { experience_Name: '14' },
    { experience_Name: '15' },
    { experience_Name: '16' },
    { experience_Name: '17' },
    { experience_Name: '18' },
    { experience_Name: '19' },
    { experience_Name: '20' },
    { experience_Name: '21' },
    { experience_Name: '22' },
    { experience_Name: '23' },
    { experience_Name: '24' },
    { experience_Name: '25' },
    { experience_Name: '26' },
    { experience_Name: '27' },
    { experience_Name: '28' },
    { experience_Name: '29' },
    { experience_Name: '30' },
    { experience_Name: '31' },
    { experience_Name: '32' },
    { experience_Name: '33' },
    { experience_Name: '34' },
    { experience_Name: '35' },
    { experience_Name: '36' },
    { experience_Name: '37' },
    { experience_Name: '38' },
    { experience_Name: '39' },
    { experience_Name: '40' },
    { experience_Name: '41' },
    { experience_Name: '42' },
    { experience_Name: '43' },
    { experience_Name: '44' },
    { experience_Name: '45' },
    { experience_Name: '46' },
    { experience_Name: '47' },
    { experience_Name: '48' },
    { experience_Name: '49' },
    { experience_Name: '50' },
  ];
  GetEmployeeDuration2 = [
    { experience_Name: '0' },
    { experience_Name: '1' },
    { experience_Name: '2' },
    { experience_Name: '3' },
    { experience_Name: '4' },
    { experience_Name: '5' },
    { experience_Name: '6' },
    { experience_Name: '7' },
    { experience_Name: '8' },
    { experience_Name: '9' },
    { experience_Name: '10' },
    { experience_Name: '11' },
    { experience_Name: '12' },
    { experience_Name: '13' },
    { experience_Name: '14' },
    { experience_Name: '15' },
    { experience_Name: '16' },
    { experience_Name: '17' },
    { experience_Name: '18' },
    { experience_Name: '19' },
    { experience_Name: '20' },
    { experience_Name: '21' },
    { experience_Name: '22' },
    { experience_Name: '23' },
    { experience_Name: '24' },
    { experience_Name: '25' },
    { experience_Name: '26' },
    { experience_Name: '27' },
    { experience_Name: '28' },
    { experience_Name: '29' },
    { experience_Name: '30' },
    { experience_Name: '31' },
    { experience_Name: '32' },
    { experience_Name: '33' },
    { experience_Name: '34' },
    { experience_Name: '35' },
    { experience_Name: '36' },
    { experience_Name: '37' },
    { experience_Name: '38' },
    { experience_Name: '39' },
    { experience_Name: '40' },
    { experience_Name: '41' },
    { experience_Name: '42' },
    { experience_Name: '43' },
    { experience_Name: '44' },
    { experience_Name: '45' },
    { experience_Name: '46' },
    { experience_Name: '47' },
    { experience_Name: '48' },
    { experience_Name: '49' },
    { experience_Name: '50' },
  ];

  GetDomainExperience = [
    { experience_Name: '0' },
    { experience_Name: '1' },
    { experience_Name: '2' },
    { experience_Name: '3' },
    { experience_Name: '4' },
    { experience_Name: '5' },
    { experience_Name: '6' },
    { experience_Name: '7' },
    { experience_Name: '8' },
    { experience_Name: '9' },
    { experience_Name: '10' },
    { experience_Name: '11' },
    { experience_Name: '12' },
    { experience_Name: '13' },
    { experience_Name: '14' },
    { experience_Name: '15' },
    { experience_Name: '16' },
    { experience_Name: '17' },
    { experience_Name: '18' },
    { experience_Name: '19' },
    { experience_Name: '20' },
    { experience_Name: '21' },
    { experience_Name: '22' },
    { experience_Name: '23' },
    { experience_Name: '24' },
    { experience_Name: '25' },
    { experience_Name: '26' },
    { experience_Name: '27' },
    { experience_Name: '28' },
    { experience_Name: '29' },
    { experience_Name: '30' },
    { experience_Name: '31' },
    { experience_Name: '32' },
    { experience_Name: '33' },
    { experience_Name: '34' },
    { experience_Name: '35' },
    { experience_Name: '36' },
    { experience_Name: '37' },
    { experience_Name: '38' },
    { experience_Name: '39' },
    { experience_Name: '40' },
    { experience_Name: '41' },
    { experience_Name: '42' },
    { experience_Name: '43' },
    { experience_Name: '44' },
    { experience_Name: '45' },
    { experience_Name: '46' },
    { experience_Name: '47' },
    { experience_Name: '48' },
    { experience_Name: '49' },
    { experience_Name: '50' },
  ];

  GetDomainExperience2 = [
    { experience_Name: '0' },
    { experience_Name: '1' },
    { experience_Name: '2' },
    { experience_Name: '3' },
    { experience_Name: '4' },
    { experience_Name: '5' },
    { experience_Name: '6' },
    { experience_Name: '7' },
    { experience_Name: '8' },
    { experience_Name: '9' },
    { experience_Name: '10' },
    { experience_Name: '11' },
    { experience_Name: '12' },
    { experience_Name: '13' },
    { experience_Name: '14' },
    { experience_Name: '15' },
    { experience_Name: '16' },
    { experience_Name: '17' },
    { experience_Name: '18' },
    { experience_Name: '19' },
    { experience_Name: '20' },
    { experience_Name: '21' },
    { experience_Name: '22' },
    { experience_Name: '23' },
    { experience_Name: '24' },
    { experience_Name: '25' },
    { experience_Name: '26' },
    { experience_Name: '27' },
    { experience_Name: '28' },
    { experience_Name: '29' },
    { experience_Name: '30' },
    { experience_Name: '31' },
    { experience_Name: '32' },
    { experience_Name: '33' },
    { experience_Name: '34' },
    { experience_Name: '35' },
    { experience_Name: '36' },
    { experience_Name: '37' },
    { experience_Name: '38' },
    { experience_Name: '39' },
    { experience_Name: '40' },
    { experience_Name: '41' },
    { experience_Name: '42' },
    { experience_Name: '43' },
    { experience_Name: '44' },
    { experience_Name: '45' },
    { experience_Name: '46' },
    { experience_Name: '47' },
    { experience_Name: '48' },
    { experience_Name: '49' },
    { experience_Name: '50' },
  ];

  GetIndustryExperience = [
    { experience_Name: '0' },
    { experience_Name: '1' },
    { experience_Name: '2' },
    { experience_Name: '3' },
    { experience_Name: '4' },
    { experience_Name: '5' },
    { experience_Name: '6' },
    { experience_Name: '7' },
    { experience_Name: '8' },
    { experience_Name: '9' },
    { experience_Name: '10' },
    { experience_Name: '11' },
    { experience_Name: '12' },
    { experience_Name: '13' },
    { experience_Name: '14' },
    { experience_Name: '15' },
    { experience_Name: '16' },
    { experience_Name: '17' },
    { experience_Name: '18' },
    { experience_Name: '19' },
    { experience_Name: '20' },
    { experience_Name: '21' },
    { experience_Name: '22' },
    { experience_Name: '23' },
    { experience_Name: '24' },
    { experience_Name: '25' },
    { experience_Name: '26' },
    { experience_Name: '27' },
    { experience_Name: '28' },
    { experience_Name: '29' },
    { experience_Name: '30' },
    { experience_Name: '31' },
    { experience_Name: '32' },
    { experience_Name: '33' },
    { experience_Name: '34' },
    { experience_Name: '35' },
    { experience_Name: '36' },
    { experience_Name: '37' },
    { experience_Name: '38' },
    { experience_Name: '39' },
    { experience_Name: '40' },
    { experience_Name: '41' },
    { experience_Name: '42' },
    { experience_Name: '43' },
    { experience_Name: '44' },
    { experience_Name: '45' },
    { experience_Name: '46' },
    { experience_Name: '47' },
    { experience_Name: '48' },
    { experience_Name: '49' },
    { experience_Name: '50' },
  ];
  GetIndustryExperiencetoo = [
    { experience_Name: '0' },
    { experience_Name: '1' },
    { experience_Name: '2' },
    { experience_Name: '3' },
    { experience_Name: '4' },
    { experience_Name: '5' },
    { experience_Name: '6' },
    { experience_Name: '7' },
    { experience_Name: '8' },
    { experience_Name: '9' },
    { experience_Name: '10' },
    { experience_Name: '11' },
    { experience_Name: '12' },
    { experience_Name: '13' },
    { experience_Name: '14' },
    { experience_Name: '15' },
    { experience_Name: '16' },
    { experience_Name: '17' },
    { experience_Name: '18' },
    { experience_Name: '19' },
    { experience_Name: '20' },
    { experience_Name: '21' },
    { experience_Name: '22' },
    { experience_Name: '23' },
    { experience_Name: '24' },
    { experience_Name: '25' },
    { experience_Name: '26' },
    { experience_Name: '27' },
    { experience_Name: '28' },
    { experience_Name: '29' },
    { experience_Name: '30' },
    { experience_Name: '31' },
    { experience_Name: '32' },
    { experience_Name: '33' },
    { experience_Name: '34' },
    { experience_Name: '35' },
    { experience_Name: '36' },
    { experience_Name: '37' },
    { experience_Name: '38' },
    { experience_Name: '39' },
    { experience_Name: '40' },
    { experience_Name: '41' },
    { experience_Name: '42' },
    { experience_Name: '43' },
    { experience_Name: '44' },
    { experience_Name: '45' },
    { experience_Name: '46' },
    { experience_Name: '47' },
    { experience_Name: '48' },
    { experience_Name: '49' },
    { experience_Name: '50' },
  ];

  workPermitss = [];
  public countryIdd: any;
  GetWorkPermitType(countryId: any) {
    this.IJPService.GetWorkPermitType(countryId).subscribe((res: any) => {
      this.workPermitss = res;
    });
  }

  candidateCountryList = [];
  GetCandidateCountryList() {
    this.IJPService.GetCandidateCountryList().subscribe((res: any) => {
      this.candidateCountryList = res;
    });
  }

  nationalities = [];
  GetNationality() {
    this.IJPService.GetNationality().subscribe((res) => {
      this.nationalities = res;
    });
  }

  getemployeeid: any = [];
  GetEmployeeId() {
    this.employeeList.employeeTable = [];
    this.IJPService.GetEmployeeId().subscribe((res) => {
      this.getemployeeid = res;
    });
  }

  currencycodess = [];
  GetCurrencyCode() {
    this.IJPService.GetCurrencyCode().subscribe((res: any) => {
      this.currencycodess = res;
    });
  }

  Currentcurrencycodess = [];
  GetCurrentCode() {
    this.IJPService.GetCurrencyCode().subscribe((res: any) => {
      this.Currentcurrencycodess = res;
    });
  }

  getEmployment = [];
  GetEmployment() {
    this.IJPService.GetEmployment().subscribe((res = '1') => {
      this.getEmployment = res;
    });
  }

  getEmployeeCtc = [];
  GetEmployeeCTCApprover() {
    this.IJPService.GetEmployeeCTCApprover().subscribe((res: any) => {
      this.getEmployeeCtc = res;
    });
  }

  hiringTeamMemberLists = [];
  GetHiringTeamMember() {
    this.IJPService.GetHiringTeamMember().subscribe((res: any) => {
      this.hiringTeamMember = res;
    });
  }

  showAllDate(e: any) {
    this.newDatesInfo();
    // this.getCurrencyCodes();
    if (
      e.jobPostId == 4 &&
      this.basicInfoData.expectedSalarySymbol != 'INR' &&
      this.basicInfoData.expectedSalarySymbol != 'USD'
    ) {
      this.basicInfoData.expectedSalarySymbol = '';
      this.basicInfoData.expectedSalaryFrom = '';
      this.basicInfoData.expectedSalaryTo = '';
    }
    // this.sourceData2 = this.soureData;
    if (e.jobPostId == this.basicInfoData.postJObTo) {
      console.log('true');
      // this.soureData.splice(this.basicInfoData.postJObTo);
    } else return;

    // this.jobsDatepostJob = e.jobPostName
  }

  newDatesInfo() {
    this.minPublishdate = new Date();
    this.minPublishdateCutOff = new Date(this.minPublishdate);
    var my = this.convert(this.minPublishdate);
    var myy = new Date(my);
    // alert(myy)
    // myy.setDate(myy.getDate() + 30);
    var eod = new Date(myy);
    eod.setDate(eod.getDate() + 1);
    this.edate1 = eod;

    var my1 = this.convert(this.minPublishdate);
    var myy1 = new Date(my1);
    myy1.setDate(myy1.getDate() + 90);

    this.hiring_date1 = myy1;
    myy.setDate(myy.getDate() + 30);
    this.myPublishStartDate = my;
    // alert(my)
    this.myPublishEndDate = this.convert(myy);
    // this.basicInfoData.hirirngProcessCutoffDate = this.convert(myy1)
  }
  convert(str: any) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join('/');
  }
  newonendDateSelected(e: any) { }
  addjobsDatesArray: any = [];

  addDatesData() {
    let obj = {
      postJobTo: this.mypostJObTo,
      startDate: this.myPublishStartDate,
      endDate: this.myPublishEndDate,
    };
    this.addjobsDatesArray.push(obj);

    this.selectedJobPostIds = this.addjobsDatesArray.map(
      (date: any) => date.postJobTo
    );

    this.selectedJob = this.getpostJob.find(
      (job: any) => job.ijpJobPostId === obj.postJobTo
    );

    if (this.selectedJob) {
      obj.postJobTo = this.selectedJob.ijpJobPostName;
    }
    this.getpostJob = this.getpostJob.filter(
      (job: any) => !this.selectedJobPostIds.includes(job.ijpJobPostId)
    );
    console.log('this.getpostJob', this.getpostJob);

    this.mypostJObTo = null;
    this.myPublishStartDate = null;
    this.myPublishEndDate = null;
    this.findMaxPublishEndDate();
  }
  findMaxPublishEndDate() {
    // var midnight = 'T00:00:00.000Z'
    let maxPublishEndDate = new Date(
      Math.max(
        ...this.addjobsDatesArray.map((job: any) => new Date(job.endDate))
      )
    );
    maxPublishEndDate.setDate(maxPublishEndDate.getDate() + 30);

    this.myHiringCutOffDate = this.convert(maxPublishEndDate);
    this.newCutoffdate = new Date(this.myHiringCutOffDate);
  }

  dateRecordsYes(index: number) {
    console.log(this.addjobsDatesArray, "=================")

    var data = [
      {
        "postJobTo": "IJP",
        "startDate": "06/18/2024",
        "endDate": "07/18/2024"
      }
    ]

    const removedRecord = this.addjobsDatesArray.splice(index, 1)[0];
    // console.log("removedRecord",removedRecord);
    var sorceInfo = this.getpostJob;

    if (removedRecord) {
      const matchingJobPost = this.postIds.find(
        (post: any) => post.ijpJobPostName === removedRecord.postJobTo
      );
      if (matchingJobPost) {
        var newRecord = {
          'ijpJobPostId': matchingJobPost.ijpJobPostId,
          'ijpJobPostName': removedRecord.postJobTo
        }
        this.getpostJob = this.getpostJob
          .filter((item: any) => this.postIds.includes(item.ijpJobPostId))
          .concat(newRecord);
      }
      var datainfo = [...sorceInfo, ...this.getpostJob];
      this.getpostJob = datainfo,
        this.myHiringCutOffDate = null,
        this.openIndexModal = false;
    }
  }

  dateRecordNo() {
    this.openIndexModal = false;
  }

  recruiterTable: any[] = [
    {
      hiringStage: 'Screening',
      hiringTeamMember: [],
      hiringTeamMemberList: ['name1', 'name2', 'name3'],
      interviewTemplate: [],
      interviewTemplateList: ['template1', 'template2'],
      interviewType: [],
      interviewTypeList: ['type1', 'type2'],
      cutOffDate: '',
    },
    {
      hiringStage: 'Demo',
      hiringTeamMember: [],
      hiringTeamMemberList: ['name4', 'name5', 'name6'],
      interviewTemplate: [],
      interviewTemplateList: ['template1', 'template2'],
      interviewType: [],
      interviewTypeList: ['type1', 'type2'],
      cutOffDate: '',
    },
    {
      hiringStage: 'Online Assessment',
      hiringTeamMember: [],
      hiringTeamMemberList: ['name4', 'name5', 'name6'],
      interviewTemplate: [],
      interviewTemplateList: ['template1', 'template2'],
      interviewType: [],
      interviewTypeList: ['type1', 'type2'],
      cutOffDate: '',
    },
    {
      hiringStage: 'Interview Round',
      hiringTeamMember: [],
      hiringTeamMemberList: ['name4', 'name5', 'name6'],
      interviewTemplate: [],
      interviewTemplateList: ['template1', 'template2'],
      interviewType: [],
      interviewTypeList: ['type1', 'type2'],
      cutOffDate: '',
    },
    {
      hiringStage: 'Interview Round',
      hiringTeamMember: [],
      hiringTeamMemberList: ['name4', 'name5', 'name6'],
      interviewTemplate: [],
      interviewTemplateList: ['template1', 'template2'],
      interviewType: [],
      interviewTypeList: ['type1', 'type2'],
      cutOffDate: '',
    },
  ];

  showAddButton: boolean = false;

  toggleAddButton() {
    this.showAddButton = this.recruiterTable.some((row) =>
      this.isAllFieldsSelected(row)
    );
  }
  appendedTableData: any[] = [];
  selectedRowIndex: number = -1;

  add() {
    if (this.selectedRowIndex !== -1) {
      const selectedRow = this.recruiterTable[this.selectedRowIndex];

      this.appendedTableData.push({
        Hiring_Stage: selectedRow.hiringStage,
        Hiring_Team_Member: selectedRow.hiringTeamMember,
        Interview_Template: selectedRow.interviewTemplate,
        Interview_Type: selectedRow.interviewType,
        Cut_Off_Date: selectedRow.cutOffDate,
      });

      this.selectedRowIndex = -1;
    }
  }

  isAllFieldsSelected(row: {
    hiringStage: string;
    hiringTeamMember: string | any[];
    interviewTemplate: string;
    interviewType: string;
    cutOffDate: null;
  }): boolean {
    return (
      row.hiringStage.length > 0 &&
      row.hiringTeamMember.length > 0 &&
      row.interviewTemplate.length > 0 &&
      row.interviewType.length > 0 &&
      row.cutOffDate !== null
    );
  }

  // selectedRow: any;
  // modalIndex: any;

  // openModal(item: any, index: number) {
  //   this.selectedRow = item;
  //   this.modalIndex = index;
  //   const modal = new bootstrap.Modal(document.getElementById(`staticBackdrop${index}`));
  //   modal.show();
  // }

  // closeModal(index: number) {
  //   const modal = new bootstrap.Modal(document.getElementById(`staticBackdrop${index}`));
  //   modal.hide();
  // }

  skillDataArr: any = {};
  deleteFieldss5(n: any) {
    console.log(this.skillDataArr.skillLists);
    this.skillDataArr.skillLists.splice(n, 1);
  }
  addFieldss5(n: any) {
    if (!this.isAddSkillButtonEnabled(this.skillDataArr.skillLists[n].skillCategory, this.skillDataArr.skillLists[n].skills)) {
      return;
    }
    console.log(this.skillDataArr.skillLists);
    let skilltype: any = [];
    skilltype = this.skillcategroy;
    for (let k = 0; k < this.skillDataArr.skillLists.length; k++) {
      for (let j = 0; j < skilltype.length; j++) {
        if (
          this.skillDataArr.skillLists[k] &&
          this.skillDataArr.skillLists[k].skillCategory
        )
          if (
            skilltype[j].skillId ==
            this.skillDataArr.skillLists[k].skillcategroy
          )
            var idToRemove = skilltype[j].skillsId;
        skilltype = skilltype.filter(
          (item: any) => item.skillsId !== idToRemove
        );
      }
    }


    this.skillcategroy = [];
    let obj = {
      skillCategory: null,
      skills: null,
    };

    this.skillDataArr.skillLists.push(obj);
    this.skillcategroy = skilltype;
  }

  isAddSkillButtonEnabled(skillCategory: any, skills: any[]): boolean {
    return skillCategory && skills && skills.length > 0;
  }
  //   skillCategory:any;
  //   skillCategoryItem = [
  //     {id: 1, name: 'Secondary School Teacher'},
  //     {id: 2, name: 'High School Teacher'},

  // ];
  //   skills:any;
  //   skillsItems = [
  //     {id: 1, name: 'Secondary School Teacher'},
  //     {id: 2, name: 'High School Teacher'},

  // ];

  skillcategroy = [];
  GetSkillsCategory() {
    this.IJPService.GetSkillsCategory().subscribe((res: any) => {
      this.skillcategroy = res;

    });
  }

  skills = [];
  getSkills(event: any, i: number) {
    let jobskills = this.skillDataArr.skillLists;
    jobskills[i].skills = [];
    var skillsId = event.skillsId;
    this.IJPService.GetSkills(skillsId).subscribe((res: any) => {
      this.skills = res;
      let jobSkill: any = this.skillDataArr.skillLists;
      jobSkill[i].skill_list = res;
    });
  }
  descriptionInfo() {
    var skillInfo = this.skillDataArr.skillLists;
    var skill_Info: any = [];
    for (let i = 0; i < skillInfo.length; i++) {
      const element = skillInfo[i].skills;
      var skill_data = {
        skillCategory: skillInfo[i].skillCategory,
        skills: skillInfo[i].skills.toString(),
      };
      skill_Info.push(skill_data);
    }
    var obj = {
      ijpJobDescriptionId: 0,
      ijpBasicInformationId: this.ijpBasicInformationId,
      overview: this.textControl1,
      responsibility: this.textControl2,
      skillsDescription: this.textControl3,
      websiteShortDescription: this.textControl4,
      disclaimer: this.textControl5,
      ijpjobskill: skill_Info,
      status: true,
    };
    var id = this.ijpJobDescriptionId;
    if (this.ijpJobDescriptionId > 0) {
      obj['ijpJobDescriptionId'] = this.ijpJobDescriptionId;
    }
    this.IJPService.postSkill(obj).subscribe((res: any) => {


      if (res) {
        this.activeStepIndex = 2;
        this.ijpJobDescriptionId = res.ijpJobDescriptionId;
        this.GetEligibleDepartment(this.ijpBasicInformationId)
      }
      else {
        this.activeStepIndex = 1;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something Went Wrong' });
      }

    });
  }
  hiringStages: any;
  GetIjpHiringStages() {
    this.IJPService.GetIjpHiringStages().subscribe((res: any) => {
      this.hiringStage = res;
      // this.hiring_Data = res
    });
  }
  GetIjpHiringStagesData() {
    this.IJPService.GetIjpHiringStages().subscribe((res: any) => {
      // this.hiringStage = res;
      this.hiring_Data = res
    });
  }
  getAllRoleDetails() {
    this.IJPService.getAllRoleDetails().subscribe((res: any) => {
      this.userRoleList = res;
    });
  }
  getInterviewQuestionTemplate() {
    this.IJPService.getInterviewQuestionTemplate().subscribe((res: any) => {
      this.Interview_list = res;
    });
  }
  InterviewwData = [
    { id: 1, name: 'Virtual' },
    { id: 2, name: 'F2F' },
  ];
  countArr: any = [];
  isAnyCheckboxChecked: boolean = false;
  updateCheckboxState(): void {
    this.countArr = [];
    for (let n = 0; n < this.hiringStage.length; n++) {
      if (
        this.hiringStage[n].hireStageCheckBooxOne == true &&
        (this.hiringStage[n].stageName === 'Screening' ||
          this.hiringStage[n].stageName === 'Interview Round Final' ||
          this.hiringStage[n].stageName === 'Performance Feedback' ||
          this.hiringStage[n].stageName === 'Approval Stage' ||
          this.hiringStage[n].stageName === 'Ref Check' ||
          this.hiringStage[n].stageName === 'Induction' ||
          this.hiringStage[n].stageName === 'Onboarding' ||
          this.hiringStage[n].stageName === 'Offer' ||
          this.hiringStage[n].stageName === 'Doc Check')
      ) {
        this.countArr.push(this.hiringStage[n].hireStageCheckBooxOne);
      }
    }
    if (this.countArr.length == 8 || this.teamArray.length >= 8) {
      this.isAnyCheckboxChecked = this.hiringStage.some(
        (item: any) => item.hireStageCheckBooxOne
      );
    } else {
      this.isAnyCheckboxChecked = false;
    }
  }
  teamArray: any = [];
  AddListObject: any;
  clearTeamArray() {
    // Move the elements from teamArray back to hiringStage
    // for (let i = 0; i < this.teamArray.length; i++) {
    //   let stageId = this.teamArray[i].hiringStage;
    //   let stageIndex = this.hiringStage.findIndex((stage: { stageId: any; }) => stage.stageId === stageId);
    //   if (stageIndex !== -1) {
    //     // If the stage exists, restore its data
    //     this.hiringStage[stageIndex].teamMember = this.teamArray[i].teamMember.split(',');
    //     this.hiringStage[stageIndex].template = this.teamArray[i].interviewTemplate;
    //     this.hiringStage[stageIndex].cutoffdate = this.teamArray[i].cutoffDate;
    //     this.hiringStage[stageIndex].interviewType = this.teamArray[i].interviewType;
    //     // Restore the checkbox state
    //     this.hiringStage[stageIndex].hireStageCheckBooxOne = true;
    //   } else {
    //     // If the stage does not exist, create a new one
    //     this.hiringStage.push({
    //       stageId: this.teamArray[i].hiringStage,
    //       teamMember: this.teamArray[i].teamMember.split(','),
    //       template: this.teamArray[i].interviewTemplate,
    //       cutoffdate: this.teamArray[i].cutoffDate,
    //       interviewType: this.teamArray[i].interviewType,
    //       // Set the checkbox state
    //       hireStageCheckBooxOne: true
    //     });
    //   }
    // }

    // Clear the teamArray
    this.teamArray = [];
    this.GetIjpHiringStages();

    // Reset the hiringStage data
    // this.resetHiringStageData();
  }


  resetHiringStageData() {
    // Reset the hiringStage data, except for the restored elements
    for (let i = 0; i < this.hiringStage.length; i++) {
      if (this.hiringStage[i].hireStageCheckBooxOne) {
        this.hiringStage[i].teamMember = [];
        this.hiringStage[i].template = null;
        this.hiringStage[i].cutoffdate = null;
        this.hiringStage[i].interviewType = null;
        this.hiringStage[i].hireStageCheckBooxOne = false;
      }
    }
  }
  addToHiringTeamTablee() {
    // Temporary array to store elements to add to teamArray
    let elementsToAdd = [];

    // Iterate through hiringStage to find elements with hireStageCheckBooxOne set to true
    for (let i = 0; i < this.hiringStage.length; i++) {
      if (
        this.hiringStage[i].hireStageCheckBooxOne == true &&
        this.hiringStage[i].hireStageCheckBooxOne != undefined
      ) {
        var obj = {
          hiringStage: this.hiringStage[i].stageId,
          teamMember: this.hiringStage[i].teamMember.toString(),
          interviewTemplate: this.hiringStage[i].template,
          cutoffDate: this.hiringStage[i].cutoffdate,
          interviewType: this.hiringStage[i].interviewType,
        };
        this.AddListObject = obj;
        elementsToAdd.push(obj);
      }
    }
    this.teamArray.push(...elementsToAdd);

    // Add elements to teamArray

    // Update teamArray elements with stage names and interview template names
    var hiringData = this.hiringStage;
    var teamData = this.teamArray;

    for (let j = 0; j < hiringData.length; j++) {
      for (let k = 0; k < teamData.length; k++) {
        if (teamData[k].hiringStage === hiringData[j].stageId) {
          teamData[k].hiringStage = hiringData[j].stageName;
          if (teamData[k].interviewTemplate != undefined) {
            for (let l = 0; l < this.Interview_list.length; l++) {
              const element = this.Interview_list[l];
              if (teamData[k].interviewTemplate === this.Interview_list[l].interviewTemplateId) {
                teamData[k].interviewTemplate = this.Interview_list[l].interviewTemplateName;
              }
            }
          }
        }
      }
    }

    // Filter out the added elements from hiringStage
    this.hiringStage = this.hiringStage.filter((stage: { hireStageCheckBooxOne: any; }) => !stage.hireStageCheckBooxOne);

    // Reset the remaining hiringStage data
    this.resetHiringStageData();
  }




  GetEligibleCampus(brid: any, couin: any) { }

  GetCountries(eligibleBrandss: any) {
    this.eligibleCountryyyy = [];
    this.eligibleCampussss = []
    this.IJPService.GetEligibleCountry(eligibleBrandss).subscribe(
      (res: any) => {
        this.eligibleCountry = res.data;
      }
    );
  }

  GetCampusss(eligibleCountryyyy: any) {
    this.eligibleCampussss = []
    this.IJPService.GetEligibleCampus(
      this.eligibleBrandss,
      eligibleCountryyyy
    ).subscribe((res: any) => {
      this.campusInfo = res;
    });
  }
  // eligibleBrandss(eligibleBrandss: any, eligibleCountryyyy: any) {
  //   throw new Error('Method not implemented.');
  // }

  postIJPTargetAudience() {
    const payloadOfTarget = {
      id: 0,
      ijpBasicInformationId:
        this.ijpBasicInformationId > 0 ? this.ijpBasicInformationId : 0,
      brandId: this.eligibleBrandss.toString(),
      countryId: this.eligibleCountryyyy.toString(),
      campusId: this.eligibleCampussss.toString(),
      departmentId: this.eligibleDepartments.toString(),
      roleId: this.eligibleRolesss.toString(),
      status: true,
    };
    console.log('payloadOfTarget', payloadOfTarget);

    this.IJPService.PostIJPTargetAudience(payloadOfTarget).subscribe(
      (res: any) => {

        if (res) {
          this.activeStepIndex = 4;
          this.ijpBasicInformationId = res.ijpBasicInformationId;

        }
        else {
          this.activeStepIndex = 3;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something Went Wrong' });
        }
      }
    );
  }
  deleteSingleItem(i: any) {
    this.displayedItems.splice(i, 1);
  }
  eligibleBrands = [];
  GetEligibleBrand(id: any) {
    this.IJPService.GetEligibleBrand(id).subscribe((res: any) => {
      this.eligibleBrands = res.data;
    });
  }
  remainingCount: number = 0;
  // displayedItems: any[] = [];
  updateDisplayedItems(): void {
    this.displayedItems = this.showSelectedNames.slice(0, 5);
    this.remainingCount = this.showSelectedNames.length - 5;
  }
  // eligibleRole = [];
  // GetEligibleRole(ijpBasicInformationId:any) {
  //   this.IJPService.GetEligibleRole(ijpBasicInformationId).subscribe((res: any) => {
  //     this.eligibleRole = res;
  //   });
  // }

  eligibleDepartment: any = [];
  GetEligibleDepartment(ijpBasicInformationId: any) {
    var dataArray: any = []
    this.IJPService.GetEligibleDepartment(ijpBasicInformationId).subscribe((res: any) => {
      dataArray.push(res)
      this.eligibleDepartment = dataArray;
      this.eligibleDepartments = [res.departmentId]
      this.eligibleRolesss = [res.roleId]
    });
  }
  approverTableArray: any[] = [];

  approverTable: any[] = [
    {
      level: '1',
      country: 'Singapore',
      designation: 'Regional / Country Director',
      hiringTeamName: [], filteredMembers: []
    },
    {
      level: '2',
      country: 'Singapore',
      designation: 'Regional HR Team',
      hiringTeamName: [], filteredMembers: []
    },
  ];
  selectionTable: any[] = [
    {
      level: '1',
      name: 'Lincoln Westervelt',
      stage: 'Screening',
      template: 'Template 1',
      cutOffDate: 'Aug 20, 2023',
      interviewType: 'Interview Type',
      groupDiscussion: '',
    },
    {
      level: '1',
      name: 'Lincoln Westervelt',
      stage: 'Screening',
      template: 'Template 1',
      cutOffDate: 'Aug 20, 2023',
      interviewType: 'Interview Type',
      groupDiscussion: '',
    },
    {
      level: '1',
      name: 'Lincoln Westervelt',
      stage: 'Screening',
      template: 'Template 1',
      cutOffDate: 'Aug 20, 2023',
      interviewType: 'Interview Type',
      groupDiscussion: '',
    },
  ];
  GetMemberName() {
    this.IJPService.GetMemberName().subscribe((res: any) => {
      this.memberName = res;
      this.memberName = this.memberName.filter(
        (job: any) => !this.selectedJobPostIds.includes(job.userId)
      );
      this.updateFilteredMembers();
    });
  }
  updateFilteredMembers() {
    const allSelectedMembers = this.getAllSelectedMembers();
    this.approverTable.forEach((row, index) => {
      row.filteredMembers = this.memberName.filter((member: any) =>
        !allSelectedMembers.includes(member.userId) || row.hiringTeamName.includes(member.userId)
      );
    });
  }

  getAllSelectedMembers() {
    return this.approverTable.reduce((acc, row) => {
      return acc.concat(row.hiringTeamName);
    }, []);
  }

  onMemberSelect(index: number) {
    this.updateFilteredMembers();
  }
  // getFilteredMembers(index: number) {
  //   let selectedMembers :any = [];
  //   for (let i = 0; i < index; i++) {
  //     selectedMembers = selectedMembers.concat(this.approverTable[i].hiringTeamName);
  //   }
  //   return this.memberName.filter((member:any) => !selectedMembers.includes(member.userId));
  // }

  PostIJPJobApprovers() {
    let approverTables: any[] = [];
    for (let i = 0; i < this.approverTable.length; i++) {
      var obj = {
        approveMember: this.approverTable[i].hiringTeamName.toString(),
        role: this.approverTable[i].designation.toString(),
        levels: this.approverTable[i].level.toString(),
      };

      approverTables.push(obj);
    }
    var approver = {
      ijpBasicInformationId: this.ijpBasicInformationId,
      ijpReportingmanagerId: 0,
      reportingmanager: this.applicantApproval,
      iJPJobApprover: approverTables,
    };

    console.log(approver, '========');
    console.log(this.approverTable, '++++++++++++');

    this.IJPService.PostIJPJobApprovers(approver).subscribe((res: any) => {
      this.ijpBasicInformationId = res.ijpBasicInformationId;
    });
  }

  changeofDoc(documentType: any) {
    console.log(".....documentType", documentType);

  }
  treditId: boolean = false; enableReferralCampaign: boolean = false
  reportingManagerId: any
  editListingById(edId: any) {
    this.treditId = true
    this.ijpBasicInformationId = parseInt(edId)
    this.GetEligibleDepartment(edId)
    this.IJPService.EditIJP(edId).subscribe((res: any) => {
      var basicInfo = res.ijpBasicInformation
      var descriptionInfo = res.ijpJobDescription
      var hiringApprover = res.ijpEmploymentCtcApprover
      var targetData = res?.targetAudience[0]
      var reportingData = res.reportingManager
      this.trInfo = basicInfo.trName
      this.trid = basicInfo.trId
      // this.getTRInfo(this.trid)
      this.jobtitle = basicInfo.titleName
      this.mySchool = basicInfo.brandName
      this.brandInfoData = basicInfo.brand
      this.countryName = basicInfo.countryName
      this.campus = basicInfo.campusName
      this.campusInfoData = basicInfo.campus
      this.countryInfoData = basicInfo.country
      this.jobtitleData = basicInfo.jobTitle
      this.department = basicInfo.department
      this.deptNameId = basicInfo.department
      this.displayToApplicants =basicInfo.displayToApplicants
      this.jobCategory = basicInfo.jobCategory
      this.priority = basicInfo.priority
      this.numberofposition = basicInfo.numberOfPositions
      var typeofhiring = basicInfo.typeOfHiring
      if (typeofhiring == '2') {
        this.hideHashEditor = true
        this.typeofhiring = basicInfo.typeOfHiring
      } else if (typeofhiring == '1') {
        this.hideHashEditor = false
        this.typeofhiring = basicInfo.typeOfHiring
      }
      this.textControl = basicInfo.rationalForHiringNonBudgetedPosition
      this.typeofemp = basicInfo.typesOfEmployment
      this.typeofdurationn = basicInfo.employmentDuration
      this.employeeList.employeeTable = basicInfo.ijpjobreplacement
      var replace = basicInfo.ijpjobreplacement
      for (let l = 0; l < replace.length; l++) {
        var dateemployeeId = replace[l].employeeId;
        this.employeeIddd[l] = dateemployeeId
        this.GetEmployeeDetails(this.employeeIddd[l], l)
      }
      // var valreporting = basicInfo.reportingManager
      if (basicInfo.reportingManager == 0) {
        this.reportingManagerId = null
      } else {
        this.reportingManagerId = basicInfo.reportingManager
      }
      this.typeofappointment = basicInfo.typesOfAppointment
      if (basicInfo.ijpJobPostedData) {

      }
      this.addjobsDatesArray = basicInfo.ijpJobPostedData
      if (this.addjobsDatesArray) {
        let data = this.addjobsDatesArray;
        for (let i = 0; i < data.length; i++) {
          this.selectedJobPostIds = this.addjobsDatesArray.map(
            (date: any) => date.postjobto
          );

          this.selectedJob = this.getpostJob.find(
            (job: any) => job.ijpJobPostName === data[i].postjobto
          );

          if (this.selectedJob) {
            data[i].postJobTo = this.selectedJob.jobPostName;
          }
          this.getpostJob = this.getpostJob.filter(
            (job: any) => !this.selectedJobPostIds.includes(job.ijpJobPostName)
          );
          if (data[i].postjobto == 'Referral Campaign') {
            this.enableReferralCampaign = true
          }
        }
      }
      var arrayMy: any = []
      for (let k = 0; k < basicInfo.ijpJobPostedData.length; k++) {
        let obj = {
          postJobTo: basicInfo.ijpJobPostedData[k].postjobto,
          startDate: basicInfo.ijpJobPostedData[k].publishstartdate,
          endDate: basicInfo.ijpJobPostedData[k].publishenddate,
        };
        arrayMy.push(obj)
      }
      this.addjobsDatesArray = arrayMy;
      this.myHiringCutOffDate = this.convert(basicInfo.hirirngProcessCutoffDate);
      this.expJoiningDate = this.convert(basicInfo.expectedDateOfJoining);

      var documentData = basicInfo.ijpdocument
      for (let l = 0; l < documentData.length; l++) {
        var docValue = documentData[l].documentType?.split(',')
        var docValueArray: any = []
        if (docValue.length > 0) {
          docValue = docValue.forEach((element: any) => {
            docValueArray.push(parseInt(element))
          });
          let obj = {
            documentType: docValueArray,
            stages: +documentData[l].stage
          }
          this.document_Type.push(obj)
        }
      }
      console.log(this.document_Type)
      this.requiredDocArr.requiredDocList = this.document_Type
      console.log(basicInfo.ijpindustry)
      if (basicInfo.ijpindustry) {
        var indData = basicInfo.ijpindustry;
        var IndArray = [];
        for (let i = 0; i < indData.length; i++) {
          if (
            indData[i].prefferedIndustry == 0 &&
            indData[i].prefferedIndustryExperienceFrom == 0 &&
            indData[i].prefferedIndustryExperienceTo == 0
          ) {
            var objectind = {
              prefferedIndustry: null,
              prefferedIndustryExperienceFrom: null,
              prefferedIndustryExperienceTo: null,
            };
            IndArray.push(objectind);
            this.industryObj.industries = IndArray;
          } else {
            var object = {
              prefferedIndustry: indData[i].prefferedIndustry,
              prefferedIndustryExperienceFrom:
                indData[i].prefferedIndustryExperienceFrom,
              prefferedIndustryExperienceTo:
                indData[i].prefferedIndustryExperienceTo,
            };
            IndArray.push(object);
            this.industryObj.industries = IndArray;
          }
        }
      }
      // this.domainObj.ijpdomain=basicInfo.ijpdomain
      if (basicInfo.ijpdomain) {
        var domainsData = basicInfo.ijpdomain;
        var domArray = [];
        for (let i = 0; i < domainsData.length; i++) {
          if (
            domainsData[i].preferedDomain == 0 &&
            domainsData[i].domainExperienceFrom == 0 &&
            domainsData[i].domainExperienceTo == 0
          ) {
            var domObj = {
              preferedDomain: null,
              domainExperienceFrom: null,
              domainExperienceTo: null,
            };
            domArray.push(domObj);
            this.domainObj.ijpdomain = domArray;
          } else {
            var domainObj = {
              preferedDomain: domainsData[i].preferedDomain,
              domainExperienceFrom: domainsData[i].domainExperienceFrom,
              domainExperienceTo: domainsData[i].domainExperienceTo,
            };
            domArray.push(domainObj);
            this.domainObj.ijpdomain = domArray;
          }
        }
      }

      this.totalExperienceSelect = basicInfo.totalExperienceTo
      this.totalExperienceSelect2 = basicInfo.totalExperienceFrom
      this.totalExperience = basicInfo.currentSalarySymbol
      this.salaryofferRangeCode = basicInfo.salaryOfferRangeSymbol
      this.salaryofferRangeCodefrom = basicInfo.salaryOfferRangeFrom
      this.salaryofferRangeCodeto = basicInfo.salaryOfferRangeTo
      if (basicInfo.preferredHigherEducation == 0) {
        this.higherLevelEdu = null
      } else {
        this.higherLevelEdu = basicInfo.preferredHigherEducation
      }
      this.Knownnlanguages = basicInfo?.preferredLanguagesKnown?.split(",").map(Number)
      if (basicInfo?.preferredQualifications == '') {
        this.qualifications = null
      } else {
        this.qualifications = basicInfo?.preferredQualifications?.split(",").map(Number)
      }
      if (basicInfo.preferredNationality == 0) {
        this.myNationality = null
      } else {
        this.myNationality = basicInfo?.preferredNationality
      }
      if (basicInfo?.preferredGender) {
        let gender = basicInfo?.preferredGender;
        if (gender.indexOf(',') > -1) {
          let arraydoc = gender.split(',');
          this.gender = arraydoc;
        } else {
          let singleDoc = gender.split(' ');
          this.gender = singleDoc;
        }
      }
      this.relocateFlexible = basicInfo.flexibleToRelocate
      this.noticePeriodSelect = basicInfo.noticePeriodFrom
      this.noticePeriodSelectttt = basicInfo.noticePeriodTo
      if (basicInfo.preferredAgeBarFrom == 0) {
        this.preferredAgeSelect = null
      } else {
        this.preferredAgeSelect = basicInfo.preferredAgeBarFrom
      }
      if (basicInfo.preferredAgeBarTo == 0) {
        this.preferredAgeSelecttWo = null
      } else {
        this.preferredAgeSelecttWo = basicInfo.preferredAgeBarTo
      }
      this.hiringmanager = basicInfo.hiringManager
      this.requitermanager = basicInfo.recruitmentManager
      this.businesspartner = basicInfo.businessPartner
      if (basicInfo.currentSalaryFrom == 0) {
        this.currentSalaryCodefrom = null
      } else {
        this.currentSalaryCodefrom = basicInfo.currentSalaryFrom
      }
      if (basicInfo.currentSalaryTo == 0) {
        this.currentSalaryCodeto = null
      } else {
        this.currentSalaryCodeto = basicInfo.currentSalaryTo
      }
      this.workPermit = parseInt(basicInfo.workPermit)
      if (this.workPermit == 1) {
        this.workPermitDisabled = false
      } else {
        this.workPermitDisabled = true
      }

      if (basicInfo.workPermitCountry == 0) {
        this.workCountry = null
      } else {
        this.workCountry = basicInfo.workPermitCountry
        this.GetCountry(this.workCountry)
      }
      if (basicInfo.workPermitType == 0) {
        this.workPermitType = null
      } else {
        this.workPermitType = parseInt(basicInfo.workPermitType)
      }
      this.textControl1 = descriptionInfo.overview
      this.textControl2 = descriptionInfo.responsibility
      this.textControl3 = descriptionInfo.skillsDescription
      this.textControl4 = descriptionInfo.websiteShortDescription
      this.textControl5 = descriptionInfo.disclaimer
      this.skillDataArr.skillLists = descriptionInfo?.ijpjobskill

      setTimeout(() => {
        if (this.skillDataArr.skillLists) {
          let skills = this.skillDataArr.skillLists;
          for (let i = 0; i < skills.length; i++) {
            this.GetSkillNameById(skills[i].skillCategory, i);
            let skillName = skills[i].skills;
            if (skillName.indexOf(',') > -1) {
              let arraydoc = skillName.split(',');
              let skillnumarray = arraydoc.map(Number);
              skills[i].skills = skillnumarray;
            } else {
              let singleDoc = skillName.split(' ');
              let skillnumarray = singleDoc.map(Number);
              skills[i].skills = skillnumarray;
            }
          }
        }
      }, 1000);
      this.GetIjpHiringStages()
      this.GetIjpHiringStagesData()
      this.empCtcApprover = hiringApprover.employmentCtcApprover
      var stage_Array = hiringApprover.iJPHiringTeam
      var hiringArray: any = []
      for (let m = 0; m < stage_Array.length; m++) {
        var data = {
          hiringStage: stage_Array[m].hiringStageName,
          teamMember: stage_Array[m].teamMember,
          interviewTemplate: stage_Array[m].interviewTemplateName,
          cutoffDate: stage_Array[m].cutoffDate,
          interviewType: stage_Array[m].interviewType,
        };
        hiringArray.push(data)
      }
      this.teamArray = hiringArray
      setTimeout(() => {
        if (this.hiringStage && this.hiringStage.length > 0) {
          for (let i = 0; i < this.hiringStage.length; i++) {
            for (let j = 0; j < this.teamArray.length; j++) {
              if (this.teamArray[j].hiringStage == this.hiringStage[i].stageName) {
                this.hiringStage.splice(i, 1)
              }
            }
          }
        }
        console.log(this.hiringStage, "===========")
        console.log(this.hiringStage, "==========hiringStage=")
        console.log(this.hiring_Data,"==========GetIjpHiringStages")
      }, 2000);
      this.eligibleBrandss = targetData?.brandId?.split(",").map(Number)
      this.GetCountries(this.eligibleBrandss.join(','))
      this.eligibleCountryyyy = targetData?.countryId?.split(",").map(Number)
      this.GetCampusss(this.eligibleCountryyyy.join(','))
      this.eligibleCampussss = targetData?.campusId?.split(",").map(Number)
      this.eligibleRolesss = targetData?.roleId?.split(",").map(Number)
      this.eligibleDepartments = targetData?.departmentId?.split(",").map(Number)
      this.applicantApproval = reportingData?.reportingmanager.toString()
      var repotData = reportingData?.iJPJobApprover

      var repArray: any = []
      for (let n = 0; n < repotData.length; n++) {
        var obj = {
          hiringTeamName: repotData[n].approveMemberId.split(",").map(Number),
          level: repotData[n].levels,
          designation: repotData[n].role,
        }
        repArray.push(obj)
      }
      this.approverTable = repArray
      // reportingData.iJPJobApprover
      // if (reportingData.approverTable==true) {
      // }else{
      //   this.applicantApproval='no'
      // }
      // this.salaryofferRangeCodeto = basicInfo.preferredHigherEducation
      // this.selectedJobPostIds = this.addjobsDatesArray.map(
      //   (date: any) => date.postJobTo
      // );

      // this.selectedJob = this.getpostJob.find(
      //   (job: any) => job.ijpJobPostId === obj.postJobTo
      // );

      // // this.reportingManager = res.ijpBasicInformation.reportingManager
      // // this.reportingManager = res.ijpBasicInformation.departmentName

      // this.reportingManager = res.ijpBasicInformation.reportingManager
      // // this.mypostJObTo = res.ijpBasicInformation.ijpJobPostedData.postjobto
      // // this.mypostJObTo.ijpJobPostedData.push({
      // //   postjobto: 'IJP'
      // // });
      // this.textControl = res.ijpBasicInformation.rationalForHiringNonBudgetedPosition
      // this.totalExperienceSelect = res.ijpBasicInformation.totalExperienceFrom
      // this.totalExperienceSelect2 = res.ijpBasicInformation.totalExperience
      // this.totalExperience = res.ijpBasicInformation.currentSalarySymbol

      // this.salaryofferRangeCode = res.ijpBasicInformation.salaryOfferRangeSymbol
      // this.salaryofferRangeCodefrom = res.ijpBasicInformation.salaryOfferRangeFrom
      // this.salaryofferRangeCodeto = res.ijpBasicInformation.salaryOfferRangeTo
      // this.Knownnlanguages = res.ijpBasicInformation.preferredLanguagesKnown //this should be name

      // this.higherLevelEdu = res.ijpBasicInformation.preferredHigherEducation
      // this.myNationality = res.ijpBasicInformation.preferredNationality
      // this.relocateFlexible = res.ijpBasicInformation.flexibleToRelocate
      // this.noticePeriodSelect = res.ijpBasicInformation.noticePeriodFrom
      // this.noticePeriodSelectttt = res.ijpBasicInformation.noticePeriodTo
      // this.preferredAgeSelect = res.ijpBasicInformation.preferredAgeBarFrom
      // this.preferredAgeSelecttWo = res.ijpBasicInformation.preferredAgeBarTo
      // var valworkit=res.ijpBasicInformation.workPermit
      // this.workPermit = parseInt(valworkit)
      // this.workCountry = res.ijpBasicInformation.workPermitCountrys
      // this.workPermitType = res.ijpBasicInformation.workPermitTypes
      // this.basicInfoData.gender = res.ijpBasicInformation.preferredGender

















      // ijpdocument







      console.log(".....trid", this.trid);

    });
  }
  // visible: boolean = false;

  showDialog() {
    this.visible = true;
  }
  openIndexModal: boolean = false;
  toggleIndexModal() {
    this.openIndexModal = true;
  }
  // list_skills: any = [];
  async GetSkillNameById(event: any, i: any) {
    let skillid = event;
    this.IJPService.GetSkills(skillid).subscribe(async (res) => {
      let jobSkill = this.skillDataArr.skillLists;
      jobSkill[i].skill_list = res;

      // console.log(jobSkill[i].skills,"======================skill_list")
    });
  }
  //shabbberstart
  currencyArray: any = []
  GetAllCountryDetails() {
    this.IJPService.GetAllCountryDetails().subscribe(async (res) => {
      this.currencyArray = res.data

      // console.log(jobSkill[i].skills,"======================skill_list")
    });

  }
  arrayvalues: any = []; tenuredata: any = []

  GetIjpReferralById() {

    this.IJPService.GetIjpReferralById(this.userId).subscribe(async (res) => {
      if (res != null) {

        var tenutredata = res?.tenure

        for (var x = 0; x < tenutredata.length; x++) {

          let obj = {
            refreeTenureSelection: tenutredata[x].refreeTenureSelection,
            addAmount: tenutredata[x].addAmount,
          };
          this.addBonusArr.addBonusArrItem.push(obj);

        }

      }





    });

  }

  // percentagecaluculate: any = []
  // caluculatedata(val: any, index: any) {
  //   this.percentagecaluculate = this.addBonusArr.addBonusArrItem
  //   for (var m = 1; m < this.percentagecaluculate.length; m++) {

  //     var count = parseInt(this.percentagecaluculate[m].addAmount) + parseInt(val)
  //     console.log(count)
  //     if (count <= 100 && !isNaN(count)) {

  //     } else {
  //       this.addBonusArr.addBonusArrItem[index].addAmount = null
  //     }


  //   }

  // }

  valuechage() {
    this.addBonusArr.addBonusArrItem = []
    let obj = {
      refreeTenureSelection: null,
      addAmount: null,

    };
    this.addBonusArr.addBonusArrItem.push(obj);
    this.maxValue = null


  }

  valueChange1() {
    this.currencyListSelection1 = null
    this.referralAmount = 0
    this.minimumTenureSelection = null
    this.maxValue = 0
  }
  //shabbeerend


  employmentExp: any;
  roleDuration: any;
  // numberOfPositionsReferral:number = 0;
  amount: any = '10,000';
  amount1: any = '10,000';
  amount2: any = '10,000';
  referralCurrencyListSelection: any;
  input1: any;
  input2: any;
  bonusFrom: any;
  bonusFrom1: any;
  bonusTo: any;
  bonusTo1: any;
  addAmount: any;
  selectedBonusType: string = 'flat';
  additionalBonusType: string = 'na';
  referralBonusArr: any = {};
  addBonusArr: any = {};
  referralBonus: any = '1';
  // referralAmount: number = 0;
  referralApplicable: string = 'applicable';


  setReferralApplicable(value: string) {
    this.referralApplicable = value;
    if (this.referralApplicable == 'flat') {
      this.selectedBonusType = 'flat'
    } else {
      this.selectedBonusType = ''

    }

    this.currencyListSelection1 = null
    this.programTypeListSelection = null
    this.programTypeListSelection = null
    this.referralCurrencyListSelection = null
    this.numberOfPositionsReferral = null
    this.currencyListSelection1 = null
    this.referralAmount = 0
    this.maxValue = 0
    this.minimumTenureSelection = null
    this.addBonusArr.addBonusArrItem = []
    let obj = {
      refreeTenureSelection: null,
      addAmount: null,

    };

    this.addBonusArr.addBonusArrItem.push(obj);
  }
  numberOfPositionsReferral: any = 0;
  referralAmount: any = 0;
  maxValue: any = 0;

  updateMaxValue(): void {
    console.log(this.referralAmount, this.numberOfPositionsReferral, 'this.referralAmount')
    const positions = Number(this.numberOfPositionsReferral);
    const amount = Number(this.referralAmount);
    if (!isNaN(positions) && !isNaN(amount)) {
      this.maxValue = positions * amount;
    } else {
      this.maxValue = 0;
    }


  }
  // updateMaxValuetuner(): void {
  //   alert(this.amount1)
  //   const positions = Number(this.numberOfPositionsReferral);
  //   const amount = Number(this.amount1);
  //   debugger
  //   if (!isNaN(positions) && !isNaN(amount)) {
  //     this.maxValue = positions * amount;
  //   } else {
  //     this.maxValue = 0;
  //   }


  // }


  eligibleBrandSelection: any;
  eligibleBrand = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 5, name: 'Kaunas' },
    { id: 9, name: 'Kaunas' },
  ];


  eligibleCountrySelection: any = null;
  eligibleCountryReferral = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
  ];

  eligibleCampusSelection: any;
  eligibleCampus = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
  ];

  eligibleDepartmentSelection: any;
  eligibleDepartmentReferral = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
  ];
  programTypeListSelection: any;
  programTypeList = [
    { id: 1, name: 'Monetary Reward' },
    { id: 2, name: 'Kaunas' },
  ];
  currencyListSelection: any;
  currencyList = [
    { id: 1, name: 'INR' },
    { id: 2, name: 'USD' },
  ];
  currencyListSelection1: any;
  currencyList1 = [
    { id: 1, name: 'INR' },
    { id: 2, name: 'USD' },
  ];

  eligibleRoleSelection: any;
  eligibleRole = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
  ];
  minimumTenureSelection: any;
  minimumTenure = [
    { id: 1, name: '1year' },
    { id: 2, name: '2years' },
  ];
  minimumTenureSelectionSlab: any;
  minimumTenureSlab = [
    { id: 1, name: '1year' },
    { id: 2, name: '2years' },
  ];
  refreeTenureCurrencySelection: any;
  refreeTenureCurrency = [
    { id: 1, name: 'INR' },
    { id: 2, name: 'USD' },
  ];
  refreeTenureSelection: any;
  refreeTenure = [
    { id: 1, name: '1 months' },
    { id: 2, name: '2 months' },
    { id: 1, name: '3 months' },
    { id: 2, name: '4 months' },
    { id: 1, name: '5 months' },
    { id: 2, name: '6 months' },
    { id: 1, name: '7 months' },
    { id: 2, name: '8 months' },
    { id: 1, name: '9 months' },
    { id: 2, name: '10 months' },
    { id: 1, name: '11 months' },
    { id: 2, name: '12 months' },
  ];

  referralApproverTable: any[] = [
    { level: '1', country: 'Singapore', designation: 'Regional / Country Director', MemberName: 'Brandon Herwitz', MailID: 'chance324@mail.com' },
    { level: '2', country: 'Singapore', designation: 'Regional HR Team', MemberName: 'Brandon Herwitz', MailID: 'chance324@mail.com' },
  ];


  deleteFieldssRef1(x: any) {
    // console.log(this.referralBonusArr.referralBonusArrItem);
    this.referralBonusArr.referralBonusArrItem.splice(x, 1);
  }
  addFieldssRef1(x: any) {
    // console.log(this.referralBonusArr.referralBonusArrItem);
    let obj = {
      bonusFrom: null,
      bonusTo: null,
      amount1: null,
    };

    this.referralBonusArr.referralBonusArrItem.push(obj);
  }
  deleteFieldssRef2(q: any) {
    // console.log(this.addBonusArr.addBonusArrItem);
    this.addBonusArr.addBonusArrItem.splice(q, 1);
  }
  addFieldssRef2(q: any) {
    // console.log(this.addBonusArr.addBonusArrItem);
    let obj = {
      refreeTenureSelection: null,
      addAmount: null,

    };

    this.addBonusArr.addBonusArrItem.push(obj);
  }


  mycalenderOpened: boolean = false;

  onCalendarOpen() {
    this.mycalenderOpened = true;
    console.log('Calendar opened');
  }

  onCalendarClose() {
    this.mycalenderOpened = false;
    console.log('Calendar closed');
  }

}
