<section class="system_fields_main background__wrapper">
  <h1 class="system_field_heading"> Admin </h1>
  <div class="custom-bor" *ngFor="let item of adminFields; let i-index;">

    <div class="candidate-related-main">
      <h3 class="mb-4 related_heading"> Department Related </h3>
      <div class="row">

        <!-- <div class="col-md-4 mb-2  ">
          <div (click)="routeSettings2()" class="system_field_tiles ">
            <h4>{{item.Content3}}</h4>
          </div>
        </div> -->

        <!-- <a disabled class="system_field_tiles mb-2 no-cursor ml-3" style="background: transparent; border: 0; width:410px" > -->
        <!-- (click)="routeSettings4()" -->
          <!-- <div class=""> -->
            <!-- <h4>{{item.Content5}}</h4> -->
          <!-- </div> -->
        <!-- </a> -->
        <!-- <div class="col-md-4 mb-2">
          <div (click)="routeSettings4()" class="system_field_tiles">
            <h4>{{item.Content5}}</h4>
          </div>
        </div> -->

        <div class="col-md-4 mb-2">
          <div (click)="routeSettings6()" class="system_field_tiles">
            <h4>{{item.Content7}}</h4>
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div (click)="routeSettings7()" class="system_field_tiles">
            <h4>{{item.Content8}}</h4>
          </div>
        </div>
        <!-- <div class="col-md-4 mb-2 ">
          <div (click)="routeSettings8()" class=" ">
            <h4>{{item.Content9}}</h4>
          </div>
        </div> -->
        <div class="col-md-4 mb-2">
          <div (click)="routeSettings13()" class="system_field_tiles ">
            <h4>{{item.content11}}</h4>
          </div>
        </div>

        <div class="col-md-4 mb-2">
          <div (click)="routeSettings14()" class="system_field_tiles ">
            <h4>{{item.content12}}</h4>
          </div>
        </div>

        <!-- <div class="col-md-4 mb-2 ">
          <div (click)="routeSettings18()" class=" ">
            <h4>{{item.content16}}</h4>
          </div>
        </div> -->





        <!-- <div class="col-md-4 mb-2 " style="width: 400px;">
          <div (click)="routeSettings22()" class="system_field_tiles ">
            <h4>{{item.jobRemunerationn}}</h4>
          </div>
        </div>

        <div class="col-md-4 mb-2 " style="width: 400px;">
          <div (click)="routeSettingsEntity()" class=" system_field_tiles">
            <h4>{{item.Entityy}}</h4>
          </div>
        </div> -->

      </div>
    </div>

    <div class="refCheck_border_bottom_line"></div>

    <div class="applicant-Related-main">
      <h3 class="related_heading mb-4"> Applicant Related </h3>
      <div class="row">
        <div class="col-md-4 mb-2  ">
          <div (click)="routeSettings()" class="system_field_tiles">
            <h4>{{item.Content1}}</h4>
          </div>
        </div>

        <!-- <div class="col-md-4 mb-2   ">
          <div (click)="routeSettings1()" class="system_field_tiles ">
            <h4>{{item.Content2}}</h4>
          </div>
        </div>

        <div class="col-md-4 mb-2   ">
          <div (click)="routeSettings3()" class=" system_field_tiles">
            <h4>{{item.Content4}}</h4>
          </div>
        </div> -->

        <div class="col-md-4 mb-2   ">
          <div (click)="routeSettings5()" class="system_field_tiles">
            <h4>{{item.Content6}}</h4>
          </div>
        </div>


        <!-- <div class="col-md-4 mb-2   ">
          <div (click)="routeSettings15()" class="system_field_tiles ">
            <h4>{{item.content13}}</h4>
          </div>
        </div>

        <div class="col-md-4 mb-2   ">
          <div (click)="routeSettings16()" class="system_field_tiles ">
            <h4>{{item.content14}}</h4>
          </div>
        </div>

        <div class="col-md-4 mb-2   ">
          <div (click)="routeSettings17()" class="system_field_tiles ">
            <h4>{{item.content15 }}</h4>
          </div>
        </div> -->
        <!-- <div class="col-md-4 mb-2   ">
          <div (click)="routeSettings19()" class="system_field_tiles ">
            <h4>{{item.content17}}</h4>
          </div>
        </div> -->
        <!-- <div class="col-md-4 mb-2">
          <div (click)="routeSettings20()" class="system_field_tiles ">
            <h4>{{item.skillsCategory}}</h4>
          </div>
        </div> -->


        <!-- <div class="col-md-4 mb-2 ">
          <div (click)="routeSettings21()" class=" ">
            <h4>{{item.interviewTemp}}</h4>
          </div>
        </div> -->

      </div>
    </div>
  </div>

  <!-- <h1 class="head-h1">Form Fields</h1> -->
  <!-- <div class="row" *ngFor="let item of formfield; let i-index;">
        <div class="col-md-4 mb-2">
            <div  (click)="routeSettings8()" class=" ">
                <h4>{{item.Content1}}</h4>
            </div>
        </div>
        <div class="col-md-4 mb-2 ">
            <div  (click)="routeSettings9()" class=" ">
                <h4>{{item.Content2}}</h4>
            </div>
        </div>
        <div class="col-md-4 mb-2">
            <div   (click)="routeSettings10()" class=" ">
                <h4>{{item.Content3}}</h4>
            </div>
        </div>
        <div class="col-md-4 mb-2">
            <div   (click)="routeSettings11()" class=" ">
                <h4>{{item.Content4}}</h4>
            </div>
        </div>
    </div>     -->
</section>
