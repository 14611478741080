<div class="basic-job-details-main">
  <div class="d-flex gap16">
    <div class="basic-job-nav-part">
      <div class="job-nav-numbers pointer" (click)="clickCheck(1)">
        <p [ngClass]="{ 'blue-background': check1 }">1</p>
      </div>
      <div class="job-nav-numbers pointer" (click)="clickCheck(2)">
        <p [ngClass]="{ 'blue-background': check2 }">2</p>
      </div>
      <div class="job-nav-numbers pointer" (click)="clickCheck(3)">
        <p [ngClass]="{ 'blue-background': check3 }">3</p>
      </div>
      <div class="job-nav-numbers pointer" (click)="clickCheck(4)">
        <p [ngClass]="{ 'blue-background': check4 }">4</p>
      </div>
      <div class="job-nav-numbers pointer" (click)="clickCheck(5)">
        <p [ngClass]="{ 'blue-background': check5 }">5</p>
      </div>
    </div>
    <div class="basic-job-main-part w-100">
      <div
        class="basic-info-line-part white-background p16 br8 d-flex align-items-center justify-content-between"
      >
        <h1 class="blue-color fs14 fw600">
          Step 1 - Basic Information
        </h1>
        <div class="d-flex align-items-center">
          <h2>Marketing Manager</h2>
          <div class="job-id-part d-flex align-items-center gap16">
            <img
              src="./assets/Images/approval.svg"
              alt="approval"
            />
            <h3>Job ID- A01232</h3>
          </div>
        </div>
      </div>
      <form [formGroup]="basicJobDetailsForm">
      <div
        class="job-info-inputs-part white-background p16 br8 mt-3"
      >
        <h1 class="fw600 fs16">Job Information</h1>
        <div class="line"></div>
        <div class="job-info-inputs">
          <div class="figma-input">
            <label> TR-ID </label>
            <input type="text" placeholder="Enter TR-ID" />
          </div>
          <div class="figma-ng-select">
            <label> Post Job To </label>
            <ng-select placeholder="Select post job to"
              [items]="postJobToItems"
              bindLabel="name"
              bindValue="id"
              [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedPostJob"
            >
            </ng-select>
          </div>
          <div class="figma-ng-select">
            <label> Job Title </label>
            <ng-select placeholder="Select job title"
              [items]="jobTitleItems"
              bindLabel="name"
              bindValue="id"
              [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedJobTitle"
            >
            </ng-select>
          </div>
          <div class="figma-ng-select">
            <label> Country </label>
            <ng-select placeholder="Select country"
              [items]="CountryItems"
              bindLabel="name"
              bindValue="id"
              [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedCountry"
            >
            </ng-select>
          </div>
          <div class="figma-ng-select">
            <label> Campus </label>
            <ng-select placeholder="Select campus"
              [items]="CampusItems"
              bindLabel="name"
              bindValue="id"
              [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedCampus"
            >
            </ng-select>
          </div>
          <div class="figma-ng-select">
            <label> Job Category </label>
            <ng-select placeholder="Select job category"
              [items]="jobCatItems"
              bindLabel="name"
              bindValue="id"
              [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedJobCat"
            >
            </ng-select>
          </div>
          <div class="figma-ng-select">
            <label> Priority </label>
            <ng-select placeholder="Select priority"
              [items]="PriorityItems"
              bindLabel="name"
              bindValue="id"
              [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedPriority"
            >
            </ng-select>
          </div>
          <div class="figma-ng-select">
            <label> Number Of Positions </label>
            <ng-select placeholder="Select number of positions"
              [items]="positionsItems"
              bindLabel="name"
              bindValue="id"
              [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedpositions"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="type-of-hiring-card white-background p16 br8 mt-3">
        <h1 class="fw600 fs14 mb-3"> Type of Hiring </h1>
        <div class="line2"></div>
        <div class="figma-ng-select">
          <label> Type of Hiring </label>
          <ng-select placeholder="Select type of hiring"
            [items]="HiringTypeItems"
            bindLabel="name"
            bindValue="id"
            [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedHiringType"
          >
          </ng-select>
        </div>
        <div class="text-editor mt-3">
          <label class="fw500 fs12 blackk"> Rational for Hiring Non-Budgeted position </label>
        </div>


      </div>
      <div class="type-of-hiring-card white-background p16 br8 mt-3">
        <h1 class="fw600 fs14 mb-3"> Employment Related </h1>
        <div class="line2"></div>
        <div class="job-info-inputs">
        <div class="figma-ng-select">
          <label> Type of Appointment </label>
          <ng-select placeholder="Select type of appointment"
            [items]="appointmentTypeItems"
            bindLabel="name"
            bindValue="id"
            [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedappointmentType"
          >
          </ng-select>
        </div>
        <div class="figma-ng-select">
          <label> Type Of Employment </label>
          <ng-select placeholder="Select type of employment"
            [items]="EmploymentTypeItems"
            bindLabel="name"
            bindValue="id"
            [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedEmploymentType"
          >
          </ng-select>
        </div>

        <div class="figma-calender">
          <label> Employment Duration(In Days) </label>
          <p-calendar formControlName="employeeDuration" name="formControlName"
           placeholder="Select employment duration" [showIcon]="true"></p-calendar>
        </div>

        <div class="figma-ng-select">
          <label> Tentative Job Start Date </label>
          <ng-select placeholder="Select tentative job start date"
            [items]="JobStartDateItems"
            bindLabel="name"
            bindValue="id"
            [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedJobStartDate"
          >
          </ng-select>
        </div>

        <div class="figma-ng-select">
          <label> Reporting Manager </label>
          <ng-select placeholder="Select reporting manager"
            [items]="ReportingManagerItems"
            bindLabel="name"
            bindValue="id"
            [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedReportingManager"
          >
          </ng-select>
        </div>

        <div class="figma-calender">
          <label class="d-flex align-items-center">
            <img class="img-fluid me-1" pInputText pTooltip=" Hiring Process Cut-off Date  Hiring Process Cut-off Date"
             tooltipPosition="bottom" src="./assets/Images/tooltip-info-icon.svg" alt="info" />
            Hiring Process Cut-off Date </label>
          <p-calendar formControlName="hiringCutoffDate" name="hiringCutoffDate" placeholder="Select hiring cutoff date" [showIcon]="true"></p-calendar>
        </div>

        <div class="figma-calender">
          <label class="d-flex align-items-center">Publish Start Date </label>
          <p-calendar formControlName="publishStartDate" name="publishStartDate" placeholder="Select publish start date" [showIcon]="true"></p-calendar>
        </div>

        <div class="figma-calender">
          <label class="d-flex align-items-center">Publish End Date </label>
          <p-calendar formControlName="publishEndDate" name="publishEndDate" placeholder="Select publish end date" [showIcon]="true"></p-calendar>
        </div>

        <div></div>

        <div>
          <label class="fw500 fs12"> Minimum Offer Salary Range(Monthly) </label>
          <div class="d-flex align-items-center mt-2 gap10">
        <div class="figma-ng-select col-md-3">
          <ng-select placeholder=""
          [items]="minOfferCurrency"
          bindLabel="name"
          bindValue="id"
          [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedminOfferCurrency"
        >
        </ng-select>
        </div>
        <div class="figma-input d-flex align-items-center gap10">
          <input class="mt-0" type="text" placeholder="Enter" />
          <span class="fw500 fs12"> to </span>
          <input class="mt-0" type="text" placeholder="Enter" />
        </div>
        </div>
      </div>

      <div>
        <label class="fw500 fs12"> Maximum Offer Salary Range(Monthly) </label>
        <div class="d-flex align-items-center mt-2 gap10">
      <div class="figma-ng-select col-md-3">
        <ng-select placeholder=""
        [items]="maxOfferCurrency"
        bindLabel="name"
        bindValue="id"
        [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedMaxOfferCurrency">
      </ng-select>
      </div>
      <div class="figma-input d-flex align-items-center gap10">
        <input class="mt-0" type="text" placeholder="Enter" />
        <span class="fw500 fs12"> to </span>
        <input class="mt-0" type="text" placeholder="Enter" />
      </div>
      </div>
    </div>

    <div class="display-to-applicants">
      <div class="d-flex align-items-center">
      <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
      <label class="form-check-label ms-2 fw400 fs14" for="defaultCheck1"> Display To Applicants </label>
    </div>
    </div>
        </div>
      </div>
      <div class="type-of-hiring-card white-background p16 br8 mt-3">
        <h1 class="fs14 fw600"> Hiring SPOC’s </h1>
        <div class="line"></div>
        <div class="job-info-inputs">
        <div class="figma-ng-select">
          <label> Hiring Manager </label>
          <ng-select placeholder="Select hiring manager"
            [items]="hiringManagerItems"
            bindLabel="name"
            bindValue="id"
            [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedHiringManager"
          >
          </ng-select>
        </div>

        <div class="figma-ng-select">
          <label> Recruitment Manager </label>
          <ng-select placeholder="Select recruitment Manager"
            [items]="recruitmentManagerItems"
            bindLabel="name"
            bindValue="id"
            [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedRecruitmentManager"
          >
          </ng-select>
        </div>

        <div class="figma-ng-select">
          <label> Business Partner </label>
          <ng-select placeholder="Select business partner"
            [items]="businessPartnerItems"
            bindLabel="name"
            bindValue="id"
            [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedbusinessPartner"
          >
          </ng-select>
        </div>

      </div>
    </div>
    <div class="type-of-hiring-card white-background p16 br8 mt-3">
      <label class="d-flex align-items-center fs14 fw600">Applicant Eligibility Criteria
        <img class="img-fluid h24 ms-1" pInputText pTooltip="Applicant Eligibility CriteriaApplicant Eligibility CriteriaApplicant Eligibility Criteria"
         tooltipPosition="bottom" src="./assets/Images/tooltip-info-icon.svg" alt="info" />
          </label>

      <div class="line"></div>

      <div [formGroup]="prefIndustryForm">
        <div formArrayName="items" *ngFor="let item of prefIndustryForm.get('items').controls; let i = index;">
      <div [formGroupName]="i">
      <div class="job-info-inputs mt-2">
        <div class="figma-ng-select">
          <label> Preferred Industry </label>
          <ng-select placeholder="Select preferred industry"
            [items]="prefIndustryItems"
            bindLabel="name" formControlName="selectedPrefIndustryy" name="selectedPrefIndustryy"
            bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustry" -->
          </ng-select>
        </div>

        <div class="figma-ng-select">
          <label> Experience </label>
          <div class="d-flex align-items-center gap16">
          <ng-select class="col-md-5" placeholder=""
            [items]="prefIndustryExpItems" formControlName="selectedPrefIndustryExpp" name="selectedPrefIndustryExpp"
            bindLabel="name" bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustryExp" -->
          </ng-select>
          <span class="fw500 fs12"> To </span>
          <ng-select class="col-md-5" placeholder=""
            [items]="prefIndustryExpItemsTwo" formControlName="selectedPrefIndustryExpTwoo" name="selectedPrefIndustryExpTwoo"
            bindLabel="name" bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustryExpTwo" -->
          </ng-select>
        </div>
      </div>
      <div class="display-to-applicants">
        <p class="underline blue-color fw700 fs14 pointer" (click)="addItem()"> + Add </p>
      </div>
  </div>
  </div>
  </div>
      </div>

      <!-- domain form -->
      <div [formGroup]="domainForm">
        <div formArrayName="domainItems" *ngFor="let item of domainForm.get('domainItems').controls; let i = index;">
      <div [formGroupName]="i">
      <div class="job-info-inputs mt-2">
        <div class="figma-ng-select">
          <label> Preferred Domain </label>
          <ng-select placeholder="Select preferred industry"
            [items]="prefIndustryItems"
            bindLabel="name" formControlName="selectedPrefDomain" name="selectedPrefDomain"
            bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustry" -->
          </ng-select>
        </div>

        <div class="figma-ng-select">
          <label> Experience </label>
          <div class="d-flex align-items-center gap16">
          <ng-select class="col-md-5" placeholder=""
            [items]="prefIndustryExpItems" formControlName="selectedPrefDomainExpp" name="selectedPrefDomainExpp"
            bindLabel="name" bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustryExp" -->
          </ng-select>
          <span class="fw500 fs12"> To </span>
          <ng-select class="col-md-5" placeholder=""
            [items]="prefIndustryExpItemsTwo" formControlName="selectedDomainExpTwoo" name="selectedDomainExpTwoo"
            bindLabel="name" bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustryExpTwo" -->
          </ng-select>
        </div>
      </div>
      <div class="display-to-applicants">
        <p class="underline blue-color fw700 fs14 pointer" (click)="addDomainItem()"> + Add </p>
      </div>
  </div>
  </div>
  </div>
      </div>
      <!-- domain form -->

      <!-- total experience in years -->
      <div [formGroup]="totalSalaryForm">
        <div formArrayName="totalSalaryItems" *ngFor="let item of totalSalaryForm.get('totalSalaryItems').controls; let i = index;">
      <div [formGroupName]="i">
      <div class="job-info-inputs mt-2">
        <div class="figma-ng-select">
          <label> Total Experience( In Years) </label>
          <ng-select placeholder="Select preferred industry"
            [items]="prefIndustryItems"
            bindLabel="name" formControlName="selectedTotalExperience" name="selectedTotalExperience"
            bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustry" -->
          </ng-select>
        </div>

        <div class="figma-ng-select">
          <label> Current Salary Range( Monthly) </label>
          <div class="d-flex align-items-center gap16">
          <ng-select class="col-md-5" placeholder=""
            [items]="prefIndustryExpItems" formControlName="selectedCurrentSalaryOne" name="selectedCurrentSalaryOne"
            bindLabel="name" bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustryExp" -->
          </ng-select>
          <span class="fw500 fs12"> To </span>
          <ng-select class="col-md-5" placeholder=""
            [items]="prefIndustryExpItemsTwo" formControlName="selectedCurrentSalaryTwo" name="selectedCurrentSalaryTwo"
            bindLabel="name" bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustryExpTwo" -->
          </ng-select>
        </div>
      </div>
      <div class="display-to-applicants">
        <p class="underline blue-color fw700 fs14 pointer" (click)="addTotalSalaryItem()"> + Add </p>
      </div>
  </div>
  </div>
  </div>
      </div>
      <!-- total experience in years -->
      <div class="job-info-inputs">
      <div class="figma-ng-select">
        <label> Preferred Higher Education </label>
        <ng-select placeholder="Select higher education"
          [items]="higherEducationItems"
          bindLabel="name"
          bindValue="id"
          [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedHigherEducation"
        >
        </ng-select>
      </div>

      <div class="figma-ng-select">
        <label> Preferred Qualifications </label>
        <ng-select placeholder="Select qualifications"
          [items]="QualificationsItems"
          bindLabel="name"
          bindValue="id"
          [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedQualifications"
        >
        </ng-select>
      </div>

      <div class="figma-ng-select">
        <label> Preferred Languages Known </label>
        <ng-select placeholder="Select languages"
          [items]="languagesItems"
          bindLabel="name"
          bindValue="id"
          [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedLanguages"
        >
        </ng-select>
      </div>

      <div class="figma-ng-select">
        <label> Preferred Nationality </label>
        <ng-select placeholder="Select nationality"
          [items]="nationalityItems"
          bindLabel="name"
          bindValue="id"
          [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedNationality"
        >
        </ng-select>
      </div>

      <div class="figma-ng-select">
        <label> Preferred gender </label>
        <ng-select placeholder="Select gender"
          [items]="genderItems"
          bindLabel="name"
          bindValue="id"
          [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedGender"
        >
        </ng-select>
      </div>

      <div class="figma-input notice-period">
        <label> Notice period ( in Days ) </label>
        <div class=" d-flex align-items-center gap10">
        <input type='text' formControlName="noticePeriodFrom" name='noticePeriodFromm' maxlength="3"
        onkeypress="return /[0-9]/i.test(event.key)" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"/>
        <span class="fw500 fs12"> To </span>
        <input type='text' formControlName="noticePeriodTo" name='noticePeriodToo' maxlength="3"
        onkeypress="return /[0-9]/i.test(event.key)" oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"/>
      </div>
      </div>
  </div>
  </div>

  <div class="type-of-hiring-card white-background p16 br8 mt-3">
    <h1 class="fs14 fw600"> Required Documents </h1>
    <div class="line"></div>
    <div class="job-info-inputs">

      <div [formGroup]="requiredDocumentForm">
        <div formArrayName="reqDocsItems" *ngFor="let item of requiredDocumentForm.get('reqDocsItems').controls; let i = index;">
      <div [formGroupName]="i">
      <div class="job-info-inputs mt-2">
        <div class="figma-ng-select">
          <label> Document Type </label>
          <ng-select placeholder="Select document type"
            [items]="documentTypeItems"
            bindLabel="name" formControlName="selectedDocumentType" name="selectedDocumentType"
            bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustry" -->
          </ng-select>
        </div>

        <div class="figma-ng-select">
          <label> Stage </label>
          <div class="d-flex align-items-center gap16">
          <ng-select class="col-md-5" placeholder=""
            [items]="prefIndustryExpItems" formControlName="selectedDocStage" name="selectedDocStage"
            bindLabel="name" bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustryExp" -->
          </ng-select>
          <span class="fw500 fs12"> To </span>
          <ng-select class="col-md-5" placeholder=""
            [items]="prefIndustryExpItemsTwo" formControlName="selectedDocStageTwo" name="selectedDocStageTwo"
            bindLabel="name" bindValue="id">
            <!--  [(ngModel)] ="selectedPrefIndustryExpTwo" -->
          </ng-select>
        </div>
      </div>
      <div class="display-to-applicants">
        <p class="underline blue-color fw700 fs14 pointer" (click)="addReqDocsItem()"> + Add </p>
      </div>
  </div>
  </div>
  </div>
      </div>


    </div>
  </div>

  <div class="type-of-hiring-card white-background p16 br8 mt-3">
    <h1 class="fs14 fw600"> Work Permit Related </h1>
    <div class="line"></div>
    <div class="job-info-inputs">
      <div class="figma-ng-select">
        <label> Work Permit </label>
        <ng-select placeholder="Select work permit"
          [items]="workpermitItems"
          bindLabel="name"
          bindValue="id"
          [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedWorkPermitt"
        >
        </ng-select>
      </div>

      <div class="figma-ng-select">
        <label class="d-flex align-items-center">
          <img class="img-fluid me-1" pInputText pTooltip="Choose only the Country for which the Job is applicable"
           tooltipPosition="bottom" src="./assets/Images/tooltip-info-icon.svg" alt="info" />
           Country </label>
        <ng-select placeholder="Select country"
          [items]="workCountryItems"
          bindLabel="name"
          bindValue="id"
          [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedWorkCountryy"
        >
        </ng-select>
      </div>

      <div class="figma-ng-select">
        <label> Work Permit Type </label>
        <ng-select placeholder="Select work permit type"
          [items]="workpermitItemsType"
          bindLabel="name"
          bindValue="id"
          [ngModelOptions]="{standalone: true}" [(ngModel)] ="selectedWorkPermittType"
        >
        </ng-select>
      </div>


    </div>
  </div>

    <div class="type-of-hiring-card white-background p16 br8 mt-3">
      <h1 class="fs14 fw600"> Job Post Related Dates </h1>
      <div class="line"></div>
      <div class="job-info-inputs">

        <div class="figma-calender figma-tooltip">
          <label class="d-flex align-items-center">
            <img class="img-fluid me-1" pInputText pTooltip="Hiring Process Cut-off Date  Hiring Process Cut-off Date"
             tooltipPosition="bottom" src="./assets/Images/tooltip-info-icon.svg" alt="info" />
            Hiring Process Cut-off Date </label>
          <p-calendar formControlName="hiringCutoffDateTwo" name="hiringCutoffDateTwo" placeholder="Select hiring cutoff date" [showIcon]="true"></p-calendar>
        </div>

        <div class="figma-calender">
          <label class="d-flex align-items-center">Publish Start Date </label>
          <p-calendar formControlName="publishStartDateTwo" name="publishStartDateTwo" placeholder="Select publish start date" [showIcon]="true"></p-calendar>
        </div>

        <div class="figma-calender">
          <label class="d-flex align-items-center">Publish End Date </label>
          <p-calendar formControlName="publishEndDateTwo" name="publishEndDateTwo" placeholder="Select publish end date" [showIcon]="true"></p-calendar>
        </div>

        </div>
    </div>

    <div class="type-of-hiring-card white-background p16 br8 my-3">
      <button type="submit" class="blue-btn ms-auto d-flex" routerLink="../job-description"> Next </button>
    </div>

    </form>
    </div>
  </div>
</div>
