<ng-container *ngIf="!isLoginPage()">
  <app-header></app-header>
</ng-container>

<main class="app--main-body" [ngClass]="{ 'pt-4': !isLoginPage() }">
  <div [ngClass]="{ 'pt-5': !isLoginPage() }">
    <div class="d-md-flex position-relative" [ngClass]="{ 'p-3': !isLoginPage() }">
      <div class="" [ngClass]="{ 'w-10': !isLoginPage() && isSideExpanded,'w-25': !isLoginPage() && !isSideExpanded }" *ngIf="!isLoginPage()">
        <app-side-nav class="side-bar-coll" (toggleSideNav)="toggleSideClick()"></app-side-nav>
      </div>
      <div class="main-body-part" [ngClass]="{'ps-3': !isLoginPage(),'col-md-12': isLoginPage(),'w-90': !isLoginPage() && isSideExpanded,'w-75': !isLoginPage() && !isSideExpanded}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>

<!-- <div class="d-md-flex mt-4">
    <div *ngIf="!isLoginPage">
    <app-side-nav></app-side-nav>
  </div>
    <div class="main-body-part w-100">
      <router-outlet></router-outlet>
    </div>
  </div> -->
