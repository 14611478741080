import { Component, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-basic-job-details',
  templateUrl: './basic-job-details.component.html',
  styleUrls: ['./basic-job-details.component.scss'],
})
export class BasicJobDetailsComponent {
  breadcrumbitem: any;
  // home: any;
  employeeDuration: Date | undefined;
  hiringCutoffDate: Date | undefined;
  publishStartDate: Date | undefined;
  publishEndDate: Date | undefined;
  hiringCutoffDateTwo: Date | undefined;
  publishStartDateTwo: Date | undefined;
  publishEndDateTwo: Date | undefined;

  basicJobDetailsForm: any;
  prefIndustryForm: any;
  items: any;

  domainForm: any;
  domainItems: any;

  totalExperienceForm: any;
  totalExperienceFormItems: any;

  totalSalaryForm: any;
  totalSalaryItems: any;

  requiredDocumentForm: any;
  reqDocsItems: any;

  showBasicDetails = true;
  showJobDescription = false;
  showJobApprovers = false;

  constructor(public fb: FormBuilder) {}

  ngOnInit() {
    // this.items = [{ label: 'Basic' }, { label: 'Website' }];
    // this.home = { icon: 'pi pi-home', routerLink: '/' };

    this.basicJobDetailsForm = this.fb.group({
      employeeDuration: [''],
      hiringCutoffDate: [''],
      publishStartDate: [''],
      publishEndDate: [''],
      noticePeriodFrom: [''],
      noticePeriodTo: [''],
      hiringCutoffDateTwo: [''],
      publishStartDateTwo: [''],
      publishEndDateTwo: [''],
    });
    this.prefIndustryForm = new FormGroup({
      items: new FormArray([]),
    });
    this.addItem();

    this.domainForm = new FormGroup({
      domainItems: new FormArray([]),
    });
    this.addDomainItem();

    this.totalSalaryForm = new FormGroup({
      totalSalaryItems: new FormArray([]),
    });
    this.addTotalSalaryItem();

    this.requiredDocumentForm = new FormGroup({
      reqDocsItems: new FormArray([]),
    });
    this.addReqDocsItem();
  }

  createItem(): FormGroup {
    return this.fb.group({
      selectedPrefIndustryy: '',
      selectedPrefIndustryExpp: '',
      selectedPrefIndustryExpTwoo: '',
    });
  }

  addItem(): void {
    this.items = this.prefIndustryForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }

  createDomainItem(): FormGroup {
    return this.fb.group({
      selectedPrefDomain: '',
      selectedPrefDomainExpp: '',
      selectedDomainExpTwoo: '',
    });
  }

  addDomainItem(): void {
    this.domainItems = this.domainForm.get('domainItems') as FormArray;
    this.domainItems.push(this.createDomainItem());
  }

  createTotalExpItem(): FormGroup {
    return this.fb.group({
      selectedPrefDomain: '',
      selectedPrefDomainExpp: '',
      selectedDomainExpTwoo: '',
    });
  }

  addTotalExpItem(): void {
    this.domainItems = this.domainForm.get('domainItems') as FormArray;
    this.domainItems.push(this.createTotalExpItem());
  }

  createTotalSalaryItem(): FormGroup {
    return this.fb.group({
      selectedTotalExperience: '',
      selectedCurrentSalaryOne: '',
      selectedCurrentSalaryTwo: '',
    });
  }

  addTotalSalaryItem(): void {
    this.totalSalaryItems = this.totalSalaryForm.get(
      'totalSalaryItems'
    ) as FormArray;
    this.totalSalaryItems.push(this.createTotalSalaryItem());
  }

  createReqDocsItem(): FormGroup {
    return this.fb.group({
      selectedDocumentType: '',
      selectedDocStage: '',
      selectedDocStageTwo: '',
    });
  }

  addReqDocsItem(): void {
    this.reqDocsItems = this.requiredDocumentForm.get(
      'reqDocsItems'
    ) as FormArray;
    this.reqDocsItems.push(this.createReqDocsItem());
  }

  check1: boolean = false;
  check2: boolean = false;
  check3: boolean = false;
  check4: boolean = false;
  check5: boolean = false;
  clickCheck(data: any) {
    if (data == '1') {
      this.check1 = true;
      this.check2 = false;
      this.check3 = false;
      this.check4 = false;
      this.check5 = false;
    }
    if (data == '2') {
      this.check1 = true;
      this.check2 = true;
      this.check3 = false;
      this.check4 = false;
      this.check5 = false;
    }
    if (data == '3') {
      this.check1 = true;
      this.check2 = true;
      this.check3 = true;
      this.check4 = false;
      this.check5 = false;
    }
    if (data == '4') {
      this.check1 = true;
      this.check2 = true;
      this.check3 = true;
      this.check4 = true;
      this.check5 = false;
    }
    if (data == '5') {
      this.check1 = true;
      this.check2 = true;
      this.check3 = true;
      this.check4 = true;
      this.check5 = true;
    }
  }

  selectedPostJob: any;
  postJobToItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedJobTitle: any;
  jobTitleItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedCountry: any;
  CountryItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedCampus: any;
  CampusItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedJobCat: any;
  jobCatItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedPriority: any;
  PriorityItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedpositions: any;
  positionsItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedHiringType: any;
  HiringTypeItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedappointmentType: any;
  appointmentTypeItems = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Replacement' },
  ];

  selectedEmploymentType: any;
  EmploymentTypeItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedJobStartDate: any;
  JobStartDateItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedReportingManager: any;
  ReportingManagerItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedminOfferCurrency: any;
  minOfferCurrency = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedMaxOfferCurrency: any;
  maxOfferCurrency = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedHiringManager: any;
  hiringManagerItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedRecruitmentManager: any;
  recruitmentManagerItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedbusinessPartner: any;
  businessPartnerItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedPrefIndustry: any;
  prefIndustryItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedPrefIndustryExp: any;
  prefIndustryExpItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedPrefIndustryExpTwo: any;
  prefIndustryExpItemsTwo = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  prefDomainItemss: any;
  prefDomainItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  prefDomainExpItemss: any;
  prefDomainExpItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  prefDomainExpItemsTwoo: any;
  prefDomainExpItemsTwo = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedHigherEducation: any;
  higherEducationItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedQualifications: any;
  QualificationsItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedLanguages: any;
  languagesItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedNationality: any;
  nationalityItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedGender: any;
  genderItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  // selectedDocumentType :any;
  documentTypeItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedWorkPermitt: any;
  workpermitItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedWorkCountryy: any;
  workCountryItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  selectedWorkPermittType: any;
  workpermitItemsType = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
}
