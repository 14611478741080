<div class="row">
    <div class="">
      <div class="stepper white-background br8 mb-3">
        <ng-container *ngFor="let step of ['1', '2', '3',]; let i = index">
          <div
            class="step"
            [ngClass]="{
              active: i === activeStepIndex,
              completed: isStepCompleted(i)
            }"
              (click)="activeStepIndex = i"
            >
            <!-- (click)="setActiveStepIndex(i)" -->
            <p class="step-count pointer">{{ i + 1 }}</p>
          </div>
        </ng-container>
      </div>
    </div>
    
    <div class="">

      <div class=" job-post-steps">
        <div *ngIf="activeStepIndex === 0">
          
          <form novalidate #targetAudienceForm="ngForm"
          (ngSubmit)="targetAudienceForm.form.valid && updateTargetAudienceForm; nextStep('first')">
            <!-- <app-stepper></app-stepper> -->
            <div class="white-background p16 br8 mb16 ">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleBrand" class="form-label fw500 neugray">Eligible Brand</label>
                <!-- (change)="getnationalityid($event)" -->
                
                <ng-select class="custom position-relative" [multiple]="true" [items]="eligibleBrand" bindLabel="name" bindValue="id" placeholder="Please select eligible brand"
                  [ngModel]="eligibleBrandSelection"  name="eligibleBrandSelect" #eligibleBrandSelect="ngModel" required>
                </ng-select>
                <!-- <p class="blue-brd-pill mt-3" #pillElementBrand>  <img src="./assets/Images/x-close.svg" alt="close" aria-hidden="true" class="ml-2 ng-value-icon left" >{{eligibleBrandSelection}}</p> -->
               
                <div *ngIf="(eligibleBrandSelect.touched || targetAudienceForm.submitted) &&
                eligibleBrandSelect.errors?.['required']">
                    <span class="errorMessage"> Please select eligible brand</span>
                  </div>
              </div>
            </div>
            <div class="white-background p16 br8 mb16">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCountry" class="form-label fw500 neugray">Eligible Country</label>
                <!-- (change)="getnationalityid($event)" -->
                <ng-select class="custom" [multiple]="true" [items]="eligibleCountry" bindLabel="name" bindValue="id" placeholder="Select eligible country"
                  [ngModel]="eligibleCountrySelection" name="eligibleCoutnrySelect" #eligibleCoutnrySelect="ngModel" required>
                </ng-select>
                <div *ngIf="(eligibleCoutnrySelect.touched || targetAudienceForm.submitted) &&
                eligibleCoutnrySelect.errors?.['required']">
                    <span class="errorMessage"> Please select eligible country</span>
                  </div>
              </div>
              <!-- <p class="blue-brd-pill mt-3" #pillElementCountry> GIIS <img src="./assets/Images/x-close.svg" alt="close" class="ml-2" (click)="removePill(pillElementCountry)"></p> -->
            </div>
            <div class="white-background p16 br8 mb16">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Campus</label>
                <!-- (change)="getnationalityid($event)" -->
                <ng-select class="custom" [multiple]="true" [items]="eligibleCampus" bindLabel="name" bindValue="id" placeholder="Select eligible campus"
                  [ngModel]="eligibleCampusSelection" name="eligibleCampusSelect" #eligibleCampusSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(eligibleCampusSelect.touched || targetAudienceForm.submitted) &&
                eligibleCampusSelect.errors?.['required']">
                    <span class="errorMessage"> Please select eligible campus</span>
                  </div>
              </div>
              <!-- <p class="blue-brd-pill mt-3" #pillElementCampus> GIIS <img src="./assets/Images/x-close.svg" alt="close" class="ml-2" (click)="removePill(pillElementCampus)"></p> -->
            </div>
            <div class="white-background p16 br8 mb16">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Department</label>
                <!-- (change)="getnationalityid($event)" -->
                <ng-select class="custom" [multiple]="true" [items]="eligibleDepartment" bindLabel="name" bindValue="id" placeholder="Select eligible department"
                  [ngModel]="eligibleDepartmentSelection" name="eligibleDepartmentSelect" #eligibleDepartmentSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(eligibleDepartmentSelect.touched || targetAudienceForm.submitted) &&
                eligibleDepartmentSelect.errors?.['required']">
                    <span class="errorMessage"> Please select eligible department</span>
                  </div>
              </div>
              <!-- <p class="blue-brd-pill mt-3" #pillElementDepartment> GIIS <img src="./assets/Images/x-close.svg" alt="close" class="ml-2" (click)="removePill(pillElementDepartment)"></p> -->
            </div>
            <div class="white-background p16 br8 mb16">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray">Eligible Role</label>
                <!-- (change)="getnationalityid($event)" -->
                <ng-select class="custom" [multiple]="true" [items]="eligibleRole" bindLabel="name" bindValue="id" placeholder="Select eligible role"
                  [ngModel]="eligibleRoleSelection" name="eligibleRoleSelect" #eligibleRoleSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(eligibleRoleSelect.touched || targetAudienceForm.submitted) &&
                eligibleRoleSelect.errors?.['required']">
                    <span class="errorMessage"> Please select eligible role</span>
                  </div>
              </div>
              <!-- <p class="blue-brd-pill mt-3" #pillElementRole> GIIS <img src="./assets/Images/x-close.svg" alt="close" class="ml-2" (click)="removePill(pillElementRole)"></p> -->
            </div>
            <div class="white-background p16 br8 mb16">
              <div class="row">
                <div class="col-md-6 col-lg-4 figma-ng-select">
                  <label for="eligibleCampus" class="form-label fw500 neugray">Employment Experience ( In months ) <sup>*</sup></label>
                  <!-- (change)="getnationalityid($event)" -->
                  <input type="text" class="form-control" placeholder="Enter experience" onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="employmentExp" name="employmentExpSelect" #employmentExpSelect="ngModel" required>
          
                  <div *ngIf="(employmentExpSelect.touched || targetAudienceForm.submitted) &&
                  employmentExpSelect.errors?.['required']">
                      <span class="errorMessage"> Please enter experience</span>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 figma-ng-select">
                  <label for="eligibleCampus" class="form-label fw500 neugray">Current Role duration <sup>*</sup></label>
                  <!-- (change)="getnationalityid($event)" -->
                  <input type="text" class="form-control" placeholder="Enter role duration" onkeypress="return /[0-9]/i.test(event.key)"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="roleDuration" name="roleDurationSelect" #roleDurationSelect="ngModel" required>
          
                  <div *ngIf="(roleDurationSelect.touched || targetAudienceForm.submitted) &&
                  roleDurationSelect.errors?.['required']">
                      <span class="errorMessage"> Please enter role duration</span>
                    </div>
                </div>
              </div>
            </div>
    
            <div class="step-navigation white-background p16 br8 ">
              <!-- <button class="secondary-btn mr-2">Previous</button> -->
              <button class="blue-btn ms-auto">Next</button>
            </div>
          </form>
        </div>

        <div *ngIf="activeStepIndex === 1">
          <form novalidate #progrmaApproversForm="ngForm"
          (ngSubmit)="progrmaApproversForm.form.valid && updateProgrmaApproversForm(); nextStep('second')">
          <div class="table-responsive">
            <table class="table approver-table">
              <thead>
                <tr class="white-background br8">
                  <th class="blackk fs14 fw600 p16">Level <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Country <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Approvers Designation <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Member Name/ Mail ID <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of approverTable; let i = index"  class="white-background br8">
                  <td class="fs14 blackk fw400 p16">{{ x.level }}</td>
                  <td class="fs14 blackk fw400 p16">{{ x.country }}</td>
                  <td class="fs14 blackk fw400 p16">{{ x.designation }}</td>
                  <td class="d-flex p16">
                    <div>
                      <p class="fs14 blackk fw400 mb-1">{{ x.MemberName }}</p>
                     <p class="fs10 fw500 grey">{{ x.MailID }}</p>
                    </div>
                    <div class="verticalline"></div>
                    <div>
                      <p class="fs14 blackk fw400 mb-1">{{ x.MemberName }}</p>
                     <p class="fs10 fw500 grey">{{ x.MailID }}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="field gs-form-group white-background mb-4 p16 br8">
            <label class="form-label blackk fs14 fw600 mb-2">Does this job require approval from Applicant Reporting Manager ?</label>
            <div class="gs-grouped-fileds d-flex mt-2">
              <div class="ui radio checkbox gs-grouped-filed mr-2">
                <input class="hidden" type="radio" [(ngModel)]="applicantApproval" name="applicantApproval" [value]="true" tabindex="0"/>
                <label class="grouped-label blackk fs14 fw600 ml-2">Yes</label>
              </div>
              <div class="ui radio checkbox gs-grouped-filed">
                <input class="hidden" type="radio" [(ngModel)]="applicantApproval" name="applicantApproval" [value]="false" tabindex="0"/>
                <label class="grouped-label blackk fs14 fw600 ml-2">No</label>
              </div>
            </div>
            
          </div>
          <div class="step-navigation white-background mb-4 p16 br8">
            <button (click)="back()" class="secondary-btn mr-3 ms-auto">Previous</button>
            <button class="blue-btn">Next</button>
          </div>
          </form>
        </div>

        <!-- <div *ngIf="activeStepIndex === 2">
          <form novalidate #selectionProcessForm="ngForm"
          (ngSubmit)="selectionProcessForm.form.valid && updateSelectionProcessForm();nextStep('third')">
          <div class="white-background p16 br8 mb16">
            <p class="fw600 fs16 blackk">Recruitment Members</p>
                  <div class="line"></div>
        
                  <div class="row">
                    <div class="mb-3 col-md-6 col-lg-4 figma-ng-select">
                      <label for="Nationality" class="form-label fw500 neugray">Hiring Team Member <sup>*</sup></label>
                      <ng-select class="custom" bindLabel="name" bindValue="id"
                        placeholder="Select hiring team member" [ngModel]="hiringTeamMember" name="hiringTeamMemberSelect" #hiringTeamMemberSelect="ngModel" required>
                      </ng-select>
                      <div *ngIf="(hiringTeamMemberSelect.touched || selectionProcessForm.submitted) &&
                      hiringTeamMemberSelect.errors?.['required']">
                          <span class="errorMessage"> Please select hiring team member</span>
                        </div>
                    </div>
                    <div class="mb-3 col-md-6 col-lg-4 figma-ng-select">
                      <label for="Nationality" class="form-label fw500 neugray">Hiring Stage<sup>*</sup></label>
                      <ng-select class="custom" bindLabel="name" bindValue="id"
                        placeholder="Select hiring stage"  [ngModel]="hiringStage" name="hiringStageSelect" #hiringStageSelect="ngModel" required>
                      </ng-select>
                      <div *ngIf="(hiringStageSelect.touched || selectionProcessForm.submitted) &&
                      hiringStageSelect.errors?.['required']">
                          <span class="errorMessage"> Please select hiring stage</span>
                        </div>
                    </div>
                    <div class="mb-3 col-md-6 col-lg-4 figma-ng-select">
                      <label for="Nationality" class="form-label fw500 neugray">Interview Template<sup>*</sup></label>
                      <ng-select class="custom" bindLabel="name" bindValue="id"
                        placeholder="Select interview template" [ngModel]="interviewTemplate" name="interviewTemplateSelect" #interviewTemplateSelect="ngModel" required>
                      </ng-select>
                      <div *ngIf="(interviewTemplateSelect.touched || selectionProcessForm.submitted) &&
                      interviewTemplateSelect.errors?.['required']">
                          <span class="errorMessage"> Please select interview template</span>
                        </div>
                    </div>
        
                  </div>
                  <div class="row">
                    <div class="mb-3 col-md-6 col-lg-4 figma-calender">
                      <label for="Nationality" class="form-label fw500 neugray">Cut-off Date<sup>*</sup></label>
                      <div>
                        <p-calendar [showIcon]="true"  class="primecalendar"
                        placeholder="Select cut-off date" [ngModel]="cutOffDate" name="cutOffDateSelect" #cutOffDateSelect="ngModel" [required]="true"></p-calendar>
                        <div *ngIf="(cutOffDateSelect.touched || selectionProcessForm.submitted) &&
                        cutOffDateSelect.errors?.['required']">
                          <span class="errorMessage"> Please select cut-off-date</span>
                        </div>
                      </div>
        
                    </div>
                    <div class="mb-3 col-md-6 col-lg-4 figma-ng-select">
                      <label for="Nationality" class="form-label fw500 neugray">Type of Interview<sup>*</sup></label>
                      <ng-select class="custom" bindLabel="name" bindValue="id"
                        placeholder="Select type of interview"  [ngModel]="typeOfInterview" name="typeOfInterviewSelect" #typeOfInterviewSelect="ngModel" required>
                      </ng-select>
                      <div *ngIf="(typeOfInterviewSelect.touched || selectionProcessForm.submitted) &&
                      typeOfInterviewSelect.errors?.['required']">
                        <span class="errorMessage"> Please select type of interview</span>
                      </div>
                    </div>
                  </div>
        
                    <app-pop-up (click)="showDialog()" [buttonText]="'Add to List'" [buttonClass]="'add-approver-btn'"></app-pop-up>
        
        
        
          </div>
          <div class="table-responsive">
            <table class="table selection-table">
              <thead>
                <tr class="white-background br8">
                  <th class="blackk fs14 fw600 p16">Sr.No  <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Name <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Hiring stage <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Template <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Cut-off date <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Interview Type <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                  <th class="blackk fs14 fw600 p16">Group Discussion <img src="./assets/Images/sort-icons.svg" alt="sort" class="ml-2 pointer"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let x of selectionTable; let i = index"  class="white-background br8">
                  <td class="fs14 blackk fw400 p16">{{ x.level }}</td>
                  <td class="fs14 blackk fw400 p16">{{ x.name }}</td>
                  <td class="fs14 blackk fw400 p16">{{ x.stage }}</td>
                  <td class="fs14 blackk fw400 p16">{{x.template}}</td>
                  <td class="fs14 blackk fw400 p16">{{x.cutOffDate}}</td>
                  <td class="fs14 blackk fw400 p16">{{x.interviewType}}</td>
                  <td class="fs14 blackk fw400 p16"><input type="checkbox"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="step-navigation white-background mb-4 p16 br8">
            <button (click)="back()" class="secondary-btn mr-3">Previous</button>
            <button type="submit" class="blue-btn">Next</button>
          </div>
          </form>

        </div> -->
        <div *ngIf="activeStepIndex === 2">
          <form novalidate #referralRulesForm="ngForm"
          (ngSubmit)="referralRulesForm.form.valid && updateReferralRulesForm();nextStep('third')">
      
          <div class="white-background p16 br8 mb16 base-configuration">
            <div class="gs-grouped-fileds d-flex mb-4 custom01 mb-4">
              <div class="ui radio checkbox gs-grouped-filed checked">
                <input class="" type="radio" name="applicable" id="applicable" />
                <label for="applicable" class="grouped-label fs14 fw600 blackk ml-2">Applicable </label>
              </div>
              <div class="ui radio checkbox gs-grouped-filed ms-3">
                <input class="" type="radio" name="applicable" id="notApplicable"/>
                <label for="notApplicable" class="grouped-label fs14 fw600 blackk ml-2">Not Applicable</label>
              </div>
        
            </div>
            <p class="fw600 fs14 blackk mb-4">Base Configuration</p>
            <div class="row">
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCountry" class="form-label fw500 neugray">Program Type</label>
                <!-- (change)="getnationalityid($event)" -->
                <ng-select class="custom" [items]="programTypeList" bindLabel="name" bindValue="id" placeholder="Select program type"
                  [ngModel]="programTypeListSelection" name="programTypeListSelect" #programTypeListSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(programTypeListSelect.touched || referralRulesForm.submitted) &&
                programTypeListSelect.errors?.['required']">
                    <span class="errorMessage"> Please select program type</span>
                  </div>
              </div>
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCountry" class="form-label fw500 neugray">Currency</label>
                <!-- (change)="getnationalityid($event)" -->
                <ng-select class="custom" [items]="currencyList" bindLabel="name" bindValue="id" placeholder="Select currency"
                  [ngModel]="currencyListSelection" name="currencyListSelect" #currencyListSelect="ngModel" required>
                </ng-select>
                <div *ngIf="(currencyListSelect.touched || referralRulesForm.submitted) &&
                currencyListSelect.errors?.['required']">
                    <span class="errorMessage"> Please select currency</span>
                  </div>
              </div>
              <div class="col-md-6 col-lg-4 figma-ng-select">
                <label for="eligibleCampus" class="form-label fw500 neugray d-flex"><img src="./assets/Images/tooltip-info-icon.svg" pInputText 
                  pTooltip="Number of Positions" tooltipPosition="right"  alt=""> Open Position </label>
                <!-- (change)="getnationalityid($event)" -->
                <input type="text" class="form-control" placeholder="Enter number of positions" onkeypress="return /[0-9]/i.test(event.key)"
                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="numberOfPositions" value="10" name="numberOfPositionsSelect" #numberOfPositionsSelect="ngModel" required>
        
                <!-- <div *ngIf="(numberOfPositionsSelect.touched || targetAudienceForm.submitted) &&
                numberOfPositionsSelect.errors?.['required']">
                    <span class="errorMessage"> Please enter number of positions</span>
                  </div> -->
              </div>
            </div>
          </div>
          <div class="bonus-type white-background p16 br8 mb16">
              <p class="fw600 fs14 blackk mb-4">Bonus Type </p>
              <div class="field gs-form-group mb-4">
                <div class="gs-grouped-fileds d-flex custom01">
                  <div class="ui radio checkbox gs-grouped-filed checked" (click)="selectedBonusType = 'flat'">
                    <input class="hidden" type="radio" name="bonus" [checked]="selectedBonusType === 'flat'" tabindex="0"/>
                    <label class="grouped-label fs14 fw600 blackk ml-2">Flat </label>
                  </div>
                  <!-- <div class="ui radio checkbox gs-grouped-filed ms-3" (click)="selectedBonusType = 'slab'">
                    <input class="hidden" type="radio" name="bonus" [checked]="selectedBonusType === 'slab'" tabindex="0"/>
                    <label class="grouped-label fs14 fw600 blackk ml-2">Slab Based</label>
                  </div> -->
            
                </div>
              </div>
              <div class="row flat-type" *ngIf="selectedBonusType === 'flat'">
                <div class="col-md-6 col-lg-6 col-xxl-4 row mb-4">
                  <label class="form-label fw500 fs12 neugray mb-2" style="line-height: 18px;">Referral Bonus</label>
                  <div class="figma-ng-select col-md-6 col-lg-4">
                    <input type="text" value="1X" class="form-control"  onkeypress="return /[0-9]/i.test(event.key)"
                    oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';">
                  </div>
                  <div class="col-md-6 col-lg-4 figma-ng-select">
                    <!-- (change)="getnationalityid($event)" -->
                    <ng-select class="custom" [items]="currencyList1" bindLabel="name" bindValue="id" placeholder="Select currency"
                      [ngModel]="currencyListSelection1" name="currencyListSelect1" #currencyListSelect1="ngModel" required>
                    </ng-select>
                    <div *ngIf="(currencyListSelect1.touched || referralRulesForm.submitted) &&
                    currencyListSelect1.errors?.['required']">
                        <span class="errorMessage"> Please select currency</span>
                      </div>
                  </div>
                  <div class="col-md-6 col-lg-4 figma-ng-select">
                    <input type="text" class="form-control" placeholder="Enter referral bonus amount" onkeypress="return /[0-9]/i.test(event.key)"
                    oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="amount" name="amountSelect" #amountSelect="ngModel" required>
<!--             
                    <div *ngIf="(amountSelect.touched || targetAudienceForm.submitted) &&
                    amountSelect.errors?.['required']">
                        <span class="errorMessage"> Please enter referral bonus amount</span>
                      </div> -->
                  </div>
                </div>
                <div class="col-md-6 col-lg-4  figma-ng-select mb-4">
                  <label  class="form-label fw500 neugray d-flex"><img src="./assets/Images/tooltip-info-icon.svg" pInputText 
                    pTooltip="Select minimum tenure" tooltipPosition="top"  alt="">Minimum Tenure</label>
                  <!-- (change)="getnationalityid($event)" -->
                  <ng-select class="custom" [items]="minimumTenure" bindLabel="name" bindValue="id" placeholder="Select minimum tenure"
                    [ngModel]="minimumTenureSelection" name="minimumTenuretSelect" #minimumTenuretSelect="ngModel" required>
                  </ng-select>
                  <div *ngIf="(minimumTenuretSelect.touched || referralRulesForm.submitted) &&
                  minimumTenuretSelect.errors?.['required']">
                      <span class="errorMessage"> Please select minimum tenure</span>
                    </div>
                </div>
              </div>
              <!-- <div class="slab-type" *ngIf="selectedBonusType === 'slab'">
                <div class="col-md-6 col-lg-4 figma-ng-select mb-4">
                  <label  class="form-label fw500 neugray d-flex"><img src="./assets/Images/tooltip-info-icon.svg" pInputText 
                    pTooltip="Select minimum tenure" tooltipPosition="top"  alt="">Minimum Tenure</label>
                  <ng-select class="custom" [items]="minimumTenureSlab" bindLabel="name" bindValue="id" placeholder="Select minimum tenure"
                    [ngModel]="minimumTenureSelectionSlab" name="minimumTenuretSelectSlab" #minimumTenuretSelectSlab="ngModel" required>
                  </ng-select>
                  <div *ngIf="(minimumTenuretSelectSlab.touched || referralRulesForm.submitted) &&
                  minimumTenuretSelectSlab.errors?.['required']">
                      <span class="errorMessage"> Please select minimum tenure</span>
                    </div>
                </div>
                <ng-container *ngFor="let item of referralBonusArr.referralBonusArrItem; let x = index">
                  <div class="row">
                    <div class="mb-3 col-md-6 col-lg-4 figma-ng-select">
                      <label class="form-label fw500 neugray">Referral Bonus</label>
                      <div class="d-flex gap-2 align-items-center">
                  
                        <input type="text" class="form-control"  onkeypress="return /[0-9]/i.test(event.key)"
                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [(ngModel)]="item.bonusFrom" name="bonusFromInput{{x}}" #bonusFromInput="ngModel" placeholder="from" /> -
                        <input type="text" class="form-control"  onkeypress="return /[0-9]/i.test(event.key)"
                        oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [(ngModel)]="item.bonusTo" name="bonusToInput{{x}}" #bonusToInput="ngModel" placeholder="to" />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4 figma-ng-select">
                      <label  class="form-label fw500 neugray">Referral Bonus</label>
                      <input type="text" class="form-control" placeholder="Enter referral bonus" onkeypress="return /[0-9]/i.test(event.key)"
                      oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" [ngModel]="item.amount1" name="amountSelect1{{x}}" #amountSelect1="ngModel" required>
              
                      <div *ngIf="(amountSelect1.touched || targetAudienceForm.submitted) &&
                      amountSelect1.errors?.['required']">
                          <span class="errorMessage"> Please enter referral bonus</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <label class="form-label fw500 neugray fs12">Action</label>
                      <div class="d-flex">
                        <img class="m-2 pointer" src="./assets/Images/edit-2.svg" alt="edit">
                        <img class="m-2" src="./assets/Images/delete.svg" alt="delete" role="button" class="add__Text remove__Text" (click)="deleteFieldss1(x)"
                        *ngIf="(referralBonusArr.referralBonusArrItem.length === ( x+1)) &&  (x !=0 )"
                        style="color:rgba(254, 84, 84, 1) !important">
                      </div>
                    </div>
    
                  </div>

                  <div class="add-icon-main col-md-6 col-lg-4">
                  
                  <span role="button" class="add__Text add-iconnew blue-color fs14 fw600" *ngIf="
                  x == referralBonusArr.referralBonusArrItem.length - 1 ||
                  referralBonusArr.referralBonusArrItem.length == 1
                " (click)="addFieldss1(x)">
                <img src="./assets/Images/add-icon.svg" alt="add-icon">
                Add More Bonus Slab
                  </span>
                  </div>
                </ng-container>
               
              </div> -->

          </div>
          <div class="additional-bonus-type white-background p16 br8 mb16">
            <p class="fw600 fs14 blackk mb-4 d-flex ">Additional Bonus <img src="./assets/Images/tooltip-info-icon.svg" pInputText 
              pTooltip="Additional Bonus" tooltipPosition="right"  alt="info" style="width: 20px;height: 20px;margin-left: 4px;"></p>
            <div class="field gs-form-group mb-4">
              <p class="fs12 fw500 blackk mb-2">Based on</p>
              <div class="gs-grouped-fileds d-flex mb-4 custom01">
                <div class="ui radio checkbox gs-grouped-filed checked" (click)="additionalBonusType = 'na'">
                  <input class="hidden" type="radio" name="addBonus" [checked]="additionalBonusType === 'na'" tabindex="0"/>
                  <label class="grouped-label fs14 fw600 blackk ml-2">N/A </label>
                </div>
                <div class="ui radio checkbox gs-grouped-filed ms-3" (click)="additionalBonusType = 'tenure'">
                  <input class="hidden" type="radio" name="addBonus" [checked]="additionalBonusType === 'tenure'" tabindex="0"/>
                  <label class="grouped-label fs14 fw600 blackk ml-2">Tenure</label>
                </div>
          
              </div>
              <div class="tenure row" *ngIf="additionalBonusType === 'tenure'">
                <ng-container *ngFor="let item of addBonusArr.addBonusArrItem; let y = index">
                  <div class="row">
                    <div class="col-md-6 col-lg-4 figma-ng-select mb-4">
                      <label  class="form-label fw500 neugray d-flex"><img src="./assets/Images/tooltip-info-icon.svg" pInputText 
                        pTooltip="Select tenure of referee" tooltipPosition="top"  alt="">Tenure of Referee ( In months )</label>
                      <!-- (change)="getnationalityid($event)" -->
                      <ng-select class="custom" [items]="refreeTenure" bindLabel="name" bindValue="id" placeholder="Select minimum tenure"
                        [ngModel]="item.refreeTenureSelection" name="refreeTenuretSelect{{y}}" #refreeTenuretSelect="ngModel" required>
                      </ng-select>
                      <div *ngIf="(refreeTenuretSelect.touched || referralRulesForm.submitted) &&
                      refreeTenuretSelect.errors?.['required']">
                          <span class="errorMessage"> Please select refree tenure</span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 figma-ng-select">
                      <label  class="form-label fw500 neugray">Additional %</label>
                      <input type="text" class="form-control sign-suffix" placeholder="Enter" onkeypress="return /[0-9]/i.test(event.key)"
                      oninput="if(!this.value.match(/^100$|^(\d{0,2}(\.\d{0,2})?)$/)) this.value='';"  [ngModel]="item.addAmount" name="addAmountSelect{{y}}" #addAmountSelect="ngModel" required>
              
                      <div *ngIf="(addAmountSelect.touched || referralRulesForm.submitted) &&
                      addAmountSelect.errors?.['required']">
                          <span class="errorMessage"> Please enter additional %</span>
                        </div>
                    </div>
                    <div class="col-md-4 align-self-center">
                      <!-- <label class="form-label fw500 neugray fs12">Action</label> -->
                      <div class="d-flex">
                        <img src="./assets/Images/add-icon.svg" alt="edit"  class="add__Text add-iconnew blue-color fs14 fw600 mr-3" *ngIf="
                        y == addBonusArr.addBonusArrItem.length - 1 ||
                        addBonusArr.addBonusArrItem.length == 1
                      " (click)="addFieldss2(y)">
                        <img src="./assets/Images/delete.svg" alt="delete" role="button" class="add__Text remove__Text" (click)="deleteFieldss2(y)"
                        *ngIf="(addBonusArr.addBonusArrItem.length === ( y+1)) &&  (y !=0 )"
                        style="color:rgba(254, 84, 84, 1) !important">
                      </div>
                    </div>
                  </div>
                  <!-- <div class="add-icon-main col-md-6 col-lg-4">
                  
                    <span role="button" class="add__Text add-iconnew blue-color fs14 fw600" *ngIf="
                    y == addBonusArr.addBonusArrItem.length - 1 ||
                    addBonusArr.addBonusArrItem.length == 1
                  " (click)="addFieldss2(y)">
                  <img src="./assets/Images/add-icon.svg" alt="add-icon">
                  Add More Milestone
                    </span>
                    </div> -->
                </ng-container>
              </div>
            </div>
          </div>
          <div class="budgeted-for white-background p16 br8 mb16">
            <p class="fs14 fw600 blackk mb-4">Budgeted for the programme</p>
            <div class="mb-3">
              <p class="fs12 fw500 blackk mb-2">Minimum Budget</p>
              <p class="fs14 fw600 blackk">Rs. 11,000/-</p>
            </div>
            <div class="">
              <p class="fs12 fw500 blackk mb-2">Maximum Budget</p>
              <p class="fs14 fw600 blackk">Rs. 1,80,000/-</p>
            </div>
          </div>
          <div class="step-navigation white-background mb-4 p16 br8">
            <button (click)="back()" class="secondary-btn mr-3 ms-auto">Previous</button>
            <button type="submit" (click)="showSuccess()" class="blue-btn">Submit</button>
          </div>
          </form>

        </div>

      </div>
    </div>
  </div>
  <div class=" flex justify-content-center successApproveModal">
    <!-- <p-button  label="Show" /> -->
    <p-dialog
        [(visible)]="visibleSuccess"
        [modal]="true"
        [style]="{ width: '400px', height:'fit-content' }">
        <div *ngIf="!approveSuccess">
          <div class="mb-4">
            <img src=""  alt="Success">
          </div>
  
          <div>
            <p class="fs18  mb-4  fw600"> Post the Job?</p>
            <div class="d-flex">
              <button class="secondary-btn mb-2" (click)="postJob()">Yes</button>
              <button class="blue-btn mb-2 ml-2" (click)="visibleSuccess = false">No, check details</button>
            </div>
          </div>
        </div>
  
        <div *ngIf="approveSuccess">
          <img class="mb-3" src="" alt="Success">
          <p class="fs18  mb-4 fw600">Job posted and created successfully</p>
          <button class="blue-btn w-100" [routerLink]="['/dashboard']">Back to Dashboard</button>
        </div>
    </p-dialog>
  </div>

  