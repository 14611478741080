import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
// import { environment } from 'src/app/environments/environment';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {


  headers = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-origin', '*').set('Cache-Control', 'no-cache').set('Pragma', 'no-cache');
  loginUserId: any;
  userData: any;
  newUserId: any;

  constructor(private http: HttpClient) {
    let user = localStorage.getItem('token');
    if (user != null) {
      this.userData = jwtDecode(user)
      this.newUserId = this.userData.UserId
  }
   }

  GetAllBrands(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Applicant/DashboardFilters`, { headers: this.headers })
  }
  GetBrandByUserId(userId:any): Observable<any> {
    let params = new HttpParams().set("id", userId);
    return this.http.get(`${environment.apiUrl}/api/Jobs/GetBrandDetailsByUserIdDashboard`, { params: params })
  }

  GetCountryByBrand(brandId:any,userId:any): Observable<any> {
    let params = new HttpParams().set("brandId", brandId.toString()).set("userId", userId);;
    return this.http.get(`${environment.apiUrl}/api/Report/Getcountryidbrand`, { params: params })
  }

  GetCampusByCountry(brandId:any,countryId:any,userId:any): Observable<any> {
    let params = new HttpParams().set("brandId", brandId).set("countryId",countryId).set('userId',userId)
    return this.http.get(`${environment.apiUrl}/api/Report/GetBrandCountryCampusDetails`, { params: params })
  }

  GetJobCategoryByCampus(brandId:any,countryId:any,campusId:any): Observable<any> {
    let params = new HttpParams().set("brandId", brandId).set("countryId",countryId).set("campusIds",campusId)
    return this.http.get(`${environment.apiUrl}/api/Report/GetJobcategoryDetails`, { params: params })
  }

  GetDepartmentByCategory(brandId:any,countryId:any,campusId:any,categoryId:any): Observable<any> {
    let params = new HttpParams().set("brandId", brandId).set("countryId",countryId).set("campusId",campusId).set("jobCategoryId",categoryId)
    return this.http.get(`${environment.apiUrl}/api/Report/GetDepartmentDetails`, { params: params })
  }

  GetSources(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Report/GetSources`, { headers: this.headers })
  }

  GetjobPositions(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetJobsCount`, obj , { headers: this.headers })
  }

  GetAcademics(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetAcademicNonAcademic`, obj , { headers: this.headers })
  }

  GetNewReplacements(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetnewreplaceCount`, obj , { headers: this.headers })
  }

  GetSelectedApplicants(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetSelectedApplicants`, obj , { headers: this.headers })
  }

  GetStatusDistribution(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetStatusDistribution`, obj , { headers: this.headers })
  }

  GetTalentRequisition(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetTalentrequisitionCount`, obj , { headers: this.headers })
  }

  GetStagesOverview(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/StagesOverview`, obj , { headers: this.headers })
  }
  GetStagesOverviewbyBrand(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/StagesOverviewbyBrand`, obj , { headers: this.headers })
  }
  GetStagesOverviewbyCampus(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/StagesOverviewbyCampus`, obj , { headers: this.headers })
  }
  GetSourceHire(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetSourceOfHire`, obj , { headers: this.headers })//GetSourceHire
  }
  GetOpenClosedPositionsBrandWise(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetJobStatusByBrand`, obj , { headers: this.headers })
  }
  GetOpenClosedPositionsCountryWise(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetJobStatus`, obj , { headers: this.headers })
  }
  GetOpenClosedPositionsCampusWise(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetJobStatusByCampus`, obj , { headers: this.headers })
  }
  GetNewReplacementByBrand(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/EntityBrand`, obj , { headers: this.headers })
  }
  GetNewReplacementByCountry(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/EntityCountry`, obj , { headers: this.headers })
  }
  GetNewReplacementByCampus(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/EntityCampus`, obj , { headers: this.headers })
  }
  GetAverageTATBrand(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/AverageTATBrand`, obj , { headers: this.headers })
  }
  GetAverageTATCountry(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/AverageTATCountry`, obj , { headers: this.headers })
  }
  GetAverageTATCampus(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/AverageTATCampus`, obj , { headers: this.headers })
  }
  createUserLogin(logindata:any) {
    return this.http.post(`${environment.apiUrl}/api/User/LoginUser`, logindata);
  }

  setUserId(data:any) {
    localStorage.setItem("token", JSON.stringify(data));
    this.loginUserId = data
  }
  getUserId() {
    let userDetails = localStorage.getItem('token');
    this.loginUserId = userDetails;
    // console.log(this.loginUserId,'userDetails')
    return this.loginUserId
  }
  Getjobfiltersfigma(obj:any):Observable<any>{
    return this.http.post(`${environment.apiUrl}/api/Report/AddSaveCriteria`, obj , { headers: this.headers })
  }

  GetSaveCriteriaByUserId(userId:any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Report/GetSaveCriteriaByUserId?userId=${userId}`, { headers: this.headers })
  }

  Getopenclosedglobally(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/Getopenclosedglobally`, obj , { headers: this.headers })
  }

  GetGlobalJoiners(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetGlobalJoiners`,obj, { headers: this.headers })
  }

  GetGlobalJoinersByCountry(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetGlobalJoinersByCountry`,obj, { headers: this.headers })
  }

  GetGlobalJoinersByCampus(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetGlobalJoinersByCampus`,obj, { headers: this.headers })
  }

  GetopenclosedgloballyCountry(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetopenclosedgloballyCountry`, obj , { headers: this.headers })
  }

   GetopenclosedgloballyCampus(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetopenclosedgloballyCampus`, obj , { headers: this.headers })
  }

  getHRReportBrand(obj:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/api/Report/HrperformancereportMainarray`,obj, { headers: this.headers })
  }

  hrPerformanceReport(obj:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/api/Report/Hrperformancereport`,obj , { headers: this.headers })
  }

  hrPerformanceReportCountry(obj:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/api/Report/HrperformancereportCountry`,obj , { headers: this.headers })
  }

  hrPerformanceReportCampus(obj:any): Observable<any>{
    return this.http.post(`${environment.apiUrl}/api/Report/HrperformancereportCampus`,obj , { headers: this.headers })
  }

  GetSourceOfHireApplicantsCount(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/GetSourceOfHireApplicantsCount`, obj , { headers: this.headers })//GetSourceHire
  }

  GetAcademicNonAcademicBrand(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/AcademicNonAcademicBrand`, obj , { headers: this.headers })//Brand
  }

  GetAcademicNonAcademicCountry(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/AcademicNonAcademicCountry`, obj , { headers: this.headers })//country
  }

  GetAcademicNonAcademicCampus(obj:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/Report/AcademicNonAcademicCampus`, obj , { headers: this.headers })//campus
  }

  
}

