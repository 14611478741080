// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {
  Component,
  Injector,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { timeStamp } from 'console';

import { userService } from 'src/app/services/userservice';
import { AppComponentBase } from 'src/app/shared/app.component-base';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';
import { NgxDropzoneModule } from 'ngx-dropzone';
// declare var $: any;
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-applicant',
  templateUrl: './add-applicant.component.html',
  styleUrls: ['./add-applicant.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

  export class AddApplicantComponent implements OnInit {
    @ViewChild('basicInfo', { static: false }) basicInfo: NgForm;

    @ViewChild('workForm', { static: false }) workForm: NgForm;

    @ViewChild('educationForm', { static: false }) educationForm: NgForm;

    @ViewChild('familyForm', { static: false }) familyForm: NgForm;

    public path = environment.apiUrl;

    // files;

    addSubmitted: boolean = false;
  submitted: boolean = false;

  public showGeneralInfo: boolean = true;
  public showWorkHistory: boolean = false;
  public showEducationalInfo: boolean = false;
  public showfamilyInfo: boolean = false;
  public showOthers: boolean = false;
  public nationality: any;
  public showErrorCurrentSalary: boolean = false;
  public showErrorExpectSalary: boolean = false;
  public noticeError: boolean = false;
  public ageError: boolean = false;
  files: File[] = [];
  files1: File[] = [];
  files2: File[] = [];

  profileFiles: File[] = [];
  educationUploadFiles: File[] = [];
  imggirationFiles: File[] = [];
  workInfoFiles: File[] = [];
  readerEvt: any;
  resident: any;
  levelofeducation: any;
  degree: any;
  grade: any;
  public sideButtonEnable: boolean = true;
  public workSideButton: boolean = false;
  public educationSideButton: boolean = false;
  public generalSideButton: boolean = false;
  public declareSideButton: boolean = false;
  public docSideButton: boolean = false;
  public familySideButton: boolean = false;
  public otherSideButton: boolean = false;
  basicInfoData: any = {};
  workinfoData: any = {};
  educationinfoData: any = {};
  familyinfoData: any = {};
  profileArray: any;
  // public jobTitleValue: any;
  public workInfoMedias: any = [];
  public postMultimedias: any = [];
  public imggirationMedias: any = [];
  public educationMultimedia: any = [];
  public candidateId: any;
  public showRelavntVisa: boolean = false;
  public countryEnable: boolean = false;
  public suspendEnable: boolean = false;
  public checkDisability: boolean = false;
  public minExpireDate: any;
  public generalresponse: boolean = false;
  public maxExprireDate: any;
  public maxExprireDatee: any;
  public minDob: any;
  public anotherJobArray: any = [];
  public anotherReference: any = [];
  public anotherEducation: any = [];
  public longdesciptionInfoData: any = {};
  public workData: any;
  public jobId: any;
  public enableUpdateJob: boolean = false;
  public referenceUpdate: boolean = false;
  public referenceId: any;
  public userId: any;
  public degreeId: any;
  public degreeUpdate: boolean = false;
  public childIndex: any;
  public childUpdate: boolean = false;
  public phoneCode: any;
  public currencySymbol = [
    { id: 1, name: '₹', country: 'India' },
    { id: 2, name: '¥', country: 'Japan' },
    { id: 3, name: '$', country: 'Singapore' },
    { id: 5, name: 'RM', country: 'Malaysia' },
    { id: 6, name: 'د.إ', country: 'UAE' },
  ];
  public noticeValues = [
    { id: 1, noticePeriod: '30 ' },
    { id: 1, noticePeriod: '45 ' },
    { id: 2, noticePeriod: '60 ' },
    { id: 3, noticePeriod: '90 ' },
  ];
  public sub: any;
  public id: any;
  public candidateData: any;
  public submitEnable: boolean = true;
  public language: any;
  public familyData: any;
  public showPhoneError: boolean = false;

  public side_ButtonEnable: boolean = true;
  public work_SideButton: boolean = false;
  public education_SideButton: boolean = false;
  public family_SideButton: boolean = false;
  public General_SideButton: boolean = false;
  public declare_SideButton: boolean = false;
  public doc_SideButton: boolean = false;
  public other_SideButton: boolean = false;
  public hideImage: boolean = false;
  public showPhotoMaxLimit: boolean = false;
  public hideWorkhistoryCertificate: boolean = false;
  public uploadWorkhistoryCertificate: boolean = false;
  public showJobArrayError: boolean = false;
  public anotherJobArrayError: boolean = false;
  public showEducationError: boolean = false;
  public uploadImage: boolean = false;
  public userPhoto: boolean = false;
  public showPhoto: boolean = false;
  public showUploadResume: boolean = true;
  public showpdf: boolean = true;
  public showDocumentview: boolean = false;
  public hideButtoncertificate: boolean = true;

  fromyears = [
    { id: 1, name: '0' },
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11' },
    { id: 13, name: '12' },
    { id: 14, name: '13' },
    { id: 15, name: '14' },
    { id: 16, name: '15' },
    { id: 17, name: '16' },
    { id: 18, name: '17' },
    { id: 19, name: '18' },
    { id: 20, name: '19' },
    { id: 21, name: '20' },
    { id: 22, name: '21' },
    { id: 23, name: '22' },
    { id: 24, name: '23' },
    { id: 25, name: '24' },
    { id: 26, name: '25' },
    { id: 27, name: '26' },
    { id: 28, name: '27' },
    { id: 29, name: '28' },
    { id: 30, name: '29' },
    { id: 31, name: '30' },
    { id: 32, name: '31' },
    { id: 33, name: '32' },
    { id: 34, name: '33' },
    { id: 35, name: '34' },
    { id: 36, name: '35' },
    { id: 37, name: '36' },
    { id: 38, name: '37' },
    { id: 39, name: '38' },
    { id: 40, name: '39' },
    { id: 41, name: '40' },
    { id: 42, name: '41' },
    { id: 43, name: '42' },
    { id: 44, name: '43' },
    { id: 45, name: '44' },
    { id: 46, name: '45' },
    { id: 47, name: '46' },
    { id: 48, name: '47' },
    { id: 49, name: '48' },
    { id: 50, name: '49' },
  ];
  toyears = [
    { id: 1, name: '0' },
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11' },
    { id: 13, name: '12' },
    { id: 14, name: '13' },
    { id: 15, name: '14' },
    { id: 16, name: '15' },
    { id: 17, name: '16' },
    { id: 18, name: '17' },
    { id: 19, name: '18' },
    { id: 20, name: '19' },
    { id: 21, name: '20' },
    { id: 22, name: '21' },
    { id: 23, name: '22' },
    { id: 24, name: '23' },
    { id: 25, name: '24' },
    { id: 26, name: '25' },
    { id: 27, name: '26' },
    { id: 28, name: '27' },
    { id: 29, name: '28' },
    { id: 30, name: '29' },
    { id: 31, name: '30' },
    { id: 32, name: '31' },
    { id: 33, name: '32' },
    { id: 34, name: '33' },
    { id: 35, name: '34' },
    { id: 36, name: '35' },
    { id: 37, name: '36' },
    { id: 38, name: '37' },
    { id: 39, name: '38' },
    { id: 40, name: '39' },
    { id: 41, name: '40' },
    { id: 42, name: '41' },
    { id: 43, name: '42' },
    { id: 44, name: '43' },
    { id: 45, name: '44' },
    { id: 46, name: '45' },
    { id: 47, name: '46' },
    { id: 48, name: '47' },
    { id: 49, name: '48' },
    { id: 50, name: '49' },
  ];
  candidateRelated = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  preferedDomain: number;
  prefferedDomain = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  selectjobTitleValue: number;
  jobTitleValue = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectbasicPhone: number;
  basicPhone = [
    { id: 1, name: '+91' },
    { id: 2, name: '+92' },
    { id: 3, name: '+93' },
    { id: 4, name: '+94' },
  ];

  selectedcountrYYY: number;
  countrYYY = [
    { id: 1, name: 'one' },
    { id: 2, name: 'two' },
    { id: 3, name: 'three' },
    { id: 4, name: 'four' },
  ];

  selectAddressPhone: number;
  addressPhone = [
    { id: 1, name: '+91' },
    { id: 2, name: '+92' },
    { id: 3, name: '+93' },
    { id: 4, name: '+94' },
  ];

  selectAddressPhoneTwo: number;
  addressPhoneTwo = [
    { id: 1, name: '+91' },
    { id: 2, name: '+92' },
    { id: 3, name: '+93' },
    { id: 4, name: '+94' },
  ];

  cities = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Pavilnys', disabled: true },
    { id: 4, name: 'Pabradė' },
    { id: 5, name: 'Klaipėda' },
  ];
  selectCountryOne: number;
  CountryOne = [
    { id: 1, name: 'country 1' },
    { id: 2, name: 'country 2' },
    { id: 3, name: 'country 3' },
    { id: 4, name: 'country 4' },
  ];

  selectCountryTwo: number;
  CountryTwo = [
    { id: 1, name: 'country 1' },
    { id: 2, name: 'country 2' },
    { id: 3, name: 'country 3' },
    { id: 4, name: 'country 4' },
  ];

  selectPrefInd: number;
  PrefInd = [
    { id: 1, name: 'country 1' },
    { id: 2, name: 'country 2' },
    { id: 3, name: 'country 3' },
    { id: 4, name: 'country 4' },
  ];

  selectedKnownLanguages: number;
  knownLanguages = [
    { id: 1, name: 'country 1' },
    { id: 2, name: 'country 2' },
    { id: 3, name: 'country 3' },
    { id: 4, name: 'country 4' },
  ];

  selectedlevelEducation: number;
  levelEducation = [
    { id: 1, name: 'country 1' },
    { id: 2, name: 'country 2' },
    { id: 3, name: 'country 3' },
    { id: 4, name: 'country 4' },
  ];

  selecteddegreeINN: number;
  degreeINN = [
    { id: 1, one: 'country 1' },
    { id: 2, one: 'country 2' },
    { id: 3, one: 'country 3' },
    { id: 4, one: 'country 4' },
  ];

  selectedgradeEE: number;
  gradeEE = [
    { id: 1, name: 'country 1' },
    { id: 2, name: 'country 2' },
    { id: 3, name: 'country 3' },
    { id: 4, name: 'country 4' },
  ];

  selectedSkills: number;
  skillsS = [
    { id: 1, name: 'Skill 1' },
    { id: 2, name: 'Skill 2' },
    { id: 3, name: 'Skill 3' },
    { id: 4, name: 'Skill 4' },
  ];

  selectedsSkillsCategory: number;
  sSkillsCategory = [
    { id: 1, name: 'Category 1' },
    { id: 2, name: 'Category 2' },
    { id: 3, name: 'Category 3' },
    { id: 4, name: 'Category 4' },
  ];

  selectedPreFExp: number;
  preFExp = [
    { id: 1, name: '0' },
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11', isDisabled: false },
    { id: 13, name: '12', isDisabled: false },
    { id: 14, name: '13', isDisabled: false },
    { id: 15, name: '14', isDisabled: false },
    { id: 16, name: '15', isDisabled: false },
    { id: 17, name: '16', isDisabled: false },
    { id: 18, name: '17', isDisabled: false },
    { id: 19, name: '18', isDisabled: false },
    { id: 20, name: '19', isDisabled: false },
    { id: 21, name: '20', isDisabled: false },
    { id: 22, name: '21', isDisabled: false },
    { id: 23, name: '22', isDisabled: false },
    { id: 24, name: '23', isDisabled: false },
    { id: 25, name: '24', isDisabled: false },
    { id: 26, name: '25', isDisabled: false },
    { id: 27, name: '26', isDisabled: false },
    { id: 28, name: '27', isDisabled: false },
    { id: 29, name: '28', isDisabled: false },
    { id: 30, name: '29', isDisabled: false },
    { id: 31, name: '30', isDisabled: false },
    { id: 32, name: '31', isDisabled: false },
    { id: 33, name: '32', isDisabled: false },
    { id: 34, name: '33', isDisabled: false },
    { id: 35, name: '34', isDisabled: false },
    { id: 36, name: '35', isDisabled: false },
    { id: 37, name: '36', isDisabled: false },
    { id: 38, name: '37', isDisabled: false },
    { id: 39, name: '38', isDisabled: false },
    { id: 40, name: '39', isDisabled: false },
    { id: 41, name: '40', isDisabled: false },
    { id: 42, name: '41', isDisabled: false },
    { id: 43, name: '42', isDisabled: false },
    { id: 44, name: '43', isDisabled: false },
    { id: 45, name: '44', isDisabled: false },
    { id: 46, name: '45', isDisabled: false },
    { id: 47, name: '46', isDisabled: false },
    { id: 48, name: '47', isDisabled: false },
    { id: 49, name: '48', isDisabled: false },
    { id: 50, name: '49', isDisabled: false },
    { id: 51, name: '50', isDisabled: false },
  ];

  selectedWorkPermitType: number;

  WorkPermitType = [
    { id: 1, name: 'Type 1' },
    { id: 2, name: 'Type 2' },
    { id: 3, name: 'Type 3' },
    { id: 4, name: 'Type 4' },
  ];
  selectedSponsorship: number;

  Sponsorship = [
    { id: 1, name: 'Type 1' },
    { id: 2, name: 'Type 2' },
    { id: 3, name: 'Type 3' },
    { id: 4, name: 'Type 4' },
  ];
  selectedWworkPermitt: number;

  workPermitt = [
    { id: 1, name: 'Required' },
    { id: 2, name: 'Not Required' },
    { id: 3, name: 'Sponsorship' },
  ];
  selectedCountId: number;
  countId = [
    { id: 1, name: '+91' },
    { id: 2, name: '+92' },
    { id: 3, name: '+93' },
    { id: 4, name: '+94' },
  ];

  selectedcountIdTwo: number;
  countIdTwo = [
    { id: 1, name: '+91' },
    { id: 2, name: '+92' },
    { id: 3, name: '+93' },
    { id: 4, name: '+94' },
  ];
  dateValue: Date;
  dateValue2: Date;

  selectedPreFExpP: number;
  preFExpP = [
    { id: 1, name: '0' },
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11', isDisabled: false },
    { id: 13, name: '12', isDisabled: false },
    { id: 14, name: '13', isDisabled: false },
    { id: 15, name: '14', isDisabled: false },
    { id: 16, name: '15', isDisabled: false },
    { id: 17, name: '16', isDisabled: false },
    { id: 18, name: '17', isDisabled: false },
    { id: 19, name: '18', isDisabled: false },
    { id: 20, name: '19', isDisabled: false },
    { id: 21, name: '20', isDisabled: false },
    { id: 22, name: '21', isDisabled: false },
    { id: 23, name: '22', isDisabled: false },
    { id: 24, name: '23', isDisabled: false },
    { id: 25, name: '24', isDisabled: false },
    { id: 26, name: '25', isDisabled: false },
    { id: 27, name: '26', isDisabled: false },
    { id: 28, name: '27', isDisabled: false },
    { id: 29, name: '28', isDisabled: false },
    { id: 30, name: '29', isDisabled: false },
    { id: 31, name: '30', isDisabled: false },
    { id: 32, name: '31', isDisabled: false },
    { id: 33, name: '32', isDisabled: false },
    { id: 34, name: '33', isDisabled: false },
    { id: 35, name: '34', isDisabled: false },
    { id: 36, name: '35', isDisabled: false },
    { id: 37, name: '36', isDisabled: false },
    { id: 38, name: '37', isDisabled: false },
    { id: 39, name: '38', isDisabled: false },
    { id: 40, name: '39', isDisabled: false },
    { id: 41, name: '40', isDisabled: false },
    { id: 42, name: '41', isDisabled: false },
    { id: 43, name: '42', isDisabled: false },
    { id: 44, name: '43', isDisabled: false },
    { id: 45, name: '44', isDisabled: false },
    { id: 46, name: '45', isDisabled: false },
    { id: 47, name: '46', isDisabled: false },
    { id: 48, name: '47', isDisabled: false },
    { id: 49, name: '48', isDisabled: false },
    { id: 50, name: '49', isDisabled: false },
    { id: 51, name: '50', isDisabled: false },
  ];
  selectedDomFExp: number;
  domMExp = [
    { id: 1, name: '0' },
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11', isDisabled: false },
    { id: 13, name: '12', isDisabled: false },
    { id: 14, name: '13', isDisabled: false },
    { id: 15, name: '14', isDisabled: false },
    { id: 16, name: '15', isDisabled: false },
    { id: 17, name: '16', isDisabled: false },
    { id: 18, name: '17', isDisabled: false },
    { id: 19, name: '18', isDisabled: false },
    { id: 20, name: '19', isDisabled: false },
    { id: 21, name: '20', isDisabled: false },
    { id: 22, name: '21', isDisabled: false },
    { id: 23, name: '22', isDisabled: false },
    { id: 24, name: '23', isDisabled: false },
    { id: 25, name: '24', isDisabled: false },
    { id: 26, name: '25', isDisabled: false },
    { id: 27, name: '26', isDisabled: false },
    { id: 28, name: '27', isDisabled: false },
    { id: 29, name: '28', isDisabled: false },
    { id: 30, name: '29', isDisabled: false },
    { id: 31, name: '30', isDisabled: false },
    { id: 32, name: '31', isDisabled: false },
    { id: 33, name: '32', isDisabled: false },
    { id: 34, name: '33', isDisabled: false },
    { id: 35, name: '34', isDisabled: false },
    { id: 36, name: '35', isDisabled: false },
    { id: 37, name: '36', isDisabled: false },
    { id: 38, name: '37', isDisabled: false },
    { id: 39, name: '38', isDisabled: false },
    { id: 40, name: '39', isDisabled: false },
    { id: 41, name: '40', isDisabled: false },
    { id: 42, name: '41', isDisabled: false },
    { id: 43, name: '42', isDisabled: false },
    { id: 44, name: '43', isDisabled: false },
    { id: 45, name: '44', isDisabled: false },
    { id: 46, name: '45', isDisabled: false },
    { id: 47, name: '46', isDisabled: false },
    { id: 48, name: '47', isDisabled: false },
    { id: 49, name: '48', isDisabled: false },
    { id: 50, name: '49', isDisabled: false },
    { id: 51, name: '50', isDisabled: false },
  ];
  selectedDomExpP: number;
  domExpP = [
    { id: 1, name: '0' },
    { id: 2, name: '1' },
    { id: 3, name: '2' },
    { id: 4, name: '3' },
    { id: 5, name: '4' },
    { id: 6, name: '5' },
    { id: 7, name: '6' },
    { id: 8, name: '7' },
    { id: 9, name: '8' },
    { id: 10, name: '9' },
    { id: 11, name: '10' },
    { id: 12, name: '11', isDisabled: false },
    { id: 13, name: '12', isDisabled: false },
    { id: 14, name: '13', isDisabled: false },
    { id: 15, name: '14', isDisabled: false },
    { id: 16, name: '15', isDisabled: false },
    { id: 17, name: '16', isDisabled: false },
    { id: 18, name: '17', isDisabled: false },
    { id: 19, name: '18', isDisabled: false },
    { id: 20, name: '19', isDisabled: false },
    { id: 21, name: '20', isDisabled: false },
    { id: 22, name: '21', isDisabled: false },
    { id: 23, name: '22', isDisabled: false },
    { id: 24, name: '23', isDisabled: false },
    { id: 25, name: '24', isDisabled: false },
    { id: 26, name: '25', isDisabled: false },
    { id: 27, name: '26', isDisabled: false },
    { id: 28, name: '27', isDisabled: false },
    { id: 29, name: '28', isDisabled: false },
    { id: 30, name: '29', isDisabled: false },
    { id: 31, name: '30', isDisabled: false },
    { id: 32, name: '31', isDisabled: false },
    { id: 33, name: '32', isDisabled: false },
    { id: 34, name: '33', isDisabled: false },
    { id: 35, name: '34', isDisabled: false },
    { id: 36, name: '35', isDisabled: false },
    { id: 37, name: '36', isDisabled: false },
    { id: 38, name: '37', isDisabled: false },
    { id: 39, name: '38', isDisabled: false },
    { id: 40, name: '39', isDisabled: false },
    { id: 41, name: '40', isDisabled: false },
    { id: 42, name: '41', isDisabled: false },
    { id: 43, name: '42', isDisabled: false },
    { id: 44, name: '43', isDisabled: false },
    { id: 45, name: '44', isDisabled: false },
    { id: 46, name: '45', isDisabled: false },
    { id: 47, name: '46', isDisabled: false },
    { id: 48, name: '47', isDisabled: false },
    { id: 49, name: '48', isDisabled: false },
    { id: 50, name: '49', isDisabled: false },
    { id: 51, name: '50', isDisabled: false },
  ];

  selectedDocName: number;
  doccNamee = [
    { id: 1, name: 'Name 1' },
    { id: 2, name: 'Name 2' },
    { id: 3, name: 'Name 3' },
    { id: 4, name: 'Name 4' },
  ];

  selectedDocType: number;
  doccTyPEE = [
    { id: 1, name: 'Type 1' },
    { id: 2, name: 'Type 2' },
    { id: 3, name: 'Type 3' },
    { id: 4, name: 'Type 4' },
  ];
  selectedChildCountry: number;
  countryNationality = [
    { id: 1, name: 'Country 1' },
    { id: 2, name: 'Country 2' },
    { id: 3, name: 'Country 3' },
    { id: 4, name: 'Country 4' },
  ];
  selectedchildPhoneCode: number;
  childPhoneCode = [
    { id: 1, name: '+91' },
    { id: 2, name: '+92' },
    { id: 3, name: '+93' },
    { id: 4, name: '+94' },
  ];
  docfiles: File[] = [];
  jobEditList: any;
  firstPrefredDomain:any = [];
  finalDomain:any = [];

  constructor(
    injector: Injector,
    private router: Router,
    public userService: userService,
    public route: ActivatedRoute,
    dropZoneservice: NgxDropzoneModule,
    private Location: Location,
  ) {
    this.currentAddress = '';
    this.permanentAddress = '';
    // super(injector);
    // this.basicInfoData = {};
    this.basicInfoData.address = {};
    this.basicInfoData.currentAddress = {};
    this.basicInfoData.immigration = {};
    this.workinfoData = {};
    this.workinfoData.job = {};
    this.workinfoData.ref = {};
    this.educationinfoData = {};
    this.educationinfoData.education = {};
    this.familyinfoData = {};
    this.familyinfoData.spouse = {};
    this.familyinfoData.child1 = {};
    this.familyinfoData.others = {};
    this.sub = this.route.params.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.hideImage = true;
        this.hideWorkhistoryCertificate = true;
        this.showUploadResume = false;
        this.showDocumentview = false;
      } else {
        this.uploadImage = true;
        this.uploadWorkhistoryCertificate = true;
        this.showUploadResume = true;
        this.showDocumentview = true;
      }
    });

    // new row tscode start

    this.basicInfoData.addApplicant = [
      {
        prefferedIndustry: '',
        prefferedIndustryExp: '',
        industryExpFrom: '',
        selectedCityTo: '',
      },
    ];
    this.basicInfoData.addApplicantTwo = [
      {
        PRefferedDomain: '',
        doMainExperience: '',
        industryExp: '',
      },
    ];
    this.basicInfoData.addApplicantThree = [
      {
        typeofHiring: '',
        skills: '',
        industryExp: '',
      },
    ];
    this.basicInfoData.domains = [
      {
        preferedDomain: null,
        domainExperienceFrom: null,
        domainExperienceTo: null,
      },
    ];
    this.longdesciptionInfoData.jobskill = [
      {
        skillCategory: null,
        skills: null,
        skill_list: [],
      },
    ];
    // new row tscode end
    this.basicInfoData.industries = [
      {
        prefferedIndustry: null,
        prefferedIndustryExperienceFrom: null,
        prefferedIndustryExperienceTo: null,
      },
    ];

    this.refereesData.referees = [
      {
        orgNameeeTwo: null,
        refereeNameeeTwo: null,
        positionREFTwo: null,
        refemailAddressTwo: null,
        selectedcountIdTwo: null,
        spousePhoneNumberTwo: null,
      },
    ];
  }
  ngOnInit() {
    // this.getpreferredDomain();
    // this.getJobDetailsById(this.id)
    this.updatePermanentAddress();
    // this.sideButtonEnable = false;
    // this.educationSideButton = true;
    // this.basicInfoData.lookingNo='lookedNo';
    this.basicInfoData.lookingYes = 'lookedYes';
    // this.basicInfoData.gender = 'male';
    this.basicInfoData.immigration.relevantVisa = 'true';
    // this.getNationality()
    // this.getGrade();
    // this.getLevelOfEducation();
    // this.getDegree();
    // this.getJobTitle();
    let userData = this.userService.getUserId();
    this.userId = userData.userId;
    if (this.basicInfoData.immigration.relevantVisa) {
      this.showRelavntVisa = true;
    }
    this.minimumDob();
    // this.phoneCode = this.commonService.getPhoneCode();
    this.basicInfoData.basicphoneCode = '+91';
    this.basicInfoData.address.permanentphoneCode = '+91';
    this.basicInfoData.currentAddress.currentPhoneCode = '+91';
    // this.workinfoData.currencyCode = "₹";
    this.familyinfoData.spousePhoneCode = '+91';
    this.familyinfoData.childPhoneCode = '+91';
    if (parseInt(this.id) > 0) {
      // setTimeout(() => {
      //   this.showJob = true;
      //   this.showLoader = false;
      // }, 1000)
      // this.editCandidateInfoGetById();
    }
  }


  // getpreferredDomain() {
  //   this.jobSer.getpreferredDomain().subscribe((res) => {
  //     this.firstPrefredDomain = res ;
  //   });
  // }

  // getJobDetailsById(id: any) {
  //   if(id){
  //     this.id=id;
  //   }
  //   this.jobSer.editJobInfoGetById(this.id).subscribe(res => {
  //     this.jobEditList = res;
  //     let jobBasicInfo=  this.jobEditList.jobBasicInfo;
  //     let domain = jobBasicInfo.domains || [];
  //     let actualDomain = this.firstPrefredDomain || [];
  //     if(actualDomain.length > 0){
  //       for (let i=0;i<actualDomain.length;i++){
  //         for(let j=0;j<domain.length;j++){
  //           if(domain[j].preferedDomain  == actualDomain[i].domain_Id){
  //               let obj= {
  //                 domain_Id: actualDomain[i].domain_Id,
  //                 domain_Name: actualDomain[i].domain_Name
  //               }
  //               this.finalDomain.push(obj);
  //           }
  //         }
  //       }
  //     }

  //   })
  // }

  // editCandidateInfoGetById() {
  //     this.candidateService.viewCandidate(this.id).subscribe((res: any) => {
  //         this.candidateData = res;
  //         this.basicInfoData = this.candidateData.candidateGeneralInfo;
  //         let country = this.basicInfoData.country;
  //         this.basicInfoData.countryBirth = country;
  //         let dobFormate = this.convert(this.basicInfoData.dob);
  //         this.basicInfoData.dob = dobFormate;
  //         this.educationinfoData = this.candidateData.candidateEducationDetails;
  //         this.familyData = this.candidateData.candidateFamilyDetails;
  //         this.childArray = this.familyData.child;
  //         this.basicInfoData.basicphoneCode = '+91';
  //         this.basicInfoData.address.permanentphoneCode = '+91';
  //         this.basicInfoData.currentAddress.currentPhoneCode = "+91";
  //         this.workinfoData.job.currencyCode = "₹";
  //         this.familyinfoData.spousePhoneCode = "+91";
  //         this.familyinfoData.childPhoneCode = "+91";
  //         this.basicInfoData.address.city = this.basicInfoData.address.city;
  //         this.basicInfoData.immigration.relevantVisa = 'true';
  //         this.educationDocumentArray  = this.educationinfoData.candidateEducationDocuments || [];

  //         if (res.candidateWorkHistory) {
  //             this.workinfoData.relevantExpereince = this.candidateData.candidateWorkHistory.relevantExpereince || '';
  //             this.workinfoData.totalExpereince = this.candidateData.candidateWorkHistory.totalExpereince || '';
  //             this.workinfoData.noticePeriod = this.candidateData.candidateWorkHistory.noticePeriod || '';
  //             this.workinfoData.job = this.candidateData.candidateWorkHistory.candidatePreviousWorkHistory[0];
  //             this.anotherJobArray = res.candidateWorkHistory.candidatePreviousWorkHistory;
  //         }
  //         if (res.candidateWorkHistory && res.candidateWorkHistory.candidateWorkHistoryReferences) {
  //             this.anotherReference = res.candidateWorkHistory.candidateWorkHistoryReferences;
  //             this.workinfoData.candidateWorkHistoryReferences = res.candidateWorkHistory.candidateWorkHistoryReferences;
  //             this.workinfoData.candidateWorkHistoryId = res.candidateWorkHistory.candidateWorkHistoryId;
  //             this.workinfoData.additionalAttachments = res.candidateWorkHistory.additionalAttachments;
  //             this.workinfoData.additionalAttachmentsPath = res.candidateWorkHistory.additionalAttachmentsPath;
  //             this.workinfoData.additionalAttachmentsName = res.candidateWorkHistory.additionalAttachmentsName;
  //         }
  //         if (this.educationinfoData && this.educationinfoData.candidateDegree) {
  //             this.educationinfoData.education = this.educationinfoData.candidateDegree[0];
  //             this.anotherEducation = this.educationinfoData.candidateDegree;
  //         }

  //         if(this.familyData && this.familyData.spouse){
  //              this.familyinfoData.spouseName = this.familyData.spouse.spouseName;
  //              this.familyinfoData.spouseEmail = this.familyData.spouse.spouseEmail;
  //              this.familyinfoData.spousePhoneCode = this.familyData.spouse.spousePhoneCode;
  //              this.familyinfoData.spousePhoneNumber = this.familyData.spouse.spousePhoneNumber;
  //             this.familyinfoData.spouseDOB = this.convert(this.familyData.spouse.spouseDOB);
  //              this.familyinfoData.spouseCountry = this.familyData.spouseCountry ;
  //             this.familyinfoData.spouseResidentCountry = this.familyData.spouse.spouseResidentCountry ;
  //              this.familyinfoData.spousePassportNumber =  this.familyData.spouse.spousePassportNumber ;
  //             this.familyinfoData.spousePassportDOI =  this.convert(this.familyData.spouse.spousePassportDOI) ;
  //             this.familyinfoData.spousePassportDOE =  this.convert(this.familyData.spouse.spousePassportDOE);
  //             let others = this.candidateData.candidateFamilyDetails.others
  //                 if(others.courtofLaw == true){
  //                 this.familyinfoData.courtofLaw= "Yes";
  //             }else{
  //                 this.familyinfoData.courtofLaw = "No"
  //             }
  //             if(others.employeeDischargeStatus == true ){
  //                 this.familyinfoData.employeeDischargeStatus = 'Yes';
  //             }else{
  //                 this.familyinfoData.employeeDischargeStatus= 'No';
  //             }
  //             if(others.otherStatus == true){
  //                 this.familyinfoData.otherStatus = 'Yes';
  //             }else{
  //                 this.familyinfoData.otherStatus = 'No';

  //             }
  //             this.familyinfoData.otherStatusDescription = others.otherStatusDescription ;
  //             this.familyinfoData.courtofLawDescription = others.courtofLawDescription;
  //             this.familyinfoData.employeeDischargeDesc = others.employeeDischargeDesc;
  //             }

  //     })
  // }

  minimumDob() {
    let date = new Date();
    let mimimumDob = this.minConvertDate(date);
    let finalDob = new Date(mimimumDob);
    this.minDob = finalDob;
  }

  minConvertDate(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2),
      year = date.getFullYear() - 18;
    return [mnth, day, year].join('/');
  }

  // getDegree() {
  //     this.commonService.getDegree().subscribe(res => {
  //         this.degree = res
  //     })
  // }

  // getNationality() {
  //     this.commonService.getCandidateNationality().subscribe(res => {
  //         this.nationality = res;
  //         this.countryNationality = res;
  //         this.countryofBirth = res;
  //         this.resident = res;
  //     })
  // }

  // getLevelOfEducation() {
  //     this.commonService.getLevelOfEducation().subscribe(res => {
  //         this.levelofeducation = res
  //     })
  // }

  // getGrade() {
  //     this.commonService.getGrade().subscribe(res => {
  //         this.grade = res
  //     })
  //     this.commonService.getLanguages().subscribe(res => {
  //         this.language = res;
  //     });
  // }

  onDob() {
    this.dateofBirthError = false;
  }

  //radio yes or no  buttons section start
  ReadMore: boolean = true;
  ReadMore1: boolean = false;
  isDisabled: boolean = true;
  readioSelected: any;
  WorkPermitNo() {
    this.ReadMore = false;
    this.ReadMore1 = true;
  }
  WorkPermitYes() {
    this.ReadMore = true;
    this.ReadMore1 = false;
  }
  //radio yes or no section end

  public dateofBirthError: boolean = false;
  generalInfonext() {
    // console.log("aaaaaaaaaaaaaa",this.files11,this.files22)

    // if (this.files11.length > 0 || this.files22.length > 0) {
    //   this.relvantVisaenalbe = true;
    // }

    if (
      !this.basicInfo.invalid &&
      !this.showPhoneError &&
      !this.dateofBirthError
    ) {
      console.log(this.basicInfoData,"====================")
      if (this.basicInfoData.dob) {
        let dobFormate = this.convert(this.basicInfoData.dob);
        this.basicInfoData.dob = dobFormate;
      }
      // if (this.basicInfoData.immigration.dateofIssue) {
      //   let doiFormate = this.convert(
      //     this.basicInfoData.immigration.dateofIssue
      //   );
      //   this.basicInfoData.immigration.dateofIssue = doiFormate;
      // }
      // if (this.basicInfoData.immigration.dateofExpiry) {
      //   let doeFormate = this.convert(
      //     this.basicInfoData.immigration.dateofExpiry
      //   );
      //   this.basicInfoData.immigration.dateofExpiry = doeFormate;
      // }
      this.basicInfoData.candidateStatus = 1;
      this.basicInfoData.userId = this.userId;
      delete this.basicInfoData.checkAddress;
      this.showGeneralInfo = false;
      this.showWorkHistory = true;
      this.sideButtonEnable = false;
      this.side_ButtonEnable = true;
      this.workSideButton = true;
      this.work_SideButton = true;
      window.scroll(0, 0);
    } else {
      let validationDescription = this.basicInfo.form.controls;
      for (let keyVar in validationDescription) {
        validationDescription[keyVar].markAsTouched();
        validationDescription[keyVar].markAsDirty();
      }
      if (this.basicInfoData.dob) {
        this.dateofBirthError = false;
      } else {
        this.dateofBirthError = true;
      }
      if (this.successupload == true) {
        this.uploadresumeEnable = false;
      } else {
        this.uploadresumeEnable = true;
      }
      if (this.basicInfoData.immigration.relevantVisa == 'true') {
        this.showRelvantimage = true;
        if (this.uploadresumeEnable == true) {
          if (this.id) {
            this.uploadresumeEnable = false;
          } else {
            this.uploadresumeEnable = true;
          }
        } else {
          this.uploadresumeEnable = false;
        }
      } else {
        this.showRelvantimage = false;
      }
      if (this.relvantVisaenalbe == true) {
        this.showRelvantimage = false;
      } else {
        this.showRelvantimage = true;
      }
      for (let keyVar in validationDescription) {
        if (this.basicInfo.form.controls[keyVar].errors) {
          //this.scrollToError(this.basicInfo.form.controls[keyVar]);
          this.scrollToFirstInvalidControl(keyVar);
          return;
        }
      }
    }
  }

  // workHistoryNext() {
  //   if (!this.workForm.invalid && this.anotherJobArray.length >= 1) {
  //     if (this.workinfoData.doj) {
  //       let workDob = this.convert(this.workinfoData.doj);
  //       this.workinfoData.doj = workDob;
  //     }
  //     this.workinfoData.candidatePreviousWorkHistory = this.anotherJobArray;
  //     this.workinfoData.candidateWorkHistoryReferences = this.anotherReference;
  //     delete this.workinfoData.job;
  //     delete this.workinfoData.ref;
  //     this.showWorkHistory = false;
  //     this.showEducationalInfo = true;
  //     this.workSideButton = false;
  //     this.work_SideButton = true;
  //     this.educationSideButton = true;
  //     this.education_SideButton = true;
  //     this.showJobArrayError = false;
  //     window.scroll(0, 0);
  //   } else {
  //     if (this.anotherJobArray.length == 0) {
  //       this.showJobArrayError = true;
  //     } else {
  //       this.showJobArrayError = false;
  //     }
  //     let validationDescription = this.workForm.form.controls;
  //     for (let keyVar in validationDescription) {
  //       validationDescription[keyVar].markAsTouched();
  //       validationDescription[keyVar].markAsDirty();

  //     }
  //   }
  // }

  // showDocumentviewed(workDatadoc,i){
  //   if(workDatadoc && workDatadoc.documentId){
  //    var id = workDatadoc.documentId ;
  //     }
  //    this.candidateService.deleteDoc(id).subscribe(res =>{
  //     this.notifyService.showSuccess("Success !", "Document deleted Successfully");
  //     this.educationDocumentArray.splice(i,1)
  //     this.educationinfoData.candidateEducationDocuments =  this.educationDocumentArray || []
  //    })
  // }

  // educationalInfo() {
  //   if (this.anotherEducation.length >= 1) {
  //     if (this.educationinfoData.yearofGraduation) {
  //       let yearFormate = this.convert(this.educationinfoData.yearofGraduation);
  //       this.educationinfoData.yearofGraduation = yearFormate;
  //     }
  //     this.educationinfoData.candidateDegree = this.anotherEducation;
  //     delete this.educationinfoData.education;
  //     this.showEducationalInfo = false;
  //     this.showfamilyInfo = true;
  //     this.educationSideButton = false;
  //     this.education_SideButton = true;
  //     this.familySideButton = true;
  //     this.family_SideButton = true;
  //     window.scroll(0, 0);
  //   } else {
  //     if (this.eduInfoArray.length == 0) {
  //       this.showEducationError = true;
  //     } else {
  //       this.showEducationError = false;
  //     }
  //   }
  // }
  familyInfoNext() {
    this.familyinfoData.child = this.childArray;
    this.showfamilyInfo = false;
    this.showOthers = true;
    this.familySideButton = false;
    this.family_SideButton = true;
    this.otherSideButton = true;
    this.other_SideButton = true;
    // this.familyinfoData.courtofLaw = 'true';
    if (this.familyinfoData.courtofLaw == 'Yes') {
      this.countryEnable = true;
    }
    // this.familyinfoData.employeeDischargeStatus = 'true';
    if (this.familyinfoData.employeeDischargeStatus == 'Yes') {
      this.suspendEnable = true;
    }
    // this.familyinfoData.otherStatus = 'true';
    if (this.familyinfoData.otherStatus == 'Yes') {
      this.checkDisability = true;
    }
    window.scroll(0, 0);
  }
  termsAndConditions: boolean = true;
  disabledPublishButton: boolean = false;
  termsAndConditionsChange(e) {
    var x = e;
    if (e?.target?.checked) {
      this.disabledPublishButton = false;
    } else {
      this.disabledPublishButton = true;
    }
  }
  btnLoading: boolean = false;
  publicSubmit() {
    this.submitEnable = false;
    this.disabledPublishButton = true;
    this.btnLoading = true;
    var workData: any = {};
    let familyData: any = {};
    var educationDetails: any = {};
    let candidatePreviousWorkHistory: any =
      this.workinfoData.candidatePreviousWorkHistory;
    let candidateWorkHistoryReferences =
      this.workinfoData.candidateWorkHistoryReferences;
    let child = this.childArray;
    let others = {};
    let spouse = {};
    this.basicInfoData.country = this.basicInfoData.countryBirth;
    if (this.familyinfoData.courtofLaw == 'Yes') {
      others['courtofLaw'] = true;
    } else {
      others['courtofLaw'] = false;
    }
    if (this.familyinfoData.employeeDischargeStatus == 'Yes') {
      others['employeeDischargeStatus'] = true;
    } else {
      others['employeeDischargeStatus'] = false;
    }
    if (this.familyinfoData.otherStatus == 'Yes') {
      others['otherStatus'] = true;
    } else {
      others['otherStatus'] = false;
    }
    others['courtofLawDescription'] =
      this.familyinfoData.courtofLawDescription || null;
    others['employeeDischargeDesc'] =
      this.familyinfoData.employeeDischargeDesc || null;
    others['otherStatusDescription'] =
      this.familyinfoData.otherStatusDescription || null;
    workData['candidatePreviousWorkHistory'] = candidatePreviousWorkHistory;
    // workData['WorkHistoryReferences'] = candidateWorkHistoryReferences;
    workData['totalExpereince'] = this.workinfoData.totalExpereince || null;
    workData['relevantExpereince'] =
      this.workinfoData.relevantExpereince || null;
    workData['noticePeriod'] = this.workinfoData.relevantExpereince || null;
    workData['additionalAttachments'] =
      this.workinfoData.additionalAttachments || null;
    workData['additionalAttachmentsName'] =
      this.workinfoData.additionalAttachmentsName || null;
    workData['additionalAttachmentsPath'] =
      this.workinfoData.additionalAttachmentsPath || null;
    workData['doj'] = this.workinfoData.doj || null;
    workData['checkrefAvailability'] =
      this.workinfoData.checkrefAvailability || false;
    workData['doj'] = this.workinfoData.doj;
    workData['presentSalary'] = this.workinfoData.currency || null;
    workData['expectedSalary'] = this.workinfoData.expectedSalary || null;
    workData['candidateWorkHistoryReferences'] = this.anotherReference || null;
    spouse['spouseName'] = this.familyinfoData.spouseName || null;
    spouse['spouseEmail'] = this.familyinfoData.spouseEmail || null;
    spouse['spousePhoneCode'] = this.familyinfoData.spousePhoneCode || null;
    spouse['spouseDOB'] = this.familyinfoData.spouseDOB || null;
    spouse['spouseCountry'] = this.familyinfoData.spouseCountry;
    spouse['spouseResidentCountry'] =
      this.familyinfoData.spouseResidentCountry || null;
    spouse['spousePassportNumber'] =
      this.familyinfoData.spousePassportNumber || null;
    spouse['spousePassportDOI'] = this.familyinfoData.spousePassportDOI || null;
    spouse['spousePassportDOE'] = this.familyinfoData.spousePassportDOE || null;
    spouse['spousePhoneNumber'] = this.familyinfoData.spousePhoneNumber || null;
    familyData['child'] = child;
    familyData['others'] = others;
    familyData['spouse'] = spouse;
    educationDetails['candidateDegree'] =
      this.educationinfoData.candidateDegree || null;
    educationDetails['languagesKnown'] =
      this.educationinfoData.languagesKnown || null;
    educationDetails['otherAchievements'] =
      this.educationinfoData.otherAchievements || null;
    educationDetails['uploadCertificate'] = '' || null;
    educationDetails['uploadCertificateName'] = '' || null;
    educationDetails['uploadCertificatePath'] = '' || null;
    educationDetails['candidateEducationDocuments'] =
      this.educationDocumentArray || [];
    // [
    //     {
    //       "documentId": 0,
    //       "candidateId": 0,
    //       "certificate": "base64",
    //       "certificateName": "string",
    //       "certificatePath": "string"
    //     }
    //   ];
    if (this.id) {
      workData['candidateWorkHistoryId'] =
        this.workinfoData.candidateWorkHistoryId;
      workData['candidateId'] = this.basicInfoData.candidateId;
      (familyData['candidateFamilyId'] = this.familyData.candidateFamilyId),
        (familyData['candidateId'] = this.familyData.candidateId);
      // familyData['child'] = this.familyData.candidateFamilyId;
      // child['candidateFamilyId'] = this.familyData.candidateFamilyId;
      educationDetails['candidateId'] =
        this.educationinfoData.candidateEducationId;
      educationDetails['candidateEducationId'] =
        this.educationinfoData.candidateEducationId;
    }
    var params: any = {
      candidateGeneralInfo: this.basicInfoData,
      candidateWorkHistory: workData,
      candidateEducationDetails: educationDetails,
      candidateFamilyDetails: familyData,
    };

    console.log(params);
    // setTimeout(() => {
    //     this.disabledPublishButton=false;
    //     this.btnLoading=false;
    // }, 5000);
    // this.candidateService.createCandidate(params).subscribe(res => {
    //     if (this.appSettings.getIsCandidateLogin()) {
    //         this.submitEnable = false;
    //         this.disabledPublishButton = false;
    //         this.btnLoading = false;
    //         this.router.navigate(["/candidate/" + this.userId]);
    //     }
    //     else {
    //         this.submitEnable = false;
    //         this.disabledPublishButton = false;
    //         this.btnLoading = false;
    //         if (this.id) {
    //             this.notifyService.showSuccess("Thank you !", "Candidate is Updated Successfully");
    //         } else {
    //             this.notifyService.showSuccess("Thank you !", "Candidate is Created Successfully");
    //         }
    //         this.router.navigate(["/candidate/candidates"]);
    //     }
    //     // this.disabledPublishButton = false;
    //     // this.btnLoading = false;
    // }, err => {
    //     this.submitEnable = true;
    //     this.disabledPublishButton = false;
    //     this.btnLoading = false;
    //     this.notifyService.showError("Please Create Again", "Something is wrong");
    // })
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onSelect1(event) {
    console.log(event);
    this.files1.push(...event.addedFiles);
  }
  onSelect3(event) {
    console.log(event);
    this.files2.push(...event.addedFiles);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  files11: File[] = [];

  files22: File[] = [];
  onSelect22(event) {
    console.log(event);
    this.files22.push(...event.addedFiles);
  }

  onSelect11(event) {
    console.log(event);
    this.files11.push(...event.addedFiles);
  }
  onRemove11(event) {
    console.log(event);
    this.files11.splice(this.files11.indexOf(event), 1);
  }
  onRemove22(event) {
    console.log(event);
    this.files22.splice(this.files11.indexOf(event), 1);
  }

  onProfileSelect(event) {
    if (event.addedFiles.length > 0 && event.addedFiles[0].size > 200000) {
      this.showPhotoMaxLimit = true;
      this.showPhoto = false;
      return;
    } else {
      this.profileFiles = [];
      this.postMultimedias = [];
      console.log(event);
      this.profileFiles.push(...event.addedFiles);
      if (this.profileFiles && this.profileFiles[0]) {
        for (let i = 0; i < this.profileFiles.length; i++) {
          this.fileToBase64(this.profileFiles[i]).then((result) => {
            const base64String = result
              .replace('data:', '')
              .replace(/^.+,/, ''); // To remove data url part
            this.postMultimedias.push({
              name: this.profileFiles[i].name,
              content: base64String,
            }); //postMultimedias is a array which holds image name and bas64String
            console.log('image', this.postMultimedias[0].content);
            this.basicInfoData.candidatePhoto = this.postMultimedias[0].content;
            this.basicInfoData.candidatePhotoName =
              this.postMultimedias[0].name;
            this.showPhotoMaxLimit = false;
          });
        }
      }
    }
  }

  showUpload() {
    this.hideImage = false;
    this.uploadImage = true;
    this.userPhoto = false;
    this.showPhoto = true;
  }

  showCandidateResume() {
    this.showUploadResume = true;
    this.showpdf = false;
  }

  showCertificat() {
    this.hideWorkhistoryCertificate = false;
    this.uploadWorkhistoryCertificate = true;
  }

  fileToBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = (error) => reject(error);
    });
  };

  base64ToArrayBuffer(base64) {
    var base64Index = base64.indexOf(';base64,') + ';base64,'.length;
    var base64 = base64.substring(base64Index);
    var raw = window.atob(base64Index);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;

    // var binary_string = window.atob(base64);
    // var len = binary_string.length;
    // var bytes = new Uint8Array(len);
    // for (var i = 0; i < len; i++) {
    //     bytes[i] = binary_string.charCodeAt(i);
    // }
    // return bytes.buffer;
  }

  onProfileRemove(event) {
    console.log(event);
    this.profileFiles.splice(this.files.indexOf(event), 1);
  }

  public relvantVisaenalbe: boolean = false;
  public showRelvantimage: boolean = false;
  // onImggirationSelect(event) {
  //     this.imggirationFiles = [];
  //     this.imggirationMedias = [];
  //     this.imggirationFiles.push(...event.addedFiles);
  //     if (this.imggirationFiles && this.imggirationFiles[0]) {
  //         for (let i = 0; i < this.imggirationFiles.length; i++) {
  //             this.fileToBase64(this.imggirationFiles[i])
  //                 .then(result => {
  //                     const base64String = result.replace('data:', '')
  //                         .replace(/^.+,/, '');
  //                     this.imggirationMedias.push({
  //                         name: this.imggirationFiles[i].name, content:
  //                             base64String
  //                     });
  //                     this.basicInfoData.immigration.passportImage = this.imggirationMedias[0].content;
  //                     this.basicInfoData.immigration.passportImageName = this.imggirationMedias[0].name;
  //                     this.notifyService.showSuccess("Thank you !", "Document Uploaded Successfully !");
  //                     document.getElementById('immgrationUpload').style.border = "2px solid green";
  //                     this.relvantVisaenalbe = true;
  //                     this.showRelvantimage = false;
  //                 });

  //         }
  //     }
  // }

  onImggiationRemove(event) {
    this.imggirationFiles.splice(this.files.indexOf(event), 2);
  }

  onImggiationRemove1(event) {
    this.imggirationFiles.splice(this.files.indexOf(event), 2);
  }

  // onWorkInfoAttachment(event) {
  //     this.workInfoFiles = [];
  //     this.workInfoMedias = [];
  //     this.workInfoFiles.push(...event.addedFiles);
  //     if (this.workInfoFiles && this.workInfoFiles[0]) {
  //         for (let i = 0; i < this.workInfoFiles.length; i++) {
  //             this.fileToBase64(this.workInfoFiles[i])
  //                 .then(result => {
  //                     const base64String = result.replace('data:', '')
  //                         .replace(/^.+,/, ''); // To remove data url part
  //                     this.workInfoMedias.push({
  //                         name: this.workInfoFiles[i].name, content:
  //                             base64String
  //                     });//postMultimedias is a array which holds image name and bas64String
  //                     this.workinfoData.additionalAttachments = this.workInfoMedias[0].content;
  //                     this.workinfoData.additionalAttachmentsName = this.workInfoMedias[0].name;
  //                     this.notifyService.showSuccess("Thank you !", "Document Uploaded Successfully !");
  //                 });

  //         }
  //     }
  // }

  onWorkAttachmentRemove(event) {
    this.workInfoFiles.splice(this.files.indexOf(event), 1);
  }

  uploadResumeRemove(event) {
    this.uploadResumeFiles.splice(this.files.indexOf(event), 1);
  }

  public educationDocumentArray: any = [];
  // onEducationUpload(event) {
  //     this.educationDocumentArray = [];
  //     this.educationUploadFiles = [];
  //     this.educationMultimedia = [];
  //     this.educationUploadFiles.push(...event.addedFiles);
  //     if (this.educationUploadFiles && this.educationUploadFiles[0]) {
  //         for (let i = 0; i < this.educationUploadFiles.length; i++) {
  //             this.fileToBase64(this.educationUploadFiles[i])
  //                 .then(result => {
  //                     const base64String = result.replace('data:', '')
  //                         .replace(/^.+,/, '');
  //                     this.educationMultimedia.push({
  //                         name: this.educationUploadFiles[i].name, content:
  //                             base64String
  //                     });
  //                     let obj = {
  //                         "certificate":  this.educationMultimedia[i].content,
  //                         "certificateName": this.educationMultimedia[i].name,
  //                         "certificatePath": "string"
  //                     }
  //                     this.educationDocumentArray.push(obj)
  //                     // this.educationinfoData.uploadCertificate = this.educationMultimedia[0].content;
  //                     // this.educationinfoData.uploadCertificateName = this.educationMultimedia[0].name;
  //                     this.notifyService.showSuccess("Thank you !", "Document Uploaded Successfully !");
  //                     document.getElementById('certificateClass').style.border = "2px solid green"
  //                 });

  //         }
  //     }
  // }

  updateWorkdocument() {
    this.showDocumentview = true;
    this.hideButtoncertificate = false;
  }

  onUploadRemove(event) {
    this.educationUploadFiles.splice(this.files.indexOf(event), 1);
  }

  convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join('/');
  }

  // getJobTitle() {
  //     this.candidateService.getJobTitleData().subscribe(res => {
  //         this.jobTitleValue = res;
  //     })
  // }

  sideBarClick(data) {
    if (data == 'workButton') {
      this.workSideButton = false;
      this.work_SideButton = false;
      this.showWorkHistory = false;
      this.sideButtonEnable = true;
      this.side_ButtonEnable = true;
      this.showGeneralInfo = true;
      setTimeout(() => {
        document.getElementById('uploadClass').style.border = '2px solid green';
      }, 1000);
    } else if (data == 'eductionButton') {
      this.workSideButton = true;
      this.work_SideButton = true;
      this.showWorkHistory = true;
      this.educationSideButton = false;
      this.education_SideButton = false;
      this.showEducationalInfo = false;
    } else if (data == 'familyButton') {
      this.educationSideButton = true;
      this.education_SideButton = true;
      this.familySideButton = false;
      this.family_SideButton = false;
      this.showEducationalInfo = true;
      this.showfamilyInfo = false;
    } else if (data == 'FamilPart') {
      this.familySideButton = true;
      this.education_SideButton = false;
      this.familySideButton = false;
      this.family_SideButton = false;
      this.showEducationalInfo = false;
      this.showfamilyInfo = true;
      this.showOthers = false;
      this.sideButtonEnable = false;
    }
  }

  respectiveScreen(data) {
    if (data == 'Genninfo') {
      if (this.work_SideButton) {
        this.showGeneralInfo = true;
        this.showWorkHistory = false;
        this.showEducationalInfo = false;
        this.showfamilyInfo = false;
        this.showOthers = false;
      }
    } else if (data == 'WorkHIStory') {
      if (this.education_SideButton) {
        this.showGeneralInfo = false;
        this.showWorkHistory = true;
        this.showEducationalInfo = false;
        this.showfamilyInfo = false;
        this.showOthers = false;
      }
    } else if (data == 'EDUINFooo') {
      if (this.family_SideButton) {
        this.showGeneralInfo = false;
        this.showWorkHistory = false;
        this.showEducationalInfo = true;
        this.showfamilyInfo = false;
        this.showOthers = false;
      }
    } else if (data == 'famILYInfoo') {
      if (this.other_SideButton) {
        this.showGeneralInfo = false;
        this.showWorkHistory = false;
        this.showEducationalInfo = false;
        this.showfamilyInfo = true;
        this.showOthers = false;
      }
    } else if (data == 'OthersS') {
      if (this.otherSideButton) {
        this.showGeneralInfo = false;
        this.showWorkHistory = false;
        this.showEducationalInfo = false;
        this.showfamilyInfo = false;
        this.showOthers = true;
      }
    }
  }

  relevantVisa(event, value) {
    if (event.target.checked && value == true) {
      this.showRelavntVisa = true;
      this.relvantVisaenalbe = true;
      this.showRelvantimage = true;
    } else {
      this.showRelavntVisa = false;
      this.relvantVisaenalbe = true;
      this.showRelvantimage = false;
    }
  }

  onDateSelected(data, value) {
    this.minExpireDate = data;
    let maxYear = this.expireConvert(data);
    let date = new Date(maxYear);
    this.maxExprireDate = date;
    if (value == 'immigration') {
      this.basicInfoData.immigration.dateofExpiry = this.maxExprireDate;
    } else if (value == 'spouse') {
      this.familyinfoData.spousePassportDOE = this.maxExprireDate;
    } else if (value == 'child') {
      this.familyinfoData.childPassportDOE = this.maxExprireDate;
    }
  }
  onDateSelectedd(data, value) {
    this.minExpireDate = data;
    let maxYear = this.expireConvert(data);
    let date = new Date(maxYear);
    this.maxExprireDatee = date;
    if (value == 'visa') {
      this.basicInfoData.immigration.visadateofExpiry = this.maxExprireDatee;
    } else if (value == 'spouse') {
      this.familyinfoData.spousePassportDOE = this.maxExprireDatee;
    } else if (value == 'child') {
      this.familyinfoData.childPassportDOE = this.maxExprireDatee;
    }
  }

  expireConvert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2),
      year = date.getFullYear() + 10;
    return [mnth, day, year].join('/');
  }

  // addressDuplicate(event) {
  //   if (
  //     !this.basicInfo.invalid &&
  //     !this.showPhoneError &&
  //     !this.dateofBirthError
  //   ) {
  //     let data = event.target.checked;
  //     if (data == true) {
  //       let obj: any = Object;
  //       if (obj.values(this.basicInfoData.address).length > 0) {
  //         this.basicInfoData.currentAddress.currentAddressdata =
  //           this.basicInfoData.address.permanentAddress;
  //         this.basicInfoData.currentAddress.currentPhoneNumber =
  //           this.basicInfoData.address.permanentphoneNumber;
  //         this.basicInfoData.currentAddress.zipcode =
  //           this.basicInfoData.address.zipcode;
  //         this.basicInfoData.currentAddress.city =
  //           this.basicInfoData.address.city;
  //         this.basicInfoData.currentAddress.state =
  //           this.basicInfoData.address.state;
  //         this.basicInfoData.currentAddress.country =
  //           this.basicInfoData.address.country;
  //         this.basicInfoData.currentAddress.currentPhoneCode =
  //           this.basicInfoData.address.permanentphoneCode;
  //       }
  //     } else {
  //       this.basicInfoData.currentAddress.currentAddressdata = '';
  //       this.basicInfoData.currentAddress.currentPhoneNumber = '';
  //       this.basicInfoData.currentAddress.zipcode = '';
  //       this.basicInfoData.currentAddress.city = '';
  //       this.basicInfoData.currentAddress.state = '';
  //       this.basicInfoData.currentAddress.country = '';
  //       this.basicInfoData.currentAddress.currentPhoneCode = '';
  //     }
  //   } else {
  //     let validationDescription = this.basicInfo.form.controls;
  //     for (let keyVar in validationDescription) {
  //       validationDescription[keyVar].markAsTouched();
  //       validationDescription[keyVar].markAsDirty();
  //     }
  //     if (this.basicInfoData.dob) {
  //       this.dateofBirthError = false;
  //     } else {
  //       this.dateofBirthError = true;
  //     }
  //   }
  // }

  lawcountryEnable(data, value) {
    if (data.target.checked == true && value == true) {
      this.countryEnable = true;
    } else {
      this.countryEnable = false;
    }
  }

  suspendCheck(data, value) {
    if (data.target.checked == true && value == true) {
      this.suspendEnable = true;
    } else {
      this.suspendEnable = false;
    }
  }

  disabilityCheck(data, value) {
    if (data.target.checked == true && value == true) {
      this.checkDisability = true;
    } else {
      this.checkDisability = false;
    }
  }

  addanotherJob() {
    let obj: any = Object;
    if (
      this.workinfoData.jobTitle &&
      this.workinfoData.employeerName &&
      this.workinfoData.city &&
      this.workinfoData.state &&
      this.workinfoData.country &&
      this.workinfoData.currency
    ) {
      let object = {
        jobTitle: this.workinfoData.jobTitle,
        // coreSubjects: this.workinfoData.coreSubjects,
        employersName: this.workinfoData.employeerName,
        city: this.workinfoData.city,
        state: this.workinfoData.state,
        country: this.workinfoData.country,
        currency: this.workinfoData.currency,
        isCurrentJob: this.workinfoData.isCurrentJob,
      };
      if (this.workinfoData.jobTitle && this.workinfoData.employeerName) {
        this.anotherJobArray.push(object);
        this.showJobArrayError = false;
        // this.anotherJobArray = this.getUniqueListBy(this.anotherJobArray,'jobTitle');
        this.anotherJobArray = this.anotherJobArray.filter(
          (v, i, a) =>
            a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
        );
        this.workinfoData.city = '';
        this.workinfoData.state = '';
        this.workinfoData.currency = '';
        this.workinfoData.employeerName = '';
        this.workinfoData.country = '';
        this.workinfoData.jobTitle = '';
      }
    } else {
      if (this.addJobsHereArray.length == 0) {
        this.showJobArrayError = true;
      } else {
        this.showJobArrayError = false;
      }
    }
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  deleteJob(i) {
    this.anotherJobArray.splice(i, 1);
    if (this.anotherJobArray.length == 0) {
      this.showJobArrayError = true;
    }
  }
  editJob(i) {
    this.enableUpdateJob = true;
    this.jobId = i;
    let index = i;
    for (let j = 0; j < this.anotherJobArray.length; j++) {
      if (j == i) {
        this.workinfoData.jobTitle = this.anotherJobArray[j].jobTitle;
        this.workinfoData.employeerName = this.anotherJobArray[j].employeerName;
        this.workinfoData.city = this.anotherJobArray[j].city;
        this.workinfoData.state = this.anotherJobArray[j].state;
        this.workinfoData.country = this.anotherJobArray[j].country;
        this.workinfoData.isCurrentJob = this.anotherJobArray[j].isCurrentJob;
      }
    }
  }

  updateJob() {
    this.anotherJobArray[this.jobId].jobTitle = this.workinfoData.jobTitle;
    this.anotherJobArray[this.jobId].employeerName =
      this.workinfoData.employeerName;
    this.anotherJobArray[this.jobId].city = this.workinfoData.city;
    this.anotherJobArray[this.jobId].state = this.workinfoData.state;
    this.anotherJobArray[this.jobId].country = this.workinfoData.country;
    this.anotherJobArray[this.jobId].isCurrentJob =
      this.workinfoData.isCurrentJob;
  }

  refOne = [];
  refTwo = [];

  addAnotherReferenceOne() {
    let obj: any = Object;
    if (obj.values(this.workinfoData).length > 0) {
      let object = {
        orRGGNAme: this.workinfoData.orgNameee,
        reFFNamee: this.workinfoData.refereeNameee,
        ReffPOSition: this.workinfoData.positionREF,
        REFFEMaill: this.workinfoData.refemailAddress,
        countryYCODEe: this.familyinfoData.selectedCountId,
        REFFEMObilee: this.familyinfoData.spousePhoneNumber,
      };
      if (this.workinfoData.orgNameee) {
        this.refOne.push(object);
        this.refOne = this.refOne.filter(
          (v, i, a) =>
            a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
        );

        // this.anotherReference = this.getUniqueListBy(this.anotherReference,'refemailAddress');
      }
      this.workinfoData.orgNameee = '';
      this.workinfoData.refereeNameee = '';
      this.workinfoData.positionREF = '';
      this.workinfoData.refemailAddress = '';
      this.familyinfoData.spousePhoneNumber = '';
      this.familyinfoData.selectedCountId = null;
    }
  }

  refereesData: any = {};

  addAnotherReference(i) {
    let obj = {
      orgNameeeTwo: null,
      refereeNameeeTwo: null,
      positionREFTwo: null,
      refemailAddressTwo: null,
      selectedcountIdTwo: null,
      spousePhoneNumberTwo: null,
    };
    this.refereesData.referees.push(obj);
  }

  deleteReffFields(i) {
    this.refereesData.referees.splice(i, 1);
  }

  // addAnotherReference() {

  //     let object = {
  //       organNameTwo: this.workinfoData.orgNameeeTwo,
  //       RefNameTwo: this.workinfoData.refereeNameeeTwo,
  //       RefPositionnTwOO: this.workinfoData.positionREFTwo,
  //       RefEmailTwoo: this.workinfoData.refemailAddressTwo,
  //       RefCountCodeTwoo: this.familyinfoData.selectedcountIdTwo,
  //       RefMoBIleTwoo: this.familyinfoData.spousePhoneNumberTwo,
  //     };
  //     if (this.workinfoData.orgNameeeTwo) {
  //       this.refTwo.push(object);
  //       console.log(object, "&&&&&&&&&&&&&&&&");

  //       this.refTwo = this.refTwo.filter(
  //         (v, i, a) =>
  //           a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
  //       );
  //     }
  //     this.workinfoData.orgNameeeTwo = '';
  //     this.workinfoData.refereeNameeeTwo = '';
  //     this.workinfoData.positionREFTwo = '';
  //     this.workinfoData.refemailAddressTwo = '';
  //     this.familyinfoData.selectedcountIdTwo = '';
  //     this.familyinfoData.spousePhoneNumberTwo = '';

  //   this.familyinfoData.selectedcountIdTwo = null;
  // }

  // deleteReference(i) {
  //   this.refOne.splice(i, 1);
  // }
  // deleteReferenceTwo(i) {
  //   this.refTwo.splice(i, 1);
  // }

  editReference(i) {
    this.referenceUpdate = true;
    let index = i;
    this.referenceId = i;
    for (let j = 0; j < this.anotherReference.length; j++) {
      if (j == i) {
        this.workinfoData.refEmployeeName =
          this.anotherReference[j].refEmployeeName;
        this.workinfoData.refemailAddress =
          this.anotherReference[j].refemailAddress;
        this.workinfoData.refPosition = this.anotherReference[j].refPosition;
        this.workinfoData.checkrefAvailability =
          this.anotherReference[j].checkrefAvailability;
      }
    }
  }

  updateReferences() {
    this.anotherReference[this.referenceId].refEmployeeName =
      this.workinfoData.refEmployeeName;
    this.anotherReference[this.referenceId].refemailAddress =
      this.workinfoData.refemailAddress;
    this.anotherReference[this.referenceId].refPosition =
      this.workinfoData.refPosition;
    this.anotherReference[this.referenceId].checkrefAvailability =
      this.workinfoData.checkrefAvailability;
  }
  // addanotherDegree() {
  //   let obj: any = Object;
  //   if (
  //     this.educationinfoData.levelofEducation &&
  //     this.educationinfoData.degree &&
  //     this.educationinfoData.yearofGraduation
  //   ) {
  //     if (this.educationinfoData.yearofGraduation) {
  //       let spouseDobFormate = this.convert(
  //         this.educationinfoData.yearofGraduation
  //       );
  //       this.educationinfoData.yearofGraduation = spouseDobFormate;
  //     }
  //     let object = {
  //       levelofEducation: this.educationinfoData.levelofEducation,
  //       degree: this.educationinfoData.degree,
  //       grade: this.educationinfoData.grade,
  //       college: this.educationinfoData.college,
  //       mediumofStudy: this.educationinfoData.mediumofStudy,
  //       yearofGraduation: this.educationinfoData.yearofGraduation,
  //     };

  //     this.anotherEducation.push(object);
  //     this.showEducationError = false;
  //     this.anotherEducation = this.anotherEducation.filter(
  //       (v, i, a) =>
  //         a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
  //     );
  //     this.educationinfoData.levelofEducation = '';
  //     this.educationinfoData.degree = '';
  //     this.educationinfoData.grade = '';
  //     this.educationinfoData.college = '';
  //     this.educationinfoData.mediumofStudy = '';
  //     this.educationinfoData.yearofGraduation = '';
  //   } else {
  //     this.showEducationError = true;
  //   }
  // }

  editDegree(i) {
    let index = i;
    this.degreeUpdate = true;
    this.degreeId = i;
    for (let j = 0; j < this.anotherEducation.length; j++) {
      if (j == i) {
        this.educationinfoData.levelofEducation =
          this.anotherEducation[j].levelofEducation;
        this.educationinfoData.degree = this.anotherEducation[j].degree;
        this.educationinfoData.grade = this.anotherEducation[j].grade;
        this.educationinfoData.yearofGraduation =
          this.anotherEducation[j].yearofGraduation;
        this.educationinfoData.college = this.anotherEducation[j].college;
        this.educationinfoData.mediumofstudy =
          this.anotherEducation[j].mediumofStudy;
      }
    }
  }

  updateDegree() {
    this.anotherEducation[this.degreeId].levelofEducation =
      this.anotherEducation.levelofEducation;
    this.anotherEducation[this.degreeId].degree = this.anotherEducation.degree;
    this.anotherEducation[this.degreeId].grade = this.anotherEducation.grade;
    this.anotherEducation[this.degreeId].yearofGraduation =
      this.anotherEducation.yearofGraduation;
    this.anotherEducation[this.degreeId].college =
      this.anotherEducation.college;
    this.anotherEducation[this.degreeId].mediumofstudy =
      this.anotherEducation.mediumofstudy;
  }

  // deleteDegree(i) {
  //   this.anotherEducation.splice(i, 1);
  //   if (this.anotherEducation.length == 0) {
  //     this.showEducationError = true;
  //   } else {
  //     this.showEducationError = false;
  //   }
  // }

  public childArray: any = [];

  addworkChild() {
    let childObj = {
      childName: this.familyinfoData.childName,
      childEmail: this.familyinfoData.childEmail,
      childPhoneCode: this.familyinfoData.childPhoneCode,
      childPhoneNumber: this.familyinfoData.childPhoneNumber,
      childDOB: this.convert(this.familyinfoData.childDOB),
      childCountry: this.familyinfoData.selectedChildCountry,
      childResidentCountry: this.familyinfoData.typeOfResidenet,
      childPassportNumber: this.familyinfoData.childPassportNumber,
      childPassportDOI: this.convert(this.familyinfoData.childPassportDOI),
      childPassportDOE: this.convert(this.familyinfoData.childPassportDOE),
    };

    if (this.familyinfoData.childName) {
      this.childArray.push(childObj);
      // console.log(this.childArray, '##############################');

      (this.familyinfoData.childName = ''),
        (this.familyinfoData.childEmail = ''),
        (this.familyinfoData.childPhoneCode = ''),
        (this.familyinfoData.childPhoneNumber = ''),
        (this.familyinfoData.childDOB = ''),
        (this.familyinfoData.selectedChildCountry = ''),
        (this.familyinfoData.typeOfResidenet = ''),
        (this.familyinfoData.childPassportNumber = ''),
        (this.familyinfoData.childPassportDOI = ''),
        (this.familyinfoData.childPassportDOE = '');
    }
  }

  // addworkChild() {
  //   if (this.familyinfoData.spouse.spouseDOB) {
  //     let spouseDobFormate = this.convert(this.familyinfoData.spouse.spouseDOB);
  //     this.familyinfoData.spouse.spouseDOB = spouseDobFormate;
  //   }
  //   if (this.familyinfoData.childDOB) {
  //     let childDobFormate = this.convert(this.familyinfoData.childDOB);
  //     this.familyinfoData.childDOB = childDobFormate;
  //   }
  //   if (this.familyinfoData.childPassportDOI) {
  //     let spouseimmigDateofIssue = this.convert(
  //       this.familyinfoData.childPassportDOI
  //     );
  //     this.familyinfoData.childPassportDOI = spouseimmigDateofIssue;
  //   }
  //   if (this.familyinfoData.childPassportDOE) {
  //     let spouseimmigDateofExpiry = this.convert(
  //       this.familyinfoData.childPassportDOE
  //     );
  //     this.familyinfoData.childPassportDOE = spouseimmigDateofExpiry;
  //   }
  //   let obj = {
  //     childName: this.familyinfoData.childName,
  //     childEmail: this.familyinfoData.childEmail,
  //     childPhoneNumber: this.familyinfoData.childPhoneNumber,
  //     childDOB: this.familyinfoData.childDOB,
  //     childCountry: this.familyinfoData.childCountry,
  //     childResidentCountry: this.familyinfoData.childResidentCountry,
  //     childPassportNumber: this.familyinfoData.childPassportNumber,
  //     childPassportDOI: this.familyinfoData.childPassportDOI,
  //     childPassportDOE: this.familyinfoData.childPassportDOE,
  //   };
  //   if (this.id) {
  //     obj['candidateFamilyId'] = this.familyData.candidateFamilyId;
  //   }
  //   if (this.familyinfoData.childName && this.familyinfoData.childEmail) {
  //     this.childArray.push(obj);
  //     this.childArray = this.childArray.filter(
  //       (v, i, a) =>
  //         a.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(v)) === i
  //     );
  //     this.familyinfoData.childName = '';
  //     this.familyinfoData.childEmail = '';
  //     this.familyinfoData.childDOB = '';
  //     this.familyinfoData.childPhoneNumber = '';
  //     this.familyinfoData.childCountry = '';
  //     this.familyinfoData.childResidentCountry = '';
  //     this.familyinfoData.childPassportNumber = '';
  //     this.familyinfoData.childPassportDOI = '';
  //     this.familyinfoData.childPassportDOE = '';
  //   }
  // }

  editChild(i) {
    let index = i;
    this.childUpdate = true;
    this.childIndex = i;
    for (let j = 0; j < this.childArray.length; j++) {
      if (j == i) {
        this.familyinfoData.childName = this.childArray[j].childName;
        this.familyinfoData.childEmail = this.childArray[j].childEmail;
        this.familyinfoData.childPhoneNumber =
          this.childArray[j].childPhoneNumber;
        this.familyinfoData.childDOB = this.childArray[j].childDOB;
        this.familyinfoData.childCountry = this.childArray[j].childCountry;
        this.familyinfoData.childResidentCountry =
          this.childArray[j].childResidentCountry;
        this.familyinfoData.childPassportNumber =
          this.childArray[j].childPassportNumber;
        this.familyinfoData.childPassportDOI =
          this.childArray[j].childPassportDOI;
        this.familyinfoData.childPassportDOE =
          this.childArray[j].childPassportDOE;
      }
    }
  }

  deleteChild(i) {
    this.childArray.splice(i, 1);
  }

  updateChild() {
    this.anotherEducation[this.childIndex].childName =
      this.familyinfoData.childName;
    this.anotherEducation[this.childIndex].childEmail =
      this.familyinfoData.childEmail;
    this.anotherEducation[this.childIndex].childPhoneNumber =
      this.familyinfoData.childPhoneNumber;
    this.anotherEducation[this.childIndex].childDOB =
      this.familyinfoData.childDOB;
    this.anotherEducation[this.childIndex].childCountry =
      this.familyinfoData.childCountry;
    this.anotherEducation[this.childIndex].childResidentCountry =
      this.familyinfoData.childResidentCountry;
    this.anotherEducation[this.childIndex].childPassportNumber =
      this.familyinfoData.childPassportNumber;
    this.anotherEducation[this.childIndex].childPassportDOI =
      this.familyinfoData.childPassportDOI;
    this.anotherEducation[this.childIndex].childPassportDOE =
      this.familyinfoData.childPassportDOE;
  }

  keyPressAlphanumeric(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  getCountryValue(event) {
    let country = event.countryname;
    for (let i = 0; i < this.currencySymbol.length; i++) {
      if (this.currencySymbol[i].country == country) {
        this.workinfoData.currencyCode = this.currencySymbol[i].name;
      }
    }
  }

  // validatePhone(data) {
  //     let value = data.target.value;
  //     if (value.length == 10) {
  //         this.candidateService.validateCandidatePhoneNumber(value).subscribe(res => {
  //             if (res) {
  //                 this.showPhoneError = true;
  //             } else {
  //                 this.showPhoneError = false;
  //             }
  //         })
  //     } else {
  //         this.showPhoneError = false;
  //     }
  // }

  uploadResumeFiles: any = [];
  uploadResumeMedias: any = [];
  showUploadResumeMsg: boolean = false;
  uploadresumeEnable: boolean = false;
  successupload: boolean = false;
  // uploadResumeSelect(event?: any) {
  //     this.uploadResumeFiles = [];
  //     this.uploadResumeMedias = [];
  //     if (event?.addedFiles[0]?.type == "application/pdf") {
  //         this.showUploadResumeMsg = false;
  //     }
  //     else {
  //         this.showUploadResumeMsg = true
  //     }

  //     this.uploadResumeFiles.push(...event.addedFiles);
  //     if (this.uploadResumeFiles && this.uploadResumeFiles[0]) {
  //         for (let i = 0; i < this.uploadResumeFiles.length; i++) {
  //             this.fileToBase64(this.uploadResumeFiles[i])
  //                 .then(result => {
  //                     const base64String = result.replace('data:', '')
  //                         .replace(/^.+,/, '');
  //                     this.uploadResumeMedias.push({
  //                         name: this.uploadResumeFiles[i].name, content:
  //                             base64String
  //                     });
  //                     this.basicInfoData.resumeImage = this.uploadResumeMedias[0].content;
  //                     this.basicInfoData.candidateResumeName = this.uploadResumeMedias[0].name;
  //                     this.notifyService.showSuccess("Thank you !", "Document Uploaded Successfully !");
  //                     document.getElementById('uploadClass').style.border ="2px solid green";
  //                     this.uploadresumeEnable = false ;
  //                     this.successupload = true;
  //                 });

  //         }
  //     }
  // }

  // isNumberKey(evt,id)
  // {
  //     try{
  //         var charCode = (evt.which) ? evt.which : evt.keyCode;

  //         if(charCode==46){
  //             var data:any  =document.getElementById(id);
  //             var txt = data.value;
  //             if(!(txt.indexOf(".") > -1)){

  //                 return true;
  //             }
  //         }
  //         if (charCode > 31 && (charCode < 48 || charCode > 57) )
  //             return false;

  //         return true;
  //     }catch(w){
  //         alert(w);
  //     }
  // }

  private scrollToFirstInvalidControl(el = '') {
    // const firstInvalidControl: HTMLElement = el.nativeElement.querySelector(
    //     "form .ng-invalid"
    // );

    const firstElementWithError: HTMLElement = document.getElementById(el);
    // document.getElementsByName()
    const labelOffset = document.getElementsByClassName('header-dashboard')[0];
    const offset = labelOffset.getBoundingClientRect().height;
    var top =
      firstElementWithError.getBoundingClientRect().top +
      window.scrollY -
      offset;
    $('html, body').animate({ scrollTop: top - 100 }, 800);
    firstElementWithError.focus();
  }

  // addCondidate
  // private scrollToFirstworkInvalidControl(el = "") {
  //     // const firstInvalidControl: HTMLElement = el.nativeElement.querySelector(
  //     //     "form .ng-invalid"
  //     // );

  //     const firstElementWithError: HTMLElement = document.getElementById(el);
  //     // document.getElementsByName()
  //     const labelOffset = document.getElementsByClassName("header-dashboard")[0];
  //     const offset = labelOffset.getBoundingClientRect().height;
  //     var top = firstElementWithError.getBoundingClientRect().top + window.scrollY - offset;
  //     $('html, body').animate({ scrollTop: top - 100 }, 800);
  //     firstElementWithError.focus();

  // }

  addFields() {
    let obj = {
      prefferedIndustry: '',
      prefferedIndustryExp: '',
      industryExp: '',
      selectedCity: '',
    };

    this.basicInfoData.addApplicant.push(obj);
    console.log(this.basicInfoData, ' this.basicInfoData');
  }
  deleteFields(i) {
    this.basicInfoData.addApplicant.splice(i, 1);
  }
  addFieldsTwo() {
    let obj = {
      PRefferedDomain: '',
      doMainExperience: '',
      industryExp: '',
      selectedCity1: '',
    };

    this.basicInfoData.addApplicantTwo.push(obj);
  }
  deleteFieldsTwo(i) {
    this.basicInfoData.addApplicantTwo.splice(i, 1);
  }
  addFieldsThree() {
    let obj = {
      typeofHiring: '',
      skills: '',
      industryExp: '',
    };

    this.basicInfoData.addApplicantThree.push(obj);
  }
  deleteFieldsThree(i) {
    this.basicInfoData.addApplicantThree.splice(i, 1);
  }

  changeFn(val: any, item) {
    item.prefferedIndustryExperienceTo = null;
    this.preFExpP = [];
    if (val) {
      for (let i = 0; i < this.preFExp.length; i++) {
        // console.log(val.name  < this.preFExp[i].name,"===========")
        let numberVal = +val.name;
        let perfVal = +this.preFExp[i].name;
        if (numberVal < perfVal) {
          this.preFExpP.push(this.preFExp[i]);
        } else {
          // this.preFExpP[i].isDisabled = false;
        }
      }
    }
  }

  addFieldss() {
    let obj = {
      prefferedIndustry: null,
      prefferedIndustryExperienceFrom: null,
      prefferedIndustryExperienceTo: null,
    };

    this.basicInfoData.industries.push(obj);
  }
  deleteFieldss(i) {
    this.basicInfoData.industries.splice(i, 1);
  }

  getValue(data) {
    // console.log(val, '@@@@@@@@@@@@@@@@@');

    if (data == 'current') {
      let fromNumber = +this.basicInfoData.currentSalaryFrom;
      let toNumber = +this.basicInfoData.currenctSalaryTo;
      if (fromNumber > toNumber) {
        this.showErrorCurrentSalary = true;
      } else {
        this.showErrorCurrentSalary = false;
      }
    }
    if (data == 'expect') {
      let fromNumber = +this.basicInfoData.expectedSalaryFrom;
      let toNumber = +this.basicInfoData.expectedSalaryTo;
      if (fromNumber > toNumber) {
        this.showErrorExpectSalary = true;
      } else {
        this.showErrorExpectSalary = false;
      }
    }
    if (data == 'notice') {
      let fromNumber = +this.basicInfoData.noticePeriodFrom;
      let toNumber = +this.basicInfoData.noticePeriodTo;
      if (fromNumber > toNumber) {
        this.noticeError = true;
      } else {
        this.noticeError = false;
      }
    }
    if (data == 'maxage') {
      let fromNumber = +this.basicInfoData.maximumAgeBarFrom;
      let toNumber = +this.basicInfoData.maximumAgeBarTo;
      if (fromNumber > toNumber) {
        this.ageError = true;
      } else {
        this.ageError = false;
      }
    }
  }

  domainchangeFn(val: any, item) {
    item.domainExperienceTo = null;
    this.domExpP = [];
    if (val) {
      for (let i = 0; i < this.domMExp.length; i++) {
        let numberVal = +val.name;
        let perfVal = +this.domMExp[i].name;
        if (numberVal < perfVal) {
          this.domExpP.push(this.domMExp[i]);
        }
      }
    }
  }
  addFieldsTwoo() {
    let obj = {
      preferedDomain: null,
      domainExperienceFrom: null,
      domainExperienceTo: null,
      // 'industryExp':''
    };

    this.basicInfoData.domains.push(obj);
  }
  deleteFieldsTwoo(i) {
    this.basicInfoData.domains.splice(i, 1);
  }

  totoalExperienc(val: any, item) {
    this.toyears = [];
    if (val) {
      for (let i = 0; i < this.fromyears.length; i++) {
        let numberVal = +val.name;
        let perfVal = +this.fromyears[i].name;
        if (numberVal < perfVal) {
          this.toyears.push(this.fromyears[i]);
        } else {
          // this.preFExpP[i].isDisabled = false;
        }
      }
    }
    item.totalExperienceTo = null;
  }

  addJobsHereArray = [];
  addJobsHere() {
    let objJ = {
      designation: this.workinfoData.designatioonn,
      orgName: this.workinfoData.orgNammee,
      city: this.workinfoData.city,
      country: this.workinfoData.selectedcountrYYY,
      monthlYYCurCode: this.workinfoData.currencyCode,
      monthlYYSalary: this.workinfoData.currency,
      totallVariable: this.workinfoData.totalVariPayAnnum,
      InfoFromDate: this.convert(this.workinfoData.dateValue),
      InfoToODate: this.convert(this.workinfoData.dateValue2),
    };

    if (this.workinfoData.designatioonn) {
      this.addJobsHereArray.push(objJ);
      // console.log(this.addJobsHereArray, '^^^^^^^^^^^^^^^^^');

      this.workinfoData.designatioonn = '';
      this.workinfoData.orgNammee = '';
      this.workinfoData.city = '';
      this.workinfoData.selectedcountrYYY = '';
      this.workinfoData.currencyCode = '';
      this.workinfoData.currency = '';
      this.workinfoData.totalVariPayAnnum = '';
      this.workinfoData.dateValue = '';
      this.workinfoData.dateValue2 = '';
      this.workinfoData.selectedcountrYYY = null;
    }

    // if (!this.addJobsHereArray.length) {
    //   this.anotherJobArrayError = true;
    //   this.addJobsHereArray.push(objJ);
    // } else {
    //   this.anotherJobArrayError = false;
    // }
  }
  // addJobsHereTable = false;
  trashFunction(i) {
    this.addJobsHereArray.splice(i, 1);
  }

  noSpaceBar(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

  // (Cover letter should be in English

  //showJobArrayError
  workHistoryNext() {
    if (this.workForm.invalid) {
      if (this.workinfoData.doj) {
        let workDob = this.convert(this.workinfoData.doj);
        this.workinfoData.doj = workDob;
      }
      this.workinfoData.candidatePreviousWorkHistory = this.addJobsHereArray;
      this.workinfoData.candidateWorkHistoryReferences = this.anotherReference;
      delete this.workinfoData.job;
      delete this.workinfoData.ref;
      this.showWorkHistory = false;
      this.showEducationalInfo = true;
      this.workSideButton = false;
      this.work_SideButton = true;
      this.educationSideButton = true;
      this.education_SideButton = true;
      this.showJobArrayError = false;
      window.scroll(0, 0);
    } else {
      if (this.addJobsHereArray.length == 0) {
        this.showJobArrayError = true;
      } else {
        this.showJobArrayError = false;
      }
      let validationDescription = this.workForm.form.controls;
      for (let keyVar in validationDescription) {
        validationDescription[keyVar].markAsTouched();
        validationDescription[keyVar].markAsDirty();
      }
    }
  }
  eduInfoArray = [];
  addanotherDegree() {
    let eduInfoObj = {
      educatioNN: this.educationinfoData.selectedlevelEducation,
      qualification: this.educationinfoData.selectedqualificationn,
      // GRadee: this.educationinfoData.selectedgradeEE,
      yearOfGradd: this.convert(this.educationinfoData.yearofGraduation),
      collUniNamee: this.educationinfoData.college,
      // MOfStudy: this.educationinfoData.selectedMediumStudy,
      langKnoWN: this.educationinfoData.selectedKnownLanguages,
      // othrAchieveMt: this.educationinfoData.otherAchievements,
    };
    this.eduInfoArray.push(eduInfoObj);
    // console.log(this.eduInfoArray, '$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');

    this.educationinfoData.selectedlevelEducation = '';
    this.educationinfoData.selectedqualificationn = '';
    this.educationinfoData.yearofGraduation = '';
    this.educationinfoData.college = '';
    this.educationinfoData.selectedKnownLanguages = '';

    this.educationinfoData.selectedlevelEducation = null;
    this.educationinfoData.selectedqualificationn = null;
  }
  deleteDegree(i) {
    this.eduInfoArray.splice(i, 1);
  }
  educationalInfo() {
    if (this.eduInfoArray.length >= 1) {
      if (this.educationinfoData.yearofGraduation) {
        let yearFormate = this.convert(this.educationinfoData.yearofGraduation);
        this.educationinfoData.yearofGraduation = yearFormate;
      }
      this.educationinfoData.candidateDegree = this.anotherEducation;
      delete this.educationinfoData.education;
      this.showEducationalInfo = false;
      this.showfamilyInfo = true;
      this.educationSideButton = false;
      this.education_SideButton = true;
      this.familySideButton = true;
      this.family_SideButton = true;
      window.scroll(0, 0);
    } else {
      if (this.eduInfoArray.length == 0) {
        this.showEducationError = true;
      } else {
        this.showEducationError = false;
      }
    }
  }

  showGeneralInfooo = false;
  showDeclerationSs = false;
  showDocumentssS = false;

  GennInfo() {
    this.showEducationalInfo = false;
    this.showGeneralInfooo = true;
    this.generalSideButton = true;
    this.family_SideButton = true;
    this.educationSideButton = false;
  }

  genInfoBack() {
    this.showEducationalInfo = true;
    this.showGeneralInfooo = false;
    this.generalSideButton = false;
    this.educationSideButton = true;
    this.family_SideButton = false;
  }
  GennInfoNext() {
    this.showDeclerationSs = true;
    this.showGeneralInfooo = false;
    this.declareSideButton = true;
    this.generalSideButton = false;
    this.General_SideButton = true;
  }
  DeclarationsBack() {
    this.showDeclerationSs = false;
    this.showGeneralInfooo = true;
    this.generalSideButton = true;
    this.General_SideButton = false;
    this.declareSideButton = false;
  }

  DeclerationsNext() {
    this.showDeclerationSs = false;
    this.showDocumentssS = true;
    this.docSideButton = true;
    this.declareSideButton = false;
    this.declare_SideButton = true;
  }

  docsBack() {
    this.declareSideButton = true;
    this.docSideButton = false;
    this.declare_SideButton = false;
    this.showDocumentssS = false;
    this.showDeclerationSs = true;
  }
  docsNext() {
    this.showfamilyInfo = true;
    this.showDocumentssS = false;
    this.doc_SideButton = true;
    this.familySideButton = true;
    this.docSideButton = false;
  }
  lastBack() {
    this.showDocumentssS = true;
    this.showfamilyInfo = false;
    this.familySideButton = false;
    this.docSideButton = true;
    this.doc_SideButton = false;
  }

  disabledWorkPermitType: boolean = true;
  sponsorShipDisabled: boolean = true;
  WorkPermitChange(event: any) {
    if (event.name == 'Required') {
      this.disabledWorkPermitType = false;
    }
    if (event.name == 'Not Required') {
      this.sponsorShipDisabled = true;
      this.disabledWorkPermitType = true;
    }
    if (event.name == 'Sponsorship') {
      this.sponsorShipDisabled = false;
    }
  }

  // basicInfoData.address.permanentAddress

  // Permanent address inputs
  permanentAddress: any;
  permanentAddressTwo: any;
  permanentAddressThree: any;
  permanentAddressFour: any;
  permanentAddressFive: any;
  permanentAddressSix: any;
  permanentAddressSeven: any;
  permanentAddressEight: any;

  sameAsCurrent: boolean = false;
  sameAddressDisabled: boolean = false;
  // current address inputs
  currentAddress: any;
  currentAddressTwo: any;
  currentAddressThree: any;
  currentAddressFour: any;
  currentAddressFive: any;
  currentAddressSix: any;
  currentAddressSeven: any;
  currentAddressEight: any;

  updatePermanentAddress() {
    if (this.sameAsCurrent) {
      this.currentAddress = this.permanentAddress;
      this.currentAddressTwo = this.permanentAddressTwo;
      this.currentAddressThree = this.permanentAddressThree;
      this.currentAddressFour = this.permanentAddressFour;
      this.currentAddressFive = this.permanentAddressFive;
      this.currentAddressSix = this.permanentAddressSix;
      this.currentAddressSeven = this.permanentAddressSeven;
      this.currentAddressEight = this.permanentAddressEight;
      this.selectAddressPhoneTwo = this.selectAddressPhone;

      this.sameAddressDisabled = true;
    } else {
      this.currentAddress = '';
      this.currentAddressTwo = '';
      this.currentAddressThree = '';
      this.currentAddressFour = null;
      this.currentAddressFive = '';
      this.currentAddressSix = null;
      this.currentAddressSeven = '';
      this.currentAddressEight = null;
      this.selectAddressPhoneTwo = null;
      this.sameAddressDisabled = false;
    }
  }

  back() {
    this.Location.back();
  }
  uploadResume = false;
  uploadDREsume() {
    this.uploadResume = !this.uploadResume;
  }

  doMathCalc() {
    // console.log(this.workinfoData.currency*=12, '$$$$$$$$$$$$$$$$$$$$$$$');
  }
  // showMathValue() {
  //   var mine = (this.workinfoData.totalSalaryAnnumm *= 12);
  //   console.log(mine, "'$$$$$$$$$$$$$$$$$$$$$$$'");
  // }
  showDoMathValue: any;
  onKeyUPP(event) {
    this.showDoMathValue = this.workinfoData.currency * 12;

    // var rrr = (this.workinfoData.currency *= 12);
    // console.log(rrr, '11111111111111111111');
    // rrr = this.showDoMathValue;
  }
  showTotalCtc: any;
  totalCTccC(event) {
    var num1 = this.workinfoData.totalSalaryAnnumm;
    var num2 = this.workinfoData.totalVariPayAnnum;
    this.showTotalCtc = this.showDoMathValue + num2;
  }
  onSelectt(event) {
    console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemovee(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  addSkill() {
    let obj = {
      skillCategory: null,
      skills: 'null',
      skill_list: [],
    };

    this.longdesciptionInfoData.jobskill.push(obj);
  }

  deleteSkill(i) {
    this.longdesciptionInfoData.jobskill.splice(i, 1);
  }






  filesss: File[] = [];

	onSelecttt(event) {
		console.log(event);
		this.files.push(...event.addedFiles);
	}

	onRemoveee(event) {
		console.log(event);
		this.files.splice(this.files.indexOf(event), 1);
	}





  filesDocs: File[] = [];
  onSelectDoc(event) {
    console.log(event);
    this.filesDocs.push(...event.addedFiles);
  }
  filesDocs1: File[] = [];
  onSelectDocOne(event) {
    console.log(event);
    this.filesDocs1.push(...event.addedFiles);
  }
  filesDocs2: File[] = [];
  onSelectDocTwo(event) {
    console.log(event);
    this.filesDocs2.push(...event.addedFiles);
  }
  filesDocs3: File[] = [];
  onSelectDocThree(event) {
    console.log(event);
    this.filesDocs3.push(...event.addedFiles);
  }
  filesDocs4: File[] = [];
  onSelectDocFour(event) {
    console.log(event);
    this.filesDocs4.push(...event.addedFiles);
  }

  onRemoveDoc(event) {
    console.log(event);
    this.filesDocs.splice(this.filesDocs.indexOf(event), 1);
  }
  onRemoveDocone(event) {
    console.log(event);
    this.filesDocs1.splice(this.filesDocs1.indexOf(event), 1);
  }
  onRemoveDoctwo(event) {
    console.log(event);
    this.filesDocs2.splice(this.filesDocs2.indexOf(event), 1);
  }
  onRemoveDocthree(event) {
    console.log(event);
    this.filesDocs3.splice(this.filesDocs3.indexOf(event), 1);
  }
  onRemoveDocfour(event) {
    console.log(event);
    this.filesDocs4.splice(this.filesDocs4.indexOf(event), 1);
  }
  sideNavClick(data) {
    if (data == 'personalDetails') {
      if (this.work_SideButton) {
        this.showGeneralInfo = true;
        this.showWorkHistory = false;
        this.showEducationalInfo = false;
        this.showGeneralInfooo = false;
        this.showDeclerationSs = false;
        this.showDocumentssS = false;
        this.showfamilyInfo = false;
        this.sideButtonEnable = true;
        this.workSideButton = false;
        this.educationSideButton = false;
        this.generalSideButton = false;
        this.declareSideButton = false;
        this.docSideButton = false;
        this.familySideButton = false;
        this.work_SideButton = false;
        this.education_SideButton = true;
        this.family_SideButton = true;
      }
    }
    if (data == 'workHistory') {
      if (this.education_SideButton) {
        this.showGeneralInfo = false;
        this.showWorkHistory = true;
        this.showEducationalInfo = false;
        this.showGeneralInfooo = false;
        this.showDeclerationSs = false;
        this.showDocumentssS = false;
        this.showfamilyInfo = false;
        this.sideButtonEnable = false;
        this.workSideButton = true;
        this.educationSideButton = false;
        this.generalSideButton = false;
        this.declareSideButton = false;
        this.docSideButton = false;
        this.familySideButton = false;
        this.education_SideButton = false;
        this.work_SideButton = true;
        this.family_SideButton = true;
      }
    }
    if (data == 'education') {
      if (this.family_SideButton) {
        this.showGeneralInfo = false;
        this.showWorkHistory = false;
        this.showEducationalInfo = true;
        this.showGeneralInfooo = false;
        this.showDeclerationSs = false;
        this.showDocumentssS = false;
        this.showfamilyInfo = false;
        this.sideButtonEnable = false;
        this.workSideButton = false;
        this.family_SideButton = false;
        this.educationSideButton = true;
        this.generalSideButton = false;
        this.declareSideButton = false;
        this.docSideButton = false;
        this.familySideButton = false;
        this.General_SideButton = true;
        this.declare_SideButton = true;

      }
    }
    if (data == 'generalInfo') {
      if (this.General_SideButton) {
        this.showGeneralInfo = false;
        this.showWorkHistory = false;
        this.showEducationalInfo = false;
        this.showGeneralInfooo = true;
        this.showDeclerationSs = false;
        this.showDocumentssS = false;
        this.showfamilyInfo = false;
        this.sideButtonEnable = false;
        this.workSideButton = false;
        this.family_SideButton = true;
        this.educationSideButton = false;
        this.generalSideButton = true;
        this.declareSideButton = false;
        this.docSideButton = false;
        this.familySideButton = false;
        this.General_SideButton = false;
        this.work_SideButton = true;
        this.doc_SideButton = true
        this.declare_SideButton = true;
      }
    }
    if (data == 'decleration') {
      if (this.declare_SideButton) {
        this.showGeneralInfo = false;
        this.showWorkHistory = false;
        this.showEducationalInfo = false;
        this.showGeneralInfooo = false;
        this.showDeclerationSs = true;
        this.showDocumentssS = false;
        this.showfamilyInfo = false;
        this.sideButtonEnable = false;
        this.workSideButton = false;
        this.family_SideButton = true;
        this.educationSideButton = false;
        this.generalSideButton = false;
        this.declareSideButton = true;
        this.docSideButton = false;
        this.familySideButton = false;
        this.General_SideButton = true;
        this.declare_SideButton = false;
        this.work_SideButton = true;
        this.doc_SideButton = true;
      }
    }
    if (data == 'docs') {
      if (this.doc_SideButton) {
        this.showGeneralInfo = false;
        this.showWorkHistory = false;
        this.showEducationalInfo = false;
        this.showGeneralInfooo = false;
        this.showDeclerationSs = false;
        this.showDocumentssS = true;
        this.showfamilyInfo = false;
        this.sideButtonEnable = false;
        this.workSideButton = false;
        this.family_SideButton = true;
        this.educationSideButton = false;
        this.generalSideButton = false;
        this.declareSideButton = false;
        this.docSideButton = true;
        this.familySideButton = false;
        this.General_SideButton = true;
        this.declare_SideButton = true;
        this.work_SideButton = true;
        this.doc_SideButton = false;


      }
    }
    if (data == 'familyInfo') {
      if (this.other_SideButton) {
        this.showGeneralInfo = false;
        this.showWorkHistory = false;
        this.showEducationalInfo = false;
        this.showGeneralInfooo = false;
        this.showDeclerationSs = false;
        this.showDocumentssS = false;
        this.showfamilyInfo = true;
        this.sideButtonEnable = false;
        this.workSideButton = false;
        this.family_SideButton = true;
        this.educationSideButton = false;
        this.generalSideButton = false;
        this.declareSideButton = false;
        this.docSideButton = false;
        this.familySideButton = true;
        this.General_SideButton = true;
        this.declare_SideButton = true;
        this.work_SideButton = false;
        this.doc_SideButton = true
      }
    }
  }
  visaVisable = true;

  visaVisibleYes(){
    this.visaVisable = true;
  }
  visaVisibleNo(){
    this.visaVisable = false;
  }


  }

