import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  NgForm,
} from '@angular/forms';
import { IJPService } from 'src/app/services/IJP.service';

@Component({
  selector: 'app-internal-job-posting',
  templateUrl: './internal-job-posting.component.html',
  styleUrls: ['./internal-job-posting.component.scss'],
})
export class InternalJobPostingComponent {
[x: string]: any;
  activeStepIndex: number = 0;
  id:any;
  // brandId:any;
  couin:any;
  steps: any;
  stepChange: any;
  employmentExp:any;
  roleDuration:any;
  hiringTeamMember:any;
  hiringStage:any;
  interviewTemplate:any;
  cutOffDate:any;
  typeOfInterview:any;
  applicantApproval:any;
  eligibleDepartments: any;
  eligibleBrandss:any;
  eligibleCountryyyy:any;
  eligibleRolesss:any;
  eligibleCampussss:any;
  hiringTeamName:any;
  campusInfo:any;
  ijpBasicInformationId: number = 0;
  brandId: string = '';  // Ensure it's a string
  countryId: string = '';  // Ensure it's a string
  campusId: string = '';  // Ensure it's a string
  departmentId: string = '';  // Ensure it's a string
  roleId: string = '';  // Ensure it's a string
  // activeStepIndex: number = 0;

  getGettargetAudience($event: any) {
    throw new Error('Method not implemented.')
  }
// eligibleBrandSelection: any;

  constructor( public fb: FormBuilder,private IJPService: IJPService){
    // this.brandId = '';  // Replace with appropriate default or initial value
    // this.countryId = '';  // Replace with appropriate default or initial value
    // this.campusId = '';  // Replace with appropriate default or initial value
    // this.departmentId = '';  // Replace with appropriate default or initial value
    // this.roleId = '';  //
  }

  goToStep(index: number) {
    if (index >= 0 && index < this.steps.length) {
      this.activeStepIndex = index;
      this.stepChange.emit(this.activeStepIndex);
    }
  }
  ngOnInit(): void {
    console.log('dmwamawdvwad');
    // this.GetEligibleDepartment();
    // this.GetEligibleRole();
    this.GetEligibleBrand(this.id);
    this.GetMemberName();

    // this.GetEligibleCountry(this.brandId);

  }

  get activeStep(): string {
    return [
      'Step 1 - Target Audience',
      'Step 2 - Program Approvers',
      // 'Step 3 - Selection Process',
    ][this.activeStepIndex];
  }

  isStepCompleted(index: number): boolean {
    return index < this.activeStepIndex;
  }

  @ViewChild('targetAudienceForm, progrmaApproversForm, selectionProcessForm', {
    static: false,
  })
  targetAudienceForm!: NgForm;
  progrmaApproversForm!: NgForm;
  selectionProcessForm!: NgForm;

  nextStep(step: string) {
    // if (
    //   step === 'first' &&
    //   this.targetAudienceForm &&
    //   this.targetAudienceForm.form &&
    //   this.targetAudienceForm.form.valid
    // ) {
    //   this.activeStepIndex++;
    //   this.targetAudienceForm.form.markAllAsTouched();
    // } else if (
    //   step === 'second' &&
    //   this.progrmaApproversForm &&
    //   this.progrmaApproversForm.form &&
    //   this.progrmaApproversForm.form.valid
    // ) {
    //   this.activeStepIndex++;
    //   this.progrmaApproversForm.form.markAllAsTouched();
    // } else if (
    //   step === 'third' &&
    //   this.selectionProcessForm &&
    //   this.selectionProcessForm.form &&
    //   this.selectionProcessForm.form.valid
    // ) {
    //   this.activeStepIndex++;
    //   this.selectionProcessForm.form.markAllAsTouched();
    // }
    if (step === 'first') {
      this.postIJPTargetAudience();
      this.activeStepIndex++;
    } else if (step === 'second') {
      this.PostIJPJobApprovers();
      this.activeStepIndex++;
    } else if (step === 'third') {
    }
  }

  back() {
    if (this.activeStepIndex > 0) {
      this.activeStepIndex--;
    }
  }
  removePill(pillElement: HTMLElement) {
    pillElement.remove();
  }

  // updateTargetAudienceForm() {}
  // updateProgrmaApproversForm() {}
  // updateSelectionProcessForm() {}

  eligibleBrandSelection: any;
   eligibleBrandds = [
    { id: 1, name: 'Vilnius' },
    { id: 2, name: 'Kaunas' },
    { id: 3, name: 'Kaunas' },
    { id: 4, name: 'Kaunas' },
    { id: 5, name: 'Kaunas' },
    { id: 6, name: 'Kaunas' },
    { id: 7, name: 'Kaunas' },
    { id: 8, name: 'Kaunas' },
    { id: 9, name: 'Kaunas' },
    { id: 10, name: 'Kaunas' },
    { id: 11, name: 'Kaunas' },
    { id: 12, name: 'Kaunas' },
    { id: 13, name: 'Kaunas' },
    { id: 14, name: 'Kaunas' },
    { id: 15, name: 'Kaunas' },
    { id: 16, name: 'Kaunas' },
    { id: 17, name: 'Kaunas' },
    { id: 18, name: 'Kaunas' },
    { id: 19, name: 'Kaunas' },
    { id: 20, name: 'Kaunas' },
    { id: 21, name: 'Kaunas' },
    { id: 22, name: 'Kaunas' },
    { id: 23, name: 'Kaunas' },
    { id: 24, name: 'Kaunas' },
    { id: 25, name: 'Kaunas' },
    { id: 26, name: 'Kaunas' },
    { id: 27, name: 'Kaunas' },
    { id: 28, name: 'Kaunas' },
    { id: 29, name: 'Kaunas' },
  ];

  eligibleCountrySelection: any = null;


  eligibleCampusSelection: any;




  updateTargetAudienceForm() {
  }
  updateProgrmaApproversForm() {
  }
  updateSelectionProcessForm() {
  }

  approverTableArray: any[]=[]

  approverTable: any[] = [
    {
      level: '1',
      country: 'Singapore',
      designation: 'Regional / Country Director',
      MemberName: 'Brandon Herwitz',
      MailID: 'chance324@mail.com',
    },
    {
      level: '2',
      country: 'Singapore',
      designation: 'Regional HR Team',
      MemberName: 'Brandon Herwitz',
      MailID: 'chance324@mail.com',
    },
  ];
  selectionTable: any[] = [
    {
      level: '1',
      name: 'Lincoln Westervelt',
      stage: 'Screening',
      template: 'Template 1',
      cutOffDate: 'Aug 20, 2023',
      interviewType: 'Interview Type',
      groupDiscussion: '',
    },
    {
      level: '1',
      name: 'Lincoln Westervelt',
      stage: 'Screening',
      template: 'Template 1',
      cutOffDate: 'Aug 20, 2023',
      interviewType: 'Interview Type',
      groupDiscussion: '',
    },
    {
      level: '1',
      name: 'Lincoln Westervelt',
      stage: 'Screening',
      template: 'Template 1',
      cutOffDate: 'Aug 20, 2023',
      interviewType: 'Interview Type',
      groupDiscussion: '',
    },
  ];

  visible: boolean = false;

  showDialog() {
    this.visible = true;
  }

  //Target Audience
  eligibleDepartment = [];
  // GetEligibleDepartment() {
  //   this.IJPService.GetEligibleDepartment().subscribe((res: any) => {
  //     this.eligibleDepartment = res;
  //     console.log('GetEligibleDepartment', res);
  //   });
  // }

  eligibleRole = [];
  // GetEligibleRole() {
  //   this.IJPService.GetEligibleRole().subscribe((res: any) => {
  //     this.eligibleRole = res;
  //   });
  // }

  showSelectedNames: any[] = [];

  changeBrandd(event: any) {
    const selectedNames = this.eligibleBrandSelection.map((id: any) => {
      const selectedBrand = this.eligibleBrands.find((brand:any) => brand.id === id);
      return selectedBrand ? selectedBrand : '';
    });
    this.showSelectedNames = selectedNames;
    // console.log(event);
    // setTimeout(() => {
    //   event = [];
    //   this.eligibleBrand = event;
    //   console.log(event);
    // }, 2000);
    this.updateDisplayedItems();
  }
  deleteSingleItem(i: any) {
    this.displayedItems.splice(i, 1);
  }

  remainingCount: number = 0;
  displayedItems: any[] = [];
  updateDisplayedItems(): void {
    this.displayedItems = this.showSelectedNames.slice(0, 5);
    this.remainingCount = this.showSelectedNames.length - 5;
  }

  changeCountry(event: any) {}
  eligibleBrands=[]
  GetEligibleBrand(id:any){
    this.IJPService.GetEligibleBrand(id).subscribe((res:any)=>{
      this.eligibleBrands=res.data
      console.log("GetEligibleBrand",res);
      console.log("id",id);
    })

  }

  // countryId:any= []
  eligibleCampus:any=[]
  eligibleCountry:any=[]

  GetEligibleCampus(brid:any,couin:any){

  }

  GetCountries(eligibleBrandss:any){
    this.eligibleCountryyyy=[]
    this.IJPService.GetEligibleCountry(eligibleBrandss).subscribe((res:any)=>{
    this.eligibleCountry=res.data
    })
  }

  GetCampusss(eligibleCountryyyy:any){
    this.IJPService.GetEligibleCampus(this.eligibleBrandss,eligibleCountryyyy).subscribe((res:any)=>{
      this.campusInfo=res
    })
  }

  postIJPTargetAudience() {
    const payloadOfTarget = {
      "id": 0,
      "ijpBasicInformationId": this.ijpBasicInformationId > 0 ? this.ijpBasicInformationId : 0,
      "brandId": this.eligibleBrandss.toString(),
      "countryId": this.eligibleCountryyyy.toString(),
      "campusId": this.eligibleCampussss.toString(),
      "departmentId": this.eligibleDepartments.toString(),
      "roleId": this.eligibleRolesss.toString(),
      "status": true
    };
    console.log("payloadOfTarget",payloadOfTarget);


    this.IJPService.PostIJPTargetAudience(payloadOfTarget).subscribe((res: any) => {
      this.ijpBasicInformationId = res.ijpBasicInformationId;
    });
  }

  //Program Approvers
  // memberName:[]=[]
  // GetMemberName(){
  //   this.IJPService.GetMemberName().subscribe((res)=>{
  //     this.memberName=res
  //     console.log("GetMemberName",res);
  //   })
  //   this.memberName = this.memberName.filter(
  //     (job:any) => !this.selectedJobPostIds.includes(job.userId)
  //   );
  // }

  memberName: any[] = [];
  selectedJobPostIds: any[] = [];
  ijpJobApproverStatus: any;

GetMemberName() {
  this.IJPService.GetMemberName().subscribe((res: any) => {
    this.memberName = res;
    console.log("GetMemberName", res);

    this.memberName = this.memberName.filter(
      (job: any) => !this.selectedJobPostIds.includes(job.userId)
    );
  });
}

selectMember(userId: any) {
  if (!this.selectedJobPostIds.includes(userId)) {
    this.selectedJobPostIds.push(userId);
  } else {
    this.selectedJobPostIds = this.selectedJobPostIds.filter(id => id !== userId);
  }
  console.log("Selected Job Post IDs", this.selectedJobPostIds);
}

designation:any;
role:any;
level:any;
PostIJPJobApprovers() {
  let approverTables: any[] = [];

  for (let i = 0; i < this.approverTable.length; i++) {

    var obj = {
      approveMember: this.approverTable[i].hiringTeamName.toString(),
      role: this.approverTable[i].designation.toString(),
      levels: this.approverTable[i].level.toString()
    };

    approverTables.push(obj);

  }
  var approver={
    "ijpBasicInformationId":sessionStorage.getItem(''),
    "ijpReportingmanagerId":0,
    "reportingmanager" :this.applicantApproval,
    "iJPJobApprover":approverTables,
    "levels":this.level,
  };



  console.log(approver, "========");
  console.log(this.approverTable,"++++++++++++");

  this.IJPService.PostIJPJobApprovers(approver).subscribe((res: any) => {
    this.ijpBasicInformationId = res.ijpBasicInformationId;
  });
}

}



