<main class="basic-info-main">
  <div class="app_details_tabs_content tabss__sectioNN__linee mb-2 z9999 stickyy-partt">
    <ul class="stepbox-list d-flex flex-row w-100 ps-0">
      <li
        [ngClass]="{ active: tabb === 'tabb1' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Basic Information
      </li>
      <li
        [ngClass]="{ active: tabb === 'tabb3' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Work Experience
      </li>
      <li
        [ngClass]="{ active: tabb === 'tabb2' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Educational Information
      </li>
      <!-- <li [ngClass]="{ active: tabb === 'tabb4' }"
        class="stepbox-list__item position-relative fw500 text-center pointer">Family Information</li> -->
      <li
        [ngClass]="{ active: tabb === 'tabb5' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Immigration
      </li>
      <!-- <li [ngClass]="{ active: tabb === 'tabb6' }"
        class="stepbox-list__item position-relative fw500 text-center pointer">Declaration</li> -->
      <li
        [ngClass]="{ active: tabb === 'tabb7' }"
        class="stepbox-list__item position-relative fw500 text-center pointer"
      >
        Documents
      </li>
    </ul>
  </div>
  <form #profileBasicInfoForm="ngForm">
    <div class="row app_details_tabs_content">
      <ng-container *ngIf="!enableDoc">
        <div class="col-md-3 mt-4 newUpload_pdf">
          <label class="mb-2 figma__label mb-1">
            Upload Resume <span class="errorRequired">*</span>
          </label>
          <!-- [disabled]="NewnewResumeFiles.length == 1" -->
          <ngx-dropzone
            class=""
            [accept]="'application/pdf'"
            [ngClass]="
              !NewnewResumeFiles.length ? 'emptyPDFClass' : 'FillPDFClass'
            "
            (change)="newOnNewSelect($event)"
            [multiple]="false"
          >
            <ngx-dropzone-label>
              <div class="d-md-flex">
                <button type="button" class="bulk__UploadResume">
                  <img src="./assets/img/upload-symbol.svg" alt="upload" />
                </button>
                <h1 class="fw600 fs14 figma_blue_color pl-3 mt-2">
                  Upload Resume
                </h1>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-preview
              *ngFor="let f of NewnewResumeFiles"
              [removable]="true"
              (removed)="newOnnewRemove(f)"
            >
              <ngx-dropzone-label class="d-flex ml-4">
                <img src="./assets/img/red-pdf.svg" alt="red pdf" />
                <h1 class="fw600 fs14 pl-3">
                  {{ f.name | slice : 0 : 10 }}
                </h1>
              </ngx-dropzone-label>
            </ngx-dropzone-preview>
          </ngx-dropzone>
          <div *ngIf="uploadResumeError">
            <span class="errorMessage"> Please upload resume </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="enableDoc">
        <div class="col-md-12 newUpload_pdf mt-3">
          <label class="mb-2 figma__label mb-1"> Resume </label>
          <p class="relative" style="width: max-content !important">
            <a
              style="color: #764fdb !important"
              href="{{ docPathel }}"
              target="_blank"
              >{{ docNameel }}
            </a>
            <img
              class="absolute"
              style="width: 9px; cursor: pointer; right: -16px; top: -5px"
              src="../../assets/img/blue-close-icon.svg"
              alt="blue close"
              (click)="closeEl()"
            />
          </p>
        </div>
      </ng-container>

      <div class="col-md-12 mt-4">
        <label class="mb-2 figma__label">
          Add Applicant to ? <span class="errorRequired">*</span>
        </label>
        <div class="gs-grouped-fileds pt-0 d-flex mt-2">
          <div class="priority_checks align-items-center d-flex">
            <input
              class="pointer accent-color"
              type="radio"
              name="jobSelectType"
              value="Archive"
              #jobSelectType="ngModel"
              [(ngModel)]="nnewNewBasicInfoo.jobSelectType"
              (change)="onItemChange($event.target.value)"
              required
            />
            <label
              class="ml-2 figma__label"
              style="margin-bottom: 0 !important"
            >
              Data bank
            </label>
            <!-- style="font-size: 12px;" -->
            <!-- <label style="font-size: 12px"
                [ngStyle]="{color:nnewNewBasicInfoo.jobSelectType == 'Archive'? 'black': 'gray'}">Archive</label> -->
          </div>
          <div class="priority_checks align-items-center d-flex c1p">
            <input
              class="pointer accent-color"
              type="radio"
              name="jobSelectType"
              value="Active"
              #jobSelectType="ngModel"
              [(ngModel)]="nnewNewBasicInfoo.jobSelectType"
              (change)="onItemChange($event.target.value)"
              required
            />
            <label
              class="ml-2 figma__label"
              style="margin-bottom: 0 !important"
            >
              An Active Job
            </label>
            <!-- <label label style="font-size: 12px"
                [ngStyle]="{color:nnewNewBasicInfoo.jobSelectType == 'Active'? 'black': 'gray'}">Active</label> -->
          </div>
        </div>
        <div
          *ngIf="
            (jobSelectType?.touched || profileBasicInfoForm.submitted) &&
            jobSelectType.errors?.required
          "
        >
          <p class="errorMessage mt-2">Please select job type</p>
        </div>
      </div>
      <br />
      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label">
          Job Title <span class="errorRequired">*</span>
        </label>
        <!-- *ngIf="archiveSelected" -->
        <!--  *ngIf="!archiveSelected" -->
        <ng-container *ngIf="archiveSelected">
          <ng-select
            class=""
            [items]="joblistarray"
            bindLabel="jobTitle"
            (change)="onCampus($event)"
            bindValue="jobId"
            name="newAddAppJobTitle"
            #newAddAppJobTitle="ngModel"
            placeholder="Select job title"
            [(ngModel)]="nnewNewBasicInfoo.newAppProfileJobTitleee"
            required
          >
          </ng-select>
          <div
            *ngIf="
              (newAddAppJobTitle?.touched || profileBasicInfoForm.submitted) &&
              newAddAppJobTitle.errors?.required
            "
          >
            <span class="errorMessage"> Please select job title </span>
          </div>
        </ng-container>
        <ng-container *ngIf="!archiveSelected">
          <ng-select
            class=""
            [items]="joblistarray"
            bindLabel="job_Name"
            bindValue="masterJobId"
            name="newAddAppJobTitle"
            (change)="normalJob($event)"
            #newAddAppJobTitle="ngModel"
            placeholder="Select job title"
            [(ngModel)]="nnewNewBasicInfoo.newAppProfileJobTitleee"
            required
          >
          </ng-select>
          <div
            *ngIf="
              (newAddAppJobTitle?.touched || profileBasicInfoForm.submitted) &&
              newAddAppJobTitle.errors?.required
            "
          >
            <span class="errorMessage"> Please select job title </span>
          </div>
        </ng-container>
      </div>

      <div class="col-md-6" *ngIf="!campusActiveSelected"></div>
      <div class="col-md-6 figma__ng__select mt-3" *ngIf="campusActiveSelected">
        <label class="mb-2 figma__label">
          Campus <span class="errorRequired">*</span>
        </label>
        <ng-select
          class=""
          [items]="campusRes"
          bindLabel="campusName"
          bindValue="campusId"
          name="newAppProfileCampus"
          #newAppProfileCampus="ngModel"
          placeholder="Select campus"
          [(ngModel)]="nnewNewBasicInfoo.newAppProfileJobCampus"
          [multiple]="true"
          required
        >
        </ng-select>
        <div
          *ngIf="
            (newAppProfileCampus?.touched || profileBasicInfoForm.submitted) &&
            newAppProfileCampus.errors?.required
          "
        >
          <span class="errorMessage"> Please select campus </span>
        </div>
      </div>
      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label">
          First Name <span class="errorRequired">*</span>
        </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          (keydown)="noSpaceBar($event)"
          [(ngModel)]="nnewNewBasicInfoo.profileFirstName"
          name="profileFirstNamee"
          #profileFirstNamee="ngModel"
          placeholder="Enter first name"
          required
          (keypress)="restrictNumbers($event)"
        />
        <div
          *ngIf="
            (profileFirstNamee?.touched || profileBasicInfoForm.submitted) &&
            profileFirstNamee.errors?.required
          "
        >
          <span class="errorMessage"> Please enter first name </span>
        </div>
      </div>
      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label">
          Last Name <span class="errorRequired">*</span>
        </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          (keydown)="noSpaceBar($event)"
          [(ngModel)]="nnewNewBasicInfoo.profileLasstName"
          name="profileLasstNamee"
          (keypress)="restrictNumbers($event)"
          #profileLasstNamee="ngModel"
          placeholder="Enter last name"
          required
        />
        <div
          *ngIf="
            (profileLasstNamee?.touched || profileBasicInfoForm.submitted) &&
            profileLasstNamee.errors?.required
          "
        >
          <span class="errorMessage"> Please enter last name </span>
        </div>
      </div>
      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label">
          Email ID <span class="errorRequired">*</span>
        </label>
        <input
          type="email"
          class="col-md-12 figma__Inputs h_42"
          (keydown)="noSpaceBar($event)"
          [(ngModel)]="nnewNewBasicInfoo.profileEmailID"
          name="profileEmailIDdd"
          #profileEmailIDdd="ngModel"
          placeholder="Enter email id"
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
          required
          (keyup)="emailValidate($event)"
        />
        <div
          *ngIf="
            (profileEmailIDdd?.touched || profileBasicInfoForm.submitted) &&
            profileEmailIDdd.errors?.required
          "
        >
          <span class="errorMessage"> Please enter email id </span>
        </div>
        <div
          class="errorMessage"
          *ngIf="profileBasicInfoForm.controls.profileEmailIDdd.errors?.pattern"
        >
          Invalid email format
        </div>
        <div *ngIf="emailValid">
          <span class="errorMessage"> MyGIIS ID or Email already exists. </span>
        </div>
      </div>
      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label"> Alternative Email ID </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          (keydown)="noSpaceBar($event)"
          [(ngModel)]="nnewNewBasicInfoo.profilealternateEmailIDD"
          name="profilealternateEmailIDDd"
          #profilealternateEmailIDDd="ngModel"
          placeholder="Enter alternative email id"
          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
        />
        <div
          class="errorMessage"
          *ngIf="
            profileBasicInfoForm.controls.profilealternateEmailIDDd.errors
              ?.pattern
          "
        >
          Invalid email format
        </div>
        <!-- <div *ngIf="(profilealternateEmailIDDd?.touched || profileBasicInfoForm.submitted) && profilealternateEmailIDDd.errors?.required">
              <span class="errorMessage">
                Please enter alternative email id
              </span>
            </div> -->
      </div>
      <div class="col-md-6 mt-3 new__Phoe__parttttttt figma__ng__select">
        <label class="mb-2 figma__label">
          Phone No <span class="errorRequired">*</span>
        </label>
        <div class="d-flex align-items-center">
          <ng-select
            style="width: 70px"
            class=""
            [(ngModel)]="nnewNewBasicInfoo.phoneNumbercode"
            #newAddAppphoneNumber="ngModel"
            [items]="Allcountry"
            bindLabel="countryCode"
            bindValue="countryCode"
            name="phoneCodeNewApp"
            placeholder="+91"
            (change)="disabledNumberInput('two')"
            required
          >
          </ng-select>
          <input
            onkeypress="return /[0-9]/i.test(event.key)"
            [(ngModel)]="nnewNewBasicInfoo.phoneNumber"
            #newAddAppphoneNumber12="ngModel"
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
            class="w-100 figma__Inputs"
            type="text"
            name="newAddAppphoneNumber12"
            placeholder="Enter phone number"
            (keyup)="ValidateApplicantPhoneNo($event)"
            required
            pattern="[111][0-9]{9}"
            style="
              border-radius: 0 8px 8px 0 !important;
              height: 42px !important;
            "
          />
          <!-- [disabled]="phoneNumberdisabledByCodee" -->
        </div>
        <!-- <div class="errorMessage" *ngIf="profileBasicInfoForm.controls.newAddAppphoneNumber12.errors?.pattern">
            Invalid phone number format</div> -->
        <!-- <div *ngIf="
                (newAddAppphoneNumber.touched ||
                  profileBasicInfoForm.submitted) &&
                newAddAppphoneNumber.errors?.required
              ">
            <span class="errorMessage">
              Please select code
            </span>
          </div> -->
        <div
          *ngIf="
            (newAddAppphoneNumber12.touched ||
              profileBasicInfoForm.submitted) &&
            newAddAppphoneNumber12.errors?.required
          "
        >
          <span class="errorMessage"> Please enter phone number </span>
        </div>
        <div *ngIf="phoneValid">
          <span class="errorMessage"> Phone number already exists. </span>
        </div>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-6 mt-3 figma_calender_input new-app-DOB">
        <label class="mb-2 figma__label">
          Date Of Birth <span class="errorRequired">*</span>
        </label>
        <div class="position-relative calender__ml">
          <p-calendar
            class=""
            name="profileeDOBBb"
            placeholder="Select date of birth"
            [maxDate]="minAgee"
            [(ngModel)]="nnewNewBasicInfoo.profileeeDOBB"
            #profileeDOBBb="ngModel"
            [showIcon]="true"
            [required]="true"
          ></p-calendar>
        </div>
        <div
          *ngIf="
            (profileeDOBBb?.touched || profileBasicInfoForm.submitted) &&
            profileeDOBBb.errors?.required
          "
        >
          <span class="errorMessage"> Please select date of birth </span>
        </div>
      </div>
      <!-- <div class="col-md-6 figma__ng__select mt-3">
      <label class="mb-2 figma__label"> Gender </label>
      <ng-select class="" [items]="newProfileGender" bindLabel="id" bindValue="name"
        [(ngModel)]="nnewNewBasicInfoo.newProfileGenderr" #gender="ngModel" name="gender"
        placeholder="Select gender">
      </ng-select>
    </div> -->
      <div
        class="col-md-6 priority__Radios mobile_fields_gap priority_radios_mains"
        style="margin-top: 20px"
      >
        <label
          class="form-label-d priority__label__heading new__input__label mb-2"
          >Gender <span class="errorRequired">*</span>
        </label>
        <div class="gs-grouped-fileds pt-0 d-flex">
          <div class="ui radio checkbox priority_checks">
            <input
              class="pointer"
              type="radio"
              name="NewProfileGenderr"
              checked="true"
              value="Male"
              #NewProfileGenderr="ngModel"
              [(ngModel)]="nnewNewBasicInfoo.NewProfileGenderr"
              required
            />
            <!-- style="font-size: 12px;" -->
            <label
              style="font-size: 12px"
              [ngStyle]="{
                color:
                  nnewNewBasicInfoo.NewProfileGenderr == 'profileMaale'
                    ? 'black'
                    : 'gray'
              }"
              >Male</label
            >
          </div>
          <div class="ui radio checkbox priority_checks c1p">
            <input
              class="pointer"
              type="radio"
              name="NewProfileGenderr"
              value="Female"
              #NewProfileGenderr="ngModel"
              [(ngModel)]="nnewNewBasicInfoo.NewProfileGenderr"
              required
            />
            <label
              label
              style="font-size: 12px"
              [ngStyle]="{
                color:
                  nnewNewBasicInfoo.NewProfileGenderr == 'profileFemale'
                    ? 'black'
                    : 'gray'
              }"
              >Female</label
            >
          </div>
          <div class="ui radio checkbox priority_checks c1p">
            <input
              class="pointer"
              type="radio"
              name="NewProfileGenderr"
              value="Others"
              #NewProfileGenderr="ngModel"
              [(ngModel)]="nnewNewBasicInfoo.NewProfileGenderr"
              required
            />
            <label
              label
              style="font-size: 12px"
              [ngStyle]="{
                color:
                  nnewNewBasicInfoo.NewProfileGenderr == 'profileOTherss'
                    ? 'black'
                    : 'gray'
              }"
              >Others</label
            >
          </div>
        </div>
        <div
          *ngIf="
            (NewProfileGenderr?.touched || profileBasicInfoForm.submitted) &&
            NewProfileGenderr.errors?.required
          "
        >
          <span class="errorMessage"> Please select gender </span>
        </div>
      </div>
      <div class="newProfile_border_bottom col-md-12 my-3"></div>

      <div class="col-md-6 figma__ng__select">
        <label class="mb-2 figma__label"> Nationality </label>
        <ng-select
          class=""
          (change)="getnationalityid(nnewNewBasicInfoo.newProfileNationality)"
          [items]="nationalitycountry"
          bindLabel="nationality_Name"
          bindValue="nationality_Id"
          name="newProfileNationalityyy"
          #newProfileNationalityyy="ngModel"
          placeholder="Select nationality"
          [(ngModel)]="nnewNewBasicInfoo.newProfileNationality"
        >
        </ng-select>
      </div>

      <!-- <div class="col-md-6 figma__ng__select">
          <label class="mb-2 figma__label"> Permanent Residence of (Country) </label>
          <ng-select class="" [items]="newProfilepermanentResidence" bindLabel="name" bindValue="id"
            name="newProfilepermanentResidenceee" #newProfilepermanentResidenceee="ngModel"
            placeholder="Select country" [(ngModel)]="nnewNewBasicInfoo.newProfilepermanentResidencee">
          </ng-select>
        </div> -->

      <div class="col-md-6 figma__ng__select">
        <label class="mb-2 figma__label"> Nationality Id </label>
        <ng-select
          class=""
          (change)="getValueforappend($event)"
          [items]="nationalitydocarr"
          bindLabel="name"
          bindValue="name"
          name="newProfileNationalityidd"
          #newProfileNationalityidd="ngModel"
          placeholder="Select nationality id"
          [(ngModel)]="nnewNewBasicInfoo.newProfileNationalityyid"
        >
        </ng-select>
      </div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> Id Number </label>
        <!-- <ng-select class="" [items]="newProfilenewProfileidNumberrr" bindLabel="name" bindValue="id"
            name="newProfilenewProfileidNumberr" #newProfilenewPProfileidNumberr="ngModel"
            placeholder="Select id number" [(ngModel)]="nnewNewBasicInfoo.newProfileiDDDNumber">
          </ng-select> -->

        <!-- *ngIf="mandetoryStatus==false" -->
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [disabled]="mandetoryStatus == false"
          [(ngModel)]="nnewNewBasicInfoo.newProfilePassportIDDDNumber"
          name="newProfilePassportIDDDNumberr"
          #newProfilePassportIDDDNumberr="ngModel"
          placeholder="Enter id number"
          pattern="^[A-Za-z0-9]+$"
          required
        />
        <div
          class="errorMessage"
          *ngIf="
            profileBasicInfoForm.controls.newProfilePassportIDDDNumberr?.errors
              ?.pattern
          "
        >
          Please enter only characters and numbers
        </div>
        <!-- onkeypress="return /[0-9]/i.test(event.key)"
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"  -->
      </div>

      <!-- <div class="col-md-6"></div> -->

      <div class="d-flex col-md-6 mt-3 figma_calender_input">
        <div class="col-md-6 pl-0">
          <label class="mb-2 figma__label"> Issue Date </label>
          <div class="position-relative calender__ml">
            <p-calendar
              class=""
              name="profileeIssueDatee"
              placeholder="Select date"
              [disabled]="mandetoryStatus == false"
              (onSelect)="onDateSelect($event, 'immigration')"
              [(ngModel)]="nnewNewBasicInfoo.profileeIssueDateee"
              #profileeIssueDatee="ngModel"
              [showIcon]="true"
            ></p-calendar>
          </div>
        </div>

        <!--  -->
        <div class="col-md-6 pr-0">
          <label class="mb-2 figma__label"> Expiry Date </label>
          <div class="position-relative calender__ml">
            <p-calendar
              class=""
              name="profileeExpiryDatee"
              placeholder="Select date"
              [disabled]="mandetoryStatus == false"
              [(ngModel)]="nnewNewBasicInfoo.profileeExpiryDateeee"
              #profileeExpiryDatee="ngModel"
              [showIcon]="true"
            ></p-calendar>
          </div>
        </div>
        <!--  -->
      </div>
      <div class="col-md-6 mt-3 figma_calender_input"></div>

      <div class="newProfile_border_bottom col-md-12 my-3"></div>

      <div class="col-md-6 figma__ng__select">
        <label class="mb-2 figma__label"> Passport </label>
        <input
          [(ngModel)]="nnewNewBasicInfoo.Passport"
          #newApplicantPassport="ngModel"
          class="w-100 figma__Inputs"
          type="text"
          name="newApplicantPassport"
          placeholder="Enter passport"
          pattern="^[A-Za-z0-9]+$"
          required
        />
        <div
          class="errorMessage"
          *ngIf="
            profileBasicInfoForm.controls.newApplicantPassport?.errors?.pattern
          "
        >
          Please enter only characters and numbers
        </div>
        <!-- onkeypress="return /[0-9]/i.test(event.key)"
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';" -->
        <!-- <ng-select class="" [items]="NNationalityArrrayy" bindLabel="nationality_Name"
            bindValue="nationality_Id" name="newProfileNationalityidd" #newProfileNationalityidd="ngModel"
            placeholder="Select nationality id" [(ngModel)]="nnewNewBasicInfoo.newProfileNationalityyid">
          </ng-select> -->
      </div>
      <div class="col-md-6 d-flex">
        <div class="col-md-6 figma_calender_input pl-0">
          <label class="mb-2 figma__label"> Issue Date </label>
          <div class="position-relative calender__ml">
            <p-calendar
              (onSelect)="onDateSelect($event, 'visa')"
              class=""
              name="profileeIssueDaattee"
              placeholder="Select date"
              [(ngModel)]="nnewNewBasicInfoo.profileeIssueDaDateee"
              #profileeIssueDaattee="ngModel"
              [showIcon]="true"
            ></p-calendar>
          </div>
        </div>

        <div class="col-md-6 figma_calender_input pr-0">
          <label class="mb-2 figma__label"> Expiry Date </label>
          <div class="position-relative calender__ml">
            <p-calendar
              class=""
              name="profileeExpiryyDDatee"
              placeholder="Select date"
              [(ngModel)]="nnewNewBasicInfoo.profileeExpiryyDaDateee"
              #profileeExpiryyDDatee="ngModel"
              [showIcon]="true"
            ></p-calendar>
          </div>
        </div>
      </div>
      <div class="newProfile_border_bottom col-md-12 my-3"></div>

      <h1 class="fw600 fs18 figma_blue_color mt-1 mb-3 col-md-12">
        Current Address
      </h1>

      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label"> Address line 1 </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="nnewNewBasicInfoo.profileAddressLineOnee"
          name="profileAddressLineOne"
          #profileAddressLineOne="ngModel"
          placeholder="Enter address line 1"
        />
      </div>
      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label"> Address line 2 </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="nnewNewBasicInfoo.profileAddressLineTTwoo"
          name="profileAddressLineeTTTwoo"
          #profileAddressLineeTTTwoo="ngModel"
          placeholder="Enter address line 2"
        />
      </div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> Country </label>
        <ng-select
          class=""
          (change)="getCampusonCountry($event)"
          [items]="Allcountry"
          bindLabel="countryName"
          bindValue="countryId"
          name="newProfilenewProfileCoountry"
          #newProfilenewProfileCoountry="ngModel"
          placeholder="Select country"
          [(ngModel)]="nnewNewBasicInfoo.newProfileCCountryr"
        >
        </ng-select>
      </div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> State </label>
        <ng-select
          class=""
          [items]="stateList"
          bindLabel="name"
          bindValue="id"
          name="newProfilenewProfileiState"
          #newProfilenewProfileiState="ngModel"
          placeholder="Select state"
          [(ngModel)]="nnewNewBasicInfoo.newProfileCStatee"
        >
        </ng-select>
      </div>

      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label"> Zipcode </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="nnewNewBasicInfoo.profileAddressZipcode"
          name="profileAddressLineeZipcode"
          #profileAddressLineeZipcode="ngModel"
          placeholder="Enter zipcode"
          onkeypress="return /[0-9]/i.test(event.key)"
          oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
        />
      </div>

      <!-- <div class="newProfile_border_bottom col-md-12 my-3"></div> -->
      <h1 class="fw600 fs18 figma_blue_color mt-4 mb-2 col-md-12">
        Permanent Address
      </h1>
      <div class="d-flex my-3 col-md-12 align-items-center">
        <input
          type="checkbox"
          name="sameCurrentAddress"
          [(ngModel)]="newSameCurrentAddress"
          style="accent-color: #764fdb"
          (change)="onclickCurrentAddress()"
        />
        <!--   <input type="checkbox" (change)="updatePermanentAddress()" [(ngModel)]="sameAsCurrentAddress"
          name="SameAddress" /> -->
        <label class="figma__label ml-1" style="margin-bottom: 0 !important">
          Same as current address
        </label>
      </div>

      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label"> Address line 1 </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="nnewNewBasicInfoo.permanentprofileAddressLineOnee"
          name="permanentPprofileAddressLineOne"
          #permanentPprofileAddressLineOne="ngModel"
          placeholder="Enter address line 1"
          [disabled]="sameAddressDisabled"
          [ngClass]="!sameAddressDisabled ? 'firstClass' : 'MySecondClass'"
        />
      </div>
      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label"> Address line 2 </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="nnewNewBasicInfoo.permanentPprofileAddressLineTTwoo"
          name="permanentPprofileAddressLineeTTTwoo"
          #permanentPprofileAddressLineeTTTwoo="ngModel"
          placeholder="Enter address line 2"
          [disabled]="sameAddressDisabled"
          [ngClass]="!sameAddressDisabled ? 'firstClass' : 'MySecondClass'"
        />
      </div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> Country </label>
        <ng-select
          class=""
          (change)="getCampusonCountry($event)"
          [items]="Allcountry"
          bindLabel="countryName"
          bindValue="countryId"
          name="newProfilenewProfileCoountry"
          #newProfilenewProfileCoountry="ngModel"
          placeholder="Select country"
          [(ngModel)]="nnewNewBasicInfoo.newPermanentProfileCCountryr"
          [ngClass]="!sameAddressDisabled ? 'firstClass' : 'MySecondClass'"
        >
        </ng-select>
      </div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> State </label>
        <ng-select
          class=""
          [items]="stateList"
          bindLabel="stateName"
          bindValue="stateId"
          name="newProfilenewProfileiState"
          #newProfilenewProfileiState="ngModel"
          placeholder="Select state"
          [(ngModel)]="nnewNewBasicInfoo.PermanentnewProfileCStatee"
          [disabled]="sameAddressDisabled"
          [ngClass]="!sameAddressDisabled ? 'firstClass' : 'MySecondClass'"
        >
        </ng-select>
      </div>

      <div class="col-md-6 mt-3">
        <label class="mb-2 figma__label"> Zipcode </label>
        <input
          type="text"
          class="col-md-12 figma__Inputs h_42"
          [(ngModel)]="nnewNewBasicInfoo.permanentPprofileAddressZipcode"
          name="permanentPprofileAddressLineeZipcode"
          #permanentPprofileAddressLineeZipcode="ngModel"
          placeholder="Enter zipcode"
          [ngClass]="!sameAddressDisabled ? 'firstClass' : 'MySecondClass'"
          onkeypress="return /[0-9]/i.test(event.key)"
          oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
        />
      </div>
      <!-- <div class="d-flex my-3 col-md-12">
          <input type="checkbox" name="sameCurrentAddress" class="" style="accent-color: #764fdb;" />
          <label class="figma__label ml-1" style="margin-bottom: 0 !important;"> Same as current address
          </label>
        </div> -->

      <div class="newProfile_border_bottom col-md-12 my-3"></div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> Race/Ethnic Group </label>
        <ng-select
          class=""
          [items]="ehicalarray"
          bindLabel="ethicName"
          bindValue="ethicId"
          name="newProfileRaceee"
          #newProfileRaceee="ngModel"
          placeholder="Select an option"
          [(ngModel)]="nnewNewBasicInfoo.newProfileRacee"
        >
        </ng-select>
      </div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label"> Religion </label>
        <ng-select
          class=""
          [items]="regiionalarr"
          bindLabel="religionName"
          bindValue="religionId"
          name="newProfileReligionnn"
          #newProfileReligionnn="ngModel"
          placeholder="Select religion"
          [(ngModel)]="nnewNewBasicInfoo.newProfileReligionnnn"
        >
        </ng-select>
      </div>

      <div class="col-md-6 figma__ng__select mt-3">
        <label class="mb-2 figma__label">
          How did you got to know about us?
        </label>
        <ng-select
          class=""
          [items]="howdotodropdown"
          bindLabel="category"
          bindValue="category"
          name="newProfileHowKnoww"
          #newProfileHowKnoww="ngModel"
          placeholder="Select an option"
          [(ngModel)]="nnewNewBasicInfoo.newProfileHowKnowww"
        >
        </ng-select>
      </div>
      <div class="col-md-12 mt-4">
        <button
          type="button"
          class="figma_background_blue_btn float-right"
          style="padding: 12px 42px"
          (click)="saveAndnextt('profileDetails')"
        >
          Save & Next
        </button>
      </div>
    </div>
  </form>
</main>
