import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import * as moment from 'moment';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-hiring-team',
  templateUrl: './hiring-team.component.html',
  styleUrls: ['./hiring-team.component.scss'],
  providers: [MessageService]
})
export class HiringTeamComponent {
  hiringTeamTableArray: any[] = [
    // { id: "1", position: 'Manager', name: "John Doe", email: "john@gmail.com"},
    // { id: "2", position: 'Manager', name: "John Doe", email: "john@gmail.com"},
    // { id: "3", position: 'Manager', name: "John Doe", email: "john@gmail.com"},
    // { id: "4", position: 'Manager', name: "John Doe", email: "john@gmail.com"},
  ];

  selectedhiringTeamMember: any;
  hiringTeamMembersItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedhiringTeamStage: any;
  hiringTeamStageItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedhiringInterviwTemplate: any;
  hiringTeamInterviewTemplateItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  selectedhiringinterview: any;
  hiringTeamInterviewItems = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  toggleHiringTeamTable: boolean = false;

  hiringTeamForm: any;
  otherMembersForm: any;
  get f() {
    return this.hiringTeamForm.controls;
  }

  constructor(private fb: FormBuilder, private messageService: MessageService) {}

  ngOnInit() {
    this.hiringTeamForm = this.fb.group({
      hiringTeamMember: [null, Validators.required],
      hiringHiringStage: [null, Validators.required],
      hiringInterviewTemplate: [null, Validators.required],
      hiringTeamCutOffDate: [null, Validators.required],
      hiringInterviewType: [null, Validators.required],
    });
    this.otherMembersForm = this.fb.group({
      hiringTeamMemberTwo: [null, Validators.required],
      hiringHiringStageTwo: [null, Validators.required],
      hiringTeamCutOffDateTwo: [null, Validators.required],
    });
  }

  hiringAddToList() {
    let obj = {
      hiringTeamMember: this.selectedhiringTeamMember,
      hirngStage: this.selectedhiringTeamStage,
      interviewTemplate: this.selectedhiringInterviwTemplate,
      interviewType: this.selectedhiringinterview,
    };
    this.hiringTeamTableArray.push(obj);
  }
  displayModal: boolean = false;

  showModalDialog() {
    this.displayModal = true;
  }
  hiringTeamSubmitModal: boolean = false;
  toggleHiringTeamSubmitModal() {
    this.hiringTeamSubmitModal = true;
  }
  otherMembersTableArray: any[] = [];
  otherMembersFormData: any;

  otherMembersFormSubmit() {
    this.otherMembersFormData = this.otherMembersForm.value;
    console.log(this.otherMembersFormData);
  }
  surePostModalVisible : boolean = false;
  showSurePostDialog() {
    this.surePostModalVisible = true;
  }
  postScreenOne : boolean = true;
  postScreenTwo : boolean = false;
  postScreenYes(){
    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Your Job Has Been Posted' });
    setTimeout(() => {
    this.postScreenOne = false;
    this.postScreenTwo = true;
  }, 1500);
  }
  postScreenNo(){
    this.surePostModalVisible = false;
  }
}
