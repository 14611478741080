import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
(async () => {
  const response = await fetch('assets/config/config.json');
  const config = await response.json();
  let apiURL = config.apiURL;

  environment.apiUrl = apiURL;


  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
})();

