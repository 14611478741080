<section class="new_application">
  <p class="fs20 fw700 new_app">New Applicant Details</p>
  <form
    #newapplication="ngForm"
    (ngSubmit)="onSubmit(newapplication)"
    novalidate
    class=""
  >
    <div class="profile white-background mb-2">
      <p class="fs18 fw600 prof mb-4">Profile</p>
      <p class="fs18 fw600 personal_det mb-4">Personal details</p>

      <div class="row" style="display: flex; row-gap: 20px">
        <!-- Full Name Field -->
        <div class="col-md-5">
          <label for="fullName">Full Name</label>
          <input
            id="fullName"
            name="fullName"
            [(ngModel)]="formData.fullName"
            required
            pattern="^[a-zA-Z\s]+$"
            class="form-control"
            placeholder="Enter your full name"
          />
          <div
            *ngIf="
              newapplication.form.controls['fullName']?.invalid &&
              newapplication.form.controls['fullName']?.touched
            "
          >
            <span class="errorMessage">Please Enter Full Name.</span>
          </div>
        </div>

        <!-- Job Title Field -->
        <div class="col-md-5 figma-ng-select">
          <label for="jobTitle">Job Title</label>
          <input
            id="jobTitle"
            name="jobTitle"
            [(ngModel)]="formData.jobTitle"
            required
            pattern="[A-Za-z]+"
            class="form-control"
            placeholder="Enter your job title"
          />
          <div
            *ngIf="
              newapplication.form.controls['jobTitle']?.invalid &&
              newapplication.form.controls['jobTitle']?.touched
            "
          >
            <span class="errorMessage">Please Enter Job Title.</span>
          </div>
        </div>

        <!-- Phone Number Field -->
        <div class="col-md-5 figma-ng-select new_figma_select">
          <div class="phone-number-container">
            <label for="phone-number" class="form-label">Phone No</label>
            <div class="input-group">
              <ng-select
                [items]="countryCode"
                bindLabel="code"
                bindValue="code"
                [(ngModel)]="countryCode"
                placeholder="+91"
                [searchable]="true"
              >
                <ng-template ng-label-tmp>
                  {{ countryCode }}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  {{ item.code }} - {{ item.name }}
                </ng-template>
              </ng-select>

              <input
                type="text"
                id="phone-number"
                [(ngModel)]="phoneNumber"
                name="phoneNumber"
                placeholder="Enter your phone number"
                pattern="^[0-9]+$"
                (keypress)="OnlyNumbersAllowed($event)"
                class="phone-number-input"
                required
                #phoneNumberInput="ngModel"
              />
            </div>
            <!-- Display validation message -->
            <div
              *ngIf="!phoneNumberInput.valid && phoneNumberInput.touched"
              class="errorMessage"
            >
              Please enter valid phone number.
            </div>
          </div>
        </div>

        <!-- Email ID Field -->
        <div class="col-md-5 figma-ng-select">
          <div class="">
            <label for="email" class="custom_label">Email ID</label>
            <input
              type="email"
              class="form-control"
              id="email"
              name="email"
              [(ngModel)]="formData.emailId"
              required
              #emailInput="ngModel"
              placeholder="Enter your email"
              (ngModelChange)="emailInput.control.setValidators(emailValidator)"
              (ngModelChange)="emailInput.control.updateValueAndValidity()"
            />
            <!-- Display error message if the email input is invalid, touched, or dirty -->
            <div
              *ngIf="
                emailInput.invalid && (emailInput.touched || emailInput.dirty)
              "
              class="errorMessage mt-2"
            >
              Please Enter Valid Email
            </div>
          </div>
        </div>

        <!-- Gender Field -->
        <div class="col-md-5 figma-ng-select">
          <label for="gender" class="form-label fw500 neugray">Gender</label>

          <ng-select
            class="custom"
            [items]="genderOptions"
            bindLabel="name"
            bindValue="id"
            placeholder="Select your gender"
            [(ngModel)]="gender"
            name="genderSelect"
            #genderSelect="ngModel"
            required
          >
          </ng-select>

          <div
            *ngIf="(genderSelect.touched || newapplication.submitted) &&
        genderSelect.errors?.['required']"
          >
            <span class="errorMessage">Please select gender</span>
          </div>
        </div>

        <!-- Date of Birth Field -->
        <div class="mb-3 col-md-5 figma-calender">
          <label for="dateOfBirth" class="form-label fw500 neugray">DOB</label>
          <p-calendar
            [showIcon]="true"
            [(ngModel)]="perdateofbirth"
            name="perdateofbirthCal"
            #perdateofbirthCal="ngModel"
            [required]="true"
            class="primecalendar"
            placeholder="Select your date of birth"
          ></p-calendar>
          <div
            *ngIf="(perdateofbirthCal.touched || newapplication.submitted) && perdateofbirthCal.errors?.['required']"
          >
            <span class="errorMessage">Please select date of birth</span>
          </div>
        </div>

        <!-- Nationality Field -->
        <div class="col-md-5 figma-ng-select">
          <label for="nationality">Nationality</label>
          <input
            id="nationality"
            name="nationality"
            [(ngModel)]="formData.nationality"
            required
            pattern="^[a-zA-Z\s]+$"
            class="form-control"
            placeholder="Enter your nationality"
          />
          <div
            *ngIf="
              newapplication.form.controls['nationality']?.invalid &&
              newapplication.form.controls['nationality']?.touched
            "
          >
            <span class="errorMessage">Please Select Nationality.</span>
          </div>
        </div>

        <!-- Country of Birth Field -->
        <div class="col-md-5 figma-ng-select">
          <label for="countryOfBirth">Country of Birth</label>
          <input
            id="countryOfBirth"
            name="countryOfBirth"
            [(ngModel)]="formData.countryOfBirth"
            required
            pattern="^[a-zA-Z\s]+$"
            class="form-control"
            placeholder="Enter your country of birth"
          />
          <div
            *ngIf="
              newapplication.form.controls['countryOfBirth']?.invalid &&
              newapplication.form.controls['countryOfBirth']?.touched
            "
          >
            <span class="errorMessage">Please Enter Country of Birth.</span>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 mt-3 documents">
        <ngx-dropzone class="mt-2" (change)="newFileChange($event)" [accept]="'.pdf,.jpg,.jpeg,.doc,.docx,.png'" [multiple]="false">
          <ngx-dropzone-label>
            <div class="d-md-flex align-items-center newUpload_pdf">
              <button type="button" class="bulk__UploadResume">
                <img src="../../../assets/Images/new-upload.svg" alt="">
              </button>
              <h1 class="fw600 fs14 figma_blue_color pl-3 mt-2">Upload Resume</h1>
            </div>
          </ngx-dropzone-label>
        
          <ngx-dropzone-preview *ngFor="let file of uploadedFiles" [removable]="true" (removed)="removeFile(file)">
            <ngx-dropzone-label class="d-flex ml-4 align-items-center">
              <img *ngIf="file.type === 'application/pdf'" src="../../../assets/Images/pdf-icon.svg" alt="PDF Icon" style="margin-right: 8px;">
              <img *ngIf="file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" src="../../../assets/Images/doc-icon.svg" alt="DOC Icon" style="margin-right: 8px;">
              <img *ngIf="file.type === 'image/jpeg'" src="../../../assets/Images/jpeg-icon.svg" alt="JPEG Icon" style="margin-right: 8px;">
              <img *ngIf="file.type === 'image/png'" src="../../../assets/Images/png-icon.svg" alt="PNG Icon" style="margin-right: 8px;">
              
              <h1 class="fw600 fs14 pl-3 width-max">
                {{ file.name | slice : 0 : 18 }}
              </h1>
            </ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
        
        
        
      </div>
    </div>

    <div class="work_experiencen profile white-background mb-2">
      <p class="fs18 fw600 personal_det mb-4">Work Experience</p>
      <div class="row" style="display: flex; row-gap: 20px">
        <!-- Existing form fields go here -->

        <!-- New Industry field -->
        <div class="col-md-5">
          <div class="form-group">
            <label for="industry">Industry</label>
            <input
              type="text"
              id="industry"
              name="industry"
              class="form-control"
              [(ngModel)]="formData.industry"
              required
              #industry="ngModel"
              placeholder="Industry"
            />
            <div
              *ngIf="industry.invalid && (industry.dirty || industry.touched)"
              class="errorMessage"
            >
              Please enter industry.
            </div>
          </div>
        </div>

        <!-- New Experience field -->
        <div class="col-md-5">
          <div class="form-group">
            <label for="experience">Experience (in years)</label>
            <input
              type="text"
              id="experience"
              name="experience"
              class="form-control"
              [(ngModel)]="formData.experience"
              required
              #experience="ngModel"
              placeholder="Experience (in years)"
            />
            <div
              *ngIf="
                experience.invalid && (experience.dirty || experience.touched)
              "
              class="errorMessage"
            >
              Please enter your experience (in years).
            </div>
          </div>
        </div>

        <div class="row" *ngFor="let fields of fieldsList; let i = index">
            <div class="col-md-5">
                <div class="form-group">
                    <label for="industry-{{ i }}">Industry</label>
                    <input
                        type="text"
                        id="industry-{{ i }}"
                        name="industry-{{ i }}"
                        class="form-control"
                        [(ngModel)]="fields.industry"
                        required
                        placeholder="Industry"
                    />

                    <div *ngIf="industry.invalid && (industry.dirty || industry.touched)" class="errorMessage">
                        Please enter industry.
                    </div>
                </div>
            </div>
        
            <div class="col-md-5">
                <div class="form-group">
                    <label for="experience-{{ i }}">Experience (in years)</label>
                    <input
                        type="number"
                        id="experience-{{ i }}"
                        name="experience-{{ i }}"
                        class="form-control"
                        [(ngModel)]="fields.experience"
                        required
                        placeholder="Experience (in years)"
                    />

                    <div *ngIf="experience.invalid && (experience.dirty || experience.touched)" class="errorMessage">
                        Please enter your experience (in years).
                    </div>
                </div>
            </div>
            
            
        </div>
        
        <!-- Add button -->
        <div class="add-icon-main">
            <span *ngIf="fieldsList.length > 0" class="error fs14 fw600" (click)="removeFields(fieldsList.length - 1)">
                Remove
            </span>

            <span (click)="addFields()" role="button" class="add__Text add-iconnew">
                <img src="./assets/Images/add-icon.svg" alt="add-icon">
                Add
            </span>
        </div>

        <!-- New Domain field with ng-select -->
        <div class="col-md-5 figma-ng-select">
          <div class="form-group">
            <label for="domain">Domain</label>
            <ng-select
              id="domain"
              name="domain"
              [(ngModel)]="formData.domain"
              required
              #domain="ngModel"
              placeholder="Select Domain"
              [items]="['Technology', 'Finance', 'Healthcare', 'Education']"
            ></ng-select>
            <div
              *ngIf="domain.invalid && (domain.dirty || domain.touched)"
              class="errorMessage"
            >
              Please select a domain.
            </div>
          </div>
        </div>

        <!-- Experience (in years) field with ng-select -->
        <div class="col-md-5 figma-ng-select">
          <div class="form-group">
            <label for="experience">Experience (in years)</label>
            <ng-select
              id="experiencee"
              name="experiencee"
              [(ngModel)]="formData.experiencee"
              required
              #experiencee="ngModel"
              placeholder="Select Experience (in years)"
              [items]="experienceeOptions"
            ></ng-select>
            <div
              *ngIf="
                experiencee.invalid &&
                (experiencee.dirty || experiencee.touched)
              "
              class="errorMessage"
            >
              Please select your experience (in years).
            </div>
          </div>
        </div>

        <div class="row" *ngFor="let fields of fieldsLists; let i = index">
            <div class="col-md-5">
                <div class="form-group">
                    <label for="domain-{{ i }}">Domain</label>
                    <input
                        type="text"
                        id="domain-{{ i }}"
                        name="domain-{{ i }}"
                        class="form-control"
                        [(ngModel)]="fields.domain"
                        required
                        placeholder="Domain"
                    />

                    <div
              *ngIf="domain.invalid && (domain.dirty || domain.touched)"
              class="errorMessage"
            >
              Please select a domain.
            </div>
                </div>
            </div> 
            <div class="col-md-5">
                <div class="form-group">
                    <label for="experiencee-{{ i }}">Experience (in years)</label>
                    <input
                        type="text"
                        id="experiencee-{{ i }}"
                        name="experiencee-{{ i }}"
                        class="form-control"
                        [(ngModel)]="fields.experiencee"
                        required
                        placeholder="Select Experience (in years)"
                    />

                    <div
              *ngIf="
                experiencee.invalid &&
                (experiencee.dirty || experiencee.touched)
              "
              class="errorMessage"
            >
              Please select your experience (in years).
            </div>
                </div>
            </div>
        </div>

        <div class="add-icon-main">
            <span *ngIf="fieldsLists.length > 0" class="error fs14 fw600" (click)="removeFieldss(fieldsLists.length - 1)">
                Remove
            </span>

            <span (click)="addFieldss()" role="button" class="add__Text add-iconnew">
                <img src="./assets/Images/add-icon.svg" alt="add-icon">
                Add
            </span>
        </div>


        <!-- Total Experience field with ng-select -->
        <div class="col-md-5 figma-ng-select">
          <div class="form-group">
            <label for="totalExperience">Total Experience (in years)</label>
            <ng-select
              id="totalExperience"
              name="totalExperience"
              [(ngModel)]="formData.totalExperience"
              required
              #totalExperience="ngModel"
              placeholder="Select Total Experience"
              [items]="experienceOptions"
            ></ng-select>
            <div
              *ngIf="
                totalExperience.invalid &&
                (totalExperience.dirty || totalExperience.touched)
              "
              class="errorMessage"
            >
              Please select your total experience.
            </div>
          </div>
        </div>

        <!-- Notice Period (in days) field with ng-select -->
        <div class="col-md-5 figma-ng-select">
          <div class="form-group">
            <label for="noticePeriod">Notice Period (in days)</label>
            <ng-select
              id="noticePeriod"
              name="noticePeriod"
              [(ngModel)]="formData.noticePeriod"
              required
              #noticePeriod="ngModel"
              placeholder="Select Notice Period (in days)"
              [items]="noticePeriodOptions"
            ></ng-select>
            <div
              *ngIf="
                noticePeriod.invalid &&
                (noticePeriod.dirty || noticePeriod.touched)
              "
              class="errorMessage"
            >
              Please select your notice period in days.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="work_history profile white-background mb-2">
        <p class="fs18 fw600 personal_det mb-4">Work History</p>
    
        <div class="row" style="display: flex; row-gap: 20px">
            <!-- Loop through work history sets -->
            <ng-container *ngFor="let workHistory of workHistoryList; let i = index">
                <!-- Designation Field -->
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="designation-{{i}}">Designation</label>
                        <input
                            type="text"
                            id="designation-{{i}}"
                            name="designation-{{i}}"
                            class="form-control"
                            [(ngModel)]="workHistory.designation"
                            required
                            #designationControl="ngModel"
                            placeholder="Designation"
                        />
                        <div
                            *ngIf="designationControl.invalid && (designationControl.dirty || designationControl.touched)"
                            class="errorMessage"
                        >
                            Designation is required.
                        </div>
                    </div>
                </div>
    
                <!-- Organisation Name Field -->
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="organisationName-{{i}}">Organisation Name</label>
                        <input
                            type="text"
                            id="organisationName-{{i}}"
                            name="organisationName-{{i}}"
                            class="form-control"
                            [(ngModel)]="workHistory.organisationName"
                            required
                            #organisationNameControl="ngModel"
                            placeholder="Organization Name"
                        />
                        <div
                            *ngIf="organisationNameControl.invalid && (organisationNameControl.dirty || organisationNameControl.touched)"
                            class="errorMessage"
                        >
                            Please enter the organization name.
                        </div>
                    </div>
                </div>
    
                <!-- City Field -->
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="city-{{i}}">City</label>
                        <input
                            type="text"
                            id="city-{{i}}"
                            name="city-{{i}}"
                            class="form-control"
                            [(ngModel)]="workHistory.city"
                            required
                            #cityControl="ngModel"
                            placeholder="City"
                        />
                        <div
                            *ngIf="cityControl.invalid && (cityControl.dirty || cityControl.touched)"
                            class="errorMessage"
                        >
                            Please enter the city.
                        </div>
                    </div>
                </div>
    
                <!-- Country Field -->
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="country-{{i}}">Country</label>
                        <input
                            type="text"
                            id="country-{{i}}"
                            name="country-{{i}}"
                            class="form-control"
                            [(ngModel)]="workHistory.country"
                            required
                            #countryControl="ngModel"
                            pattern="^[A-Za-z ]+$"
                            placeholder="Country"
                        />
                        <div
                            *ngIf="countryControl.invalid && (countryControl.dirty || countryControl.touched)"
                            class="errorMessage"
                        >
                            Please enter the country.
                        </div>
                    </div>
                </div>
    
                <!-- Current Salary Field -->
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="currentSalary-{{i}}">Current Salary (In Rupees)</label>
                        <div class="input-group figma-ng-select">
                            <ng-select
                                [(ngModel)]="workHistory.currencyCode"
                                [items]="currencyOptions"
                                bindLabel="name"
                                bindValue="code"
                                placeholder="INR"
                                [searchable]="true"
                                class="currency_code_selector"
                            >
                            </ng-select>
                            <input
                                type="text"
                                id="currentSalary-{{i}}"
                                name="currentSalary-{{i}}"
                                class="form-control"
                                [(ngModel)]="workHistory.currentSalary"
                                required
                                #currentSalaryControl="ngModel"
                                placeholder="Current Salary"
                            />
                        </div>
                        <div
                            *ngIf="currentSalaryControl.invalid && (currentSalaryControl.dirty || currentSalaryControl.touched)"
                            class="errorMessage"
                        >
                            Please enter your current salary.
                        </div>
                    </div>
                </div>
    
                <!-- Total Variable (Per Annum) Field -->
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="totalVariable-{{i}}">Total Variable (Per Annum)</label>
                        <input
                            type="text"
                            id="totalVariable-{{i}}"
                            name="totalVariable-{{i}}"
                            class="form-control"
                            [(ngModel)]="workHistory.totalVariable"
                            required
                            #totalVariableControl="ngModel"
                            placeholder="Total Variable"
                        />
                        <div
                            *ngIf="totalVariableControl.invalid && (totalVariableControl.dirty || totalVariableControl.touched)"
                            class="errorMessage"
                        >
                            Please enter the total variable.
                        </div>
                    </div>
                </div>
    
                <!-- Duration (in years) Field -->
                <div class="col-md-5 figma-calender">
                    <div class="form-group row">
                        <label for="duration-{{i}}">Duration (in years)</label>
                        <!-- From Calendar -->
                        <div class="mb-2 col-md-6">
                            <p-calendar
                                id="fromDate-{{i}}"
                                [showIcon]="true"
                                [(ngModel)]="workHistory.fromDate"
                                name="fromDate-{{i}}"
                                #fromDateControl="ngModel"
                                [required]="true"
                                class="primecalendar"
                                placeholder="From"
                                dateFormat="dd/mm/yy"
                                (onSelect)="validateDateRange(i)"
                            ></p-calendar>
                            <div
                                *ngIf="fromDateControl.invalid && (fromDateControl.dirty || fromDateControl.touched)"
                                class="errorMessage"
                            >
                                Start date is required.
                            </div>
                        </div>
    
                        <!-- To Calendar -->
                        <div class="col-md-6">
                            <p-calendar
                                id="toDate-{{i}}"
                                [showIcon]="true"
                                [(ngModel)]="workHistory.toDate"
                                name="toDate-{{i}}"
                                #toDateControl="ngModel"
                                [required]="true"
                                class="primecalendar"
                                placeholder="To"
                                dateFormat="dd/mm/yy"
                                (onSelect)="validateDateRange(i)"
                            ></p-calendar>
                            <div
                                *ngIf="toDateControl.invalid && (toDateControl.dirty || toDateControl.touched)"
                                class="errorMessage"
                            >
                                End date is required.
                            </div>
                            <div *ngIf="workHistory.dateRangeInvalid" class="errorMessage">
                                Start date must be earlier than end date.
                            </div>
                        </div>
                    </div>
                </div>
    
                <!-- Current Job Checkbox -->
                <div class="col-md-12">
                    <div class="form-group row">
                        <div
                            class="col-md-4 create-job-select figma-ng-select"
                            style="display: flex; align-items: center"
                        >
                            <input
                                type="checkbox"
                                class="custom"
                                style="width: 20px; height: 20px; border-radius: 5px; border: 1.5px;"
                                [(ngModel)]="workHistory.currentJob"
                                name="currentJob-{{i}}"
                            />
                            <span
                                class="form-label fw500 neugray mb-0"
                                style="margin-left: 10px"
                            >
                                This is my current job
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
    
            <!-- Add button -->
            <div class="add_ant">
                <button
                        class="error fs14 fw600 remove_btn"
                        (click)="removeWorkHistory(i)"
                        *ngIf="workHistoryList.length > 1"
                    >
                        Remove this job
                    </button>
                <button class="secondary-btn" style="height: 40px;" (click)="addWorkHistory()">
                    <img style="margin-right: 6px;" src="../../../assets/Images/add-icon.svg" alt="" /> Add another job
                </button>
            </div>
        </div>
    </div>
    

    <div class="educational_info white-background profile mb-2">
      <p class="fs18 fw600 personal_det mb-4">Educational Information</p>

      <div class="row" style="display: flex; row-gap: 20px">
        <!-- High-level of Education Field -->
        <ng-container *ngFor="let educationInfo of educationInfoList; let i = index">
            <div class="col-md-5 figma-ng-select">
              <label for="education-{{i}}">High-level of Education</label>
              <ng-select
                [(ngModel)]="educationInfo.education"
                name="education-{{i}}"
                required
                placeholder="Select higher level of education"
                #educationControl="ngModel"
              >
                <ng-option *ngFor="let option of educationOptions" [value]="option">
                  {{ option }}
                </ng-option>
              </ng-select>
              <div
                *ngIf="educationControl.invalid && (educationControl.dirty || educationControl.touched)"
                class="error"
              >
                Please select a higher level of education.
              </div>
            </div>
      
            <div class="col-md-5 figma-ng-select">
              <label for="qualification-{{i}}">Qualification</label>
              <ng-select
                [(ngModel)]="educationInfo.qualification"
                name="qualification-{{i}}"
                required
                placeholder="Select Qualification"
                #qualificationControl="ngModel"
              >
                <ng-option *ngFor="let option of qualifications" [value]="option">
                  {{ option }}
                </ng-option>
              </ng-select>
              <div
                *ngIf="qualificationControl.invalid && (qualificationControl.dirty || qualificationControl.touched)"
                class="error"
              >
                Please select qualification.
              </div>
            </div>
      
            <div class="col-md-5 figma-calender">
              <label for="yearOfEducation-{{i}}" class="form-label fw500 neugray">
                Year of Education
              </label>
              <p-calendar
                [(ngModel)]="educationInfo.yearOfEducation"
                name="yearOfEducation-{{i}}"
                [required]="true"
                dateFormat="dd/mm/yy"
                placeholder="DD/MM/YY"
                #yearOfEducationControl="ngModel"
                [showIcon]="true"
              ></p-calendar>
              <div
                *ngIf="yearOfEducationControl.invalid && (yearOfEducationControl.dirty || yearOfEducationControl.touched)"
                class="error"
              >
                Please select year of education.
              </div>
            </div>
      
            <div class="col-md-5">
              <label for="collegeName-{{i}}">College/University Name</label>
              <input
                type="text"
                [(ngModel)]="educationInfo.collegeName"
                name="collegeName-{{i}}"
                required
                class="form-control"
                placeholder="Enter the name"
                #collegeNameControl="ngModel"
              />
              <div
                *ngIf="collegeNameControl.invalid && (collegeNameControl.dirty || collegeNameControl.touched)"
                class="error"
              >
                Please enter college/university name.
              </div>
            </div>
        </ng-container>


          <div class="add_ant">
            <button class="error fs14 fw600 remove_btn" *ngIf="educationInfoList.length > 1" style="border: 0; background: none;" (click)="removeEducationInfo(educationInfoList.length-1)">
                Remove this degree
              </button>
            <button class="secondary-btn" style="height: 40px;" (click)="addEducationInfo()">
              <img style="margin-right: 6px" src="../../../assets/Images/add-icon.svg" alt="" /> Add another degree
            </button>
          </div>

          <!-- Skill Category Field -->
        <div class="col-md-5 figma-ng-select">
            <label for="skillCategory">Skill Category</label>
            <ng-select
              name="skillCategory"
              [(ngModel)]="formData.skillCategory"
              required
              #skillCategory="ngModel"
              placeholder="Select skills category"
            >
              <ng-option
                *ngFor="let category of skillCategories"
                [value]="category"
              >
                {{ category }}
              </ng-option>
            </ng-select>
            <div
              *ngIf="
                skillCategory.invalid &&
                (skillCategory.dirty || skillCategory.touched)
              "
              class="error"
            >
              Please select skill category.
            </div>
          </div>
  
          <!-- Skills Field -->
          <div class="col-md-5 figma-ng-select">
            <label for="skills">Skills</label>
            <ng-select
              name="skills"
              [(ngModel)]="formData.skills"
              [items]="skillsOptions"
              bindLabel="name"
              bindValue="id"
              required
              #skills="ngModel"
              placeholder="Select document name"
            >
              <ng-option *ngFor="let skill of skillsOptions" [value]="skill.id">
                {{ skill.name }}
              </ng-option>
            </ng-select>
            <div
              *ngIf="skills.invalid && (skills.dirty || skills.touched)"
              class="error"
            >
              Please select skills.
            </div>
          </div>
  
          <!-- Languages Known Field -->
          <div class="col-md-5 figma-ng-select">
            <label for="languagesKnown">Languages Known</label>
            <ng-select
              name="languagesKnown"
              [(ngModel)]="formData.languagesKnown"
              [items]="languagesOptions"
              bindLabel="name"
              bindValue="id"
              required
              #languagesKnown="ngModel"
              placeholder="Select Languages"
            >
              <ng-option
                *ngFor="let language of languagesOptions"
                [value]="language.id"
              >
                {{ language.name }}
              </ng-option>
            </ng-select>
            <div
              *ngIf="
                languagesKnown.invalid &&
                (languagesKnown.dirty || languagesKnown.touched)
              "
              class="error"
            >
              Please select languages known.
            </div>
          </div>
        </div>

    </div>

    <div class="general_info white-background profile mb-3">
      <p class="fs18 fw600 personal_det mb-4">General Information</p>
      <p class="mb-3 mt-3 fs18 fw600">1. Address</p>
      <p class="fs18 fw600 mb-3">Permanent Address</p>

      <div class="row" style="display: flex; row-gap: 20px">
        <!-- Address Line 1 -->
        <div class="col-md-5">
          <label for="addressLine1">Address Line 1</label>
          <input
            type="text"
            id="addressLine1"
            name="addressLine1"
            [(ngModel)]="formData.addressLine1"
            required
            minlength="5"
            maxlength="100"
            #addressLine1="ngModel"
            placeholder="Enter address line 1"
            [ngClass]="{
              error:
                addressLine1.invalid &&
                (addressLine1.dirty || addressLine1.touched)
            }"
          />
          <div
            *ngIf="
              addressLine1.invalid &&
              (addressLine1.dirty || addressLine1.touched)
            "
          >
            <div class="error" *ngIf="addressLine1.errors?.['required']">
              Please enter address
            </div>
          </div>
        </div>

        <!-- Address Line 2 -->
        <div class="col-md-5">
          <label for="addressLine2">Address Line 2</label>
          <input
            type="text"
            id="addressLine2"
            name="addressLine2"
            [(ngModel)]="formData.addressLine2"
            #addressLine2="ngModel"
            placeholder="Enter address line 2"
            [ngClass]="{
              errormessage:
                addressLine2.invalid &&
                (addressLine2.dirty || addressLine2.touched)
            }"
          />
        </div>

        <!-- Address Line 3 -->
        <div class="col-md-5">
          <label for="addressLine3">Address Line 3</label>
          <input
            type="text"
            id="addressLine3"
            name="addressLine3"
            [(ngModel)]="formData.addressLine3"
            #addressLine3="ngModel"
            placeholder="Enter address line 3"
            [ngClass]="{
              errormessage:
                addressLine3.invalid &&
                (addressLine3.dirty || addressLine3.touched)
            }"
          />
        </div>

        <!-- State -->
        <div class="col-md-5">
          <label for="state">State</label>
          <input
            type="text"
            id="state"
            name="state"
            [(ngModel)]="formData.state"
            required
            minlength="2"
            maxlength="2"
            pattern="[A-Z]{2}"
            #state="ngModel"
            placeholder="Enter state"
            [ngClass]="{
              errormessage: state.invalid && (state.dirty || state.touched)
            }"
          />
          <div *ngIf="state.invalid && (state.dirty || state.touched)">
            <div class="error" *ngIf="state.errors?.['required']">
              Please enter state.
            </div>
          </div>
        </div>

        <!-- Country -->
        <div class="col-md-5 figma-ng-select">
          <label for="country">Country</label>
          <ng-select
            [(ngModel)]="formData.addresscountry"
            name="country"
            #country="ngModel"
            required
            [items]="countryList"
            bindLabel="name"
            bindValue="code"
            placeholder="Select country"
          ></ng-select>
          <div
            class="errorMessage"
            *ngIf="country.invalid && (country.dirty || country.touched)"
          >
            <div *ngIf="country.errors?.['required']">
              Please select country.
            </div>
          </div>
        </div>

        <!-- ZIP Code -->
        <div class="col-md-5">
          <label for="zipCode">ZIP Code</label>
          <input
            type="text"
            id="zipCode"
            name="zipCode"
            [(ngModel)]="formData.zipCode"
            required
            pattern="^\d{6}(-\d{6})?$"
            #zipCode="ngModel"
            placeholder="Enter your zip code"
          />
          <div *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)">
            <div class="errorMessage" *ngIf="zipCode.errors?.['required']">
              Please enter Zip code.
            </div>
            <div class="errorMessage" *ngIf="zipCode.errors?.['pattern']">
              enter valid Zip code(e.g., 12345-6789).
            </div>
          </div>
        </div>

        <!-- Alternate Phone Number Field -->
        <div class="col-md-5 figma-ng-select">
          <div class="alternate-phone-number-container">
            <label for="alternate-phone-number" class="form-label"
              >Alternate Phone Number</label
            >
            <div class="input-group">
              <ng-select
                [(ngModel)]="alternateCountryCode"
                [items]="countryCode"
                bindLabel="name"
                bindValue="code"
                placeholder="+91"
                class="country-code-selector"
                name="alternateCountryCode"
              >
              </ng-select>

              <input
                type="text"
                id="alternate-phone-number"
                [(ngModel)]="formData.alternatePhoneNumber"
                name="alternatePhoneNumber"
                placeholder="Enter alternate phone number"
                pattern="^[0-9]+$"
                (keypress)="OnlyNumbersAllowed($event)"
                class="alternate-phone-number-input"
                required
                #alternatePhoneNumberInput="ngModel"
              />
            </div>
            <!-- Display validation message -->
            <div
              *ngIf="
                !alternatePhoneNumberInput.valid &&
                alternatePhoneNumberInput.touched
              "
              class="errorMessage"
            >
              Please enter valid alternate phone number.
            </div>
          </div>
        </div>

        <p class="fs18 fw600 mb-3">Current Address</p>
        <div class="row" style="display: flex; row-gap: 20px">
          <div class="col-md-5">
            <label for="currentaddressLine1">Address Line 1</label>
            <input
              type="text"
              id="currentaddressLine1"
              name="currentaddressLine1"
              [(ngModel)]="formData.currentaddressLine1"
              required
              minlength="5"
              maxlength="100"
              #currentaddressLine1="ngModel"
              placeholder="Enter address line 1"
              [ngClass]="{
                error:
                  currentaddressLine1.invalid &&
                  (currentaddressLine1.dirty || currentaddressLine1.touched)
              }"
            />
            <div
              *ngIf="
                currentaddressLine1.invalid &&
                (currentaddressLine1.dirty || currentaddressLine1.touched)
              "
            >
              <div
                class="error"
                *ngIf="currentaddressLine1.errors?.['required']"
              >
                Please enter address
              </div>
            </div>
          </div>

          <!-- Address Line 2 -->
          <div class="col-md-5">
            <label for="currentaddressLine2">Address Line 2</label>
            <input
              type="text"
              id="currentaddressLine2"
              name="currentaddressLine2"
              [(ngModel)]="formData.currentaddressLine2"
              #currentaddressLine2="ngModel"
              placeholder="Enter address line 2"
              [ngClass]="{
                errormessage:
                  currentaddressLine2.invalid &&
                  (currentaddressLine2.dirty || currentaddressLine2.touched)
              }"
            />
          </div>

          <!-- Address Line 3 -->
          <div class="col-md-5">
            <label for="currentaddressLine3">Address Line 3</label>
            <input
              type="text"
              id="currentaddressLine3"
              name="currentaddressLine3"
              [(ngModel)]="formData.currentaddressLine3"
              #currentaddressLine3="ngModel"
              placeholder="Enter address line 3"
              [ngClass]="{
                errormessage:
                  currentaddressLine3.invalid &&
                  (currentaddressLine3.dirty || currentaddressLine3.touched)
              }"
            />
          </div>

          <!-- State -->
          <div class="col-md-5">
            <label for="currentstate">State</label>
            <input
              type="text"
              id="currentstate"
              name="currentstate"
              [(ngModel)]="formData.currentstate"
              required
              minlength="2"
              maxlength="2"
              pattern="[A-Z]{2}"
              #currentstate="ngModel"
              placeholder="Enter state"
              [ngClass]="{
                errormessage:
                  currentstate.invalid &&
                  (currentstate.dirty || currentstate.touched)
              }"
            />
            <div
              *ngIf="
                currentstate.invalid &&
                (currentstate.dirty || currentstate.touched)
              "
            >
              <div class="error" *ngIf="currentstate.errors?.['required']">
                Please enter state.
              </div>
            </div>
          </div>

          <!-- Country -->
          <div class="col-md-5 figma-ng-select">
            <label for="currentcountry">Country</label>
            <ng-select
              [(ngModel)]="formData.currentcountry"
              name="currentcountry"
              #currentcountry="ngModel"
              required
              [items]="countryList"
              bindLabel="name"
              bindValue="code"
              placeholder="Select country"
            ></ng-select>
            <div
              class="errorMessage"
              *ngIf="
                currentcountry.invalid &&
                (currentcountry.dirty || currentcountry.touched)
              "
            >
              <div *ngIf="currentcountry.errors?.['required']">
                Please select country.
              </div>
            </div>
          </div>

          <!-- ZIP Code -->
          <div class="col-md-5">
            <label for="currentzipCode">ZIP Code</label>
            <input
              type="text"
              id="currentzipCode"
              name="currentzipCode"
              [(ngModel)]="formData.currentzipCode"
              required
              pattern="^\d{6}(-\d{6})?$"
              #currentzipCode="ngModel"
              placeholder="Enter your zip code"
            />
            <div
              *ngIf="
                currentzipCode.invalid &&
                (currentzipCode.dirty || currentzipCode.touched)
              "
            >
              <div
                class="errorMessage"
                *ngIf="currentzipCode.errors?.['required']"
              >
                Please enter Zip code.
              </div>
              <div class="errorMessage" *ngIf="currentzipCode.errors?.['pattern']">
                enter valid Zip code(e.g., 12345-6789).
              </div>
            </div>
          </div>

          <!-- Alternate Phone Number Field -->
          <div class="col-md-5 figma-ng-select">
            <div class="alternate-phone-number-container">
              <label for="current-alternate-phone-number" class="form-label"
                >Alternate Phone Number</label
              >
              <div class="input-group">
                <ng-select
                  [(ngModel)]="alternateCountryCode"
                  [items]="countryCode"
                  bindLabel="code"
                  bindValue="code"
                  placeholder="+91"
                  class="country-code-selector"
                  name="currentalternateCountryCode" >
                </ng-select>

                <input
                  type="text"
                  id="current-alternate-phone-number"
                  [(ngModel)]="formData.alternatePhoneNumberr"
                  name="currentalternatePhoneNumber"
                  placeholder="Enter alternate phone number"
                  pattern="^[0-9]+$"
                  (keypress)="OnlyNumbersAllowed($event)"
                  class="alternate-phone-number-input"
                  required
                  #currentalternatePhoneNumberrInput="ngModel"
                />
              </div>
              <!-- Display validation message -->
              <div
                *ngIf="
                  !currentalternatePhoneNumberrInput.valid &&
                  currentalternatePhoneNumberrInput.touched
                "
                class="errorMessage"
              >
                Please enter valid alternate phone number.
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="col-md-4 create-job-select figma-ng-select"
              style="display: flex; align-items: center"
            >
              <input
                type="checkbox"
                class="custom"
                style="
                  width: 20px;
                  height: 20px;
                  border-radius: 5px;
                  border: 1.5px;
                "
                [(ngModel)]="currentjob"
                name="typaofappointcheck"
              />
              <span
                for="Nationality"
                class="form-label fw500 neugray mb-0"
                style="margin-left: 10px"
                >Same as permanent address</span
              >
            </div>
          </div>
        </div>

        <p class="mb-3 fs18 fw600">2. Immigration Status</p>

        <p for="visa" class="fs12 fw600">
          Do you have the relevant visa to work in the country?
        </p>
        <br />
        <div class="visa-container">
          <div class="d-flex radio-group">
            <input type="radio" id="yes" name="visa" value="yes" />
            <span for="yes">Yes</span>
          </div>
          <div class="d-flex radio-group">
            <input type="radio" id="no" name="visa" value="no" checked />
            <span for="no">No</span>
          </div>
        </div>

        <div class="row" style="display: flex; row-gap: 20px">
          <div class="col-md-5 figma-ng-select">
            <label for="workPermitType">Work Permit Type:</label>
            <ng-select
              [(ngModel)]="formData.workPermitType"
              name="workPermitType"
              #workPermitType="ngModel"
              required
              [items]="workPermitTypeList"
              bindLabel="name"
              bindValue="code"
              placeholder="Select work permit type"
            ></ng-select>
            <div
              class="errorMessage"
              *ngIf="
                workPermitType.invalid &&
                (workPermitType.dirty || workPermitType.touched)
              "
            >
              <div *ngIf="workPermitType.errors?.['required']">
                Please select a work permit type.
              </div>
            </div>
          </div>

          <div
            class="row"
            style="display: flex; row-gap: 20px; align-items: flex-end"
          >
            <!-- Passport Number -->
            <div class="col-md-5 figma-ng-select">
              <label for="passportNumber">Passport Number</label>
              <input
                type="text"
                [(ngModel)]="formData.passportNumber"
                name="passportNumber"
                #passportNumber="ngModel"
                required
                minlength="6"
                maxlength="9"
                placeholder="Enter passport number"
              />
              <div
                class="errorMessage"
                *ngIf="
                  passportNumber.invalid &&
                  (passportNumber.dirty || passportNumber.touched)
                "
              >
                <div *ngIf="passportNumber.errors?.['required']">
                  Please enter passport number.
                </div>
                <div *ngIf="passportNumber.errors?.['minlength']">
                  Passport number must be at least 6 characters.
                </div>
                <div *ngIf="passportNumber.errors?.['maxlength']">
                  Passport number must not exceed 9 characters.
                </div>
              </div>
            </div>

            <div class="col-md-5">
              <div class="form-group row" style="row-gap: 20px;">
                <label for="passport">Date of Issue & Expiry</label>
                <!-- From Calendar -->
                <div class="col-md-6 figma-calender">
                  <p-calendar
                    id="fromDate"
                    [showIcon]="true"
                    [(ngModel)]="formData.dateOfIssue"
                    name="dateOfIssue"
                    #dateOfIssue="ngModel"
                    [required]="true"
                    class="primecalendar"
                    placeholder="From"
                    dateFormat="dd/mm/yy"
                    (onSelect)="validateDateRange(i)"
                  ></p-calendar>
                  <div
                    *ngIf="
                      dateOfIssue.invalid &&
                      (dateOfIssue.dirty || dateOfIssue.touched)
                    "
                    class="errorMessage"
                  >
                    Start date is required.
                  </div>
                </div>

                <!-- To Calendar -->
                <div class="col-md-6 figma-calender">
                  <p-calendar
                    id="dateOfExpiry"
                    [showIcon]="true"
                    [(ngModel)]="formData.dateOfExpiry"
                    name="dateOfExpiry"
                    #dateOfExpiry="ngModel"
                    [required]="true"
                    class="primecalendar"
                    placeholder="To"
                    dateFormat="dd/mm/yy"
                    (onSelect)="validateDateRange(i)"
                  ></p-calendar>
                  <div
                    *ngIf="
                      dateOfExpiry.invalid &&
                      (dateOfExpiry.dirty || dateOfExpiry.touched)
                    "
                    class="errorMessage"
                  >
                    End date is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-5 figma-ng-select">
              <label for="visNumber">Visa Number</label>
              <input
                type="text"
                [(ngModel)]="formData.visaNumber"
                name="visaNumber"
                #visaNumber="ngModel"
                required
                minlength="6"
                maxlength="9"
                placeholder="Enter passport number"
              />
              <div
                class="errorMessage"
                *ngIf="
                  visaNumber.invalid && (visaNumber.dirty || visaNumber.touched)
                "
              >
                <div *ngIf="visaNumber.errors?.['required']">
                  Please enter visa number.
                </div>
                <div *ngIf="visaNumber.errors?.['minlength']">
                  Passport number must be at least 6 characters.
                </div>
                <div *ngIf="visaNumber.errors?.['maxlength']">
                  Passport number must not exceed 9 characters.
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group row" style="row-gap: 20px;">
                <label for="visaNumber">Date of Issue & Expiry</label>
                <!-- From Calendar -->
                <div class="col-md-6 figma-calender">
                  <p-calendar
                    id="visadateOfIssue"
                    [showIcon]="true"
                    [(ngModel)]="formData.visadateOfIssue"
                    name="visadateOfIssue"
                    #visadateOfIssue="ngModel"
                    [required]="true"
                    class="primecalendar"
                    placeholder="From"
                    dateFormat="dd/mm/yy"
                    (onSelect)="validateDateRange(i)"
                  ></p-calendar>
                  <div
                    *ngIf="
                      visadateOfIssue.invalid &&
                      (visadateOfIssue.dirty || visadateOfIssue.touched)
                    "
                    class="errorMessage"
                  >
                    Start date is required.
                  </div>
                </div>

                <!-- To Calendar -->
                <div class="col-md-6 figma-calender">
                  <p-calendar
                    id="visadateOfExpiry"
                    [showIcon]="true"
                    [(ngModel)]="formData.visadateOfExpiry"
                    name="visadateOfExpiry"
                    #visadateOfExpiry="ngModel"
                    [required]="true"
                    class="primecalendar"
                    placeholder="To"
                    dateFormat="dd/mm/yy"
                    (onSelect)="validateDateRange(i)"
                  ></p-calendar>
                  <div
                    *ngIf="
                      visadateOfExpiry.invalid &&
                      (visadateOfExpiry.dirty || visadateOfExpiry.touched)
                    "
                    class="errorMessage"
                  >
                    End date is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="declaration white-background profile mb-2">
      <p class="fs18 fw600 personal_det mb-3">Declaration</p>

      <div>
        <!-- Question 1: Convictions -->
        <p class="fs12 fw600">
          Have you ever been convicted in a court of law of any country?
        </p>
        <br />
        <div class="visa-container">
          <div class="d-flex radio-group">
            <input
              type="radio"
              id="convictedYes"
              name="convictions"
              value="Yes"
            />
            <span for="convictedYes">Yes</span>
          </div>
          <div class="d-flex radio-group">
            <input
              type="radio"
              id="convictedNo"
              name="convictions"
              value="No"
              checked
            />
            <span for="convictedNo">No</span><br />
          </div>
        </div>

        <!-- Question 2: Employment -->
        <p class="fs12 fw600">
          Have you ever been dismissed, discharged or suspended from employment?
        </p>
        <br />
        <div class="visa-container">
          <div class="d-flex radio-group">
            <input
              type="radio"
              id="employmentYes"
              name="employment"
              value="Yes"
            />
            <span for="employmentYes">Yes</span>
          </div>
          <div class="d-flex radio-group">
            <input
              type="radio"
              id="employmentNo"
              name="employment"
              value="No"
              checked
            />
            <span for="employmentNo">No</span><br />
          </div>
        </div>

        <!-- Question 3: Health -->
        <p class="fs12 fw600">Do you suffer from any of the following?</p>
        <br />
        <div class="visa-container">
          <div class="d-flex radio-group">
            <input type="radio" id="healthYes" name="health" value="Yes" />
            <span for="healthYes">Yes</span>
          </div>
          <div class="d-flex radio-group">
            <input
              type="radio"
              id="healthNo"
              name="health"
              value="No"
              checked
            />
            <span for="healthNo">No</span><br />
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="declaration_select figma-ng-select"
          style="display: flex; align-items: center"
        >
          <input
            type="checkbox"
            class="custom"
            style="width: 20px; height: 20px; border-radius: 5px; border: 1.5px"
            [(ngModel)]="declaration"
            name="declarationcheck"
            checked
          />
          <span
            for="declaration"
            class="form-label fw500 neugray mb-0 fs12 fw500"
            style="margin-left: 10px"
          >
            I acknowledge and agree to GII’S document retention and privacy
            terms and conditions
          </span>
        </div>
      </div>
    </div>

    <div class="documents white-background profile mb-2 newUpload_pdf">
        <p class="fs18 fw600 personal_det mb-3">Documents</p>
    
        <div *ngFor="let dropZone of dropzones; let index = index" class="col-md-4 mt-4 newUpload_pdf">
            <ngx-dropzone class="mt-2" (change)="newSigDocument($event, index)" [accept]="'.pdf,.jpg,.jpeg,.doc,.docx,.png'" [multiple]="false">
                <ngx-dropzone-label>
                    <div class="d-md-flex align-items-center">
                        <button type="button" class="bulk__UploadResume">
                            <img src="../../../assets/Images/new-upload.svg" alt="">
                        </button>
                        <h1 class="fw600 fs14 figma_blue_color pl-3 mt-2">Upload</h1>
                    </div>
                </ngx-dropzone-label>
    
                <ngx-dropzone-preview *ngFor="let f of dropZone.files" [removable]="true" (removed)="onRemoveSl(f, index)">
                    <ngx-dropzone-label class="d-flex ml-4 align-items-center">
                        <img *ngIf="f.type == 'application/pdf'" src="../../../assets/Images/pdf-icon.svg" alt="PDF Icon" style="margin-right: 8px;">
                        <img *ngIf="f.type == 'application/msword'" src="../../../assets/Images/doc-icon.svg" alt="DOC Icon" style="margin-right: 8px;">
                        <img *ngIf="f.type == 'image/jpeg'" src="../../../assets/Images/jpeg-icon.svg" alt="JPEG Icon" style="margin-right: 8px;">
    
                        <h1 class="fw600 fs14 pl-3 width-max">
                            {{ f.name | slice : 0 : 18 }}
                        </h1>
                    </ngx-dropzone-label>
                </ngx-dropzone-preview>
            </ngx-dropzone>
    
            
        </div>
    
        <div class="add_ant">

            <button class="error fs14 fw600 remove_btn" style="height: 40px; margin-top: 10px; border: 1px solid red; background: none; border-radius: 8px; margin-right: 8px;" (click)="removeDropzone(index)" *ngIf="dropzones.length > 1" (click)="removeDropzone(index)">
                Remove
            </button>
            <button class="secondary-btn" style="height: 40px" (click)="addAnotherDropzone()">
                <img style="margin-right: 6px" src="../../../assets/Images/add-icon.svg" alt="" />
                Add another document
            </button>
        </div>
    </div>
    
    
      

    <div class="submit_btn white-background d-flex justify-content-end">
      <button
        type="submit"
        [disabled]="newapplication.form.invalid"
        class="blue-btn"
        (click)="showDialog()"
      >
        Submit
      </button>
    </div>
  </form>

  <p-dialog  class="p-0" [(visible)]="visible" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <div class="p_content text-center">
        <div class="mb-5">
            <img src="./assets/Images/Vector.svg" alt="">
        </div>

        <h4 class="fs24 fw600 mb-5">You have successfully applied for this Job!</h4>
        <button class="blue-btn green_btn fs16 fw400">OK</button>
      </div>
</p-dialog>
</section>
