<ng-container *ngIf="referAFriendScreen">
<section class="external_referrer white-background">
    <div class="refrre">
        <div class=" mb-4 pb-3 d-flex w-100">
            <div class="align-self-center">
              <a class="back"
                ><img (click)="back()"
                  class="me-2 pe-1"
                  src="./assets/Images/black-arrow_back.svg"
                  alt=""
              /></a>
            </div>
            <div class="d-flex align-items-center">
              <h4 class="fs24 fw700">Refer a Friend</h4>
            </div>
        </div>
          <h4 class="fs24 fw700 sr_manager">#11002 - Sr. Marketing Manager</h4>
          <p class="fs12 fw400 tell_us mb-5 pb-3">
            You have been referred for this position by your friend - [Employee Name - Employee ID]. 
          </p>
    </div>

    <form #externalreferrerForm="ngForm" (ngSubmit)="onSubmit(externalreferrerForm)">
        <div class="friend_form">
    
            <div class="d-flex">
                <div class="col-md-5 col-12" style="display: flex; flex-direction: column; row-gap: 30px;">
                      <h3 class="fs20 fw700">Your Information (Referrer)</h3>

                    <!-- Email -->
                    <div class="">
                      <label for="email" class="custom_label">Email Address</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        [(ngModel)]="formData.email"
                        required
                        #emailInput="ngModel"
                        (ngModelChange)="emailInput.control.setValidators(emailValidator)"
                        (ngModelChange)="emailInput.control.updateValueAndValidity()"
                      />
                      <!-- Display error message if the email input is invalid, touched, or dirty -->
                      <div *ngIf="emailInput.invalid && (emailInput.touched || emailInput.dirty)" class="text-danger mt-2">
                        Please Enter Valid Email
                      </div>
                      </div>
                  
                    <!-- First Name -->
                    <div class="">
                      <label for="firstName" class="custom_label">First Name</label>
                      <input
                        type="text"
                        id="firstName"
                        class="form-control"
                        name="firstName"
                        [(ngModel)]="formData.firstName"
                        required
                        pattern="[A-Za-z]+"
                        #firstNameInput="ngModel"
                      />
                      <div
                        *ngIf="
                          firstNameInput.invalid &&
                          (firstNameInput.touched || firstNameInput.dirty)
                        "
                        class="text-danger mt-2"
                      >
                        First name is required.
                      </div>
                    </div>
                
                    <!-- Last Name -->
                    <div class="">
                      <label for="lastName" class="custom_label">Last Name</label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        class="form-control"
                        [(ngModel)]="formData.lastName"
                        required
                        pattern="[A-Za-z]+"
                        #lastNameInput="ngModel"
                      />
                      <div
                        *ngIf="
                          lastNameInput.invalid &&
                          (lastNameInput.touched || lastNameInput.dirty)
                        "
                        class="text-danger mt-2"
                      >
                        Last name is required.
                      </div>
                    </div>
                
                    <!-- Mobile Number -->
                    <div class="">
                      <label for="mobileNumber" class="custom_label">Mobile Number</label>
                      <input
                        type="text"
                        id="mobileNumber"
                        name="mobileNumber"
                        class="form-control"
                        [(ngModel)]="formData.mobileNumber"
                        (keypress)="OnlyNumbersAllowed($event)"
                        required
                        pattern="^[0-9]+$"
                        #mobilenumberInput="ngModel"
                      />
                      <div
                        *ngIf="
                          mobilenumberInput.invalid &&
                          (mobilenumberInput.touched || mobilenumberInput.dirty)
                        "
                        class="text-danger mt-2"
                      >
                      Please Enter Valid Mobile Number
                      </div>
                    </div>

                    <div class="radio_btn">
                        <span>Current Employee?</span>
                        <div class="rad_btns" style="display: flex; align-items: center;">
                                    <input type="radio" value="Yes" style="margin-right: 10px; margin-top: 10px; width: 16px; height: 16px;" [(ngModel)]="current" name="employeeStatusRadio" #employeeStatusRadio="ngModel" required>
                                    <span>Yes</span>

                                    <input type="radio" value="No" style="margin-right: 10px; margin-top: 10px; width: 16px; height: 16px;" [(ngModel)]="current" name="employeeStatusRadio" #employeeStatusRadio="ngModel" required>
                                    <span>No</span> 
                        </div>
                        <div *ngIf="!current && employeeStatusRadio.touched" class="text-danger mt-2">
                            Please select current employee status.
                        </div>
    
                    </div>
                
            </div>
        </div>
    
          <!-- View Job Button -->
          <div class="d-flex justify-content-end">
            <button type="submit" class="blue-btn view_job_btn fs16 fw700" (click)="viewJobDescription('1')">View Job Description</button>
          </div>
    
        </div>
      </form>

</section>
</ng-container>

<ng-container *ngIf="!referAFriendScreen">
<section class="referal_job_description white-background">

    <div class=" mb-4 pb-3 d-flex w-100 job_des">
        <div class="align-self-center">
          <a (click)="viewJobDescription('2')" class="back"
            ><img
              class="me-2 pe-1"
              src="./assets/Images/black-arrow_back.svg"
              alt=""
          /></a>
        </div>
        <div class="d-flex align-items-center">
          <h4 class="fs24 fw700">Job Description</h4>
        </div>
    </div>
    <p class="fs20 fw700 mb-4 pb-3">#11002 - Sr. Marketing Manager</p>
    <div class="job_details d-flex justify-content-between ">
        <div>
            <ul>
                <li>Brand : <span> GIIS </span></li>
                <li>Campus: <span>East Coast Campus</span> Singapore</li>
                <li>Category : <span>Academic</span></li>
                <li>Type : <span>New</span></li>
                <li>Cretaed : <span>July 19, 2023</span></li>
                <li>Cut off Date : <span>July 31, 2023</span></li>
                <li>Job Description :</li>
            </ul>
        </div>
    </div>

    <div class="description">
        <div class="row">
            <div class="col-md-4">
                <h5>Overview *</h5>
            </div>
            <div class="col-md-8">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>

            <div style="border-bottom: 1px solid #C6C6C6; margin-bottom: 24px; padding-bottom: 24px;"></div>
            <div class="col-md-4">
                <h5>Responsibilities *</h5>
            </div>
            <div class="col-md-8">
                <ul>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                </ul>
            </div>

            <div style="border-bottom: 1px solid #C6C6C6; margin-bottom: 24px; padding-bottom: 24px;"></div>
            <div class="col-md-4">
                <h5>Skill Description *</h5>
            </div>
            <div class="col-md-8 new_li">
                <ul>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                    <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                </ul>
            </div>

            <div style="border-bottom: 1px solid #C6C6C6; margin-bottom: 24px; padding-bottom: 24px;"></div>
            <div class="col-md-4">
                <h5>About Us*</h5>
            </div>
            <div class="col-md-8">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
            </div>

            <div style="border-bottom: 1px solid #C6C6C6; margin-bottom: 24px; padding-bottom: 24px;"></div>
            <div class="col-md-4">
                <h5>Disclaimer*</h5>
            </div>
            <div class="col-md-8">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                <p>Note:Lorem Ipsum is simply dummy text of the printing and typesetting industry. i</p>
            </div>
            
            <div style="border-bottom: 1px solid #C6C6C6; margin-bottom: 24px; padding-bottom: 24px;"></div>
            <div class="col-md-4">
                <h5>Skill Category *</h5>

            </div>
            <div class="col-md-8">
                <div class="skill_set">
                    <div>
                        <h5 class="mb-3">Skill*</h5>
                        <div class="d-flex">
                            <p class="d-flex">Skill 1<img src="./assets/Images/x-close.svg" alt=""></p>
                            <p class="d-flex">Skill 2<img src="./assets/Images/x-close.svg" alt=""></p>
                            <p class="d-flex">Skill 3<img src="./assets/Images/x-close.svg" alt=""></p>
                            <p class="d-flex">Skill 4<img src="./assets/Images/x-close.svg" alt=""></p>
                            <p class="d-flex">Skill 5<img src="./assets/Images/x-close.svg" alt=""></p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex align-items-center gap12 job-list-add-buttons align-self-baseline justify-content-end mt_94">
                <button type="button" class="secondary-btn apply_job" routerLink="/internal-shared-new-application"> Apply for this Job </button>
              </div>
        </div>

    </div>
</section>
</ng-container>
